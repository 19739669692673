import React from "react";
// react library for routing
import { Route, Switch, Redirect } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
// import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

//import routes from "routes.js";
import Components from 'routes.js';
import axios from 'axios';
import { PrivateRoute } from '../_components';
import Productform from 'views/pages/product/Products';
import Addprodtab from 'views/pages/product/Addprodtab';
import AddUser from 'views/pages/User/AddUser'
import ProductDetail from "views/pages/Channel-Listing/ProductDetail.js";
import RequestIntroView from "views/pages/Request_Intro/RequestIntroView";
import ChannelDetail from 'views/pages/Channel-Listing/ChannelDetail';
import OldChannelDetail from "views/pages/Old-Channel-Listing/OldChannelDetail";

import MyproductlistVendor from "views/pages/product/MyproductlistVendor";
import ImportChannel from 'views/pages/Data-Import/Import-Channel';
import TravelList from 'views/pages/Events/TravelList';



class Admin extends React.Component {
  state = {
    sidenavOpen: false,
    routes: []
  };

  componentWillMount() {
    let user = JSON.parse(localStorage.getItem('CHuser'));
    (async () => {
      await axios.get('/admin/menuListing/' + user.accessId).then(res => {
        console.log("response:", res.data);
        if (res.data) {
          let routes = [];
          routes.push(...res.data);
          this.setState({ ...this.state, routes: routes })
        }

        //return res.data;
      });

      // data.map(m => {
      //   routes.map((x,index) =>{              
      //       if (x.name.trim()==m.menuName.trim()&&m.menuId==0)
      //         routes.splice(index,1);          

      //       if (x['views'])
      //       {                  
      //         x['views'].map((v,index) => {
      //           if (x['views']&&v.name.trim()==m.menuName.trim()&&m.menuId==1&&m.asmId==0)
      //             x['views'].splice(index,1);          
      //         })                  
      //       }        
      //   })             
      // })   
    })();
  }

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      console.log('prop.component:', prop.component)
      const ComponentToRender = Components[prop.component];

      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={ComponentToRender}
            exact
            key={key}
          />
          //   <Route
          //   path={prop.layout + prop.path}
          //   component={ComponentToRender}
          //   key={key}
          // />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < this.state.routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          this.state.routes[i].layout + this.state.routes[i].path
        ) !== -1
      ) {
        return this.state.routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  toggleSidenav = e => {
    // console.log('is pined',document.body.classList.contains("g-sidenav-pinned"));
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.CallAddClass().then(this.RemoveAdd);
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen
    });
  };

  CallAddClass = e => {
    return new Promise(function (resolve, reject) {
      setTimeout((function () {
        resolve(document.getElementById("nav-bar").classList.add("nav-bar"));
      }), 10);
    });
  }
  RemoveAdd = e => {
    setTimeout((function () {
      document.getElementById("nav-bar").classList.remove("nav-bar");
    }), 1000);

  }

  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf(
      "admin/dashboard"
    ) === -1
      ? "dark"
      : "dark";
  };
  render() {
    return (
      <>
        {/* admin */}
        <Sidebar
          {...this.props}
          routes={this.state.routes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: "/",
            imgSrc: require("assets/img/brand/channel_hub_logo.png"),
            imgAlt: "..."
          }}
        />
        <div
          className="main-content"
          ref="mainContent"
          onClick={this.closeSidenav}
        >
          <AdminNavbar
            {...this.props}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {this.getRoutes(this.state.routes)}
            <Route exact path="/admin/CompanyListing/companysettings" component={Components["CompanySetting"]} key={"company"} />
            <Route exact path="/admin/CompanyListing/companysettings/userManage" component={Components["UserManagement"]} key={"usermanage"} />
            <Route exact path="/admin/CompanyListing/companysettings/settings" component={Components["Settings"]} key={"settings"} />
            <Route exact path="/admin/CompanyListing/companysettings/profileform" component={Components["Userprofileform"]} key={"profileform"} />
            <Route exact path="/admin/CompanyListing/companysettings/productsList" component={MyproductlistVendor} key={"productslist"} />
            <Route exact path="/admin/CompanyListing/companysettings/userManage/edituser" component={Components["Userprofileform"]} key={"editform"} />
            <Route exact path="/admin/CompanyListing/companysettings/userManage/userform" component={Components["Userprofileform"]} key={"user"} />
            <Route exact path="/admin/CompanyListing/companysettings/productsList/EditProduct" component={Addprodtab} key={"editproduct"} />
            <Route exact path="/admin/CompanyListing/companysettings/productsList/AddProduct" component={Addprodtab} key={"addproduct"} />
            <Route exact path="/admin/CompanyListing/companysettings/productsList/productDetail/:id/:cid" component={ProductDetail} />

            <Route path="/admin/productmanage" component={Addprodtab} />
            <Route path="/admin/addUser" component={AddUser} />


            <Route path="/admin/data-import" component={ImportChannel} />
            <Route path="/admin/travelList" component={TravelList} />

            <Route path="/admin/productDetail/:id/:cid" component={ProductDetail} />
            <Route path="/admin/reqintroview/:channelId" component={RequestIntroView} key={"reqintro"} />
            <Route path="/admin/event" component={Components["Events"]} key={"boevents"} />
            <Route path="/admin/hotel/:id" component={Components["Hotels"]}  />
            <Route path="/admin/hotel" component={Components["Hotels"]}  />
            <Route path="/admin/eventListing" component={Components["EventListing"]} key={"eventListing"} />
            <Route path="/admin/manageEvent" component={Components["ManageEvent"]} key={"manageEvent"} />

            <Route path="/admin/eventConnection" component={Components["EventConnection"]} key={"eventConnection"} />

            <Route exact path="/admin/CompanyListing/channelDetail/:id" component={ChannelDetail} />
            <Route exact path="/admin/oldChannelListing" component={Components["OldChannelListing"]} key={"oldChannelListing"} />
            <Route exact path="/admin/oldChannelListing/oldChannelDetail/:id" component={OldChannelDetail} key={"oldChannelDetail"} />

            <Route exact path="/admin/oldChannelEdit" component={Components["OldChannelEdit"]} key={"oldChannelEdit"} />

            <Route exact path="/admin/channels"  key={'Channels'}/>
            <Route exact path="/admin/brand" component={Components["Brand"]} />

            <Route exact path="/admin/eventmeet" component={Components["EventHome"]} key={"eventCatalyst"} />
            <Redirect from="*" to="/admin/dashboard" />


          </Switch>
          {/* <AdminFooter /> */}
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </>
    );
  }
}

export default Admin;
