import React from "react"
import axios from 'axios'; 
export const NoData= ()=> {
    return (
<div className="no-records ">
          {/* <span>No Records</span> */}
          <div className="no-img text-center">
          <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")}/></div>
        </div>
    )
}
