import React, { useState, useEffect } from 'react';
import {Card,CardBody,Row,Col,Container,NavItem,NavLink,Nav,TabContent,TabPane} from "reactstrap";
import classnames from "classnames";
import TradeDetails from './TradeDetails'
import GeneralForm from './GeneralForm'
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { history } from '_helpers';
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import DistributionChannel from './DistributionChannel';
import { useSelector  } from 'react-redux';
import queryString from 'query-string';
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";

const Addprodtab = (props) => {
  const redux_data = useSelector(state => state.distributionChannel);
  console.log('redux_data', redux_data);
  let [isLoading, setLoading] = useState(false);
  let [productDetail, setProductDetail] = useState(null);
  let [isAdd, setAdd] = useState(props.location.pathname?.includes('AddProduct'));
  const [isdata, setData] = useState(true);
    const [tabs, toggleNav] = useState("1");
    let [generalForm, setGeneralForm] = useState(null);
    let [tradeForm, setTradeForm] = useState(null);
    const params = queryString.parse(props.location.search);
    console.log('isAdd',isAdd);

    var user = JSON.parse(localStorage.getItem('CHuser'))?.userData;
    
    

    useEffect(()=>{
      if(!isAdd){
        getProduct();
      }else{
        let data = {channelId: user.channelId, channelTypeId: user.channelTypeId};
        productDetail=data;
        setProductDetail({...productDetail});
      }
    }, []);

    const [hasBrand, setBrands] = useState({
      length: 0,
      brand: []
    })

    const getBrands = async() => {
      const res = await axios.get('/setting/admin/getChannelBrand/'+params.channelId).then(res => res.data);
      if(res){
        if(res?.brand?.length > 0){
          setBrands({
            length: 1,
            brand: res.brand[0]
          });
        }
      }
    } 

    const getProduct= async()=>{
      setLoading(true);
      setData(true);
      const res = await commonService.getServices('Product/getAdminProduct/'+params.sku+'/'+params.channelId).catch(e=> console.log(e));
      if(res){
        productDetail=res.data;
        setProductDetail(productDetail);
      }
      await getBrands();
      setData(false);
      setLoading(false);
    }
    const toggleNavs=(e,index)=>{
        e.preventDefault();
        toggleNav(index);
    }
    const tabLoading=(data)=>{
      console.log('tabLoading', data);
      setLoading(data);
    }
    const [showDrawer,setshowDrawer]=useState(false);
    console.log("text",showDrawer);
    const closeDrawer =()=>{
      setshowDrawer(false);
    }
    const saveGeneralForm=(data)=>{
      setLoading(true);
      console.log('saveGeneralForm',data);
      generalForm=data;
      setGeneralForm(generalForm);
      // toggleNav('2');
    }
    const saveTradeDetails=async(data)=>{
      setLoading(true);
      console.log('saveTradeDetails',data);
      tradeForm=data
      setTradeForm(tradeForm);
      let keyRetailers = await getKeyRetailersList(redux_data?.stateValue?.RetailerList);
      let keyDistributors = await getKeyRetailersList(redux_data?.stateValue?.DistributorList);
      let keyResellers = await getKeyRetailersList(redux_data?.stateValue?.ResellerList);
      
      let productTargetLocation= {
        targetlocation:redux_data?.location?.length ? [].concat(redux_data?.location?.map(x => {
          return {regioncountryjctid: parseInt(x.regioncountryjctid)};
        })) : []
      };

      let productRelevantChannel ={
        distributionchannel: redux_data?.stateValue?.relevantDistributor?.length ? [].concat(redux_data?.stateValue?.relevantDistributor?.map(x => {
          return {customerProfileId: parseInt(x.Channel_Customer_Profile_ID)};
        })) : [],
        retailchannel: redux_data?.stateValue?.relevantRetail?.length ? [].concat(redux_data?.stateValue?.relevantRetail?.map(x => {
          return {customerProfileId: parseInt(x.Channel_Customer_Profile_ID)};
        })) : [],
        resellerchannel: redux_data?.stateValue?.relevantReseller?.length ? [].concat(redux_data?.stateValue?.relevantReseller?.map(x => {
          return {customerProfileId: parseInt(x.Channel_Customer_Profile_ID)};
        })) : []
      }
      if(productRelevantChannel.distributionchannel.length===0)
      delete productRelevantChannel.distributionchannel;
      if(productRelevantChannel.retailchannel.length===0)
      delete productRelevantChannel.retailchannel;
      if(productRelevantChannel.resellerchannel.length===0)
      delete productRelevantChannel.resellerchannel;

      let distributionChannel ={
        keyRetailers:keyRetailers,
        keyDistributors:keyDistributors,
        keyResellers:keyResellers,
        productTargetLocation:productTargetLocation,
        productRelevantChannel:productRelevantChannel
      }
      let deleted = {
        deletedkeydistributor:redux_data?.stateValue?.deletedkeydistributor,
        deletedkeyretailer:redux_data?.stateValue?.deletedkeyretailer,
        deletedkeyreseller:redux_data?.stateValue?.deletedkeyreseller
      }
      console.log('deleted',deleted);
      if(!isAdd){
        if(deleted.deletedkeydistributor.length===0)
        delete deleted.deletedkeydistributor
        if(deleted.deletedkeyretailer.length===0)
        delete deleted.deletedkeyretailer
        if(deleted.deletedkeyreseller.length===0)
        delete deleted.deletedkeyreseller
      }
      if(distributionChannel.keyRetailers.length===0)
      delete distributionChannel.keyRetailers;
      if(distributionChannel.keyDistributors.length===0)
      delete distributionChannel.keyDistributors;
      if(distributionChannel.keyResellers.length===0)
      delete distributionChannel.keyResellers;

      generalForm.productBasicInfo.keydistributors=redux_data?.stateValue?.distributorDesc;
      generalForm.productBasicInfo.keyretailers=redux_data?.stateValue?.RetailerDesc;
      generalForm.productBasicInfo.keyresellers=redux_data?.stateValue?.ResellerDesc;
      console.log('form data',{...generalForm,...tradeForm, ...distributionChannel} );
      let res;


      if(isAdd){
        res = await commonService.postService({...generalForm,...tradeForm, ...distributionChannel, user: {
          userId: user.userId,
          channelId: user.channelId
        }
        }, '/channelAdmin/Product/0').catch(err =>ToastsStore.error('Something went wrong...'));
        //Mail Code
        if(res)
        {
        //  console.log("addproduct:",res);
          // let maildata={
          //   userId:+res.data.data['updatedBy'],                      
          // }
          // axios.post('mailerservice/Add_Product_Mail',maildata);
        }
      }else{
        if(params.action === '2'){
          res = await commonService.postService({...generalForm,...tradeForm, ...distributionChannel, user: {
            userId: user.userId,
            channelId: user.channelId
          }}, '/channelAdmin/Product/0').catch(err =>ToastsStore.error('Something went wrong...'));
          //Mail Code
          // if(res)
          // {
          //   console.log("addproduct:",res);
          //   let maildata={
          //     userId:+res.data.data['updatedBy'],                      
          //   }
          //   axios.post('mailerservice/Add_Product_Mail',maildata);
          // }
        }else{
          res = await commonService.postService({...generalForm,...tradeForm, ...distributionChannel, ...deleted}, '/Product/updateProduct/'+params.sku).catch(err =>ToastsStore.error('Something went wrong...'));
        }
      }
      setLoading(false);
      if(res){
       
        if( res.data == 'productLimitExceeded'){
          setTimeout(() => ToastsStore.warning('This Channel Exceeded the allowed product limit as per subscribed plan'));
          //history.push('/admin/product');
        } else {
          setTimeout(() => ToastsStore.success('Product Saved Successfully.'), 1000);
          if(props.location.pathname.includes('companysettings')){
            history.push('/admin/CompanyListing/companysettings/productsList')
          }else{
            history.push('/admin/product');
          }
        }
      }
    }
    const getKeyRetailersList=(res) =>{
      let data = [];
      let count = 0;
      res.forEach(item => {
        let keyRetailer={
          existchannelid:null,
          oldchannelid:null,
          keypartnerid:null,
          channelkeypartnerid:null,
          countryid:null,
          partnername:null,
          image:null,
          imageid:null,
        };
        if (item?.channelId) {
          keyRetailer.existchannelid = item?.channelId;
          keyRetailer.image = item?.image;
          keyRetailer.imageid = item?.imageid;
        }else{
          delete keyRetailer.existchannelid;
        }
        if (item?.oldchannelid) {
          keyRetailer.oldchannelid = item?.oldchannelid;
        }else{
          delete keyRetailer.oldchannelid;
        }
        if (item?.keypartnerid) {
          keyRetailer.keypartnerid = item?.keypartnerid;
        }
        else{
          delete keyRetailer.keypartnerid;;
        }
        if (item?.channelkeypartnerid) {
          keyRetailer.channelkeypartnerid = item?.channelkeypartnerid;
        }else{
          delete keyRetailer.channelkeypartnerid;
        }
        if(!item?.keypartnerid && !item?.channelkeypartnerid){
          delete keyRetailer.keypartnerid;;
          delete keyRetailer.channelkeypartnerid;
        }
        if(item.isNew == '1')
        {
          
          if(item.documentpath)
            keyRetailer.image = item.documentpath;
          
        }
        // if(!item?.keypartnerid && !item?.oldchannelid && !item?.channelId){
        //   delete keyRetailer.channelkeypartnerid;
        //   delete keyRetailer.keypartnerid;
        // }
        keyRetailer.countryid = item?.countryid;
        keyRetailer.partnername = item?.name;
        // const countKeyRetailer = this.partnerObjects[typeId].keyRetailerUpdatedData.filter(retailer => retailer.keypartnerid === item.keypartnerid);
        // if (countKeyRetailer.length !== 0) {
        //   count++;
        // }
        data.push(keyRetailer);
      });
      // if (count === this.partnerObjects[typeId].keyRetailerSelectedData.length && this.actionType === ActionType.UPDATE) {
      //   data = [];
      // }
      return data;
    }
    return (
      <>
      {isLoading && <Spinner/>}
        <div className="main-content">
        {!props.ratingId && <SimpleHeader location={props.match} />}
          <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
            <Container className="mt-4 col-lg-9 col-md-9 col-sm-12   container-fluid" fluid>
          <Row>
          <Col lg="12">
          <div style={{textAlign:"-webkit-center"}}>
          <div className="nav-wrapper" style={{width:"35%"}}>
          <Nav
            className="nav-fill flex-column flex-md-row"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
              <NavItem>
              <NavLink
                aria-selected={tabs === "1"}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: tabs === "1"
                })}
                onClick={e=>toggleNavs(e,"1")}
                href="#pablo"
                role="tab"
              >
                <i class="ni ni-single-copy-04 tat mr-2"/>
                General
              </NavLink>
            </NavItem>

            {/* <NavItem>
              <NavLink
                aria-selected={tabs === "2"}
                className={classnames("mb-sm-3 mb-md-0 mr-2", {
                  active: tabs === "2"
                })}
                onClick={e=>toggleNavs(e,"2")}
                href="#pablo"
                role="tab"
              >
                <i class="ni ni-square-pin tat mr-2"/>
                Trade Details
              </NavLink>
            </NavItem> */}
          </Nav>
          </div>
        </div>
        <Card>
          <CardBody>
            {(!isAdd && isdata )?'' : <TabContent activeTab={ tabs}>
              <TabPane tabId="1">
                  <GeneralForm  saveGeneralForm={saveGeneralForm} tabLoading={tabLoading} isAdd={isAdd} params={params} productDetail={productDetail} saveTradeDetails={saveTradeDetails} hasBrand={hasBrand} userData={user}/>
              </TabPane>
              {/* <TabPane tabId="2">
                <TradeDetails saveTradeDetails={saveTradeDetails} isAdd={isAdd} params={params} productDetail={productDetail}/>
              </TabPane> */}
            </TabContent>}
          </CardBody>
        </Card>
         </Col>
         </Row>
         {/* {tabs ==='1' && <div onClick={()=>{setshowDrawer(true)}} style={{cursor:"pointer", color: '#32325d'}}><h3>Distribution Channel</h3><br/>
         <p>By default we clone the same information that are entered under your company profile for your current distribution channels and target countries. Click here if you wish to modify these information</p>
         </div>}
         {(!isAdd && isdata)?'' : <DistributionChannel title="Distribution Channel" visible={showDrawer} closeDrawer={closeDrawer} isAdd={isAdd} params={params} productDetail={productDetail}/>} */}
              </Container>
            </div>
            </>
         );
              }

export default Addprodtab;