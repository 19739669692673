import React, { useEffect, useState } from 'react';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';

//import { DataGrid, GridToolbar } from '@material-ui/data-grid';

import { Card, CardBody, Row, Col, Button, CardHeader, Modal } from "reactstrap";

import { FormAutocomplete, FormInput } from "_components/FormElements/FormInput";
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FormControlLabel from '@mui/material/FormControlLabel';

import Checkbox from '@mui/material/Checkbox';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import TextareaAutosize from '@mui/material/TextareaAutosize';
import { ContactSupportOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Typography } from 'antd';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}));

const Agenda = (props) => {
    const classes = useStyles();
    const [startHr, setStartHr] = React.useState('');
    const [startMin, setStartMin] = React.useState('');
    const [endHr, setEndHr] = React.useState('');
    const [endMin, setEndMin] = React.useState('');

    const [listing, setListing] = useState(null);
    const [eventId, setEvent] = useState();

    const [eventList, setEventList] = useState([]);
    const [locationList, setLocationList] = useState([{ id: '1', name: 'Workshop Rooms' }, { id: '2', name: 'Exhibition Area' }]);
    const [typeList, setTypeList] = useState([{ id: '1', name: 'Workshop' }, { id: '2', name: 'Dinner' }]);

    const [agendaList, setAgenda] = useState([]);
    const [parentFunctionList, setparentFunctionList] = useState();
    const [parentAgendaId, setParentAgendaId] = useState();
    const [vendorList, setVendorList] = useState();
    const[parentfunctionDetail, setParentFunctionDetail] = useState()

    const stateInitialValue = {
        eventDate: null,
        function: '',
        utcFormat:'',
        shortFunction: '',
        locationId: null,
        typeId: null,
        comment: '',
        zoomUrl: '',
        description: '',
        image: '',
        hasParticipant: false,
        allDay: false,
        UponRegistration:false,
        UponInvitation:false,
        Print:false,
        Live:false,
        agendaId: null, 
        transfer:null,
        capacity:null,
        minus:null, 
        mandatoryall:false,
        mandatorybuy:false,
        mandatorysta:false,
        mandatorypar:false,
        mandatoryspe:false,
        mandatorynon:false,
        mandatorynon:false,
        mandatoryven:false,
        mandatoryvis:false,
        mandatoryjou:false,

        optionalall:false,
        optionalbuy:false,
        optionalsta:false,
        optionalpar:false,
        optionalspe:false,
        optionalnon:false,
        optionalnon:false,
        optionalven:false,
        optionalvis:false,
        optionaljou:false
    }

    const [state, setState] = useState(stateInitialValue)

    useEffect(() => {
        getEventDetails();
    }, [])

    useEffect(() => {
        getAgendaList();
        getVendorList()
    }, [eventId])

    function getEventDetails() {
        axios.get('/channelAdmin/getEventMeet', {
        }).then(res => {
            setEventList(res.data.map(x => {
                return { id: x.id, name: x.name }
            }))

        }).catch(err => {
            console.log("Event Error:", err);
        })
    }

    const clearState = () => {
        setState(stateInitialValue);
        setStartHr('');
        setStartMin('');
        setEndHr('');
        setEndMin('');
    }

    async  function  getAgendaList() {
        if (eventId)
         await   axios.get('/channelAdmin/agendaList/' + eventId, {
            }).then(res => {
                console.log("agendalist:", res.data)
                setAgenda(res.data.map(x => {
                    return { id: x.agendaId, time: x.fromTime + ' - ' + x.toTime, title: x.function,utcFormat:x.utcFormat, date: x.functionDate.split('T')[0] }
                }))
            }).catch(err => {
                console.log("Event Error:", err);
            })
        getParentfunctionList()
    }

    async  function getParentfunctionList(){  
        let res = await axios.get('/channelAdmin/getParentFunctionList/'+ eventId, {
        }).then(res => { 
            setparentFunctionList(res.data.map(x => {
                return { agendaId: x.agendaId, function: x.function }
            })) 
        }).catch(err => {
            console.log("Event Error:", err);
        }) 


    }


    async  function getVendorList(){  
        let res = await axios.get('/channelAdmin/getCompanyList', {
        }).then(res => {  
            let tenCompnay = []
            for(let i=0; i<10; i++){
                tenCompnay[i] = res.data[i]
            }

 
            setVendorList(tenCompnay.map(
            (x, index)=>{ 
                return { channelId: x.channelId, channelTypeId: x.channelTypeId, companyName:x.companyName }
            }   )) 
        }).catch(err => {
            console.log("Event Error:", err);
        })  

    }


    const[urlError, setUrlError] = useState(null)
    const[zoomUrlError, setZoomUrlError] = useState(null)



    const save = () => {

        if(parentfunctionDetail != null ){
            setParentAgendaId(parentfunctionDetail.agendaId) 
        }
        
        let data = {
            agendaId: state.agendaId,
            eventId: eventId,
            functionDate: state.eventDate,
            function: state.function,
            utcFormat:state.utcFormat,
            function_short: state.shortFunction,
            location: state.locationId,
            type: state.locationId,
            comment: state.comment,
            zoomUrl: state.zoomUrl,
            description: state.description,
            fromTime: startHr + ':' + startMin,
            toTime: endHr + ':' + endMin,
            imageUrl: state.image,
            hasParticipant: state.hasParticipant ? '1' : '0',
            transfer:state.transfer,
            capacity:state.capacity,
            minus:state.minus, 
            mandatoryall: state.mandatoryall ? '1' : '0',
            allDay: state.allDay ? '1' : '0',
            UponRegistration:state.UponRegistration ? '1' : '0',
            UponInvitation:state.UponInvitation ? '1' : '0',
            Print:state.Print ? '1' : '0',
            Live:state.Live ? '1' : '0',

            mandatorybuy: state.mandatorybuy ? '1' : '0',
            mandatorysta: state.mandatorysta ? '1' : '0',
            mandatorypar: state.mandatorypar ? '1' : '0',
            mandatoryspe: state.mandatoryspe ? '1' : '0',
            mandatorynon: state.mandatorynon ? '1' : '0',
            mandatoryven: state.mandatoryven ? '1' : '0',
            mandatoryvis: state.mandatoryvis ? '1' : '0',
            mandatoryjou: state.mandatoryjou ? '1' : '0',
    
            optionalall: state.optionalall ? '1' : '0',
            optionalbuy: state.optionalbuy ? '1' : '0',
            optionalsta: state.optionalsta ? '1' : '0',
            optionalpar: state.optionalpar ? '1' : '0',
            optionalspe: state.optionalspe ? '1' : '0',
            optionalnon: state.optionalnon ? '1' : '0',
            optionalven: state.optionalven ? '1' : '0',
            optionalvis: state.optionalvis ? '1' : '0',
            optionaljou: state.optionaljou ? '1' : '0',
            parentAgendaId : parentAgendaId,
            vendor:vendor,
            parentfunctionDetail:parentfunctionDetail!=undefined ?parentfunctionDetail:null
        }

        axios.post('/channelAdmin/saveAgenda', data).then(res => {

            if(res.data.imgError){
              setUrlError(res.data.imgError); 
                return
            }

            if(res.data.zoomError){
                setZoomUrlError(res.data.zoomError); 
                return
            }

            if (res) {
                if(res.img)
                if (!state.agendaId)
                    setAgenda([...agendaList, { id: res.data.agendaId, time: data.fromTime + ' - ' + data.toTime, title: data.function,utcFormat:data.utcFormat}])
                setVendor(null)
                setParentFunctionDetail(null)
                setListing(true);
            }

        }).catch(err => {
            console.log("saveAgenda Error:", err);
        })
    }


    const eventChange = (value) => {
        setEvent(value);
        setListing(true);
    }


    const deleteAgenda = (id) => {

        let data = {
            eventId: eventId,
            agendaId: id
        }

        axios.post('/channelAdmin/deleteAgenda', data).then(res => {
            getAgendaList();
        })
            .catch(err => {
                console.log("delete agenda:", err);
            })

    }

    const editAgenda = (id) => {

        axios.get('/channelAdmin/getAgendaById/' + eventId + '/' + id).then(res => {

            if (res.data.length) {
                let response = res.data[0];
                setState({
                    ...state,
                    eventDate: response.functionDate.split('T')[0],
                    function: response.function,
                    utcFormat:response.utcFormat,
                    shortFunction: response.function_short,
                    locationId: response.location,
                    typeId: response.type,
                    comment: response.comment,
                    zoomUrl: response.zoomUrl,
                    description: response.description,
                    image: response.imageUrl,
                    hasParticipant: response.hasParticipant == '1' ? true : false,
                    agendaId: response.agendaId
                });

                setStartHr(response.fromTime.split(':')[0]);
                setStartMin(response.fromTime.split(':')[1]);
                setEndHr(response.toTime.split(':')[0]);
                setEndMin(response.toTime.split(':')[1]);

                setListing(false);
            }

        })
            .catch(err => {
                console.log("delete agenda:", err);
            })

    }

    const agendaCol = React.useMemo(
        () => [

            {
                field: 'date',
                headerName: 'Date',
                width: 150,
                editable: false,
                sortable: false
            },
            {
                field: 'time',
                headerName: 'Time',
                width: 150,
                editable: false,
                sortable: false
            },
            {
                field: 'title',
                headerName: 'Title',
                width: 450,
                editable: false,
                sortable: false
            },
            {
                field: 'action',
                headerName: 'Action',
                type: 'actions', headerAlign: 'center',
                width: 150,

                renderCell: (params) => {

                    return (
                        <>
                            <i onClick={() => editAgenda(params.id)} className="fas fa-pencil-alt ml-4"></i>
                            <i onClick={() => deleteAgenda(params.id)} className="fas fa-trash-alt ml-4"></i>

                        </>
                    );
                }
            }


        ]
    );
    const [vendor, setVendor] = useState()

    return (
        <>
            <Card>
                <CardHeader className="p-3">
                    <Button onClick={() => { setListing(false); clearState() }}>Add Function<span className="btn-inner--icon"><i class="fas fa-plus"></i></span></Button>

                    <FormControl sx={{ m: 1, minWidth: 320 }} size="small">

                        <InputLabel id="demo-select-small">Select Event</InputLabel>
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={eventId}
                            label="Select Event"
                            onChange={(e) => eventChange(e.target.value)}
                        >
                            {eventList.map(x =>
                                <MenuItem value={x.id}>{x.name}</MenuItem>
                            )}

                        </Select>
                    </FormControl> 
 
                </CardHeader>

            </Card>

            {listing &&
                <DataGrid
                    rows={agendaList}
                    columns={agendaCol}
                    pageSize={100}
                    disableColumnMenu
                    pagination={false}

                    autoHeight
                />}

            {listing === false &&         
                <Card>
<Card>
  <CardHeader className="p-1">
{
    parentFunctionList &&
  <FormControl sx={{ m: 1, minWidth: 630, justifyContent: "flex-end"}} size="small">

      <InputLabel id="demo-select-small">Parent Function</InputLabel>
      <Select
    labelId="demo-select-small"
    id="demo-select-small"
    value={parentAgendaId}
    label="Parent Function"
    onChange={(e) => 
    {
        setParentFunctionDetail(e.target.value)
    }
   }
>
          {parentFunctionList.map(x =>
              <MenuItem value={x}> {x.function}</MenuItem>
          )}

      </Select>
  </FormControl>
 
}

</CardHeader>

</Card>
                    <Row>
                        <div className="col-lg-6 mt-4">
                            <div className="form-group">

                                <FormControl sx={{  minWidth: 320 }} size="small" className={classes.root}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            label="Time slot date"
                                            InputLabelProps={{ shrink: true }}
                                            inputFormat="MM/dd/yyyy"
                                            value={state.eventDate}
                                            onChange={(value) => setState({ ...state, ['eventDate']: value })}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>

                                <Row>
                        <div className="col-lg-6 mt-4">
                            <div className="form-group">
                               All Day <Checkbox

                                    checked={state.allDay}
                                    color="warning"
                                    onChange={(e) => setState({ ...state, ['allDay']: e.target.checked ? true : false })}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                        </div>
                    </Row>
                    
                            </div>
                        </div>

                        {/* <FormControlLabel control={<Checkbox defaultChecked={false} />} label="All day" /> */}

                        <div className="col-lg-6 mt-4">
                            <div className="form-group">
                                Time Start
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">

                                    <InputLabel id="demo-select-small">Hour</InputLabel>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={startHr}
                                        label="Hour"
                                        onChange={(e) => setStartHr(e.target.value)}
                                    >
                                        {
                                            hour.map(x =>
                                                <MenuItem value={x}>{x}</MenuItem>
                                            )}

                                    </Select>
                                </FormControl>

                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">

                                    <InputLabel id="demo-select-small">Min</InputLabel>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={startMin}
                                        label="Min"
                                        onChange={(e) => setStartMin(e.target.value)}
                                    >{
                                            min.map(x =>
                                                <MenuItem value={x}>{x}</MenuItem>
                                            )}
                                    </Select>
                                </FormControl>

                                End
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">

                                    <InputLabel id="demo-select-small">Hour</InputLabel>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={endHr}
                                        label="Hour"
                                        onChange={(e) => setEndHr(e.target.value)}
                                    >
                                        {
                                            hour.map(x =>
                                                <MenuItem value={x}>{x}</MenuItem>
                                            )}
                                    </Select>
                                </FormControl>

                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">

                                    <InputLabel id="demo-select-small">Min</InputLabel>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={endMin}
                                        label="Min"
                                        onChange={(e) => setEndMin(e.target.value)}
                                    >

                                        {
                                            min.map(x =>
                                                <MenuItem value={x}>{x}</MenuItem>
                                            )}
                                    </Select>
                                </FormControl>

                                <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 190 }} size="small">
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        id="filled-password-input"
                                        label="UTC Format"
                                        type="text"
                                        value={state.utcFormat}
                                        onChange={(e) => setState({ ...state, ['utcFormat']: e.target.value })}
                                    />
                                </FormControl>


                            </div>
                        </div>


                     
                    </Row>
                    <Row>

                        <div className="col-lg-6 mt-4">
                            <div className="form-group">
                                <FormControl size="small" className={classes.root}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        id="filled-password-input"
                                        label="Function"
                                        type="text"
                                        value={state.function}
                                        onChange={(e) => setState({ ...state, ['function']: e.target.value })}
                                    />
                                </FormControl>
                            </div>
                        </div>

                        <div className="col-lg-6 mt-4">
                            <div className="form-group">
                                <FormControl size="small" className={classes.root}>
                                    <TextField
                                        id="filled-password-input"
                                        InputLabelProps={{ shrink: true }}
                                        label="Function short version"
                                        type="text"
                                        value={state.shortFunction}
                                        onChange={(e) => setState({ ...state, ['shortFunction']: e.target.value })}
                                    />
                                </FormControl>
                            </div>
                        </div>

                    </Row>

                    <Row>
                        <div className="col-lg-6 mt-4">
                            <div className="form-group">
                                <FormControl size="small" className={classes.root}>

                                    <InputLabel id="demo-select-small">Location</InputLabel>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={state.locationId}
                                        label="Location"
                                        onChange={(e) => setState({ ...state, ['locationId']: e.target.value })}
                                    >
                                        {locationList.map(x =>
                                            <MenuItem value={x.id}>{x.name}</MenuItem>
                                        )}

                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className="col-lg-6 mt-4">
                            <div className="form-group">
                                <FormControl size="small" className={classes.root}>

                                    <InputLabel id="demo-select-small">Type</InputLabel>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={state.typeId}
                                        label="Type"
                                        onChange={(e) => setState({ ...state, ['typeId']: e.target.value })}
                                    >
                                        {typeList.map(x =>
                                            <MenuItem value={x.id}>{x.name}</MenuItem>
                                        )}

                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </Row>

                    <Row>

                    <div className="col-lg-6 mt-4">                          <div className="form-group"> 
      <FormControl sx={{  width:'100%'}} size="small"> 
       <InputLabel id="demo-select-small">Select Vendor</InputLabel>
         <Select
          labelId="demo-select-small"
          id="demo-select-small"
          value={vendor}
          label="Select Vendor"
          onChange={(e) => setVendor(e.target.value)}
         >
         {
        vendorList && vendorList.map(x =>
            <MenuItem value={x}>{x.companyName}</MenuItem>
           ) 
        }  
                            

         </Select>
       </FormControl>
 

                            </div>
                        </div>

                      
                        <div className="col-lg-6 mt-4">

                        <div className="form-group">
                                <FormControl size="small" className={classes.root}>
                                    <TextField
                                        id="filled-password-input"
                                        InputLabelProps={{ shrink: true }}
                                        label="minus"
                                        type="text"
                                        value={state.minus}
                                        onChange={(e) => setState({ ...state, ['minus']: e.target.value })}
                                    />
                                </FormControl>
                            </div>
                       
                        </div>

                    </Row>


                    <Row>
                    <div style={{display:"flex", marginLeft:"1rem", justifyContent:"space-between", width:"100%"}}>

                            <div className="form-group">
                                Ask Participant <Checkbox

                                    checked={state.hasParticipant}
                                    color="warning"
                                    onChange={(e) => setState({ ...state, ['hasParticipant']: e.target.checked ? true : false })}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                       

                            <div className="form-group">
                               Upon Registration <Checkbox

                                    checked={state.UponRegistration}
                                    color="warning"
                                    onChange={(e) => setState({ ...state, ['UponRegistration']: e.target.checked ? true : false })}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                    


                       
                            <div className="form-group">
                            Upon Invitation<Checkbox

                                    checked={state.UponInvitation}
                                    color="warning"
                                    onChange={(e) => setState({ ...state, ['UponInvitation']: e.target.checked ? true : false })}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                     


                     
                            <div className="form-group">
                                 Print<Checkbox

                                    checked={state.Print}
                                    color="warning"
                                    onChange={(e) => setState({ ...state, ['Print']: e.target.checked ? true : false })}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                     

                    
                            <div className="form-group" style={{marginRight:"0.5rem"}}>
                                   Live<Checkbox

                                    checked={state.Live}
                                    color="warning"
                                    onChange={(e) => setState({ ...state, ['Live']: e.target.checked ? true : false })}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                         </div>
                        </div>
                    </Row>


                    <Row>
                        <div className="col-lg-6 mt-4">
                            <div className="form-group">
                                <FormControl size="small" className={classes.root}>
                                    <TextField
                                        id="filled-password-input"
                                        InputLabelProps={{ shrink: true }}
                                        label="transfer"
                                        type="text"
                                        value={state.transfer}
                                        onChange={(e) => setState({ ...state, ['transfer']: e.target.value })}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4">
                        <div className="form-group">
                                <FormControl size="small" className={classes.root}>
                                    <TextField
                                        id="filled-password-input"
                                        InputLabelProps={{ shrink: true }}
                                        label="capacity"
                                        type="text"
                                        value={state.capacity}
                                        onChange={(e) => setState({ ...state, ['capacity']: e.target.value })}
                                    />
                                </FormControl>
                            </div>
                       
                        </div>

                    </Row>

                    <Row>

                    <div className="col-lg-6 mt-4">
                            <div className="form-group">
                                <FormControl size="small" className={classes.root}>
                                    <TextField
                                        id="filled-password-input"
                                        InputLabelProps={{ shrink: true }}
                                        label="Comment"
                                        type="text"
                                        value={state.comment}
                                        onChange={(e) => setState({ ...state, ['comment']: e.target.value })}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4">

                        {<h4 >Description * (5000 Characters Max)
                      {state.description?.length > 0 && <span style={{ color: state.description?.length > 5000 ? "red" : "#9ea2a5" }}> remaining characters  {5000 - state.description.length}</span>}
                    </h4>} 
                            <div className="form-group">
                                <TextareaAutosize
                                    maxRows={4}
                                    InputLabelProps={{ shrink: true }}
                                    placeholder="Description"
                                    aria-label="maximum height" 
                                    value={state.description}
                                    onChange={(e) => setState({ ...state, ['description']: e.target.value })}
                                    style={{ width: '100%', height: 100 }}
                                />
                            </div>
                        </div>

                    </Row>
                    <Row>
                        <div className="col-lg-6 mt-4">
                            <div className="form-group">
                                <FormControl size="small" className={classes.root}>
                                    <TextField
                                        id="filled-password-input"
                                        InputLabelProps={{ shrink: true }}
                                        label="Image Url"
                                        type="text"
                                        value={urlError == null ?state.image:urlError}
                                        error ={urlError != null ? true:false}
                                        onChange={(e) =>{
                                            setUrlError(null)
                                            setState({ ...state, ['image']: e.target.value })}
                                        } 
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4">
                            <div className="form-group">
                                <FormControl size="small" className={classes.root}>
                                    <TextField
                                        id="filled-password-input"
                                        InputLabelProps={{ shrink: true }}
                                        label="Zoom meeting url"
                                        type="text"

                                        value={zoomUrlError == null ?state.zoomUrl:zoomUrlError}
                                        error ={zoomUrlError != null ? true:false} 
                                        onChange={(e) =>{
                                            setZoomUrlError(null)
                                        setState({ ...state, ['zoomUrl']: e.target.value })}
                                        }                                        
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </Row>


<Row >
<Col className="col-lg-6 mt-4" style={{ display: 'flex'  }}>
        <div style={{ width: '100%', display:"flex"}}>

<div>
          <Typography>Mandatory</Typography>  
          </div>
          <div style={{display:"flex", flexDirection:"column", marginLeft:"4rem"}}>
       <span><Checkbox
               checked={state.mandatoryall}
               color="warning"
               onChange={(e) => setState({ ...state, ['mandatoryall']: e.target.checked ? true : false })}
               inputProps={{ 'aria-label': 'controlled' }}
               /> all</span> 

             <span> <Checkbox
               checked={state.mandatorybuy}
               color="warning"
               onChange={(e) => setState({ ...state, ['mandatorybuy']: e.target.checked ? true : false })}
            inputProps={{ 'aria-label': 'controlled' }}
           />buy</span>  

             <span> <Checkbox
               checked={state.mandatorysta}
               color="warning"
               onChange={(e) => setState({ ...state, ['mandatorysta']: e.target.checked ? true : false })}
            inputProps={{ 'aria-label': 'controlled' }}
           />sta</span>  

             <span> <Checkbox
               checked={state.mandatorypar}
               color="warning"
               onChange={(e) => setState({ ...state, ['mandatorypar']: e.target.checked ? true : false })}
            inputProps={{ 'aria-label': 'controlled' }}
           />par</span>   

             <span> <Checkbox
               checked={state.mandatoryspe}
               color="warning"
               onChange={(e) => setState({ ...state, ['mandatoryspe']: e.target.checked ? true : false })}
            inputProps={{ 'aria-label': 'controlled' }}
           />spe</span>   

          </div>  
          <div style={{display:"flex", flexDirection:"column", marginLeft:"4rem"}}>
                <span> <Checkbox
               checked={state.mandatorynon}
               color="warning"
               onChange={(e) => setState({ ...state, ['mandatorynon']: e.target.checked ? true : false })}
            inputProps={{ 'aria-label': 'controlled' }}
           />non</span>   

             <span><Checkbox
               checked={state.mandatoryven}
               color="warning"
               onChange={(e) => setState({ ...state, ['mandatoryven']: e.target.checked ? true : false })}
            inputProps={{ 'aria-label': 'controlled' }}
           />ven</span>   

             <span> <Checkbox
               checked={state.mandatoryvis}
               color="warning"
               onChange={(e) => setState({ ...state, ['mandatoryvis']: e.target.checked ? true : false })}
            inputProps={{ 'aria-label': 'controlled' }}
           />vis</span>   

             <span> <Checkbox
               checked={state.mandatoryjou}
               color="warning"
               onChange={(e) => setState({ ...state, ['mandatoryjou']: e.target.checked ? true : false })}
            inputProps={{ 'aria-label': 'controlled' }}
           />jou</span>   

          </div> 
        </div>
      </Col>

    
     <Col className="col-lg-6 mt-4" style={{ display: 'flex'  }}>
        <div style={{ width: '100%', display:"flex"}}>

<div>
          <Typography>Optional</Typography>  
          </div>
          <div style={{display:"flex", flexDirection:"column", marginLeft:"4rem"}}>
       <span><Checkbox
               checked={state.optionalall}
               color="warning"
               onChange={(e) => setState({ ...state, ['optionalall']: e.target.checked ? true : false })}
               inputProps={{ 'aria-label': 'controlled' }}
               /> all</span> 

             <span> <Checkbox
               checked={state.optionalbuy}
               color="warning"
               onChange={(e) => setState({ ...state, ['optionalbuy']: e.target.checked ? true : false })}
            inputProps={{ 'aria-label': 'controlled' }}
           />buy</span>  

             <span> <Checkbox
               checked={state.optionalsta}
               color="warning"
               onChange={(e) => setState({ ...state, ['optionalsta']: e.target.checked ? true : false })}
            inputProps={{ 'aria-label': 'controlled' }}
           />sta</span>  

             <span> <Checkbox
               checked={state.optionalpar}
               color="warning"
               onChange={(e) => setState({ ...state, ['optionalpar']: e.target.checked ? true : false })}
            inputProps={{ 'aria-label': 'controlled' }}
           />par</span>   

             <span> <Checkbox
               checked={state.optionalspe}
               color="warning"
               onChange={(e) => setState({ ...state, ['optionalspe']: e.target.checked ? true : false })}
            inputProps={{ 'aria-label': 'controlled' }}
           />spe</span>   

          </div>  
          <div style={{display:"flex", flexDirection:"column", marginLeft:"4rem"}}>
                <span> <Checkbox
               checked={state.optionalnon}
               color="warning"
               onChange={(e) => setState({ ...state, ['optionalnon']: e.target.checked ? true : false })}
            inputProps={{ 'aria-label': 'controlled' }}
           />non</span>   

             <span><Checkbox
               checked={state.optionalven}
               color="warning"
               onChange={(e) => setState({ ...state, ['optionalven']: e.target.checked ? true : false })}
            inputProps={{ 'aria-label': 'controlled' }}
           />ven</span>   

             <span> <Checkbox
               checked={state.optionalvis}
               color="warning"
               onChange={(e) => setState({ ...state, ['optionalvis']: e.target.checked ? true : false })}
            inputProps={{ 'aria-label': 'controlled' }}
           />vis</span>   

             <span> <Checkbox
               checked={state.optionaljou}
               color="warning"
               onChange={(e) => setState({ ...state, ['optionaljou']: e.target.checked ? true : false })}
            inputProps={{ 'aria-label': 'controlled' }}
           />jou</span>   
           
          </div>
        </div>
      </Col>
</Row>

                    <div className="text-center mt-4">
                        <button type="submit" className="btn btn-primary" onClick={() => save()}>
                            Save
                        </button>
                    </div>

                </Card>

            }

        </>

    )

}

const companyType = [
    { name: 'Vendor', id: '2' },
    // { name: 'Partially Completed', id: '2' },
    { name: 'Distributor', id: '3' },
    { name: 'Retailer', id: '4' },
    { name: 'Reseller', id: '5' }];

const hour = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
const min = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];


export default Agenda;