import React, { useEffect, useState , useRef } from 'react';
import { useForm} from "react-hook-form";
import { SelectAutocomplete } from '_components/FormElements/FormInput'
import { Card, CardBody, Container, Row, Col,Media,  CardHeader, UncontrolledTooltip } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import UserFilter from "components/Filter-Right/UserFilter.js";
import pagination from "views/Hoc/pagination.js";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import axios from 'axios';
import {Spinner} from '../../Hoc/Spinner'
import TablePagination from '@material-ui/core/TablePagination';
import {TablePaginationActions} from 'views/Hoc/PaginationActions';
import Drawer from 'views/Hoc/Dialog/Globaldrawer'
import { commonService } from '_services/common.service';

 // toggles collapse between mini sidenav and normal
 const toggleSidenav = e => {
 
  if (document.getElementById('filter').classList.contains("g-sidenav-pinned")) {
    document.getElementById('filter').classList.remove("g-sidenav-pinned");
    document.getElementById('filter').classList.add("g-sidenav-hidden");
  } else {
    document.getElementById('filter').classList.add("g-sidenav-pinned");
    document.getElementById('filter').classList.remove("g-sidenav-hidden");
  }
};

const company_type_Option =[
  { name: "Vendor", id: 2 },
  { name: "Distributor", id: 3 },
  { name: "Retailer", id: 4 },
];

export const Coupon = (props) => {

  const { control } = useForm({ mode:'onBlur' });
  
let [loading, setLoading]  = useState(true)
let [page, setPage] = useState(0);
let [rowsPerPage, setRowsPerPage] = useState(10);
const [showDrawer,setshowDrawer]=useState(false);

const [couponId,setCouponId]=useState();

const [state, setState ] = useState({dataTable:[], total:0});
const [searchState, setSearch] = useState({search:null, dropdownList:[]});
const [filterValueState, setFilterValue] = useState({Company_type:null,country:[]});
const [ filterOptionState, setFilterOption] = useState({countryOption:[],company_type_Option:company_type_Option});
  

// useEffect(() => {  
  
//   (async () => {
//     console.log("coupon");  
//   let coupons = await commonService.postService({},'channelAdmin/channelCoupons');  
//   state.dataTable = coupons.data.rows;
//   state.total = coupons.data.count;   
//   setState({ ...state });  
//   setLoading(false);
//   })();
// }, []);

const handleChangePage = (event, newPage) => {
    page=newPage;
    setPage(page);
    getCouponListing();
};

const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    setPage(0);
    getCouponListing();
};

useEffect(() => {
  getCouponListing();
    //getCountryList();     
    //getSearchList()
}, []);

const getCountryList = ()=>{
    axios.post('/admin/userCountry', {    
        }).then(res=>{          
          let ctyFilter=res.data.map(x => x.countryid);   
          filterOptionState.countryOption = ctyFilter;
          setFilterOption({...filterOptionState, countryOption:filterOptionState.countryOption});
        })
}

const getFilterData=()=>{
    let filter =[];
    if(filterValueState.country && filterValueState.country.length>0){
      filter.countryId=filterValueState.country.map(x=> parseInt(x.id));
    }
    if(filterValueState.Company_type){
      filter.channelTypeId=filterValueState.Company_type.id;
    }    
    return filter;
}
  
const getCouponListing = async ()=>{   
    loading = true;
    setLoading(loading);
   // var filter = await getFilterData();
   console.log("page:",page);
    let data = {
      // search: searchState.search?searchState.search[0]?searchState.search[0].id:null:null,      
      // filter : {
      //   countryId:filter.countryId?filter.countryId:null,
      //   channelTypeId:filter.channelTypeId?filter.channelTypeId:null,        
      // },
      page:page * rowsPerPage,
      limit:rowsPerPage
    }
    axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.post('/channelAdmin/channelCoupons',data
    ).then(res =>{
      //console.log("userlisting:",res.data);      
      state.dataTable = res.data.rows;
      state.total = res.data.count;
        loading = false;
        setLoading(loading);
        setState({ ...state });
    }).catch((err)=>{
      state.dataTable = [];
        state.total = 0;
        loading = false;
        setLoading(loading);
        setState({ ...state });
    })
}

const getSearchList = ()=>{
  axios.get('/admin/searchList').then(res =>{
    let data = [];    
    res.data.map(x=>{      
        data.push({name:x.fName.trim() + ' ' + x.lName.trim(), id:x.id});
      })
    
    //data.push(...contact);
    searchState.dropdownList = data
        setSearch({...state, dropdownList:searchState.dropdownList });
    })  
}

const handleChangeSelect = (input, value)=>{
  if(input=='search'){
    searchState.search= [value]
    setSearch({ ...searchState })
  }
  if(input=='Company_type'){
    filterValueState.Company_type= value
    setFilterValue({ ...filterValueState })
  }
  if(input=='country'){
    filterValueState.country= value
    setFilterValue({ ...filterValueState })
  }
  getCouponListing();
}

const resetFilterValue =()=>{
  filterValueState.Company_type=null; 
  filterValueState.country=[]; 
  setFilterValue({ ...filterValueState });
  getCouponListing();
}

const edit=(e)=>{
e.preventDefault();
console.log('e',e);
}

  console.log("text",showDrawer);
  const closeDrawer =()=>{
    setshowDrawer(false);
  }
const [sidenavOpen,setOpen] = useState(false);
  return (
    <div id="usermanage">
      <Drawer title="Add Coupon" visible={showDrawer} getCouponListing={getCouponListing} couponId={couponId} closeDrawer={closeDrawer}/>
      {/* <div className={classes.root}> */}
      <UserFilter 
        Filter={true}
        filterOption={filterOptionState}
        filterValue={filterValueState}
        resetFilterValue={resetFilterValue}
        handleChangeSelect={handleChangeSelect}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen} />
      
      <SimpleHeader name="admin-users" parentName="Dashboard" />
      
        <Container className="mt--3" fluid>
        
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Coupons Manage</h3>
                 </CardHeader>
                 {loading? <Spinner/>:<ToolkitProvider
                  data={state.dataTable}
                  keyField="Id"
                  columns={[                                          
                    { 
                      dataField: 'Id',
                      text: " Id",
                      sort: true,
                      formatter: (rowContent, row, rowIndex) => {
                        return(
                          <>                          
                          {rowIndex+1+(page*rowsPerPage)}                          
                          </>

                        )
                      }
                    },
                    {
                      dataField: "couponDesc",
                      text: " DESCRIPTION",
                      sort: true
                    },
                    {
                      dataField: "couponCode",
                      text: " Code",
                      sort: true
                    },
                    {
                      dataField: "couponOfferValue",
                      text: "Offers",
                      sort: true
                    },
                    {
                      dataField: "isActive",
                      text: "Active/Inactive",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return(
                          <>
                          {row.isActive==1?'ACTIVE':'INACTIVE'}                          
                          </>

                        )
                      }
                    },
                    {
                      dataField: "fromDate",
                      text: "From Date",
                      sort: true
                    },                    
                    {
                      dataField: "toDate",
                      text: "To Date",
                      sort: true,
                    },
                    {
                      dataField: "subscriptionPlanId",
                      text: "Subscription Plan/ Period",
                      sort: true,                      
                      formatter: (rowContent, row) => {
                        return(
                          <>
                          {row.subscriptionPeriodId==1&&row.subscriptionPlan.subscriptionPlanType+"/ M"}
                          {row.subscriptionPeriodId==2&&row.subscriptionPlan.subscriptionPlanType+"/ Y"}                          
                          </>

                        )
                      }

                    },
                    {
                      dataField: "channelTypeId",
                      text: "Channel Type",
                      sort: true,                      
                      formatter: (rowContent, row) => {
                        return(
                          <>
                          {row.channelTypeId==2 && 'VENDOR'}
                          {row.channelTypeId==3 && 'DISTRIBUTOR'}
                          {row.channelTypeId==4 && 'RETAILER'}
                          
                          </>

                        )
                      }
                    },
                    {
                      dataField: "Action",
                      text: "Action",
                      formatter: (rowContent, row, index) => {
                        return (    
                          <>
                         <i id={'Edit' + index}  onClick={()=>{setshowDrawer(true);setCouponId(row.couponId)}} class="fas fa-edit pointer">
                         <UncontrolledTooltip target={'Edit' + index} placement='bottom'> Edit </UncontrolledTooltip></i> 
                         
                        </>
                        )
                        }
                      }
                    
                   
                  ]}
                  search
                >
              {props => (
                    <div className="py-2 table-responsive">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 mb-2"
                      >
                <div style={{ width:'100%'}}>
                          <SelectAutocomplete
                            id="search"
                            type="text"                
                            label="search"
                            name="search"
                            className="d-inline-block"
                            style={{ width:'30%'}}
                            options={searchState.dropdownList}
                            placeholder="search..."
                            disableCloseOnSelect
                            // defaultValue={searchState.search?searchState.search[0]:null}
                            control={control}                         
                            input='search'
                            value={searchState.search?searchState.search[0]:null}
                            handleChangeSelect={handleChangeSelect}
                        /> 
                           <button onClick={()=>{setshowDrawer(true);setCouponId(0)}} className="btn btn-primary float-right">Create Coupon</button>
                        </div>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        // pagination={pagination}
                        bordered={false}
                        hover ={true}
                        rowClasses= 'pointer' 
                      />

                  <TablePagination
                        component="div"
                        count={state.total}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </div>
                  )}
                </ToolkitProvider>}

                
              </Card>
        
                            </Container>
    </div>
  );
};
export default Coupon;
