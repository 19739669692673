import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Moment from 'moment';
import {
  Button, Modal,Row,Col,Card
} from "reactstrap";
import { LaptopWindows } from '@material-ui/icons';
const Awardlist=(props)=> {
  
  const {open, handleClose, value } = props;
  //console.log(props)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
 

  return (
    <div>
      <Modal className="modal-dialog-centered" isOpen={open}>
          <div className="modal-header">
           <strong>Awards</strong>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body awd-bdy pt-0">
            <Card>
                {value.map(item=>(
                  <Row>
                  <Col lg='12' className='text-left p-0 mt-3'>
                      <div className='awd-list'>
                      <h4 style={{color:'#1d6373'}}>Award :<span className='ml-2' style={{color:'#525f7f'}}>{item.awardDetail}</span></h4>
                      <h4 style={{color:'#1d6373'}}>Issued by :<span className='ml-2' style={{color:'#525f7f'}}>{item.issuedBy}</span></h4>
                      <h4 style={{color:'#1d6373'}}>Valid upto :<span className='ml-2' style={{color:'#525f7f'}}>
                        {item?.isLifeTime==='1'?'Life Time': Moment(item?.validUpto).format('ll') }
                        </span></h4>
                      </div>
                  </Col>
                  </Row>
                ))}
            </Card>
          </div>
          <div className="modal-footer">
          {/* <Button color="primary" type="button" onClick={()=>handleSave(value)}>Ok</Button> */}
          <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
          </div>
        </Modal>
    </div>
  );
}
export default Awardlist;