
import React, { useEffect, useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Row,
  Col
} from "reactstrap";
import { history } from '_helpers';

let RouteName = {
	productDetail: 'Product Detail',
	channelDetail: 'Channel Detail',
	EditProduct: 'Edit Product',
	CloneProduct: 'Clone Product',
	channel: 'Suggestions',
	product: 'Product',
	favourites: 'Favourites',
	mynetwork: 'My Network',
	completeProfile: 'Company Profile',
	Myproductlist: 'My ProductList',
	notification: 'Notification',
	userform: 'User Form',
	Payment: 'Payment',
	Subscription: 'Subscription',
	Addcart: 'Add to Cart',
	checkout: 'Check Out',
	ordersuccess: 'Order Success',
	transaction: 'Transaction',
	profileform: 'Profile Form',
	edituser: 'Edit User',
	vendorDashboard: 'Vendor Dashboard',
	profileView: 'Profile View',
	userManage: 'User Manage',
	settings: 'Settings',
	profileSettings: 'Company Profile',
	companyprofileretres: 'Company Profile',
	companyprofiledist: 'Company Profile',

  companysettings: 'Company Settings',
  CompanyListing: 'Company Listing'
}


const TimelineHeader = (props)=> {
  let userData = JSON.parse(localStorage.getItem('CHuser'));
  let [name, setName] = useState(null);
  let [breadcrumbList, setBreadcrumbList] = useState([]);
  console.log("props", props)
  useEffect(() => {
    let params = props?.location?.params;
    if(params){
    let url = props.location.url;
    url = url.replace("/admin", "");
    if(params?.id){
      url = url.replace("/"+params?.id, "");
    }
    if(params?.cid){
      url = url.replace("/"+params?.cid, "");
    }
    if(params?.channelId){
      url = url.replace("/"+params.channelId, "-"+params.channelId);
    }
    if(params?.productId){
      url = url.replace("/"+params.productId, "-"+params.productId)
    }
    if(params?.eventId){
      url = url.replace("/"+params.eventId, "-"+params.eventId)
    }
    console.log('path', url);
    let path=url.split("/");
    path = path.filter(x=> x)
    name = path.pop();
    breadcrumbList = path;
    setName(name);
    setBreadcrumbList(breadcrumbList);
    console.log('path', path);
    }
},[props.location]);
   const  Openfilter= () => {
    if (document.getElementById('filter').classList.contains("g-sidenav-pinned")) {
      document.getElementById('filter').classList.remove("g-sidenav-pinned");
      document.getElementById('filter').classList.add("g-sidenav-hidden");
      document.getElementById('filter-hide').classList.add("filter-hide-width");
    } else {
      document.getElementById('filter').classList.add("g-sidenav-pinned");
      document.getElementById('filter').classList.remove("g-sidenav-hidden");
      //document.getElementById('filter-hide').classList.add("filter-show-class");
      document.getElementById('filter-hide').classList.remove("filter-hide-width");
      //document.getElementById('filter-hide').classList.remove("filter-hide-class");


       //document.getElementById('filter-hide').classList.remove("filter-hide-width");
    }
  }
  // render() {
    const goTo=(string)=>{
      console.log('string', string)
      string = string.replace('-', '/');
      let url = props.location.url.split(string);
      console.log('url', url);
      let from = '';
      let current = breadcrumbList.indexOf(string);
      if(current>=0 && breadcrumbList.length > current+1)
         breadcrumbList[current+1].includes('productDetail') ? from='product'  : from='channel';
      else
         name.includes('productDetail') ? from='product' : from='channel';
      console.log('from path', from, string, breadcrumbList[current], breadcrumbList[current+1], breadcrumbList.length, current);
      // if(string=='CompanyListing')
      //   history.push('/admin/CompanyListing');
      // else
        history.push(url[0]+string);
    }
    return (
      <>
      {/* pb-6 bg-info*/}
        <div className="header header-dark drk-blue content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  {/* <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                    {props.name}
                  </h6>{" "} */}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-lg-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem className="pointer">
                      <a onClick={e => history.push('/admin/dashboard')}>
                        <i className="fas fa-home" />
                      </a>
                    </BreadcrumbItem>

                   {breadcrumbList.length>0 && breadcrumbList.map(item=>(
                      <BreadcrumbItem className="pointer">
                      <a onClick={() => goTo(item)}>
                      {
                      RouteName[item.split('-')[0].replace(/[-_]/g,' ') == "CompanyListing" ? "Company Listing" : item.split('-')[0].replace(/[-_]/g,' ')] ?  RouteName[item.split('-')[0].replace(/[-_]/g,' ') == "CompanyListing" ? "Company Listing" : item.split('-')[0].replace(/[-_]/g,' ')] : item.split('-')[0].replace(/[-_]/g,' ') == "CompanyListing" ? "Company Listing" : item.split('-')[0].replace(/[-_]/g,' ')
                      }
                      {/* {item.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == "channel" && "Suggestions"}
                      {item.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'channeldetail' && 'Channel Detail'}
                      {item.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'productdetail' && 'Product Detail'}

                      {item.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'myproductlist' && 'My Products'}
                      {item.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'mynetwork' && 'My Network'}
                      {item.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'favourites' && 'Favourites'}
                      {item.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'editproduct' && 'Edit Product'}
                      {item.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'cloneproduct' && 'Clone Product'} */}
                      
                      </a>
                      </BreadcrumbItem>
                   ))
                   }
              
                    { name && 
                    <BreadcrumbItem aria-current="page" className="active">
                      {/* {name.split('-')[0].replace(/[-_]/g,' ') == "channel" ? "Suggestions" : name.split('-')[0].replace(/[-_]/g,' ')} */}

                      {
                      RouteName[name.split('-')[0].replace(/[-_]/g,' ') == "channel" ? "Suggestions" : name.split('-')[0].replace(/[-_]/g,' ')] ?  RouteName[name.split('-')[0].replace(/[-_]/g,' ') == "channel" ? "Suggestions" : name.split('-')[0].replace(/[-_]/g,' ')] : name.split('-')[0].replace(/[-_]/g,' ') == "channel" ? "Suggestions" : name.split('-')[0].replace(/[-_]/g,' ')
                      }

                      {/* {name.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == "channel" && "Suggestions"}
                      {name.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'channeldetail' && 'Channel Detail'}
                      {name.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'productdetail' && 'Product Detail'}

                      {name.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'myproductlist' && 'My Products'}
                      {name.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'mynetwork' && 'My Network'}
                      {name.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'favourites' && 'Favourites'}

                      {name.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'editproduct' && 'Edit Product'}
                      {name.split('-')[0].replace(/[-_]/g,' ')?.toLowerCase() == 'cloneproduct' && 'Clone Product'} */}
                    </BreadcrumbItem>}
                    
                  </Breadcrumb>
                </Col>
                {props.filter &&
                <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                  {/* <Button className="btn-neutral" color="default" size="sm">
                    New
                  </Button> */}
                  <Button onClick={Openfilter} className="btn-neutral" color="default" size="sm">
                    Filters
                  </Button>
                </Col>
  } {props.Ispublic &&
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                  <Button onClick={()=>history.push('/admin/CompanyListing/channelDetail/'+ userData.channelId)} className="btn-neutral" color="default" size="sm">
                    View Your Public Profile
                  </Button>
                </Col>
}
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  // }
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string
};

export default TimelineHeader;
