
export const LocalStorageService = (function(){
    var _service;

    function _getService() {
        if(!_service) {
          _service = this;
          return _service
      }
      return _service
    }

    function _setToken(token) {
      localStorage.setItem('fo-auth-token', token);      
    }
    function _setAppToken(token) {
        localStorage.setItem('fo-app-token', token);        
    }
    function _getAccessToken() {
      return localStorage.getItem('fo-auth-token');
    }
    function _getAppToken() {
        return localStorage.getItem('fo-app-token');
    }
    function _getRefreshToken() {
      return localStorage.getItem('refresh_token');
    }
    function _clearToken() {
      let appToken=localStorage.getItem('fo-app-token');      
      localStorage.clear();
      _setAppToken(appToken);
    }
    
   return {
      getService : _getService,
      setToken : _setToken,
      getAccessToken : _getAccessToken,
      setAppToken : _setAppToken,
      getAppToken : _getAppToken,
      getRefreshToken : _getRefreshToken,
      clearToken : _clearToken
    }
})();
   