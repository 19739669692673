import React, { useState,useEffect,useRef } from "react";
import { Card,CardBody,CardHeader, Container,Col,Row,Badge,UncontrolledTooltip} from "reactstrap";
import {FormRadio,FormCheckBox} from "_components/FormElements/FormInput";
import {InputLabel,Select,TextField,FormControl} from '@material-ui/core';
import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { AddFirstProductSchema } from '../validation';
import AccountDeletion from "views/Hoc/Dialog/AccountDeletion";
import ChangeContact from "views/Hoc/Dialog/ChangeContact";
import axios from 'axios';
import { dashboardActions } from '../../../../_actions';
import { Spinner} from 'views/Hoc/Spinner';
import { history } from '../../../../_helpers';
import { FeatureListDialog } from "views/Hoc/Dialog/FeatureDialog";
import { commonService } from '_services/common.service';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import SimpleHeader from "components/Headers/SimpleHeader"
var _ = require('lodash');

const Settings = (props) => {

    const { register, handleSubmit, control, errors,formState } = useForm({      
        mode:'onBlur',  
        //resolver: yupResolver(AddFirstProductSchema),
      });

    
    var userData = JSON.parse(localStorage.getItem('CHuser'))?.userData;
    // var userData = props.location.state.channelData;
    var typeid = userData.channelTypeId;
    let businessType = userData.isOEM;

    var userParams = {
        userId: userData.userId,
        channelId: userData.channelId
    }
    
            
    let companyType=[{id:2,name:'VENDOR',status:false},{id:3,name:'DISTRIBUTOR',status:false},{id:4,name:'RETAILER',status:false},{id:5,name:'RESELLER',status:false}];  

    if (typeid == 2) {
        if (businessType == 1)
            companyType = [{ id: 3, name: 'DISTRIBUTOR', status: true }, { id: 4, name: 'RETAILER', status: true }];
        if (businessType == 2)
            companyType = [{ id: 3, name: 'DISTRIBUTOR', status: true }, { id: 5, name: 'RESELLER', status: true }];
        if (businessType == 3)
            companyType = [{ id: 3, name: 'DISTRIBUTOR', status: true }, { id: 4, name: 'RETAILER', status: true }, { id: 5, name: 'RESELLER', status: true }];
    }
    
    const [state,setState]=useState({
        isVisible:'false',
        paymentOption:[],         
        paymentTerm:[], 
        currency:[], 
        visibleOption:companyType.filter(x => x.id!=typeid),
        contactName:'',
        jobTitle:'',
        country:'',   
        profileLogo:'',
        otherInfo:'',
        amount:'',        
        selectedCurrency:'1',
        rcamount:'',
        autoRenewal:true,
        featureList: [],
        userCount: null
    });

    const myRef = useRef();

      const [accDelete, setOpen] = useState(false);
      const [loading,setLoading] = useState(false);
      const [OpenContact, setOpenContact] = useState(false)
      const [OpenFeatureList, setOpenFeatureList] = useState(false)  

      let dashboard = props.location.state?.dashboard ? props.location.state?.dashboard : '';
      
      const openDelete=(e)=>{
          e.preventDefault();
        setOpen(true);
      }
      const closeOpen=()=>{
        setOpen(false);
      }
      const openContact=(e)=>{
        e.preventDefault();
        setOpenContact(true);
      }
      const closeContact=()=>{
        setOpenContact(false);
      }

      const openFeatureList=(e)=>{
        e.preventDefault();
        setOpenFeatureList(true);
      }
      const closeFeatureDialog=()=>{ 
        setOpenFeatureList(false);
      }

      const setDefaultContact =  input => async e => {              
        await axios.post('channelAdmin/setDefaultContact/'+e.target.value, userParams).then(async res => {
            await axios.post('channelAdmin/defaultcontact', userParams).then(res =>{           
                setState({ ...state, userCount: res.data.userCount, contactName: res.data.user.firstName + " " + res.data.user.lastName, jobTitle: res.data.user.jobTitle, country: res.data.user.country.country, profileLogo: res.data.user.document?.documentPath });        
            });
        });        
        setOpenContact(false)
      }

      const setDefaultFeatureDialog =  input => async e => {        
        var subscriptionId = JSON.parse(localStorage.getItem('CHuser')).subscriptionId;
        await axios.post('/channel/subscription/getPlansDetailsById/', { subscriptionId: +subscriptionId }).then(async res => {
            setState({ ...state, featureList:res.data }); 
        }).catch(err=> {
            commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'/channel/subscription/getPlansDetailsById'},'/error');                     
        });
      }
      
      const handleChange = input => e => {    
        // if(input=='amount')
        // {
        //     state.rcamount=e.target.value;
        //     setState({...state});
        // }  
        if(input=='autoRenewal')
        {
            if(state.autoRenewal==false)
                state.autoRenewal=true;
            else if(state.autoRenewal==true)
                state.autoRenewal=false
            setState({...state});
        }
        else if(input=='curr')
        {
            state.selectedCurrency=e.target.value;
            setState({...state});
        }
        else if(input=="L/C"||input=="T/T"||input=="D/D"||input=="Western Union"||input=="Wire Transfer"||input=="Others")      
        {
            console.log("poption:",state.paymentOption);
            state.paymentOption.map(x => {
                if(x.paymenttype==input&&x.status==false)
                    x.status=true;
                else if(x.paymenttype==input&&x.status==true)
                    x.status=false;                
            });
            setState({...state});
        }
        else if(input=="Net 30"||input=="Net 60"||input=="Cash In Advance"||input=="Cash On Delivery")      
        {
            state.paymentTerm.map(x => {
                if(x.paymentterm==input&&x.status==false)
                    x.status=true;
                else if(x.paymentterm==input&&x.status==true)
                    x.status=false;         
            });
            setState({...state});
        }
        else if(input=="VENDOR"||input=="DISTRIBUTOR"||input=="RETAILER"||input=="RESELLER")      
        {
            state.visibleOption.map(x => {
                if(x.name==input&&x.status==false)                
                    x.status=true;
                else if(x.name==input&&x.status==true)                
                    x.status=false;                
            });
            setState({...state});
        }
        else                
            setState({...state, [input] : e.target.value});         
      }

      //console.log("sstate:",state);

    useEffect(() => {

        try
        {
          setLoading(true);
          (async() => {            
            await axios.post('channelAdmin/defaultcontact', userParams).then(res =>{                           
                let user = res.data.user;
                state.contactName=user?.firstName+" "+user?.lastName;
                state.jobTitle=user?.jobTitle;
                state.country=user?.country.country;
                state.profileLogo=user?.document?.documentPath;
                state.userCount = res.data.userCount;                
            }).catch(err=> {
               commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'user/defaultcontact'},'/error');                     
            });

            await axios.get('channelAdmin/currency').then(res =>{                           
                console.log("payment:",res);
                state.currency=res.data;
            }).catch(err=> {
                commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'setting/currency'},'/error');                     
             });

            await axios.get('channelAdmin/getpaymentoption').then(async res =>{                                           
                let data=res.data?.paymentOption;let term=res.data?.paymentTerm;let poption=[];let pterm=[];
                let share=[];
                await axios.post('channelAdmin/settings', userParams).then(res =>{                           
                    let key=res.data?.paymentType?.map(x => +x.paymentoptiontypeid);                    
                    let key1=res.data?.paymentTerms?.map(x => +x.paymenttermid);                    
                    let key2=res.data?.share?.map(x => +x.sharetypeid+1);                    
                    state.isVisible=res.data.shareStatus? res.data.shareStatus?.toString() : state.isVisible;
                    state.plan=res.data.channelSubscription.plan;
                    let planId = res.data.channelSubscription.planId;
                    state.userWalletAmount = res.data.userWalletAmount;
                    state.endat= (planId === 1)?'Lifetime':res.data.channelSubscription.endat;
                    let cur=res.data.currencyId?.toString();
                    let renewal=res.data.autoRenewalOff;                    
                    
                    data.map(x => {
                        key.map(y => {                            
                            if(y==+x.paymentoptiontypeid)
                                x.status=true;
                        })
                        poption.push(x);
                    });

                    term.map(x => {
                        key1.map(y => {                            
                            if(y==+x.paymenttermid)
                                x.status=true;
                        })
                        pterm.push(x);
                    });

                    state.visibleOption.map(x => {
                        if(key2.filter(y => y==x.id).length)
                            x.status=true;
                        else
                            x.status=false;
                        // key2.map(y => {
                        //     if (y == +x.id)
                        //         x.status = true;
                        // })
                        share.push(x);
                    });

                    
                    setState({...state,paymentOption:poption,paymentTerm:pterm,visibleOption:share,isVisible:state.isVisible,selectedCurrency:cur,otherInfo:res.data.otherOptions,autoRenewal:renewal})
                }).catch(err=> {
                    commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'setting/settings'},'/error');                     
                });   
                
            }).catch(err=> {
                commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'setting/getpaymentoption'},'/error');                     
            });                

            setLoading(false);

             if(!loading && dashboard)
                        myRef.current.scrollIntoView({ behavior: 'smooth' });
            
          })();
        }
        catch(err)
        {   
          //  console.log("settingerr:",err);
         // ToastsStore.error('User Product Not Found!');
          commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'setInitialData'},'/error');       
        }
          
    }, []);

    // useEffect(() => {  
    //     if(!_.isEmpty(errors)&&formState.isSubmitted)
    //     {
    //       ToastsStore.error(JSON.stringify(errors));
    //       commonService.postService({err:JSON.stringify(errors),reqUrl:window.location.pathname,service:'validation_err'},'/error')    
    //     }
    //   }, [errors])
      

      const save = async() =>{                  
        try
        {        
        setLoading(true);

        if(!state.selectedCurrency || state.selectedCurrency=='Choose your currency'){
            throw "currency"
        }

        // var userData = JSON.parse(localStorage.getItem('CHuser'));j

        const paymentTerms = state.paymentTerm.reduce((a, o) => (o.status && a.push({paymenttermid:+o.paymenttermid}), a), [])
        
        const paymentOption = state.paymentOption.reduce((a, o) => (o.status && a.push({paymentoptiontypeid:+o.paymentoptiontypeid}), a), [])

        const share = state.visibleOption.reduce((a, o) => (o.status && a.push({sharetypeid:+o.id}), a), [])

        //console.log("paymentTerms:",paymentTerms);

        let finalData={
            channelType: +userData?.channelTypeId,
            currencyId: +state.selectedCurrency,
            // otherOptions: state.otherInfo,
            // paymentTerms: paymentTerms,
            // paymentType: paymentOption,
            //sampleRequests: [{preferencetypeid: 1}]
            share: share,
            shareStatus: (state.isVisible==='true'),
            user: userParams
        }
        //console.log("result:",finalData);
        
        let result=await dashboardActions.updateSettingInfo(finalData).catch(err=> {
            ToastsStore.error('Something Went Wrong!');
            setLoading(false);    
            commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'updateSettingInfo'},'/error');                     
        });
    
        if(result) 
          setLoading(false);
        }
        catch(err)
        {
            if(err=='currency')
            ToastsStore.error('Please Select Default Currency!');
            else
            ToastsStore.error('Something Went Wrong!');
            setLoading(false);    
            commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'updateSettingInfo'},'/error');                     
        }
        
    }      

    return (
        <div className="main-content">
            <SimpleHeader location={props.match}/>
        <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
        {loading? <Spinner />:(
           <Container className="mt-4 col-lg-10 col-md-9 col-sm-12  " fluid>
           <form noValidate onSubmit={handleSubmit(save)}>

               {/* <Card>
                   <CardHeader>
                    <h3 className="text-center">Payment Options</h3>
                    <p className="text-center">Enter the various payment options you accept when trading with your channel partners</p>
                   </CardHeader>
                   <CardBody>
                   <Col lg="12">
                       <h4>Payment Type</h4>
                       <Row>
                           <Col lg="10">
                               <Row>

                            {state.paymentOption.map(x => (
                               <Col lg="2">
                               <div className="chck">
                                    <FormCheckBox
                                        id={x.paymenttype}
                                        type="checkbox"
                                        label={x.paymenttype}
                                        checked={x.status}
                                        label1={{textAlign:"justify"}}
                                        onClick={handleChange(x.paymenttype)}
                                        //register={register}
                                    />
                                </div>
                               </Col>
                               ))}                            
                               
                               </Row>
                           </Col>
                       </Row>
                       </Col>
                    <Col lg="12">
                       <h4 className="mt-4">Payment Terms</h4>
                       <Row>
                           <Col lg="10">
                               <Row>
                            {state.paymentTerm.map(x => (
                               <Col lg="3">
                               <div className="chck">
                                    <FormCheckBox
                                        id={x.paymentterm}
                                        type="checkbox"
                                        label={x.paymentterm}
                                        checked={x.status}
                                        label1={{textAlign:"justify"}}
                                        onClick={handleChange(x.paymentterm)}
                                        //register={register}
                                    />
                                </div>
                               </Col>
                            ))}

                               
                               </Row>
                           </Col>
                       </Row>
                       </Col>
                       <Row>
                            <Col lg="12">
                            <div className="form-group mt-4">            
                                <form>
                                <textarea 
                                className="settingstext1" 
                                id="Other Information" 
                                onChange={handleChange('otherInfo')}
                                rows="3" 
                                value={state.otherInfo}
                                aria-label="empty textarea"
                                placeholder="Other Information">
                                </textarea>
                                </form> 
                            </div>
                            </Col>
                       </Row>
                   </CardBody>
               </Card> */}
           
               {/* <Card>
                    <CardHeader>
                    <h3 className="text-center">Stripe Details</h3>
                    <p className="text-center">Stripe is our third-party payment gateway. Add or create your stipe account for receiving direct payments samples orders you'll get through ChannelHub. ChannelHub is not taking any commission on these payments</p>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col lg="4"></Col>
                            <Col lg="4">
                            <div className="text-center">
                                <button type="submit" className="btn btn-primary stripe" >Connect with Stripe</button> 
                            </div>  
                            </Col>
                            <Col lg="4"></Col>
                        </Row>
                    </CardBody>
                </Card> */}
                <Card>
                    <CardHeader>
                    <h3 className="text-center" ref={myRef}>E-Wallet</h3>
                    <p className="text-center">Use your e-wallet to recharge credit that you can use for various options on your account.</p>
                    {/* <p className="text-center">Stripe is our third-party payment gateway. Add or create your stipe account for receiving direct payments samples orders you'll get through ChannelHub. ChannelHub is not taking any commission on these payments</p> */}
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col lg="6">
                            <h4>Current balance</h4>
                            <h4> {state.userWalletAmount} <i class="fas fa-euro-sign"></i></h4>
                            </Col>
                            <Col lg="3">
                            {/* <div className="text-center">
                            <TextField className="comp-name" onChange={handleChange('amount')}  variant="outlined" label="Enter Amount for recharge" placeholder="" on />
                            </div>   */}
                            </Col>
                            {/* <Col lg="3" className="mt-2">
                            <div className="text-center">
                              <a href="#" className="ml-4" onClick={()=>history.push('/admin/wallet/' )}>
                                <button type="submit" className="btn btn-primary stripe" >Recharge</button> 
                                </a>
                            </div>  
                            </Col> */}
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader>
                        <h3 className="text-center">Account Settings</h3>
                        <p className="text-center">Add setting and preferences to your account.</p>
                    </CardHeader>
                    <CardBody>
                        <Col lg="6">
                        <h4 className="mt-4">Default Currency</h4>
                        <FormControl variant="outlined" style={{width:"100%",marginTop:"1rem"}}>
                        <InputLabel htmlFor="outlined-age-native-simple">Default Currency</InputLabel>
                        <Select
                        native
                        value={state.selectedCurrency}
                        //value={state.age}
                        onChange={handleChange('curr')}
                        label="Default Currency *"
                        inputProps={{
                            name: 'Default Currency',
                            id: 'Default Currency',
                        }}

                        >
                        <option aria-label="None" value="Choose your currency" />
                        {state.currency?.map(x => (
                        <option value={x.currencyid}>{x.currency}</option>                        
                        ))}
                        </Select>
                        </FormControl>
                        </Col> 
                        <Row>
                            <Col lg="6">
                            <h4 className="mt-4">My Subscription Plan</h4>
                            <Badge className='outer_badge' style={{width:"30%",height:"2rem",padding:"0.6rem"}}>
                            {state.plan}
                          </Badge>
                          {/* {typeid == 2?<a href="#" className="ml-4" onClick={()=>history.push('/admin/Subscription')}>Upgrade Plan</a>:''} */}
                          
                            </Col>
                            <Col lg="3">
                            <h4 className="mt-4">Account Expiry Date</h4>
                            <p>{state.endat}</p>
                            </Col>

                            {/* <Col lg="3">
                                
                                <a className="ml-6" href="#" onClick={e=>openFeatureList(e)} style={{color:"#30a2e4"}}>
                                    Feature Value
                                </a>
                                <FeatureListDialog closeFeatureDialog={closeFeatureDialog} Open={OpenFeatureList} setDefaultFeatureDialog={setDefaultFeatureDialog} userParams={userParams}/>

                            </Col> */}

                        </Row> 
                        <Row>
                            <Col lg="6">
                            <h4 className="mt-4">Account Status</h4>
                            <Badge style={{width:"30%",height:"2rem",padding:"0.6rem",color:"#fff",backgroundColor:"#2c9db7"}}>
                            ON
                          </Badge>
                          <button onClick={e=>openDelete(e)} type="submit" className="btn btn-warning ml-4" >Delete Account</button>
                    
                          <AccountDeletion Open={accDelete} closeOpen={closeOpen} />
                    
                          
                            </Col>
                            <Col lg="6">
                            <h4 className="mt-4">Auto Renewal</h4>
                            <label className="custom-toggle custom-toggle-success">
                                    <input defaultChecked={state.autoRenewal} type="checkbox" name="status" onChange={handleChange('autoRenewal')} />
                                <span className="custom-toggle-slider rounded-circle" data-label-off="Inactive" data-label-on="Active"></span>
                            </label>
                            </Col>
                        </Row>
                        <Row>
                        <Col lg="6">
                        <h4 className="mt-4">Default contact person on your company profile</h4>
                        <Row>
                            <Col lg="2" className="pad-less">
                                <div className="set-prof">
                                    <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={state.profileLogo?process.env.REACT_APP_CDN_URL+state.profileLogo:process.env.REACT_APP_CDN_URL+'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />
                                </div>
                            </Col>
                            <Col lg="10">
                                <h4>{state.contactName}</h4>
                                <span>{state.jobTitle}</span><br/>
                                <span>{state.country}</span>
                    
                                {state.userCount > 1 &&
                                <a className="ml-6" href="#" onClick={e=>openContact(e)} style={{color:"#30a2e4"}}>Change</a>}
                              
                                <ChangeContact closeContact={closeContact} Open={OpenContact} setDefaultContact={setDefaultContact} userData={userData}/>
                              
                            </Col>
                        </Row>                        
                        </Col> 
                        </Row>
                        <Row>
                        <Col lg="6">
                        <h4 className="mt-4">Visibility of your Company <span id='algorithm'><i class="far fa-handshake didy"></i></span>
                   <UncontrolledTooltip className="handshak" delay={0} delay={0} placement="bottom" target="algorithm">This field is included in the matching algorithm</UncontrolledTooltip></h4>
                        <p>Do you wish to share your Public Company Profile with other Members?</p>
                        <div className="form-group">        
                        <FormRadio
                            name="isVisible"
                            id="isVisible"
                            className="text-sm mb-0"
                            label=""
                            options={radioGroupOptions3}
                            control={control}
                            value={state.isVisible}
                            //defaultValue={state.isVisible}
                            row
                            onChange={handleChange('isVisible')}
                        />

                        </div> 

                        {/* {state.isVisible=='true' && ( <> */}
                        {typeid == 2 && (<>
                            <p>Suggestion you wish to see</p>
                        {state.visibleOption.map(x => (
                        <div className="chck">
                                    <FormCheckBox
                                        id={x.name}
                                        type="checkbox"
                                        label={x.name}
                                        checked={x.status}
                                        label1={{textAlign:"justify"}}
                                        onClick={handleChange(x.name)}
                                        //register={register}
                                    />
                                </div>
                            ))}

                                </>)}
                        </Col> 
                        </Row> 
                        <div className="text-center">
        <button type="submit" className="btn btn-primary" >Save</button> 
          </div>                 
                    </CardBody>
                </Card>
      
                </form>
           </Container>
        )}
        </div>
    );
};
const radioGroupOptions3 = [
    {
      value: 'true',
      label: "Yes",
    },
    {
      value: 'false',
      label: "No",
    },];

export default Settings;