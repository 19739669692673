import React, {useEffect, useState} from 'react';
import {Card,CardBody,Row,Col} from "reactstrap";
import {TextField} from '@material-ui/core';
import {Spinner} from 'views/Hoc/Spinner';
import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { updateContact } from '../pages/profile/validation';
import {FormAutocomplete, FormInput} from "_components/FormElements/FormInput";
import axios from 'axios';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { commonService } from '_services/common.service';

const CreateUser = (props) => {
    const { contactData, saved } = props;
    const [loading, setLoading] = useState(true);
    let [mail, setMail] = useState(null);
    const [checkMail, setCheckMail] = useState(0);
    const [checkContactHubspot, setCheckContactHubspot] = useState(0);
    const [checkContactHubspotRes, setCheckContactHubspotRes] = useState(null);

    
    

    const [state, setState] = useState({
      firstName:null,
      lastName:null,
      email: '',
      country: '',
      phone: '',
      mobileNo: '',
      jobTitle: '',
      reportingTo: null,
      contactHubspotId:''
    });

    const [stateList, setStateList] = useState({ 
      countryList:[],
      reportingList:[]
    })

      const { register, handleSubmit, control, errors, watch,setValue  } = useForm({      
        mode:'onBlur',
        resolver: yupResolver(updateContact),
      });
      const watchAll = watch();
      const handleChangeSelect = (input, value) => {
        setState({...state, [input]:value});
      }
      const handleChange = (input)=> (e) => {
        setState({ ...state, [input]: e.target.value });
      };
      let adminUser = JSON.parse(localStorage.getItem('CHuser'));
      useEffect(()=>{
        (async() => {
        setLoading(true);
        setCheckMail(0);
        let reporting = await axios.post('/channelAdmin/reporting', {channelId: props.contactData.channelId, userId:props.contactData.user.userId}).catch(err => setLoading(false));
        let countrylist= await axios.post('/channelAdmin/getFilterCountry', {
          signupstatusid:[14,15],
          userId:adminUser.userId
          });
          stateList.countryList = countrylist.data;
          setStateList({...stateList });
        let reportTo = [];
        if(reporting){
          stateList.reportingList = reporting.data;
          setStateList({...stateList});
          if(reportTo.length){
            reportTo = stateList.reportingList.find(x => x.id == props.contactData.rptContactId);
            state.reportingTo= reportTo;
            console.log('re[pr', reportTo);
          }
          state.firstName=contactData?.firstName;
          state.lastName=contactData?.lastName;
          state.email= contactData?.email;
          state.contactHubspotId = contactData?.hubspotContactId;
          mail = contactData?.email
          setMail(mail);
          let country = stateList.countryList.find(x => parseInt(x.id) === parseInt(contactData.countryId));
          console.log('country', country);
          if(country)
          state.country = country;
          console.log('stateList',stateList);
          state.phone= contactData?.phone1;
          state.mobileNo= contactData?.mobileNo;
          state.jobTitle= contactData?.jobTitle;
          setState({...state});
          setLoading(false);
          validateHubspotId();
        }else{
          // setState({...props.contactData, reporting: [], reportingTo: reportTo});
        }})();
      }, [props.contactData])
    
      console.log('state', state);
      const save = async () => {
        if (state.email.trim() !== mail.trim()) {
          let testMail = await commonService.postService({ userId: props.contactData.user.userId }, 'user/checkusermail/' + state.email);
          if (testMail?.data) {
            if (!testMail.data.isavialble) {
              setCheckMail(1);
              setLoading(false);
              return false;
            } else { setCheckMail(0); }
          } else
            setCheckMail(1);
        } else
          setCheckMail(0);
        await axios.patch('channelAdmin/updateContact/' +  props.contactData.user.contactId, {...state, rptContactId: parseInt(props.contactData.user.role.roleId)===1?null:state.reportingTo?.id ? state.reportingTo?.id : null}).then(async res => {
          ToastsStore.success('Saved Successfully!');
          axios.get('algorithm/channel/' + props.contactData.user.contactId);
          // if (typeId != 2) {
          //   await axios.get('algorithm/product/' + props.contactData.user.contactId);
          //   axios.post('mailerservice/Product_Suggestion_Mail', { contactId: props.contactData.user.contactId, channelId: props.contactData.channelId });
          // }
          saved();
        }).catch(err => {
          ToastsStore.error('Something Went Wrong!');
          setLoading(false);
          commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'updateContact' }, '/error');
        });
      }

      const validateHubspotId = () => {
        if(state.contactHubspotId != null){
            axios.get('/hubspot/checkContactById/'+ state.contactHubspotId).then(res1 => {
              if(res1.data){
                if(res1.data.status == 'error'){
                  setCheckContactHubspot(1)
                  setCheckContactHubspotRes(res1.data.message)
                } else {
                  setCheckContactHubspot(0)
                  setCheckContactHubspotRes(null)
                }
              }
            });
        } else {
            axios.post('/hubspot/checkContactByEmail',{'contactData': props.contactData , 'email' : state.email} ).then(res1 => {
              if(res1.data){
                if(res1.data.vid){
                  document.getElementById('contactHubspotId').value = res1.data.vid;
                  document.getElementById('contactHubspotId').focus(); 
                  state.contactHubspotId = res1.data.vid;
                  setState({...state});
                  
                } 
              }
            });
        }
      }
    return (
        <div>
          <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
          {loading ? <Spinner /> : 
            <Card>
                <CardBody>
                <form className="coupon-form"  noValidate onSubmit={handleSubmit(save)} autoComplete="off">
                <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="roleId" ref={register} name="roleId" value={parseInt(props.contactData.user.role.roleId)} control={control} />
                <Row>
                    <div className="col-lg-6 mt-4">
                      <div className="form-group">
                        <FormInput
                          id="firstName"
                          type="text"
                          name="firstName"
                          label="First Name *"
                          register={register}
                          defaultValue={state.firstName}
                          error={errors.firstName}
                          value={watchAll.firstName}
                          onChange={handleChange('firstName')}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                      <div className="form-group">
                        <FormInput
                          id="lastName"
                          type="text"
                          name="lastName"
                          label="Last Name *"
                          //placeholder="Password"
                          register={register}
                          error={errors.lastName}
                          value={watchAll.lastName}
                          defaultValue={state.lastName}
                          onChange={handleChange('lastName')}
                        />
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <FormInput
                          id="email"
                          type="text"
                          label="Contact Email"
                          name="email"
                          control={control}
                          register={register}
                          error={errors.email ? errors.email : checkMail ? { message: "Email Id already taken", required: true } : ''}
                          defaultValue={state.email}
                          value={watchAll.email}
                          onChange={handleChange('email')}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <FormAutocomplete
                          id="country"
                          name="country"
                          label="Country"
                          options={stateList.countryList}
                          placeholder="Select Country"
                          // multiple
                          disableCloseOnSelect
                          blurOnSelect
                          limitTags={1}
                          control={control}
                          input='country'
                          value={state.country}
                          handleChangeSelect={handleChangeSelect}
                          errMessage={'Please Select Country'}
                          error={errors.country}
                          defaultValue={state.country}
                        />
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-lg-6 code">
                      <div className="form-group">
                        <FormInput
                          className="comp-name12"
                          id="phone"
                          type="text"
                          label="Contact Phone Number"
                          name="phone"
                          register={register}
                          error={errors.phone}
                          defaultValue={state.phone}
                          value={watchAll.phone}
                          onChange={handleChange('phone')}
                        />
                        {/* </InputGroup> */}
                      </div>
                    </div>
                    <div className="col-lg-6 code">
                      <div className="form-group">
                        <FormInput
                          id="mobile"
                          className="comp-name12"
                          type="text"
                          label="Mobile Number *"
                          name="mobile"
                          register={register}
                          error={errors.mobile}
                          defaultValue={state.mobileNo}
                          value={watchAll.mobile}
                          onChange={handleChange('mobileNo')}
                        />
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <FormInput
                          id="jobposition"
                          type="text"
                          label="Job Position"
                          name="jobposition"
                          control={control}
                          register={register}
                          error={errors.jobposition}
                          defaultValue={state.jobTitle}
                          value={watchAll.jobposition}
                          onChange={handleChange('jobTitle')}
                        />
                      </div>
                    </div>
                    {parseInt(props.contactData.user.role.roleId)!==1 && <div className="col-lg-6">
                      <div className="form-group">
                        <FormAutocomplete
                          id="reportingTo"
                          type="text"
                          label="Reporting To"
                          name="reportingTo"
                          options={stateList.reportingList}
                          disableCloseOnSelect
                          blurOnSelect
                          limitTags={1}
                          control={control}
                          input='reportingTo'
                          value={state.reportingTo}
                          handleChangeSelect={handleChangeSelect}
                          errMessage={'Please Select Reporting Id'}
                          error={errors.reportingTo}
                          defaultValue={state.reportingTo}
                        />

                      </div>
                    </div>}
                  </Row>
                  <Row>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <FormInput
                          id="contactHubspotId"
                          type="text"
                          name="contactHubspotId"
                          label="Contact Hubspot Id *"
                          register={register}
                          defaultValue={state.contactHubspotId}
                          error={errors.contactHubspotId ? errors.contactHubspotId : checkContactHubspot ? { message: checkContactHubspotRes, required: true } : ''}
                          value={watchAll.contactHubspotId}
                          onChange={handleChange('contactHubspotId')}
                        /> 
                       
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                       <a href="#" onClick={handleSubmit(validateHubspotId)}>Check In HubSpot API  </a>                      
                      </div>
                    </div>

                  </Row>
                    {/* <Row>
                    <Col lg="6" className="mt-4">
                        <TextField id="outlined-basic" label="Channel Type" variant="outlined"
                         value={state.channelTypeId == '2' ? 'Vendor' : state.channelTypeId == '3' ? 'Distributor' : state.channelTypeId == '4' ? 'Retailer' : ''}/>
                        </Col>
               
                    </Row> */}

                    <div className="text-center mt-4">
            <button type="submit" className="btn btn-primary" >Save</button>
            {/* <button className="btn btn-warning" >Cancel</button> */}
          </div> 
                </form>
                </CardBody>
            </Card>}
        </div>
    );
};

export default CreateUser;