import React, { useState } from "react";
import { Card,Button, CardBody, Container,CardHeader} from "reactstrap";
import { useForm } from "react-hook-form";
import {FormInput, FormAutocomplete, FormRadio} from "_components/FormElements/FormInput";
import { FormAutocompleteGrouping, SingleAutocomplete } from '../../../../_components/FormElements/FormInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserProfileSchema } from '../validation';
import {useBeforeFirstRender} from '_hooks/index'
import ImageLibrary from 'views/Hoc/Dialog/ImageLibrary';
import axios from 'axios';
import { history } from '../../../../_helpers';
import { dashboardActions } from '../../../../_actions';
import {ModalSpinner} from 'views/Hoc/Spinner';
import {InputLabel,Select,TextField,FormControl} from '@material-ui/core';
import Row from "reactstrap/lib/Row";


const MyProfile = (props) => {
  
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, control, errors } = useForm({      
    mode:'onBlur',  
    resolver: yupResolver(UserProfileSchema),
  });
const [state, setState] = useState({
  
  sellingCountries : [],
  sellingCountriesOption : [],
  targetCountries : [],
  targetCountriesOption : [],

  productValue : [],
  productOptions : [],
  documentId : '',
  suggestions : '1',
  networkInvitation : '1',
  companyLogo: ''
});

  //localStorage
  var userData = JSON.parse(localStorage.getItem('CHuser'));

   const selectedItem=(gname,check,optionName,optionValue) => {    
    let opt=[];    
    console.log("check:",check,gname);
    if(check==true)
    {    
      state[optionName].map((item) => {      
        console.log("groupname:",item.groupname);
            if(item.groupname==gname||item.status==true)
            {
              item.status=check;      
              opt.push(item);
            }     
      });
    }
    if(check==false)
    {  
      console.log("check:",check);
      state[optionName].map((item) => {      
          if(item.groupname==gname&&state[optionValue].length>0)
              item.status=check;                          
          // if(state.productValue.length==0)
          //     item.status=false;
      });

      opt=state[optionValue].filter((item) => item.groupname!=gname);      
      opt.map(option => option.status=true);
    }
    
    state[optionValue]=opt;
    setState({...state, [optionValue]:state[optionValue]});    
    setState({...state, [optionName]:state[optionName]});    

    if(optionValue='sellingCountries' && state.sellingCountries.length==0)
        errors.selling=true;
    else if(optionValue='targetCountries' && state.targetCountries.length==0)
        errors.target=true;
    else if(optionValue='productValue' && state.productValue.length==0)
        errors.productCat=true;
    

  }

useBeforeFirstRender(() => {
  (async() =>{  
await axios.get('setting/getUserProfile/'+userData?.channelId).then(res =>{      
  state.productOptions=res.data.productGroup;
  state.sellingCountriesOption=res.data.regions;
  state.targetCountriesOption=res.data.regions;


  setState({...state, targetCountriesOption:state.targetCountriesOption});
  setState({...state, productOptions:state.productOptions});
  setState({...state, sellingCountriesOption:state.sellingCountriesOption});
  
})
  })();
  
})

const getCompanyLogo = (resImagePath) => {
  
  setState({...state, companyLogo: resImagePath.document.documentUrl, documentId : resImagePath.documentId});
  
};

const handleChange = input => e => {
  setState({...state, [input] : e.target.value});
}

const handleChangeSelect = (input,value) => {      
  
  if(input=='product'){
    state.productOptions.map((item) => {      
      if(value.length==0)
        item.status=false;
      else
      {
        // value.map(v => {          
        //   if(v.childname!=item.childname)
        //     item.status=false;                 
        //   else if(v.childname==item.childname)            
        //     item.status=true;                       
          
        // });      
        if(value.filter(e => e.childname == item.childname).length)
              item.status = true;
            else
              item.status = false;
      }
    });

    value.map(option => option.status=true);
    state.productValue= value
    setState({...state, productOptions:state.productOptions});
    setState({...state, productValue:state.productValue});

    if(state.productValue.length==0)
      errors.productCat=true;
  }
  else if(input=='sellingCountries'){
    state.sellingCountriesOption.map((item) => {      
      if(value.length==0)
        item.status=false;
      else
      {
        // value.map(v => {          
        //   if(v.childname!=item.childname)
        //     item.status=false;                 
        //   else if(v.childname==item.childname)            
        //     item.status=true;                       
          
        // });      
        if(value.filter(e => e.childname == item.childname).length)
              item.status = true;
            else
              item.status = false;
      }
    });

    value.map(option => option.status=true);
    state.sellingCountries= value
    setState({...state, sellingCountries:state.sellingCountriesOption});
    setState({...state, sellingCountries:state.sellingCountries});

    if(state.sellingCountries.length==0)
      errors.selling=true;
  }
  else if(input=='targetCountries'){
    state.targetCountriesOption.map((item) => {      
      if(value.length==0)
        item.status=false;
      else
      {
        // value.map(v => {          
        //   if(v.childname!=item.childname)
        //     item.status=false;                 
        //   else if(v.childname==item.childname)            
        //     item.status=true;                       
          
        // });      
        if(value.filter(e => e.childname == item.childname).length)
              item.status = true;
            else
              item.status = false;
      }
    });

    value.map(option => option.status=true);
    state.targetCountries= value
    setState({...state, targetCountriesOption:state.targetCountriesOption});
    setState({...state, targetCountries:state.targetCountries});

    if(state.targetCountries.length==0)
      errors.target=true;
  }
  // else if(input=='sellingRegion'){
  //   state.sellingRegion= value
  //   setState({...state, sellingRegion:state.sellingRegion});
  // }
  if(input!='product'&&input!='sellingCountries'&&input!='targetCountries')
   setState({...state, [input]:[value]});
}


const save = async() => {
  setLoading(true);
  let data = {
  // sellingRegion : state.sellingCountries.map(x => +x.regionCountryJCTId),
  // targetRegion : state.targetCountries.map(x => +x.regionCountryJCTId),  
  companyLogo:state.documentId,
  productGroup : state.productValue.map(x => +x.groupid),
  productCat: state.productValue.map(x => +x.childid),
  suggestions : +state.suggestions,
  networkInvitation : +state.networkInvitation,
  ratingId: +props.ratingId,
  contactId:userData?.contactId,
  channelId:userData?.channelId
  };

  if(typeId==4||typeId==3)      
        Object.assign(data,{sellingRegion : state.sellingCountries.map(x => +x.regionCountryJCTId) });
  if(typeId==2||typeId==3)      
        Object.assign(data,{targetRegion : state.targetCountries.map(x => +x.regionCountryJCTId) });

  let result=await dashboardActions.userInfo({data});
  if(result) setLoading(false);
  history.push('admin/dashboard');

  console.log('save', data);
}

console.log('state', state);
console.log('err', errors)
const onSubmit = data => {    
if (data) {        
    console.log("data:",data);
}    
}

let typeId=userData?.channelTypeId;

return (
   
     <div className="main-content">
        <Container className="mt-4 col-lg-10 col-md-9 col-sm-12" fluid>
        <form noValidate onSubmit={handleSubmit(save)}>        
        {loading && <div style={{ top: '50%', left: '50%', zIndex: '1000',}}><ModalSpinner /></div>}
        <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="typeId" ref={register} name="typeId" value={typeId} control={control} />
              <Card>
                  <CardHeader>
                  <h3 className="text-center">Registration Info</h3>
                  <p className="text-center">Your personal profile and contact details</p>
                  </CardHeader>
           <CardBody>
                <div className="row"> 
                <div className="col-sm-12">
                <div className="row">
                <div className="col-sm-6">
                <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="companyLogo" name="companyLogo" ref={register} value={state.companyLogo} /> 
                <h3>Profile Image *{!errors.companyLogo || state.companyLogo ? '' : <span style={{color: '#f44336', fontSize:'0.75rem'}}>   Please Select Profile Image</span>}</h3>
                <div>
                <ImageLibrary getDocumentId={getCompanyLogo} aws_folderName='account-s3' id={2}
          img={state.companyLogo ? state.companyLogo : "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}/>
        
             </div>
              </div>
    <div className="col-sm-6 mt-4">
        <span>Accepts Jpeg / Jpg / Png Only</span>
        <br></br>
        <span>(Max size allowed 200 Kb)</span>
        </div> 
         </div>
                  
    </div>
     
</div>

<div className="row">
  <div className="col-lg-12">
  {/* <h4 >Company Legal Name *</h4> */}
  <TextField className="comp-name mt-4" id="outlined-search" label="Employee ID *" type="text" variant="outlined" />
  </div>
</div>

<Row>
<div className="col-lg-6 mt-4">
            <div className="form-group">        
                    <FormInput 
                        id="firstName"
                        type="text"
                        name="firstName"
                        label="First Name *"
                        //placeholder="Password"
                        register={register}
                        //defaultValue={firstName}
                        //error={errors.firstName}
                        //value={watchFirstName}
                        //onChange={handleChange('firstName')}
                    />            
            </div>
            </div>


            <div className="col-lg-6 mt-4">
            <div className="form-group">        
                    <FormInput 
                        id="lastName"
                        type="text"
                        name="lastName"
                        label="Last Name *"
                        //placeholder="Password"
                        ////register={register}
                        ////error={errors.lastName}
                        ////value={watchLastName}
                        ////defaultValue={lastName}
                        ////onChange={handleChange('lastName')}
                    />            
            </div>
            </div>        
</Row>

<Row>
        <div className="col-lg-6">
        <div className="form-group">
        <FormInput
            id="Contact Email" 
            type="text" 
            label="Contact Email" 
            name="Contact Email" 
            //control={control}
            //register={register}
            //error={errors.jobposition}
            //defaultValue={jobPosition}
            //value={watchJobPosition}
            //onChange={handleChange('jobPosition')}
         />
        </div>
        </div>
        <div className="col-lg-6">
        <div className="form-group">               
        <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="target" name="target" ref={register} value={state.targetCountries.length?state.targetCountries[0].childname:''} /> 
          <FormAutocompleteGrouping
            input="Countries"
            id="Countries"
            label="Countries"
            name="Countries"
            Options={state.targetCountriesOption}
            placeholder="Search"              
            errMessage={'Please choose your Countries'}
            error={!errors.target || state.targetCountries.length ?false:true}
            optionName='targetCountriesOption'
            optionValue='targetCountries'
            defaultValue={state.targetCountries}
            value={state.targetCountries}         
            loading={true}
            selectedItem={selectedItem}
            handleChangeSelect={handleChangeSelect}
            control={control}
            register={register}
        />
</div>
        </div>
</Row>

<Row>
        <div className="col-lg-6">
        <div className="form-group">
        <FormInput
            id="Contact Phone Number" 
            type="text" 
            label="Contact Phone Number" 
            name="Contact Phone Number" 
            //control={control}
            //register={register}
            //error={errors.jobposition}
            //defaultValue={jobPosition}
            //value={watchJobPosition}
            //onChange={handleChange('jobPosition')}
         />
        </div>
        </div>
        <div className="col-lg-6">
        <div className="form-group">
        <FormInput
            id="Mobile Number" 
            type="text" 
            label="Mobile Number *" 
            name="Mobile Number" 
            //control={control}
            //register={register}
            //error={errors.jobposition}
            //defaultValue={jobPosition}
            //value={watchJobPosition}
            //onChange={handleChange('jobPosition')}
         />
        </div>
        </div>
</Row>

<Row>
        <div className="col-lg-6">
        <div className="form-group">
        <FormInput
            id="jobposition" 
            type="text" 
            label="Job Position" 
            name="jobposition" 
            //control={control}
            //register={register}
            //error={errors.jobposition}
            //defaultValue={jobPosition}
            //value={watchJobPosition}
            //onChange={handleChange('jobPosition')}
         />
        </div>
        </div>
        <div className="col-lg-6">
        <div className="form-group">
        <FormInput
            id="Reporting To" 
            type="text" 
            label="Reporting To" 
            name="Reporting To" 
            //control={control}
            //register={register}
            //error={errors.jobposition}
            //defaultValue={jobPosition}
            //value={watchJobPosition}
            //onChange={handleChange('jobPosition')}
         />
        </div>
        </div>
</Row>
<Row>
<div className="col-lg-12">
        <div className="form-group">
        <FormInput
            id="Linkedin URL" 
            type="text" 
            label="Linkedin URL" 
            name="Linkedin URL" 
            //control={control}
            //register={register}
            //error={errors.jobposition}
            //defaultValue={jobPosition}
            //value={watchJobPosition}
            //onChange={handleChange('jobPosition')}
         />
        </div>
        </div>
</Row>
<Row>
<div className="col-lg-12">
        <div className="form-group">
        <FormInput
            id="Twitter URL" 
            type="text" 
            label="Twitter URL" 
            name="Twitter URL" 
            //control={control}
            //register={register}
            //error={errors.jobposition}
            //defaultValue={jobPosition}
            //value={watchJobPosition}
            //onChange={handleChange('jobPosition')}
         />
        </div>
        </div>
</Row>
</CardBody>
           </Card>
<Card>
    <CardHeader>
    <h3 className="text-center mt-4" >Manage Password</h3>
    <p className="text-center" >Update profile Password</p>
    </CardHeader>
    <CardBody>
    <Row>
        <div className="col-lg-6">
        <div className="form-group">
        <FormInput
            id="Current Password" 
            type="text" 
            label="Current Password *" 
            name="Current Password" 
            //control={control}
            //register={register}
            //error={errors.jobposition}
            //defaultValue={jobPosition}
            //value={watchJobPosition}
            //onChange={handleChange('jobPosition')}
         />
        </div>
        </div>
        <div className="col-lg-6">
        <div className="form-group">
        <FormInput
            id="New Password" 
            type="text" 
            label="New Password *" 
            name="New Password" 
            //control={control}
            //register={register}
            //error={errors.jobposition}
            //defaultValue={jobPosition}
            //value={watchJobPosition}
            //onChange={handleChange('jobPosition')}
         />
        </div>

        <div className="form-group">
        <FormInput
            id="Confirm Password" 
            type="text" 
            label="Confirm Password *" 
            name="Confirm Password" 
            //control={control}
            //register={register}
            //error={errors.jobposition}
            //defaultValue={jobPosition}
            //value={watchJobPosition}
            //onChange={handleChange('jobPosition')}
         />
        </div>
        </div>
</Row>
<div className="col-lg-12 text-center mt-4">
            <Button color="primary" type="submit" >
                    Submit
                  </Button>
                  </div> 
    </CardBody>
</Card>

<Card>
    <CardHeader>
    <h3 className="text-center mt-4" >Account Preferences</h3>
    <p className="text-center" >Specify if you want your user profile to be visible on ChannelHub</p>
    </CardHeader>
    <CardBody>
    {/* <div className="form-group">        
            <FormRadio
                          name="productPositioning"
                          id="productPositioning"
                          className="text-sm mb-0"
                          label=""
                          options={radioGroupOptions1}
                          control={control}
                          //defaultValue={state.productPositioning}
                          //value={watchAll.productPositioning}
                          //row
                          //onChange={handleChange('productPositioning')}
                     />

                 </div>
    <div className="form-group">        
            <FormRadio
                          name="productPositioning"
                          id="productPositioning"
                          className="text-sm mb-0"
                          label=""
                          options={radioGroupOptions1}
                          control={control}
                          //defaultValue={state.productPositioning}
                          //value={watchAll.productPositioning}
                          //row
                          //onChange={handleChange('productPositioning')}
                     />

                 </div> */}
    </CardBody>
</Card>

          <Card>
           <CardBody>
           
        <h3 className="text-center mt-4" >Trade Locations</h3>
                  <p className="text-center" >Update the list of countries you are in charge of. We have pre-loaded the list of countries selected in your company profile</p>
       
       <div className="col-lg-12 ">
 <div className="form-group">               
 <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="target" name="target" ref={register} value={state.targetCountries.length?state.targetCountries[0].childname:''} /> 
          <FormAutocompleteGrouping
   input="targetCountries"
   id="TargetCountries"
   label="Target Locations"
   name="TargetCountries"
   Options={state.targetCountriesOption}
   placeholder="Search"              
   errMessage={'Please choose Target Locations'}
   error={!errors.target || state.targetCountries.length ?false:true}
   optionName='targetCountriesOption'
   optionValue='targetCountries'
   defaultValue={state.targetCountries}
   value={state.targetCountries}         
   loading={true}
   selectedItem={selectedItem}
   handleChangeSelect={handleChangeSelect}
   control={control}
   register={register}
   />
</div>
</div>

<div className="col-lg-12 ">
            <div className="form-group">               
            <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="selling" name="selling" ref={register} value={state.sellingCountries.length?state.sellingCountries[0].childname:''} /> 
                     <FormAutocompleteGrouping
              input="sellingCountries"
              id="SellingCountries"
              label= 'Selling Locations'
              name="SellingCountries"
              Options={state.sellingCountriesOption}
              placeholder="Search"              
              errMessage='Please choose Selling Locations'
              error={!errors.selling || state.sellingCountries.length ?false:true}
              optionName='sellingCountriesOption'
              optionValue='sellingCountries'
              defaultValue={state.sellingCountries}
              value={state.sellingCountries}         
              loading={true}
              selectedItem={selectedItem}
              handleChangeSelect={handleChangeSelect}
              control={control}
              register={register}
              />
        </div>
        </div>

        </CardBody>
           </Card>       

          <Card>
           <CardBody>
       
      
            <h3 className="text-center">Product Responsibilities</h3>
                  <p className="text-center">Select the products families that you offer</p>
                  {/* <h5 style={{color:'green'}}>Download to view List of Product Families and relevant Categories<h5/> */}

            <div className="col-lg-12 ">
            <div className="form-group">               
            <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="productCat" name="productCat" ref={register} value={state.productValue.length?state.productValue[0].childname:''} /> 
            <FormAutocompleteGrouping
              id="ProductResponsibilities"
              name="ProductResponsibilities"
              label="Product Responsibilities"
              Options={state.productOptions}
              optionName='productOptions'
              optionValue='productValue'
              placeholder="Product Responsibilities"              
              errMessage='Please Select Product'
              error={!errors.productCat || state.productValue.length ?false:true}
              defaultValue={state.productValue}
              value={state.productValue}
              input="product"              
              selectedItem={selectedItem}
              handleChangeSelect={handleChangeSelect}
              control={control}
              register={register}
          />  
        </div>
        </div>
        </CardBody>
           </Card>

           <Card>
           <CardBody>
       
      
            <h3 className="text-center">Interested Categories</h3>
                  <p className="text-center">From the selected product families above, select the categories looking for or interested by</p>
                  {/* <h5 style={{color:'green'}}>Download to view List of Product Families and relevant Categories<h5/> */}

            <div className="col-lg-12 ">
            <div className="form-group">               
            <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="productCat" name="productCat" ref={register} value={state.productValue.length?state.productValue[0].childname:''} /> 
            <FormAutocompleteGrouping
              id="InterestedCategories"
              name="InterestedCategories"
              label="InterestedCategories"
              Options={state.productOptions}
              optionName='productOptions'
              optionValue='productValue'
              placeholder="InterestedCategories"              
              errMessage='Please Select InterestedCategories'
              error={!errors.productCat || state.productValue.length ?false:true}
              defaultValue={state.productValue}
              value={state.productValue}
              input="product"              
              selectedItem={selectedItem}
              handleChangeSelect={handleChangeSelect}
              control={control}
              register={register}
          />  
        </div>
        </div>
        <div className="col-lg-12 text-center mt-4">
            <Button color="primary" type="submit" >
                    Save
                  </Button>
                  </div> 
        </CardBody>
           </Card>
         
          </form>
          </Container>
      </div>
    
 
  );
};



const radioGroupOptions = [
  {
    value: "1",
    label: "One a  week",
  },
  {
    value: "2",
    label: "Once per 2 weeks ",
  },
  {
    value: "3",
    label: "Once per month",
  },
  {
    value: "4",
    label: "Never",
  },
 
];
const radioGroupOptions1 = [
    {
      value: "Shared",
      label: "Shared",
    },
    {
      value: "Confidential",
      label: "Confidential ",
    }, 
  ];
 
export default MyProfile;