import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Card, Container, Row, CardHeader, UncontrolledTooltip, ListGroupItem,
  ListGroup, Col, Badge, Button, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Filter from "components/Filter-Right/Filter.js";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useForm } from "react-hook-form";
import { SelectAutocomplete } from '_components/FormElements/FormInput';
import axios from 'axios';
import { Spinner } from '../../Hoc/Spinner'
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/PaginationActions';
import Dialog from 'views/Hoc/Dialog/GlobalDialog';
import NotificationAlert from "react-notification-alert";
import UserView from 'views/Hoc/Dialog/UserView';
import Drawer from 'views/Hoc/Dialog/Globaldrawer';
import Connect from 'views/Hoc/Dialog/Connect';
import { history } from '_helpers/history';
import CompanyPublicSettings from 'views/Hoc/Dialog/CompanyPublicSettings';
import ChannelExportFilter from 'views/Hoc/Dialog/ChannelExportFilter';
import { commonService } from '_services/common.service';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import EventsPop from 'views/Hoc/Eventspop';
const toggleSidenav = e => {
  console.log(e)

  if (document.getElementById('filter').classList.contains("g-sidenav-pinned")) {
    document.getElementById('filter').classList.remove("g-sidenav-pinned");
    document.getElementById('filter').classList.add("g-sidenav-hidden");
  } else {
    document.getElementById('filter').classList.add("g-sidenav-pinned");
    document.getElementById('filter').classList.remove("g-sidenav-hidden");
  }
};

export const HubspotManager = (props) => {
  const notificationAlert = useRef(null);
  const { control } = useForm({ mode: 'onBlur' });
  const [showDrawer, setshowDrawer] = useState(false);
  const [connect, setshowconnect] = useState(false);

  const [contactid, setContact] = useState();

  const [user, setUser] = useState({ firstName: '', lastName: '', jobTitle: '', country: '' });

  const [state, setState] = useState({ dataTable: [], total: 0 });
  const [searchState, setSearch] = useState({ search: null, dropdownList: [] });
  const [filterValueState, setFilterValue] = useState({ Company_type: null, is_OEM: null, country: [], plan: [], product_Categories: [], tag: null, product_families: [], brand: [], dist_profile: [], reseller_profile: [], retailer_profile: [], pending_profile: null  });


  let [loading, setLoading] = useState(true);
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [open, setModalOpen] = useState(false);
  
  let [channelId, setChannelId] = useState(null);
  let [contactId, setContactId] = useState(null);
  let [contactList, setContactList] = useState(null);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null });
  let adminUser = JSON.parse(localStorage.getItem('CHuser'));
  const [drawerInput, setDrawerInput] = useState(null);
  let [sort, setSort] = useState('');
  let [isSort, setisSort] = useState(false);
  useEffect(() => {

    //clearing local storage data about selected user
    let existing = JSON.parse(localStorage.getItem('CHuser'));
    existing['userData'] = {};
    localStorage.setItem('CHuser', JSON.stringify(existing));
    getActiveChannel();
    getPendingChannelsList();
  }, []);

  const openDuplicate = (data) => {
    setDrawerInput(data);
    setshowDrawer(true);
    console.log('datauser', data);
  }

  const getActiveChannel = async () => {

    loading = true;
    setLoading(loading);
    let data = {
      search: searchState.search ? searchState.search[0] ? searchState.search[0].id : null : null,
      signupstatusid: [14,15],
      userId: adminUser.userId,
      filter: { },
      page: page * rowsPerPage,
      order: isSort ? sort : false,
      limit: rowsPerPage
    }
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.post('/hubspot/getChannels',
      data).then(res => {
        //console.log("pending:",res);
        state.dataTable = res.data.rows;
        state.total = res.data.count;

        loading = false;
        setLoading(loading);
        setState({ ...state });

        let data = [];
        data.push(...res.data.rows)
        data = data.map(x => {
          return { name: x.companyName.trim(), id: x.channelId }
        });
      
      // searchState.dropdownList = data
      // setSearch({ dropdownList: searchState.dropdownList });
        
      }).catch((err) => {
        state.dataTable = [];
        state.total = 0;
        loading = false;
        setLoading(loading);
        setState({ ...state });
      })
  }

  const getPendingChannelsList = () => {
    axios.get('/channelAdmin/pendingChannelsList/' + adminUser.userId).then(res => {
      let data = [];
      data.push(...res.data)
      data = data.map(x => {
        return { name: x.name.trim(), id: x.id }
      });
      let contact = [];
      res.data.map(x => {
        x.contacts.map(c => {
          contact.push({ name: c.firstName + ' ' + c.lastName, id: x.id, companyName: x.name.trim() });
        })
      })
      data.push(...contact);
      searchState.dropdownList = data
      setSearch({ dropdownList: searchState.dropdownList });
    });
  }

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getActiveChannel();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    callPendingChannel();
  };

  const handleChangeSelect = (input, value) => {
    if (input == 'search') {
      filterValueState.Company_type = null;
      filterValueState.is_OEM = null;
      filterValueState.country = [];
      filterValueState.plan = [];
      filterValueState.tag = null;
      filterValueState.pending_profile = null;
      filterValueState.product_Categories = [];
      filterValueState.product_families = [];
      filterValueState.brand = [];
      filterValueState.retailer_profile = [];
      filterValueState.reseller_profile = [];
      filterValueState.dist_profile = [];
      setFilterValue({ ...filterValueState });
      searchState.search = [value]
      setSearch({ ...searchState })
    }
    
    callPendingChannel();
  }

  const callPendingChannel = () => {
    closeDrawer();
    page = 0;
    setPage(page);
    getActiveChannel();
  }
  const setSorting = (data) => {
    isSort = true;
    setisSort(isSort);
    if (data != sort) {
      sort = data;
      setSort(sort);
      callPendingChannel();
    }
  }

  const clearSorting = () => {
    isSort = false;
    setisSort(isSort);
    callPendingChannel();
  }
 

  const updateStatus = (data, id, contact) => {
    console.log('contact', contact);
    loading = true;
    setLoading(loading);
    axios.put('/channelAdmin/channelApproval', {
      channelId: data,
      signUpStatusId: id
    }).then(res => {
      if (res) {
        if (id == 0)
          ToastsStore.success('Channel Removed successfully')
        else {
          id == 15 ? ToastsStore.success('Channel Approved successfully') : ToastsStore.warning('Channel Dismiss successfully');
          if (id == 15) {
            let maildata = {
              to: contact.email,
              contactId: contact.contactId,
              profileurl: process.env.REACT_APP_LOCALSITE + 'login'
            }
            axios.post('mailerservice/Channel_Approval_Mail', maildata);
          }
          else {
            let maildata = {
              to: contact.email,
              contactId: contact.contactId,
              //profileurl: process.env.REACT_APP_LOCALSITE + 'login'
            }
            axios.post('mailerservice/Channel_Dismiss_Mail', maildata);
          }
        }
        callPendingChannel();
        searchState.search = null
        setSearch({ ...searchState, search: searchState.search })
      }
    }).catch((err) => {
      console.log('err', err)
      loading = false;
      setLoading(loading);
      ToastsStore.error('Soming went wrong');
    })
  }

  
  

  const handleClose = () => {
    setModalOpen(false);
    
  };
  const handleSave = (value) => {
    open = false;
    setModalOpen(open);
    updateStatus(value.value.channelId, value.value.id, value.value.contact);
  };
  const [userview, setuserview] = useState(false)
 

  const closeDrawer = () => {
    setshowDrawer(false);
  }

  const closeConnect = () => {
    setshowconnect(false);
  }
  const openConnect = () => {
    setshowconnect(true);
  }
  const opneUser = () => {
    closeConnect();
    setshowDrawer(true);
  }
  // style={{width:'46%',float:'right'}}

  //copy email
  const copyEmail = (x) => {
    navigator.clipboard.writeText(x.email);
    if (document.getElementById("tooltipemail" + x.contactId)) {
      document.getElementById("tooltipemail" + x.contactId).innerHTML = "copied : " + x.email;
    }
  }
 
  const User = {
    renderer: (row, rowIndex) => {
      console.log('rowuser', row, rowIndex);
      return (
        <div style={{ width: '100%' }}>
          {row.contacts.length && row.contacts.map((x, i) =>
            <ListGroup className="list my--2" flush key={'table' + x.contactId}>
              <ListGroupItem className="px-0">
                <Row className="align-items-center">
                  <Col className="col-1">
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"); }}
                        alt="..."
                        src={x.document?.documentPath ? x.document.documentPath.includes(process.env.REACT_APP_CDN_URL) ? x.document.documentPath : process.env.REACT_APP_CDN_URL + x.document.documentPath : "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"}//{require("assets/img/theme/team-1.jpg")}
                      />
                    </a>
                  </Col>
                  <div className="col-lg-2 ml--2">
                    <p className="mb-0" style={{ fontSize: '0.7rem' }}>

                      {x.firstName + ' ' + x.lastName}

                    </p>
                    <small>{x.email} <i style={{ cursor: "pointer", color: "green", fontSize: "0.7rem" }} id={"email" + x.contactId} onClick={() => copyEmail(x)} className="far fa-copy"></i>  </small>

                    
                    <UncontrolledTooltip delay={0} delay={0} placement="bottom" id={"tooltipemail" + x.contactId} target={"email" + x.contactId}>copy email</UncontrolledTooltip>

                    
                  </div>

                  {/* {/* <div className="col-lg-1 ml--2">
                    <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                      Last Login
                    </p>
                    <small>{new Date(x.user.lastLogin).toLocaleDateString()}</small>
                  </div> 

                  <div className="col-lg-2 ml--2">
                    <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                      {x.jobTitle}
                    </p>
                  </div> */}
                
                 
                  {/* <div className="col-lg-1 ml--2">
                    <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                      {x.user?.role?.roleId == '1' ? 'Account Owner' : x.user?.role?.roleId == '2' ? 'Admin' : 'Primary'}
                    </p>
                  </div>
                  <div className="col-lg-1 ml--2">
                    <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                      {x.isShared == '0' ? 'Confidential' : 'Shared'}
                    </p>
                  </div> */}

                  <div className="col-lg-1 ml--2">
                    <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                    Hubspot Id
                    </p>
                    <small>{x.hubspotContactId}</small>
                  </div>
                  
                  <div className="col-lg-1 ml-2">

                    <Badge className='outer_badge' style={{ width: "100%", height: "2rem", padding: "0.6rem", border: "1px solid #46b88f", color: "#46b88f" }}>
                      {x.isActive == '1' ? 'Active' : 'Inactive'}

                    </Badge>

                  </div>
                
                  
                </Row>
              </ListGroupItem>

            </ListGroup>)
          }
        </div>
      )
    },
    className: 'bgrow'
  };
    
  return (
    <div id="channelList">

      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <Dialog open={open} value={modalPopValue} handleSave={handleSave} handleClose={handleClose}></Dialog>
      <NotificationAlert ref={notificationAlert} />
      <Drawer title="Merge Account" visible={showDrawer} cData={drawerInput} reload={getActiveChannel}  closeDrawer={closeDrawer} reload={getActiveChannel} />
          
      <Container className="mt--3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Merge Accounts</h3>
              </CardHeader>
              {loading ? <Spinner /> : <ToolkitProvider
                ClassName="align-items-center table-flush" responsive
                data={state.dataTable}
                keyField="contacts[0].email"
                columns={[
                  {
                    dataField: "Action",
                    text: "Action",
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          <button onClick={() => openDuplicate(row)} className="btn btn-icon btn-success btn-sm" variant="contained" color="success" >Merge</button>
                          
                        </>
                      )
                    }
                  },
                  
                  {
                    dataField: "companyName",
                    text: " Company Name",
                    sort: true
                  },
                  
                  {
                    dataField: "channelTypeId",
                    text: "Company Type",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {rowContent == 2 && "Vendor"}
                          {rowContent == 3 && "Distributor"}
                          {rowContent == 4 && "Retailer"}
                          {rowContent == 5 && "Reseller"}
                        </>
                      )
                    }
                  },
                  
                  {
                    dataField: "hubspotCompanyId",
                    text: "Hubspot Id",
                    sort: true,
                   
                  },
                  {
                    dataField: "createdAt",
                    text: "Registered On",
                    sort: true
                  }
                ]}
              >
                {props => (
                  <div className="py-2 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 mb-2"
                    >
                      <div style={{ width: '20%', display: 'inline-block' }}>
                        <SelectAutocomplete
                          id="search"
                          type="text"
                          label="search"
                          name="search"
                          options={searchState.dropdownList}
                          placeholder="search..."
                          disableCloseOnSelect
                          // defaultValue={searchState.search?searchState.search[0]:null}
                          control={control}
                          input='search'
                          value={searchState.search ? searchState.search[0] : null}
                          handleChangeSelect={handleChangeSelect}
                        />
                      </div>

                      <UncontrolledDropdown className="pl-3 mt-2 mb-2" group>
                        <DropdownToggle caret className="p-2">
                          <h3
                            style={{ display: "inline-block" }}
                            className="font-weight-bold m-0"
                          >
                            Sort {isSort ? sort ? 'A - Z' : 'Z - A' : ''}
                          </h3>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem

                            onClick={() => setSorting('asc')}
                          >
                            A - Z
                          </DropdownItem>
                          <DropdownItem

                            onClick={() => setSorting('desc')}
                          >
                            Z - A
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      {isSort && <button class="btn btn-icon btn-secondary ml-2" type="button" onClick={clearSorting}>
                        <span class="btn-inner--text">clear</span>
                      </button>}
                      
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      expandRow={User}
                      bootstrap4={true}
                      // pagination={pagination}
                      bordered={false}
                      hover={true}

                      rowClasses='pointer'
                    />

                    <TablePagination
                      component="div"
                      count={state.total}
                      page={page}
                      onChangePage={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </div>
                )}
              </ToolkitProvider>}
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};
export default HubspotManager;