import React ,{useEffect, useState} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
  Card, CardBody, CardHeader, Container, FormGroup, Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup, Col, Row, Badge
} from "reactstrap";
import {
Button, Modal,
} from "reactstrap";
import { ProfileUploader } from '../ProfileUpload';
import { commonService } from '_services/common.service';
import {Spinner} from 'views/Hoc/Spinner';
import {FormCheckBox} from "_components/FormElements/FormInput";
import axios from "axios";
// import CardBody from 'reactstrap/lib/CardBody';

export const CompanyPublicSettings=(props)=> {
const { opneadd, closeModalPopup, brandName } = props;
const [open, setOpen] = React.useState(false);
const theme = useTheme();
const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
useEffect(() => {
setOpen(true);

}, [])

const [state, setState] = useState({ featureValue:[] , featureLabel: [], limitField: []});


useEffect(() => {
  getFlags();
}, []);

const getFlags = async() => {
  let IsLimit = {};
  let ctid = props.data.channelTypeId;

  //console.log('ctid====', ctid);

  if(+ctid == 2){
  const featureKeys = await commonService.getServices('channel/subscription/getFeaterList/'+props.data.channelId).catch(e=> console.log(e));
  
  let limitField = await featureKeys.data.category.map((data, index) => {
    IsLimit[data.id] = false;
    if(+data.value > 0){
      IsLimit[data.id] = true;
     }
    //return IsLimit;
  });

  await axios.get('channelAdmin/getPublicCompanyFlags/'+props.data.channelId).then(res => {
    let data = {
      isFeatured: res.data.isFeatured == '1' ? true : false,
      isPublic: res.data.isPublic == '1' ? true : false,
      channelId: res.data.channelId,
      featureValue: featureKeys.data.category,
      featureLabel: featureKeys.data.result.keyFeature.label,
      limitField: IsLimit
    }
    setState(data);
    
  })

  } else {

    await axios.get('channelAdmin/getPublicCompanyFlags/'+props.data.channelId).then(res => {
      let data = {
        isFeatured: res.data.isFeatured == '1' ? true : false,
        isPublic: res.data.isPublic == '1' ? true : false,
        channelId: res.data.channelId,
       
        limitField: IsLimit
      }
      setState(data);
      
    })
  }
  

  
  
};



const handleClickOpen = () => {
setOpen(true);
};

const handleClose = () => {
setOpen(false);
};

const save = async() => {
  let data = {
    isFeatured: state.isFeatured  ? '1' : '0',
    isPublic: state.isPublic  ? '1' : '0',
    channelId: state.channelId,
  }

  await axios.post('channelAdmin/setCompanyPublicFlags', data).then(res => {
    console.log('saved');
  }).catch(err => {
    console.log(err);
  })
  let featureValue = {}
  let ids = ''
  let vals = '';
  featureValue['label'] = {};

  if(+props.data.channelTypeId == 2){
    state.featureValue.map((data, index) => {
        ids = data.id;
        vals = document.getElementById(data.id).value;

        if(document.getElementById(data.id).value != null) {
          if(document.getElementById(data.id).value == 'limit'){
            featureValue[data.id] = document.getElementById('numeric'+data.id).value;
          } else {
            featureValue[data.id] = document.getElementById(data.id).value;
          }
          
        } else {
          return false;
        }

        if(document.getElementById('l_'+data.id).value != null) {
          featureValue['label'][data.id] = document.getElementById('l_'+data.id).value;
        } else {
          return false;
        }
       
        
    });
   // console.log(featureValue, state.channelId);
  }
    let res = await axios.post('/channel/subscription/updateChannelFeatureList', {channelId: +state.channelId, extraFeature: featureValue})
      .then(res =>{
          return res.data;
        
      }).catch();

      if(res){
        console.log('Updated Successfully!...'); //ToastsStore.success('Updated Successfully!...');
        
      }else{
        console.log('Something went wrong!...');
        //ToastsStore.warning('Something went wrong!...');
      }
      

  setState({});
  closeModalPopup();

}

const closeModalPopups= () => {
  setState({});
  closeModalPopup();
}

const handleChange  = input => e => {
  setState({...state, [input]:!state[input]});
}


const handleValues = input => e => {
  state.featureValue[input].value = e.target.value;
  //console.log(e.target.id ,' e',  e.target.value);
  setState({...state,
      featureValue: state.featureValue
    });
  }


const handleLabels = input => e => {
  let ids = e.target.id;
    state.featureLabel[ids.substring(2)] = e.target.value;
    if(!e.target.value){
      state.featureLabel['error_'+ ids.substring(2)] = 'Invalid values';
    } else {
      state.featureLabel['error_'+ ids.substring(2)] = '';
    }
    setState({...state,
      featureLabel: state.featureLabel
    });
   
}

const handleSelect  = input => e => {
  
  if((document.getElementById('numeric'+e.target.id)) && e.target.value != 'limit' && document.getElementById('numeric'+e.target.id).style.display != 'none'){
    document.getElementById('numeric'+e.target.id).style.display = 'none';
    state.featureValue[input].value = e.target.value;
  } else if(e.target.value == 'limit'){

    state.limitField[e.target.id] = true;
        setState({...state,
          limitField: state.limitField
          });
    state.featureValue[input].value = 1;
    //document.getElementById('numeric'+e.target.id).value;
    // document.getElementById('numeric'+e.target.id).style.display = 'block';
  } else {
    state.featureValue[input].value = e.target.value;
  }
    
    setState({...state,
      featureValue: state.featureValue
    });
}
return (
<div>
  
  <Modal
className="modal-dialog-centered Reorderdialog"
isOpen={opneadd} >
<div className="modal-header">
<h3 className="text-center">Guest Page Settings</h3>
<button
aria-label="Close"
className="close"
data-dismiss="modal"
type="button"
onClick={closeModalPopups}
>
<span aria-hidden={true}>×</span>
</button>
</div>
{/* { state.hasOwnProperty('channelId') && <div className="modal-body">

<div style={{display: 'flex', justifyContent: 'space-between'}}>
<h4>Is Featured :</h4>
<label className="custom-toggle custom-toggle-success">
                             <input type="checkbox" checked={state.show_in_home_page || state.isFeatured} disabled={state.show_in_home_page}  name="status" onClick={handleChange('isFeatured')}  />
                             <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                             </label>                             
</div>

<div style={{display: 'flex', justifyContent: 'space-between'}}>
<h4>Is Public:</h4>
<label className="custom-toggle custom-toggle-success">
                             <input type="checkbox" checked={state.show_in_home_page || state.isPublic} disabled={state.show_in_home_page}  name="status" onClick={handleChange('isPublic')}  />
                             <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                             </label>                             

</div>
</div>
} */}

<Card>
  <CardBody>
    <Row>
      <Col>
      { state.hasOwnProperty('channelId') && <div className="modal-body">

<div style={{display: 'flex', justifyContent: 'space-between'}}>
<h4>Is Featured :</h4>
<label className="custom-toggle custom-toggle-success">
                             <input type="checkbox" checked={state.show_in_home_page || state.isFeatured} disabled={state.show_in_home_page}  name="status" onClick={handleChange('isFeatured')}  />
                             <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                             </label>                             
</div>

<div style={{display: 'flex', justifyContent: 'space-between'}}>
<h4>Is Public:</h4>
<label className="custom-toggle custom-toggle-success">
                             <input type="checkbox" checked={state.show_in_home_page || state.isPublic} disabled={state.show_in_home_page}  name="status" onClick={handleChange('isPublic')}  />
                             <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                             </label>                             

</div>
</div>
}
{state.featureValue != null? <h2 className="text-center">Subscription Feature Settings</h2>:''}
<div> 
      {state.featureValue != null? state.featureValue.map((data, index) => (
        <div style={{ width:'100%',  display:'block', padding : '0 0 33px 0'}}>
          <div style={{ width:'40%', height: '50%' , display:'inline-block'}}>
            <h4>{data.title}</h4>
          </div>

          <div style={{ width:'60%', display:'inline-block'}}>
            <div  style={{ width:'100%', display:'block'}}>
              <div style={{ width:'50%', display:'inline', valign:'center'}}>
                <TextField id={'l_'+data.id}  value={state.featureLabel[+data.id]}  onChange ={handleLabels(index)} variant="outlined"  />
                {/* {state.featureLabel['error_'+ +data.id] && <span style = {{ display:'flex', color:'red'}}>{state.featureLabel['error_'+ +data.id]}</span> } */}
              </div>
              <div style={{float:'right', width:'50%', display:'inline', valign:'center'}}>  
                {/* <TextField id={data.id}  value={state.featureValue[index].value}  onChange ={handleValues(index)} variant="outlined"  /> */}

                <select id={data.id} style={{ padding: '18.5px 14px', color:'currentColor', borderRadius:'4px'}} onChange ={handleSelect(index)}>
                  <option value="yes" selected={'yes'  == state.featureValue[index].value}>Yes</option>
                  <option value="no" selected={'no'  == state.featureValue[index].value}>No</option>
                  <option value="random" selected={'random'  == state.featureValue[index].value}>Random</option>
                  <option value="limit" selected={'limit'  == state.featureValue[index].value || +state.featureValue[index].value > 0}>Limit</option>
                  <option value="unlimited" selected={'unlimited'  == state.featureValue[index].value}>Unlimited</option>
                </select>
                { (state.limitField[data.id]) === true?
                <input type="text" id={'numeric'+data.id} style={{display: 'block'}}  value={state.featureValue[index].value}  onChange ={handleValues(index)} variant="outlined"  />
                :''}
              </div>
            </div>
          </div>

          <div style={{ width:'60%', display:'inline-block'}}>
            <div  style={{ width:'100%', display:'block'}}>
              <div style={{ width:'50%', display:'inline', valign:'center'}}>
                {state.featureLabel['error_'+ +data.id] && <span style = {{ display:'flex', color:'red'}}>{state.featureLabel['error_'+ +data.id]}</span> }
              </div>
              <div style={{float:'right', width:'50%', display:'inline', valign:'center'}}>  
               
                
              </div>
            </div>
          </div>

        </div>  
      )):''} 
</div></Col>
    </Row>
  </CardBody>

</Card>

<div className="modal-footer">

<Button
color="primary"
data-dismiss="modal"
type="button"
onClick={save}
>
Save
</Button>

<Button
color="secondary"
data-dismiss="modal"
type="button"
onClick={closeModalPopups}
>
Close
</Button>


</div>
</Modal>
</div>
);
}
export default CompanyPublicSettings;