import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Card, Container, Row, CardHeader, UncontrolledTooltip, ListGroupItem,
  ListGroup, Col, Badge, Button
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import BillingFilter from "components/Filter-Right/BillingFilter.js";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useForm } from "react-hook-form";
import { SelectAutocomplete } from '_components/FormElements/FormInput';
import axios from 'axios';
import { Spinner } from '../../Hoc/Spinner'
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/PaginationActions';
import Dialog from 'views/Hoc/Dialog/GlobalDialog';
import NotificationAlert from "react-notification-alert";
import UserView from 'views/Hoc/Dialog/UserView';
import Drawer from 'views/Hoc/Dialog/Globaldrawer';
import Connect from 'views/Hoc/Dialog/Connect';
import { history } from '_helpers/history';
import Chip from '@mui/material/Chip';
import Moment from 'moment';

const toggleSidenav = e => {
  console.log(e)

  if (document.getElementById('filter').classList.contains("g-sidenav-pinned")) {
    document.getElementById('filter').classList.remove("g-sidenav-pinned");
    document.getElementById('filter').classList.add("g-sidenav-hidden");
  } else {
    document.getElementById('filter').classList.add("g-sidenav-pinned");
    document.getElementById('filter').classList.remove("g-sidenav-hidden");
  }
  // function handleOrangeClick({
  //  sidenavOpen: setOpen(!sidenavOpen)
  // });
};
const company_type_Option = [
  { name: "Vendor", id: 2 },
  { name: "Distributor", id: 3 },
  { name: "Retailer", id: 4 },
];
const is_OEM_Option = [
  { name: "Yes", id: 'yes' },
  { name: "No", id: 'no' },
]
export const EventPayment = (props) => {
  const notificationAlert = useRef(null);
  const { control } = useForm({ mode: 'onBlur' });
  const [showDrawer, setshowDrawer] = useState(false);
  const [connect, setshowconnect] = useState(false);

  const [contactid, setContact] = useState();

  const [user, setUser] = useState({ firstName: '', lastName: '', jobTitle: '', country: '' });

  const [state, setState] = useState({ dataTable: [], total: 0 });

  const [eventList, setEventList] = useState({ value: null, option: [] });


  const [searchState, setSearch] = useState({ search: null, dropdownList: [] });
  const [filterValueState, setFilterValue] = useState({ EventList: [], DueList: [], PaymentType: null, PackList: [], Status: [] });
  const [filterOptionState, setFilterOption] = useState({ EventListOption: [], DueListOption: [{ id: 1, name: 'First Due' }, { id: 2, name: 'Second Due' }, { id: 3, name: 'Third Due' }], PackListOption: [], StatusOption: [{ id: 1, name: 'Paid' }, { id: 2, name: 'Unpaid' }], PaymentTypeOption: [{ id: 1, name: 'Full Payment' }, { id: 2, name: 'Split Payment' }] });

  let [loading, setLoading] = useState(true);
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [open, setModalOpen] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null });
  let [channelSubscriptionId, setChannelSubscriptionId] = useState();
  let [actions, setAction] = useState();

  const [drawerInput, setDrawerInput] = useState(null);

  useEffect(() => {
    //getAggregation(); 
    getPendingChannel(false);
    //getPendingChannelsList();

    //getProductCat();
  }, []);

  const getCountryList = () => {
    axios.post('/channelAdmin/getFilterCountry', {
      signupstatusid: [14]
    }).then(res => {
      filterOptionState.countryOption = res.data;
      setFilterOption({ ...filterOptionState, countryOption: filterOptionState.countryOption });
    })
  }

  const handleChangeSelect = (input, value) => {

    console.log("subs:", value);
    let filterFlag = false;

    if (input === 'eventlist') {
      filterFlag = true;
      filterValueState.EventList = value
      setFilterValue({ ...filterValueState })
    }
    if (input === 'paymenttype') {
      filterFlag = true;
      filterValueState.PaymentType = value
      setFilterValue({ ...filterValueState })
    }
    if (input == 'search') {
      filterFlag = true;
      filterValueState.Company_type = [];
      filterValueState.periods = [];
      filterValueState.plan = [];
      filterValueState.channel_status = [];
      filterValueState.sub_status = [];
      setFilterValue({ ...filterValueState });
      searchState.search = [value]
      setSearch({ ...searchState })
    }
    if (input == 'duelist') {
      filterFlag = true;
      filterValueState.DueList = value
      setFilterValue({ ...filterValueState })
    }

    if (input == 'packlist') {
      filterFlag = true;
      filterValueState.PackList = value
      setFilterValue({ ...filterValueState })
    }

    if (input == 'status') {
      filterFlag = true;
      filterValueState.Status = value
      setFilterValue({ ...filterValueState })
    }

    if (filterFlag) {
      page = 0;
      setPage(page);
      getPendingChannel(filterFlag);
    }
  }


  const getFilterData = () => {
    let filter = {
      eventId: [],
      status: "0",//paymentMethodId: 2,
      // transactionStatus: '0'
    };

    if (filterValueState.EventList && filterValueState.EventList.length > 0) {
      Object.assign(filter, { eventId: filterValueState.EventList.map(x => parseInt(x.id)) });
    }

    if (filterValueState.Status) {
      Object.assign(filter, { status: filterValueState.Status.id });
    }

    if (filterValueState.PaymentType) {
      Object.assign(filter, { PaymentType: filterValueState.PaymentType.id });
    }

    if (filterValueState.DueList) {
      Object.assign(filter, { dues: filterValueState.DueList.id });
    }




    return filter;
  }

  const getPendingChannel = async (filterFlag) => {
    loading = true;
    setLoading(loading);
    var filter = await getFilterData();


    //   let filter = {
    //     invoiceno: "CHZ",
    //     isPaid: "1"
    //     };

    //   search: searchState.search?searchState.search[0]?searchState.search[0].id:null:null,
    //   signupstatusid:[14],
    //   filter : {
    //     countryId:filter.countryId?filter.countryId:null,
    //     channelTypeId:filter.channelTypeId?filter.channelTypeId:null,
    //     is_OEM:filter.is_OEM?filter.is_OEM:null,
    //     product_Categories:filter.product_Categories?filter.product_Categories:null
    //   },

    filter.page = page * rowsPerPage;
    filter.limit = rowsPerPage;
    // }
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.post('/channelAdmin/getPendingEventPayment',
      filter, {
      params: {
        search: '', page: page,
        limit: rowsPerPage, sort: 'desc'
      }
    }
    ).then(res => {


      if (!filterFlag) {
        const eventList = res.data.rows.map(e => e.event);
        const ids = eventList.map(({ id }) => id);
        const filteredEvents = eventList.filter(({ id }, index) => !ids.includes(id, index + 1));

        filterOptionState.EventListOption = filteredEvents;
        setFilterOption({ ...filterOptionState });
      }


      // console.log("billing:", filterOptionState);

      state.dataTable = res.data.rows;
      state.total = res.data.count;



      setState({ ...state });
      loading = false;
      setLoading(loading);
    }).catch((err) => {
      state.dataTable = [];
      state.total = 0;
      loading = false;
      setLoading(loading);
      setState({ ...state });
    })
  }


  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getPendingChannel(true);
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);

  };




  const updateStatus = (id, data) => {
    loading = true;
    setLoading(loading);

  }

  const openDialag = (id, status) => {
    let data = { 'id': id, 'status': status };
    setDrawerInput(data);
    //setChannelSubscriptionId(id); 
    //    setAction(status); 
    setshowDrawer(true);

  }

  const openModalPopUp = (ch_Id, id) => {
    if (id === 1) {
      modalPopValue.message = 'You want to Approve Event Payment';
      modalPopValue.title = "Approve Payment";
      let data = { 'status': true };
      modalPopValue.value = { id: ch_Id, data: data };
    } else {
      let data = { 'status': false };
      modalPopValue.message = 'You want to Dismiss Event Payment';
      modalPopValue.title = "Dismiss Payment";
      modalPopValue.value = { id: ch_Id, data: data };
    }
    modalPopValue.popUpId = 1;
    setModalPopValue({ ...modalPopValue })
    open = true;
    setModalOpen(open);
  };



  const closeDrawer = () => {
    setshowDrawer(false);
  }

  const closeConnect = () => {
    setshowconnect(false);
  }
  // style={{width:'46%',float:'right'}}
  const reCallView = () => {
    closeDrawer();

  }

  const updatePaymentStatus = async (id, status) => {
    let data = {
      eventChannelId: id,
      status: status,
    }
    axios.post('/channelAdmin/updateEventPayment/', data).then(res => {
      if (res) {

      }
    }).catch((err) => {

    })

    setLoading(false);

  }
  //const [openSide,setOpen] = React.useState(1);
  const [sidenavOpen, setOpen] = useState(false);
  return (
    <div id="channelList">
      {/*       
      <SimpleHeader name="Event Wire Transfer" parentName="Event" />
      {/* <Drawer title="WireTransfer" visible={showDrawer} channelSubscriptionId={channelSubscriptionId} closeDrawer={closeDrawer}/> */}

      <Drawer title="Event Payment" visible={showDrawer} cData={drawerInput} closeDrawer={closeDrawer} reCallView={reCallView} />

      <Container className="mt--3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Event Payment</h3>
                {/* <p className="text-sm mb-0">
                    This is an exmaple of data table using the well known
                    react-bootstrap-table2 plugin. This is a minimal setup in
                    order to get started fast.
                  </p> */}
              </CardHeader>
              {loading ? <Spinner /> : <ToolkitProvider
                ClassName="align-items-center table-flush" responsive
                data={state.dataTable}
                keyField="contacts[0].email"
                columns={[

                  {
                    dataField: "eventName",
                    text: "Event Name",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {row.event.name}
                        </>
                      )
                    }
                  },
                  {
                    dataField: "channelName",
                    text: "Company Name",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {row.channel.companyName}
                        </>
                      )
                    }
                  },

                  {
                    dataField: "Contact",
                    text: "Contact Name",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {row.contact.firstName + " " + row.contact.lastName}
                        </>
                      )
                    }
                  },

                  {
                    dataField: "package",
                    text: "Package Details",
                    sort: true,
                    formatter: (rowContent, row) => {
                      let currCode = row.currCode == "USD" ? "$" : "€";
                      return (
                        <>
                          <p>Plan : {row.package.packageName}</p>
                          <p>Plan Cost : {row.package.price.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')} {currCode}</p>
                          {row.discount > 0 && <>
                            <p>Discount : {row.discount.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')} {currCode}</p>
                            <p>Final Cost : {(row.package.price - row.discount).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')} {currCode}</p></>}

                        </>
                      )
                    }
                  },
                  {
                    dataField: "splitId",
                    text: "Installment",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {row.splitId}

                        </>
                      )
                    }
                  },
                  {
                    dataField: "splitAmount",
                    text: "Due Amount",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {row.splitAmount}

                        </>
                      )
                    }
                  },
                  {
                    dataField: "paymentDate",
                    text: "Due Date",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {row.paymentDate ? Moment(row.paymentDate).format('DD-MM-YYYY') : ''}

                        </>
                      )
                    }
                  },

                  {
                    dataField: "Status",
                    text: "Status",
                    formatter: (rowContent, row) => {

                      let payment = '';
                      if (row.paymentStatus == 1)
                        payment = 'Paid';
                      else
                        payment = 'Unpaid';

                      return (
                        <>
                          {payment == "Paid" ?
                            <Chip label={`${payment}`} style={{ backgroundColor: '#4caf50', color: '#fff' }} variant="filled" size="large" /> : <Chip label={`${payment}`} style={{ backgroundColor: '#fb6340', color: '#fff' }} variant="filled" size="large" />}


                        </>
                      )
                    }
                  }
                ]}
              >
                {props => (
                  <div className="py-2 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 mb-2"
                    >

                      <div className='row'>
                        <div className='col-sm-6'>

                          <SelectAutocomplete
                            id="eventlist"
                            type="text"
                            label="Events"
                            name="eventlist"
                            multiple
                            className="d-inline-block"
                            style={{ width: '48%' }}
                            options={filterOptionState.EventListOption}
                            placeholder="search..."
                            disableCloseOnSelect
                            getOptionSelected={(options, value) => options.name === value.name}
                            defaultValue={filterValueState.EventList}
                            control={control}
                            input='eventlist'
                            value={filterValueState.EventList}
                            handleChangeSelect={handleChangeSelect}
                          />

                          <SelectAutocomplete
                            id="paymenttype"
                            type="text"
                            label="Payment Type"
                            name="paymenttype"
                            multiple={false}
                            className="d-inline-block"
                            style={{ width: '48%', marginLeft: '20px' }}
                            options={filterOptionState.PaymentTypeOption}
                            placeholder="Payment Type..."
                            disableCloseOnSelect
                            getOptionSelected={(options, value) => options.name === value.name}
                            defaultValue={filterValueState.PaymentType}
                            control={control}
                            input='paymenttype'
                            value={filterValueState.PaymentType}
                            handleChangeSelect={handleChangeSelect}
                          />

                          {filterValueState.PaymentType &&
                            <SelectAutocomplete
                              id="duelist"
                              type="text"
                              label="Payment Dues"
                              name="duelist"
                              multiple={false}
                              className="d-inline-block"
                              style={{ width: '48%', marginLeft: '20px' }}
                              options={filterOptionState.DueListOption}
                              placeholder="Dues ..."
                              disableCloseOnSelect
                              getOptionSelected={(options, value) => options.name === value.name}
                              defaultValue={filterValueState.DueList}
                              control={control}
                              input='duelist'
                              value={filterValueState.DueList}
                              handleChangeSelect={handleChangeSelect}
                            />}

                          <SelectAutocomplete
                            id="status"
                            type="text"
                            label="Payment Status"
                            name="status"
                            multiple={false}
                            className="d-inline-block"
                            style={{ width: '48%', marginLeft: '20px' }}
                            options={filterOptionState.StatusOption}
                            placeholder="Status..."
                            disableCloseOnSelect
                            getOptionSelected={(options, value) => options.name === value.name}
                            defaultValue={filterValueState.Status}
                            control={control}
                            input='status'
                            value={filterValueState.Status}
                            handleChangeSelect={handleChangeSelect}
                          />



                        </div>

                      </div>

                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      // pagination={pagination}
                      bordered={false}
                      hover={true}

                      rowClasses='pointer'
                    />

                    <TablePagination
                      component="div"
                      count={state.total}
                      page={page}
                      onChangePage={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </div>
                )}
              </ToolkitProvider>}
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};
export default EventPayment;
