import React, { useState, useEffect, useRef} from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink,  Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Button, Modal } from "reactstrap";
import { Checkbox } from '@material-ui/core';
import MultiUpload from '../MultiUpload';
import { commonService } from '_services/common.service';
import {Spinner} from 'views/Hoc/Spinner';
import {UncontrolledTooltip} from "reactstrap";
import PdfViewer from '../PdfViewer';
import NotificationAlert from "react-notification-alert";
import Dialog from '../Dialog/GlobalDialog';
import ImagePreview from 'views/Hoc/Dialog/ImagePreview';
import axios from 'axios';
import { Radio } from '@material-ui/core';
import ModalImageCropper from '../ModalImageCropper';
var _ = require('lodash');
export const MultiSelectImages=(props)=> {
  console.log('props', props);
  const notificationAlert = useRef(null);
  const { getBrochuresIds, img, aws_folderName, id, isOpen, input, documentIds, limit, acceptedFiles } = props;
  let [loading, setLoading]  = useState(false);
  // let [selectData, setSelectData ] = useState([]);
  let [selectIndex, setSelectIndex] = useState([]);
  let [list, setList] = useState([]);
  let [isModalPopup, setModalPopup]  = useState(false);
  let [modalPopValue, setModalPopValue]  = useState({message:null, title:null, value:null, popUpId:null });
  let [isImg, setImgOpen]  = useState(false);
  let [imgUrl, setImgUrl]  = useState(null);
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  useEffect(() => {
    getCompanyMediaLibrary('/setting/getCompanyMediaLibrary/');
  }, []);

  const handleClose = () => {
    setActiveTab('1');
    props.handleClose();
  };
  const handleUpload=()=>{
    console.log(selectIndex);
    getBrochuresIds(input, selectIndex, 'dummy');
    handleClose();
  }
  const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  //localStorage
  let userData = props.channelData;

    const getCompanyMediaLibrary = async(url)=>{
      setLoading(true);
      const MediaLibrary = await commonService.getServices(url+userData?.channelId);
      console.log("MediaLibrary",MediaLibrary.data);
      list=MediaLibrary.data.filter(x=> !documentIds.includes(parseInt(x.documentId)));
      if(props.id===2){
        const awardsMediaLibrary = await commonService.getServices('/setting/getChannelAwardsAdmin/'+userData?.channelId);
        console.log('awardsMediaLibrary', awardsMediaLibrary.data);
        if(awardsMediaLibrary.data && awardsMediaLibrary?.data?.length>0){
          awardsMediaLibrary.data.map(x=>{
            if(!documentIds.includes(parseInt(x.documentId)))
            list.push(Object.assign(x, {document:{documentId:x.documentId, documentUrl:x.documentUrl}, type:'awards'}))
          })
        }
      }else if(props.id===3){
        const awardsMediaLibrary = await commonService.getServices('setting/getProductAwardsAdmin/'+userData?.channelId);
        console.log('awardsMediaLibrary', awardsMediaLibrary.data);
        if(awardsMediaLibrary.data && awardsMediaLibrary?.data?.length>0){
          awardsMediaLibrary.data.map(x=>{
            if(!documentIds.includes(parseInt(x.documentId)))
            list.push(Object.assign(x, {document:{documentId:x.documentId, documentUrl:x.documentUrl}, type:'productAwards'}))
          })
        }
      }
          setList(list);
          setLoading(false);
    }
    const handleToggle = (value) => {
      const currentIndex = selectIndex.indexOf(value);
      const newChecked = [...selectIndex];
      if (currentIndex === -1) {
        let limit=5-documentIds.length;
        if(newChecked.length>=limit){
          notify('warning','Maximum 5 Images');
          return false;
        }
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      selectIndex=newChecked;
      setSelectIndex(selectIndex);
      console.log("selectIndex",selectIndex);
    };

    const deleteLogo =async(data)=>{
        loading=true;
        console.log('data',data);
        setModalPopup(false);
        setLoading(loading);
        let uploadMediaLibrary;
        if(data.value.data?.type === 'awards'){
          uploadMediaLibrary = await commonService.postService({
              id:data.value.data.id
            }, '/setting/deleteChannelAwards');
        }else if(data.value.data?.type === 'productAwards'){
          uploadMediaLibrary = await commonService.postService({
            id:data.value.data.id
            }, '/setting/deleteProductAwards');
        }else{
          uploadMediaLibrary = await commonService.postService({
            channelDocumentId:data.value.data.channelDocumentId,
            user:{
              channelId:userData?.channelId,
              contactId:userData?.contactId
            }}, '/channelAdmin/deleteCompanyLogo');
        }
          if(uploadMediaLibrary){
            notify('success','successfully deleted...');
            getCompanyMediaLibrary('/setting/getCompanyMediaLibrary/');
          }else{
            notify('warning','Something went wrong!...');
          }
      }
      const imgUrls = (data) =>{
        setLoading(true);
        const promises = data.map(async item => {
          const formData = new FormData();
          formData.append('file', item);
          await commonService.fileUploadService(formData, '/mediaupload/upload/'+aws_folderName)
            .then(async response=> {
              if(response){
                const uploadMediaLibrary = await commonService.postService({
                  Key:response.data.url, 
                  user:{
                    channelId:userData?.channelId
                  }}, '/setting/createCompanyLogo');
                list.unshift(uploadMediaLibrary.data);
                setList(list);
              }
              console.log('response', response);
            return true
          });
        });
      
        Promise.all(promises).then(results => {
          console.log('results', results);
          handleToggle(list[0]);
          toggle('2');
          setLoading(false);
        })
      }
    const selectImg = async(data)=>{
      console.log('data', data);
      if(data?.length)
      imgUrls(data);
    }
    const notify = (type, message) => {
      let options = {
        place: "tr",
        message: (
          <div className="alert-text">
            <span className="alert-title" data-notify="title">
              {/* {" "}
              {type} */}
            </span>
            <span data-notify="message">
              {message}
            </span>
          </div>
        ),
        type: type,
        // icon: "ni ni-bell-55",
        autoDismiss: 5
      };
      notificationAlert.current.notificationAlert(options);
    };
    const openModalPopUp = (data) => {
        modalPopValue.message= 'You want to Delete';
        modalPopValue.title= "Delete Image";
        modalPopValue.value= { data };
      modalPopValue.popUpId= 1;
      setModalPopValue({...modalPopValue})
      setModalPopup(true);
    };
      const previewImg =(data)=>{
        imgUrl = data.document.documentUrl;
        setImgUrl(imgUrl);
        isImg=true;
        setImgOpen(isImg);
      }
  return (
    <div>
      <ImagePreview imgModalClose={()=>setImgOpen(false)} isOpen={isImg} url={imgUrl}/>
      <NotificationAlert ref={notificationAlert}/>
      <Dialog open={isModalPopup} value={modalPopValue} handleSave={deleteLogo} handleClose={()=>setModalPopup(false)}></Dialog>
      <Modal scrollable={true}
          className="modal-dialog-centered"
          isOpen={isOpen} size="lg">
          <div className="modal-header">
          <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            Upload File
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
          Media library
          </NavLink>
        </NavItem>
     
      </Nav>
     
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={ handleClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
          
          </div>
          <div className="modal-body p-0">
       
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
        {loading? <Spinner/> : <Row>
            <Col sm="12">
            <MultiUpload limit={limit} acceptedFiles={acceptedFiles} handleChange={selectImg} input={input}/>

            </Col>
          </Row>}
        </TabPane>
        <TabPane tabId="2">
        {list.length <= 0 && <div className="no-records">
          {/* <span>No Records</span> */}
          <div className="no-img text-center">
          <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")}/></div>
        </div>}
        {loading? <Spinner/> :<>
          <div className="container">
        <div className="dropdown-divider m-0"></div>
        </div>
          <Row>
    {list.map((x, i)=>{
      return(
        <Col sm="2" lg="2" className="mr-3">
        <div className="brandCard" onClick={()=>handleToggle(x)}>
        <GreenCheckbox style={{width:'50px',position:'absolute',top:'-12px',right:'-15px', zIndex:'1000'}}  checked={selectIndex.indexOf(x) !== -1} tabIndex={-1} name='check' />
            <div  className="pdfContiner">
              
            {/* <PdfViewer className="pdf-view" edit={false} pdf={x.document.documentUrl} /> */}
            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="lib-img" type="img/img" src={x.document.documentUrl}/>
          </div>
          <div className="row medd-view">
          <div className="col-6 text-right">
            <div id='eye' className="editeye text-center" onClick={()=>previewImg(x)}>
            <i  class="fa fa-eye"></i> 
          </div> 
          <UncontrolledTooltip content='preview video' target='eye' placement='bottom'>
              preview
            </UncontrolledTooltip></div>
           <div className="col-6 text-left">
             <div  id='delete' className="deleteicon text-center" onClick={()=>openModalPopUp(x)}>
           <i class="fas fa-trash-alt"></i>
          </div>
          <UncontrolledTooltip content='Delete video' target='delete' placement='bottom'>
              delete
            </UncontrolledTooltip></div>
           </div>
        </div>
       </Col>
      )
    })}
          </Row></>}
        </TabPane>
      </TabContent>
          </div>
          <div className="modal-footer">
          {activeTab === '2' &&<>
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={handleUpload} disabled={selectIndex.length<=0}>
              Upload
            </Button></>}
          </div>
        </Modal>
       
    </div>
  );
}


export const ImageLibrary=(props)=> {
  const { getDocumentId, aws_folderName, id, isOpen, input, documentIds, selectedId } = props;
  let [loading, setLoading]  = useState(false);
  let [selectData, setSelectData ] = useState(null);
  let [selectIndex, setSelectIndex] = useState(null);
  let [list, setList] = useState([]);
  let [isImg, setImgOpen]  = useState(false);
  let [imgUrl, setImgUrl]  = useState(null);
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  useEffect(() => {
    getCompanyMediaLibrary('/setting/getCompanyMediaLibrary/');
  }, []);
  const handleClickOpen = () => {
    if(id===1 && id===2){
      
    }
  };

  const handleClose = () => {
    setActiveTab('1');
    props.handleClose();
  };
  const handleUpload=()=>{
    props.handleClose();
    if(id===4)
    getDocumentId(input, selectData, selectedId);
    else if(id===5)
    getDocumentId(input, selectData, selectedId);
    else
    getDocumentId(selectData);
  }
  const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  //localStorage
  let userData = props.channelData;

    const getCompanyMediaLibrary = async(url)=>{
      loading=true;
      setLoading(loading);
      const MediaLibrary = await commonService.getServices(url+userData?.channelId);
      console.log("MediaLibrary",MediaLibrary.data);
      if(documentIds)
      list=MediaLibrary.data.filter(x=> !documentIds.includes(parseInt(x.documentId)));
      else
      list=MediaLibrary.data;
      if(id===4){
        const awardsMediaLibrary = await commonService.getServices('setting/getProductReview/'+userData?.channelId);
        console.log('awardsMediaLibrary', awardsMediaLibrary.data);
        if(awardsMediaLibrary.data && awardsMediaLibrary?.data?.length>0){
          awardsMediaLibrary.data.map(x=>{
            if(!documentIds.includes(parseInt(x.documentId)))
            list.push(Object.assign(x, {document:{documentId:x.documentId, documentUrl:x.documentUrl}, type:'productReviews'}))
          })
        }
      }else if(id===5){
        const awardsMediaLibrary = await commonService.getServices('setting/getEndUserReview/'+userData?.channelId);
        console.log('awardsMediaLibrary', awardsMediaLibrary.data);
        if(awardsMediaLibrary.data && awardsMediaLibrary?.data?.length>0){
          awardsMediaLibrary.data.map(x=>{
            if(!props?.documentIds.includes(parseInt(x.documentId)))
            list.push(Object.assign(x, {document:{documentId:x.documentId, documentUrl:x.documentUrl}, type:'endUserReviews'}))
          })
        }
      }
      list = _.uniqBy(list, 'documentId')
      setList(list);
      loading=false;
      setLoading(loading);
      handleChange(null,null);
    }

    const handleChange = (index, data) => {
      selectIndex = index;
      setSelectIndex(selectIndex);
      selectData=data;
      setSelectData(selectData);
    };

    const [image, setImage] = useState(null);

    const handleOnDrop = async(data) => {
      if(data.length > 0){
        console.log('datat', data);
        let file = data[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          console.log('file', file)
          console.log('reader.result' ,reader.result)
          setImage(
            reader.result
          );
          console.log('image', image)
          //setOpen(false);          
        };
        
      }
    }

    function dataURLtoFile(dataurl, filename) {
 
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
  }

  const handleCropClose = () => {
    setImage('');
  }

    const selectImg = async(data)=>{
      if(data.length>0){
        loading=true;
        
        setLoading(loading);
        setImage('');
        //converting base64 string to file format.
        var random = Math.random(); 
        var fileName =random +'.png';
        var file = dataURLtoFile(data, fileName);    
        const formData = new FormData();
        formData.append('file', file);

        const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/'+aws_folderName);
      if(resImagePath){
        const uploadMediaLibrary = await commonService.postService({
          Key:resImagePath.data.url, 
          user:{
            channelId:userData?.channelId
          }}, '/setting/createCompanyLogo');
        list.unshift(uploadMediaLibrary.data);
        setList(list);
        handleChange(0, list[0]);
        toggle('2');
        loading=false;
        setLoading(loading);
      }else{
        loading=false;
        setLoading(loading);
      }
      
      }
      // setOpen(false);
    }
    const deleteLogo =async(data)=>{
      loading=true;
      setLoading(loading);
      let uploadMediaLibrary;
      if(data.value.data?.type === 'productReviews'){
        uploadMediaLibrary = await commonService.postService({
          id:data.value.data.id
          }, '/setting/deleteProductReview');
      }else if(data.value.data?.type === 'endUserReviews'){
        uploadMediaLibrary = await commonService.postService({
          id:data.value.data.id
          }, '/setting/deleteEndUserReview');
      }
      else{
        uploadMediaLibrary = await commonService.postService({
        channelDocumentId:data.channelDocumentId,
        user:{
          channelId:userData?.channelId,
          contactId:userData?.contactId
        }}, '/channelAdmin/deleteCompanyLogo');
      }
      if(uploadMediaLibrary){
        // notify('success','successfully deleted...');
        getCompanyMediaLibrary('/setting/getCompanyMediaLibrary/');
      }else{
        // notify('warning','Something went wrong!...');
      }
    }
    const imageModalClose = () => {
      isImg=false;
      setImgOpen(isImg);
    };
    const previewImg =(data)=>{
      imgUrl = data.document.documentUrl;
      setImgUrl(imgUrl);
      isImg=true;
      setImgOpen(isImg);
    }
  return (
    <div>
      <Modal scrollable={true}
          className="modal-dialog-centered"
          isOpen={isOpen} size="lg">
          <div className="modal-header">
          <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            Upload File
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
          Media library
          </NavLink>
        </NavItem>
     
      </Nav>
     
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={ handleClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
          
          </div>
          <div className="modal-body p-0">
       
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          {loading? <Spinner/> : <Row>
            <Col sm="12">
            <MultiUpload acceptedFiles={['image/jpeg','image/png','image/gif']} handleChange={handleOnDrop} size={props.size}/>

            </Col>
          </Row>}
        </TabPane>
        <TabPane tabId="2">
        {list.length<=0 && <div className="no-records">
          {/* <span>No Records</span> */}
          <div className="no-img text-center">
          <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")}/></div>
        </div>}
        {loading? <Spinner/> :<>
          <div className="container">
        <div className="dropdown-divider m-0"></div>
        </div>
          <Row>
    {list.map((x, i)=>{
      return(
        <Col sm="2" lg="2" className="mr-3">
        <div className="brandCard" onClick={()=>handleChange(i,x)}>
        <GreenCheckbox style={{width:'50px',position:'absolute',top:'-12px',right:'-15px'}} checked={selectIndex === i } name="checked" />
            <div  className="imgContiner">
              
        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="lib-img" type="img/img" src={x.document.documentUrl}/>
          </div>
          <div className="row medd-view">
          <div className="col-6 text-right">
            <div id='eye' className="editeye text-center"  onClick={()=>previewImg(x)}>
            <i  class="fa fa-eye"></i> 
          </div> 
          <UncontrolledTooltip content='preview video' target='eye' placement='bottom'>
              preview
            </UncontrolledTooltip></div>
           <div className="col-6 text-left">
             <div  id='delete' className="deleteicon text-center" onClick={()=>deleteLogo(x)}>
           <i class="fas fa-trash-alt"></i>
          </div>
          <UncontrolledTooltip content='Delete video' target='delete' placement='bottom'>
              delete
            </UncontrolledTooltip></div>
           </div>
        </div>
       </Col>
      )
    })}
          </Row></>}
        </TabPane>
      </TabContent>

          </div>
          <div className="modal-footer">
          {activeTab === '2' &&<>
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={()=>handleUpload(selectData)} disabled={selectIndex === null}>
              Upload
            </Button></>}
          </div>
          <ImagePreview imgModalClose={imageModalClose} isOpen={isImg} url={imgUrl}/>
        </Modal>
        {image ? <ModalImageCropper isOpen={true} src={image} handleChange={selectImg} handleCropClose={handleCropClose}/> : <></>}
    </div>
    
  );
}