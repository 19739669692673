import React from "react";
import axios from 'axios';
import {
    Container,
  Row,
  Col,
  
} from "reactstrap";

// core components


import SimpleHeader from "components/Headers/SimpleHeader.js";
class ComingSoon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNav: 1,
     
    };

  }

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
   
    });
  };
  render() {
    const img=require("assets/img/brand/coming-soon-dashboard.png");
    return (
      <>
        <SimpleHeader name="Coming Soon" parentName="Dashboard" />
      <Container className="text-center pt-2">
     
       <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
       alt="as"
                className="img-fluid"
                src={img}
              />
              </Container>
      </>
    );
  }
}

export default ComingSoon;
