import React, { useState } from 'react'
import {
    Card, Row, CardHeader, CardBody,
    Button,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader";
import * as xlsx from 'xlsx';
import { Spinner } from '../../Hoc/Spinner';

const template = [
    ['channelId', 'signUpStatusId', 'companyName', 'companyMail', 'countryId', 'showId', 'oldChannelId', 'profileTypeId', 'channelTypeId[2:vendor or 3:dist or 4:retailer or 5:reseller]', 'regAddressId', 'billAddressId', 'isActive[0 or 1]', 'deletedAt', 'createdAt', 'updatedAt', 'profileStrength', 'planId', 'subscriptionId', 'stripeCustomerId', 'autoRenewalOff', 'slug', 'isFrenchTech[0 or 1]', 'isAdminCreated[0 or 1]', 'stripePaymentMethodId', 'hubspotCompanyId', 'actAsDistributor', 'brandFlag', 'isOEM[1:B2C or 2:B2B or 3:Both]', 'isPublic', 'isCompanyPublic', 'isFeatured', 'approvedDate', 'isTestAccount', 'approvalReminderCount', 'fromEvent', 'eventId', 'estYear[2022]', 'regYear[2022]', 'detailDesc', 'empCntRange[0-10]', 'empCnt[10]', 'turnoverRange[]', 'turnover[]', 'revD', 'revI', 'phone1', 'phone2', 'mobileNo', 'webSiteUrl', 'channelLogoId', 'channelIconId', 'turnoverPhyStore', 'turnoverOnStore', 'ownerFirstName', 'ownerLastName', 'stripeConnectId', 'paymentoptionothers', 'currencyid', 'mystatus', 'startup', 'storeCount', 'expectations', 'keyretailers', 'maturitylevel', 'no_of_skus', 'no_of_retailers', 'no_of_distributors', 'no_of_vendors', 'keydistributors', 'introvideoId', 'channelBannerId', 'no_of_resellers', 'keyresellers', 'isPhysicalSales', 'physicalSales', 'companyDesc', 'shareStatus', 'bannerURL'],
    ['channelId', 'firstName', 'lastName', 'phone1', 'phone2', 'mobileNo', 'email', 'jobTitle', 'linkedIn', 'documentId', 'countryId', 'isDefaultContact[0 or 1]', 'isShared', 'isActive', 'createdAt'],//contact
    ['channelid', 'year', 'turn_over'],//trunover
    ['contactId', 'channelId', 'productGroupId', 'productCategorieId'],//product
    ['channelId', 'countryId'],//location

]

export default function ImportChannel() {
    const [files, setFiles] = useState(null);
    const [data, setData] = useState();
    let [loading, setLoading] = useState(false);

    const downloadBrand = (id) => {
        var fileDownload = require('js-file-download');
        let fileName;
        if (id == 0)
            fileName = 'Channel.csv';
        else if (id == 1)
            fileName = 'User.csv';
        else if (id == 2)
            fileName = 'Turnover.csv';
        else if (id == 3)
            fileName = 'Product.csv';
        else if (id == 4)
            fileName = 'Location.csv';

        setLoading(true);
        fileDownload(template[id], fileName);
        setLoading(false);
    }


    const onFileChange = (e) => {
        setFiles(e.target.files[0]);

        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = xlsx.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = xlsx.utils.sheet_to_json(worksheet);
            console.log("json:", json);
            setData(json);

        };
        reader.readAsArrayBuffer(e.target.files[0]);
    }

    const onFileUpload = () => {
        console.log(data);
    }

    return (
        <div className="main-content">
            <SimpleHeader />
            {loading ? <Spinner /> : <>
                <Card>
                    <CardHeader>
                        <h3 className="text-center">Channels Primary Data</h3>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <div>
                                <button class="btn btn-primary btn-sm pt-2  " type="button" onClick={() => downloadBrand(0)}><i class="fa fa-download" aria-hidden="true"></i></button>
                                <input type="file" onChange={onFileChange} />
                                <Button onClick={() => onFileUpload()}>
                                    Upload!
                                </Button>
                            </div>
                        </Row>
                    </CardBody>
                </Card>

                <Card>
                    <CardHeader>
                        <h3 className="text-center">User Data</h3>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <div>
                                <button class="btn btn-primary btn-sm pt-2  " type="button" onClick={() => downloadBrand(1)}><i class="fa fa-download" aria-hidden="true"></i></button>
                                <input type="file" onChange={onFileChange} />
                                <Button onClick={() => onFileUpload()}>
                                    Upload!
                                </Button>
                            </div>
                        </Row>
                    </CardBody>
                </Card>

                <Card>
                    <CardHeader>
                        <h3 className="text-center">TurnOver </h3>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <div>
                                <button class="btn btn-primary btn-sm pt-2  " type="button" onClick={() => downloadBrand(2)}><i class="fa fa-download" aria-hidden="true"></i></button>
                                <input type="file" onChange={onFileChange} />
                                <Button onClick={() => onFileUpload()}>
                                    Upload!
                                </Button>
                            </div>
                        </Row>
                    </CardBody>
                </Card>

                <Card>
                    <CardHeader>
                        <h3 className="text-center">Product </h3>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <div>
                                <button class="btn btn-primary btn-sm pt-2  " type="button" onClick={() => downloadBrand(3)}><i class="fa fa-download" aria-hidden="true"></i></button>
                                <input type="file" onChange={onFileChange} />
                                <Button onClick={() => onFileUpload()}>
                                    Upload!
                                </Button>
                            </div>
                        </Row>
                    </CardBody>
                </Card>

                <Card>
                    <CardHeader>
                        <h3 className="text-center">Selling/Target Locations </h3>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <div>
                                <button class="btn btn-primary btn-sm pt-2  " type="button" onClick={() => downloadBrand(4)}><i class="fa fa-download" aria-hidden="true"></i></button>
                                <input type="file" onChange={onFileChange} />
                                <Button onClick={() => onFileUpload()}>
                                    Upload!
                                </Button>
                            </div>
                        </Row>
                    </CardBody>
                </Card>



            </>}
        </div>

    );

}

