import React, { useEffect, useState } from "react";
import { FormInput, FormFileInput, FormSelect, FormButton, FormMultiSelect, FormTextArea, FormCheckBox, FormAutocomplete, FormRadio, NewFieldAutocomplete, } from "_components/FormElements/FormInput";
import {
  Row, Card, CardBody, CardHeader, Container
} from "reactstrap";
import { PdfCard, ImageCard } from "views/Hoc/PdfCard";
import { useForm } from 'react-hook-form';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { MultiSelectImages } from 'views/Hoc/Dialog/MultiSelectImages';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IncrementDecrementBtn from './IncDncbtn';
import AsyncNewFieldAutocomplete from "_components/FormElements/FormInput.js";
import axios from 'axios';
import RoomNames from './roomname.js'
import ImageUpload from './roomimage';
import { Box, Checkbox, ListItemText } from "@material-ui/core";
import Modal from '@mui/material/Modal';
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import FormHelperText from '@material-ui/core/FormHelperText';
import { history } from '_helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import { ModalSpinner } from "views/Hoc/Spinner.js";
import { ProfileUploader } from "views/Hoc/ProfileUpload.js";
import { commonService } from '_services/common.service';
import OutlinedInput from '@mui/material/OutlinedInput';
import CloseIcon from '@mui/icons-material/Close';

var _ = require('lodash');


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const Hotels = (props) => {
  const { eventId = '', companyList = [] } = props.values || {};
  const hotelId = props.location.pathname.split('/').filter(Boolean).pop();
  const [countryList, setCountryList] = useState([])
  const [fieldError, setFieldError] = useState(false)
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleteHotelImage, setDeleteHotelImage] = useState([])
  const [image, setImage] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [selectRoomNameArray, setSelectRoomNameArray] = useState([])
  const names = ['VENDOR', 'DISTRIBUTOR', 'RETAILER', 'RESELLER'];
  const [roomId, setRoomId] = useState(null);
  const[hotelBookingData, setHotelBookingData] = useState([])
  const handleChangeOnMultiSelect = (event) => {
    const { target: { value }, } = event;
    setRoomDetail({ ...roomDetail, ["participantType"]: typeof value === 'string' ? value.split(',') : value });
  };

  const handleClose = () => {
    setOpen(false);
    setHotelFlag(false)
    setHotelFlag([])
  }


  const { register, control, errors } = useForm({
    mode: 'onBlur'
  });

  useEffect(() => {
    localStorage.setItem('previousPath', 'admin/hotel');
    if (hotelId != "hotel") {
      getEventHotelData(hotelId)
    }
    getCountryList()
  }, [])

  const handleCropClose = () => {
    setImage('');
  }

  const handleChangeImage = event => {
    const data = event.target.files[0];
    setError('');
    if (data && data['type'].split('/')[0] === 'image') {
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImage(
          reader.result
        );
      };

    } else {
      setError('Jpg | Jpeg | PNG Image Formats Only Accepted');
    }

  };

  const selectImg = async (data) => {
    if (data) {
      setImage('');
      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + "hotelLogo");
      if (resImagePath) {
        setImageSrc(resImagePath.data.url);
        setHotelInfromation({ ...hotelInfromation, hotelLogo: resImagePath.data.url });
        setLoading(false);
      }
    }
  }


  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }


  const handleTimeZone = (time) => {
    errors.timezone = false;
    setHotelInfromation({ ...hotelInfromation, timezone: time });
  }


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [update, setUpdate] = useState(false)
  const [editIndex, setEditIndex] = useState(null)
  const [hotelFlag, setHotelFlag] = useState(false)
  const [hotelInfromation, setHotelInfromation] = useState({
    timezone: 'Europe/Paris'
  })
  const [roomDetail, setRoomDetail] = useState({});
  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null });
  const [deleteIndex, setdeleteIndex] = useState(null)
  const [deleteIndexArray, setDeleteIndexArray] = useState([])
  const handleTimeChange = (time, name) => {
    setHotelInfromation({ ...hotelInfromation, [name]: time });
  }
  const [deleteId, setDeleteId] = useState()

  const roomIsBooked = (roomId) =>{
   let roomBooked = false;
   for(let i=0; i<hotelBookingData.length; i++){
      if(hotelBookingData[i].roomTypeId == roomId){
        roomBooked = true;
        break;
      }
   }
   return roomBooked;
  }

  const openModalPopUp = (index, id) => {
    if(!roomIsBooked(id)){
      modalPopValue.title = 'Delete Room';
      modalPopValue.message = 'You want to Delete Room Details';
      modalPopValue.popUpId = 1;
      setModalPopValue({ ...modalPopValue })
      setdeleteIndex(index)
      setDeleteId(id)
      setModalPopup(true);
    }else{
      ToastsStore.warning("You are unable to delete this room type because there are bookings associated with it");
    }

  
  
  };

  const deleteRoomDetail = () => {
    const updateRoomDetail = [...hotelRoomDetails]
    updateRoomDetail.splice(deleteIndex, 1)
    setDeleteIndexArray([...deleteIndexArray, deleteId])
    setHotelRoomDetail(updateRoomDetail);
    setModalPopup(false)
    ToastsStore.success("Hotel Room delete successfully")
  }

  const hotelInformationOnChange = (e) => {
    const { name, value } = e.target;
    setHotelInfromation({ ...hotelInfromation, [name]: value });
  }

  const handleTimeChangeForRoom = (e) => {
    if (e == null) return
    setRoomDetail({ ...roomDetail, ["date"]: e })
  }
  const hotelRoomDetailOnChange = (e, IncrementDecrementBtnData, roomNames) => {
    if (roomNames) {
      setRoomDetail({ ...roomDetail, ["roomName"]: roomNames });
      return
    } else if (IncrementDecrementBtnData) {
      const name = IncrementDecrementBtnData.name
      const value = IncrementDecrementBtnData.counter
      setRoomDetail({ ...roomDetail, [name]: value });
      return
    }
    if (e == null) return
    const { name, value } = e.target;
    setRoomDetail({ ...roomDetail, [name]: value });
  };

  const [hotelRoomDetails, setHotelRoomDetail] = useState([]);

  const find = (value) => {
    return selectRoomNameArray.some((item) => item == value);
  };

  const validation = () => {
    if (roomDetail.roomName == null || roomDetail.roomName == "" || roomDetail.roomName == " ") {
      ToastsStore.warning('Please Select Room Name');
      return false;
    } else if (roomDetail.roomCount == null) {
      ToastsStore.warning('Please Select Room Count');
      return false;
    } else if (roomDetail.gueststay == null) {
      ToastsStore.warning('Please Select Guest Stay');
      return false;
    } else if (roomDetail.participantType == (null || undefined) || roomDetail.participantType.length == 0) {
      ToastsStore.warning('Please Select Participant Type');
      return false;
    } else if (roomDetail.price == null) {
      ToastsStore.warning('Please Select Price');
      return false;
    } else if (roomDetail.date == null) {
      ToastsStore.warning('Please Select Room Avaliable Date');
      return false;
    } else if (images2 == 0) {
      ToastsStore.warning('Please Select Room Image');
      return false;
    } else {
      return true
    }
  }


  const saveSingleHotelRoomDetail = () => {
    if (validation() == false) {
      return
    }

    if (update == false) {
      const roomDetails = roomDetail;
      roomDetail.image = images2;
      setHotelRoomDetail([...hotelRoomDetails, roomDetails]);
      ToastsStore.success('Room add successfully');
    } else if (update == true) {
      const roomDetails = roomDetail;
      roomDetail.image = images2;
      const updateRoomDetail = [...hotelRoomDetails]
      updateRoomDetail[editIndex] = roomDetails
      setHotelRoomDetail(updateRoomDetail);
      setUpdate(false)
      ToastsStore.success('Room update successfully');
    }

    setSelectRoomNameArray([...selectRoomNameArray, roomDetail.roomName])

    setHotelFlag(false)
    setRoomDetail({})
    setImages2([])
  };

  const editHotelDetailRoom = (index, roomId) => {
    setRoomDetail(hotelRoomDetails[index])
    setImages2(hotelRoomDetails[index].image)
    setUpdate(true)
    setEditIndex(index)
    setHotelFlag(true)
    setRoomId(roomId)
  }

  let [awardImage, setAwardImage] = useState(false);

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [images, setImages] = useState([])
  const [images2, setImages2] = useState([])

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };
  const [associate, setAssociate] = useState(true);
  let [companyHsList, setCompanyHsList] = useState(companyList?.length ? companyList : []);
  let handleInput = '';
  let comp = {};
  const { handleChange, handleChangeSelect, selectedItem } = props;
  const onChangeHandle = async value => {
    console.log(value);
    handleInput = value;

    const apiUrl = '/Hubspot/getCompanyListSearch?search=' + value;
    axios.get(apiUrl).then(res => {
      if (!!companyList.length)
        companyHsList = companyList;
      companyHsList.push(...res.data);
      setCompanyHsList(companyHsList);
      console.log('companyHsList', companyHsList);
    });
  };
  let [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (!open) {
      companyHsList = companyList;
      setCompanyHsList(companyHsList);
    }
  }, [open]);

  const checkReqField = () => {
    if (
      imageSrc == null || imageSrc == '' ||
      hotelInfromation.hotelName == null || hotelInfromation.hotelName == '' ||
      hotelInfromation.HotelTelephone == null || hotelInfromation.HotelTelephone == '' ||
      hotelInfromation.address == null || hotelInfromation.address == '' ||
      hotelInfromation.city == null || hotelInfromation.city == '' ||
      hotelInfromation.hotelMobile == null || hotelInfromation.hotelMobile == '' ||
      hotelInfromation.designation == null || hotelInfromation.designation == '' ||
      hotelInfromation.pincode == null || hotelInfromation.pincode == '' ||
      hotelInfromation.rail == null || hotelInfromation.rail == '' ||
      hotelInfromation.state == null || hotelInfromation.state == '' ||
      hotelInfromation.website == null || hotelInfromation.website == '' ||
      hotelInfromation.email == null || hotelInfromation.email == '' ||
      !hotelInfromation.email.includes('@') ||
      hotelInfromation.timezone == null || hotelInfromation.timezone == ''
    ) {
      setFieldError(true);
      return true;
    } else {
      return false
    }

  }

  const createHotel = async () => {
    const apiUrl = '/channelAdmin/createHotel';

    // if(checkReqField() == true){
    // return;
    // }
    setLoading(true)
    const promises = images.map(image => selectImgagesToCloud(image, image.caption, "hotelImage"));
    const imagesData = await Promise.all(promises);
    const hotelRoomDetails_data = hotelRoomDetails;

    for (let i = 0; i < hotelRoomDetails_data.length; i++) {
      console.log(hotelRoomDetails_data[i].image)
      const promises = hotelRoomDetails_data[i].image.map(image => selectImgagesToCloud(image, image.caption, "roomTypeImage"));
      const imagesData = await Promise.all(promises);
      hotelRoomDetails_data[i].image = imagesData
    }


    const hotelData = {
      hotelInfromation: hotelInfromation,
      images: imagesData,
      hotelRoomDetails: hotelRoomDetails_data
    }

    await axios.post(apiUrl, {
      hotelData
    }).then(res => {
      history.push({ pathname: '/admin/eventListing' })
      ToastsStore.success('Hotel Created Successfully!');
    }).catch((e) => {
      console.log(e)
      ToastsStore.warning('Something went wrong!');
    })

  }


  const updateHotel = async (id) => {
    const apiUrl = `/channelAdmin/updateHotel/${id}`;
    if (checkReqField() == true) {
      return;
    }

    setLoading(true)
    const promises = images.map(image => selectImgagesToCloud(image, image.caption, "hotelImage"));
    const imagesData = await Promise.all(promises);

    const hotelRoomDetails_data = hotelRoomDetails;

    for (let i = 0; i < hotelRoomDetails_data.length; i++) {
      console.log(hotelRoomDetails_data[i].image)
      const promises = hotelRoomDetails_data[i].image.map(image => selectImgagesToCloud(image, image.caption, "roomTypeImage"));
      const imagesData = await Promise.all(promises);
      hotelRoomDetails_data[i].image = imagesData
    }

    const hotelData = {
      hotelInfromation: hotelInfromation,
      images: imagesData,
      hotelRoomDetails: hotelRoomDetails_data,
      deleteHotelImages: deleteHotelImage
    }


    await axios.put(apiUrl, {
      hotelData,
      deleteIndexArray
    }).then(res => {
      history.push({ pathname: '/admin/eventListing' })
      ToastsStore.success('Hotel Updated Successfully!');
    }).catch((e) => {
      console.log(e)
      ToastsStore.warning('Something went wrong!');
    })
  }

  const createAndUpdateHotel = async () => {
    if (hotelId != null && hotelId != undefined && hotelId != "hotel") {
      await updateHotel(hotelId)
    } else {
      await createHotel()
    }
  }


  async function getEventHotelData(id) {
    setLoading(true);
    await axios.get(`channelAdmin/getEventHotel/${id}`).then((res) => {
      const roomDetail = res.data.HotelRoomsDetail
      const roomParticipantType = res.data.roomParticipantsType
      const roomTypeImages = res.data.roomTypeImages
      for (let i = 0; i < roomDetail.length; i++) {
        let image = []
        let index = 0;
        for (let j = 0; j < roomTypeImages.length; j++) {
          if (roomTypeImages[j].roomId == roomDetail[i].id) {
            image[index] = { documentPath: roomTypeImages[j].documentPath, caption: roomTypeImages[j].caption }
            index++;
          }

        }
        roomDetail[i].image = image
      }

      for (let i = 0; i < roomDetail.length; i++) {
        const array = []
        for (let j = 0; j < roomParticipantType.length; j++) {
          if (roomDetail[i].id === roomParticipantType[j].roomId) {
            array.push(roomParticipantType[j].participantsType)
          }
        }
        roomDetail[i].participantType = array;
      }


      setHotelInfromation(res.data.hotelData[0])
      setHotelRoomDetail(roomDetail)
      setHotelBookingData(res.data.hotelBookingData)
      const roomNameData = roomDetail.map((item) => item.roomName)
      setSelectRoomNameArray(roomNameData)
      setImageSrc(res.data.hotelData[0].hotelLogo)
      setLoading(false)
    }).catch((err) => console.log(err))
  }

  async function getCountryList() {
    let countryListData = [];
    await axios.get('/channelAdmin/getCountryList/').then(res => {
      countryListData = res.data;
      countryListData = _.sortBy(countryListData, (e) => {
        return e.name.toLowerCase()
      })
      setCountryList(countryListData)
    });
  }

  const checkCompanyName = async (id) => {
    const apiUrl = 'account/checkCompanyName/';

    await axios.post(apiUrl, {
      name: comp.name,
      eventId: eventId
    }).then(res => {

      if (res.data.isavialble == true) {
        setAssociate(true);
        if (id == 1) {
          //handleChangeSelect('signUpUser', { channelId: res.ch_id, userId: res.user_id });
          props.handleNext();
        }
        if (typeof (res.data.isavialble) == "object" && id == 3)
          handleChangeSelect('signUpUser', { signUpStatusId: res.signUpStatusId, accountContactId: res.accountContactId, channelId: res.ch_id, userId: res.user_id });

      }
      else {
        console.log('error already taken mail')
        // setCompanyError(1);    
        if (id == 1 && associate == false) {
          props.handleNext();
          //handleChangeSelect('signUpUser', { channelId: res.ch_id, userId: res.user_id });
        }
        // else if (id == 3) {
        //   setAssociate(false);
        //   handleChangeSelect('signUpUser', { channelId: res.ch_id, userId: res.user_id });
        // }

      }



    })

  }
  //Multiupload
  const [mediaState, setMediaState] = useState({
    productBrochures: [],
    bannerImages: [],
    companyImages: []
  });


  const [State, setState] = useState({
    awards: [],
  });
  const deleteAwards = (data) => {
    if (data?.id) {
      State.savedAwards.filter((item) => {
        if (data.id == item.id)
          State.deletedAwards.push(item.id);
      });
      const filteredList = State.awards.filter((item) => item.id !== data.id);
      State.awards = filteredList;
      setState({ ...State, awards: State.awards, deletedAwards: State.deletedAwards });
    } else {
      const filteredList = State.awards.filter((item) => item.documentId !== data.documentId);
      State.awards = filteredList;
      setState({ ...State, awards: State.awards });
    }
  }


  const handleChangeFileSelect = async (input, value, filename) => {
    if (input === 'companyImages') {
      console.log('companyImages');
      let temp = mediaState.companyImages;
    } else if (input === 'awardsImages') {
      console.log('awardsImages');
      let temp = State.awards;
    } else {
      let res = await handleOnDrop(input, value, filename);
      if (res) {
        console.log('res', res);
        setMediaState({ ...mediaState, [input]: value })
      };
    }
  }


  //functions for image
  const handleOnDrop = async (input, data, file_name) => {
    if (data) {
      setLoading(true);
      console.log('datat', data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log('file', file)
        console.log('reader.result', reader.result)
        // selectImg(input, reader.result, file_name)
        //setOpen(false);          
      };

    }
  }
  const openAwardMediaLib = () => {
    if (State.awards.length >= 5) {
      ToastsStore.error('Maximum 5 Images');
    } else {
      setAwardImage(true);
    }
  }
  const handleChangeRoomName = (input, value) => {
    if (value)
      comp = value;
    else
      comp = { name: "", id: "0" };
    if (comp.name)
      checkCompanyName(2)
    handleChangeSelect(input, value);
  }
  const handleKeyDown = (event) => {
    switch (event.key) {
      case "Tab": {
        if (handleInput.length > 0) {
          handleChangeRoomName('companyName', { name: handleInput });
          setValue('companyName', { name: handleInput });
        }
        break;
      }
      case "Enter": {
        if (handleInput.length > 0) {
          handleChangeRoomName('companyName', { name: handleInput });
          setValue('companyName', { name: handleInput });
        }
        break;
      }
      default:
    }
  };

  const handleRoomImage = (images) => {
    setImages(images)
  }

  const handleRoomImage2 = (images2) => {
    setImages2(images2)
  }

  const selectImgagesToCloud = async (data, caption, imageType) => {
    if (data.documentPath != (null && undefined) && imageType == "roomTypeImage") {
      return { url: data.documentPath, caption: caption }
    }
    if (data.image == null || undefined) {
      return null
    }
    data = data.image
    if (data.length > 0) {
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);
      const res = await commonService.fileUploadService(formData, `/mediaupload/upload/${imageType}`);
      if (res) {
        return { url: res.data.url, caption: caption }
      }

    }
  }

  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const counters = [
    { minValue: 0, maxValue: 100 }
  ];


  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleAddRoom = () => {
    setHotelFlag(true);
    setRoomDetail({});
    setImages2([]);
  }

  return (
    <>
      {
        loading && <ModalSpinner />
      }

      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />

      <div className="main-content" style={{ display: loading ? "none" : "block" }}>
        <Container className="mt-4 col-lg-9 col-md-9 col-sm-12  " fluid>
          {awardImage &&
            <MultiSelectImages
              id={2}
              isOpen={awardImage}
              multiple={true}
              limit={5}
              acceptedFiles={['image/jpeg', 'image/png', 'image/gif']}
              getBrochuresIds={handleChangeFileSelect}
              input="awardsImages" aws_folderName="awards"
              handleClose={() => {
                setAwardImage(false)
              }}
              documentIds={State.awards.length > 0 ? State.awards.map(x => parseInt(x.documentId)) : []}
            // channelData={userData}
            />
          }
          <Card style={{ padding: "20px" }}>
            <CardHeader style={{ display: "flex", justifyContent: "space-between" }}>


              <div>
                <h3 className="text-left">Hotel Information</h3>
                <p className="text-left">{props.location.pathname == "/admin/hotel" ? "Add your Hotel details here" : "Update your Hotel details here"}</p>
              </div>
              <div>
                <button className=" btn btn-warning btn-sm" style={{ borderRadius: "100%" }} onClick={() => history.push({ pathname: '/admin/eventListing' })}>x</button>
              </div>

            </CardHeader>
            <div className="row">

              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <FormInput
                  className="comp-name mt-4"
                  id="hotelName"
                  name="hotelName"
                  placeholder=""
                  label="Name of the Hotel *"
                  type="text"
                  variant="outlined"

                  error={fieldError && (!hotelInfromation.hotelName || hotelInfromation.hotelName.trim().length === 0)}
                  value={hotelInfromation.hotelName != null ? hotelInfromation.hotelName : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 50) {
                      hotelInformationOnChange(e);
                    } else {
                      const truncatedValue = inputValue.slice(0, 50);
                      e.target.value = truncatedValue;
                      hotelInformationOnChange(e);
                    }
                  }}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <FormInput
                  className="comp-name mt-4"
                  id="address"
                  name="address"
                  placeholder=""
                  label="Address *"
                  type="text"
                  variant="outlined"
                  error={fieldError && (!hotelInfromation.address || hotelInfromation.address.trim().length === 0)}
                  value={hotelInfromation.address != null ? hotelInfromation.address : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 100) {
                      hotelInformationOnChange(e);
                    } else {
                      const truncatedValue = inputValue.slice(0, 100);
                      e.target.value = truncatedValue;
                      hotelInformationOnChange(e);
                    }
                  }}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <FormInput
                  className="comp-name mt-4"
                  id="city"
                  name="city"
                  placeholder=""
                  label="City *"
                  type="text"
                  variant="outlined"
                  error={fieldError && (!hotelInfromation.city || hotelInfromation.city.trim().length === 0)}
                  value={hotelInfromation.city != null ? hotelInfromation.city : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 50) {
                      hotelInformationOnChange(e);
                    } else {
                      const truncatedValue = inputValue.slice(0, 50);
                      e.target.value = truncatedValue;
                      hotelInformationOnChange(e);
                    }
                  }}
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <FormInput
                  className="comp-name mt-4"
                  id="pincode"
                  name="pincode"
                  placeholder=""
                  label="Pincode *"
                  type="number"
                  variant="outlined"
                  error={fieldError && (!hotelInfromation.pincode || hotelInfromation.pincode.trim().length == 0)}
                  value={hotelInfromation.pincode != null ? hotelInfromation.pincode : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 10) {
                      hotelInformationOnChange(e);
                    } else {
                      const truncatedValue = inputValue.slice(0, 10);
                      e.target.value = truncatedValue;
                      hotelInformationOnChange(e);
                    }
                  }}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <FormInput
                  className="comp-name mt-4"
                  id="state"
                  name="state"
                  placeholder=""
                  label="State *"
                  type="text"
                  variant="outlined"
                  error={fieldError && (!hotelInfromation.state || hotelInfromation.state.trim().length === 0)}
                  value={hotelInfromation.state != null ? hotelInfromation.state : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 50) {
                      hotelInformationOnChange(e);
                    } else {
                      const truncatedValue = inputValue.slice(0, 50);
                      e.target.value = truncatedValue;
                      hotelInformationOnChange(e);
                    }
                  }}
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <FormControl fullWidth className="mt-4"
                  error={fieldError && (!hotelInfromation.country || hotelInfromation.country.trim().length === 0)}
                >
                  <InputLabel id="country">Country *</InputLabel>
                  <Select
                    labelId="country"
                    id="country"
                    label="country *"
                    name="country"
                    value={hotelInfromation.country != null ? hotelInfromation.country : ""}
                    onChange={(e) => hotelInformationOnChange(e)}
                  >

                    {countryList.length > 0 && countryList.map((item) => {
                      return (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      )
                    })
                    }


                  </Select>
                </FormControl>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <FormInput
                  className="comp-name mt-4"
                  id="website"
                  name="website"
                  placeholder=""
                  label="Website *"
                  type="text"
                  variant="outlined"
                  error={fieldError && (!hotelInfromation.website || hotelInfromation.website.trim().length === 0)}
                  value={hotelInfromation.website != null ? hotelInfromation.website : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 50) {
                      hotelInformationOnChange(e);
                    } else {
                      const truncatedValue = inputValue.slice(0, 50);
                      e.target.value = truncatedValue;
                      hotelInformationOnChange(e);
                    }
                  }}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <FormInput
                  className="comp-name mt-4"
                  id="mobile"
                  name="hotelMobile"
                  placeholder=""
                  label="Mobile No *"
                  type="number"
                  variant="outlined"
                  error={fieldError && (!hotelInfromation.hotelMobile || hotelInfromation.hotelMobile.trim().length === 0)}
                  value={hotelInfromation.hotelMobile != null ? hotelInfromation.hotelMobile : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 15) {
                      hotelInformationOnChange(e);
                    } else {
                      const truncatedValue = inputValue.slice(0, 15);
                      e.target.value = truncatedValue;
                      hotelInformationOnChange(e);
                    }
                  }}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <FormInput
                  className="comp-name mt-4"
                  id="telephone"
                  name="HotelTelephone"
                  placeholder=""
                  label="Telephone No *"
                  type="number"
                  variant="outlined"
                  error={fieldError && (!hotelInfromation.HotelTelephone || hotelInfromation.HotelTelephone.trim().length === 0)}
                  value={hotelInfromation.HotelTelephone != null ? hotelInfromation.HotelTelephone : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 15) {
                      hotelInformationOnChange(e);
                    } else {
                      const truncatedValue = inputValue.slice(0, 15);
                      e.target.value = truncatedValue;
                      hotelInformationOnChange(e);
                    }
                  }}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <FormInput
                  className="comp-name mt-4"
                  id="Fax"
                  name="hotelFax"
                  placeholder=""
                  label="Fax No "
                  type="text"
                  variant="outlined"
                  value={hotelInfromation.hotelFax != null ? hotelInfromation.hotelFax : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 200) {
                      hotelInformationOnChange(e);
                    } else {
                      const truncatedValue = inputValue.slice(0, 200);
                      e.target.value = truncatedValue;
                      hotelInformationOnChange(e);
                    }
                  }}
                />
              </div>


              {/* hotellogo */}
              <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="eventLogo" name="eventLogo" ref={register} value={hotelInfromation.eventLogo} />
              {/* <h4>Event Logo *{!errors.eventLogo || state.eventLogo ? '' : <span style={{color: '#f44336', fontSize:'0.75rem'}}>   Please Select Event Logo</span>}</h4> */}

              <div className="col-xl-2 col-lg-3  col-md-4 col-sm-12 mt-3">
                <h4>Hotel Logo *{error != '' ? <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   {error}</span> : fieldError == true && (imageSrc == (null || '')) ? <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select Event Hotel Logo</span> : ''}</h4>


                <ProfileUploader img={hotelInfromation.eventLogoId ? process.env.REACT_APP_CDN_URL + imageSrc : imageSrc ? process.env.REACT_APP_CDN_URL_TEMP + imageSrc : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
                  handleChange={handleChangeImage} selectImg={selectImg} image={image} handleCropClose={handleCropClose}
                />
              </div>

              <div className="mob-para col-lg-5 col-md-7 col-sm-12 mt-5 pl-4">
                <p className="image-hint">Accepts Jpeg / Jpg / Png Only</p>
                <p className="image-hint">(Max size allowed 200 Kb)</p>
              </div>


              {/* timezone */}
              <div className="col-lg-6 col-sm-12 mt-3">
                <FormControl>
                  <h4>TimeZone * <p style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{errors.timezone?.message}</p></h4>
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="timezone" name="timezone" ref={register} value={hotelInfromation.timezone} />

                  <TimezonePicker
                    absolute={false}
                    value={hotelInfromation.timezone}
                    placeholder="Select timezone..."
                    onChange={handleTimeZone}
                  />
                </FormControl>

              </div>


              {/* Time */}
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="time" name="fromTime" value={hotelInfromation.checkInTime != null ? hotelInfromation.checkInTime.length > 5 ? hotelInfromation.checkInTime.substring(0, 5) : hotelInfromation.checkInTime : null} />
                  <div className="col-lg-7 col-sm-12 mt-2">
                    <h4>Check-In time</h4>
                    <div className="awd-date">
                      <FormControl>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                          <KeyboardTimePicker
                            disableToolbar
                            variant="inline"
                            margin="normal"
                            id="time1"
                            name="time1"
                            defaultValue={hotelInfromation.checkInTime != null ? hotelInfromation.checkInTime : null}
                            control={control}
                            value={hotelInfromation.checkInTime != null ? hotelInfromation.checkInTime : null}
                            onChange={(time) => handleTimeChange(time, "checkInTime")}
                            ampm={false}
                          />
                        </MuiPickersUtilsProvider>
                        {/* <FormHelperText style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }} ></FormHelperText> */}
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div style={{ width: "100%" }}>
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="time" name="fromTime" value={hotelInfromation.checkOutTime != null ? hotelInfromation.checkOutTime : null} />
                  <div className="col-lg-7 col-sm-12 mt-2">
                    <h4>Check-Out time</h4>
                    <div className="awd-date">
                      <FormControl>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                          <KeyboardTimePicker
                            disableToolbar
                            variant="inline"

                            margin="normal"
                            id="time2"
                            name="time2"
                            defaultValue={hotelInfromation.checkOutTime != null ? hotelInfromation.checkOutTime : null}
                            // ref={register}
                            control={control}
                            value={hotelInfromation.checkOutTime != null ? hotelInfromation.checkOutTime : null}
                            onChange={(time) => handleTimeChange(time, "checkOutTime")}
                            ampm={false}
                          />
                        </MuiPickersUtilsProvider>
                        <FormHelperText style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }} ></FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>



              {/* </div> */}
              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <FormControl fullWidth className="mt-4">
                  <InputLabel id="demo-simple-select-label">Star Category</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="starCategory"
                    label="Star Category"
                    value={hotelInfromation.starCategory != null ? hotelInfromation.starCategory : ""}
                    onChange={(e) => hotelInformationOnChange(e)}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <FormInput
                  className="comp-name mt-4"
                  id="airport"
                  name="airport"
                  placeholder=""
                  label="Nearest Airport "
                  type="text"
                  variant="outlined"
                  value={hotelInfromation.airport != null ? hotelInfromation.airport : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 50) {
                      hotelInformationOnChange(e);
                    } else {
                      const truncatedValue = inputValue.slice(0, 50);
                      e.target.value = truncatedValue;
                      hotelInformationOnChange(e);
                    }
                  }}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <FormInput
                  className="comp-name mt-4"
                  id="rail"
                  name="rail"
                  placeholder=""
                  label="Nearest Railway Station *"
                  type="text"
                  variant="outlined"
                  error={fieldError && (!hotelInfromation.rail || hotelInfromation.rail.trim().length === 0)}
                  value={hotelInfromation.rail != null ? hotelInfromation.rail : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 50) {
                      hotelInformationOnChange(e);
                    } else {
                      const truncatedValue = inputValue.slice(0, 50);
                      e.target.value = truncatedValue;
                      hotelInformationOnChange(e);
                    }
                  }}
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <FormInput
                  className="comp-name mt-4"
                  id="hotelMap"
                  name="hotelMapUrl"
                  placeholder=""
                  label="Hotel Location Map URL"
                  type="text"
                  variant="outlined"
                  value={hotelInfromation.hotelMapUrl != null ? hotelInfromation.hotelMapUrl : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 3000) {
                      hotelInformationOnChange(e);
                    } else {
                      const truncatedValue = inputValue.slice(0, 3000);
                      e.target.value = truncatedValue;
                      hotelInformationOnChange(e);
                    }
                  }}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">

                <FormTextArea
                  id="h-description"
                  rows="5"
                  aria-label="empty textarea"
                  aria-hidden="true"
                  name="hotelDescription"
                  class=" buytext2"
                  placeholder="Hotel Description"
                  value={hotelInfromation.hotelDescription != null ? hotelInfromation.hotelDescription : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 8000) {
                      hotelInformationOnChange(e);
                    } else {
                      const truncatedValue = inputValue.slice(0, 8000);
                      e.target.value = truncatedValue;
                      hotelInformationOnChange(e);
                    }
                  }}
                />
              </div>
            </div>
          </Card>
          <Card>
            <CardHeader>
              <h3 className="text-left">Hotel Images</h3>
              <p className="text-left">Add your Hotel different views with their names.</p>
            </CardHeader>
            <CardBody>
              <ImageUpload handleRoomImage={handleRoomImage} imageType={"hotelImageType"} hotelId={hotelId != "hotel" ? hotelId : null} deleteHotelImage={deleteHotelImage} setDeleteHotelImage={setDeleteHotelImage} setLoading={setLoading} />
            </CardBody>
          </Card>
          {
            hotelFlag &&
            <Modal
              open={hotelFlag}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              {/* Modal content */}


              <Card style={{ margin: "0", overflowY: "scroll", height: "100vh", padding: "14px", maxWidth: "69%" }}>
                <div>
                  <div style={{ display: 'flex', marginLeft: "1.7%", width: "97%", padding: "10px 0", justifyContent: 'space-between' }}>
                    <div>
                      <h3 className="text-left">Room Details</h3>
                      <p className="text-left">Add your exact details about the room.</p>
                    </div>
                    <button style={{ border: "none", maxHeight: "1rem", backgroundColor: "white" }} onClick={() => { setHotelFlag(false); setImages2([]) }}><CloseIcon /></button>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12" >
                      <RoomNames hotelRoomDetailOnChange={hotelRoomDetailOnChange} value={roomDetail.roomName != null ? roomDetail.roomName : null} selectRoomNameArray={selectRoomNameArray} hotelRoomDetails={hotelRoomDetails} roomId={roomId} />
                      <div>
                        <FormInput
                          className="comp-name mt-4"
                          id="roomcount"
                          name="roomCount"
                          placeholder=""
                          label="How many rooms of this type do you have?"
                          type="number"
                          variant="outlined"
                          value={roomDetail.roomCount != null ? roomDetail.roomCount : null}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue.length <= 4) {
                              hotelRoomDetailOnChange(e);
                            } else {
                              const truncatedValue = inputValue.slice(0, 4);
                              e.target.value = truncatedValue;
                              hotelRoomDetailOnChange(e);
                            }
                          }}
                        />
                      </div>
                      <div className="mt-4">
                        <FormInput
                          className="comp-name "
                          id="gueststay"
                          name="gueststay"
                          placeholder=""
                          label="How many guest can stay in this room?"
                          type="number"
                          variant="outlined"
                          value={roomDetail.gueststay != null ? roomDetail.gueststay : null}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue.length <= 4) {
                              hotelRoomDetailOnChange(e);
                            } else {
                              const truncatedValue = inputValue.slice(0, 4);
                              e.target.value = truncatedValue;
                              hotelRoomDetailOnChange(e);
                            }
                          }}
                        />
                      </div>

                      <div className="mt-4">
                        <FormControl sx={{ width: "100%" }}>
                          <InputLabel id="demo-multiple-checkbox-label">Select Participant Types</InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={roomDetail.participantType != (null && undefined) ? roomDetail.participantType : []}
                            onChange={handleChangeOnMultiSelect}
                            input={<OutlinedInput label="Select Participant Types" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                          >
                            {names.map((name) => (
                              <MenuItem key={name} value={name} style={{ width: "100%" }}>
                                <Checkbox checked={roomDetail.participantType != null ? roomDetail.participantType.indexOf(name) > -1 : false} style={{ color: "blue" }} />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>

                      <div className="mt-4">
                        <FormTextArea
                          id="r-description"
                          rows="5"
                          label="Room Description"
                          aria-label="empty textarea"
                          aria-hidden="true"
                          name="roomDescription"
                          class=" buytext2"
                          placeholder="Room Description"

                          value={roomDetail.roomDescription != null ? roomDetail.roomDescription : ""}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue.length <= 2000) {
                              setRoomDetail({ ...roomDetail, ["roomDescription"]: e.target.value });
                            } else {
                              const truncatedValue = inputValue.slice(0, 2000);
                              setRoomDetail({ ...roomDetail, ["roomDescription"]: truncatedValue });
                            }
                          }}
                        />
                      </div>




                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 bed-detail" style={{ maxHeight: "30rem" }}>
                      <h3>Which beds are available in this room?</h3>
                      <div className="unit-bed">
                        <h4>Single Bed</h4>
                        <IncrementDecrementBtn counters={counters} value={roomDetail.SingleBed != null ? roomDetail.SingleBed : 0} name={"SingleBed"} domain={"/admin/hotel"} hotelRoomDetailOnChange={hotelRoomDetailOnChange} />
                      </div>
                      <div className="unit-bed">
                        <h4>Double Bed</h4>
                        <IncrementDecrementBtn counters={counters} value={roomDetail.DoubleBed != null ? roomDetail.DoubleBed : 0} name={"DoubleBed"} domain={"/admin/hotel"} hotelRoomDetailOnChange={hotelRoomDetailOnChange} />
                      </div>
                      <div className="unit-bed">
                        <h4>Large Bed (king size)</h4>
                        <IncrementDecrementBtn counters={counters} value={roomDetail.LargeBed != null ? roomDetail.LargeBed : 0} name={"LargeBed"} domain={"/admin/hotel"} hotelRoomDetailOnChange={hotelRoomDetailOnChange} />
                      </div>
                      <div className="unit-bed">
                        <h4>Extra-Large  Double Bed (super-king size)</h4>
                        <IncrementDecrementBtn counters={counters} value={roomDetail.ExtraLargeBed != null ? roomDetail.ExtraLargeBed : 0} name={"ExtraLargeBed"} domain={"/admin/hotel"} hotelRoomDetailOnChange={hotelRoomDetailOnChange} />
                      </div>
                      <p className="bed-dropdown" onClick={toggleVisibility}><i class="fas fa-angle-down"></i> Fewer Bed options</p>
                      {isVisible && (
                        <div>
                          <div className="unit-bed">
                            <h4>Bunk Bed</h4>
                            <IncrementDecrementBtn counters={counters} value={roomDetail.BunkBed != null ? roomDetail.BunkBed : 0} name={"BunkBed"} domain={"/admin/hotel"} hotelRoomDetailOnChange={hotelRoomDetailOnChange} />
                          </div>
                          <div className="unit-bed">
                            <h4>Sofa Bed</h4>
                            <IncrementDecrementBtn counters={counters} value={roomDetail.SofaBed != null ? roomDetail.SofaBed : 0} name={"SofaBed"} domain={"/admin/hotel"} hotelRoomDetailOnChange={hotelRoomDetailOnChange} />
                          </div>
                          <div className="unit-bed">
                            <h4>Futon Mat</h4>
                            <IncrementDecrementBtn counters={counters} value={roomDetail.FutonBed != null ? roomDetail.FutonBed : 0} name={"FutonBed"} domain={"/admin/hotel"} hotelRoomDetailOnChange={hotelRoomDetailOnChange} />
                          </div>
                        </div>
                      )}
                      <div style={{ marginTop: "25.6%" }}>
                        <FormInput
                          className="comp-name "
                          id="SubBooking"
                          name="subBooking"
                          placeholder=""
                          label="Select Sub Booking"
                          type="number"
                          variant="outlined"
                          value={roomDetail.subBooking != null ? roomDetail.subBooking : 0}
                          onChange={(e) => hotelRoomDetailOnChange(e)}
                        />
                      </div>
                    </div>


                    <div className="mt-1 mb-5 ml-3" style={{ width: "99.5%", display: "flex" }}>
                      <div style={{ width: "47.5%", marginTop: "3.5%", height: "100%" }}>
                        <FormInput
                          className="comp-name "
                          id="price"
                          name="price"
                          placeholder=""
                          label="Price ($)"
                          type="number"
                          variant="outlined"
                          value={roomDetail.price != null ? roomDetail.price : null}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue.length <= 4) {
                              hotelRoomDetailOnChange(e);
                            } else {
                              const truncatedValue = inputValue.slice(0, 4);
                              e.target.value = truncatedValue;
                              hotelRoomDetailOnChange(e);
                            }
                          }}
                        />
                      </div>


                      <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', height: '1px', overflow: 'hidden' }} id="date" name="fromDate" ref={register} value={roomDetail.fromDate} />
                      <Row >
                        <div className="col-lg-10 col-md-8 col-sm-12" style={{ marginLeft: "7%", padding: "0" }}>
                          <h4>Available Date</h4>
                          <div className="awd-date">
                            <FormControl>
                              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                <KeyboardDatePicker
                                  disableToolbar
                                  variant="inline"
                                  format="MM/dd/yyyy"
                                  margin="normal"
                                  id="date1"
                                  name="date"
                                  style={{ width: "100%" }}
                                  defaultValue={roomDetail.date}
                                  control={control}
                                  minDate={new Date()}
                                  value={roomDetail.date != null ? roomDetail.date : null}
                                  ampm={false}
                                  onChange={handleTimeChangeForRoom}
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                            {/* <FormHelperText style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }} >{(fieldError && (roomDetail.fromDate === null || roomDetail.fromDate === "")) ? errors.fromDate?.message : ""}</FormHelperText> */}
                          </div>
                        </div>
                      </Row>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem", width: "90%" }}>


                      <ImageUpload handleRoomImage2={handleRoomImage2} imageType={"roomImageType"} roomTypeImages={images2} deleteHotelImage={deleteHotelImage} setDeleteHotelImage={setDeleteHotelImage} setLoading={setLoading} roomImageMinWidth={"25rem"} />
                    </div>

                  </div>

                  <button style={{ fontSize: "1rem", border: "none" }} onClick={() => saveSingleHotelRoomDetail()} className="btn btn-primary ml-3 mt-4" >Save</button>
                  <button type="button" data-dismiss="modal" class="btn btn-secondary btn-md ml-1 mt-4" style={{ fontSize: "1rem", border: "none" }} onClick={() => setHotelFlag(false)}>Cancel</button>

                </div>

              </Card>
            </Modal>
          }

          {
            isModalPopup &&
            <Dialog open={isModalPopup} value={modalPopValue} handleSave={() => { deleteRoomDetail() }} handleClose={() => setModalPopup(false)}></Dialog>
          }

          <Card style={{ padding: "20px" }}>
            <CardHeader>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3 className="text-left">Hotel Room Information</h3>
                <button className="btn btn-primary" onClick={() => { handleAddRoom() }}>Add Room</button>
              </div>
            </CardHeader>
            <div className="row">


              {
                hotelRoomDetails.length == 0 ?
                  <p style={{ textAlign: "center", marginTop: "2%", width: "100%" }}> No Room Add By You</p>
                  : (

                    <table className="table">
                      <thead>
                        <tr>
                          <th>Room Name</th>
                          <th>No of Room</th>
                          <th>No of Persons</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>

                        {hotelRoomDetails.map((item, index) => {
                          console.log(item, index)
                          return (
                            <tr>
                              <td>{item.roomName != (null && undefined) ? item.roomName : null}</td>
                              <td>{item.roomCount != (null && undefined) ? item.roomCount : null}</td>
                              <td>{item.gueststay != (null && undefined) ? item.gueststay : null}</td>
                              <td><button id="Edit150" class="btn btn-icon btn-primary btn-sm" variant="contained" color="primary" onClick={() => { editHotelDetailRoom(index, item.id) }}><span class="btn-inner--icon"><i class="fas fa-pencil-alt"></i></span></button> <button id="Del150" class="btn btn-icon btn-warning btn-sm" variant="contained" color="#fb6340" onClick={() => { openModalPopUp(index, item.id) }}><span class="btn-inner--icon"><i class="fas fa-trash-alt"></i></span></button></td>
                            </tr>
                          )
                        })
                        }
                      </tbody>
                    </table>)
              }
            </div>
          </Card>

          <Card style={{ padding: "20px" }}>
            <CardHeader>
              <h3 className="text-left">Contact Information</h3>
              <p className="text-left">Fill the contact details one who guide to reach you.</p>
            </CardHeader>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <FormInput
                  className="comp-name mt-4"
                  id="cName"
                  name="name"
                  placeholder=""
                  label="Your Name *"
                  type="text"
                  variant="outlined"
                  error={fieldError && (!hotelInfromation.name || hotelInfromation.name.trim().length === 0)}
                  value={hotelInfromation.name != null ? hotelInfromation.name : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 50) {
                      hotelInformationOnChange(e);
                    } else {
                      const truncatedValue = inputValue.slice(0, 50);
                      e.target.value = truncatedValue;
                      hotelInformationOnChange(e);
                    }
                  }}

                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <FormInput
                  className="comp-name mt-4"
                  id="designation"
                  name="designation"
                  placeholder=""
                  label="Designation *"
                  type="text"
                  variant="outlined"
                  error={fieldError && (!hotelInfromation.designation || hotelInfromation.designation.trim().length === 0)}
                  value={hotelInfromation.designation != null ? hotelInfromation.designation : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 50) {
                      hotelInformationOnChange(e);
                    } else {
                      const truncatedValue = inputValue.slice(0, 50);
                      e.target.value = truncatedValue;
                      hotelInformationOnChange(e);
                    }
                  }}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <FormInput
                  className="comp-name mt-4"
                  id="ctelephone"
                  name="telephone"
                  placeholder=""
                  label="Telephone No"
                  type="number"
                  variant="outlined"
                  value={hotelInfromation.telephone != null ? hotelInfromation.telephone : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 15) {
                      hotelInformationOnChange(e);
                    } else {
                      const truncatedValue = inputValue.slice(0, 15);
                      e.target.value = truncatedValue;
                      hotelInformationOnChange(e);
                    }
                  }}

                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <FormInput
                  className="comp-name mt-4"
                  id="cmobile"
                  name="mobile"
                  placeholder=""
                  label="Mobile No* "
                  type="number"
                  variant="outlined"
                  error={fieldError && (!hotelInfromation.mobile || hotelInfromation.mobile.trim().length === 0)}
                  value={hotelInfromation.mobile != null ? hotelInfromation.mobile : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 15) {
                      hotelInformationOnChange(e);
                    } else {
                      const truncatedValue = inputValue.slice(0, 15);
                      e.target.value = truncatedValue;
                      hotelInformationOnChange(e);
                    }
                  }}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <FormInput
                  className="comp-name mt-4"
                  id="cfax"
                  name="fax"
                  placeholder=""
                  label="Fax No "
                  type="text"
                  variant="outlined"
                  value={hotelInfromation.fax != null ? hotelInfromation.fax : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 50) {
                      hotelInformationOnChange(e);
                    } else {
                      const truncatedValue = inputValue.slice(0, 50);
                      e.target.value = truncatedValue;
                      hotelInformationOnChange(e);
                    }
                  }}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <FormInput
                  className="comp-name mt-4"
                  id="cemail"
                  name="email"
                  placeholder=""
                  label="Email * "
                  type="email"
                  variant="outlined"
                  error={fieldError && (!hotelInfromation.email || hotelInfromation.email.trim().length === 0 || !hotelInfromation.email.includes('@'))}
                  value={hotelInfromation.email != null ? hotelInfromation.email : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 50) {
                      hotelInformationOnChange(e);
                    } else {
                      const truncatedValue = inputValue.slice(0, 50);
                      e.target.value = truncatedValue;
                      hotelInformationOnChange(e);
                    }
                  }}
                />
              </div>

            </div>
          </Card>

          <div className="text-center mt-4" style={{ marginBottom: '40px' }}>
            <button type="submit" className="btn btn-primary" onClick={() => createAndUpdateHotel()}>{hotelId != "hotel" ? "Update" : "save"}</button>
            <button className=" btn btn-warning btn-md ml-2" onClick={() => history.push({ pathname: '/admin/eventListing' })}>Cancel</button>
          </div>


        </Container>
      </div>
    </>
  )

}





export default Hotels;