import React, { useEffect, useState } from 'react';
import { Button, Modal } from "reactstrap";
import { Card, CardBody, ListGroupItem, ListGroup, Row, Col } from "reactstrap";
import CountryFlag from "views/Hoc/countryFlag/countryFlag";
import { commonService } from '_services/common.service';
import { Spinner } from 'views/Hoc/Spinner';
import axios from 'axios';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Moment from 'moment';
var _ = require('lodash');
export const EventsPop = (props) => {
  const { isOpen, handleClose, channelId, contactId, contactList, handleSaveEvnt } = props;
  let [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(false);
  let [list, setList] = useState([]);
  let [listData, setListData] = useState([]);
  let [token, settoken] = useState(null);
  let logo = 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg';
  useEffect(() => {
    if (isOpen)
      eventAuth();
  }, [isOpen]);
  const eventAuth = () => {
    setLoading(true);
    eventRegister();
    if (!localStorage.getItem('eventcatalyst_token')) {
      let req = {
        url: process.env.REACT_APP_EVENTCATALYST_API + 'oauth/token'
      }
      commonService.postService(req, 'account/eventcatalystToken').then(res => {
        if (res.data?.error) {
          setLoading(false);
          ToastsStore.warning('Something went wrong');
        } else {
          localStorage.setItem('eventcatalyst_token', JSON.stringify(res.data));
          getEventList();
        }
      }).catch(err => {
        setLoading(false);
        console.log('err', err)
      })
    } else {
      getEventList();
    }
  }
  const getEventList = () => {
    const url = 'getEventRegister';
    let data = {
      token: JSON.parse(localStorage.getItem('eventcatalyst_token')),
      url: process.env.REACT_APP_EVENTCATALYST_API + 'api/registration/' + contactId
    }
    commonService.postService(data, 'account/getEventRegister').then(res => {
      setLoading(false);
      list = listData = _.orderBy(res.data.registrations, ['date_start'], ['desc']);
      setListData(listData);
      setList(list);
      console.log('list', list)
      // resultCount= list?list.length:0;
    }, err => {
      setLoading(false);
      console.log('err', err)
    });
  }
  const eventRegister = () => {
    return new Promise((resolve) => {
      commonService.getServices('channel/eventRegister/' + contactId).then(async res => {
        profileData = res.data;
        setProfileData(profileData);
        console.log('res.data', res.data);
        let tokenData = await commonService.getServices('account/getPublicToken/' + channelId);
        if (tokenData) {
          console.log('tokenData', tokenData);
          token = tokenData.data.token;
          settoken(token);
          resolve(true);
        } else
          resolve(false);
      }, err => {
        resolve(false);
        console.log('err', err);
      });
    })
  }
  const getManage = async (event) => {
    setLoading(true);
    await eventRegister();
    let dec = profileData.length > 0 ? profileData[0].detailDesc : null
    if ((dec === null) || (dec === '') || !dec) {
      dec = null;
    } else {
      dec = dec.toString();
      dec = dec.replace(/(<([^>]+)>)/ig, '').trim();
    }
    let data = {
      participantId: parseInt(contactId),
      eventId: event.eventId,
      companyId: parseInt(channelId),
      existingCategory: profileData.length > 0 ? profileData[0].ExistingCategory ? profileData[0].ExistingCategory.replace(/,/g, "|") : null : null,
      interestedCategory: profileData.length > 0 ? profileData[0].IntrestedCategory ? profileData[0].IntrestedCategory.replace(/,/g, "|") : null : null,
      profileType: profileData.length > 0 ? profileData[0].ProfileType : null,
      retailCustomerProfile: profileData.length > 0 ? profileData[0].Retail_Customer_Profile ? profileData[0].Retail_Customer_Profile.replace(/,/g, "|") : null : null,
      detailDesc: dec,
      password: profileData.length > 0 ? profileData[0].password : null
    }
    let body = {
      token: JSON.parse(localStorage.getItem('eventcatalyst_token')),
      url: process.env.REACT_APP_EVENTCATALYST_API + 'api/manage',
      body: data
    }
    commonService.postService(body, 'account/postEventRegister').then(res => {
      setLoading(false);
      window.open(res.data.pageUrl, '_blank');
      // handleSaveEvnt(channelId, contactId, contactList, event.eventId);
      handleClose();
    });
  }
  const registration = async (event) => {
    setLoading(true);
    await eventRegister();
    let dec = profileData.length > 0 ? profileData[0].detailDesc : null
    console.log('dec', dec);
    if ((dec === null) || (dec === '')) {
      dec = null;
    } else {
      dec = dec.toString();
      dec = dec.replace(/(<([^>]+)>)/ig, '').trim();
    }

    let data = {
      "participantId": parseInt(contactId),
      "firstName": profileData[0].firstName,
      "lastName": profileData[0].lastName,
      "email": profileData[0].email,
      "companyName": profileData[0].companyName,
      "companyId": parseInt(channelId),
      "eventId": event.eventId,
      "status": "Confirmed",
      "type": contactList.channelTypeId === '2' ? "Vendor" : "Buyer",
      "typeId": parseInt(contactList.channelTypeId),
      "timezone": "",
      "matchingScore": [],
      "country": profileData[0].isoCode,
      "profileUrl": process.env.REACT_APP_LOCALSITE + 'channelView/' + channelId + '?token=' + token,
      "photoUrl": profileData[0].profileimage,
      "mobile": profileData[0].mobileNo,
      "jobTitle": profileData[0].jobTitle,
      "category": contactList.channelTypeId === '2' ? 'Vendor' : contactList.channelTypeId === '3' ? 'Distributor' : contactList.channelTypeId === '4' ? 'Retailer' : 'Reseller',
      "logoUrl": profileData[0].companylogo,
      "existingCategory": profileData.length > 0 ? profileData[0].ExistingCategory ? profileData[0].ExistingCategory.replace(/,/g, "|") : null : null,
      "interestedCategory": profileData.length > 0 ? profileData[0].IntrestedCategory ? profileData[0].IntrestedCategory.replace(/,/g, "|") : null : null,
      "profileType": profileData.length > 0 ? profileData[0].ProfileType : null,
      "retailCustomerProfile": profileData.length > 0 ? profileData[0].Retail_Customer_Profile ? profileData[0].Retail_Customer_Profile.replace(/,/g, "|").replace(/,/g, "|") : null : null,
      "detailDesc": dec,
      "password": profileData.length > 0 ? profileData[0].password : null
    }
    // // Status: Invited, Shortlist, Confirmed, Rejected, Cancelled, WaitingList, Declined
    let body = {
      token: JSON.parse(localStorage.getItem('eventcatalyst_token')),
      url: process.env.REACT_APP_EVENTCATALYST_API + 'api/registration',
      body: data
    }
    commonService.postService(body, 'account/postEventRegister').then(res => {
      setLoading(false);
      setEventContact(data);
      getEventList();
      handleSaveEvnt(channelId, contactId, res.data.pageUrl, event.eventId);
    });
  }
  const setEventContact = (data) => {
    //update contact table isEvent
    commonService.getServices('channel/setEventContact/' + contactId).then(res => {
    }, err => {
      console.log('err', err)
    });

    //add into event participant table
    commonService.postService(data, 'channel/addEventParticipants').then(res => {
    }, err => {
      console.log('err', err)
    });

  }
  return (
    <div>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        size="lg"
      >
        <div className="modal-header">
          <h3>Events</h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-1 pb-1">
          {loading ? <Spinner /> :
            <Card>
              <CardBody className='p-0'>
                {list.length > 0 &&
                  list.map(item => (
                    <Row>
                      <Col lg="12" className="event-card pl-3 pt-2 pr-3 pb-2">
                        <Row>
                          <Col lg="2" md="1" sm="12">
                            <div className="d-inline mr-4">
                              <a className="event-logo avatar avatar-xl mb-2 p-2" href="#pablo" onClick={(e) => e.preventDefault()}>
                                <img alt="..." src={item.logo ? item?.logo : "https://static.wixstatic.com/media/76c928_c8c2edb7ca4347cfb5f6a94cc986bdda~mv2.png/v1/fill/w_188,h_118,al_c,q_85,usm_0.33_1.00_0.00/RETAIL_CONNECT_ONE_TO_ONE_EMEA.webp"} />
                              </a>
                            </div>
                          </Col>
                          <Col className="ml-2" lg="7" md="5" sm="12" align-self-center>
                            <div className="d-inline mr-4">
                              <div className="event-title mb-2">{item.event_name}</div>
                              <div className="d-inline mr-4">
                                {/* <div className="event-venue mb-2">Date and Venue</div> */}
                                <div className="event-venue-detail"><i class="fa fa-calendar mr-2" aria-hidden="true"></i>{Moment(item.date_start).format('LL')} - {Moment(item.date_end).format('LL')}</div>
                                <div className="event-venue-detail"><i class="fa fa-map-marker mr-2" aria-hidden="true"></i>{item.location}</div>
                              </div>
                            </div>
                          </Col>
                          <Col lg="1" md="5" sm="12" align-self-center>
                            <div className="d-inline">
                              {/* {(Moment(item.date_end).format('YYYY')>=Moment().format('YYYY') && Moment(item.date_end).format('M')>=Moment().format('M')) ? <> */}
                              {(Moment(item.date_start).format('YYYY') >= 2022 && Moment(item.date_start).format('M') >= 1) ? <>
                                {!item.statusDate && item.statusDate === '' && <Button size="md" type="button" onClick={() => registration(item)}>Register</Button>}
                                {item.statusDate && item.statusDate !== '' && <Button size="md" type="button" onClick={() => getManage(item)}>Manage</Button>}
                              </> :
                                <Button size="md" type="button">Completed</Button>}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ))
                }
              </CardBody>
            </Card>
          }


        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={handleClose}
          >
            Close
          </Button>

        </div>
      </Modal>
    </div>
  );
}
export default EventsPop;