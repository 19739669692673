import React, { useState, useEffect } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Select, DatePicker } from 'antd';
import Couponform from 'views/pages/Coupons/Couponform'
import CreateUser from '../CreateUser';
import EventSlider from './EventSlider';
import CreateBoUser from '../CreateBoUser';
import CreateSubscription from '../CreateSubscription';
import UpdateOfflineSubscription from '../UpdateOfflineSubscription';
import ComplaintView from 'views/pages/Complaint-List/ComplaintView';
import ResetPassword from './ResetPassword';
import HubspotDuplicate from './HubspotDuplicate';
import BoUserResetPassword from './BoUserResetPassword';
import Offerform from 'views/pages/Coupons/Offerform';
import Pdf_Template_Data from 'views/pages/Events/Pdf_Template_Data';
import Video_Template_Data from 'views/pages/Events/Video_Template_Data';

const Globaldrawer = (props) => {

  const { closeDrawer, visible, title, couponId, getCouponListing, eventOptionList, getParticipants, eventId } = props;

  // const [state,setState] =  useState(visible);

  useEffect(() => {
    console.log('visible', visible);
  }, [visible])

  // const showDrawer = () => {
  //   setState(true);
  // };

  const onClose = () => {
    // setState(false);
    closeDrawer();
  };

  const SwitchPage = () => {

    switch (title) {
      case "Event Offer":
        return <Offerform onClose={onClose} couponId={couponId} eventOptionList={eventOptionList} getCouponListing={getCouponListing} />

      case "Add Coupon":
        return <Couponform onClose={onClose} couponId={couponId} getCouponListing={getCouponListing} />
      case "Edit User":
        return <CreateUser contactData={props.contactData} saved={props.reload} />
      case "Event Request":
        return <EventSlider eventId={props.eventId} contactData={props.contactData} saved={props.reload} className="eventslidedrawer" getParticipants={getParticipants} closeDrawer={closeDrawer} />
      case "Edit Users":
        return <CreateBoUser contactData={props.contactData} saved={props.reload} />
      case "Add Users":
        return <CreateBoUser contactData={props.contactData} saved={props.reload} />
      case "View":
        return <ComplaintView onClose={onClose} ComplaintData={props.ComplaintData} setloading={props.setloading} reCallView={props.reCallView} visible={visible} />
      case "Subscription":
        return <CreateSubscription subscriptionId={props.subscriptionId} />
      case "WireTransfer":
        return <UpdateOfflineSubscription onClose={onClose} channelsubsubscription={props.cData} reCallView={props.reCallView} />
      case "Reset Password":
        return <ResetPassword contactData={props.contactData} saved={props.reload} />
      case "Merge Account":
        return <HubspotDuplicate onClose={onClose} companyData={props} saved={props.reload} reCallView={props.reCallView} />
      case "Reset BoUserPassword":
        return <BoUserResetPassword contactData={props.contactData} saved={props.reload} />
      case "Pdf Template":
        return <Pdf_Template_Data onClose={onClose} eventId={eventId} visible={visible} />

      case "Video Template":
        return <Video_Template_Data onClose={onClose} eventId={eventId} visible={visible} />
      default:
        return <></>
    }
  }

  return (
    <div className="site-form-in-drawer-wrapper">
      {/* <button class="btn btn-primary" type="button" onClick={showDrawer}>{props.text}</button> */}
      <Drawer
        title={title}
        // title="Create Your Account"
        // width={720}
        onClose={onClose}
        visible={visible}
      //bodyStyle={{ paddingBottom: 80 }}
      // footer={
      //   <div
      //     style={{
      //       textAlign: 'right',
      //     }}
      //   >
      //     <Button  className="btn btn-light" onClick={onClose} style={{ marginRight: 8 }}>
      //       Cancel
      //     </Button>
      //     <Button className="btn btn-primary" onClick={onClose} type="primary">
      //       Submit
      //     </Button>
      //   </div>
      // }
      >
        {SwitchPage()}
      </Drawer>
    </div>
  );
};

export default Globaldrawer;