import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
// import Check from '@material-ui/icons/Check';
// import SettingsIcon from '@material-ui/icons/Settings';
// import GroupAddIcon from '@material-ui/icons/GroupAdd';
// import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import EmailIcon from '@material-ui/icons/Email';
import BusinessIcon from '@material-ui/icons/Business';
import CallIcon from '@material-ui/icons/Call';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';

import Step1 from './Signup.step1';
import Step2 from './Signup.step2';
import Step3 from './Signup.step3';
import Step4 from './Signup.step4';
import Step5 from './Signup.step5';
import Logo from 'views/pages/LoginPage/login-logo.js';
import axios from 'axios';
import * as yup from "yup";

import { history } from '../../../_helpers';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient(95deg, rgba(2,0,36,1) 0%, rgba(36,121,9,1) 27%, rgba(0,212,255,1) 100%)',   //stepper-connector color <connecting line>
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient(95deg, rgba(2,0,36,1) 0%, rgba(36,121,9,1) 27%, rgba(0,212,255,1) 100%)',   //stepper-connector color <connecting line>
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient(136deg, rgba(2,0,36,1) 0%, rgba(36,121,9,1) 27%, rgba(0,212,255,1) 100%)',  //stepper round color
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient(136deg, rgba(2,0,36,1) 0%, rgba(36,121,9,1) 27%, rgba(0,212,255,1) 100%)',  //stepper round color 
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <EmailIcon />,
    2: <PersonIcon />,
    3: <BusinessIcon />,
    4: <CallIcon />,
    5: <LockIcon />
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'green',                                         //for text description above the next and back button 
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));


const DefaultState = {
  step: 1,
  email: '',
  firstName: '',
  lastName: '',
  jobPosition: 'job',
  companyName: [{name: 'company', id: '0'}],
  address: '',
  city: '',
  state: '',
  country:[] ,
  countryId: '',
  postalCode: '',
  websiteUrl: '',
  switchPhone: '',
  productFamilies: [[{name: 'product', id: '0'}]],
  isOEM: '3',
  mobile: '',
  password: '',
  rePassword: '',
  channelTypeId:0,
  hubspot_contact_id:null,
  hubspotCompanyId:null,
  oldChannelId: null,
  errState:{
    email:'',
    firstName: '',
    lastName: '',
    companyName:'',
    jobPosition:'',
    address:'',
    city:'',
    state:'',
    country:'',
    postalCode:'',
    websiteUrl:'',
    password:'',
    rePassword:'',
    switchPhone: '',
    address: '',
    verified: 0
  }
}



export default function CustomizedSteppers() {

  function getSteps() {
    return ['Select Profile', 'Login Details', 'User Details', 'Company Details', 'Verification Code',];
  }
  


  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [Flag, setFlag] = React.useState(0);
  const steps = getSteps();

  const [state, setState] = React.useState(DefaultState);
  console.log(state);
  
  const [staticValues, setStaticValues] = useState({
  productGroup:[],
  companyList:[],
  countryList:[],
  })

//doesn't used
  // const validateNext = async() => {
  //   if( activeStep == 0 ){
  //     if(state.channelTypeId !== 0)
  //       handleNext();
  //   }  
  //   if(activeStep == 1 ){
      
  //     if(state.email  && state.firstName && state.lastName && state.password && state.rePassword && (state.password === state.rePassword) ) {
  //       if(state.email!=""&& (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state.email)))  {
  //          axios.post('/common/getOldProduct', { 
  //           email: state.email
  //           }).then(res =>{           
            
  //           console.log("products:",res.data.companyAddress.length);
  //           if(res.data.companyAddress.length>0)
  //           {
  //            setState(
  //             {...state,productFamilies:[res.data.productGroup],
  //               companyName:[{name:res.data.companyAddress[0].name,id:res.data.companyAddress[0].id}],
  //               jobPosition:res.data.companyAddress[0].jobTitle,
  //               address:res.data.companyAddress[0].address,
  //               city:res.data.companyAddress[0].city,          
  //               country:res.data.companyAddress[0].country,
  //               websiteUrl:res.data.companyAddress[0].webSiteUrl,
  //               postalCode:res.data.companyAddress[0].postalCode,
                
  //             });
  //           }
  //           else
  //           {
  //             setState(
  //               {...state,productFamilies:[],
  //                 companyName:[],
  //                 jobPosition:'',
  //                 address:'',
  //                 city:'',          
  //                 country:'',
  //                 websiteUrl:'',
  //                 postalCode:'',
                  
  //               });
  //               getData();
  //           }
            
            
  //           //console.log("states:",state);
            
  //             });  
  //             getData();
  //       }
  //         handleNext();
  //         console.log('after nect', state);
  //         console.log('entered step2');
  //       }
  //       else
  //       {
  //         setState({...state,errState:{email:state.email?'':'Please complete this required field.',
  //         firstName:state.firstName?'':'Please complete this required field.',
  //         lastName:state.lastName?'':'Please complete this required field.',
  //         password:state.password?'':'Please complete this required field.',
  //         rePassword:state.rePassword?'':'Please complete this required field.',
          

  //         jobPosition:state.errState.jobPosition?'Please complete this required field.':'',
  //         companyName:state.errState.companyName?'Please complete this required field.':'',
  //         address:state.errState.address?'Please complete this required field.': '',
  //         city:state.errState.city?'Please complete this required field.':'',
  //         state:state.errState.state?'Please complete this required field.':'',
  //         country:state.errState.country?'Please complete this required field.':'',
  //         postalCode:state.errState.postalCode?'Please complete this required field.':'',
  //         websiteUrl:state.errState.websiteUrl?'Please complete this required field.':'',
  //         switchPhone:state.errState.switchPhone?'Please complete this required field.':'',
  //         productFamilies:state.errState.productFamilies?'Please complete this required field.':'',

  //       }            
  //       });     
    
  //       }
  //     }
  //     if(activeStep == 2)
  //     {
  //       if(state.companyName[0] && state.jobPosition && state.isOEM && state.productFamilies[0].length){
  //         handleNext();
  //         console.log('entered step3')
  //       }
  //       else{
  //         setState({...state,errState:{jobPosition:state.jobPosition?'':'Please complete this required field.',
  //         companyName:state.companyName[0]?'':'Please complete this required field.',
  //         productFamilies:state.productFamilies[0].length?'':'Please complete this required field.',

  //         email:state.errState.email?'Please complete this required field.':'',
  //         firstName:state.errState.firstName?'Please complete this required field.':'',
  //         lastName:state.errState.lastName?'Please complete this required field.':'',
  //         password:state.errState.password?'Please complete this required field.':'',
  //         rePassword:state.errState.rePassword?'Please complete this required field.':'',
          

          
  //         address:state.errState.address?'Please complete this required field.': '',
  //         city:state.errState.city?'Please complete this required field.':'',
  //         state:state.errState.state?'Please complete this required field.':'',
  //         country:state.errState.country?'Please complete this required field.':'',
  //         postalCode:state.errState.postalCode?'Please complete this required field.':'',
  //         websiteUrl:state.errState.websiteUrl?'Please complete this required field.':'',
  //         switchPhone:state.errState.switchPhone?'Please complete this required field.':'',
          

  //       }            
  //       });
  //       }
  //     }
  //     if (activeStep == 3){
  //     if(state.address && state.city && state.country[0] && state.city && state.postalCode && state.websiteUrl && state.switchPhone ) //!= 0
  //     {
  //         handleNext();
  //         console.log('entered step4');
  //     }
  //     else
  //     {
  //       setState({...state,errState:{address:state.address?'':'Please complete this required field.',
  //         city:state.city?'':'Please complete this required field.',
  //         state:state.state?'':'Please complete this required field.',
  //         country:state.country[0]?'':'Please complete this required field.',
  //         postalCode:state.postalCode?'':'Please complete this required field.',
  //         websiteUrl:state.websiteUrl?'':'Please complete this required field.',
  //         switchPhone:state.switchPhone?'':'Please complete this required field.',
          
  //         productFamilies:state.errState.productFamilies?'Please complete this required field.':'',
  //         firstName: state.errState.firstName?'Please complete this required field.':'',
  //         lastName: state.errState.lastName?'Please complete this required field.':'',
  //         email:state.errState.email?'Please complete this required field.':'',
  //         jobPosition:state.errState.jobPosition?'Please complete this required field.':'',
  //         companyName:state.errState.companyName?'Please complete this required field.':'',
  //         password:state.errState.password?'Please complete this required field.':'',
  //         rePassword:state.errState.rePassword?'Please complete this required field.':'',
  //       }            
  //       });
  //     }
  //   }
    
  // }





  //used to handle form 1, 5(i mean 2)
  const assignValues = (data) => {
    setState({...state,step : activeStep+1, ...data});
    console.log('assigner1', state);
    //handleNext();
    if(activeStep != 0){
      const apiUrl = "activity/nonuseractivity";
      const data = {
        email : state.email,
        step : activeStep
      };
       axios.post(apiUrl, data).then(res =>{  
        console.log("response:",res.data);
       }).catch(error =>{
         console.log("error" ,error)
       });     

    }
    if(activeStep != 1){
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

  }

  

 //used to fetch data for email if already exists in old table or hubspot table
  useEffect(() => {
    (async()=>{
      if(Flag){
        let response =  await gettingData();
        console.log('response', response);
        if(response === 1){
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        else if(response === 0){
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        console.log('getting data');
      }
      setFlag(1);
    })();
  }, [state.email])


  //FinishSubmit
  const finishSubmit = async() => {

    const prodFam = []
      if(state.productFamilies.length > 0){
        state.productFamilies[0].map(x => {
          prodFam.push( +x.id);
        });
      }
      //console.log('prod', prodFam)  
      
  
      const data = {
        firstName: state.firstName,
        lastName: state.lastName,
        companyName: state.companyName[0].name,
        jobPosition: state.jobPosition,
        email: state.email,
        password: state.password,
        channelTypeId: state.channelTypeId,
        oldChannelId: state.oldChannelId,
        hubspot_contact_id: state.hubspot_contact_id,
        hubspotCompanyId: state.hubspotCompanyId,
        mobile: state.mobile,
        switchPhone: state.switchPhone,
        address: state.address,
        countryId: parseInt(state.country[0].id) ,
        postalCode: state.postalCode,
        city: state.city,
        state: state.state,
        websiteurl: state.websiteUrl,
        productFamilies: prodFam,
        isOEM: state.isOEM
      }
  
      axios.post('/account/', data).then(res => {
        console.log('res', res);
        if(res.status == 201){
          axios.post('/emailer/', {
            email: state.email,
            companyName: state.companyName  
          })
          history.push('/Pending'); 
        }
      }).catch(err => {
        console.log('err', err);
      })
  
  
    }

    
  const gettingData = async() => {
    let res = await axios.post('/common/getOldProduct', { 
      email: state.email
      }).then(res =>{           
      console.log("products:",res.data.companyAddress.length);
      //if(res.data.companyAddress.length>0)
      setState(
        {...state,productFamilies:[res.data.productGroup],
          companyName:res.data.companyAddress.length>0 ? [{name:res.data.companyAddress[0].name,id:res.data.companyAddress[0].id}] : [null],
          jobPosition:res.data.companyAddress.length>0 ? res.data.companyAddress[0].jobTitle:'',
          firstName:res.data.companyAddress.length>0 ? res.data.companyAddress[0].firstName:'',
          lastName:res.data.companyAddress.length>0 ? res.data.companyAddress[0].lastName:'',
          address:res.data.companyAddress.length>0 ? res.data.companyAddress[0].address : '',
          city:res.data.companyAddress.length>0 ? res.data.companyAddress[0].city : '',          
          country:res.data.companyAddress.length>0 ? [{name:res.data.companyAddress[0].country, id:res.data.companyAddress[0].countryId}] : [null],
          websiteUrl:res.data.companyAddress.length>0 ? res.data.companyAddress[0].webSiteUrl : '',
          postalCode:res.data.companyAddress.length>0 ? res.data.companyAddress[0].postalCode : '',
          countryId:res.data.companyAddress.length>0 ? res.data.companyAddress[0].isoCode : '',
        });
        return 1;
    });         
     getData();
     return res;
  }

//used to handle forms 2, 3, 4
  const handleNext = () => {
    console.log('handlenext', activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setState({...state, step : activeStep});

      if(activeStep==1)
    {
      
      axios.post('/common/getOldProduct', { 
      email: state.email
      }).then(res =>{           
      console.log("products:",res.data.companyAddress.length);
      //if(res.data.companyAddress.length>0)
      setState(
        {...state,productFamilies:[res.data.productGroup],
          companyName:res.data.companyAddress.length>0 ? [{name:res.data.companyAddress[0].name,id:res.data.companyAddress[0].id}] : [null],
          firstName: res.data.companyAddress.length>0 ? res.data.companyAddress[0].firstName: '',
          lastName: res.data.companyAddress.length>0 ? res.data.companyAddress[0].lastName: '',
          jobPosition:res.data.companyAddress.length>0 ? res.data.companyAddress[0].jobTitle:'',
          address:res.data.companyAddress.length>0 ? res.data.companyAddress[0].address : '',
          city:res.data.companyAddress.length>0 ? res.data.companyAddress[0].city : '',          
          country:res.data.companyAddress.length>0 ? [{name : res.data.companyAddress[0].country, id: res.data.companyAddress[0].countryId}] : [null],
          websiteUrl:res.data.companyAddress.length>0 ? res.data.companyAddress[0].webSiteUrl : '',
          postalCode:res.data.companyAddress.length>0 ? res.data.companyAddress[0].postalCode : '',
          countryId:res.data.companyAddress.length>0 ? res.data.companyAddress[0].isoCode : '',
        });
      
    
    });         
     getData();
     
    }

    
  

    if(activeStep !== 0){
      const apiUrl = "activity/nonuseractivity";
      const data = {
        email : state.email,
        step : activeStep
      };
       axios.post(apiUrl, data).then(res =>{  
        console.log("response:",res.data);
       }).catch(error =>{
         console.log("error" ,error)
       });     

    }
  };

  //hubspot
  const getData = () => {
    let email = state.email;
    const apiUrl = 'Hubspot/getCompany/'+email;
    let details = {};
     axios.get(apiUrl).then(res =>{  

    if(res.data.company.length === 0 ){
      console.log('no data')
    }
    else{
       details = {
        hubspot_contact_id : res.data.contactVid,
        hubspotCompanyId : res.data.company.companyId,
        companyName : [{name : res.data.company.properties.name.value}],
        jobPosition: res.data.company.properties.job_position ? res.data.company.properties.job_position.value : '',
        city : res.data.company.properties.city ? res.data.company.properties.city.value : '',
        country : res.data.company.properties.country ? res.data.company.properties.country.value : '',
        address : res.data.company.properties.address ? res.data.company.properties.address.value : '',
        websiteUrl : res.data.company.properties.website ? res.data.company.properties.website.value : '',
        postalCode : res.data.company.properties.zip ? res.data.company.properties.zip.value : '',
      };
    }  
    setState({...state, ...details})
    console.log('getDArat', state);
  }).catch(error =>{
    console.log("error" ,error, state)
  });    
  
    
}

useEffect(() => {     
  axios.get('/common/getSignup').then(res =>{  
    //console.log("pgrrou:",res.data.productGroup);
    setStaticValues({...staticValues,productGroup:res.data.productGroup,
    companyList:res.data.company,
    countryList:res.data.country,
    });
  });
 console.log('i am rendering');
}, []);  


//useEffect for email form filling

// useEffect(() => {             
//   let flag=0;
  
//   if(state.email!=""&& (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state.email)))  {
//   let mailname=state.email.substring(0,state.email.lastIndexOf("@"));
//   let maildomain=state.email.substring(state.email.lastIndexOf("@"),state.email.length);
//   let domain=maildomain.substring(0,maildomain.indexOf("."));
//   if(mailFlag!=mailname+domain)
//   {
//     setMailFlag(mailname+domain);
//     flag=1;
//   } 
  
//   if(flag==1)
//   {
//       axios.post('/common/getOldProduct', { 
//       email: mailname+domain
//       }).then(res =>{           
      
//       console.log("products:",res.data.companyAddress.length);
//       if(res.data.companyAddress.length>0)
//       {
//       setState(
//         {...state,productFamilies:[res.data.productGroup],
//           companyName:[{name:res.data.companyAddress[0].name,id:res.data.companyAddress[0].id}],
//           jobPosition:res.data.companyAddress[0].jobTitle,
//           address:res.data.companyAddress[0].address,
//           city:res.data.companyAddress[0].city,          
//           country:res.data.companyAddress[0].country,
//           websiteUrl:res.data.companyAddress[0].webSiteUrl,
//           postalCode:res.data.companyAddress[0].postalCode,
          
//         });
//       }
//       else
//       {
//         setState(
//           {...state,productFamilies:[],
//             companyName:[],
//             jobPosition:'',
//             address:'',
//             city:'',          
//             country:'',
//             websiteUrl:'',
//             postalCode:'',
            
//           });
//           getData();
//       }
      
      
//       //console.log("states:",state);
      
//         });
//   }
//   getData();      
//   }  
  
// }, [state.email]);  

  const signUp = (otpSuccess) => {
    //handleNext();

    console.log("otpsuccess:",otpSuccess);  
    if(otpSuccess)
    {
     console.log('hi');  
     setState({...state, verified: 1});
    }
    
    // const apiUrl = 'account';
    
    // //post method
    // return axios.post(apiUrl, { 
    //     data : state
    //   }
    //   ).then(res =>{  
    //    console.log("response:",res.data);
    //   }).catch(error =>{
    //     console.log("error" ,error)
    //   });     

    //console.log('signup mail');
    //const apiUrl = 'emailer';
    
    //post method
    // return axios.post(apiUrl, { 
    //     emailID : state.email
    //   }
    //   ).then(res =>{  
    //    console.log("response:",res.data);
    //   }).catch(error =>{
    //     console.log("error" ,error)
    //   });     
  }

  const handleBack = () => {

    // if(activeStep==1)
    // {
    //   setState({...state,errState:{email:'',
    //       jobPosition:'',
    //       companyName:'',
    //       password:'',
    //       rePassword:'',

    //       address:'',
    //       city:'',
    //       state:'',
    //       country:'',
    //       postalCode:'',
    //       websiteUrl:'',
    //       switchPhone:'',
    //       productFamilies:'',
    //     }            
    //     });     
    // }

    // if(activeStep==2)
    // {
    // setState({...state,errState:{email:'',
    //       jobPosition:'',
    //       companyName:'',
    //       password:'',
    //       rePassword:'',

    //       address:'',
    //       city:'',
    //       state:'',
    //       country:'',
    //       postalCode:'',
    //       websiteUrl:'',
    //       switchPhone:'',
    //       productFamilies:'',
    //     }            
    //     });     
    // }
    // if(activeStep==3)
    // {
    //   setState({...state,errState:{
    //     //to b
    //       email:'',
    //       companyName:'',
    //       jobPosition:'',
    //       password:'',
    //       rePassword:'',

    //       address:'',
    //       city:'',
    //       state:'',
    //       country:'',
    //       postalCode:'',
    //       websiteUrl:'',
    //       switchPhone:'',
    //       productFamilies:'',
    //     }            
    //     });
    // }

    

    setState({...state,errState:{
          //to b
            email:'',
            firstName: '',
            lastName: '',
            companyName:'',
            jobPosition:'',
            password:'',
            rePassword:'',
  
            address:'',
            city:'',
            state:'',
            country:'',
            postalCode:'',
            websiteUrl:'',
            switchPhone:'',
            productFamilies:'',
          }            
          });

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  

  const handleReset = () => {
    setActiveStep(0);
  };

const handleChange = input => e => {
  console.log("add",e.target.value);  
  setState({...state, [input] : e.target.value});
}

const handleChangeAddress = (input, value, faddress) => {
  // console.log("Address", value);
  console.log(faddress)
  const gAddress = {
    address : faddress,
    city : '',
    state : '',
    country : '',
    postalCode : '',
    countryId : ''
  }
  value.map(x => {
    if(x.types.length && x.types.includes('locality'))
      gAddress.city = x.long_name;
    if(x.types.length && x.types.includes("administrative_area_level_1"))
      gAddress.state = x.long_name;
    if(x.types.length && x.types.includes("country")){
      gAddress.country = {name: x.long_name, countryCode : x.short_name};
      gAddress.countryId = x.short_name;
    }
    if(x.types.length && x.types.includes("postal_code"))
      gAddress.postalCode = x.long_name;
  })
  
  setState({...state, ...gAddress});
}


const handleChangeSelect = (input,value) => {
  console.log("value",value);  

  if(input == 'channelTypeId' || input == 'mobile' || input == 'address'){
    setState({...state, [input]:value});
  }
  else{
    setState({...state, [input]:[value]});
  }
//  console.log("state",state);
}


  function getStepContent(step) {
    switch (step) {
      case 0:
        //console.log(state);
        return <Step1 handleChangeSelect={handleChangeSelect} values={state} assignValues={assignValues}/>;
      case 1:
        //console.log(state);
        return <Step5 handleChange={handleChange} handleNext={handleNext} handleChangeSelect={handleChangeSelect} values={state} handleNext={handleNext} assignValues={assignValues}/>;
      case 2:
        //console.log(state);
         
         return <Step2  handleChange={handleChange}  handleChangeSelect={handleChangeSelect} values={state} handleNext={handleNext} staticValues={staticValues}/>;
      case 3:
        return <Step3 handleChangeAddress={handleChangeAddress} handleChangeSelect={handleChangeSelect} handleChange={handleChange} values={state} handleNext={handleNext} staticValues={staticValues}/>;
      case 4:
          return <Step4 signUp={signUp} handleChangeSelect={handleChangeSelect} values={state} handleNext={handleNext} assignValues={assignValues} staticValues={staticValues}/>;
    
      default:
        return 'Unknown step';
    }
  }


  return (
    <div class="main-content">
      <Logo/>
    <div class="header signup-header-bg py-4 py-lg-6 pt-lg-7">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
            <h1 class="text-white">Create Your Account</h1>
            <p class="text-lead text-white">You are only few minutes away of joining the largest community of consumer tech professionals in the World  </p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>

    <div class="container mt--8 pb-5">
    <div class="row justify-content-center">
    <div class="col-lg-10 col-md-12">
    <div class="card bg-secondary border-0 mb-0">  
  
    <div class="card-body px-lg-5 py-lg-5">   
    <div className={classes.root}>
      <Stepper style={{backgroundColor: '#f7fafc' }} activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step  key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div className="reset-but">
             <Typography className={classes.instructions}>Thankyou for your application.Our team will review your profile and comeback to you shortly.</Typography>
            {/* <button className="btn btn-warning ch-btn-blue reset-but" onClick={handleReset}>Reset</button> */}
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <div>
              {state.verified || activeStep == 2 ? '' : (<button
                hidden={activeStep === 0}
                onClick={handleBack}
                className="btn btn-primary back-but"
              >
                Back
              </button>)}

              

               {
               activeStep === steps.length - 1 ? 
               ( state.verified ? (<button  className="btn btn-primary ch-btn-blue right-but" variant="contained" color="primary" onClick={finishSubmit}>
                Finish
              </button>) : ''):''
                } 
              {/*: (<button  className="btn btn-primary ch-btn-blue right-but" variant="contained" color="primary" onClick={validateNext}>
                Next
              </button>)
                } */}
              {/* <button  className="btn btn-primary ch-btn-blue right-but" variant="contained" color="primary" onClick={activeStep === steps.length - 1 ? '' : validateNext}>
                {//activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </button> */}
            </div>
          </div>
        )}
      </div>
    </div>          
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>
  );
}
const data1 = [{id: "1",name: "AV"},{id: "2",name: "AB"}];