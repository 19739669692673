import React ,{useEffect, useState} from 'react';
import { Button, Modal } from "reactstrap";
import { Card,CardBody,Row,Col } from "reactstrap";
import { FormInput} from "_components/FormElements/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from 'axios';
import {ToastsContainer, ToastsStore} from 'react-toasts';
export  const HubspotEdit=(props)=> {
  const {  isOpen, handleClose, handleSave } = props;
  let [companyList, setCompanyList] = useState([]);
  let [companyHS, setCompanyHS] = useState([]);
  let [currentHubspotStatus, setCurrentHubspotStatus] = useState([]);
  
  const { register, control, errors, watch } = useForm({
    mode: "onBlur",
    // resolver: yupResolver(retailProfileSchema)
  });
  const [newhubspotId, setNewhubspotId ] = useState(null);
  const [hsstate, setHsstate ] = useState('none');
  const [chstate, setCHstate ] = useState('none');

  const [chflag, setCHflag ] = useState(0);
  const [hsflag, setHSflag ] = useState(0);

   
  const checkCurrentId = () => {
    if(props.type.hubspotCompanyId){
    axios.get('/hubspot/checkCompanyId/'+props.type.hubspotCompanyId).then(res1 => {
      if(res1.data){
        if(res1.data.portalId){
          setCurrentHubspotStatus('Portal Id: '+ res1.data.portalId);
        } else {
          setCurrentHubspotStatus(res1.data.message);
        }
      }
    });
   }
  }

  checkCurrentId();

  const handleHS = async() =>{

    let ch1 = false;
    let HS1 = false;
    var mewId = document.getElementById('new_hubspotId').value;
    let result1 = await axios.post('/hubspot/getMatchedHS', { 'mewId': mewId, 'channelId': props.type.channelId }).then(res => {
      if(res.data){
        props.type.sta = 'inline-block';
        setCompanyList(res.data);
        if(res.data.length){
          ch1 = true
        } else {
          ch1 = false
        }
        return res.data;
      }
    });
    let result2 =  await axios.get('/hubspot/checkCompanyId/'+mewId).then(res => {
      if(res.data){
       // setHsstate('inline-block');
        props.type.sta = 'inline-block';
        if(res.data.portalId){
          setCompanyHS('Portal Id: '+ res.data.portalId);
          HS1 = false;
        } else {
          HS1 = true;
          setCompanyHS(res.data.message);
        }
        return res.data;
      }
    });
    
  if(result1 && result2){
    if(HS1 == false && ch1 == false){
      document.getElementById('updatebuton').style.display = 'inline-block';
    }  else {
      document.getElementById('updatebuton').style.display = 'none';
    }
  }
 
    
    
  }

  const saveNewHS = async() => {
    var newHSId = document.getElementById('new_hubspotId').value;
    var channelId = props.type.channelId;
    axios.post('/channelAdmin/updateCompanyHS/',{hsId:newHSId, channelId:channelId}).then(res1 => {
      if(res1.data){
        axios.post('/hubspot/mergeCompanyContact/',{hsId:newHSId, channelId:channelId});
        ToastsStore.success('Company hubspot details updated successfully.');
        handleSave();
      }
    });
  }
    return (
    <div>
      <Modal
          className="modal-dialog-centered"
          isOpen={isOpen}
         size="xs"
        >
          <div className="modal-header">
          <h4>Current Hubspot Details</h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={handleClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
         
          
         
          <Card>
                <CardBody>
                   
                    <Row>
                        <Col lg="12" className="col-lg-6 ">
                          Hubspot Id : {props.type.hubspotCompanyId}
                        </Col>
                    </Row>  
                    <Row>
                        <Col lg="12" className="col-lg-6 ">
                          Hubspot Status : {currentHubspotStatus}
                        </Col>
                    </Row>  
                </CardBody>
            </Card>
            <div class="modal-header">
              <h4>New Hubspot Details</h4>    
            </div>
            <Card>
                <CardBody>     
                     
                    <Row>
                        <Col lg="12" className="col-lg-6 ">
                          <FormInput 
                                      id="new_hubspotId"
                                      type="number"
                                      name="new_hubspotId"
                                      label="New HUbspot Id"
                                      defaultValue={newhubspotId}
                                      value={newhubspotId}
                          />
                        </Col>
                    </Row> 
                    <Row>
                        <Col lg="12" className="col-lg-6 " style={{display:props.type.sta}}>
                        <h3> HS Response : </h3>
                           {companyHS}
                        </Col>
                    </Row>  
                    <Row>
                        <Col lg="12" className="col-lg-6 "  style={{display:props.type.sta }}>
                        
                            {companyList.map((item) =>
                              <p>{item.companyName} - {item.channelId} already exist in CH</p>)
                            }

                        </Col>
                    </Row>    
                </CardBody>   
          </Card>       
        
        
        <div className="modal-footer">
          <Button
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={() => handleHS()}
            >
              Verify
            </Button>
          
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              id="updatebuton"
              style={{display:props.type.upt}}
              onClick={() => saveNewHS()}
            >
              Update
            </Button>
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={handleClose}
            >
              Close
            </Button>
           
  
           
        </div>
        </Modal>
    </div>
  );
}


export default HubspotEdit;