import React,{useState,useEffect} from "react";
import {FormInput} from "_components/FormElements/FormInput";
import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

import "./Signup.scss"
import PlacesAutocomplete from 'react-places-autocomplete';
import PlaceIcon  from '@material-ui/icons/Place';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';


export const GoogleAddress = props => {
  const { errors,handleChangeAddress, defAddress, address1, register, handleChange, handleChangeSelect, id} = props;
//   const { register, handleSubmit, control, errors } = useForm({      
//     mode:'onBlur',  
//     resolver: yupResolver(signupschema)
// });
    
    const [address,setAddress]=useState(defAddress);
    //console.log("Defauly", defAddress);
    const handleSelect =async value => {
      const results=await geocodeByAddress(value);      
      const latLng=await getLatLng(results[0]);
      console.log("sugg:",results);
      setAddress(value);
      setCoordinates(latLng);
      handleChangeAddress('address', results[0].address_components, value);
    };
    const [coordinates,setCoordinates]=useState({
    lat:null,
    lng:null
    });
  
    useEffect(() => {
      //  let src='https://maps.googleapis.com/maps/api/js?key=AIzaSyDNJ5mvENR0ocI61G_TfPuhGQvrG2erhNY&libraries=places';
      
      //  const script = document.createElement("script");
      //      script.src = src;
      //      script.async = true;
    
      //      document.body.appendChild(script);
      handleChangeSelect('address', address);
    }, [address]);


  return ( 

  <div className="canvas">
  <PlacesAutocomplete  value={address} 
          onChange={setAddress}
          onSelect={handleSelect}> 
  
  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <FormInput 
                        id="address"
                        type="text"
                        name="address"
                        label="Address"
                        //placeholder="Password"
                        //register={register}
                        //error={errors.address}
                        value={address}
                        
                        //onChange={handleChange('address')}
                        //onChange={(e) => { console.log('hi') }}
                        
                        //id={id}
                        {...getInputProps({
                          placeholder: 'Search Places ...',
                          //className: 'location-search-input',
                        })}        
                    />
              {/* <input
                {...getInputProps({
                  placeholder: 'Search Places ...',
                  className: 'location-search-input',
                })}
              /> */}
              <div className="autocomplete-dropdown-container" style={{zIndex: 100001,position: 'absolute',width: '100%'}}>
                {loading && <div className="input-suggestion"><PlaceIcon style={{ color: '#4285f4', marginRight:'.5rem' }}></PlaceIcon> Loading...</div>}
                {!loading && suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    
                    <div className="input-suggestion"
                      {...getSuggestionItemProps(suggestion, {
                        // className,
                        style,
                      })}
                    >
                        <PlaceIcon style={{ color: '#4285f4', marginRight:'.5rem' }}></PlaceIcon>
                        <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          
  </PlacesAutocomplete>
  </div> 
  );
  }
  
  
//export default ParlorForm;