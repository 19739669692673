import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import { commonService } from '_services/common.service';
// import { ChannelDismiss } from 'views/Hoc/Dialog/ChannelDismiss';
// import { ChannelConnect } from "views/Hoc/Dialog/ChannelConnect";
// import { AskmeetingPopup } from "views/Hoc/Dialog/AskmeetingPopup";
// import { Dialog, UpgradeModal } from "views/Hoc/Dialog/GlobalDialog";
import { history } from '../../../_helpers/history';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import axios from 'axios';

export const ContactListing = (props) => {
  const { data, title, channelTypeId, channelId, getChannelContact, notify, mynetwork } = props;
  const [showMore, setMore] = useState(false);
  let [dismissPopUp, setDismissPopUp] = useState(false);
  let [connectPopUp, setConnectPopUp] = useState(false);
  let [isModalPopup, setModalPopup] = useState(false);
  let [isUpgradePopup, setUpgradePopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });
  let [UpdateData, setUpdateData] = useState(null);
  let [askMeeting, setAskMeeting] = useState(false);
  let [selectedContact, setSelectedContact] = useState(null);
  // let [connectionLimit, setConnectionLimit] = useState({ connectLimit });
  let [suggest_Count, setSuggest_Count] = useState(0);
  let [outboundRemaind, setOutboundRemaind] = useState({ remaining: null, end_date: null });
  let userData = JSON.parse(localStorage.getItem('CHuser'));
  let [dissmissFrom, setDissmissFrom] = useState(null);
  let logo = 'http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png';
  // useEffect(() => {
  //   getSugOutboundcount();
  // }, []);

  const more_click = () => {
    if (!showMore) setMore(true);
    else setMore(false);
  }

  if (data?.records?.length > 0 || data.length > 0) {
    let _remain = [];
    let _elements;
    if (mynetwork == true) {
      _elements = data.slice(0, 4); // clone array
    }
    else {
      _elements = data?.records.slice(0, 4); // clone array
      _remain = data?.records.slice(4, data.length);
    }

    const contact = (data) => {
      return (<>
        {data.length > 0 &&
          <>{data.map((x) => {
            return (<Col lg="3" xs="12" md='6'>
              <Card>
                <div className="detv-contact">
                  <div className={"conne-img"}>
                    <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"); }} className="img-fluid" alt="contact img" src={x.record.documenturl ? x.record.documenturl : logo} />
                  </div>
                  <div className="conn-cont">
                    <h3 style={{ marginTop: "0.5rem" }}> {x.record.firstName + ' ' + x.record.lastName} </h3>
                    <p className={'contactTitle'} style={{ marginBottom: "0.3rem" }}> {x.record.jobTitle} </p>
                    <a href={'/admin/mynetwork/channelDetail/' + x.channelId} ><p style={{ marginBottom: "0.3rem" }}> {x.record.companyName} </p></a>
                    <h5>{x.record.country}</h5>
                  </div>
                  <div style={{ textAlign: "center" }} className="conn-btn">

                    {!!x.record?.sociallink?.length &&
                      x.record.sociallink.map(y => (
                        <>
                          {!!y.contactSocialLink ? <a onClick={() => {
                            let url = y.contactSocialLink;
                            if (!url.match(/^https?:\/\//i) && !url.match(/^http?:\/\//i)) {
                              url = '//' + url;
                            }
                            window.open(url, '_blank')
                          }}>
                            <Button className="btn-icon-only mr-2 i-link" color="twitter" type="button">
                              <span className="btn-inner--icon">
                                <i className="fab fa-linkedin" />
                              </span>
                            </Button>
                          </a>
                            :
                            <a data-placement="top" id={'social-id' + x.record.contactId} onClick={() => {
                            }}>
                              <Button className="btn-icon-only mr-2 i-linkDisabled" type="button">
                                <span className="btn-inner--icon">
                                  <i className="fab fa-linkedin" />
                                </span>
                              </Button>
                              <UncontrolledTooltip target={"social-id" + x.record.contactId} placement='bottom'>
                                {userData?.contactId == x.record.contactId ? 'Update your LinkedIn url for better profile' : 'LinkedIn url not available for this contact.'}
                              </UncontrolledTooltip>
                            </a>
                          }</>
                      ))

                    }


                    {/* {mynetwork === true && <>
                      <Button className="btn-icon-only" color="eyeicon" type="button">
                        <a style={{color:'inherit'}} href={'/admin/mynetwork/channelDetail/' + x.channelId} >
                          <span className="btn-inner--icon">
                            <i style={{top:"0px",color:"fff"}}className="far fa-eye" />
                          </span>
                        </a>
                      </Button>
                      
                    </>} */}


                    {/* {x.relationId === '2' && <> */}
                      <Button className="btn-warning btn-icon-only" type="button">
                        <span className="btn-inner--icon">
                          <i style={{top:"0px"}} class="fas fa-minus-circle"></i>
                        </span>
                      </Button>
                      <Button className="btn-primary btn-icon-only" color="default" type="button">
                      <a style={{color:'inherit'}}href={'mailto:' + x.record?.email} >
                          <span className="btn-inner--icon">
                          <i style={{top:"0px"}} className="far fa-envelope">
                           </i>
                            </span>
                            </a>
                          </Button>
                      {/* </>} */}
                  </div>
                  {/* {(x?.relationId && parseInt(x?.relationId) !== 0) ? <div class=""><div class="ribbon-9">{getStatus(x)}</div></div> : ''} */}
                </div>
              </Card>
            </Col>)
          })}</>
        }
      </>
      )
    }
    return (
      <Card>
        <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
        {/* <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} outboundRemaind={outboundRemaind} /> */}
        <CardBody className="">
          <h3> {title} </h3>
          <div style={{ padding: "1rem" }}>
            <Row>
              {contact(_elements)}
            </Row>
            {showMore &&
              <Row>
                {contact(_remain)}
              </Row>
            }
          </div>
          {data?.records?.length > 4 && <h3 onClick={more_click} className='float-right text-primary' style={{ cursor: 'pointer' }}>{!showMore ? 'Show More' : 'Show Less'}</h3>}
        </CardBody>
      </Card>
    )
  }
}
