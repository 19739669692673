import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";
import { InputLabel, Select, TextField, FormControl } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { useForm } from "react-hook-form";
import { FormAutocomplete, FormTextArea } from "_components/FormElements/FormInput";
import { commonService } from '_services/common.service';
import { Spinner } from 'views/Hoc/Spinner';
import { history } from '_helpers/history';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const offerTypeOption = [{ id: 1, name: "Previous Event Attended Offer" }, { id: 2, name: "Code Offer" }];

const typeOption = [{ id: 2, name: "VENDOR" }, { id: 3, name: "DISTRIBUTOR" }, { id: 4, name: "RETAILER" }, { id: 5, name: "RESELLER" }];
const Offerform = (props) => {

  const { register, handleSubmit, control, errors } = useForm({
    mode: 'onBlur',
    //resolver: yupResolver(TradeInformationSchema),
  });

  const { couponId, getCouponListing,eventOptionList } = props;

  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    typeOptions: typeOption,
    typeValue: [],
    couponId: null,
    eventOption: eventOptionList.eventOption,//[],
    eventValue: [],
    statusOptions: [{ id: "1", name: "Active" }, { id: '0', name: "InActive" }],
    statusValue: [],
    discountOptions: [{ id: 2, name: "Percentage", id: 1, name: "Amount" }],
    discountValue: [],
    couponDesc: '',
    fullDesc: '',
    couponCode: '',
    couponOfferValue: '',
    userThreshold: '',
    usageThreshold: '',
    fromDate: new Date(),//('2014-08-18T21:11:54'),        
    toDate: new Date(),//('2014-08-18T21:11:54'),    
    offerTypeOption: offerTypeOption,
    prevEventOption: eventOptionList.prevEventOption,//[],
    offerType: [],
    offerParam: '',
    prevEventValue: [],

  });






  //const [fromDate, setFromDate] = React.useState(new Date('2014-08-18T21:11:54'));
  //const [toDate, setToDate] = React.useState(new Date('2014-08-18T21:11:54'));

  useEffect(() => {
    //getEventList();
    console.log("couponId:",couponId);
    if (couponId != 0) {
      setEditCoupon();      
    }
    else {
      
      setState({
        ...state,
        typeOptions: typeOption,
        typeValue: [],
        eventValue: [],
        statusOptions: [{ id: "1", name: "Active" }, { id: '0', name: "InActive" }],
        statusValue: [],
        discountOptions: [{ id: 2, name: "Percentage", id: 1, name: "Amount" }],
        discountValue: [],
        couponDesc: '',
        fullDesc: '',
        couponCode: '',
        couponOfferValue: '',
        userThreshold: '',
        usageThreshold: '',
        fromDate: new Date(),//('2014-08-18T21:11:54'),        
        toDate: new Date(),//('2014-08-18T21:11:54'),  
        offerTypeOption: offerTypeOption,
        offerType: null,
        offerParam: '',
        prevEventValue: [],
        // prevEventOption: [],
      });
    }
  }, [couponId]);

  const getEventList = async () => {
    let result = await commonService.getServices('/channelAdmin/getEventList');
    if (result)
      setState({ ...state, prevEventOption: result.data.prevEvents, eventOption: result.data.currentEvents });

  }

  const setEditCoupon = async () => {

    setLoading(true);
    await commonService.getServices('channelAdmin/eventOffers/' + couponId).then(coupons => {
      if (coupons) {

        let prevEvents = [];
        let codeoffer = '';
        let channelType = typeOption.find(type => type.id == coupons.data[0].channelTypeId)
        let currentEvent = state.eventOption.find(event => event.id == coupons.data[0].eventId);

        if (coupons.data[0].offerType == 1) {
          let ids = coupons.data[0].offerParam.split(',').map(id => +id);
          prevEvents = state.prevEventOption.filter(x => ids.includes(+x.id));
        }
        if (coupons.data[0].offerType == 2)
          codeoffer = coupons.data[0].offerParam;


        setState({
          ...state, couponId: couponId, eventValue: currentEvent,
          prevEventValue: prevEvents,
          typeValue: channelType,
          statusValue: { id: coupons.data[0].isActive, name: coupons.data[0].isActive == 1 ? 'Active' : 'InActive' },
          discountValue: state.discountOptions.find(x => x.id == coupons.data[0].discountUnit),
          couponDesc: coupons.data[0].couponDesc,
          fullDesc: coupons.data[0].fullDesc,
          couponCode: codeoffer,//coupons.data[0].couponCode,
          couponOfferValue: coupons.data[0].couponOfferValue,
          userThreshold: coupons.data[0].userThreshold,
          usageThreshold: coupons.data[0].usageThreshold,
          fromDate: coupons.data[0].fromDate,
          toDate: coupons.data[0].toDate,
          offerType: offerTypeOption.find(x => x.id == coupons.data[0].offerType),
          offerParam: coupons.data[0].offerParam,

        });
       
        setLoading(false);
      }
    });
    // console.log("coupons:", coupons);

  }

  const handleChange = input => e => {
    setState({ ...state, [input]: e.target.value });
  };


  const save = async () => {

    let offerParams;
    if (state.offerType?.id == 1) {
      offerParams = state.prevEventValue?.map(x => +x.id).toString();
    } else if (state.offerType?.id == 2) {
      offerParams = state.couponCode;
    }
    let data = {
      eventId: +state.eventValue.id,
      offerType: +state.offerType.id,
      offerParam: offerParams,
      isActive: state.statusValue.id,
      channelTypeId: +state.typeValue.id,
      discountUnit: +state.discountValue.id,
      couponOfferValue: +state.couponOfferValue,
      userThreshold: +state.userThreshold,
      usageThreshold: +state.usageThreshold,
      couponDesc: state.couponDesc,
      fullDesc: state.fullDesc,
      eventName: state.eventValue.shortname,
      //couponCode: state.couponCode,
      fromDate: state.fromDate,
      toDate: state.toDate,
    }

    console.log("saveOffer:", data);
    setLoading(true);
    if (couponId == 0)
      await commonService.postService({ ...data, add: 1 }, 'channelAdmin/addOffer');
    else
      await commonService.postService({ ...data, couponId: couponId, add: 0 }, 'channelAdmin/addOffer');
    setLoading(false);

    closeDrawer();

    getCouponListing();

  }


  const handleFromDate = (date) => {
    state.fromDate = date;
    setState({ ...state, fromDate: state.fromDate })
    //setFromDate(date);
  };

  const handleToDate = (date) => {
    state.toDate = date;
    setState({ ...state, toDate: state.toDate })
    //setToDate(date);
  };

  const handleChangeSelect = (input, value) => {

    setState({ ...state, [input]: value });

  }

  const [showDrawer, setshowDrawer] = useState(false);

  const closeDrawer = () => {
    props.onClose();
    setshowDrawer(false);
  }

  return (

    <div>
      {loading ? <Spinner /> : (
        <Card id='Coupon'>
          <CardBody>
            <form className="coupon-form" noValidate autoComplete="off" onSubmit={handleSubmit(save)}>
              <Row>
                <Col lg="6" className="mt-4">
                  <TextField id="outlined-basic" value={state.couponDesc} onChange={handleChange('couponDesc')} label="Offer Title" variant="outlined" />
                </Col>

                <Col lg="6" className="mt-4">
                  <FormControl variant="outlined">
                    <FormAutocomplete
                      id="eventValue"
                      name="eventValue"
                      label="Select Event"
                      options={state.eventOption}
                      placeholder="Select Event"
                      input='eventValue'
                      errMessage={'Please Select Event'}
                      //error={!errors.subplan || state.subplan.length ?false:true}
                      value={state.eventValue}
                      defaultValue={state.eventValue}
                      handleChangeSelect={handleChangeSelect}
                      control={control}
                      //multiple
                      disableCloseOnSelect
                      blurOnSelect
                      getOptionSelected={(options, value) => options.name === value.name}
                    />

                  </FormControl>
                </Col>


              </Row>
              <Col lg="12" className="mt-4">
                <FormTextArea
                  className="buytext2 w-100 h-auto"
                  name="fullDesc"
                  id="fullDesc"
                  rows="3"
                  type="textarea"
                  label="Offer Description *"
                  defaultValue={state.fullDesc}
                  // placeholder="Tell us about the Event -max 5000 characters *"
                  value={state.fullDesc}
                  onChange={handleChange('fullDesc')}
                />
              </Col>
              <Row>

              </Row>

              <Row>

                <Col lg="6" className="mt-4">
                  <FormControl variant="outlined">
                    <FormAutocomplete
                      id="offerType"
                      name="offerType"
                      label="Offer Type"
                      options={state.offerTypeOption}
                      placeholder="Select Offer Type"
                      input='offerType'
                      errMessage={'Please Select Offer Type'}
                      //error={!errors.subplan || state.subplan.length ?false:true}
                      value={state.offerType}
                      defaultValue={state.offerType}
                      handleChangeSelect={handleChangeSelect}
                      control={control}
                      //multiple
                      disableCloseOnSelect
                      blurOnSelect
                      getOptionSelected={(options, value) => options.name === value.name}
                    />

                  </FormControl>
                </Col>

                {state.offerType?.id == 2 &&
                  <Col lg="6" className="mt-4">
                    <TextField id="outlined-basic" value={state.couponCode} label="Code Offer" onChange={handleChange('couponCode')} variant="outlined" />
                  </Col>}
                {state.offerType?.id == 1 &&
                  <Col lg="6" className="mt-4">
                    <FormControl variant="outlined">
                      <FormAutocomplete
                        id="prevEventValue"
                        name="prevEventValue"
                        label="Select Previous Event"
                        options={state.prevEventOption}
                        placeholder="Previous Event Attended Offer"
                        input='prevEventValue'
                        errMessage={'Please Select Previous Event Attended Offer'}
                        //error={!errors.subplan || state.subplan.length ?false:true}
                        value={state.prevEventValue}
                        defaultValue={state.prevEventValue}
                        handleChangeSelect={handleChangeSelect}
                        control={control}
                        multiple
                        disableCloseOnSelect
                        blurOnSelect
                        getOptionSelected={(options, value) => options.name === value.name}
                      />

                    </FormControl>
                  </Col>}

              </Row>

              <Row>
                <Col lg="6" className="mt-4">
                  <TextField id="outlined-basic" value={state.couponOfferValue} label="Offer Value" onChange={handleChange('couponOfferValue')} variant="outlined" />
                </Col>
                <Col lg="6" className="mt-4">
                  <FormControl variant="outlined">
                    <FormAutocomplete
                      id="discount"
                      name="discount"
                      label="Offer Discount Unit"
                      options={state.discountOptions}
                      placeholder="Coupon Discount Unit"
                      input='discountValue'
                      errMessage={'Please Select Offer Discount Unit'}
                      //error={!errors.subplan || state.subplan.length ?false:true}
                      value={state.discountValue}
                      defaultValue={state.discountValue}
                      handleChangeSelect={handleChangeSelect}
                      control={control}
                      //multiple
                      disableCloseOnSelect
                      blurOnSelect
                      getOptionSelected={(options, value) => options.name === value.name}
                    />

                  </FormControl>
                </Col>
              </Row>



              <Row>
                <Col lg="6" className="mt-4">
                  <span>Offer From Date</span>
                  <div className="awd-date">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="datefrom"
                        //label="Coupon From Date"
                        value={state.fromDate}
                        onChange={handleFromDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Col>
                <Col lg="6" className="mt-4">
                  <span>Offer To Date</span>
                  <div className="awd-date">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="dateto"
                        // label="Coupon To Date"
                        value={state.toDate}
                        onChange={handleToDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg="6" className="mt-4">
                  <FormControl variant="outlined">
                    <FormAutocomplete
                      id="status"
                      name="status"
                      label="Offer Active/Inactive"
                      options={state.statusOptions}
                      placeholder="Offer Active/Inactive"
                      input='statusValue'
                      errMessage={'Please Select Offer Active/Inactive'}
                      //error={!errors.subplan || state.subplan.length ?false:true}
                      value={state.statusValue}
                      defaultValue={state.statusValue}
                      handleChangeSelect={handleChangeSelect}
                      control={control}
                      //multiple
                      disableCloseOnSelect
                      blurOnSelect
                      getOptionSelected={(options, value) => options.name === value.name}
                    />

                  </FormControl>
                </Col>

                <Col lg="6" className="mt-4">
                  <FormControl variant="outlined">
                    <FormAutocomplete
                      id="channeltype"
                      name="channeltype"
                      label="Offer Channel Type"
                      options={state.typeOptions}
                      placeholder="Offer Channel Type"
                      input='typeValue'
                      errMessage={'Please Select Offer Channel Type'}
                      //error={!errors.subplan || state.subplan.length ?false:true}
                      value={state.typeValue}
                      defaultValue={state.typeValue}
                      handleChangeSelect={handleChangeSelect}
                      control={control}
                      //multiple
                      disableCloseOnSelect
                      blurOnSelect
                      getOptionSelected={(options, value) => options.name === value.name}
                    />
                  </FormControl>
                </Col>

              </Row>



              <Row>
                <Col lg="6" className="mt-4">
                  <TextField id="outlined-basic" value={state.userThreshold} label="User Threshold" variant="outlined" onChange={handleChange('userThreshold')} />
                </Col>
                <Col lg="6" className="mt-4">
                  <TextField id="outlined-basic" value={state.usageThreshold} label="Usage Threshold" variant="outlined" onChange={handleChange('usageThreshold')} />
                </Col>
              </Row>


              <div className="text-center mt-4">
                <button type="submit" className="btn btn-primary" >Save</button>
                <button onClick={e => { e.preventDefault(); closeDrawer() }} className="btn btn-warning" >Cancel</button>
              </div>
            </form>
          </CardBody>
        </Card>
      )};
    </div>

  );
};

export default Offerform;