import React, { useEffect, useState } from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import TablePagination from '@material-ui/core/TablePagination';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
 
import { Card, } from "@material-ui/core";
import Modal from '@mui/material/Modal';
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';

import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
import { ModalSpinner } from "views/Hoc/Spinner";
 
const BookingHotelUsersData = ({roomTypeId, hotelBookingUserDataFlag,setHotelBookingUserDataFlag}) => {
     const[loading, setLoading] = useState(false)
     const[bookingData, setBookingData] = useState([])
     let [page, setPage] = useState(0);
     let [rowsPerPage, setRowsPerPage] = useState(10);
     const[count, setCount] = useState(10);

     useEffect(()=>{
        getBookingData(roomTypeId)
     },[])

     const getBookingData = async(roomTypeId)=>{
        setLoading(true)
        await axios.get(`channelAdmin/getHotelBookingDataByRoomId/${roomTypeId}`)
        .then((res)=>{
            setLoading(false);
            setBookingData(res.data)
     }).catch((err)=>console.log(err))
     }
     
     const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
       
      
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setLoading(true)
      };
    

  return (
    <Modal
    open={hotelBookingUserDataFlag}
    onClose={()=>setHotelBookingUserDataFlag(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    style={{
     display: 'flex',
     alignItems: 'center',
     justifyContent: 'flex-end', 


   }}
  > 
        <Card style={{margin:"0", overflowY:"scroll",height:"100vh",  padding:"14px",minWidth:"74%", maxWidth:"80%"}}>
        <div style={{display:"flex", justifyContent:"space-between",textAlign:"center", width:"97%"}}>
        <button className="btn btn-primary btn-sm" onClick={()=>setHotelBookingUserDataFlag(false)}> <i class="fa fa-arrow-left"></i></button>
        <h3>User Detail</h3>
                  <button style={{border:"none", backgroundColor:"white"}} onClick={()=>setHotelBookingUserDataFlag(false)}><CloseIcon/></button>
                </div>

   {loading && <ModalSpinner/>}
   <div style={{display:loading==true?"none":'block'}}>  
      <ToolkitProvider
        data={bookingData}
        keyField="id"
        columns={[ 

         {
           dataField: "firstName",
           text: "User Name",
           sort: true,
           formatter: (cell, row) => (
              `${cell} ${row.lastName}`
           )
         },

        //   {
        //     dataField: "roomName",
        //     text: "Room Type",
        //     sort: true,
        //     formatter: (cell, row) => (
        //       <>
        //       <div>{cell}</div>
        //       <div>{ `Available Room ${row.roomCount}`}</div> 
        //       </>
        //     )
        //   },

          {
            dataField: "channelTypeId",
            text: "Participant Type",
            formatter: (cell) => {
                switch (cell) {
                  case '1':
                    return 'HUB';
                  case '2':
                    return 'VENDOR';
                  case '3':
                    return 'DISTRIBUTOR';
                  case '4':
                    return 'RETAILER';
                  case '5':
                    return 'RESELLER';
                  case '6':
                    return 'SIGNUP';
                  default:
                    return '';
                }
            }
          },

          {
            dataField: "name",
            text: "Event Name",
            sort: true,
          },
 

 
          {
            dataField: "hotelName",
            text: "Hotel",
            sort: true
          }, 

          {
            dataField: "roomName",
            text: "Room Type",
            sort: true
          }, 
        ]}
        search
      >
        {props => (
          <div className="table-responsive"> 
            <div
              id="datatable-basic_filter"
              className="dataTables_filter mb-2"
              style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
            >
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center",   paddingTop: "10px", paddingBottom: "10px" }}>
              
               
              </div> 
            </div>

 
            <BootstrapTable
              bordered={false}
              classes={`table-striped table-hover`}
              {...props.baseProps}
              bootstrap4={true}
            />
          </div>
        )}
      </ToolkitProvider>
      
      <TablePagination
        component="div"
        count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
  
      </div> 

 <div>

</div>
 
    </Card> 
        
  </Modal>
  )
}

export default BookingHotelUsersData