import React, { useEffect, useState , useRef } from 'react';
import { useForm} from "react-hook-form";
import { SelectAutocomplete,FormInput } from '_components/FormElements/FormInput'
import { Card, CardBody, Container, Row, Col,Media,  CardHeader,UncontrolledTooltip } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import UserFilter from "components/Filter-Right/UserFilter.js";
import pagination from "views/Hoc/pagination.js";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import axios from 'axios';
import {Spinner} from '../../Hoc/Spinner'
import TablePagination from '@material-ui/core/TablePagination';
import {TablePaginationActions} from 'views/Hoc/PaginationActions';
import { history } from '_helpers';

 // toggles collapse between mini sidenav and normal
 const toggleSidenav = e => {
 
  if (document.getElementById('filter').classList.contains("g-sidenav-pinned")) {
    document.getElementById('filter').classList.remove("g-sidenav-pinned");
    document.getElementById('filter').classList.add("g-sidenav-hidden");
  } else {
    document.getElementById('filter').classList.add("g-sidenav-pinned");
    document.getElementById('filter').classList.remove("g-sidenav-hidden");
  }
};


export const RequestIntro = (props) => {

  const { control } = useForm({ mode:'onBlur' });
  
let [loading, setLoading]  = useState(true)
let [page, setPage] = useState(0);
let [rowsPerPage, setRowsPerPage] = useState(10);

const [data, setData ] = useState({srcTable:[], srcTotal:0});

const [state, setState ] = useState({dataTable:[], total:0});


const handleChangePage = (event, newPage) => {
    page=newPage;
    setPage(page);
    console.log("page:",page,rowsPerPage);
    state.dataTable = data.srcTable.slice(page*rowsPerPage, (page+1)*rowsPerPage);
    //getReqListing();
};

const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    setPage(0);
    state.dataTable = data.srcTable.slice(page, rowsPerPage);
    //getReqListing();
};

useEffect(() => {
  getReqListing();    
}, []);
  
const getReqListing = async ()=>{   
    loading = true;
    setLoading(loading);
    let data = { 
    }
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.post('/channelAdmin/request_Intro/0',data
    ).then(res =>{      
      data.srcTable = res.data.result;
      data.srcTotal = res.data.result.length;
      loading = false;
      setData({ ...data});
      state.dataTable=data.srcTable.slice(page, rowsPerPage);
      state.total=data.srcTotal;      
      setLoading(loading);
      setState({ ...state });
    }).catch((err)=>{
      state.dataTable = [];
        state.total = 0;
        loading = false;
        setLoading(loading);
        setState({ ...state });
    })
}

const handleChange = e => {    
    
    if(e.target.value!='')
    {
        state.dataTable = data.srcTable.filter (item => item.companyName.toLowerCase().includes(e.target.value));
        state.total=state.dataTable.length;    

        state.dataTable = state.dataTable.slice(0,rowsPerPage);
        state.total=state.dataTable.length;            
        setState({...state,dataTable:state.dataTable,total:state.total});
    }
    else
    {
        state.dataTable = data.srcTable.slice(0,rowsPerPage);
        state.total=data.srcTable.length;    
        setState({...state,dataTable:state.dataTable,total:state.total});
    }
}

  const view = (channelId) => {
    history.push('/admin/reqintroview/'+channelId);
  }

  return (
    <div id="reqintrolist">
     
      
      <SimpleHeader name="Listing" parentName="Dashboard" filter={'request_intro'} />
      
        <Container className="mt--3" fluid>
        
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Request Intro</h3>
                 </CardHeader>
                 {loading? <Spinner/>:<ToolkitProvider
                  data={state.dataTable}
                  keyField="Id"
                  columns={[                                          
                    { 
                      dataField: 'S.No',
                      text: " S.No",
                      sort: true,
                      formatter: (rowContent, row, rowIndex) => {
                        return(
                          <>                          
                          {rowIndex+1+(page*rowsPerPage)}                          
                          </>

                        )
                      }
                    },
                    {
                      dataField: "companyName",
                      text: " Buyer Company",
                      sort: true
                    },
                    {
                      dataField: "count",
                      text: " Request",
                      sort: true
                    },                    
                    {
                      dataField: "Action",
                      text: "Action",
                      formatter: (rowContent, row, index) => {
                        return (    
                          <>                          
                         <i id={'Edit' + index} onClick={()=>view(row.channelId)} class="far fa-eye pointer">
                         <UncontrolledTooltip target={'Edit' + index} placement='bottom'> View </UncontrolledTooltip></i> 
                         
                        </>
                        )
                        }
                      }
                    
                   
                  ]}
                  search
                >
              {props => (
                    <div className="py-2 table-responsive">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 mb-2"
                      >
                <div style={{ width:'100%'}}>
                          <FormInput
                            id="search"
                            type="text"                
                            label="search"
                            name="search"
                            className="d-inline-block"
                            style={{ width:'30%'}}
                            //options={searchState.dropdownList}
                            placeholder="search..."
                            //disableCloseOnSelect
                            //defaultValue={searchState.search?searchState.search[0]:null}
                            control={control}                         
                            input='search'
                            //value={searchState.search?searchState.search[0]:null}
                            onChange={handleChange}
                        /> 
                           
                        </div>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        // pagination={pagination}
                        bordered={false}
                        hover ={true}
                        rowClasses= 'pointer' 
                      />

                  <TablePagination
                        component="div"
                        count={state.total}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </div>
                  )}
                </ToolkitProvider>}

                
              </Card>
        
                            </Container>
    </div>
  );
};
export default RequestIntro;
