import * as yup from "yup";

const loginschema = yup.object().shape({
  email: yup.string().email("Mail id invalid").required("Required"),
  password: yup.string().min(8,"Password too short").required(),
});

const loginschema1 = yup.object().shape({
    age: yup.number().max(2,"smaller").required(),
  });
  
export {loginschema,loginschema1};