import React ,{useEffect, useState} from 'react';
import { Button, Modal } from "reactstrap";
import { Card, CardBody, ListGroupItem, ListGroup, Row, Col } from "reactstrap";
import { DateTimePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm} from "react-hook-form";
import FormHelperText from '@material-ui/core/FormHelperText';

export  const MeetingPopup=(props)=> {
  const {  isOpen, handleClose, submit, data  } = props;
  let [value, setValue] = React.useState(new Date());
  const handleDateChange = (date) => {
    console.log('date', date);
    setValue(date);
  };

  return (
    <div>
      <Modal
          className="modal-dialog-centered"
          isOpen={isOpen}
         size="sm"
        >
          <div className="modal-header">
           <h3>Meeting</h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={ handleClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body  d-flex justify-content-center pt-1 pb-1">
        <Card className="w-100 mb-0">
        <p>Do you want to Confirm Meeting with User </p>
        <div className="awd-date">
                  <FormControl> 
                  <MuiPickersUtilsProvider utils={DateFnsUtils} >            
                    <KeyboardDateTimePicker
                    // variant="inline"
                    // ampm={false}
                    // label="With keyboard"
                    value={value}
                    onChange={handleDateChange}
                    onError={console.log}
                    disablePast
                    format="dd-MM-yyyy hh:mm a"
                    />
                  {/* <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd HH:mm"
                    margin="normal"
                    id="date1"
                    name="date1"
                    defaultValue={state.fromDate}
                    // ref={register}
                    control={control}
                    value={state.fromDate}
                    onChange={handleDateChange}
                    // KeyboardButtonProps={{
                    //   'aria-label': 'change date',
                    // }}
                  /> */}
              </MuiPickersUtilsProvider>
              {/* <FormHelperText style={{color: '#f44336', float: 'right', fontSize:'0.75rem'}} >{errors.fromDate?.message}</FormHelperText> */}
              </FormControl>
              </div>
        </Card>


          </div>
          <div className="modal-footer">
            <Button
              data-dismiss="modal"
              type="button"
              onClick={()=>submit(value, data)}
              color="primary"
            >
              Confirm
            </Button>
           
          </div>
        </Modal>
    </div>
  );
}
export default MeetingPopup;