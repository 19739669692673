import React, { useState } from "react";
// react library for routing
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";
// reactstrap components
import {
  Collapse,
  NavItem,
  NavbarBrand,
  Navbar,
  Nav,
  Button,
} from "reactstrap";
import { useForm} from "react-hook-form";
import { FormAutocomplete } from "_components/FormElements/FormInput";
const BillingFilter = (props)=> {
  const {handleChangeSelect, toggleSidenav, sidenavOpen, resetFilterValue } = props

  const { control } = useForm({ mode:'onBlur' });
  const [collapseOpen, setCollapseOpen] = useState(false);

  console.log("bill:",props.filterOption);
    const {channel_status_Option, periods_Option,plan_Option, company_type_Option, sub_status_Option,} = props.filterOption;
    const { Company_type, plan, periods, channel_status,sub_status }=props.filterValue;

  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes

  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
  };
  const closeFilter = () => {
    if (document.getElementById("filter").classList.contains("g-sidenav-pinned")) {
      document.getElementById("filter").classList.remove("g-sidenav-pinned");
      document.getElementById("filter").classList.add("g-sidenav-hidden");
      document.getElementById("filter-hide").classList.add("filter-hide-width");
    }
  };

    // let navbarBrandProps;

    const scrollBarInner = (
      <div className="scrollbar-inner">
        {/* style={{height: "60px"}}  */}
        <div className="sidenav-header d-flex">
          <NavbarBrand>
            <Button className="btn-icon my-2" color="secondary" type="button" onClick={resetFilterValue}>
              <span className="btn-inner--icon">
              <i className="fas fa-sync-alt"></i>              </span>
              <span className="btn-inner--text">Clear Filter</span>
            </Button>
          </NavbarBrand>

          <div className="ml-auto">
            <div
              className={classnames("sidenav-toggler d-block", {
                active: sidenavOpen,
              })}
              onClick={closeFilter}
            >
              {/* <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div> */}
              <div>
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner">
          <Collapse navbar isOpen={true}>
            <hr className="my-3" />
            <Nav navbar>

              {/* Filter for Company Type */}  
              <NavItem>
                <div id="filter" className="row">
                  <div className="col-lg-12">
                    <div className="form-group" >
                       <FormAutocomplete
                        id="Company_type"
                        name="Company_type"
                        label="Company type"
                        options={company_type_Option}
                        placeholder="Select Company type"
                        multiple
                        disableCloseOnSelect
                        // limitTags={2}
                        control={control}
                        input='Company_type'
                        value={ Company_type }
                        handleChangeSelect={handleChangeSelect}
                        />
                    </div>
                  </div>
                </div>
              </NavItem>

              {/* Filter for OEM */}           

              <NavItem>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <FormAutocomplete
                        id="channel_status"
                        name="channel_status"
                        label="Channel Status"
                        options={channel_status_Option}
                        placeholder="Select  Channel Status"
                        multiple
                        disableCloseOnSelect
                        // limitTags={2}
                        control={control}                         
                        input='channel_status'
                        value={ channel_status }
                        handleChangeSelect={handleChangeSelect}
                        />
                    </div>
                  </div>
                </div>
              </NavItem>

              {/* Filter for Category */}

              <NavItem>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <FormAutocomplete
                        id="periods"
                        name="periods"
                        label="Subscription Period"
                        options={periods_Option}
                        placeholder="Select Subscription Period"
                        multiple
                        disableCloseOnSelect
                        limitTags={2}
                        control={control}                         
                        input='periods'
                        value={ periods }
                        handleChangeSelect={handleChangeSelect}
                        />
                    </div>
                  </div>
                </div>
              </NavItem>


              <NavItem>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <FormAutocomplete
                        id="plan"
                        name="plan"
                        label="Subscription Plan"
                        options={plan_Option}
                        placeholder="Select Subscription Plan"
                        multiple
                        disableCloseOnSelect
                        limitTags={2}
                        control={control}                         
                        input='plan'
                        value={ plan }
                        handleChangeSelect={handleChangeSelect}
                        />
                    </div>
                  </div>
                </div>
              </NavItem>


               

              {/* Filter for Roles */}

              <NavItem>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <FormAutocomplete
                        id="sub_status"
                        name="sub_status"
                        label="sub_status"
                        options={sub_status_Option}
                        placeholder="Select Subscription Status"
                        multiple
                        disableCloseOnSelect
                        limitTags={2}
                        control={control}                         
                        input='sub_status'
                        value={ sub_status }
                        handleChangeSelect={handleChangeSelect}
                        />
                    </div>
                  </div>
                </div>
              </NavItem>

              
            </Nav>
            <hr className="my-3" />
          </Collapse>
        </div>
      </div>
    );
    return (
      <Navbar
        id="filter-hide"
        className={
          "sidenav navbar-vertical navbar-expand-xs navbar-light bg-white filter-hide-width fixed-right"
        }
      >
        {navigator.platform.indexOf("Win") > -1 ? (
          <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
        ) : (
          scrollBarInner
        )}
      </Navbar>
    );
}

BillingFilter.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  rtlActive: false,
};

BillingFilter.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool,
};

export default BillingFilter;
