import React, { useEffect, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { FormTextArea, FormInput, FormAutocomplete, SingleAutocompleteGrouping, FormRadio, FormCheckBox, FormAutocompleteGrouping } from "_components/FormElements/FormInput";
import { useForm } from "react-hook-form";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Card, CardBody, ListGroupItem, ListGroup, Row, CardHeader,  ModalFooter, ModalHeader, ModalBody,Col } from "reactstrap";

import {
  FormControl,
  InputAdornment,
} from "@material-ui/core";
import {
  Button, Modal
} from "reactstrap";


export const ChangeCoordinator = (props) => {

    const { open, handleClose, handleSave, value } = props;
    //console.log(props)
    const theme = useTheme();
    const { register, handleSubmit, control, errors, watch } = useForm({
      mode: "onBlur",
      // resolver: yupResolver(retailProfileSchema)
    });
    const [reason, setReason] = useState();
    const [changeRegisterUser, setChangeRegisterUser] = useState(false);
    const [removePaticipant, setRemovePaticipant] = useState(false);

    const [changeRegisterUserValue, setChangeRegisterUserValue] = useState(false);
    const [removePaticipantValue, setRemovePaticipantValue] = useState(false);
    const [saveFlag, setSaveFlag] = useState(false);    
    const [enablePop, setEnablePop] = useState(false);
    const [validationError, setValidationError] = useState({ comments: false, coordinator: false });
    const [closeValidatePopup, setCloseValidatePopup] = useState(false)
    let selectedUser;

    const enableChkChangeRegisteredUser = () => {
      setChangeRegisterUserValue(true);
      setCloseValidatePopup(false);
      setEnablePop(false);
      enableChangeDiv(); 
    }
    
    const handleChange = (input, data) => {
        setRemovePaticipant(false)
        setChangeRegisterUser(false)
        setSaveFlag(false);
      if(input == 'coordinator' && data) {
        Object.assign(value,{newMainDelegate:+data.id})
        let newUserData = []
        value.overallUser.filter(x => x.contactId == +data.id).map(x => {
          newUserData.push(x)      
        });
        setRemovePaticipantValue(false);
        setChangeRegisterUserValue(false);
        if (newUserData[0]?.eventParticipants?.length > 0){
          setRemovePaticipant(true)
          setSaveFlag(true);
        } else if (newUserData[0]?.eventParticipants?.length == 0){
          setChangeRegisterUser(true)
          setEnablePop(true)
        }
      } else if (input == 'chkRemovePaticipant') {
        let stst2 = (removePaticipantValue == false)?true:false;
        setRemovePaticipantValue(stst2);
        setRemovePaticipant(true)
        setSaveFlag(true);          
      } else if(input == 'chkChangeRegisteredUser') {
        enableChangeDiv();        
      }
    }


    const enableChangeDiv = () => {
      let stst1 = (changeRegisterUserValue == false)?true:false;
        setChangeRegisterUserValue(stst1);
        setChangeRegisterUser(true)
        //changeRegisterUserValue = (changeRegisterUserValue)?false:true
        if(stst1){
          setSaveFlag(true);
        } else {
          setSaveFlag(false);
        }
    }
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));  

    const setReasonForChange = (e) => {
      setReason((e.target.value))
      Object.assign(value,{comments:e.target.value})
      validationError.comments = false 
      setValidationError({comments: validationError.comments })
    }
    const validate = () => {
      Object.assign(value,{changeregister:changeRegisterUserValue})
      Object.assign(value,{removeparticipant:removePaticipantValue})
      if(!value.newMainDelegate|| value.newMainDelegate == ''){
        validationError.coordinator = true
        setValidationError({coordinator: validationError.coordinator })        
      } else if(!value.comments || value.comments == ''){  
        validationError.comments = true 
        setValidationError({comments: validationError.comments })        
      } else {
        handleSave(value);
      }
    }
    const closePopError = (e) => {
      setCloseValidatePopup(false);
      setEnablePop(false);
    };
    return (
      <div>
        <Modal
          className="modal-dialog-centered"
          isOpen={enablePop}
          toggle={closePopError}
        >           
          <ModalHeader toggle={closePopError}>Change Register User </ModalHeader>
          <ModalBody>
              <div>
                  Chosen User is not Event registered user. This action will remove current event manager in database.
                  and change currently selected user as the register user. Are you sure want to proceed
              </div>   
          </ModalBody>
          <ModalFooter>
           
            <Button color="primary" type="button" onClick={closePopError}>
              Close
            </Button>
            <Button color="primary" type="button" onClick={enableChkChangeRegisteredUser}>
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
        <Modal className="modal-dialog-centered" isOpen={open}>
          <div className="modal-header">
            <strong>Change Event Coordinator</strong>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {/* {value.message}
            {value?.second_message && <p className="mt-2">{value?.second_message}</p>} */}
  
            <div className="col-sm-12">
                <FormControl variant="outlined" style={{ width: "100%" }}>
                <div>
                  <FormAutocomplete
                    id="eventuser"
                    name="eventuser"
                    label="Event Coordinator"
                    options={value.users}
                    placeholder="Select Coordinator"
                    disableCloseOnSelect
                    blurOnSelect
                    // limitTags={1}
                    multiple={false}
                    control={control}
                    input="coordinator"
                    getOptionSelected={(options, value) => options.name === value.name}
                    value={selectedUser}
                    handleChangeSelect={(e, input)=>handleChange(e, input)}
                    errMessage={"Please Select Coordinator"}
                    error={validationError.coordinator}
                  />
                </div> 
                <div> &nbsp; </div>
                {changeRegisterUser && <div>
                    <span style={{display: 'inline-flex'}}>
                    <FormCheckBox
                    id="chkChangeRegisteredUser"
                    type="checkbox"
                    input="chkChangeRegisteredUser"
                    label1={{textAlign:"justify"}}
                    checked={changeRegisterUserValue}
                    // register={register}
                    value={'checked'}
                    style={{border:'1px solid #ccc;'}}
                    onClick={(e, input)=>handleChange('chkChangeRegisteredUser',null)}
                    /> </span>
                    Chosen User is not Event registered user. This action will remove current event manager in database.
                    and change currently selected user as the register user. Are you sure want to proceed
                  </div>}
                 
                {removePaticipant && <div>
                  
                  <span style={{display: 'inline-flex'}}>
                    <FormCheckBox
                    id="chkRemovePaticipant"
                    input="chkRemovePaticipant"
                    type="checkbox"
                    label1={{textAlign:"justify"}}
                    checked={removePaticipantValue}
                    // register={register}
                    value={'checked'}
                    onClick={(e, input)=>handleChange('chkRemovePaticipant', null)}
                    /> </span>

                    Remove current event manager from the event participant
                  </div>}
                  <div> &nbsp; </div>
                  { saveFlag && <div style={{ paddingTop :"10px" }}>
                    <TextareaAutosize
                        maxRows={4}
                        control={control}
                        id="reason"
                        name="reason"
                        placeholder="Reason"
                        aria-label="maximum height"
                        value={reason}
                        onChange={setReasonForChange}
                        style={{ width:430, height: 100 }}
                      />
                       <span style={{ color: '#f44336',  fontSize: '0.75rem' }}>{
                          (validationError.comments) ? 'Comments can\'t be empty.' : ''
                        } </span>
                  </div>  }
                </FormControl>
              </div>
  
          </div>
          { saveFlag && <div className="modal-footer">
            <Button color="primary" type="button" onClick={() => validate()} >Confirm</Button>
            <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Cancel</Button>
          </div> }
          <div>
            &nbsp;
          </div>
        </Modal>
      </div>
    );
  }

  export default ChangeCoordinator;