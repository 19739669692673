import React, { useEffect, useState } from 'react'
import { FormInput, FormFileInput, FormSelect, FormButton, FormMultiSelect, FormTextArea, FormCheckBox, FormAutocomplete, FormRadio, NewFieldAutocomplete, } from "_components/FormElements/FormInput";
import {
  Card, CardBody, CardHeader, Container,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import TablePagination from '@material-ui/core/TablePagination';



import 'date-fns';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';

import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import { EventSchema } from './validation';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { SelectAutocomplete } from "_components/FormElements/FormInput";
import { ModalSpinner } from 'views/Hoc/Spinner';

const EventHotelBooking = ({ eventId }) => {
  const [booking, setBooking] = useState([])
  const [filterBooking, setFilterBooking] = useState([])
  const [hotels, setHotels] = useState([])
  const [hotel, setHotel] = useState(null)
  const [loading, setLoading] = useState(false)
  let [page, setPage] = useState(0)
  let [rowsPerPage, setRowsPerPage] = useState(10)
  const [count, setCount] = useState(10)
  const { register, handleSubmit, control, errors, watch, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(EventSchema),
  });

  useEffect(() => {
    getBookingByEventId()
  }, [rowsPerPage, page]);

  const getBookingByEventId = async () => {
    setLoading(true)

    let data = {
      page: page,
      limit: rowsPerPage
    }

    await axios.post(`channelAdmin/getBookingByEventId/${eventId}`, data)
      .then((res) => {
        setBooking(res.data.Booking);
        setFilterBooking(res.data.Booking);
        setCount(res.data.count)
        setHotels(res.data.Booking.map((item) => ({ name: item.hotelName })))
        setLoading(false)
      })
      .catch((err) => console.log(err))
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };



  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setLoading(true)
  };

  const filterHotels = (hotelName) => {
    if (hotelName == null) {
      setFilterBooking(booking)
    } else {
      setFilterBooking(booking.filter((item) => (item.hotelName === hotelName)))
    }
  }

  const handleFilter = (input, value) => {
    if (value == null) {
      filterHotels(null)
      setHotel(null);
      return
    }
    setHotel(value)
    filterHotels(value.name)
  }



  return (
    <Container className="col-lg-9 col-md-9 col-sm-12  " fluid>
      <Card>
        <CardHeader>
          <h2 style={{ textAlign: "center", color: "#2ca5cd" }}>Booking Hotels</h2>
        </CardHeader>
        <CardBody>
          <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />


          <div style={{ display: "flex", margin: 0, alignItems: "center", alignContent: "center", alignItems: "center" }}>
            <SelectAutocomplete
              id="search"
              type="text"
              label="Hotel"
              placeholder="Select Hotel Name"
              name="hotelName"
              className="d-inline-block"
              style={{ width: '35%' }}
              options={hotels}
              disableCloseOnSelect
              control={control}
              input='selectHotel'
              value={hotel}
              defaultValue={hotel}
              handleChangeSelect={handleFilter}
            />

          </div>

          {loading == true && <ModalSpinner />}

          <div style={{ display: loading == true ? "none" : 'block' }}>
            {
              booking.length > 0 ?
                <div>
                  <ToolkitProvider
                    data={filterBooking}
                    keyField="id"
                    columns={[

                      {
                        dataField: "firstName",
                        text: "User Name",
                        formatter: (cell, row) => (
                          `${cell} ${row.lastName}`
                        )
                      },


                      {
                        dataField: "channelTypeId",
                        text: "Participant Type",
                        formatter: (cell) => {
                          switch (cell) {
                            case '1':
                              return 'HUB';
                            case '2':
                              return 'VENDOR';
                            case '3':
                              return 'DISTRIBUTOR';
                            case '4':
                              return 'RETAILER';
                            case '5':
                              return 'RESELLER';
                            case '6':
                              return 'SIGNUP';
                            default:
                              return '';
                          }
                        }
                      },

                      {
                        dataField: "eventName",
                        text: "Event Name",
                      },



                      {
                        dataField: "hotelName",
                        text: "Hotel",
                        sort: true
                      },

                      {
                        dataField: "roomName",
                        text: "Room Type"
                      },

                      {
                        dataField: "bookingDate",
                        text: "Booking Date",
                        formatter: (cell, row) => cell.split(',').join(',  '),
                      },

                      {
                        dataField: "hotelId",
                        text: "View",
                        formatter: (cell, row) => (
                          <button onClick={() => {
                            let path = window.location.href.substring(0, window.location.href.lastIndexOf('/'));
                            path = path + `/hotel/${cell}`
                            window.open(path, '_blank');
                          }} id="View150" class="btn btn-icon btn-primary btn-sm" variant="contained" color="primary"><span class="btn-inner--icon"><i class="far fa-eye"></i></span></button>
                        )
                      },


                    ]}
                    search
                  >
                    {props => (
                      <div className="table-responsive">
                        <div
                          id="datatable-basic_filter"
                          className="dataTables_filter mb-2"
                          style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
                        >
                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "10px", paddingBottom: "10px" }}>


                          </div>
                        </div>


                        <BootstrapTable
                          bordered={false}
                          classes={`table-striped table-hover`}
                          {...props.baseProps}
                          bootstrap4={true}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                  <TablePagination
                    component="div"
                    count={count}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
                :

                <h4 style={{ textAlign: "center", marginTop: "4rem" }}>There is no hotel booking data</h4>
            }



          </div>

        </CardBody>
      </Card>
    </Container>
  )
}

export default EventHotelBooking