import React ,{useEffect, useState} from 'react';
import { Button, Modal } from "reactstrap";
import { Card, CardBody, ListGroupItem, ListGroup, Row, Col } from "reactstrap";
import axios from 'axios';
import { Spinner} from 'views/Hoc/Spinner';
import { commonService } from '_services/common.service';

export  const FeatureListDialog=(props)=> {

  const { closeFeatureDialog, Open, userParams } = props;
  let logo = 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg';

  const [state,setState]=useState({
        featureList:[]
  });
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    
    (async() => {            
      
      // await axios.get('/channel/subscription/getPlansDetails').then(res => {  
      //   console.log(' feature list >>>> ', res.data.result.subscriptionExtraFeature)
      //   setState({ ...state, featureList:res.data.result.subscriptionExtraFeature }); 
      // });

      await axios.post('/channel/subscription/getPlansDetailsBo', userParams).then(res =>{
        console.log('subscriptionExtraFeature');
        console.log(res.data.result.subscriptionExtraFeature);
        console.log('=========================================');
        setState({...state,featureList:res.data.result.subscriptionExtraFeature});
        setLoading(false);
      }).catch(err=> {
        setLoading(false);
        commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'/channel/subscription/getPlansDetails'},'/error');                     
    });

    })();
  }, []);


  const closeOpens=(e)=>{
    e.preventDefault();
    closeFeatureDialog(false);
  }

  return (
    <div>
    {loading? <Spinner />:(
      <Modal
          className="modal-dialog-centered"
          isOpen={Open}
         size="md"
        >
          <div className="modal-header">
           <h1>Feature List</h1>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={e=> closeOpens(e)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body  d-flex justify-content-center pt-1 pb-1">
        <Card className="w-100 mb-0">
        <CardBody>
              {state.featureList.map(x => (
                  <ListGroup className="list my--3" flush>
                    
                      <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                         <h4>{x.subscriptionExtraFeatureCategory.subsc}</h4>
                        </Col>
                       
                        <Col className="col-auto">
                        {x.feature_value} 
                        </Col>
                      </Row>
                    </ListGroupItem>
                  
                    </ListGroup>
             ))}
        </CardBody>
        </Card>


          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={e=>closeOpens(e)}
            >
              Close
            </Button>
           
          </div>
        </Modal>
    )}
    </div>
  );
}
export  default FeatureListDialog;