import React ,{useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import { Button, Modal } from "reactstrap";
import { ProfileUploader } from '../ProfileUpload';
import { commonService } from '_services/common.service';
import {Spinner} from 'views/Hoc/Spinner';
import {ToastsContainer, ToastsStore} from 'react-toasts';

export const AddBrand=(props)=> {
const { opneadd, closeModalPopup, brandName,isEdit } = props;

const [image, setImage] = React.useState('');
const [imageSrc, setImageSrc] = React.useState('');
const [documentUrl, setDocumentUrl] = React.useState(null);
const [brand, setBrand] = React.useState(brandName);
const [loading, setLoading] = React.useState(false);
const [error, setError] = React.useState('');

useEffect(() => {
  console.log('props',props);
  if(props?.isEdit && opneadd){
    setLoading(true);
    setValues();
  }
}, [opneadd]);

const setValues=()=>{
  let value = props?.value;
  setBrand(value?.brandName);
  setImageSrc(value?.document?.documentPath?value?.document?.documentPath:null);
  setDocumentUrl(value?.document?.documentUrl?value?.document?.documentUrl:null)
  console.log('brand', brand, imageSrc);
  setLoading(false);
}
console.log('brandaa', brand);

const selectImg = async(data)=>{
    if(data){
      setImage('');
      //converting base64 string to file format.
      var random = Math.random(); 
      var fileName =random +'.png';
      var file = dataURLtoFile(data, fileName);    
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/'+props.aws_folderName);
      console.log('resImagePath', resImagePath);
      if(resImagePath){
        setDocumentUrl(process.env.REACT_APP_CDN_URL_TEMP+resImagePath.data.url);
        setImageSrc(resImagePath.data.url);
        setLoading(false);
      }
        
    }
    
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}

const handleChange = event => {
    const data = event.target.files[0];
    console.log('files', data)
      setError('');
      if(data && data['type'].split('/')[0] === 'image'){
        console.log('datat', data);
        let file = data;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          console.log('file', file)
          console.log('reader.result' ,reader.result)
          setImage(
            reader.result
          );
          console.log('image', image)      
        };
        
      }
      else{
        setError('Jpg | Jpeg | PNG Image Formats Only Accepted');
      }
    
  };

  const handleCropClose = () => {
    setImage('');
  }

  const addBrandData = async() => {
    if(brand && imageSrc){
        console.log('brand', brand);
        setLoading(true);
        const isAvailable = await commonService.postService({
          brandName: brand,
          id:props?.isEdit?props?.value?.id:null
        }, 'setting/channelBrandCheck');
        if(isAvailable.data){
          if(props?.isEdit){
            try{
            let data ={
              brandName: brand,
              brandImage:imageSrc,
              isActive:'1'
            }
            if(brand.trim() === props?.value.brandName.trim())
              delete data.brandName;
            if(imageSrc.trim() === props?.value?.document?.documentPath.trim())
              delete data.brandImage;
            const editBrand = await commonService.putService('master/brand/'+props?.value.id, data);
            if(editBrand.data){
              setLoading(false);
              ToastsStore.success(isEdit?'Update Successfully!':'Saved Successfully!');
              props.handleChangeSelect('brands', { name:brand, id: +props?.value?.id, documentUrl:documentUrl });
              closeModalPopup();
            }else{
              setLoading(false);
              ToastsStore.error('Something Went Wrong!');
            }
          }catch(err){
            setLoading(false);
            console.log('err',err);
          }
        }else{
          setLoading(false);
          let random = '10' + Math.floor(Math.random() * 100000);
          props.handleChangeSelect('brands', {name:brand, id: +random, doc_id:random,  documentpath:imageSrc, isNew: '1'});
          closeModalPopup();
          }
        }else{
          setLoading(false);
          setError('BrandName already taken.')
        }
    }else{
        let err = '';
        err = !brand ? 'Please Enter Brand Name' : '';
        err = !imageSrc ? err ? 'Please Enter Brand Name and Select Brand Image' :  'Please Choose an image' : err;
        setError(err);
        console.log('error', error);
    }
  }

  const handleBrand = e => {
    setBrand(e.target.value);
  }

return (
<div>
<Modal className="modal-dialog-centered" isOpen={opneadd}>
<ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
<div className="modal-header">
<h3 className="text-center">{isEdit?'Update Brands':'Add New Brands'}</h3>
<button
aria-label="Close"
className="close"
data-dismiss="modal"
type="button"
onClick={closeModalPopup}
>
<span aria-hidden={true}>×</span>
</button>
</div>
<div className="modal-body">
<div className="row">
{loading ? <div style={{ float: 'center' }}><Spinner /></div> : <>
<div className="col-6 d-flex align-items-center">
<TextField id="outlined-basic" label="Brand Name" variant="outlined" onChange={handleBrand} value={brand === true ? '' : brand}/>
</div>
<div className="col-6">
<ProfileUploader img={documentUrl ? documentUrl : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
handleChange={handleChange} selectImg={selectImg} image={image} handleCropClose={handleCropClose}
/>
</div></>
}
</div>
<span style={{color: '#f44336', fontSize:'0.75rem'}}>{error}</span>
</div>

<div className="modal-footer">
<Button
color="secondary"
data-dismiss="modal"
type="button"
onClick={closeModalPopup}
>
Close
</Button>
<Button
className='btn btn-primary'
data-dismiss="modal"
type="button"
onClick={addBrandData}
>
Add
</Button>
</div>
</Modal>
</div>
);
}
export default AddBrand;