import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {useEffect,useState} from 'react';
import {
    Button, Modal,Col,Card, Container, Row, CardHeader
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {FormInput,FormFileInput,FormSelect,FormButton,FormMultiSelect,FormTextArea, FormCheckBox, FormAutocomplete, FormRadio} from "_components/FormElements/FormInput";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import RiteAPIEdit from './RiteAPIEdit';
import { TablePaginationActions } from 'views/Hoc/PaginationActions';
import TablePagination from '@material-ui/core/TablePagination';
import BootstrapTable from "react-bootstrap-table-next";
import { SelectAutocomplete } from '_components/FormElements/FormInput';
import { Spinner } from '../../Hoc/Spinner'
import axios from 'axios';

export  const RiteAPI=(props)=> {
const { handleClose, handleSave, value, visible, packageData, savePackage } = props;
const [riteAPIEditOpen, setRiteAPIEditOpen] = useState(false);
const [keyVal, setkeyVal] = useState({});
const [searchState, setSearch] = useState({ search: null, dropdownList: [] });
let [loading, setLoading] = useState(true);
let [rowsPerPage, setRowsPerPage] = useState(10);
let [page, setPage] = useState(0);
const [state, setState] = useState({ dataTable: [], total: 0 });
const { control } = useForm({ mode: 'onBlur' });
let [isSort, setisSort] = useState(false);
let [sort, setSort] = useState('');
// const [open, Setopen] = useState(visible)
const closeOpen=(e)=>{
  e.preventDefault();
  // Setopen(false);
  handleClose();
}

useEffect(() => {
  getAllSearch();
  callDomainSearch();
}, []);

const getAllSearch = async () => {

  //props.location.approval
  loading = true;
  setLoading(loading);
  let data = {
    search: searchState.search ? searchState.search[0] ? searchState.search[0].id : null : null,
    page: page * rowsPerPage,
    order: isSort ? sort : false,
    limit: rowsPerPage
  }
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.post('/riteKiteAPI/getAllSearch', data).then(res => {
      state.dataTable = res.data.rows;
      state.total = res.data.count;
      loading = false;
      setLoading(loading);
      setState({ ...state });
    }).catch((err) => {
      state.dataTable = [];
      state.total = 0;
      loading = false;
      setLoading(loading);
      setState({ ...state });
    })
}

const editRiteAPI= (data) =>{
  console.log(data)
  setRiteAPIEditOpen(true);
  setkeyVal(data);
}

const riteAPIEditClose= () =>{
  setRiteAPIEditOpen(false);
}

const riteAPIEditSave = () => {
  setRiteAPIEditOpen(false);
}

const handleChangeRowsPerPage = (event) => {
  rowsPerPage = parseInt(event.target.value, 10);
  setRowsPerPage(rowsPerPage);
  callDomainSearch();
};

const handleChangeSelect = (input, value) => {
  if (input == 'search') {
    searchState.search = [value]
    setSearch({ ...searchState })
  }
  getAllSearch();
}

const handleChangePage = (event, newPage) => {
  page = newPage;
  setPage(page);
  callDomainSearch();
};

const callDomainSearch = () => {
  
  page = 0;
  setPage(page);
  axios.get('/riteKiteAPI/getAllDomainList/').then(res => {
      let data = [];
      data.push(...res.data)
      data = data.map(x => {
        return { name: x.domainName.trim(), id: x.ritekiteAPISearchId }
      });
     
      searchState.dropdownList = data
      setSearch({ dropdownList: searchState.dropdownList });
    });
  }


  return (
    <div id="domainList">
     <RiteAPIEdit isOpen={riteAPIEditOpen} type={keyVal} handleClose={riteAPIEditClose} handleSave={riteAPIEditSave} />
     <SimpleHeader location= {props.match} name="'External API - RiteAPI'" parentName="Dashboard" filter={false} />
     
    <div>
    <Container className="mt--3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h2>RiteKit API</h2>
                <h5>Returns a company logo based on a website domain. If the logo is not in our database yet, it will be extracted from the site on the fly.</h5>
                <Button style={{ float: 'right' }} color="primary" type="button" onClick={() => editRiteAPI('api')}>API</Button>
                &nbsp;&nbsp;
                <Button style={{ float: 'right' }} color="primary" type="button" onClick={() => editRiteAPI('search')}>Search</Button>
              </CardHeader>
              {loading ? <Spinner /> : <ToolkitProvider
                ClassName="align-items-center table-flush" responsive
                data={state.dataTable}
                keyField="ritekiteAPISearchId"
                columns={[

                  
                  {
                    dataField: "domainName",
                    text: "Domain Name",
                    sort: true
                  },
                 
                  {
                    dataField: "searchResult",
                    text: "Search Result",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {rowContent.url}
                        </>
                      )
                    }
                  },
                  
                ]}
              >
                {props => (
                  <div className="py-2 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 mb-2"
                    >
                      <div style={{ width: '20%', display: 'inline-block' }}>
                        <SelectAutocomplete
                          id="search"
                          type="text"
                          label="search"
                          name="search"
                          options={searchState.dropdownList}
                          placeholder="search..."
                          disableCloseOnSelect
                          // defaultValue={searchState.search?searchState.search[0]:null}
                          control={control}
                          input='search'
                          value={searchState.search ? searchState.search[0] : null}
                          handleChangeSelect={handleChangeSelect}
                        />
                      </div>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      expandRow={false}
                      bootstrap4={true}
                      // pagination={pagination}
                      bordered={false}
                      hover={true}

                      rowClasses='pointer'
                    />

                    <TablePagination
                      component="div"
                      count={state.total}
                      page={page}
                      onChangePage={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </div>
                )}
              </ToolkitProvider>}
            </Card>  
            </div>
            </Row>
      </Container>      
      
      
    </div>
    </div>
  );
};
export default  RiteAPI;