import React, { useState, useEffect, useRef } from "react";
import { Card, Button, CardHeader, CardBody, Container, Row, Col, InputGroupAddon, InputGroupText, InputGroup } from "reactstrap";
import { useForm } from "react-hook-form";
import { FormInput, FormAutocomplete, FormRadio, FormAutocompleteGroupingRegion , FormAutocompleteGrouping} from "_components/FormElements/FormInput";
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import ImageLibrary from 'views/Hoc/Dialog/ImageLibrary';
import axios from 'axios';
import { history } from '_helpers/history';
import { ModalSpinner } from 'views/Hoc/Spinner';
import { Spinner } from 'views/Hoc/Spinner';
import { GeneralFormSchema } from '../profile/Settings/validation';
import SimpleHeader from "components/Headers/SimpleHeader";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { commonService } from '_services/common.service';
import { FormControl } from "@material-ui/core";

var _ = require('lodash');


// const PreferenceForm = (props) => {
//   const { closeDrawer, visible, title, contactId } = props;
//   return (
//     <div className="site-form-in-drawer-wrapper">
//       <Drawer title={title} onClose={closeDrawer} visible={visible}>
//         <Preference contactId={contactId} visible={visible} />
//       </Drawer>
//     </div>
//   );
// };

const UserprofileformOld = (props) => {
  const [loading, setLoading] = useState(false);
  const [showDrawer, setshowDrawer] = useState(false);
  const [checkMail, setCheckMail] = useState(0);
  let [userId, setUserId] = useState(null);
  const { register, handleSubmit, control, errors, watch, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(GeneralFormSchema),
  });
  const { changedData, closeDrawer, userData } = props;

  let typeId = parseInt(userData?.channelTypeId);

  // for scrolling to linkedin when clicking from channelDetail contact list
  let myRef = useRef();

  let linkedIn = props.location?.state?.linkedIn ? props.location?.state?.linkedIn : false;

  let contactId = userData.contact ? parseInt(userData.contact.oldContactId) : null;
  let data = useSelector(state => state.adduser);
  let [mail, setMail] = useState(null);
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    reportingTo: '',
    phone: '',
    mobileNo: '',
    empId: '',
    HSContact: '',
    userType: null,
    companyLogo: '',
    status: true,
    country: '',
    countryList: [],
    linkedinurl: '',
    twitterurl: '',
    isShared: '1',
    documentId: '',
    reportingList: [],
    sellingCountries: [],
    sellingCountriesOption: [],
    targetCountries: [],
    targetCountriesOption: [],
    productValue: [],
    productOptions: [],
    suggestions: '1',
    networkInvitation: '1',
  });

  const watchAll = watch(['firstName', 'lastName', 'mobile', 'phone', 'email', 'jobposition', 'TwitterURL', 'LinkedinURL']);

  const selectedItem = (gname, check, optionName, optionValue) => {
    let opt = [];
    console.log("check:", check, gname);
    if (check === true) {
      state[optionName].map((item) => {
        console.log("groupname:", item.groupname);
        if (item.groupname === gname || item.status === true) {
          item.status = check;
          opt.push(item);
        }
      });
    }
    if (check === false) {
      console.log("check:", check);
      state[optionName].map((item) => {
        if (item.groupname === gname && state[optionValue].length > 0)
          item.status = check;
      });
      opt = state[optionValue].filter((item) => item.groupname !== gname);
      opt.map(option => option.status = true);
    }
    state[optionValue] = opt;
    setState({ ...state, [optionValue]: state[optionValue] });
    setState({ ...state, [optionName]: state[optionName] });

    if (optionValue === 'sellingCountries' && state.sellingCountries.length === 0)
      errors.selling = true;
    else if (optionValue === 'targetCountries' && state.targetCountries.length === 0)
      errors.target = true;
    else if (optionValue === 'productValue' && state.productValue.length === 0)
      errors.productCat = true;
  }
  useEffect(() => {
    // console.log('props.location.pathname',props.location.pathname);
    // console.log('userData',userData);
    // contactId = props.location.pathname==="/admin/profileform"? parseInt(userData.contactId):props?.location?.state?parseInt(props.location?.state?.contactId):null;
    if (contactId) {
      (async () => {
        await setTradelocation();
        await setUserDetail();
        await setProductFamilies();
      })();
    } else {
      (async () => {
        await setUserDetail();
        await setTradelocation();
        await setProductFamilies();

      })();
      // history.push('/admin/dashboard');
    }
  }, []);

  // useEffect(() => {
  //   if (!_.isEmpty(errors) && formState.isSubmitted) {
  //     ToastsStore.error(JSON.stringify(errors));
  //     commonService.postService({ err: JSON.stringify(errors), reqUrl: window.location.pathname, service: 'validation_err' }, '/error')
  //   }
  // }, [errors])

  const setUserDetail = async () => {
    try {
      setLoading(true);
      await axios.get('common/getSignup/' + userData?.channelTypeId).then(res => {
        state.countryList = res.data.country;
      }).catch(err => {
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'common/getSignup' }, '/error');
      });

      if (contactId) {

        //   await axios.get('setting/reporting').then(res => {
        //   state.reportingList = res.data;
        // }).catch(err => {
        //   commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/reporting' }, '/error');
        // });

        await axios.post('channelAdmin/generalInfoOld', { contactId: contactId }).then(res => {
          console.log('response', res.data);
          userId = res.data.userId;
          setUserId(userId);
          let country = state.countryList.filter(x => parseInt(x.id) === parseInt(res.data.countryId));
          state.country = country[0];
          let report = state.reportingList.filter(x => parseInt(x.id) === parseInt(res.data.rptContactId));
          state.reportingTo = report[0];
          state.firstName = res.data.firstName;
          state.lastName = res.data.lastName;
          state.empId = res.data.empId;
          state.HSContact = res.data.hubspotContactId;
          state.email = res.data.email;
          mail = res.data.email
          setMail(mail);
          state.jobTitle = res.data.jobTitle;
          state.linkedinurl = res.data.sociallink ? res.data.sociallink[0]?.contactSocialLink : '';
          state.twitterurl = res.data.sociallink ? res.data.sociallink[1]?.contactSocialLink : '';
          state.phone = res.data.phone1;
          state.mobileNo = res.data.mobileNo; //.replace('-','');
          state.companyLogo = res.data.documentPath;
          state.contactId = contactId;
          state.documentId = res.data.documentId;
          state.isShared = res.data.isShared;
          state.userType = parseInt(res?.data?.roleId);
          state.status = parseInt(res?.data?.isActive) === 1 ? true : false;
          state.suggestions = res.data.preference.suggestion_comm ? res.data.preference.suggestion_comm : '1';
          state.networkInvitation = res.data.preference.invitation_comm ? res.data.preference.invitation_comm : '1';
        }).catch(err => {
          commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/generalInfo' }, '/error');
        });
      }
      setState({ ...state });
      // setLoading(false);
    }
    catch (err) {
      //ToastsStore.error('User Not Found!');
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setUserDetail' }, '/error');
    }
  }
  const setTradelocation = async () => {
    try {
      let target = []; let selling = [];
      setLoading(true);
      // await axios.get('setting/tradelocation').then(res => {
      //   target = res.data.targetLocation;
      //   selling = res.data.sellingLocation;
      //   selling.map(x => {
      //     x.status = false;
      //   })
      //   target.map(x => {
      //     x.status = false;
      //   })
      //   state.targetCountriesOption = res.data.targetLocation;
      //   state.sellingCountriesOption = res.data.sellingLocation;
      //   console.log('state *******', state);
      //   setState({ ...state });
      // }).catch(err => {
      //   commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/tradelocation' }, '/error');
      // });

      // selling.map(x => {
      //   x.status = false;
      // })
      // target.map(x => {
      //   x.status = false;
      // })

      await axios.post('channelAdmin/getOldInitialProfile/', userData).then(res => {

        state.sellingCountriesOption = res.data.regions1;
        selling = res.data.regions1;
        state.targetCountriesOption = res.data.regions;
        target = res.data.regions;
        state.productOptions = res.data.productGroup;

        setState({ ...state, targetCountriesOption: state.targetCountriesOption });
        setState({ ...state, sellingCountriesOption: state.sellingCountriesOption });

      }).catch(err => {
        setLoading(false);
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getUserProfile' }, '/error')
      })

      if (contactId) {
        await axios.post('channelAdmin/getOldContactLocation/', {
          user: { oldChannelId: userData.oldChannelId, oldContactId: contactId, channelTypeId: userData.channelTypeId }
        }).then(res => {
          target = target.map(x => {
            res.data.targetArray.map(y => {
              if (x.groupid == y.groupid && x.childId == y.childId)
                x.status = true;
            })
            return x;
          });

          selling = selling.map(x => {
            res.data.sellingArray.map(y => {
              if (x.groupid == y.groupid && x.childId == y.childId)
                x.status = true;
            })
            return x;
          });
          state.targetCountriesOption = target;
          state.targetCountries = res.data.targetArray.length === 0 ? [] : res.data.targetArray;
          state.sellingCountries = res.data.sellingArray.length === 0 ? [] : res.data.sellingArray;
          state.sellingCountriesOption = selling
          setState({ ...state });
        }).catch(err => {
          commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getContactLocation' }, '/error');
        });
      }
    }
    catch (err) {
      //ToastsStore.error('User Location Not Found!');
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setTradelocation' }, '/error');
    }





  }

  const setProductFamilies = () => {
    try {
      (async () => {
        // const apiUrl = '/setting/getInitialProfile';
        // await axios.get(apiUrl).then(res => {
        //   state.productOptions = res.data.productGroup;
        // }).catch(err => {
        //   commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getInitialProfile' }, '/error');
        // });

        if (contactId) {
          await axios.get('/channelAdmin/getOldContactProduct/' + contactId + '/' + userData.oldChannelId).then(res => {
            console.log('contatc', res.data);
            state.productValue = res.data.productGroup;

          }).catch(err => {
            commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getContactProduct' }, '/error');
          });
          state.productOptions = state.productOptions.map(x => {
            state.productValue.map(y => {
              if (x.groupid == y.groupid && x.childid == y.childid)
                x.status = true;
            })
            return x;
          })
          setState({ ...state });
        }
        setLoading(false);

        if (linkedIn && myRef) {
          myRef.current.scrollIntoView({ behavior: 'smooth' });
        }

      })();
    }
    catch (err) {
      //ToastsStore.error('User Product Not Found!');
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setProductFamilies' }, '/error');
    }
  }


  const getCompanyLogo = (resImagePath) => {

    setState({ ...state, companyLogo: resImagePath.document.documentUrl, documentId: resImagePath.documentId });

  };



  const handleChange = input => e => {
    if (input === 'status') {
      setState({ ...state, [input]: e.target.checked });
    } else if (input === 'userType') {
      setState({ ...state, [input]: parseInt(e.target.value) });
    } else
      setState({ ...state, [input]: e.target.value });
  }

  //console.log('watchAll', state, watchAll);

  const handleChangeSelect = (input, value) => {
    console.log(input, value)
    if (input === 'product') {
      state.productOptions.map((item) => {
        if (value.length === 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   if(v.childId!==item.childId)
          //     item.status=false;                 
          //    if(v.childname===item.childname)            
          //     item.status=true;                       

          // });      

          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;

        }
      });

      value.map(option => option.status = true);
      state.productValue = value
      setState({ ...state });

      if (state.productValue.length === 0)
        errors.productCat = true;
    } else if (input === 'sellingCountries') {
      state.sellingCountriesOption.map((item) => {
        if (value.length === 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   if(v.childname!==item.childname)
          //     item.status=false;                 
          //   else if(v.childname===item.childname)            
          //     item.status=true;                       

          // });      
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.sellingCountries = value
      setState({ ...state });

      if (state.sellingCountries.length === 0)
        errors.selling = true;
    } else if (input === 'targetCountries') {
      state.targetCountriesOption.map((item) => {
        if (value.length === 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   if(v.childname!==item.childname)
          //     item.status=false;                 
          //   else if(v.childname===item.childname)            
          //     item.status=true;                       

          // });      
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.targetCountries = value
      setState({ ...state });

      if (state.targetCountries.length === 0)
        errors.target = true;
    }
    // else if(input==='product'){
    //   state.productOptions.map((item) => {      
    //     if(value.length===0)
    //       item.status=false;
    //     else
    //     {
    //       value.map(v => {          
    //         if(v.childname!==item.childname)
    //           item.status=false;                 
    //         else if(v.childname===item.childname)            
    //           item.status=true;                       

    //       });      
    //     }
    //   });

    //   value.map(option => option.status=true);
    //   state.productValue= value
    //   setState({...state});

    //   // state.productValue= value
    //   // setState({...state, productValue:state.productValue});
    //   if(state.productValue.length===0)
    //     errors.productValue=true;

    //   let Group=_.uniq(state.productValue.map(x => x.groupid));
    //   state.groupCount=Group.length;
    //   setState({...state});     



    // }
    else if (input === 'country' || input === 'reportingTo') {
      state[input] = value;
      setState({ ...state });
    } else {
      state[input] = [value];
      setState({ ...state });
    }
  }


  const save = async (e) => {
    // e.preventDefault();
    try {
      setLoading(true);

      let olduser = props.userData;

      if (contactId)
        olduser = Object.assign(props.userData, { oldContactId: contactId, oldUserId: userId });



      let userData = state;
      // if (userData.email.trim() !== mail.trim()) {
      //   let testMail = await commonService.postService({ userId: userId }, 'user/checkusermail/' + userData.email);
      //   if (testMail?.data) {
      //     if (!testMail.data.isavialble) {
      //       setCheckMail(1);
      //       setLoading(false);
      //       return false;
      //     } else { setCheckMail(0); }
      //   } else
      //     setCheckMail(1);
      // } else
      //   setCheckMail(0);

      let trade = state.productValue;
      // let loc = data.trade_loc;
      let sellingLocation = []; let targetLocation = [];

      if (typeId === 4 || typeId === 3 || typeId == 5)
        sellingLocation = state.sellingCountries.map(x => +x.regionCountryJCTId);
      if (typeId === 2 || typeId === 3)
        targetLocation = state.targetCountries.map(x => +x.regionCountryJCTId);


      let loc = {
        sellingLocation: sellingLocation,
        targetLocation: targetLocation
      }
      let contact = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        documentId: parseInt(userData.documentId),
        countryId: parseInt(userData.country.id),
        isActive: userData.status ? '1' : '0',
        isShared: userData.isShared,
        jobTitle: userData.jobTitle,
        linkedInUrl: userData.linkedinurl,
        googlePlusUrl: userData.twitterurl,
        phoneNo: userData?.phone,
        mobileNo: userData.mobileNo,
        role: parseInt(userData.userType),
        // rptContactId: userData.userType != 1 ? parseInt(userData.reportingTo?.id) : null,
        workNatureId: 1,
        suggestion_comm: +state.suggestions,
        invitation_comm: +state.networkInvitation
      };
      let locations = [];

      let productCategories = [];
      //console.log('productValue',trade);
      trade.map(x => {
        let obj = {
          productFamilId: x.groupid,
          productCategoriesId: x.childid
        }
        productCategories.push(obj);
      });

      // let interestedProductCategories = [].push(trade.intProductValue.map(x => ({
      //   productFamilId: x.groupid,
      //   productCategoriesId: x.childid,
      // })));

      let productFamilies = _.uniq(trade.map(x => +x.groupid));
      productFamilies = productFamilies.map(x => ({ id: x }));

      // let interestedProductFamilies = _.uniq(trade.intProductValue.map(x => +x.groupid));
      // interestedProductFamilies = interestedProductFamilies.map(x => ({id: x}));

      if (typeId === 2 || typeId === 3)
        locations.push({
          locationType: 'TARGET',
          isUpdate: true,
          location: loc.targetLocation,
        });
      if (typeId === 3)
        locations.push({
          locationType: 'SELLING',
          isUpdate: true,
          location: loc.sellingLocation,
        });
      if (typeId === 4)
        locations.push({
          locationType: 'RETAIL',
          isUpdate: true,
          location: loc.sellingLocation,
        });
      if (typeId === 5)
        locations.push({
          locationType: 'RESELLING',
          isUpdate: true,
          location: loc.sellingLocation,
        });

      console.log("data", data);
      let checkedKeys = [];
      let checkedKeys1 = [];
      let accessPermissions = [];
      if (data?.preference?.checkedKeys)
        checkedKeys = data?.preference?.checkedKeys
      if (data?.preference?.checkedKeys1)
        checkedKeys1 = data?.preference?.checkedKeys1
      if (checkedKeys.length > 0 || checkedKeys1.length > 0) {
        accessPermissions = checkedKeys.map(x => ({ id: +x }));
        accessPermissions = accessPermissions.concat(checkedKeys1.map(x => ({ id: +x })));
      }
      let sendData = {
        channelTypeId: +typeId,
        contact: contact,
        locations: locations,
        productCategories: productCategories,
        productFamilies: productFamilies,
        user: olduser
        // accessPermissions: accessPermissions,
        // ratingId: props.ratingId ? props.ratingId : null
      }
      // if (sendData.accessPermissions.length === 0)
      //   delete sendData.accessPermissions;

      // if(interestedProductFamilies.length)
      //   sendData = Object.assign(sendData, {interestedProductCategories: interestedProductCategories, interestedProductFamilies: interestedProductFamilies});
      //console.log('savingdata', sendData);    
      let apipath = '';
      if (contactId) {
        apipath = 'channelAdmin/updateOldUser/' + contactId;
      } else {
        apipath = 'channelAdmin/addOldUser';
      }
      await axios.post(apipath, sendData).then(async res => {

        ToastsStore.success('Saved Successfully!');

        // await setTradelocation();
        // await setUserDetail();
        // await setProductFamilies();

        changedData(Object.assign(contact, {
          companyLogo: state.companyLogo,
          oldContactId: contactId
        }), 4);

        setLoading(false);
        closeDrawer();
        //history.push('admin/profileform');
      }).catch(err => {
        ToastsStore.error('Something Went Wrong!');
        setLoading(false);
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'UserEdit' }, '/error');
      });
    }
    catch (err) {
      console.log("err:", err);
      ToastsStore.error('Something Went Wrong!');
      setLoading(false);
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'UserEdit' }, '/error');
    }

  }


  return (
    <>
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      {/* <SimpleHeader location={props.match} /> */}
      <div className="main-content">
        {loading ? <Spinner /> : (
          <Container className="col-sm-12" fluid>
            <form noValidate onSubmit={handleSubmit(save)}>
              {loading && <div style={{ top: '50%', left: '50%', zIndex: '1000', }}><ModalSpinner /></div>}
              <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="typeId" ref={register} name="typeId" value={typeId} control={control} />
              <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="roleId" ref={register} name="roleId" value={state.userType} control={control} />
              <Card>
                <CardHeader>
                  <h3 className="text-center mt-4">User Profile</h3>
                </CardHeader>
                <CardBody>
                  <Row>

                    <Col lg="6">
                      <h3>Your Picture {!errors.companyLogo || state.companyLogo ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select Profile Image</span>}</h3>
                      <Row>
                        <Col lg="5" style={{ padding: "0px" }}>
                          <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="companyLogo" name="companyLogo" ref={register} value={state.companyLogo} />
                          <div>
                            <ImageLibrary userLogo={true} getDocumentId={getCompanyLogo} aws_folderName='account-s3' id={2} size={1}
                              img={state.companyLogo ? state.companyLogo : "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"} channelData={userData} />
                          </div>
                        </Col>
                        <Col style={{ paddingTop: "2.5rem" }} lg="7">
                          <span>Accepts Jpeg / Jpg / Png Only</span>
                          <br></br>
                          <span>(Max size allowed 200 Kb)</span>
                        </Col>
                      </Row>
                    </Col>
                    {/* <Col lg="6">
                      <div className="form-group text-sm mb-0">
                        <h3>User Type</h3>
                        {state.userType !== 1 && <FormRadio
                          name="User type"
                          className="text-sm mb-0"

                          options={radioGroupOptions1}
                          control={control}
                          row
                          defaultValue={state.userType}
                          value={state.userType}
                          onChange={handleChange('userType')}
                        />}
                      </div>
                      {state.userType === 1 && <span> Acccount Owner</span>}
                      {state.userType !== 1 && <Row>
                        <Col lg="2" style={{ padding: "0px" }}><h3>Status</h3></Col>
                        <Col lg="10">
                          <div className="form-group">
                            <label className="custom-toggle custom-toggle-success">
                              <input defaultChecked checked={state.status} type="checkbox" ref={register} name="status" onChange={handleChange('status')} />
                              <span className="custom-toggle-slider rounded-circle" data-label-off="Inactive" data-label-on="Active"></span>
                            </label>
                          </div>
                        </Col>
                      </Row>}
                    </Col> */}
                  </Row>

                  {/* { addOrEdit==='EDIT' && <div className="row">
                    <div className="col-lg-6">
                        <FormInput 
                           id="empid"
                           type="text"
                           name="empid"
                           label="Employee ID *"
                           onChange={handleChange('empid')}
                           defaultValue={state.empId}
                           value={watchAll.empid}
                           disabled={true}/>
                    </div>
                    <div className="col-lg-6">
                        <FormInput 
                           id="hscontact"
                           type="text"
                           name="hscontact"
                           label="HS Contact ID *"
                           onChange={handleChange('hscontact')}
                           defaultValue={state.HSContact}
                           value={watchAll.hscontact}
                           disabled={true}
                      />
                    </div>
  </div>} */}

                  <Row>
                    <div className="col-lg-6 mt-4">
                      <div className="form-group">
                        <FormInput
                          id="firstName"
                          type="text"
                          name="firstName"
                          label="First Name *"
                          register={register}
                          defaultValue={state.firstName}
                          error={errors.firstName}
                          value={watchAll.firstName}
                          onChange={handleChange('firstName')}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                      <div className="form-group">
                        <FormInput
                          id="lastName"
                          type="text"
                          name="lastName"
                          label="Last Name *"
                          //placeholder="Password"
                          register={register}
                          error={errors.lastName}
                          value={watchAll.lastName}
                          defaultValue={state.lastName}
                          onChange={handleChange('lastName')}
                        />
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <FormInput
                          id="email"
                          type="text"
                          label="Contact Email *"
                          name="email"
                          control={control}
                          register={register}
                          error={errors.email ? errors.email : checkMail ? { message: "Email Id already taken", required: true } : ''}
                          defaultValue={state.email}
                          value={watchAll.email}
                          onChange={handleChange('email')}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <FormControl variant="outlined" style={{ width: "100%" }}>
                          <FormAutocomplete
                            id="country"
                            name="country"
                            label="Country *"
                            options={state.countryList}
                            placeholder="Select Country"
                            // multiple
                            disableCloseOnSelect
                            blurOnSelect
                            limitTags={1}
                            control={control}
                            input='country'
                            value={state.country}
                            handleChangeSelect={handleChangeSelect}
                            errMessage={'Please Select Country'}
                            error={errors.country}
                            defaultValue={state.country}
                          />
                        </FormControl >
                      </div>
                    </div>
                  </Row>

                  <Row>
                    <div className="col-lg-6 code">
                      <div className="form-group">

                        <label className="form-control-label" htmlFor="">
                          {/* <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="phone" ref={register} name="phone"/> */}
                        </label>
                        {/* <InputGroup  className="reg reg_12">
  <InputGroupAddon addonType="prepend">
    <InputGroupText></InputGroupText>
  </InputGroupAddon> */}
                        <FormInput
                          className="comp-name12"
                          id="phone"
                          type="text"
                          label="Contact Phone Number"
                          name="phone"
                          register={register}
                          error={errors.phone}
                          defaultValue={state.phone}
                          value={watchAll.phone}
                          onChange={handleChange('phone')}
                        />
                        {/* </InputGroup> */}
                      </div>
                    </div>

                    {/* To scroll when editing linkedin url by clicking icon in channelDetail */}
                    <div style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} ref={myRef} />

                    <div className="col-lg-6 code">
                      <div className="form-group">

                        <label className="form-control-label" htmlFor="">
                          {/* <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="mobile" ref={register} name="mobile"/> */}
                        </label>
                        {/* <InputGroup  className="reg reg_12">
  <InputGroupAddon addonType="prepend">
    <InputGroupText></InputGroupText>
  </InputGroupAddon> */}
                        <FormInput
                          id="mobile"
                          className="comp-name12"
                          type="text"
                          label="Mobile Number *"
                          name="mobile"
                          register={register}
                          error={errors.mobile}
                          defaultValue={state.mobileNo}
                          value={watchAll.mobile}
                          onChange={handleChange('mobileNo')}
                        />
                        {/* </InputGroup> */}
                      </div>
                      {/* <div className="form-group">
          <FormInput
              id="mobile" 
              type="text" 
              label="Mobile Number *" 
              name="mobile" 
              register={register}
              error={errors.mobile}
              defaultValue={state.mobileNo}
              value={watchAll.mobile}
              onChange={handleChange('mobileNo')}
           />
          </div> */}
                    </div>
                  </Row>


                  <Row>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <FormInput
                          id="jobposition"
                          type="text"
                          label="Job Position *"
                          name="jobposition"
                          control={control}
                          register={register}
                          error={errors.jobposition}
                          defaultValue={state.jobTitle}
                          value={watchAll.jobposition}
                          onChange={handleChange('jobTitle')}
                        />
                      </div>
                    </div>

                    {state.userType != '1' && <div className="col-lg-6">
                      <div className="form-group">
                        <FormAutocomplete
                          id="reportingTo"
                          type="text"
                          label="Reporting To"
                          name="reportingTo"
                          options={state.reportingList}
                          disableCloseOnSelect
                          blurOnSelect
                          limitTags={1}
                          control={control}
                          input='reportingTo'
                          value={state.reportingTo}
                          handleChangeSelect={handleChangeSelect}
                          errMessage={'Please Select Reporting Id'}
                          error={errors.reportingTo}
                          defaultValue={state.reportingTo}
                        />

                      </div>
                    </div>}


                    <div className="col-lg-6">
                      <div className="form-group">
                        <FormInput
                          id="LinkedinURL"
                          type="text"
                          label="Linkedin URL"
                          name="LinkedinURL"
                          register={register}
                          error={errors.LinkedinURL}
                          defaultValue={state.linkedinurl}
                          value={watchAll.LinkedinURL}
                          onChange={handleChange('linkedinurl')}
                          autoFocus={linkedIn}
                        />
                      </div>
                    </div>


                    <div className="col-lg-6">
                      <div className="form-group">
                        <FormInput
                          id="TwitterURL"
                          type="text"
                          label="Twitter URL"
                          name="TwitterURL"
                          register={register}
                          error={errors.TwitterURL}
                          defaultValue={state.twitterurl}
                          value={watchAll.TwitterURL}
                          onChange={handleChange('twitterurl')}
                        />
                      </div>
                    </div>
                  </Row>


                  <Col lg="6">
                    <h3 style={{ textAlign: 'left' }}> Visibility</h3>
                    <div className="form-group">
                      <FormRadio
                        name="Visibility"
                        className="text-sm mb-0"

                        options={radioGroupOptions3}
                        control={control}
                        row

                        value={state.isShared}
                        onChange={handleChange('isShared')}
                      />
                    </div>
                  </Col>
                </CardBody>
              </Card>
              <Card>


                <CardHeader>
                  <h3 className="text-center">Product Interest</h3>
                  <p className="text-center">{userData.channelTypeId==2? 'Select the products families that you offer' : 'Select the product categories that you are interested in or in charge of'}</p>
                </CardHeader>
                <CardBody>
                  <div className="col-lg-12 ">
                    <div className="form-group">
                      <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="productCat" name="productCat" ref={register} value={state.productValue.length ? state.productValue[0].childname : ''} />
                      <FormAutocompleteGrouping
                        id="ProductResponsibilities"
                        name="ProductResponsibilities"
                        label="Product Interest *"
                        Options={state.productOptions}
                        optionName='productOptions'
                        optionValue='productValue'
                        placeholder="Product Interest"
                        errMessage='Please Select Product'
                        error={!errors.productCat || state.productValue.length ? false : true}
                        defaultValue={state.productValue}
                        value={state.productValue}
                        input="product"
                        selectedItem={selectedItem}
                        handleChangeSelect={handleChangeSelect}
                        control={control}
                        register={register}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card>

                <CardHeader>
                  <h3 className="text-center" >Trade Locations</h3>
                  <p className="text-center" >Update the list of countries you are in charge of or you are targetting.</p>
                </CardHeader>
                <CardBody>
                  {(typeId === 3 || typeId === 4 || typeId === 5) && (
                    <div className="col-lg-12 ">
                      <div className="form-group">
                        <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="selling" name="selling" ref={register} value={state.sellingCountries.length ? state.sellingCountries[0].childname : ''} />
                        <FormAutocompleteGroupingRegion
                          input="sellingCountries"
                          id="SellingCountries"
                          label='Selling Locations *'
                          name="SellingCountries"
                          Options={state.sellingCountriesOption}
                          placeholder="Search"
                          errMessage='Please choose Selling Locations'
                          error={!errors.selling || state.sellingCountries.length ? false : true}
                          optionName='sellingCountriesOption'
                          optionValue='sellingCountries'
                          defaultValue={state.sellingCountries}
                          value={state.sellingCountries}
                          // loading={true}
                          isGroup='0'
                          selectedItem={selectedItem}
                          handleChangeSelect={handleChangeSelect}
                          control={control}
                          register={register}
                        />
                      </div>
                    </div>
                  )}

                  {(typeId === 3 || typeId === 2) && (

                    <div className="col-lg-12 ">
                      <div className="form-group">
                        <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="target" name="target" ref={register} value={state.targetCountries.length ? state.targetCountries[0].childname : ''} />
                        <FormAutocompleteGroupingRegion
                          input="targetCountries"
                          id="TargetCountries"
                          label="Target Locations *"
                          name="TargetCountries"
                          Options={state.targetCountriesOption}
                          placeholder="Search"
                          errMessage={'Please choose Target Locations'}
                          error={!errors.target || state.targetCountries.length ? false : true}
                          optionName='targetCountriesOption'
                          optionValue='targetCountries'
                          defaultValue={state.targetCountries}
                          value={state.targetCountries}
                          // loading={true}
                          selectedItem={selectedItem}
                          handleChangeSelect={handleChangeSelect}
                          control={control}
                          register={register}
                          isGroup='0'
                        />
                      </div>
                    </div>
                  )}
                  {/* {contactId && <div onClick={() => { setshowDrawer(true) }} style={{ cursor: "pointer" }}>User Preference</div>}
                  <PreferenceForm title="User Preference" visible={showDrawer} closeDrawer={closeDrawer} contactId={contactId} /> */}
                </CardBody>
              </Card>


              <Card>
                <CardHeader>
                  <h3 className="text-center">Communication Preference</h3>
                </CardHeader>
                <CardBody>
                  <div className="row ">
                    1. How frequently do you wish to receive suggestions?
                    <div className="col-sm-12 mt-4">
                      <div className="form-group ">
                        <FormRadio
                          name="CommunicationPreference1"
                          className="text-sm mb-0"
                          //label="How frequently do you wish to receive suggestions?"
                          options={radioGroupOptions}
                          control={control}
                          register={register}
                          row
                          onChange={handleChange('suggestions')}
                          value={state.suggestions}
                          defaultValue={state.suggestions}
                          error={errors.CommunicationPreference1}
                        />
                      </div>
                    </div>
                  </div>


                  {typeId == '2' && <div className="row">
                    2.	How do you want to manage your network invitation coming from vendors and distributors?
                    <div className="col-sm-12 mt-4">
                      <div className="">
                        <FormRadio
                          name="CommunicationPreference2"
                          className=""
                          //label="2.	How do you want to manage your network invitation coming from vendors and distributors? "
                          options={radioGroupOptions}
                          register={register}
                          control={control}
                          row
                          onChange={handleChange('networkInvitation')}
                          value={state.networkInvitation}
                          defaultValue={state.networkInvitation}
                          error={errors.CommunicationPreference2}
                        />
                      </div>
                    </div>
                  </div>}


                  <div className="col-lg-12 text-center m-4">
                    <Button color="primary" type="submit">
                      Save
                    </Button>
                    {/* <Button color="primary" type="button" href="#">
                   Cancel
                  </Button> */}
                  </div>
                </CardBody>
              </Card>

            </form>
          </Container>
        )}
      </div>
    </>
  );
};

const radioGroupOptions = [
  { value: "0", label: "As they come!" },
  { value: "1", label: "One a  week" },
  { value: "2", label: "Once per 2 weeks " },
  { value: "3", label: "Once per month" },
  { value: "4", label: "Never" }
];
const radioGroupOptions3 = [
  { value: "1", label: "Shared" },
  { value: "0", label: "Confidential" }
];
const radioGroupOptions1 = [
  { value: 3, label: "Primary" },
  { value: 2, label: "Admin " }
];

// function mapState(state) {
//     const { general_data } = state.adduser;
//     return { general_data };
// }

// const actionCreators = {
//   adduser: adduserActions.adduser
// };

// export default connect(mapState,actionCreators)();
export { UserprofileformOld }