import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Container, Col, Row } from "reactstrap";
import { Tree } from 'antd';
import axios from 'axios';
import { Spinner } from 'views/Hoc/Spinner';
import { history } from '_helpers/history';
import { connect, useSelector } from 'react-redux';
import { adduserActions } from "_actions/adduser.actions";
var _ = require('lodash');

const Preference = (props) => {
  const [loading, setLoading] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [expandedKeys1, setExpandedKeys1] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [checkedKeys1, setCheckedKeys1] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedKeys1, setSelectedKeys1] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [autoExpandParent1, setAutoExpandParent1] = useState(true);

  let data = useSelector(state => state.adduser);
  let chuser = props.userData//JSON.parse(localStorage.getItem('CHuser'));
  let typeId = chuser?.channelTypeId;
  const [state, setState] = useState({
    leftData: [],
    rightData: []
  });


  const onExpand = (expandedKeys) => {
    console.log('onExpand', expandedKeys); // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.

    setExpandedKeys(expandedKeys);
    setAutoExpandParent(true);
  };

  const onExpand1 = (expandedKeys) => {
    console.log('onExpand', expandedKeys); // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.

    setExpandedKeys1(expandedKeys);
    setAutoExpandParent1(true);
  };

  const onCheck = (checkedKeys) => {
    console.log('onCheck', checkedKeys);
    setCheckedKeys(checkedKeys);
    setPreferenceRedux();
  };
  const onCheck1 = (checkedKeys) => {
    console.log('onCheck', checkedKeys);
    setCheckedKeys1(checkedKeys);
    setPreferenceRedux();
  };

  const onSelect = (selectedKeys, info) => {
    console.log('onSelect', info);
    setSelectedKeys(selectedKeys);
    setPreferenceRedux();
  };

  const onSelect1 = (selectedKeys, info) => {
    console.log('onSelect', info);
    setSelectedKeys1(selectedKeys);
    setPreferenceRedux();
  };

  const setPreferenceRedux = () => {
    props.adduser({ checkedKeys: checkedKeys, checkedKeys1: checkedKeys1 }, 4);
  }
  useEffect(() => {

    let left = []; let right = [];
    setLoading(true);

    (async () => {

      setLoading(true);
      await axios.get('channelAdmin/preference/' + chuser?.channelTypeId).then(res => {
        setState({ ...state, leftData: res.data.left, rightData: res.data.right });

        res.data.left.map(x => {

          left.push(x.key)
          if (x.children?.length) {
            x.children.map(y => {
              if (y.key)
                left.push(y.key);
            })
          }

        });

        res.data.right.map(x => {

          right.push(x.key)
          if (x.children?.length) {
            x.children.map(y => {
              if (y.key)
                right.push(y.key);
            })
          }

        });

        console.log('left side', left, 'right side', right);

        if (!props.contactId) {
          setCheckedKeys(res.data.left.map(x => x.key));
          setSelectedKeys(res.data.left.map(x => x.key));
          setCheckedKeys1(res.data.right.map(x => x.key));
          setSelectedKeys1(res.data.right.map(x => x.key));
        }
      })
      if (props.contactId) {
        await axios.get('channelAdmin/userpreference/' + props.contactId + '/' + props.channelId).then(res => {
          if (res.data.length > 0) {
            let data = [];
            res.data.map(x => {
              if (x.accesspermissionid == '50' || x.accesspermissionid == '2' || x.accesspermissionid == '12') {
                if (x.allowEdit == '1')
                  data.push(x.accesspermissionid + '111');
                if (x.allowView == '1')
                  data.push(x.accesspermissionid + '222');
              }
              else
                data.push(x.accesspermissionid);
            })


            setCheckedKeys(left.filter(x => data.includes(x)));
            setSelectedKeys(left.filter(x => data.includes(x)));
            setCheckedKeys1(right.filter(x => data.includes(x)));
            setSelectedKeys1(right.filter(x => data.includes(x)));
            setPreferenceRedux();
          }
          //setState({...state, checkedKeys:res.data,selectedKeys:res.data});
        })
      }
      setLoading(false);

    })();

  }, []);

  useEffect(() => {
    setPreferenceRedux();
  }, [!props.visible, checkedKeys, checkedKeys1]);

  const save = async (e) => {
    e.preventDefault();
    setLoading(true);
    let userData = data.general_data;
    let trade = data.trade_info;
    let loc = data.trade_loc;

    let contact = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      documentId: userData.documentId ? +userData.documentId : null,
      countryId: +userData.country.id,
      googlePlusUrl: userData.TwitterURL,
      isActive: userData.status ? '1' : '0',
      isShared: userData.isShared,
      jobTitle: userData.jobTitle,
      linkedInUrl: userData.LinkedinURL,
      mobileNo: userData.mobileNo[1], //.split(' ')[0]+'-'+userData.mobileNo[2],
      phoneNo: userData.phone[2].length >= 4 ? userData.phone[1] : null,//userData.phone[1].split(' ')[0]+'-'+userData.phone[2] : null,
      role: +userData.userType,
      rptContactId: +userData.reportingTo.id,
      workNatureId: 1
    };

    let locations = [];

    let productCategories = [];
    trade.productValue.map(x => {
      let obj = {
        productFamilId: x.groupid,
        productCategoriesId: x.childid
      }
      productCategories.push(obj);
    });

    // let interestedProductCategories = [].push(trade.intProductValue.map(x => ({
    //   productFamilId: x.groupid,
    //   productCategoriesId: x.childid,
    // })));

    let productFamilies = _.uniq(trade.productValue.map(x => +x.groupid));
    productFamilies = productFamilies.map(x => ({ id: x }));
    let productFamily = [...new Set(trade.productValue.map(x => x.groupname))];
    let productCat = trade.productValue.map(x => x.childname);
    // let interestedProductFamilies = _.uniq(trade.intProductValue.map(x => +x.groupid));
    // interestedProductFamilies = interestedProductFamilies.map(x => ({id: x}));

    if (typeId == 2)
      locations.push({
        locationType: 'TARGET',
        isUpdate: true,
        location: loc.targetLocation,
      });
    // if (typeId == 3)
    //   locations.push({
    //     locationType: 'SELLING',
    //     isUpdate: true,
    //     location: loc.sellingLocation,
    //   });
    // if (typeId == 4)
    //   locations.push({
    //     locationType: 'RETAIL',
    //     isUpdate: true,
    //     location: loc.sellingLocation,
    //   });
    // if (typeId == 5) {
    //   locations.push({
    //     locationType: 'RESELLING',
    //     isUpdate: true,
    //     location: loc.sellingLocation,
    //   })
    // }

    let accessPermissions = checkedKeys.map(x => ({ id: +x }));
    accessPermissions = accessPermissions.concat(checkedKeys1.map(x => ({ id: +x })));
    let sendData = {
      channelTypeId: +typeId,
      contact: contact,
      locations: locations,
      productCategories: productCategories,
      productFamilies: productFamilies,
      accessPermissions: accessPermissions,
      user: {
        userId: chuser.userId,
        channelId: chuser.channelId
      },
      productFamily: productFamily,
      productCat: productCat
    }

    // if(interestedProductFamilies.length)
    //   sendData = Object.assign(sendData, {interestedProductCategories: interestedProductCategories, interestedProductFamilies: interestedProductFamilies});

    console.log('save', data);
    console.log('savingdata', sendData);

    if (props.contactId) {
      await axios.put('user/profile/' + props.contactId, sendData).then(res => {
        setLoading(false);
        axios.get('algorithm/channel/' + props.contactId);
        if (typeId != 2)
          axios.get('algorithm/product/' + props.contactId);
        //console.log('response', res.data);
        history.push('/admin/settings');
      }).catch(err => console.log('error', err));
    }
    else {
      await axios.post('channelAdmin/add', sendData).then(res => {
        setLoading(false);
        // Mail Code
        let maildata = {
          to: res.data.created.email,
          name: res.data.created.contact.firstName,// + " " + res.data.created.contact.lastName,
          profileurl: process.env.REACT_APP_LOCALSITE,
          userId: res.data.created.email,
          password: res.data.created.password,
          accountOwner: +res.data.created.contact.rptContactId,
        }
        axios.post('mailerservice/Add_User_Mail', maildata);

        history.push({ pathname: '/admin/CompanyListing/companysettings/userManage', state: { channelData: chuser } });
      }).catch(err => console.log('useradderror', err));

    }



  }

  return (
    <div className="main-content">
      {loading ? <Spinner /> : (
        <Container className="mt-4 col-lg-12 col-md-9 col-sm-12  " fluid>
          <form noValidate >
            <Card>
              <CardHeader>
                <h3 className="text-center">User Account Credentials</h3>
                <p className="text-center">Select the modules that the user will be able to access.Tick the checkbox to give privileges</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="6">
                    <Tree
                      checkable
                      onExpand={onExpand}
                      expandedKeys={expandedKeys}
                      autoExpandParent={autoExpandParent}
                      onCheck={onCheck}
                      checkedKeys={checkedKeys}
                      onSelect={onSelect}
                      selectedKeys={selectedKeys}
                      treeData={state.leftData}
                    />
                  </Col>
                  <Col lg="6">
                    <Tree
                      checkable
                      onExpand={onExpand1}
                      expandedKeys={expandedKeys1}
                      autoExpandParent={autoExpandParent1}
                      onCheck={onCheck1}
                      checkedKeys={checkedKeys1}
                      onSelect={onSelect1}
                      selectedKeys={selectedKeys1}
                      treeData={state.rightData}
                    />
                  </Col>
                </Row>
                <div className="text-center mt-4">
                  {/* {!props.contactId && <> <button onClick={e => { e.preventDefault(); props.toggleNavs("4") }} className="btn btn-primary" >Back</button>
                    <button type="submit" onClick={save} className="btn btn-primary" >Save </button> </>} */}
                </div>
              </CardBody>
            </Card>
          </form>
        </Container>
      )}
    </div>
  );
};

// export default Preference;

function mapState(state) {
  const { general_data } = state.adduser;
  return { general_data };
}

const actionCreators = {
  adduser: adduserActions.adduser
};

export default connect(mapState, actionCreators)(Preference);