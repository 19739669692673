import React, { useState, useEffect } from 'react';
import { commonService } from '_services/common.service';
import TablePagination from '@material-ui/core/TablePagination';
import {TablePaginationActions} from 'views/Hoc/PaginationActions';
import {Spinner} from '../../Hoc/Spinner';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Card, Container, CardHeader,UncontrolledTooltip } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Drawer from 'views/Hoc/Dialog/Globaldrawer'
export const Subscription=()=>{
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [loading, setLoading]  = useState(true);
    const [subscriptionId,setSubscriptionId]=useState();
    const [showDrawer,setshowDrawer]=useState(false);
    let [total, setTotal]  = useState(0);
    let [data, setData]  = useState([]);
    useEffect(() => {
        getPlans();
    }, []);
    
    const getPlans= async()=>{
      setLoading(true);
      let res=  await commonService.getServiceWithParams({
                offset:page * rowsPerPage,
                page: page * rowsPerPage,
                limit: rowsPerPage
                },'/channel/subscription/getPlansforAdmin');
      if(res){
        data=res.data.result.rows;
        setData(data);
        total=parseInt(res.data.result.count);
        setTotal(total);
      } else {
        data=[];
        setData(data);
        total=0;
        setTotal(total);
      }
      setLoading(false);
    }
      const handleChangePage = (event, newPage) => {
        page=newPage;
        setPage(page);
        getPlans();
      };
    
      const handleChangeRowsPerPage = (event) => {
        rowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(rowsPerPage);
        setPage(0);
        getPlans();
      };

      const closeDrawer =()=>{
        setshowDrawer(false);
      }

    const openBlock = (id) => {
      setSubscriptionId(id)
    }
    

      return (
        <div id="usermanage">
           <Drawer title="Subscription" visible={showDrawer} subscriptionId={subscriptionId} closeDrawer={closeDrawer}/>
          <SimpleHeader name="Subsription Plans" parentName="Master" />
            <Container className="mt--3" fluid>
                  <Card>
                    <CardHeader>
                      <h3 className="mb-0">Listing</h3>
                     </CardHeader>
                     {loading? <Spinner/>:<ToolkitProvider
                      data={data}
                      keyField="fName"
                      columns={[
                        {
                            dataField: "subscriptionPeriod.periodDesc",
                            text: " Period",
                            sort: true
                          },
                          {
                            dataField: "subscriptionPlan.subscriptionPlanType",
                            text: " Plan Name",
                            sort: true
                          },
                          {
                            dataField: "isActive",
                            text: "Status",
                            sort: true,
                            formatter: (rowContent, row) => {
                              return(
                                <>
                                {row.isActive=='1' && 'Active'}
                                {row.isActive=='0' && 'In-Active'}
                                </>
                              )
                            }
                          },
                          {
                            dataField: "channelTypeId",
                            text: "Channel Type",
                            sort: true,                      
                            formatter: (rowContent, row) => {
                              return(
                                <>
                                {row.channelTypeId==2 && 'VENDOR'}
                                {row.channelTypeId==3 && 'DISTRIBUTOR'}
                                {row.channelTypeId==4 && 'RETAILER'}
                                
                                </>
      
                              )
                            }
                          },
                          {
                            dataField: "Action",
                            text: "Action",
                            formatter: (rowContent, row, index) => {
                              return (    
                                <>
                               <i id={'Edit' + index} onClick={()=>{setshowDrawer(true);openBlock(row.subscriptionId)}} class="fas fa-edit pointer">
                               <UncontrolledTooltip target={'Edit' + index} placement='bottom'> Edit </UncontrolledTooltip></i> 
                               
                              </>
                              )
                              }
                            }
                      ]}
                      search
                    >
                  {props => (
                        <div className="py-2 table-responsive">
                          <BootstrapTable
                            {...props.baseProps}
                            bootstrap4={true}
                            // pagination={pagination}
                            bordered={false}
                          />
    
                      <TablePagination
                        component="div"
                        count={total}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                        </div>
                      )}
                    </ToolkitProvider>}
                  </Card>
                  </Container>
        </div>
      );
}

export default Subscription;