import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { LocalStorageService } from '../_services';

const localStorageService = LocalStorageService.getService();

export const PrivateRoute = ({ component: Component, ...rest }) => {
    
    console.log('private');

    return(  
    // <Route {...rest} render={props => (
    //     localStorageService.getAccessToken()
    //         ? <Component  {...props} />
    //         : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    // )} />

    <Route {...rest} render={props => ( <Component {...props} /> )} />
)}