import React, { useEffect, useState } from 'react'
import {
  Card, CardBody, CardHeader, Container
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";


import 'date-fns';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';

import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import { EventSchema } from './validation';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { SelectAutocomplete } from "_components/FormElements/FormInput";
import { ModalSpinner } from 'views/Hoc/Spinner';
import SetRoom from './SetRoom';


const SelectHotel = ({ eventId, country, eventFromDate, eventToDate }) => {
  const [hotelName, setHotelName] = useState([]);
  const [selectHotel, setSelectHotel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hotelDataForEvent, setHotelDataForEvent] = useState([])
  const [tblHotelByEventCountryId, setTblHotelByEventCountryId] = useState(null)
  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null });
  const [deleteId, setDeleteId] = useState(null)
  const [roomModal, setRoomModal] = useState(false);
  const [hotelId, setHotelId] = useState(null);
  const [hotelBookData, setHotelBookingData] = useState([]);

  const [hotelNameForSetupRoom, setHotelNameForSetupRoom] = useState(null);
  const [hotelCountryForSetupRoom, sethotelCountryForSetupRoom] = useState(null);
  const [hotelAddressForSetupRoom, sethotelAddressForSetupRoom] = useState(null);

  const { register, handleSubmit, control, errors, watch, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(EventSchema),
  });

  useEffect(() => {
    getHotelName()
    getHotelsForEvent()
  }, [])

  const getHotelName = async () => {
    setLoading(true);
    await axios.get(`channelAdmin/getHotelsByEventCountry/${country}`)
      .then((res) => {
        const hotelData = res.data.map((item) => ({ id: item.id, name: item.hotelName }));
        setHotelName(hotelData)
        setLoading(false)
      })
      .catch((err) => console.log(err))
  }

  const getHotelsForEvent = async () => {
    setLoading(true);
    await axios.get(`channelAdmin/getHotelsForEvent/${eventId}`)
      .then((res) => {
        setHotelDataForEvent(res.data.hotelDataForEvent);
        setHotelBookingData(res.data.hotelBookingData) 
        sessionStorage.setItem('hotelBookingData', JSON.stringify(res.data.hotelBookingData));
        setLoading(false)
      })
      .catch((err) => console.log(err))
  }

  const handleHotelBooking = (input, value) => {
    if (input == 'selectHotel') {
      if (value == null) {
        setSelectHotel(null);
        return
      }
      setSelectHotel(value)
    }
  }

  const alreadyHotelAdd = (id) => {
    let ret = false; 
    for (let i = 0; i < hotelDataForEvent.length; i++) {
      if (hotelDataForEvent[i].hotelId == id) {
        ret = true;
        break;
      }
    }

    return ret;
  }


  const addHotelForEvent = (id) => {
    if (selectHotel == null) {
      ToastsStore.warning('Please Select Hotel')
      return;
    }

    if (alreadyHotelAdd(selectHotel.id)) {
      ToastsStore.warning('Hotel is already added')
      return
    }

    const data = {
      eventId: eventId,
      hotelId: selectHotel.id,
      createdAt: Date.now()
    }

    setLoading(true);
    axios.post(`channelAdmin/addHotelForEvent`, data)
      .then(async (res) => {
        getHotelsForEvent();
        ToastsStore.success('Hotel is added successfully')
      })
      .catch((err) => {
        ToastsStore.warning('Something went wrong')
        console.log(err);
      })

  }

  const hotelIsBooked = (hotelId) => {
    let hotelBooked = false;
    let hotelBookingDatas = hotelBookData;
    if(hotelBookingDatas.length == 0){
      hotelBookingDatas = JSON.parse(sessionStorage.getItem('hotelBookingData'));
    }
   
    if(hotelBookingDatas == (null || "null")){
      hotelBooked = false;
    }

    console.log(hotelBookingDatas)
      for (let i = 0; i < hotelBookingDatas.length; i++) {
        console.log("hotelBookData[i].hotelId", hotelBookingDatas[i].hotelId, "id", hotelId)
        if (hotelBookingDatas[i].hotelId == hotelId) {
          hotelBooked = true;
          break;
      }
    }

    return hotelBooked;
  }

  const openModalPopUp = (id) => {
    if (!hotelIsBooked(id)) {
      modalPopValue.title = 'Remove Hotel';
      modalPopValue.message = 'You want to Remove Hotel';
      modalPopValue.popUpId = 1;
      setModalPopValue({ ...modalPopValue })
      setDeleteId(id)
      setModalPopup(true);
    } else {
      ToastsStore.warning("You are unable to delete this Hotel because there are bookings associated with it");
    }
  };


  const removeHotelForEvent = async () => {
    setModalPopup(false)
    setLoading(true);
    await axios.delete(`channelAdmin/removeHotelForEvent/${deleteId}`).then(async (res) => {
      getHotelsForEvent();
      ToastsStore.success('Hotel is deleted successfully')
    })
      .catch((err) => {
        ToastsStore.warning('Something went wrong')
        console.log(err);
      })
  }

  return (
    <Container className="col-lg-9 col-md-9 col-sm-12  " fluid>
      {
        roomModal == true && <SetRoom hotelName={hotelNameForSetupRoom} hotelCountry={hotelCountryForSetupRoom} hotelAddress={hotelAddressForSetupRoom} roomModal={roomModal} setRoomModal={setRoomModal} hotelId={hotelId} eventId={eventId} tblHotelByEventCountryId={tblHotelByEventCountryId} eventFromDate={eventFromDate} eventToDate={eventToDate} />
      }
      <Card>
        <CardHeader>
          <h2 style={{ textAlign: "center", color: "#2ca5cd" }}>Hotels</h2>
        </CardHeader>
        <CardBody>
          <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />



          {isModalPopup &&
            <Dialog open={isModalPopup} value={modalPopValue} handleSave={removeHotelForEvent} handleClose={() => setModalPopup(false)} >
            </Dialog>
          }

          <div style={{ display: "flex", margin: 0, alignItems: "center", alignContent: "center", alignItems: "center" }}>
            <SelectAutocomplete
              id="search"
              type="text"
              label="Hotel"
              placeholder="Select Hotel Name"
              name="hotelName"
              className="d-inline-block"
              style={{ width: '35%' }}
              options={hotelName}
              disableCloseOnSelect
              control={control}
              input='selectHotel'
              value={selectHotel}
              defaultValue={selectHotel}
              handleChangeSelect={handleHotelBooking}
            />

            <button type="submit" className="btn btn-primary ml-4" onClick={addHotelForEvent}>Add Hotel</button>
          </div>

          {loading == true && <ModalSpinner />}

          <div style={{ display: loading == true ? "none" : 'block' }}>
            {
              hotelDataForEvent.length > 0 ?
                <ToolkitProvider
                  data={hotelDataForEvent}
                  keyField="id"
                  columns={[

                    {
                      dataField: "hotelName",
                      text: "Hotel",
                      sort: true,

                      formatter: (cell, row) => (
                        <div style={{ display: "flex" }}>
                          <img src={`${process.env.REACT_APP_CDN_URL_TEMP}${row.hotelLogo}`} alt="Hotel Logo" style={{ width: '50px', height: '50px', borderRadius: "50%" }} />
                          <div style={{ marginLeft: "1.5%" }}>
                            <div>{cell}</div>
                            <div style={{ marginTop: "2%" }}>{row.address}</div>
                          </div>
                        </div>
                      )

                    },

                    {
                      dataField: "name",
                      text: "Contact",
                      sort: true,
                      formatter: (cell, row) => (
                        <>
                          <div>{cell}</div>
                          <div style={{ marginTop: "7%" }}>{row.mobile}</div>
                        </>
                      )
                    },

                    {
                      dataField: "country",
                      sort: true,
                      text: "Country"
                    },

                    {
                      dataField: "hotelId",
                      text: "Room Setup",
                      formatter: (cell, row) => (
                        <button id="Edit150" class="btn btn-icon btn-primary btn-sm" variant="contained" onClick={() => {
                          setHotelId(cell);
                          setHotelNameForSetupRoom(row.hotelName);
                          sethotelCountryForSetupRoom(row.country);
                          sethotelAddressForSetupRoom(row.address)
                          setTblHotelByEventCountryId(row.id);
                          setRoomModal(true)
                        }}>
                          <span class="btn-inner--icon">Room Setup</span></button>
                      )
                    },



                    {
                      dataField: "hotelId",
                      text: "View",
                      formatter: (cell, row) => (
                        <button onClick={() => {
                          let path = window.location.href.substring(0, window.location.href.lastIndexOf('/'));
                          path = path + `/hotel/${cell}`
                          window.open(path, '_blank');
                        }} id="View150" class="btn btn-icon btn-primary btn-sm" variant="contained" color="primary"><span class="btn-inner--icon"><i class="far fa-eye"></i></span></button>
                      )
                    },

                    {
                      dataField: "hotelId",
                      text: "Action",
                      width: 100,
                      formatter: (rowContent, row) => (
                        <button id="Del150" class="btn btn-icon btn-warning btn-sm" variant="contained" color="#fb6340" onClick={() => openModalPopUp(rowContent)}><span class="btn-inner--icon"><i class="fas fa-trash-alt"></i></span></button>
                      )
                    },

                  ]}
                  search
                >
                  {props => (
                    <div className="table-responsive">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter mb-2"
                        style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
                      >
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "10px", paddingBottom: "10px" }}>


                        </div>
                      </div>


                      <BootstrapTable
                        bordered={false}
                        classes={`table-striped table-hover`}
                        {...props.baseProps}
                        bootstrap4={true}
                      />
                    </div>
                  )}
                </ToolkitProvider>
                :

                <h4 style={{ textAlign: "center", marginTop: "4rem" }}>Please Add Hotels For Event</h4>
            }



          </div>

        </CardBody>
      </Card>
    </Container>
  )
}

export default SelectHotel