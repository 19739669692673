import React, { useEffect, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { FormTextArea, FormInput, FormAutocomplete, SingleAutocompleteGrouping, FormRadio, FormCheckBox, FormAutocompleteGrouping } from "_components/FormElements/FormInput";
import { useForm } from "react-hook-form";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker, KeyboardDatePicker,
  } from '@material-ui/pickers';
  import DateFnsUtils from '@date-io/date-fns';
import Select from '@mui/material/Select';


import {
    FormControl,
    InputAdornment,
} from "@material-ui/core";
import {
    Button, Modal
} from "reactstrap";
import Chip from '@mui/material/Chip';

const payOption = [{ id: 1, name: "Full Amount", checked: false }, { id: 2, name: "Split", checked: true }]

const payPeriod = [{ id: 1, name: "Immediate", value: 0 }, { id: 2, name: "45 Days", value: 45 }, { id: 3, name: "90 Days", value: 90 }]

export const SplitEdit = (props) => {

    const { pack, paymentSplit, splits,eventId, eventChannel, contactList, channel, contact, popupId } = props;


    var today = Moment();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [paymentOption, setPaymentOption] = useState(payOption);
    const [periodList, setPeriodList] = useState([]);//{ id: 1, period: payPeriod, percent: null, selectedPeriod: 1 }, { id: 2, period: payPeriod, percent: null, selectedPeriod: null }]);
    const [totalSplit, setSplit] = useState(2);

    const [paidAmount, setPaidAmt] = useState();
    const [balAmount, setBalAmt] = useState();


    const [lastSplitErr, setLastSplitErr] = useState(false);
    const [splitErr, setSplitErr] = useState(false);

    const [paidFlag, setPaidFlag] = useState(true);



    useEffect(() => {

        splits.sort((a, b) => a.splitId - b.splitId)
        // console.log("contactList:", splits);
        let list = [];let paidamt=0;let balance=0;
        for (var i = 0; i < splits.length;i++) {
            if(splits[i].paymentStatus==1){
            paidamt=paidamt+parseInt(splits[i].splitAmount);
            }else{
                balance=balance+parseInt(splits[i].splitAmount);
            }
            list.push({channelId:splits[i].channelId,contactId:splits[i].contactId,paymentStatus:splits[i].paymentStatus,id: splits[i].splitId, percent: splits[i].percentage, selectedPeriod: null,paymentDate:Moment(splits[i].paymentDate).format('MM/DD/YYYY') });

        }
        setPaidAmt(paidamt);setBalAmt(balance);

        setPeriodList(list);
        setSplit(splits.length);

        //handleSplit(2);

    }, [])



    const handleSplit = (value) => {


        setSplitErr(false);
        let list = [];

        paymentSplit(true, []);

        if (value == "") {
            setPeriodList(list);
            setSplit(0);
        }
        if (value == 1 || value == 7) {
            setPeriodList(list);
            setSplit(value);

        } else {
            for (var i = 0; i < value && value <= 6; i++) {
                list.push({ id: i + 1, period: payPeriod, percent: null, selectedPeriod: i == 0 ? 1 : null });

            }
            if (value <= 6) {
                setPeriodList(list);
                setSplit(value);
            }
        }

    }

    const changePaymentOption = (selected, value) => {
        if (selected == 1 && value) {
            setPaymentOption([{ id: 1, name: "Full Amount", checked: value }, { id: 2, name: "Split", checked: false }]);
            setPeriodList([{ channelId:splits[0].channelId,contactId:splits[0].contactId,id: 1, period: payPeriod, percent: 100, selectedPeriod: 1 }]);
            setSplitErr(false);
            setLastSplitErr(false);
        } else if (selected == 2 && !value) {
            setPaymentOption([{ id: 1, name: "Full Amount", checked: true }, { id: 2, name: "Split", checked: value }]);
            setPeriodList([{ channelId:splits[0].channelId,contactId:splits[0].contactId,id: 1, period: payPeriod, percent: 100, selectedPeriod: 1 }]);
            
            
            setSplitErr(false);
            setLastSplitErr(false);

           
        } 
        else if (selected == 2 && value) {
            setPaymentOption([{ id: 1, name: "Full Amount", checked: false }, { id: 2, name: "Split", checked: value }]);
            // handleSplit(2);
            setSplitErr(false);
            let list = [];
            for (var i = 0; i < splits.length;i++) {
               
                list.push({channelId:splits[i].channelId,contactId:splits[i].contactId,paymentStatus:splits[i].paymentStatus, id: splits[i].splitId, percent: splits[i].percentage, selectedPeriod: null,paymentDate:Moment(splits[i].paymentDate).format('MM/DD/YYYY') });
    
            }           
    
            setPeriodList(list);
        }

        paymentSplit(true, []);
    }

    const confirmPayment = () => {
        // console.log("periodList:", contactList,eventId);
        let errFlag = false;
        let list = [];
        let splits = [];

        if(popupId == 3 && paidFlag){

            const channels = eventChannel ? eventChannel?.find(x => x.eventId == eventId) : channel;
    
            const participant = contactList ? contactList?.filter(x => x.eventParticipants?.filter(p => p.eventId == eventId && (p.status == 0 || p.status == null)).length > 0) : contact;
    
            let obj = {
                channelId: channels?.channelId,
                contactId: participant?.length ? participant[0].contactId : participant.contactId,
                eventId: eventId,
                packageId: pack.id,//channel.packageId,
                splitId: 1,
                paymentRemainderCount: 0,
                paymentMethod: 1,
                paymentStatus: '1',
                wireTransfer: '1',
                percentage: 100,
                splitAmount: pack.price,
                totalAmount: pack.price,//channel.package['price']
            }

            splits.push(obj);
        }else{           
    
            if (paymentOption.find(x => x.checked == true).id == 2) {
                const empty = periodList.filter(p => p.percent == null || !p.paymentDate);
    
                if (totalSplit == 0 || lastSplitErr) {
                    errFlag = true;
                }
                else if (empty.length) {
                    setSplitErr(true);
                    paymentSplit(true, []);
                    errFlag = true;
                    return;
                } else {
                    setSplitErr(false);
                    list = periodList;
                    errFlag = false;
                }
            }
    
            if (paymentOption.find(x => x.checked == true).id == 1) {
                list = periodList.filter(p => p.selectedPeriod == 1);
            }
    
           
            //const channels = eventChannel ? eventChannel?.find(x => x.eventId == eventId) : channel;
    
            //const participant = contactList ? contactList?.filter(x => x.eventParticipants?.filter(p => p.eventId == eventId).length > 0) : contact;
    
    
    
            var today = Moment();
    
            list.map(p => {
                // console.log("paymentSplitss:", participant);
                let obj = {
                    channelId: p.channelId,
                    contactId: p.contactId,//participant?.length ? participant[0].contactId : participant.contactId,
                    eventId: eventId,
                    packageId: pack.id,//channel.packageId,
                    splitId: p.id,
                    paymentRemainderCount: 0,
                    paymentMethod: paymentOption.find(x => x.checked == true).id,
                    paymentStatus: p.paymentStatus==1?p.paymentStatus:'0',
                    wireTransfer: '0',
                    paymentDate:p.paymentDate,
                    percentage: p.percent,
                    splitAmount: ((p.percent / 100) * pack.price),
                    totalAmount: pack.price,//channel.package['price']
                }
    
                if (paymentOption.find(x => x.checked == true).id == 2) {
                    Object.assign(obj, { percentage: p.percent});// paymentDate: p.selectedPeriod == 1 ? today.format('MM/DD/YYYY') : Moment(today).add(payPeriod.find(x => x.id == p.selectedPeriod).value, 'days').format('MM/DD/YYYY') })
                } else {
                    Object.assign(obj, { splitAmount: balAmount, percentage: 100});// paymentDate: today.format('MM/DD/YYYY') })
                }
    
                splits.push(obj);
    
            })

        }

        paymentSplit(errFlag, splits);
    }


    const handlePercent = (input, value) => {
        //console.log("per:",input,value,totalSplit);
        const peri = periodList.map(x => {
            if (x.id == input && x.id <= totalSplit - 1) {
                x.percent = value;
            }
            return x;
        })

        // if (input == totalSplit - 1) {
        const last = periodList.find(x => x.id == totalSplit);
        const peris = peri.filter(x => x.id != totalSplit);
        const sum = peris?.reduce((acc, obj) => acc + +obj.percent, 0)
        last.percent = 100 - sum;
        if (last.percent == 0) {
            setLastSplitErr(true);
            paymentSplit(true, []);

        } else {
            setLastSplitErr(false);
        }
        setPeriodList([...peris, last]);
        // } else {
        // setPeriodList(peri);
        // }

    }

    


    const setPeriod = (period, selected) => {
        console.log("period:", periodList,period, Moment(selected).format('MM/DD/YYYY'));
        const peri = periodList.map(x => {
            if (x.id == period) {
                // x.selectedPeriod = selected;
                x.paymentDate = Moment(selected).format('MM/DD/YYYY');
            }
            return x;
        })

        setPeriodList(peri);

    }

    return (
        <>
            <Box
                sx={{
                    border: '2px dashed #2c9db7',
                    // height: 320,

                    marginLeft: '25px',

                    width: 780,
                    '& .actions': {
                        color: 'text.secondary',
                    },
                    '& .textPrimary': {
                        color: 'text.primary',
                    },
                }}
            >
                <div className="container" style={{ marginTop: 5, padding: '10px' }}>

                    <div className="row">
                        <div className="col-lg-2">
                            <b>Package Amount</b>
                        </div>
                        <div className="col-lg-2">
                            <Chip label={`€ ${pack.price}`} style={{ backgroundColor: 'blue', color: '#fff' }} variant="filled" size="large" />
                        </div>

                        <div className="col-lg-2">
                            <b>Paid Amount</b>
                        </div>
                        <div className="col-lg-2">
                            <Chip label={`€ ${paidAmount}`} style={{ backgroundColor: 'green', color: '#fff' }} variant="filled" size="large" />
                        </div>

                        <div className="col-lg-2">
                            <b>Balance Amount</b>
                        </div>
                        <div className="col-lg-2">
                            <Chip label={`€ ${balAmount}`} style={{ backgroundColor: 'red', color: '#fff' }} variant="filled" size="large" />
                        </div>

                        {/* {popupId == 3 &&
                            <div className="col-lg-3">
                                <label className="custom-toggle custom-toggle-success">
                                    <input defaultChecked={paidFlag ? true : false} type="checkbox" name="status" onClick={() => { setPaidFlag(!paidFlag) }} />
                                    <span className="custom-toggle-slider rounded-circle" data-label-off="Not Paid" data-label-on="Paid"></span>
                                </label>
                            </div>} */}
                    </div>

                    {((popupId == 3 && !paidFlag) || popupId != 3) && <>
                        <div className="row">
                            <div className="col-lg-3">
                                <Checkbox
                                    checked={paymentOption.find(x => x.id == 1).checked}
                                    // color="warning"
                                    onChange={(e) => { changePaymentOption(1, e.target.checked ? true : false) }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                /> Full Amount
                            </div>

                            <div className="col-lg-6">
                                <Checkbox
                                    checked={paymentOption.find(x => x.id == 2).checked}
                                    // color="warning"
                                    onChange={(e) => { changePaymentOption(2, e.target.checked ? true : false) }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                /> Split
                            </div>


                        </div>

                        {paymentOption.find(x => x.id == 2).checked &&
                            <div className="row">
                                <div className="col-lg-3">
                                    <b>Set Amount Split</b>
                                </div>

                                <div className="col-lg-6" style={{ marginBottom: '20px' }}>
                                    <FormInput
                                        className=""
                                        id="splitCount"
                                        label="No of Split"
                                        type="number"
                                        name='splitCount'
                                        disabled
                                        // register={register}
                                        // error={errors.template}
                                        defaultValue={totalSplit}
                                        value={totalSplit}
                                        onChange={(e) => handleSplit(e.target.value)}

                                        // autoComplete="current-password"
                                        variant="outlined"
                                    />

                                </div>
                            </div>}

                        {(periodList.length > 0 && paymentOption.find(x => x.id == 2).checked) &&
                            <div className="row">

                                {periodList.map(period => {
                                    return <>

                                        <div className="col-lg-4" style={{ marginBottom: '20px', paddingRight: 0 }}>
                                            <InputLabel id={period.id.toString()}><b>{`Split ${period.id}`}</b></InputLabel>
                                            <div className='row'>
                                                <div className='col-xs-2' style={{ paddingRight: 0 }}>
                                                    <TextField style={{ width: '90px' }}
                                                        InputLabelProps={{ shrink: true }}
                                                        id={period.id}
                                                        label="Percentage"
                                                        type="text"
                                                        disabled
                                                        // disabled={period.id == totalSplit ? true : false}
                                                        name={period.id}
                                                        value={period.percent}
                                                        onChange={(e) => handlePercent(e.target.name, e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-xs-7' style={{ paddingLeft: '10px' }}>

                                                    {/* <FormControl className='period-spit'  >
                                                        <InputLabel id="demo-select-small" style={{ position: 'absolute', zIndex: 1, backgroundColor: '#fff', fontSize: '10px', top: '-5px', padding: '3px 10px', left: '10px' }}>Period</InputLabel>

                                                        <Select
                                                            labelId="demo-select-small"
                                                            id="demo-select-small" //{period.id.toString()}
                                                            value={payPeriod.find(x => x.id == period.selectedPeriod)?.id}
                                                            label="Period"
                                                            onChange={(e) => setPeriod(period.id, e.target.value)}
                                                        >
                                                            {
                                                                payPeriod.map(x =>
                                                                    <MenuItem value={x.id}>{x.name}</MenuItem>
                                                                )}

                                                        </Select>
                                                    </FormControl> */}

                                                    <div className="awd-date">
                        <FormControl className='period-spit'>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/dd/yyyy"
                              margin="normal"
                              id="buyerMeetingDate"
                              name="buyerMeetingDate"
                              disabled={period.paymentStatus==1?true:false}
                              defaultValue={!period.paymentDate? null:periodList.find(x => x.id == period.id)?.paymentDate}
                              // ref={register}
                            //   control={control}
                              value={!period.paymentDate? null:periodList.find(x => x.id == period.id)?.paymentDate}
                            //   value={state.buyerMeetingDate}
                              onChange={(date) => setPeriod(period.id, date)}
                            // KeyboardButtonProps={{
                            //   'aria-label': 'change date',
                            // }}
                            />
                          </MuiPickersUtilsProvider>
                          {/* <FormHelperText style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }} >{errors.fromDate?.message}</FormHelperText> */}
                        </FormControl>
                      </div>

                                                </div>
                                            </div>
                                        </div>

                                    </>
                                })}

                            </div>}
                    </>}
                </div>
            </Box>
            <div style={{ paddingLeft: '25px' }}>
                {(totalSplit == 1 && paymentOption.find(x => x.id == 2).checked) && <div className="row"><p style={{ color: "red" }}>Please go with Full Amount</p></div>}

                {(totalSplit == 7 && paymentOption.find(x => x.id == 2).checked) && <div className="row"><p style={{ color: "red" }}>Maximum 6 splits are allowed</p></div>}

                {(totalSplit == 0 && paymentOption.find(x => x.id == 2).checked) && <div className="row"><p style={{ color: "red" }}>Atleast minimum 2 splits are must</p></div>}

                {splitErr && <><br /> <div className="row"><p style={{ color: "red" }}>Please make it proper split with all data</p></div></>}

                {lastSplitErr && <><br /> <div className="row"><p style={{ color: "red" }}>Last Split Percentage Should be atleast 1</p></div></>}
            </div>
            <div className="modal-footer" style={{ textAlign: 'center', paddingBottom: '0' }}>
                <Button style={{ margin: 'auto' }} color="primary" type="button" onClick={() => confirmPayment()}>ConfirmPayment</Button>
            </div>
        </>
    );
}