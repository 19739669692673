import React, { useState, useEffect } from 'react';
import { Card, Container, CardHeader, UncontrolledTooltip } from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import BootstrapTable from "react-bootstrap-table-next";
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/PaginationActions';
import AddBrand from "views/Hoc/Dialog/AddBrand";
import classnames from "classnames";
import { Spinner } from '../../Hoc/Spinner';
import { SelectAutocompleteWithList, FormAutocomplete } from '_components/FormElements/FormInput';
import axios from 'axios';
import { commonService } from '_services/common.service';
import { useForm } from "react-hook-form";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
import { Fileuploaddialog } from "views/Hoc/Dialog/Fileuploaddialog";
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { Form, Row, Col, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";
const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export const Brand = () => {
  const [state, setState] = useState({ dataTable: [], total: 0 });
  let [searchList, setSearchList] = useState([]);
  let [search, setSearch] = useState(null);
  let [sort_by, setSortby] = useState([]);
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [loading, setLoading] = useState(false);
  const [openadd, openAddnew] = useState(false);
  const [isEdit, setEdit] = useState(false);
  let [editBrand, setEditBrand] = useState(null);
  let [sort, setSort] = useState('desc');
  let [sortBrandLogo, setBrandLogo] = useState(2);
  let [sortText, setSortText] = useState('All Brand');
  let [deletedBrand, setDeletedBrand] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false);

  const handleShowMerge = () => setShowMergeModal(true);
  const [checkForBrand, setCheckForBrand] = useState([]);
  const [checkForTargetBrand, setCheckForTargetBrand] = useState([]);
  const [selectedBrand,setSelectedBrand] = useState([]);
  const [selectTarget, setSelectTarget] = useState();
  const [searchTarget, setSearchTarget] = useState();
  
  const [showSelectedTarget,setShowSelectedTarget] = useState(false)
  const { control, register } = useForm({ mode: 'onBlur' });
  let [isModalPopup, setModalPopup] = useState(false);
  let [isFilePopup, setFilePopup] = useState(false);
  let [isBrandLoaded,setIsBrandLoaded] = useState(false)
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });
  useEffect(() => {
    getBrand();
    getSearchDropdownList();
  }, [deletedBrand]);
  const getBrand = async () => {
    setLoading(true);
    let sortby = [];
    if (sort_by.length) {
      sort_by.map(x => {
        sortby.push(x.id);
      })
    } else {

      sortby = ['createdAt','id'];
    }
    let res = await commonService.getServiceWithParams({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      search: search ? search.id : null,
      orderBy: sort,
      order: sortby,
      sortBrandLogo: sortBrandLogo,// ? 1 : 0,
      deletedBrand: deletedBrand ? 1 : 0
    }, '/master/getBrands');
    if (res) {
      // console.log("getBrands:", res);
       state.dataTable = res.data.list;
        state.total = res.data.total;
      setState({ ...state });
      setLoading(false);
    } else {
      ToastsStore.error('Something Went Wrong!');
      state.dataTable = [];
      state.total = 0;
      setLoading(false);
      setState({ ...state });
    }
  }
  const handleCloseMerge = () => { setShowMergeModal(false);
    setSelectedBrand(prev=>[]);
    setSearchTarget(null);
    setSelectTarget(null);
  };
  const getSearchDropdownList = () => {
    let deleted = deletedBrand ? 2 : 1;
    let logo = sortBrandLogo;// ? 1 : 0;
    axios.get('/master/brandNameList/' + deleted + '/' + logo).then(res => {
      if (res.data) {
        searchList = res.data;
        setSearchList(searchList);
        setIsBrandLoaded(true);
      }
    });
  }
  const callPendingBrand = () => {
    page = 0;
    setPage(page);
    getBrand();
  }

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getBrand();
  };

  const handleMergeBrand = () => {
 //   debugger
    if(isBrandLoaded) {
        handleShowMerge();
        setCheckForBrand(searchList);
        setCheckForTargetBrand(searchList);
    } else {
      ToastsStore.info('Please try again, data not loaded. ');
    }
 
  };

  
  const handleSelectedDelete = (chipToDelete) => () => {
   //debugger
  
    setSelectedBrand((prev) => prev.filter((chip) => chip.id !== chipToDelete.id));
  
    setCheckForBrand(prev=>[chipToDelete,...prev])
    setCheckForTargetBrand(prev=>[chipToDelete,...prev]);
  };

  const handleCheckForBrand = (e, option) => {
    ///debugger
    if(selectTarget == option.id){
      ToastsStore.error('Brand already selected in target. Invalid');
    } else {
      let filterValue = checkForBrand.filter((value)=>value.id !== option.id) 
      setCheckForBrand(filterValue);
      setCheckForTargetBrand(filterValue);
      setSelectedBrand(prev=>[...prev,option])
    }
  };

  const handleMergeBrandId = (e,option)=>{
    if(option?.id){
      setSearchTarget(option);
      setSelectTarget(option.id);
    }
  }


  const submitFinalMergeData = async ()=>{
  //  debugger
    setLoading(true)
   
    let brandArray = selectedBrand.map((item)=>{
     return item.id;
   })
    
    if(brandArray.length > 0 && selectTarget != null) {
      let res=await axios.post("/master/mergeBrand",{target:selectTarget,source:brandArray})
      setLoading(false)
      ToastsStore.success('Brand has been successfully merged');
      handleCloseMerge();
      window.location.reload();
    } else if(brandArray.length == 0) {    
      ToastsStore.error('Invalid source. Try again');
      setLoading(false);
    } else if(selectTarget == null) {    
      ToastsStore.error('Invalid target. Try again');
      setLoading(false);
    }
}

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    callPendingBrand();
  };
  const handleChange = (input) => (e) => {
    search = e.target.value;
    setSearch(search);
    callPendingBrand();
  }

  const changeStatus = (id, status) => {
    let newStatus = status == '1' ? '0' : '1';
    axios.patch('master/updateBrandStatus/' + id + '/' + newStatus);
    state.dataTable.map(x => {
      if (x.id == id) {
        x.isActive = newStatus;
      }
    })
    setState({ ...state });
    //console.log("newStatus:", newStatus);

  }

  const setBrandVerified = (id, status) => {
    axios.patch('master/setBrandVerified/' + id + '/' + status).then(res => {
      if (res)
        getBrand();
    });
    // state.dataTable.map(x => {
    //   if (x.id == id) {
    //     x.isActive = status;
    //     x.isVerified = status;
    //   }
    // })
    // setState({ ...state });

  }

  // const setSorting = (data) => {
  //     if (data != sort) {
  //       sort = data;
  //       setSort(sort);
  //       callPendingBrand();
  //     }

  //   }
  const setSorting = () => {
    sort = sort === 'asc' ? 'desc' : 'asc';
    setSort(sort);
    callPendingBrand();
  }

  const setSortingBrandLogo = (data) => {
    if (data != sortBrandLogo) {
      sortBrandLogo = data;
      if (data == 1)
        setSortText('Brand With Logo')
      else if (data == 0)
        setSortText('Brand Without Logo');
      else if (data == 2)
        setSortText('All Brand');
      setBrandLogo(sortBrandLogo);
      callPendingBrand();
    }

  }
  const handleChangeSelect = async (input, value) => {
    if (input == 'brands') {
      setLoading(true);
      if (value?.isNew === '1') {
        let data = {
          brandName: value.name,
          isActive: '1',
          brandImage: value.documentpath
        }
        const editBrand = await commonService.postService(data, 'master/brand');
        if (editBrand.data) {
          setLoading(false);
          ToastsStore.success('Saved Successfully!');
          search = null;
          setSearch(search);
          sort = 'desc';
          setSort(sort);
          callPendingBrand();
        } else {
          setLoading(false);
          ToastsStore.error('Something Went Wrong!');
        }
      } else {
       const dataTable = state.dataTable.map((x) => {
         if (x.id === value.id) {
           x.brandName = value.name;
           if(!x.document)
           {
            x.document= {}
           }
           x.document.documentUrl = value.documentUrl;
         }
         return x
       });
       debugger
        setState({ ...state, dataTable });
      }
      setLoading(false);
    }
    if (input == 'search') {
      search = value
      setSearch(search);
      callPendingBrand();
    }
    if (input == 'sort_by') {
      sort_by = value
      setSortby(sort_by);
      callPendingBrand();
    }
  }

  const openDialag = (value) => {
    // console.log('value', value);
    editBrand = value;
    setEditBrand(editBrand);
    setEdit(true);
    openAddnew(true);
  }

  const addBrand = () => {
    setEdit(false);
    openAddnew(true);
  }

  const downloadBrand = async () => {
    var fileDownload = require('js-file-download');
    setLoading(true);
    let sortby = [];
    if (sort_by.length) {
      sort_by.map(x => {
        sortby.push(x.id);
      })
    } else {
      sortby = ['brandName'];
    }
    axios({
      url: 'master/downloadBrand',
      method: 'GET',
      params: { order: sortby, orderBy: sort, sortBrandLogo: sortBrandLogo },
      responseType: 'blob', // Important
    }).then((response) => {
      fileDownload(response.data, `BrandList.xlsx`);
      setLoading(false);
    });

  }

  const showFileUpload = () => {
    setFilePopup(true)
  }

  const closeModalPopup = (value) => {
    openAddnew(false);
  }
  const deletePopup = (contactid) => {
    modalPopValue.message = 'Do you want to delete this brand?';
    modalPopValue.title = 'Delete User';
    modalPopValue.value = contactid;
    modalPopValue.popUpId = 1;
    modalPopValue.active = false;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);
  }
  const dismissPopup = (contactid) => {
    modalPopValue.message = ' You want to Dismiss this Brand ';
    modalPopValue.title = 'Dismiss Brand';
    modalPopValue.value = contactid;
    modalPopValue.popUpId = 1;
    modalPopValue.active = false;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);
  }
  const approvePopup = (data) => {
    modalPopValue.message = ' You want to Approve this Brand ';
    modalPopValue.title = 'Approve Brand';
    modalPopValue.value = data;
    modalPopValue.popUpId = 1;
    modalPopValue.active = false;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);
  }
  const activePopup = (contactid) => {
    modalPopValue.message = 'Do you want to Restore this brand?';
    modalPopValue.title = 'Restore Brand';
    modalPopValue.value = contactid;
    modalPopValue.popUpId = 1;
    modalPopValue.active = true;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);
  }

  const closePopup = () => {
    setModalPopup(false);
  };

  const closeFilePopup = () => {
    setFilePopup(false);
  };
  const confirmPopup = (res) => {
    setModalPopup(false);
    if (res.active) {
      activateBrand(res.value)
    }
    else if (res.value.brandApprove === true) {
      setBrandVerified(res.value.brandId, '1')
    }
    else if (res.value.brandApprove === false) {
      setBrandVerified(res.value.brandId, '0')
    }
    else {
      deleteBrand(res.value)

    }



  }
  const deleteBrand = async (data) => {
    await axios.delete('master/deleteBrand/' + data.id).then(res => {
		  if(res.data == 'ProductExist') {
        ToastsStore.warning('Product Exist for this Brand, Unable to delete');
      }  else {      
        ToastsStore.success('Brand Removed successfully!');
        if (searchList.length)
            removeBrandFromList(data.id)
        getBrand();
      }
    }).catch(err => {
      ToastsStore.error('Something went wrong!');
    })
  }

  const activateBrand = async (data) => {
    await axios.patch('master/activateBrand/' + data.id).then(res => {
      ToastsStore.success('Brand Restored Successfully!');
      if (searchList.length)
        removeBrandFromList(data.id)
      getBrand();
    }).catch(err => {
      ToastsStore.error('Something went wrong!');
    })
  }

  const removeBrandFromList = (id) => {
    searchList = searchList.filter(x => x.id != id);
    setSearchList(searchList);
  }
  return (
    <div id="channelList">
      <SimpleHeader name="Product Brands" parentName="Master" />
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <Fileuploaddialog isOpen={isFilePopup} handleClose={closeFilePopup} />
      <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
      <AddBrand aws_folderName="brandlogo" opneadd={openadd} closeModalPopup={closeModalPopup} value={editBrand} isEdit={isEdit} brandName={null} handleChangeSelect={handleChangeSelect} />
      <Container className="mt--3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Product Brands</h3>
                {/* <p className="text-sm mb-0">
                        This is an exmaple of data table using the well known
                        react-bootstrap-table2 plugin. This is a minimal setup in
                        order to get started fast.
                      </p> */}
              </CardHeader>
              {loading ? <Spinner /> : <ToolkitProvider
                ClassName="align-items-center table-flush" responsive
                data={state.dataTable}
                keyField="id"
                columns={[
                  {
                    dataField: "id",
                    text: "Brand Id",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {row.id}
                        </>
                      )
                    }
                  },
                  {
                    dataField: "brandLogo",
                    text: "Brand Image",
                    // sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="avatar avatar-sm rounded-circle"
                            alt="..."
                            src={row.document?.documentUrl ? row.document?.documentUrl : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}//{require("assets/img/theme/team-1.jpg")}
                          />
                        </>
                      )
                    }
                  },
                  {
                    dataField: "brandName",
                    text: "Product Brands",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {row.brandName}
                        </>
                      )
                    }
                  },   {
                    dataField: "channel",
                    text: "Created BY",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {row.channel?.companyName}                          
                        </>
                      )
                    }
                  },
                  {
                    dataField: "createdAt",
                    text: "Created on",
                    sort: true,
                    // formatter: (rowContent, row) => {
                    //   return (
                    //     <>
                    //       {row.createdAt}
                          
                    //     </>
                    //   )
                    // }
                  },
                  {
                    dataField: "isActive",
                    text: "Status",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          <label className="custom-toggle custom-toggle-success">
                            <input defaultChecked={row.isActive == '1' ? true : false} type="checkbox" name="status" onClick={() => { changeStatus(row.id, row.isActive) }} />
                            <span className="custom-toggle-slider rounded-circle" data-label-off="Inactive" data-label-on="Active"></span>
                          </label>
                        </>
                      )
                    }
                  },
                  {
                    dataField: "Invoices",
                    text: "Action",
                    formatter: (rowContent, row, index) => {
                      return (
                        <>
                          {/* <button 
                            onClick={()=>{openDialag(row)}} 
                            className="btn btn-icon btn-primary btn-sm" variant="contained" color="primary" >
                              <span className="btn-inner--icon">Edit</span>
                            </button> */}
                          <div className="res-i">

                            {row.isVerified == '0' && <>
                              <a onClick={() => { approvePopup({ brandId: row.id, ...row.channel, ...row.contact, brandApprove: true }) }} ><i id={'approve' + index} class="ni ni-check-bold ml-4" style={{
                                color: "#46b88f", fontSize: "18px",
                                top: "8px",
                                position: "relative"
                              }}>
                                <UncontrolledTooltip target={'approve' + index} placement='bottom'> Approve </UncontrolledTooltip></i></a>


                              <a onClick={() => { dismissPopup({ brandId: row.id, ...row.channel, ...row.contact, brandApprove: false }) }} ><i id={'dismiss' + index} class="ni ni-fat-remove ml-4" style={{
                                color: "#fb6340", fontSize: "21px",
                                top: "8px",
                                position: "relative"
                              }}>
                                <UncontrolledTooltip target={'dismiss' + index} placement='bottom'> Dismiss </UncontrolledTooltip></i></a>
                            </>}


                            <a onClick={() => { openDialag(row) }} ><i id={'Edit' + index} class="fas fa-pencil-alt ml-4" style={{ color: "#46b88f" }}>
                              <UncontrolledTooltip target={'Edit' + index} placement='bottom'> Edit </UncontrolledTooltip></i></a>
                            {!deletedBrand ?
                              <>
                                <a onClick={() => deletePopup(row)}><i id={'Del' + index} class="fas fa-trash-alt ml-4" style={{ color: "#fb6340" }}>
                                  <UncontrolledTooltip target={'Del' + index} placement='bottom'> Delete </UncontrolledTooltip></i></a>
                              </> :

                              <>
                                <a onClick={() => activePopup(row)}><i id={'Act' + index} class="fas fa-trash-alt ml-4" style={{ color: "green" }}>
                                  <UncontrolledTooltip target={'Act' + index} placement='bottom'> Restore Brand </UncontrolledTooltip></i></a>
                              </>
                            }
                          </div>
                        </>
                      )
                    }
                  }
                ]}
              >
                {props => (
                  <div className="py-2 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 mb-2"
                    >
                      <Row>
                        <Col lg="3">
                          <div >
                            <SelectAutocompleteWithList
                              id="brandsearch"
                              type="text"
                              label="search"
                              name="search"
                              options={searchList}
                              placeholder="search..."
                              disableCloseOnSelect
                              defaultValue={search ? search : null}
                              control={control}
                              input='search'
                              value={search ? search : null}
                              handleChangeSelect={handleChangeSelect}
                            />


                          </div>

                        </Col>
                        <Col lg='4' className='p-0 text-center'>
                          {/* <AdminNavbar/> */}
                          <Form
                            className={classnames(
                              "navbar-search form-inline mr-sm-3",
                            )}
                          >
                            {/* <FormGroup className="mb-2 mt-2">
                  <InputGroup className="input-group-alternative input-group-merge">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Search Company or Products" type="text" 
                    // autoFocus
                    ref={ref}
                    value={search}
                    onChange={handleChange("search")}
                    />
                  </InputGroup>
                </FormGroup> */}
                            <div className="ml-2" style={{ width: '40%' }}>
                              {/* <Autocomplete
                                  multiple
                                  id="checkboxes-tags-demo"
                                  options={top100Films}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) => option.title}
                                  renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                    {option.title}
                                  </React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" label="Sort by" placeholder="Sort by" />
                                )}
                            /> */}

                              <FormAutocomplete
                                id="sort_by"
                                name="sort_by"
                                label="Sort by"
                                options={top100Films}
                                placeholder="Sort by"
                                multiple
                                disableCloseOnSelect
                                blurOnSelect
                                limitTags={1}
                                input='sort_by'
                                value={sort_by}
                                handleChangeSelect={handleChangeSelect}
                                defaultValue={sort_by}
                                control={control}
                              />
                            </div>
                            <button class="btn btn-icon btn-secondary ml-2" type="button" onClick={setSorting}>

                              <span class="btn-inner--text">Sort</span>
                              <span class="btn-inner--icon">
                                {sort == 'asc' ? <i class="fas fa-sort-alpha-down"></i> :
                                  <i class="fas fa-sort-alpha-up"></i>}
                              </span>
                            </button>
                            <UncontrolledDropdown className="pl-3 mt-2 mb-2" group>
                              <DropdownToggle caret className="p-2">
                                <h3
                                  style={{ display: "inline-block" }}
                                  className="font-weight-bold m-0"
                                >
                                  {sortText}
                                </h3>
                              </DropdownToggle>
                              <DropdownMenu>

                                <DropdownItem

                                  onClick={() => setSortingBrandLogo(2)}
                                >
                                  All Brand
                                </DropdownItem>

                                <DropdownItem

                                  onClick={() => setSortingBrandLogo(1)}
                                >
                                  With Logo
                                </DropdownItem>
                                <DropdownItem

                                  onClick={() => setSortingBrandLogo(0)}
                                >
                                  Without logo
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                            {!sortBrandLogo && <>
                              <button class="btn btn-primary btn-sm pt-2  " type="button" onClick={() => downloadBrand()}><i class="fa fa-download" aria-hidden="true"></i></button>

                              <button class="btn btn-primary " type="button" onClick={() => showFileUpload()}>Import</button></>}


                            {/* <UncontrolledDropdown className="pl-3 mt-2 mb-2" group>
                <DropdownToggle caret className="p-2">
                  <h3
                    style={{ display: "inline-block" }}
                    className="font-weight-bold m-0"
                  >
                  
                   Brand Name {sort == 'asc' ? <i class="fas fa-sort-alpha-down"></i> : <i class="fas fa-sort-alpha-up"></i>}
                  </h3>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem

                    onClick={() => setSorting('asc')}
                  >
                    A-Z
                  </DropdownItem>
                  <DropdownItem

                    onClick={() => setSorting('desc')}
                  >
                    Z-A
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}


                            {/* <button  class="btn btn-primary " type="button" onClick={()=> addBrand()}>Import</button> */}



                          </Form>
                        </Col>

                        <Col className='text-right pt-2  auto'>
                           <button class="btn btn-primary" type="button" onClick={() => handleMergeBrand()}>{(isBrandLoaded)?'Merge Brand':'Loading..'}</button>
                          <button class="btn btn-primary" style={{ margin: 0 }} type="button" onClick={() => setDeletedBrand(prev => !prev)}>Show {deletedBrand ? 'Active' : 'Deleted'} Brands</button>
                          <button class="btn btn-primary float-right" type="button" onClick={() => addBrand()}>Add Brand</button>
                        </Col>


                      </Row>

                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      // pagination={pagination}
                      bordered={false}
                      hover={true}

                      rowClasses='pointer'
                    />

                    <TablePagination
                      component="div"
                      count={state.total}
                      page={page}
                      onChangePage={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </div>
                )}
              </ToolkitProvider>}
            </Card>
          </div>
        </Row>
      </Container>
      <Modal show={showMergeModal} onHide={handleCloseMerge}>
        <Modal.Header closeButton>
          <Modal.Title>Merge Brand</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
                          <div >
                            <p>Please select the brand</p>
                            <SelectAutocompleteWithList
                              id="brandidsearch"
                              label="search"
                              clearOnBlur
                              freeSolo
                              name="search"
                              options={checkForBrand}
                              placeholder="search"
                              disableCloseOnSelect
                              defaultValue={search ? search : null}
                              control={control}
                              input='search'
                              value={search ? search : null}
                              handleChangeSelect={handleCheckForBrand}
                              
                            />                         
                          </div>
                          <br/>
                          
                         {selectedBrand.length>0 && <div>
                            <p>Selected Brand</p>
                            <Paper
                                  sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    listStyle: 'none',
                                    p: 0.5,
                                    m: 0,
                                  }}
                                  component="ul"
                                >
                                  {selectedBrand.map((data) => {                                   
                                    return (
                                      <ListItem id={data.id}>
                                        <Chip
                                          label= {data.name}
                                          onDelete={handleSelectedDelete(data)}                                         
                                        />
                                      </ListItem>
                                    );
                                  })}
                             </Paper>
                          </div> }
                          
                          <br/>
                          
                          <div >
                           <p>Please select the target</p>
                            <SelectAutocompleteWithList
                              id="brandTarget"
                              label="search"
                              name="search"
                              clearOnBlur
                              freeSolo
                              options={checkForTargetBrand}
                              placeholder="search"
                              disableCloseOnSelect
                              defaultValue={search ? search : null}
                              control={control}
                              input='search'
                              value={searchTarget ? searchTarget : null}
                              handleChangeSelect={handleMergeBrandId}
                            />
                          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button class="btn btn-primary" type="button" onClick={submitFinalMergeData}>
            Final Merge
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default Brand;
const top100Films = [
  { name: 'Brand Name', id: 'brandName' },
  { name: 'Brand ID', id: 'id' }];