import React, { useState } from 'react';
import MaterialTable from 'material-table';
import { Button, Modal } from "reactstrap";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const ProductBulkEdit = (props) => {

    const { open, handleClose, handleSave, products, editType } = props;
    const [editFlag, setEditFlag] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    let selectedProduct = [];
    products.list.map(x => {
        selectedProduct.push(...x);
    })

    selectedProduct = selectedProduct.filter(x => products.ids.includes(x.productId));



    let data = [];

    let columns = [];

    if (editType.isApproval == '0')
        selectedProduct.map(x => {
            data.push({
                productId: x.productId, product: x.product,
                isactive: +x.isactive ? true : false,
                isApproved: +x.isApproved ? true : false,
                channelId: x.channelId,
                productImage: x.documentUrl,
                isPublic: +x.isPublic ? true : false,
                isFeatured: +x.isFeatured ? true : false, show_in_home_page: +x.show_in_home_page ? true : false,
                eco_friendly: +x.eco_friendly ? true : false, work_from_home: +x.work_from_home ? true : false,
                life_style: +x.life_style ? true : false, french_tech: +x.french_tech ? true : false,
                taiwan_excellence: +x.taiwan_excellence ? true : false
            });
        })
    else if (editType.isApproval == '1') {
        selectedProduct.map(x => {
            let obj = {
                productId: x.productId, product: x.product,
                isApproved: +x.isApproved ? true : false,
                isactive: +x.isactive ? true : false,
                channelId: x.channelId,
                productImage: x.documentUrl ? 'With Image' : 'Without Image',
                isPublic: +x.isPublic ? true : false,
                isFeatured: +x.isFeatured ? true : false, show_in_home_page: +x.show_in_home_page ? true : false,
                eco_friendly: +x.eco_friendly ? true : false, work_from_home: +x.work_from_home ? true : false,
                life_style: +x.life_style ? true : false, french_tech: +x.french_tech ? true : false,
                taiwan_excellence: +x.taiwan_excellence ? true : false
            }
            data.push(obj);
        })



        columns = [
            { title: 'Id', field: 'productId' },
            { title: 'Name', field: 'product' },
            { title: 'Approval', field: 'isApproved', type: 'boolean' },
            { title: 'Image', field: 'productImage', editable: 'never' },
            { title: 'Public', field: 'isPublic', type: 'boolean' },
            { title: 'Featured', field: 'isFeatured', type: 'boolean' },
            { title: 'HomePage', field: 'show_in_home_page', type: 'boolean' },
            { title: 'EcoFriendly', field: 'eco_friendly', type: 'boolean' },
            { title: 'WorkFromHome', field: 'work_from_home', type: 'boolean' },
            { title: 'LifeStyle', field: 'life_style', type: 'boolean' },
            { title: 'FrenchTech', field: 'french_tech', type: 'boolean' },
            { title: 'TaiwanExcellence', field: 'taiwan_excellence', type: 'boolean' }
        ]
    }



    const [state, setState] = useState({
        data: data
    });

    return (
        <div>
            <Modal className="modal-dialog-centered" id="popupedit" isOpen={open} size="xl">

                <div className="modal-header">
                    <strong>Product Bulk Edit</strong>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">
                    <MaterialTable
                        title={editType.isApproval == '1' ? <span style={{ color: "red" }}>Without images are not approved</span> : "Bulk Edit Preview"}
                        columns={editType.isApproval == '1' ? columns : [
                            { title: 'Id', field: 'productId' },
                            { title: 'Name', field: 'product' },
                            { title: 'Active', field: 'isactive', type: 'boolean' },
                            { title: 'Public', field: 'isPublic', type: 'boolean' },
                            { title: 'Featured', field: 'isFeatured', type: 'boolean' },
                            { title: 'HomePage', field: 'show_in_home_page', type: 'boolean' },
                            { title: 'EcoFriendly', field: 'eco_friendly', type: 'boolean' },
                            { title: 'WorkFromHome', field: 'work_from_home', type: 'boolean' },
                            { title: 'LifeStyle', field: 'life_style', type: 'boolean' },
                            { title: 'FrenchTech', field: 'french_tech', type: 'boolean' },
                            { title: 'TaiwanExcellence', field: 'taiwan_excellence', type: 'boolean' }
                        ]}
                        data={state.data}
                        editable={{
                            onBulkUpdate: (changes) =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        resolve();
                                        if (changes) {
                                            let newData = [];
                                            setEditFlag(true);
                                            Object.keys(changes).map((editData) => {
                                                setState((prevState) => {
                                                    const data = [...prevState.data];
                                                    data[data.indexOf(changes[editData]['oldData'])] =
                                                        changes[editData]['newData'];
                                                    newData = [...newData, changes[editData]['newData']];
                                                    return { ...prevState, data };
                                                });
                                            });
                                        }
                                    }, 1000);
                                }),
                            onRowDelete: (oldData) =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        resolve();
                                    }, 1000);
                                }),

                            // onRowUpdate: (newData, oldData) =>
                            //     new Promise((resolve, reject) => {
                            //         setTimeout(() => {
                            //             const dataUpdate = [...data];
                            //             const index = oldData.tableData.id;
                            //             dataUpdate[index] = newData;
                            //             setState([...dataUpdate]);

                            //             resolve();
                            //         }, 1000)
                            //     }),
                        }}
                    />
                </div>

                <div className="modal-footer">
                    {editFlag && <Button color="primary" type="button" onClick={() => handleSave(state.data)}>Save</Button>}
                </div>

            </Modal >
        </div >
    );
}

export default ProductBulkEdit;