import React, { useState, useEffect, Component } from "react";
import { useBeforeFirstRender } from '_hooks/index'
import PdfViewer from './PdfViewer';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import axios from 'axios';
import { Construction } from "@mui/icons-material";
var _ = require('lodash');

const PdfCard = props => {

  let pdfList = props.pdfList;
  const [delPdf, setDelete] = useState('0');

  useEffect(() => {
    if (delPdf !== '0') {
      props.deletePdf(delPdf);
      console.log("deleteValue:", pdfList);
      //setBrand(filteredList);    
    }
  }, [delPdf])

  const deletePdf = item => {
    console.log('id', item);
    setDelete(item);
  }

  const checkFile = (url) => {
    console.log('url.split', url.split(/[#?]/)[0].split('.').pop().trim());
    if (url) {
      return (
        <>
          {url.split(/[#?]/)[0].split('.').pop().trim() === 'pdf' ?
            <PdfViewer edit={false} pdf={url} /> :
            <img className='Brandimg' src={url} />
          }
        </>
      )
    } else {
      return (
        <img className='Brandimg' src="http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg" />
      )
    }
  }
  return (

    <div className="brand mb-3">
      <div className="scroll">

        {pdfList.map((item) => (
          <div className="brandCard">
            <button onClick={e => { e.stopPropagation(); deletePdf(item) }} className="xbtn" type="button"><svg style={{ borderRadius: "50%", background: "#f8f8f8" }} className="BrandCloseSvg" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path></svg></button>
            <div className="imgContiner" style={{ overflow: 'hidden' }}>
              {/* <PdfViewer edit={false} pdf={item.document.documentUrl} /> */}
              {checkFile(item.document.documentUrl)}
            </div>
            <div style={{ width: '8rem', textOverflow: 'ellipsis' }} className="BrandName">{item.name}
              <i class="fas fa-pencil-alt edit-icon" onClick={e => { e.stopPropagation(); props.editName(item) }}></i>
            </div>
          </div>
        ))}

      </div>
    </div>
  );
}

const ImageCard = props => {

  let { list, deleteImg } = props;

  return (

    <div className="brand mb-3">
      <div className="scroll">

        {list.map((item) => (
          <div className="brandCard">
            <button onClick={e => { e.stopPropagation(); deleteImg(item) }} className="xbtn" type="button"><svg style={{ borderRadius: "50%", background: "#f8f8f8" }} className="BrandCloseSvg" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path></svg></button>
            <div className="imgContiner" style={{ overflow: 'hidden' }}>
              <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="img-fluid" alt="..." src={item.documentUrl} />
            </div>
            <div style={{ width: '8rem', textOverflow: 'ellipsis' }} className="BrandName">{item.name}</div>
          </div>
        ))}

      </div>
    </div>
  );
}

const ReorderImages = (props) => {
  const {deleteImg,images} = props;

  const [state,setState] = useState({
    images:_.sortBy(props.images,"sortorder"),
    deleteImg:deleteImg
  })
  let dragId='';
  // constructor(props) {
  //   console.log("posimage:",props.images)
  //   super();
  //   const images = _.sortBy(props.images,"sortorder");
  //   const { deleteImg } = props;   

  //   this.state = {
  //     images,   
  //     deleteImg   
  //   };
  //   this.dragId = '';    
  // }

  const imageDragOver = ev => ev.preventDefault();

  const imageDragStart = ev => {
   
    dragId = ev.target.id
  };

  const dropImage = ev => {
    ev.preventDefault();
    const dragElement = dragId.split('-');
    let dragIndex = '';
    if (dragElement.length > 1) {
      dragIndex = dragElement[0];
    }

    const dropElement = ev.target.id.split('-');
    let dropIndex = '';
    if (dropElement.length > 1) {
      dropIndex = dropElement[0];
    }

    if (dragIndex !== '' && dropIndex !== '') {
      const { images } = state;
      const dragObject = images[dragIndex];
      images.splice(dragIndex, 1);
      images.splice(dropIndex, 0, dragObject);
      setState({ images });
      props.callback(images);
    }
  };



  // render() {
  //   const { images,deleteImg } = this.state;
    return (
      <div className="brand mb-3">
        <div className="scroll">
          {images &&
            images.length > 0 &&
            images.map((img, index) => {
              console.log("docurl:", img)
              return (

                <div className="brandCard"
                  // className='col-lg-2 col-md-3 col-sm-6'
                  // style={{ padding: '20px' }}
                  key={index}
                  id={`${index}-div`}
                  onDrop={dropImage}
                  onDragOver={imageDragOver}
                >

                  <button onClick={e => { e.stopPropagation(); deleteImg(img) }} className="xbtn" type="button"><svg style={{ borderRadius: "50%", background: "#f8f8f8" }} className="BrandCloseSvg" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path></svg></button>
                  <div className="imgContiner" style={{ overflow: 'hidden' }}>
                    <img
                      id={`${index}-img`}
                      draggable={true}
                      onDragStart={imageDragStart}
                      style={{ width: '150px', height: '150px' }}
                      alt='100%x190'
                      src={img.documentUrl ? img.documentUrl : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
                      data-holder-rendered='true'
                    />
                  </div>
                  <div style={{ width: '8rem', textOverflow: 'ellipsis' }} className="BrandName">{img.name}</div>

                  {/* <span>{img.caption}</span> */}
                </div>
              );
            })}
        </div>
      </div>
    );
  // }
}



export {
  PdfCard,
  ImageCard,
  ReorderImages
} 
