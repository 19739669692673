import React, { useState, useEffect, useRef } from 'react';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Card, CardBody, CardHeader, Row, Col, CardImg, Container, Button, UncontrolledTooltip } from "reactstrap";
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import { Badge } from "reactstrap";
import { Categories, ExpandString, Profile } from "views/Hoc/text-tags/TextTags";
import { KeyBrands, BrandListing, ChannelProducts } from "views/Hoc/keyBrands/KeyBrands";
import Channelpiclist from 'views/Hoc/Companypiclist'
import { ContactListing } from "views/Hoc/contact-list/contactList";
import { OfficialDocument } from "views/Hoc/officialDocuments/officialDocuments";
import NotificationAlert from "react-notification-alert";
import VideoPreview from 'views/Hoc/Dialog/VideoPreview';
import CountryFlag from 'views/Hoc/countryFlag/countryFlag';
import Awardlist from 'views/Hoc/Dialog/Awardlist';
import B2blink from 'views/Hoc/Dialog/B2blink';
import Moment from 'moment';
// import CompanyDrawerEdit from "views/pages/Channel-Listing/Globaleditdrawer";
import ModalImageCropper from 'views/Hoc/ModalImageCropper';
import { ToastsContainer, ToastsStore } from 'react-toasts';
// import FavouritiesChannelDetail from 'views/Hoc/Dialog/FavouritiesChannelDetail'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { history } from '../../../_helpers/history';
import { channelAction } from '_actions/channel.action';
import { connect, useSelector } from 'react-redux';
import axios from 'axios';
import Autherizedown from 'views/Hoc/Autherized-own';
const ChannelDetail = (props) => {
  console.log('topsearch', props.match.params.topsearch);
  let [loading, setLoading] = useState(true);
  let [id, setId] = useState(props.match.params.id ? props.match.params.id : props.match.params.channelId);
  const [image, setImage] = useState('');
  let [channelDetails, setChannelDetails] = useState(null);
  let [contactList, setContactList] = useState([]);
  let [brandList, setBrandList] = useState([]);
  let [productList, setProductList] = useState([]);
  let [similarProductList, setSimilarProductList] = useState([]);
  let userData = JSON.parse(localStorage.getItem('CHuser'));
  const [prod_show, more] = useState(false);
  let [isVideo, setVideoOpen] = useState(false);
  let [videoUrl, setVideoUrl] = useState(null);
  let [awardPopupOpen, setAwardPopupOpen] = useState(false);
  let [b2bPopupOpen, setb2bPopupOpen] = useState(false);
  const notificationAlert = useRef(null);
  const [imageSrc, setImageSrc] = useState('');
  const [showDrawer, setshowDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState(null);
  let [channelBasicInfo, setChannelBasicInfo] = useState({
    channelB2B: [],
    channelLogo: null,
    address: null,
    city: null,
    companyName: null,
    aboutUs: null,
    country: null,
    isoCode: null,
    phoneno: null,
    webSiteUrl: null,
    introvideo: null,
    socialLinks: [],

    linkedIn_pre: 'https://www.linkedin.com/company/',
    insta_pre: 'https://www.instagram.com/',
    facebook_pre: 'https://www.facebook.com/',
    twitter_pre: 'https://twitter.com/',

  })
  let [tradeInformation, setTradeInformation] = useState({
    productCategories: [],
    speciality: [],
    ChannelProfileValue: [],
    RetailerProfileValue: [],
    ResellerProfileValue: [],
    DistributorProfileValue: [],
    profileType: null
  });
  let [factInfo, setFactInfo] = useState({
    empCnt: null,
    empCntRange: null,
    estYear: null,
    no_of_skus: null,
    startup: null,
    storeCount: null,
    turnover: null,
    turnoverRange: null,
  });
  let [switchValue, setSwitchValue] = useState(0);
  const redux_data = useSelector(state => state.channelListing);
  let Channel_List = redux_data?.channel;

  let [businessType, setBusinessType] = useState(null);

  //redirecting route for decision making
  let to = props.location?.pathname?.split('/')[2] ? props.location?.pathname?.split('/')[2] : 'channel';
  //console.log('pathnames', props.location.pathname.split('/'))

  useEffect(() => {
    id = props.match.params.id ? props.match.params.id : props.match.params.channelId;
    setId(id);
    if (userData.channelId !== id && !props.match.params.topsearch) {
      switchValue = Channel_List?.indexOf(+id);
      setSwitchValue(switchValue);
    }
    // switchValue = switchValue ? switchValue : 9999;
    // setSwitchValue(switchValue);
    setLoading(true);
    getChannelDetails(id);

  }, [props.location.pathname]);

  const closeDrawer = () => {
    setshowDrawer(false);
  }

  const nextData = async () => {
    let prev = switchValue;
    switchValue = switchValue != Channel_List?.length - 1 ? switchValue + 1 : switchValue;
    if (switchValue != Channel_List?.length - 1 || prev == Channel_List?.length - 2) {
      //console.log("switch:", switchValue, Channel_List[switchValue]);
      setLoading(true);
      getChannelDetails(Channel_List[switchValue]);
      //setId(Channel_List[switchValue]);
      window.history.replaceState({}, null, '/admin/' + to + '/channelDetail/' + Channel_List[switchValue]);

    }
    setSwitchValue(switchValue);
    //console.log("switchValue:", switchValue, Channel_List[switchValue]);
  }

  const prevData = async () => {
    let prev = switchValue;
    switchValue = switchValue != 0 ? switchValue - 1 : switchValue;
    if (switchValue != 0 || prev == 1) {
      //console.log("switch:", switchValue, Channel_List[switchValue]);
      setLoading(true);
      getChannelDetails(Channel_List[switchValue]);
      //setId(Channel_List[switchValue]);
      window.history.replaceState({}, null, '/admin/' + to + '/channelDetail/' + Channel_List[switchValue]);
    }
    setSwitchValue(switchValue);
    //console.log("switchValue:", switchValue, Channel_List[switchValue]);
  }

  const getChannelDetails = async (id) => {

    let res = await commonService.getServices('channelAdmin/channelDetail/' + id).catch(err => console.log('err', err));
    if (res) {
      channelDetails = res.data;
      //business type 
      setBusinessType(channelDetails.isOEM)
      setId(id);
      setChannelDetails(channelDetails);

      if (channelDetails.channelTypeId.toString() !== '4')
        getChannelBrand(id);
      if (channelDetails.channelTypeId.toString() === '2') {
        getChannelProduct(id);
        // if (parseInt(userData.channelTypeId) !== 2)
        //   setSimilarProduct(id);
      }
      getChannelContact(id);


      channelBasicInfo.channelB2B = channelDetails?.channelB2B ? channelDetails?.channelB2B : [];
      channelBasicInfo.channelLogo = channelDetails?.channelLogo;
      channelBasicInfo.address = channelDetails?.regAddress?.address
      channelBasicInfo.city = channelDetails?.regAddress?.city;
      channelBasicInfo.companyName = channelDetails?.companyName;
      channelBasicInfo.aboutUs = channelDetails?.aboutUs;
      channelBasicInfo.country = channelDetails?.country;
      channelBasicInfo.isoCode = channelDetails?.isoCode;
      channelBasicInfo.zip = channelDetails?.regAddress?.postalCode;
      // channelBasicInfo.phoneno=channelDetails?.phoneno;
      //text formatting for phone number
      channelBasicInfo.phoneno = channelDetails?.phoneno ? channelDetails?.phoneno.split('-').length > 1 ?
        channelDetails.phoneno : (channelDetails.phoneCode.substr(1,) == channelDetails.phoneno.substr(0, channelDetails.phoneCode.substr(1,).length)) ?
          channelDetails.phoneCode + '-' + channelDetails.phoneno.slice(channelDetails.phoneCode.substr(1,).length,) : channelDetails.phoneCode + '-' + channelDetails.phoneno : '';
      channelBasicInfo.webSiteUrl = channelDetails?.webSiteUrl;
      channelBasicInfo.introvideo = channelDetails?.introvideo;
      channelBasicInfo.socialLinks = channelDetails?.socialLinks;
      setChannelBasicInfo({ ...channelBasicInfo });
      setImageSrc(channelDetails?.channelBanner);

      tradeInformation.productCategories = channelDetails?.tradeInformation?.userProductCategories;
      tradeInformation.speciality = channelDetails.channelTypeId.toString() === '3' ? channelDetails?.tradeInformation?.speciality : [];


      tradeInformation.ChannelProfileValue = channelDetails?.tradeInformation?.customerProfile;
      tradeInformation.RetailerProfileValue = channelDetails?.tradeInformation?.retailerProfile;
      tradeInformation.ResellerProfileValue = channelDetails?.tradeInformation?.resellerProfile;

      tradeInformation.profileType = channelDetails.profileType;
      setTradeInformation({ ...tradeInformation });

      factInfo.empCnt = channelDetails?.empCnt;
      factInfo.empCntRange = channelDetails?.empCntRange;
      factInfo.estYear = channelDetails?.estYear;
      factInfo.no_of_skus = channelDetails?.no_of_skus;
      factInfo.startup = channelDetails?.startup;
      factInfo.storeCount = channelDetails?.storeCount;
      factInfo.turnover = channelDetails?.turnover;
      factInfo.turnoverRange = channelDetails?.turnoverRange;

      setFactInfo({ ...factInfo });


      setLoading(false)
    }
  }
  const getChannelContact = async (id) => {
    setLoading(true);
    contactList = [];
    setContactList(contactList);
    let res = await commonService.postService('', 'channel/channelContacts/' + id).catch(err => console.log('err', err));
    if (res) {
      contactList = res.data;
      setContactList(contactList);
    }
    setLoading(false);
  }
  const getChannelBrand = async (id) => {
    let res = await commonService.postService('', 'channel/channelBrands/' + id).catch(err => console.log('err', err));
    if (res) {
      brandList = res.data;
      setBrandList(brandList);
    } else {
      brandList = [];
      setBrandList(brandList);
    }
  }
  const getChannelProduct = async (id) => {
    let res = await commonService.postService('', 'product/channel-products/' + id).catch(err => console.log('err', err));
    if (res) {
      productList = res.data;
      setProductList(productList);
    } else {
      productList = [];
      setProductList(productList);
    }
  }
  const setSimilarProduct = async (id) => {
    // let res = await commonService.getServices('product/channel-similarproducts/' + id).catch(err => console.log('err', err));
    // if (res) {
    //   similarProductList = res.data;
    //   setSimilarProductList(similarProductList);
    // } else {
    //   similarProductList = [];
    //   setSimilarProductList(similarProductList);
    // }
  }
  const previewVideo = (data) => {
    videoUrl = data;
    setVideoUrl(videoUrl);
    setVideoOpen(true);
  }
  const videoModalClose = () => {
    setVideoOpen(false);
  };
  const notify = (type, message) => {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {/* {" "}
                {type} */}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      // icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };
  const closeAwardPopUp = () => {
    setAwardPopupOpen(false);
    setb2bPopupOpen(false);
  }
  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const selectImg = async (data) => {
    if (data) {
      setImage('');
      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + 'banner');
      console.log('resImagePath', resImagePath);
      if (resImagePath) {
        const path = await commonService.postService({ bannerUrl: resImagePath.data.url }, '/setting/updateBannerImage/');
        if (path) {
          setImageSrc(path?.data?.documentUrl);
          ToastsStore.success('Banner image updated!');
        } else {
          ToastsStore.error('Something Went Wrong!');
        }
        setLoading(false);
      }

    }

  }
  const handleChangeBanner = event => {
    const data = event.target.files[0];
    console.log('files', data)

    if (data && data['type'].split('/')[0] === 'image') {
      console.log('datat', data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log('file', file)
        console.log('reader.result', reader.result)
        setImage(
          reader.result
        );
        console.log('image', image)
        //setOpen(false);          
      };

    }
  };
  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  const changedData = (data, id) => {
    console.log(data);
    console.log(id);
    setLoading(true);
    if (id === 1) {
      channelBasicInfo.channelB2B = data?.B2B_url ? data?.B2B_url : [];
      channelBasicInfo.channelLogo = data.company_logo;
      channelBasicInfo.address = data.reg_address
      channelBasicInfo.city = data.reg_reg_city;
      channelBasicInfo.companyName = data.company_name;
      channelBasicInfo.aboutUs = data.company_about;
      channelBasicInfo.country = data.country;
      channelBasicInfo.isoCode = data.isoCode;
      channelBasicInfo.phoneno = data.company_phone1;
      channelBasicInfo.webSiteUrl = data.company_website_url;
      channelBasicInfo.introvideo = data.introvideo;
      channelBasicInfo.socialLinks = [];
      if (data?.social_instagram_url)
        channelBasicInfo.socialLinks.push({ social: 'Instagram', link: data.social_instagram_url });
      if (data?.social_linkedin_url)
        channelBasicInfo.socialLinks.push({ social: 'Linkedin', link: data.social_linkedin_url });
      if (data?.social_twitter_url)
        channelBasicInfo.socialLinks.push({ social: 'Twitter', link: data.social_twitter_url });
      if (data?.social_facebook_url)
        channelBasicInfo.socialLinks.push({ social: 'Facebook', link: data.social_facebook_url });
      setChannelBasicInfo({ ...channelBasicInfo });
    } else if (id === 2) {
      factInfo.empCnt = data?.employeesCount;
      factInfo.empCntRange = data?.employeesRange;
      factInfo.estYear = data?.year_of_estabilishment;
      factInfo.no_of_skus = data?.no_of_skus;
      factInfo.startup = data?.startup;
      factInfo.storeCount = data?.storeCount;
      factInfo.turnover = data.annualTurnover;
      factInfo.turnoverRange = data?.annualTurnoverRange;
      setFactInfo({ ...factInfo });
    } else if (id === 3) {
      brandList = data.brand;
      setBrandList({ channelBrand: brandList });
      tradeInformation.productCategories = data.productValue;
      tradeInformation.speciality = data.speciality;
      tradeInformation.ChannelProfileValue = data.ChannelProfileValue;
      tradeInformation.DistributorProfileValue = data.DistributorProfileValue;
      tradeInformation.profileType = data.profileType;
      setTradeInformation({ ...tradeInformation });
    }
    setLoading(false);
  }
  const setDrawerId = (data) => {
    setDrawerType(data);
    setshowDrawer(true);
  }
  const [play, setPlay] = useState(true);

  const [mute, setMute] = useState(true);

  const unmute = () => {
    console.log('muted', mute)
    setMute(prev => !prev);
  }

  const playVideo = () => {
    var myVideo = document.getElementById('myVideo');

    if (myVideo) {
      if (play)
        myVideo.play();
      else
        myVideo.pause();
    }

  }

  useEffect(() => {
    playVideo();
  }, [play])


  return (
    <div id='channel-detail' className="main-content">
      <NotificationAlert ref={notificationAlert} />
      {/* <VideoPreview videoModalClose={videoModalClose} isOpen={isVideo} url={videoUrl}/> */}
      <SimpleHeader location={props.match} />

      <Container className="mt-4" fluid>
        {loading ? <Spinner /> : <>
          <B2blink open={b2bPopupOpen} handleClose={closeAwardPopUp} value={channelBasicInfo?.channelB2B} />
          <Awardlist open={awardPopupOpen} handleClose={closeAwardPopUp} value={channelDetails?.awards} />
          {/* <CompanyDrawerEdit title="Company Edit" visible={showDrawer} closeDrawer={closeDrawer} changedData={changedData} type={drawerType} /> */}
          <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
          <Row >
            <Col className="card-wrapper" lg="12" md="12" sm="12" >
              {/* {userData.channelId===id && <i class="fas fa-pencil-alt ch-edit" onClick={handleClick} style={{cursor:"pointer"}}></i>} */}
              <Card >
                {/* {(userData.channelId !== id && !props.match.params.topsearch) && <> <div className='left-arrow'>
                  <i class="fas fa-angle-left" onClick={prevData}></i>
                </div>
                  <div className='right-arrow'>
                    <i class="fas fa-angle-right" onClick={nextData}></i>
                  </div></>} */}
                <CardImg
                  alt="..."
                  style={{ height: "250px", objectFit: "cover" }}
                  src={imageSrc ? imageSrc : require("assets/img/channelDetail/Default Banner.png")}
                  top
                />
                <input type="file"
                  ref={hiddenFileInput}
                  accept="image/*"
                  onChange={handleChangeBanner}
                  style={{ display: 'none' }}
                />
                {image ? <ModalImageCropper isOpen={true} src={image} handleChange={selectImg} handleCropClose={() => setImage('')} type="banner" /> : <></>}
                {/* {userData.channelId!==id &&<div className='tet'> */}
                {/* <i class="curs-point" onClick={()=>console.log('clicked')}></i> */}
                {/* <FavouritiesChannelDetail channelTypeId={channelDetails.channeltypeid} channelId={channelDetails.channelId} isFavorite={channelDetails.favorite}/> */}
                {/* </div>} */}
                {/* {userData.channelId === id && <div className='tet curs-point' onClick={handleClick}>
                  <i class="fas fa-camera"></i>
                </div>} */}
                {(channelDetails.channelTypeId != 2 || channelDetails?.subscriptionPlan) && <div className="plan-badge">
                  <Row>
                    <Col lg="9" className="pad-less c-1 col-9">
                      <div className="plan-txt">
                        <h3>{parseInt(channelDetails.channelTypeId) !== 2 ? 'Member since ' + Moment(channelDetails?.createdAt).format('Y') : channelDetails?.subscriptionPlan}</h3>
                      </div>
                    </Col>
                    <Col lg="3" className="pad-less c-1 col">
                      <div className="plan-txt1"><i class="fas fa-bookmark"><i class="fas fa-star str-i"></i></i></div>
                    </Col>
                  </Row>
                </div>}
                {/* {userData.channelId !== id && <div className='cnect-brnd'>
                  <FavouritiesChannelDetail channelTypeId={channelDetails.channelTypeId} channelId={channelDetails.channelId} isFavorite={channelDetails.favorite} />
                  {channelDetails?.channelTypeId === '2' &&
                    <AnchorLink className='btn butn-f pt-2 mres2' href='#connectWith'>Connect with this brand</AnchorLink>
                  }
                </div>} */}
              </Card>
            </Col>
          </Row>
          <div className='mt--6'>
            <Card className='rounded-0'>

              <CardBody className='pb-6'>
                {/* {userData.channelId === id && <i class="fas fa-pencil-alt ch-edit" onClick={() => setDrawerId('1')} style={{ cursor: "pointer" }}></i>} */}

                <Row className="align-items-center">
                  <Col className="col-lg-auto col-md-auto col-sm-12 complog">
                    <a
                      style={{ width: "150px", height: "150px", padding: "5px", border: "1px solid #b8b8b8", backgroundColor: "white" }}
                      className="avatar avatar-xl mb-2"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }}
                        alt="..."
                        src={channelBasicInfo?.channelLogo ? channelBasicInfo?.channelLogo : "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}
                      />
                    </a>
                  </Col>
                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <h2 className='text-uppercase mt-6'>{channelBasicInfo?.companyName}</h2>


                    <p className='text-uppercase mb-1'>since {channelDetails?.estYear} </p>
                    <div className='badges mb-3'>
                      <Badge>
                        {channelDetails?.channelType}
                      </Badge>
                      {/* <span className='text-uppercase ml-3 mr-2'>{channelDetails?.channelTypeId ==='2' ?"Are you Startup ?":"Looking For Startup ?"}</span>
                          <Badge className='outer_badge1'>
                          {parseInt(factInfo?.startup)===1?'Yes':'No'}
                          </Badge> */}
                      {/* {parseInt(factInfo?.startup)===1 && <> 
                            <Badge className='outer_badge1'>
                          {channelDetails?.channelTypeId ==='2'?'Startup':'Looking Startup'}
                          </Badge></>} */}
                    </div>

                    <div className='mb-3'>
                      {/* <Badge>
                            {channelDetails?.channelType}
                          </Badge> */}
                      <Badge className='outer_badge'>
                        {channelBasicInfo?.country}
                      </Badge>
                    </div>
                    <div className="mb-3">
                      {channelBasicInfo?.socialLinks?.map(x => {
                        return (<>
                          {/* x.social==='Googleplus' ||  */}
                          {(x.social === 'Twitter') && <a onClick={() => {
                            let url = x.social === 'Twitter' ? channelBasicInfo.twitter_pre + x.link.replace(/\/$/, "").split('/').splice(-1, 1) : x.link;
                            if (!url.match(/^https?:\/\//i) && !url.match(/^http?:\/\//i)) {
                              url = '//' + url;
                            }
                            window.open(url, '_blank')
                          }}>
                            <Button className="btn-icon-only mr-2" color="twitter" type="button">
                              <span className="btn-inner--icon">
                                <i className="fab fa-twitter" />
                              </span>
                            </Button>
                          </a>}
                          {(x.social === 'Linkedin' && x.link) ? <a onClick={() => {
                            let url = channelBasicInfo.linkedIn_pre + x.link.replace(/\/$/, "").split('/').splice(-1, 1);
                            if (!url.match(/^https?:\/\//i) && !url.match(/^http?:\/\//i)) {
                              url = '//' + url;
                            }
                            window.open(url, '_blank')
                          }}>
                            <Button className="btn-icon-only mr-2 i-link" color="twitter" type="button">
                              <span className="btn-inner--icon">
                                <i className="fab fa-linkedin" />
                              </span>
                            </Button>
                          </a>
                            :
                            <>
                              {(x.social !== 'Twitter' && x.social !== 'Instagram' && x.social !== 'Twitter' && x.social === 'Linkedin') && <a data-placement="top" id={'linked-in' + id} onClick={() => {
                                // let url = y.contactSocialLink;
                                // if (!url.match(/^https?:\/\//i) && !url.match(/^http?:\/\//i)) {
                                //   url = '//' + url;
                                // }
                                // window.open(url, '_blank')
                              }}>
                                <Button className="btn-icon-only mr-2 i-linkDisabled" type="button">
                                  <span className="btn-inner--icon">
                                    <i className="fab fa-linkedin" />
                                  </span>
                                </Button>
                                <UncontrolledTooltip target={'linked-in' + id} placement='bottom'>
                                  {userData?.channelId == id ? 'Update your LinkedIn url for better profile' : 'LinkedIn url not available for this channel.'}
                                </UncontrolledTooltip>
                              </a>}
                            </>
                          }
                          {x.social === 'Instagram' && <a onClick={() => {
                            let url = channelBasicInfo.insta_pre + x.link.replace(/\/$/, "").split('/').splice(-1, 1);
                            if (!url.match(/^https?:\/\//i) && !url.match(/^http?:\/\//i)) {
                              url = '//' + url;
                            }
                            window.open(url, '_blank')
                          }}>
                            <Button className="btn-icon-only mr-2 i-insta" color="instagram" type="button">
                              <span className="btn-inner--icon">
                                <i className="fab fa-instagram"></i>
                              </span>
                            </Button>
                          </a>}
                        </>
                        )
                      })}


                      {/* {channelBasicInfo?.channelB2B?.length > 0 && <Button onClick={() => setb2bPopupOpen(true)} className="btn-icon-only mr-2" color="primary" type="button">
                        <span className="btn-inner--icon">
                          <i class="fas fa-external-link-alt"></i>
                        </span>
                      </Button>} */}

                      {/* <a target="_blank" href=""> */}
                      {channelDetails?.awards?.length > 0 && <Button onClick={() => setAwardPopupOpen(true)} className="btn-icon-only mr-2" color="warning" type="button">
                        <span className="btn-inner--icon">
                          <i class="fas fa-trophy"></i>
                        </span>
                      </Button>}
                      {/* </a> */}

                      <Badge style={{ color: 'white', backgroundColor: '#adb5bd' }} className='text-capitalize'>
                        Share
                      </Badge>
                    </div>
                  </div>

                  <div className="col-lg-5 col-md-auto col-sm-12 ml--3 p-0">

                    <p className='mb-1 font-weight-700'>About us </p>
                    <Row><p className="w-100"> {channelBasicInfo?.aboutUs?.replace(/<[^>]+>/g, '')} </p></Row>
                    <Row><Col lg="8" className="pad-less">
                      <h4 style={{ color: '#b8b8b8' }}>Address</h4>
                      <Row><p> {channelBasicInfo?.address}
                      <br/>
                      {channelBasicInfo?.zip} {channelBasicInfo?.city}
                      <br/>
                      {channelBasicInfo?.country}
                     </p> </Row>
                      </Col>
                      <Col lg="4">
                        <h4 style={{ color: '#b8b8b8' }}>Phone Number</h4>
                        <Row><p className='mt1-5'><CountryFlag code={channelBasicInfo.isoCode} /></p> <p className='ml-2 res-ph'> {channelBasicInfo?.phoneno} </p></Row></Col>
                    </Row>
                    <Row><h4 style={{ color: '#b8b8b8' }}>Web Site</h4></Row>
                    <Row><p className='mb-1'><a href=''
                      onClick={(e) => {
                        e.preventDefault();
                        let url = channelBasicInfo?.webSiteUrl;
                        if (!url.match(/^https?:\/\//i)) {
                          if (!url.match(/^http?:\/\//i))
                            url = '//' + url;
                        }
                        window.open(url, '_blank')
                      }} className="">{channelDetails?.webSiteUrl?.replace(/^\W+/, '')} </a></p></Row>

                    {!!channelBasicInfo?.channelB2B?.length && channelBasicInfo?.channelB2B.slice(0, 2).map(x =>
                      <p className='mb-1'><a href=''
                        onClick={(e) => {
                          e.preventDefault();
                          let url = x?.channelB2B;
                          if (!url.match(/^https?:\/\//i)) {
                            if (!url.match(/^http?:\/\//i))
                              url = '//' + url;
                          }
                          window.open(url, '_blank')
                        }} className="">{x?.channelB2B?.replace(/^\W+/, '')} </a></p>

                    )}

                    {channelBasicInfo?.channelB2B?.length > 2 && <h4 onClick={() => setb2bPopupOpen(true)} className=" mr-2" color="primary">
                      <span>
                        {/*<span className="btn-inner--icon"> <i class="fas fa-external-link-alt"></i> */}
                        View More
                      </span>
                    </h4>}

                  </div>
                  {channelBasicInfo?.introvideo && <><div style={{ flex: '0 0 2.66667%' }} className="col-lg-3 col-md-2 col-sm-12 text-center mr--4">
                    {/* <h3>Watch Our Introduction Video</h3> */}
                  </div>
                    <div className="col-lg-2 col-md-auto col-sm-12">
                      {/* onClick={()=>previewVideo(channelBasicInfo?.introvideo)} */}
                      {/* {!play && <i style={{ fontSize: '3rem', position: 'absolute', top: '30%', left: '42%', color: '#FF6347' }} class="fa fa-play-circle" aria-hidden="true" onClick={() => { setPlay(true); }} ></i>} */}

                      <video id="myVideo" controls src={channelBasicInfo?.introvideo} style={{ width: '260px', height: '150px', border: '1px solid blue' }}
                        autoPlay={play} muted={mute} onClick={() => setPlay(prev => !prev)}
                      >
                      </video>
                      {/* <button id="unmuteBtn" onClick={unmute}>
                        <i class={mute ? 'fas fa-volume-mute mte' : 'fas fa-volume-up unmte'}></i>
                      </button> */}
                    </div></>}


                </Row>

              </CardBody>


            </Card>
          </div>
          {<Card >
            <CardHeader className="p-3">
              <h3 className="ml-2 mb-4">Facts & Figures
                {/* {userData.channelId === id && <i class="fas fa-pencil-alt ch-edit" onClick={() => setDrawerId('2')} style={{ cursor: "pointer" }}></i>} */}
              </h3>
              <Row>
                <Col>
                  <Row>
                    <Col lg='3' className='pad-less'>
                      <div className='fact-icon'>
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require("assets/img/icons/establish-icon-png.png")} />
                      </div>
                    </Col>
                    <Col lg='9' className='pr-0'>
                      <h1 className="mb-0 fa-s">{factInfo?.estYear != null ? new Date().getFullYear() + "-" + parseInt(factInfo.estYear) : new Date().getFullYear() + "-" + new Date().getFullYear()}</h1>
                      <h5 className="m-0 text-uppercase text-muted">Years In Business</h5>
                    </Col>
                  </Row>
                </Col>

                <Col>
                  <Row>
                    <Col lg='3' className='pad-less'>
                      <div className='fact-icon'>
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require("assets/img/icons/turnover.png")} />
                      </div>
                    </Col>
                    <Col lg='9' className='pr-0'>
                      <h1 className="mb-0 fa-s">{factInfo?.turnoverRange ? factInfo?.turnoverRange : factInfo?.turnover != null ? factInfo?.turnover + ' M€' : ''}</h1>
                      <h5 className="m-0 text-uppercase text-muted">Annual Turnover</h5>
                    </Col>
                  </Row>
                </Col>

                <Col>
                  <Row>
                    <Col lg='3' className='pad-less'>
                      <div className='fact-icon'>
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require("assets/img/icons/employess-icon.png")} />
                      </div>
                    </Col>
                    <Col lg='9' className='pr-0'>
                      <h1 className="mb-0 fa-s">{factInfo?.empCntRange ? factInfo?.empCntRange : factInfo?.empCnt}</h1>
                      <h5 className="m-0 text-uppercase text-muted">No. Of Employees</h5>
                    </Col>
                  </Row>
                </Col>
                {/* {parseInt(channelDetails?.channelTypeId) !==3 && <Col>
                    <Row>
                      <Col lg='3' className='pad-less'>
                        <div className='fact-icon'>
                        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={require("assets/img/icons/startupru.png")}/>
                        </div>
                      </Col>
                      <Col lg='9' className='pr-0'>
                      <h1 className="mb-0 fa-s"><Badge className='outer_badge'>{channelDetails?.channelType}</Badge></h1>
                      <h5 className="m-0 text-uppercase text-muted">{channelBasicInfo.country}</h5>
                      </Col>
                    </Row>
                    </Col>} */}
                {/* <Col>
                    <Row>
                      <Col lg='3' className='pad-less'>
                        <div className='fact-icon'>
                        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={require("assets/img/icons/lookstart.png")}/>
                        </div>
                      </Col>
                      <Col lg='9' className='pad-less'>
                      <h1 className="mb-0 fa-s">{ channelDetails?.no_of_skus }</h1>
                      <h5 className="m-0 text-uppercase text-muted">Looking For Startup ?</h5>
                      </Col>
                    </Row>
                    </Col>
                    */}

                {parseInt(channelDetails?.channelTypeId) === 2 && <Col>
                  <Row>
                    <Col lg='3' className='pad-less'>
                      <div className='fact-icon'>
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require("assets/img/icons/SKU.png")} />
                      </div>
                    </Col>
                    <Col lg='9' className='pr-0'>
                      <h1 className="mb-0 fa-s">{factInfo?.no_of_skus}</h1>
                      <h5 className="m-0 text-uppercase text-muted">{channelDetails?.channelTypeId === '2' ? "Total No of Sku" : "No of Sku"}</h5>
                    </Col>
                  </Row>
                </Col>}



                {parseInt(channelDetails?.channelTypeId) === 2 && <Col>
                  <Row>
                    <Col lg='3' className='pad-less'>
                      <div className='fact-icon'>
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require("assets/img/icons/distributor1.png")} />
                      </div>
                    </Col>
                    <Col lg='9' className='pr-0'>
                      <h1 className="mb-0 fa-s">{channelDetails?.no_of_distributors}</h1>
                      <h5 className="m-0 text-uppercase text-muted">No of Distribution Partners</h5>
                    </Col>
                  </Row>
                </Col>}

                {channelDetails?.channelTypeId === '4' && <Col>
                  <Row>
                    <Col lg='3' className='pad-less'>
                      <div className='fact-icon'>
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require("assets/img/icons/store.png")} />
                      </div>
                    </Col>
                    <Col lg='9' className='pr-0'>
                      <h1 className="mb-0 fa-s">{factInfo?.storeCount}</h1>
                      <h5 className="m-0 text-uppercase text-muted">No of Stores</h5>
                    </Col>
                  </Row>
                </Col>}
              </Row>

            </CardHeader>
          </Card>}
          {channelDetails?.companyImages.length > 0 && <Channelpiclist images={channelDetails?.companyImages} />}
          <Row>
            {/* {userData.channelId === id && <i class="fas fa-pencil-alt ch-edit" onClick={() => setDrawerId('3')} style={{ cursor: "pointer" }}></i>} */}
            {parseInt(channelDetails.channelTypeId) !== 2 && <Col lg="6">
              <Card>
                <CardBody style={{ minHeight: '11rem' }}>
                  {tradeInformation?.profileType && <Col lg="6">
                    {/* <Categories id='1' data={[tradeInformation?.profileType]}  title={parseInt(channelDetails.channelTypeId) ===3?'Distribution Profile':'Retailer Profile'}/> */}
                    <Profile id='1' data={[tradeInformation?.profileType]} title={parseInt(channelDetails.channelTypeId) === 3 ? 'Distribution Profile' : 'Retailer Profile'} />
                  </Col>}

                  {(tradeInformation?.speciality && tradeInformation?.speciality.length > 0) && <Col lg="6">
                    {/* <Categories id='3' data={tradeInformation?.speciality} title='Speciality In'/>  */}
                    <Profile id='3' data={tradeInformation?.speciality} title='Speciality In' />
                  </Col>}
                </CardBody>
              </Card>
            </Col>}


            {(tradeInformation?.productCategories && tradeInformation?.productCategories.length > 0) && <Col lg="6" className="pl-0">
              <Categories id='4' data={tradeInformation?.productCategories} title='Product Categories' /> </Col>}

            {((channelDetails.channelTypeId == 2 && channelDetails?.companyDesc && channelDetails?.companyDesc != null) || (channelDetails.channelTypeId != 2 && channelDetails?.expectations && channelDetails?.expectations != null)) && <Col lg={parseInt(channelDetails.channelTypeId) === 2 ? '6' : '12'} className="pr-0"><Card>
              <CardBody className='minheit'><h3>Company background</h3>
                {
                  <ExpandString data={parseInt(channelDetails.channelTypeId) === 2 ? channelDetails?.companyDesc : channelDetails?.expectations} title="Company background" id={1} />
                }
              </CardBody>
            </Card></Col>}
            {(channelDetails?.tradeInformation?.interestedCategories && channelDetails?.tradeInformation?.interestedCategories.length > 0) && <Col lg="6">
              {/* {userData.channelId === id && <i class="fas fa-pencil-alt ch-edit mt-3" onClick={() => { setshowDrawer(true) }} style={{ cursor: "pointer" }}></i>} */}
              <Categories id='5' data={channelDetails?.tradeInformation?.interestedCategories} title='Interested Categories' /> </Col>}
          </Row>

          <Card>
            {(brandList?.channelBrand?.length > 0 && parseInt(channelDetails.channelTypeId) !== 4) && <CardHeader className="p-3">
              <h2 className="text-center m-0 text-uppercase">{parseInt(channelDetails.channelTypeId) === 2 ? "Our brands" : "Distribution brands"}</h2>
            </CardHeader>}
          </Card>
          {(brandList?.channelBrand?.length > 0 && parseInt(channelDetails.channelTypeId) !== 4) && <>
            {/* {userData.channelId === id && <i class="fas fa-pencil-alt ch-edit1 mt-4"></i>} */}
            <BrandListing sizeSM={true} data={brandList?.channelBrand} title={parseInt(channelDetails.channelTypeId) === 2 ? "Our brands" : "Distribution brands"} /></>}
          {productList?.length > 0 && <>
            {/* {userData.channelId === id && <i class="fas fa-pencil-alt ch-edit1"></i>} */}
            <ChannelProducts data={productList} title="Key Products" channelId={id} from={to} /></>}
          {/* {(similarProductList?.length > 0 && parseInt(userData.channelTypeId) !== 2) && <ChannelProducts data={similarProductList} title="Similar Products" channelId={id} from={to} />} */}
          {/* <Autherizedown/>
             */}

          <div>
            <Row>
              <Col lg='4' className='p-0'>
                <Card>
                  <CardBody>
                    <h3>Autherized owner</h3>
                    <Row>
                      <Col lg='5' className='pt-3 pb-4'>
                        <div className="Auth-img">
                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"); }}
                            src={channelDetails?.contact?.documentUrl ? channelDetails?.contact?.documentUrl : 'http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png'} className="img-fluid" alt="contact img" />
                        </div>
                      </Col>
                      <Col lg='7' className='pt-3 pb-4'>
                        <h2 className='pt-3'>{channelDetails?.contact?.firstName}</h2>
                        <h4>
                          <Button className="btn-primary btn-icon-only" color="default" type="button">
                            <a style={{ color: 'inherit' }} href="#">
                              <span className="btn-inner--icon">
                                <i style={{ top: "0px" }} className="far fa-envelope">
                                </i>
                              </span>
                            </a>
                          </Button>
                          <a href={'mailto:' + channelDetails?.contact?.email} className='ml-2'>{channelDetails?.contact?.email}</a>
                        </h4>
                        <h4>
                          <Button className="btn-warning btn-icon-only" type="button">
                            <span className="btn-inner--icon">
                              <i style={{ top: "0px" }} class="fas fa-phone"></i>
                            </span>
                          </Button>
                          <a href='#' className='ml-2'>{channelDetails?.contact?.mobileNo}</a>
                        </h4>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg='8'>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg='6' className='p-0'>
                        <h3>Subscription Plan Details</h3>
                        <h3>Plan :<span className='ft-nor ml-3'>{channelDetails?.subscriptionPlan ? channelDetails?.subscriptionPlan : 'none'}</span></h3>
                        <h3>Plan Cost :<span className='ft-nor ml-3'>{channelDetails?.subscriptionDetails?.actualAmount ? channelDetails?.subscriptionDetails?.actualAmount : 'none'}</span></h3>
                        <h3>Coupon Code Applied :<span className='ft-nor ml-3'>{channelDetails?.subscriptionDetails?.coupon?.couponCode ? channelDetails?.subscriptionDetails?.coupon?.couponCode : 'None'}</span></h3>
                        <h3>Subscription Paid Amount :<span className='ft-nor ml-3'>{channelDetails?.subscriptionDetails?.finalAmount ? channelDetails?.subscriptionDetails?.finalAmount : 'none'}</span></h3>
                        <h3>Transaction Number :<span className='ft-nor ml-3'>{channelDetails?.subscriptionDetails?.paymentTransaction?.transactionNumber ? channelDetails?.subscriptionDetails?.paymentTransaction?.transactionNumber : 'None'}</span></h3>
                        
                        <h3>Expiry Date :<span className='ft-nor ml-3'> 
                        {(+channelDetails?.subscriptionDetails?.subscriptionId == 26)?Moment(channelDetails?.subscriptionDetails?.endAt).format(' D MMMM YYYY'):'LifeTime'} 
                          {/* <i class="fas fa-pencil-alt ml-3"></i> */}
                          </span></h3>
                      </Col>
                      <Col lg='6'>
                        {channelDetails?.certificate?.Registration && <>
                          <h3>Legal Certificate</h3>
                          {channelDetails?.certificate?.Registration?.length > 0 && channelDetails.certificate.Registration.map(certificate => (
                            <h3>{certificate.cerificateName} :<span className='ft-nor ml-3'>{certificate.cerificateNumber}</span></h3>
                          ))}
                          {channelDetails?.certificate?.Tax?.length > 0 && channelDetails.certificate.Tax.map(Tax => (
                            <h3>{Tax.cerificateName} :<span className='ft-nor ml-3'>{Tax.cerificateNumber}</span></h3>
                          ))}</>}
                      </Col>
                    </Row>

                    <Row>
                      <Col lg='12' className='p-0'>

                      </Col>
                    </Row>
                    <Row>

                    </Row>


                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          {contactList?.records?.length > 0 && <div id="connectWith">
            {/* {userData.channelId === id && <i class="fas fa-pencil-alt ch-edit1 mt-4"></i>} */}
            <ContactListing data={contactList} title={channelDetails?.channelTypeId === '2' ? "Connect With Us" : "List of contacts"} channelTypeId={channelDetails?.channelTypeId} channelId={id} getChannelContact={getChannelContact} notify={notify} />
          </div>}

          {parseInt(channelDetails.channelTypeId) !== 4 && <Card>
            {(tradeInformation?.ChannelProfileValue?.length > 0 || tradeInformation?.RetailerProfileValue?.length > 0 || tradeInformation?.ResellerProfileValue?.length > 0 || channelDetails?.channelKeyDistributor?.length > 0 || (channelDetails?.channelKeyRetailer?.length > 0 && (businessType == 1 || businessType == 3)) || (channelDetails?.channelKeyReseller?.length > 0 && (businessType == 2 || businessType == 3))) && <CardHeader className="p-3">
              <h2 className="text-center m-0 text-uppercase">Channel Description</h2>
            </CardHeader>}
          </Card>}

          {channelDetails?.channelKeyDistributor?.length > 0 && <Card><KeyBrands sizeSm={true} type={2} data={channelDetails?.channelKeyDistributor} no={channelDetails?.no_of_distributors} desc={channelDetails?.keydistributors} title="List of Key Distributors" count_title="Distributors" /></Card>}

          {((parseInt(channelDetails.channelTypeId) === 2 || parseInt(channelDetails.channelTypeId) === 3) && (businessType == 1 || businessType == 3)) && <Card>
            {tradeInformation?.RetailerProfileValue?.length > 0 && <Categories id='6' data={tradeInformation?.RetailerProfileValue} title={channelDetails.channelTypeId == 2 ? "Relevant Retailer Channels" : "Retail Customers Profile"} />}
            {channelDetails?.channelKeyRetailer?.length > 0 && <KeyBrands sizeSm={true} type={2} data={channelDetails?.channelKeyRetailer} no={channelDetails?.no_of_retailers} desc={channelDetails?.keyretailers} title="List of Key Retailers" count_title="Retailers" />}
          </Card>
          }

          {((parseInt(channelDetails.channelTypeId) === 2 || parseInt(channelDetails.channelTypeId) === 3) && (businessType == 2 || businessType == 3)) && <Card>
            {tradeInformation?.ResellerProfileValue?.length > 0 && <Categories id='6' data={tradeInformation?.ResellerProfileValue} title={channelDetails.channelTypeId == 2 ? "Relevant Reseller Channels" : "Resellers Customers Profile"} />}
            {channelDetails?.channelKeyReseller?.length > 0 && <KeyBrands sizeSm={true} type={2} data={channelDetails?.channelKeyReseller} no={channelDetails?.no_of_resellers} desc={channelDetails?.keyresellers} title="List of Key Resellers" count_title="Resellers" />}
          </Card>
          }


          {(tradeInformation?.ChannelProfileValue?.length > 0 && parseInt(channelDetails.channelTypeId) !== 4 && parseInt(channelDetails.channelTypeId) !== 2) && (
            <>
              <Categories id='6' data={tradeInformation?.ChannelProfileValue} title="Channel Profiles" />
              {channelDetails?.channelKeyRetailer?.length > 0 && <KeyBrands sizeSm={true} type={2} data={channelDetails?.channelKeyRetailer} no={channelDetails?.no_of_retailers} desc={channelDetails?.keyretailers} title="List of Key Retailers" count_title="Retailers" />}
            </>
          )}




          {parseInt(channelDetails.channelTypeId) !== 4 && <Card>
            {channelDetails?.channelDocuments?.length > 0 && <CardHeader className="p-3">
              <h2 className="text-center m-0 text-uppercase">Resources</h2>
            </CardHeader>}
          </Card>}
          <Card>
            {channelDetails?.channelDocuments?.length > 0 && <OfficialDocument type='1' data={channelDetails?.channelDocuments} />}
          </Card>

        </>
        }
      </Container>
    </div>

  );
}

function mapState(state) {
  const general_data = state.channelListing;
  return general_data;
}

const actionCreators = {
  channelRedux: channelAction.channelRedux
};

export default connect(mapState, actionCreators)(ChannelDetail);
