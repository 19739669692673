import React,{useState, useEffect} from 'react';
import { Badge } from '@material-ui/core';
import {FormInput,FormRadio,FormCheckBox,FormTextArea,SingleAutocomplete,FormSelect,NewFieldAutocomplete} from "_components/FormElements/FormInput";
import { Card,CardBody,CardHeader, Container,Row,Col,Button} from "reactstrap";
import { commonService } from '_services/common.service';
import { useForm} from "react-hook-form";
import Moment from 'moment';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { ComplaintReplySchema } from '../product/validation';
import { yupResolver } from '@hookform/resolvers/yup';
const ComplaintView = (props) => {
    let [state,setState]=useState(null);
    const {ComplaintData, onClose, setloading, reCallView, visible} =props;
    const { register, errors, handleSubmit, watch } = useForm({ 
      mode:'onBlur',
      resolver: yupResolver(ComplaintReplySchema)
   });
   useEffect(() => {
    state=''
    setState(state);
  }, [visible]);
    const closeDrawer =()=>{
        onClose();
      }
      const handleChange = e => {
        state=e.target.value;
        setState(state);
      }
      const save=async()=>{
        let data ={
          action: "replied",
          compalaintreply: {
            content: state
          }
        }
        setloading(true);
        let res=await commonService.postService(data,'master/action/'+ComplaintData.complaintid).catch(err => setloading(false));
        if(res){
          ToastsStore.success('Successfully Replied...');
          // closeDrawer();
          reCallView();
        }else{
          ToastsStore.warning('Something went wrong!...');
        }
        setloading(false);
      }
    return (
              <>
              <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
              <div className="col">
                <div className="card-profile-stats d-flex justify-content-center">
                  <div>
                  <span className="heading">Status:</span>  
                    <span className="B-prod1 ">{ComplaintData?.status} </span>
                  </div>
                  <div>
                    <span className="heading">Receive Date:</span>  
                    <span className="paragraph">{Moment(ComplaintData?.createdat).format('ll')}</span>
                  </div>
                  <div>
                  <span className="heading">Type:</span>  
                    <span className="paragraph">{ComplaintData?.complaintcategory?.complaintcategory} </span>
                  </div>
                </div>
              </div>
              <Row> 
              <Col lg="3">            
              <div className="text-left">
              <h5 className="h3"> User Details:</h5>  
              </div>
              </Col> 
            <Col lg="5">
              <div className="font-weight-300 text-left ud-p">
                <p> {ComplaintData?.firstname} {ComplaintData?.lastname} </p>
               {ComplaintData?.channel && <p> {ComplaintData?.channel?.companyName} </p>}
               <p> {ComplaintData?.country?.country} </p>
               <p> {ComplaintData?.phoneno} </p>
                <p>{ComplaintData?.email}</p>
              </div>
              </Col>
              <Col lg="4"></Col>
              </Row>
            <div className="text-left col-lg-12">
              <h5 className="h3">
              Subject:</h5><p> {ComplaintData?.subject} </p>
              </div>
            <div>
             <Row>
            <Col lg="12">    
            <div className="form-group">
          <FormTextArea 
          className="buytext2 w-100"
          label="Message:"
          value={ComplaintData?.detaildesc}
          disabled
          />
        </div>
        </Col>
        </Row>   
        </div>
        <div>
             <Row>
            <Col lg="12">    
            <div className="form-group">
          <FormTextArea 
          className="buytext2 w-100" 
          placeholder="Reply your Comment"
          label="Comment:"
          onChange={handleChange}
          register={register}
          name='Comment'
          id='Comment'
          error={errors.Comment}
          value={state}
          default={state}
          />
        </div>
        </Col>
        </Row>   
        </div> 
        <div className="modal-footer">
        <button onClick={e => {e.preventDefault(); closeDrawer()}}  className="btn btn-warning" >Cancel</button>
        <Button  onClick={handleSubmit(save)} className='btn btn-primary' data-dismiss="modal" type="button">Save</Button>
      </div>
        </>
       
    );
};

export default ComplaintView;
