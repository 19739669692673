import React, { useEffect, useState } from "react";
import { FormInput, FormFileInput, FormSelect, FormButton, FormMultiSelect, FormTextArea, FormCheckBox, FormAutocomplete, FormRadio, NewFieldAutocomplete, } from "_components/FormElements/FormInput";
import {
    Row, Card, CardBody, CardHeader, Container
} from "reactstrap";
import { PdfCard, ImageCard } from "views/Hoc/PdfCard";
import { useForm } from 'react-hook-form';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { MultiSelectImages } from 'views/Hoc/Dialog/MultiSelectImages';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IncrementDecrementBtn from './IncDncbtn';
import AsyncNewFieldAutocomplete from "_components/FormElements/FormInput.js";
import axios from 'axios';
import RoomNames from './roomname.js'
import ImageUpload from './roomimage';
import { Box, Checkbox, ListItemText } from "@material-ui/core";
import Modal from '@mui/material/Modal';

import CloseIcon from '@mui/icons-material/Close';

const RoomTypeImageModal = ({ roomTypeImageModal, setRoomTypeImageModal, item }) => {

    const getContainerStyle = () => ({
        cursor: "pointer",
        width: "190px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft:"0.9rem",
        borderRadius: "10px",
        transition: "border 0.3s ease, padding 0.3s ease"
      });
    
      const imgStyle = {
        width: "156px",
        height: "200px",
        borderRadius: "10px",
        marginTop:"27px",
        transition: "transform 0.3s ease"
      };


      const handleMouseOver = (e) => {
        e.currentTarget.style.transform = "scale(1.1)";
      };
    
      const handleMouseOut = (e) => {
        e.currentTarget.style.transform = "scale(1)";
      };
    
 
    return (
        <Modal
            open={roomTypeImageModal}
            onClose={() => setRoomTypeImageModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }}
        >

            <Card style={{ margin: "0", overflowY: "scroll", height: "100vh", padding: "14px", minWidth: "66%", maxWidth: "66%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", textAlign: "center", width: "97%" }}>
                    <button className="btn btn-primary btn-sm" onClick={() => setRoomTypeImageModal(false)}> <i class="fa fa-arrow-left"></i></button>
                    <h3>Hotel Room  Images</h3>
                    <button style={{ border: "none", backgroundColor: "white" }} onClick={() => setRoomTypeImageModal(false)}><CloseIcon /></button>
                </div>

                {/* {loading && <ModalSpinner />} */}

                {
      item.length > 0 && 
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start" }}>
      {item.map((item) => (
        <div 
          key={item.id} 
          style={getContainerStyle()} 
        >
          <img 
            className="img-fluid"  
            style={imgStyle}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            alt="ch-logo" 
            src={`${process.env.REACT_APP_CDN_URL_TEMP}${item.documentPath}`} 
          />
          <h4 style={{ fontSize: "0.7rem", marginTop: "0.5rem", textAlign:"center" }}>{item.caption}</h4>
        </div>
      ))}
    </div>
  }



            </Card>


        </Modal>
    )
}

export default RoomTypeImageModal