import axios from "axios";
import { LocalStorageService } from '_services';
import { history } from '../_helpers';
axios.defaults.baseURL = process.env.REACT_APP_API;

export const setAxiosDefauls = () => {
  
  axios.interceptors.request.use(
    onRequestFulfilled
    , onRequestRejected);

  axios.interceptors.response.use(
    onResponseFulfilled
    , onResponseRejected);
}

export const resetSession = () => {
  localStorage.remove("fo-auth-token");
      history.push('/');
}

const onRequestFulfilled = (config) => {
  const localStorageService = LocalStorageService.getService();  
  const token = localStorageService.getAccessToken("fo-auth-token");
  if (token && token !== 'undefined') {
    config.headers = {
      "Authorization": "Bearer " + token
      , "Cache-Control": "no-cache"
      , "Pragma": "no-cache"
      , "X-Frame-Options": "SAMEORIGIN",
    };
  } 
  return Promise.resolve(config);
}

const onRequestRejected = (error) => {
  return Promise.reject(error);
}

const onResponseFulfilled = (config) => {
  return Promise.resolve(config);
}

const onResponseRejected = (error) => {
  return Promise.reject(error);
}