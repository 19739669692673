import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useForm} from "react-hook-form";
import { ListGroupItem, ListGroup } from 'reactstrap';
import { commonService } from '_services/common.service';
import { FormAutocomplete } from "_components/FormElements/FormInput";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const MyprodfilterVendor = (props) => {

  const { activeTab, handleChangeSelect, filterValueState, resetFilterValue, searchOption } = props;
  const [ filterOptionState, setFilterOption] = useState({PositionOption:[{name:'MID',id:'Mid'},{name:'HIGH',id:'High'}], Brand_Option:[], Profile_Option:[], Location_Option:[],Family_Option:[],Category_Option:[],  });
  var user = JSON.parse(localStorage.getItem('CHuser'))?.userData;
  const { control } = useForm({ mode:'onBlur' });

useEffect(() => {
    if(activeTab)
    getSearchAggregation();
}, [activeTab])

const userParams = {
  channelId: user.channelId,
  userId: user.userId
}

const getSearchAggregation=async()=>{
//  resetFilterValue();
  //resetFilterOptions();
  let data = await commonService.postService(userParams, '/channelAdmin/myProductsAgg/'+activeTab);
  if(data){
    let options = data.data;
    if(options){
   filterOptionState.Brand_Option=options.brand;
   filterOptionState.Family_Option=options.family;
   filterOptionState.Category_Option=options.category;
   setFilterOption({...filterOptionState});
   searchOption(filterOptionState.Brand_Option=options.search)
   }
   }
  console.log('filterOptionState', filterOptionState);
}

  // const toggle = tab => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // }
  //const [activeTab, setActiveTab] = useState('1');
  //const { control } = useForm({ mode:'onBlur' });

  const useStyles = makeStyles({
    root: {
      width:"100%",
    },
  });
  function valuetext(value) {
    return `${value}°C`;
  }

  const classes = useStyles();
  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };  

  const style={height: '10rem'};

  return (
    <div  className='fav-ser1 p-2'>
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Positioning</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ListGroup>
          <ListGroupItem style={style}>
        <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Positioning"
                        name="Positioning"
                        label=""
                        options={filterOptionState.PositionOption}
                        placeholder="Type Positioning"
                         //multiple
                        // disableCloseOnSelect
                        limitTags={1}
                        control={control}                         
                        input='position'
                        value={filterValueState.Position}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                        />
                    </div>
                  </div>
                  </ListGroupItem>
          </ListGroup>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Brand</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ListGroup>
          <ListGroupItem style={style}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Brand"
                        name="Brand"
                        label=""
                        options={filterOptionState.Brand_Option}
                        placeholder="Type Brand"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}                         
                        input='brand'
                        value={filterValueState.Brand}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                        />
                    </div>
                  </div>
                  </ListGroupItem>
          </ListGroup>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>Category</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ListGroup>
          <ListGroupItem style={style}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Category"
                        name="Category"
                        label=""
                        options={filterOptionState.Category_Option}
                        placeholder="Type Category"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}                         
                        input='category'
                        value={filterValueState.Category_Option}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                        />
                    </div>
                  </div>
                  </ListGroupItem>
          </ListGroup>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>Family</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ListGroup>
          <ListGroupItem style={style}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Family"
                        name="Family"
                        label=""
                        options={filterOptionState.Family_Option}
                        placeholder="Type Family"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}                         
                        input='family'
                        value={filterValueState.Family_Option}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                        />
                    </div>
                  </div>
                  </ListGroupItem>
          </ListGroup>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
    </div>
  );
}

export default MyprodfilterVendor;