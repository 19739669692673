import React, { useEffect, useState } from 'react';
//import { DataGrid, GridToolbar } from '@material-ui/data-grid';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { Card, CardBody, Row, Col, Button, CardHeader, Modal } from "reactstrap";

import axios from 'axios';
import Avatar from "@material-ui/core/Avatar";

import { ConfirmDialog, BrandMergePopup } from "views/Hoc/Dialog/GlobalDialog";
import Brand from '../brand/brand';
import { ContactsOutlined } from '@material-ui/icons';



// let idCounter = 0;
// const createRandomRow = () => {
//     idCounter += 1;
//     return {
//         id: idCounter, yeare: '', revenueOption: '', revenueCustom: ''
//     };
// };

const OldChannelEdit = (props) => {

    let [selectedId, setSelectedId] = useState(null);

    let [isModalPopup, setModalPopup] = useState(false);
    let [isMergePopup, setMergePopup] = useState(false);
    let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });

    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState(null);

    const [channelList, setChannel] = useState([]);
    const [categoryMaster, setCategoryMaster] = useState([]);


    const [brandMaster, setBrandMaster] = useState([]);
    const [revenueList, setRevenue] = useState([]);


    const [brandSelectionModel, setBrandSelectionModel] = useState([]);
    const [categorySelectionModel, setCategorySelectionModel] = useState([]);
    const [channelSelectionModel, setChannelSelectionModel] = useState([]);

    const [pageSize, setPageSize] = React.useState(100);

    const [year, setYear] = useState([]);

    let [description, setDescription] = useState({ value: '', formattedValue: null });

    const closePopup = () => {
        setModalPopup(false);
        setMergePopup(false);
    };

    const confirmPopup = (res) => {
        if (res.popUpId == 1) {
            setTimeout(() => {
                setBrandMaster((prevRows) => prevRows.filter((row) => row.id !== res.value));
            });
        }
        if (res.popUpId == 2) {
            setTimeout(() => {
                setCategorySelectionModel(categorySelectionModel.filter(x => x !== res.value));
            });
        }
        if (res.popUpId == 3) {
            setTimeout(() => {
                setRevenue((prevRows) => prevRows.filter((row) => row.id !== res.value));
            });
        }
        setModalPopup(false);
    }

    const confirmMerge = async (id) => {

        
        let mergedIds = brandSelectionModel.filter(x => x != id);        
        let newBrandList = brandMaster.filter(x => ![...mergedIds].includes(x.id));
        setBrandMaster(newBrandList);      

        let data = {
            id: +id,
            updatedIds: mergedIds
        }

        await axios.post('/channelAdmin/mergeBrand', data).then(res => {
            console.log("updated");
        });

        setMergePopup(false);
    }

    const updateChannel = async () => {
        let channel = channelList.filter(x => channelSelectionModel.includes(x.id))[0];

        let channelObject = {
            channelId: channel.id,
            companyName: channel.name
        }
        let obj = {
            channelObject: channelObject,
            channelId: channel.id,
            productCategories: categorySelectionModel.map(x => +x),
            brand: brandSelectionModel,
            revenue: revenueList,
        }
        await axios.post('/channelAdmin/updateChannelEdit', obj).then(res => {
            console.log("updated");
        });
    }

    const deleteRevenue = (id) => {

        modalPopValue.message = 'You want to delete this revenue';
        modalPopValue.title = 'Delete Revenue';
        modalPopValue.popUpId = 3;
        modalPopValue.value = id;
        setModalPopValue({ ...modalPopValue });
        setModalPopup(true);
    }


    const copyRevenue = (id) => {

        let len = revenueList.length;
        setRevenue((prevRows) => {
            const rowToDuplicate = prevRows.find((row) => row.id === id);
            return [...prevRows, { ...rowToDuplicate, id: len + 1 }];
        });

    }

    const deleteBrand = (id) => {

        modalPopValue.message = 'You want to delete this brand';
        modalPopValue.title = 'Delete Brand';
        modalPopValue.popUpId = 1;
        modalPopValue.value = id;
        setModalPopValue({ ...modalPopValue });
        setModalPopup(true);
    }

    const deleteCategory = (id) => {

        modalPopValue.message = 'You want to delete this category';
        modalPopValue.title = 'Delete Category';
        modalPopValue.popUpId = 2;
        modalPopValue.value = id;
        setModalPopValue({ ...modalPopValue });
        setModalPopup(true);
    }

    const deleteSelectedBrand = (id) => {

        setTimeout(() => {
            setBrandSelectionModel(brandSelectionModel.filter(x => x !== id));
        });
    }


    const addBrand = () => {

        let len = brandMaster.length + 1000;
        setBrandMaster((prevRows) => [{ id: len + 1, document: '', brandName: '', isNew: true }, ...prevRows]);

    }


    const addRevenue = () => {

        let len = revenueList.length;
        setRevenue((prevRows) => [...prevRows, { id: len + 1, year: null, revenueOption: '', revenueCustom: '' }]);

    }

    useEffect(() => {
        productMaster();
        brandMasters();
        getChannel();
        setYears();

    }, [])

    const setYears = () => {
        let years = [];
        for (let yearCount = new Date().getFullYear(); yearCount >= 1900; yearCount--) {
            years.push(yearCount);
        }
        setYear(years);
    }

    useEffect(() => {
        if (channelSelectionModel.length === 0) {
            setCategorySelectionModel([]);
            setBrandSelectionModel([]);
        }
        else {
            getProduct(channelSelectionModel[0]);
            getRevenue(channelSelectionModel[0]);
            getBrand(channelSelectionModel[0]);
        }

    }, [channelSelectionModel])


    const getChannel = async () => {
        await axios.get('/channelAdmin/oldChannels1/').then(res => {
            setChannel(res.data);

        });
    }

    const productMaster = async () => {
        await axios.get('/channelAdmin/getProductMaster/').then(res => {
            setCategoryMaster(res.data);

        });
    }

    const brandMasters = async () => {
        await axios.get('/channelAdmin/getBrandMaster/').then(res => {

            setBrandMaster(res.data);

        });
    }

    const getProduct = async (id) => {
        await axios.get('/channelAdmin/getChannelProducts/' + id).then(res => {
            setCategorySelectionModel(res.data.productGroup.map(c => c.id));
        });
    }

    const getRevenue = async (id) => {
        await axios.get('/channelAdmin/getChannelRevenue/' + id).then(res => {
            res.data.map(x => {
                if (!['<1M€', '1-10M€', '10-100M€', '>100M€'].includes(x.revenueOption)) {
                    x.revenueCustom = x.revenueOption;
                    x.revenueOption = 'Custom Value';
                }
            })
            setRevenue(res.data);
        });
    }

    const getBrand = async (id) => {
        await axios.get('/channelAdmin/getChannelBrands/' + id).then(res => {


            setBrandSelectionModel(res.data.brand.map(c => +c.id));

        });
    }


    const handleRevenueCommit = (e) => {

        console.log("commitrev");
        const array = revenueList.map(r => {
            console.log("e.field:", e.id, e.field, e.value)
            if (r.id == e.id) {
                return { ...r, [e.field]: e.value }
            }
            else {
                return { ...r }
            }
        });
        setRevenue(array);

    }

    const handleBrandCommit = (e) => {
        const array = brandMaster.map(r => {
            if (r.id == e.id) {
                return { ...r, [e.field]: e.value }
            }
            else {
                return { ...r }
            }
        });
        setBrandMaster(array);

    }

    const handleChannelCommit = (e) => {

        let nameList = channelList.map(x => x.name?.replace(/\s/g, '').toLowerCase())


        if (nameList.includes(e.value.replace(/\s/g, '').toLowerCase())) {
            modalPopValue.message = 'Company Name already exists';
            modalPopValue.title = 'Edit Company Name';
            modalPopValue.popUpId = 4;
            modalPopValue.value = e.id;
            setModalPopValue({ ...modalPopValue });
            setModalPopup(true);
        } else {

            const array = channelList.map(r => {
                if (r.id == e.id) {
                    return { ...r, [e.field]: e.value }
                }
                else {
                    return { ...r }
                }
            });
            setChannel(array);

        }



    }


    const categoryCol = React.useMemo(
        () => [

            {
                field: 'id',
                headerName: 'Id',
                headerAlign: 'center',
                width: 50,
                editable: false,
                sortable: false
            },
            {
                field: 'category',
                headerName: 'Product Category',
                headerAlign: 'center',
                width: 300,
                editable: false,
                sortable: true
            }
        ]
    );

    const companyCol = React.useMemo(
        () => [

            {
                field: 'id',
                headerName: 'id',
                headerAlign: 'center',
                width: 100,
                editable: false,
                sortable: false
            },
            {
                field: 'name',
                headerName: 'Company Name',
                headerAlign: 'center',
                width: 200,
                editable: true,
                sortable: true
            }
        ]
    );

    const revenueCol = React.useMemo(
        () => [

            {
                field: 'year',
                headerName: 'Year',
                type: 'singleSelect', headerAlign: 'center',
                valueOptions: year,
                width: 250,
                editable: true,
                sortable: true
            },
            {
                field: 'revenueOption',
                headerName: 'Revenue',
                type: 'singleSelect', headerAlign: 'center',
                valueOptions: ['<1M€', '1-10M€', '10-100M€', '>100M€', 'Custom Value'],
                width: 250,
                editable: true,
                // sortable: false
            },
            {
                field: 'revenueCustom', headerName: 'Custom Value', width: 250, type: 'number', headerAlign: 'center', editable: true
            },
            {
                field: 'action',
                headerName: 'Actions',
                type: 'actions', headerAlign: 'center',
                width: 200,

                renderCell: (params) => {

                    return (
                        <>
                            <Button onClick={() => deleteRevenue(params.id)}><span className="btn-inner--icon"><i className="fas fa-trash-alt"></i></span></Button>
                            <Button onClick={() => copyRevenue(params.id)}><span className="btn-inner--icon"><i class="fas fa-copy"></i></span></Button>

                        </>
                    );
                }
            }


        ]
    );


    const brandCol = React.useMemo(
        () => [

            {
                field: 'id',
                headerName: 'id',
                width: 80,
                headerAlign: 'center',
                editable: false,
                sortable: false
            },
            {
                field: "document",
                headerName: " Brand Image",
                width: 120,
                editable: true,
                headerAlign: 'center',
                sortable: false,
                renderCell: (params) => {

                    return (
                        <>
                            <Avatar src={params.value?.documentUrl?params.value.documentUrl:'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />
                        </>
                    );
                }
            },
            {
                field: 'brandName',
                headerName: 'Brand Name',
                headerAlign: 'center',
                width: 150,
                editable: true,
                sortable: true
            },
            {
                field: 'action',
                headerName: 'Actions',
                type: 'actions', headerAlign: 'center',
                width: 50,

                renderCell: (params) => {

                    return (
                        <>
                            <Button onClick={() => deleteBrand(params.id)}><span className="btn-inner--icon"><i className="fas fa-trash-alt" ></i></span></Button>
                            {/* <Button onClick={() => copyBrand(params.id)}><span className="btn-inner--icon"><i class="fas fa-copy"></i></span></Button> */}

                        </>
                    );
                }
            }

        ]
    );

    const renderCategoryCol = React.useMemo(
        () => [

            {
                field: 'category',
                headerName: 'Product Category',
                headerAlign: 'center',
                width: 300,
                editable: false,
                sortable: true
            },

            {
                field: 'action',
                headerName: 'Actions',
                type: 'actions', headerAlign: 'center',
                width: 100,

                renderCell: (params) => {

                    return (
                        <>
                            <Button onClick={() => deleteCategory(params.id)}><span className="btn-inner--icon"><i className="fas fa-trash-alt"></i></span></Button>


                        </>
                    );
                }
            }
        ]
    );


    const renderBrandCol = React.useMemo(
        () => [

            {
                field: 'id',
                headerName: 'id',
                width: 80,
                headerAlign: 'center',
                editable: false,
                sortable: false
            },
            {
                field: 'brandName',
                headerName: 'Brand Name',
                headerAlign: 'center',
                width: 150,
                editable: false,
                sortable: true
            },

            {
                field: 'action',
                headerName: 'Actions',
                type: 'actions', headerAlign: 'center',
                width: 100,

                renderCell: (params) => {

                    return (
                        <>
                            <Button onClick={() => deleteSelectedBrand(params.id)}><span className="btn-inner--icon"><i className="fas fa-trash-alt"></i></span></Button>


                        </>
                    );
                }
            }
        ]
    );




    const renderCategory = () => {


        if (categorySelectionModel.length > 0) {

            let data = categoryMaster.filter(x => categorySelectionModel.includes(x.id));


            return <DataGrid editMode="row"
                getRowHeight={() => 'auto'}
                //components={{ Toolbar: GridToolbar }}
                // checkboxSelection
                // selectionModel={brandSelectionModel}
                // onSelectionModelChange={setBrandSelectionModel}
                // disableSelectionOnClick

                // disableColumnFilter
                // disableColumnMenu

                // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                // rowsPerPageOptions={[5, 10, 20]}
                rows={data}
                columns={renderCategoryCol}
                //onCellEditCommit={handleBrandCommit}
                //onCellEditStart={handleEdit}
                //pagination={false}
                pageSize={pageSize}
            />


        }


    }

    const renderBrand = () => {


        if (brandSelectionModel.length > 0) {

            let data = brandMaster.filter(x => brandSelectionModel.includes(x.id));


            return <DataGrid editMode="row"
                getRowHeight={() => 'auto'}
                //components={{ Toolbar: GridToolbar }}
                // checkboxSelection
                // selectionModel={brandSelectionModel}
                // onSelectionModelChange={setBrandSelectionModel}
                // disableSelectionOnClick

                // disableColumnFilter
                // disableColumnMenu

                // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                // rowsPerPageOptions={[5, 10, 20]}
                rows={data}
                columns={renderBrandCol}
                //onCellEditCommit={handleBrandCommit}
                //onCellEditStart={handleEdit}
                //pagination={false}
                pageSize={pageSize}
            />


        }


    }

    return (
        <div>  <div style={{ textAlign: 'right', margin: '10px' }}><Button class="btn btn-primary" onClick={() => updateChannel()}>Save</Button></div>
            <div>
                <ConfirmDialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
                <BrandMergePopup open={isMergePopup} data={brandMaster.filter(x => brandSelectionModel.includes(x.id))} renderBrandCol={renderBrandCol} handleSave={confirmMerge} handleClose={closePopup} />

                <Row>

                    <Card style={{ height: 600, width: '30%' }}>
                        <CardHeader className="p-3">
                            <h2 className="text-center m-0 text-uppercase">Company Info</h2>
                        </CardHeader>
                        <DataGrid
                            getRowHeight={() => 'auto'}
                            //components={{ Toolbar: GridToolbar }}
                            checkboxSelection
                            selectionModel={channelSelectionModel}
                            onSelectionModelChange={setChannelSelectionModel}
                            disableSelectionOnClick

                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector

                            components={{ Toolbar: GridToolbar }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}

                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            // rowsPerPageOptions={[5, 10, 20]}
                            rows={channelList}
                            columns={companyCol}
                            onCellEditCommit={handleChannelCommit}
                            //onCellEditStart={handleEdit}
                            //pagination={false}
                            pageSize={pageSize}
                        />
                    </Card>

                    <Card style={{ height: 600, width: '30%' }}>
                        <CardHeader className="p-3">
                            <h2 className="text-center m-0 text-uppercase">Product Master</h2>
                        </CardHeader>
                        <DataGrid editMode="row"
                            getRowHeight={() => 'auto'}

                            checkboxSelection
                            selectionModel={categorySelectionModel}
                            onSelectionModelChange={setCategorySelectionModel}
                            disableSelectionOnClick

                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector

                            components={{ Toolbar: GridToolbar }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}

                            // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            // rowsPerPageOptions={[5, 10, 20]}
                            rows={categoryMaster}
                            columns={categoryCol}
                            //onCellEditCommit={handleCommit}
                            //onCellEditStart={handleEdit}
                            //pagination={false}
                            pageSize={pageSize}
                        />
                    </Card>

                    <Card style={{ height: 600, width: '30%' }}>

                        <CardHeader className="p-3">
                            <h2 className="text-center m-0 text-uppercase">Selected Product Category</h2>
                        </CardHeader>

                        {renderCategory()}


                    </Card>

                </Row>

                <Row>

                    <Card style={{ height: 600, width: '45%' }}>
                        <CardHeader className="p-3">
                            <h2 className="text-center m-0 text-uppercase">Turn over <Button onClick={() => addRevenue()}><span className="btn-inner--icon"><i class="fas fa-plus"></i></span></Button></h2>

                        </CardHeader>
                        <DataGrid
                            getRowHeight={() => 'auto'}
                            //components={{ Toolbar: GridToolbar }}
                            //checkboxSelection
                            // selectionModel={channelSelectionModel}
                            // onSelectionModelChange={setChannelSelectionModel}
                            //disableSelectionOnClick

                            //disableColumnFilter
                            //disableColumnMenu

                            // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            // rowsPerPageOptions={[5, 10, 20]}
                            rows={revenueList}
                            columns={revenueCol}
                            onCellEditCommit={handleRevenueCommit}
                            //onCellEditStart={handleEdit}
                            //pagination={false}
                            pageSize={pageSize}
                        />
                    </Card>

                    <Card style={{ height: 600, width: '30%' }}>
                        <CardHeader className="p-3">
                            <h2 className="text-center m-0 text-uppercase">Brand Master <Button onClick={() => addBrand()}><span className="btn-inner--icon"><i class="fas fa-plus"></i></span></Button>
                            </h2>
                        </CardHeader>
                        <DataGrid
                            getRowHeight={() => 'auto'}

                            checkboxSelection
                            selectionModel={brandSelectionModel}
                            onSelectionModelChange={setBrandSelectionModel}
                            disableSelectionOnClick

                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector

                            components={{ Toolbar: GridToolbar }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}

                            // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            // rowsPerPageOptions={[5, 10, 20]}
                            rows={brandMaster}
                            columns={brandCol}
                            onCellEditCommit={handleBrandCommit}
                            //onCellEditStart={handleEdit}
                            //pagination={false}
                            pageSize={pageSize}
                        />
                    </Card>

                    <Card style={{ height: 600, width: '25%' }}>

                        <CardHeader className="p-3">
                            <h2 className="text-center m-0 text-uppercase">Selected Brand <Button onClick={() => setMergePopup(true)}>Merge</Button></h2>
                        </CardHeader>
                        {renderBrand()}

                    </Card>

                </Row>

                {/* <Button onClick={() => updateChannel()}>Save</Button> */}
            </div>
        </div>
    );
}



export default OldChannelEdit;
