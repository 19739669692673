import React ,{useEffect, useState} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {InputLabel,Select,TextField,FormControl} from '@material-ui/core';
import {
  Card, CardBody, CardHeader, Container, FormGroup, Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup, Col, Row, Badge
} from "reactstrap";
import {
Button, Modal,
} from "reactstrap";
import { commonService } from '_services/common.service';
import {Spinner} from 'views/Hoc/Spinner';
import axios from "axios";
import { useForm} from "react-hook-form";
import { SelectAutocomplete } from '_components/FormElements/FormInput';

var FileSaver = require('file-saver');
// import CardBody from 'reactstrap/lib/CardBody';

export const ChannelExportFilter=(props)=> {
const { opneadd, closeModalPopup, brandName } = props;
const [open, setOpen] = React.useState(false);
const theme = useTheme();
const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
const { register, handleSubmit, control, errors } = useForm({      
  mode:'onBlur',  
  //resolver: yupResolver(TradeInformationSchema),
});


useEffect(() => {
setOpen(true);

}, [])
const [filterValueState, setFilterValue] = useState({ activeStatus :  [{id:"1",name:"Active"},{id:'0',name:"InActive"},{id:'2',name:"Both"}] ,status: 2,isOEM: 0, businessType :  [{id:"1",name:"B2B"},{id:'2',name:"B2C"},{id:'3',name:"Both"}] });
const [state, setState] = useState({  });

useEffect(() => {
  getFlags();
}, []);

const getFlags = async() => {
  
};

const handleClickOpen = () => {
setOpen(true);
};

const handleClose = () => {
setOpen(false);
};

const save = async() => {
  
  let data = []
  
    if(state.channelId){ data.push('channelId'); }  
    if(state.companyName){ data.push('companyName'); }  
    if(filterValueState.status){ data.push('status'); }  
    if(filterValueState.isOEM){ data.push('isOEM'); }  
    if(state.lastlogin){ data.push('lastlogin'); }  
    if(state.subscription){ data.push('subscription'); }  
  
 let filterArr = {};
 if(filterValueState.isOEM){ filterArr.isOEM = filterValueState.isOEM; }  
 if(filterValueState.status){ filterArr.status = filterValueState.status; } 
 
 
      axios.post('channelAdmin/createRecord', { fields: data, values: filterArr}).then((response) => {
       axios.get('channelAdmin/exportRecords', { responseType: 'arraybuffer'}).then((response) => {
           let  fileName = 'fileName.xlsx';
           
           var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
               FileSaver.saveAs(blob, fileName);
               //setLoading(false);
           });
         });    
     
 // setState({});
  //closeModalPopup();
}

const closeModalPopups= () => {
  setState({});
  closeModalPopup();
}

const handleChangeSelect = (input, value) => {
  if (input == 'status' && value != null) {
    filterValueState.status = value.id
  }
  if (input == 'isOEM' && value != null) {
    filterValueState.isOEM = value.id
  }
  
  setFilterValue({ ...filterValueState });
}  

const handleChange  = input => e => {
  console.log('+++++++++++++++++++++++++++++');
  console.log(input);
  console.log(e);
  console.log('+++++++++++++++++++++++++++++');

  if(input == 'status'){
    //setState({...state, [input]:[value]});
  } else {
    setState({...state, [input]:!state[input]});
  }

 // setState({...state, [input]:!state[input]});
}

const handleValues = input => e => {
  console.log(state.featureValue[input], ' valus ', e.target.value);
  state.featureValue[input].value = e.target.value;
  
  setState({...state,
    featureValue: state.featureValue
  });
}
return (
<div>
<Modal
className="modal-dialog-centered Reorderdialog"
isOpen={opneadd} >
<div className="modal-header">
<h3 className="text-center">Channel Export Filters</h3>
<button
aria-label="Close"
className="close"
data-dismiss="modal"
type="button"
onClick={closeModalPopups}
>
<span aria-hidden={true}>×</span>
</button>
</div>
<Card>
  <CardBody>
    <Row>
      <Col>
        <div className="modal-body">
<div style={{display: 'block' , width: '100%' }}>
<div style={{display: 'inline-block' , width: '50%' }}>
ChannelId
</div>
<div style={{display: 'inline-block' , width: '40%' }}>
      <label className="custom-toggle custom-toggle-success">
      <input type="checkbox" checked={state.channelId || state.channelId} disabled={state.channelId}  name="channelId" onClick={handleChange('channelId')}  />
      <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
      </label>                            
</div>
<div>&nbsp;</div>
</div>

<div style={{display: 'block' , width: '100%' }}>
<div style={{display: 'inline-block' , width: '50%' }}>
<h4>Company Name:</h4>
</div>
<div style={{display: 'inline-block' , width: '40%' }}>
      <label className="custom-toggle custom-toggle-success">
      <input type="checkbox" checked={state.companyName || state.companyName} disabled={state.companyName}  name="status" onClick={handleChange('companyName')}  />
      <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
      </label>                          
</div>
<div>&nbsp;</div>
</div>

<div style={{display: 'block' , width: '100%' }}>
<div style={{display: 'inline-block' , width: '50%' }}>
<h4>Status:</h4>
</div>
<div style={{display: 'inline-block' , width: '40%' }}>
      {/* <label className="custom-toggle custom-toggle-success">
      <input type="checkbox" checked={state.status || state.status} disabled={state.status}  name="status" onClick={handleChange('status')}  />
      <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
      </label>                         */}
     
                        <SelectAutocomplete
                          id="status"
                          type="text"
                          label="Status"
                          name="status"
                          options={filterValueState.activeStatus}
                          placeholder="status..."
                          disableCloseOnSelect
                          // defaultValue={searchState.search?searchState.search[0]:null}
                          control={control}
                          input='status'
                          value={filterValueState.status}
                          handleChangeSelect={handleChangeSelect}
                        />
</div>
<div>&nbsp;</div>
</div>


<div style={{display: 'block' , width: '100%' }}>
<div style={{display: 'inline-block' , width: '50%' }}>
<h4>Business Type:</h4>
</div>
      {/* <label className="custom-toggle custom-toggle-success">
      <input type="checkbox" checked={state.isOEM || state.isOEM} disabled={state.isOEM}  name="isOEM" onClick={handleChange('isOEM')}  />
      <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
      </label>                         */}
<div style={{display: 'inline-block' , width: '40%' }}>      
      <SelectAutocomplete
                          id="isOEM"
                          type="text"
                          label="isOEM"
                          name="isOEM"
                          options={filterValueState.businessType}
                          placeholder="Business Type..."
                          disableCloseOnSelect
                          // defaultValue={searchState.search?searchState.search[0]:null}
                          control={control}
                          input='isOEM'
                          value={filterValueState.isOEM}
                          handleChangeSelect={handleChangeSelect}
                        />
</div>
<div>&nbsp;</div>
</div>

<div style={{display: 'block' , width: '100%' }}>
<div style={{display: 'inline-block' , width: '50%' }}>
<h4>Last Login:</h4>
</div>
<div style={{display: 'inline-block' , width: '40%' }}>
      <label className="custom-toggle custom-toggle-success">
      <input type="checkbox" checked={state.lastlogin || state.lastlogin} disabled={state.lastlogin}  name="lastlogin" onClick={handleChange('lastlogin')}  />
      <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
      </label>                            
</div>
<div>&nbsp;</div>
</div>


<div style={{display: 'block' , width: '100%' }}>
<div style={{display: 'inline-block' , width: '50%' }}>
<h4>Subscription</h4>
</div>
<div style={{display: 'inline-block' , width: '40%' }}>
      <label className="custom-toggle custom-toggle-success">
      <input type="checkbox" checked={state.subscription || state.subscription} disabled={state.subscription}  name="subscription" onClick={handleChange('subscription')}  />
      <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
      </label> 
</div>

</div>
</div>

     </Col>
    </Row>
  </CardBody>

</Card>

<div className="modal-footer">

<Button
color="primary"
data-dismiss="modal"
type="button"
onClick={save}
>
Proceed
</Button>

<Button
color="secondary"
data-dismiss="modal"
type="button"
onClick={closeModalPopups}
>
Close
</Button>


</div>
</Modal>
</div>
);
}
export default ChannelExportFilter;