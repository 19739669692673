import React, { useEffect } from 'react';
import { Drawer } from 'antd';
import Channelprofedit from 'views/pages/Old-Channel-Listing/Channelprofedit';
import Profileedit from 'views/pages/Old-Channel-Listing/Profileedit';
import ChannelEdit from 'views/pages/Old-Channel-Listing/ChannelEdit';
import Factedit from 'views/pages/Old-Channel-Listing/Factedit';
import { UserprofileformOld } from './UserprofileformOld';

const Globaleditdrawer = (props) => {
  const { closeDrawer, visible, title, changedData, type, userData } = props;

  useEffect(() => {
    console.log('visible', visible);
    console.log('type', type);
  }, [visible])

  const onClose = () => {
    closeDrawer();
  };

  const SwitchPage = () => {
    switch (type) {
      case "1":
        return <Channelprofedit visible={visible} changedData={changedData} closeDrawer={closeDrawer} userData={userData} />
      //for linked in icon edit
      case "11":
        return <Channelprofedit visible={visible} changedData={changedData} closeDrawer={closeDrawer} linkedIn={true} />
      case "2":
        return <Factedit visible={visible} changedData={changedData} closeDrawer={closeDrawer} userData={userData} />
      case "3":
        return <Profileedit visible={visible} changedData={changedData} closeDrawer={closeDrawer} userData={userData} />
      case "4":
        return <UserprofileformOld visible={visible} changedData={changedData} closeDrawer={closeDrawer} userData={userData} />
      //edit key partner section
      case "12":
        return <ChannelEdit visible={visible} changedData={changedData} closeDrawer={closeDrawer} userData={userData} />
      default:
        return <></>
    }
  }

  return (
    <div className="site-form-in-drawer-wrapper">
      <Drawer
        title={title}
        // width={720}
        onClose={onClose}
        visible={visible}
      >
        {SwitchPage()}
      </Drawer>
    </div>
  );
};

export default Globaleditdrawer;