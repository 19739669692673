import React from "react";
import * as signup  from "./Signup.scss"
import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import {signupschema} from './Validation'; 

import {FormInput,FormFileInput,FormSelect,FormButton,FormMultiSelect,FormTextArea, FormCheckBox, FormAutocomplete, FormRadio} from "_components/FormElements/FormInput";

export const Vendor = props => {  


    
    const { register, handleSubmit, control, errors } = useForm({      
            mode:'onBlur',  
            resolver: yupResolver(signupschema)
    });
    const onSubmit = data => {    
        if (data) {        
           console.log("data:",data);
        }    
    }
       
   
  return (

    <div className="main-content">
    <div className="container">
       {/* <h1>SignPage</h1> */}
       <h2 style={{textAlign:"center",color:"#2ca5cd"}}>You are a vendor</h2>
       <h3 style={{textAlign:"center",color:"#2ca5cd"}}>Use this form if you are a brand owner or manufacturer of isOEM consumer tech products</h3>
       <br></br>
       <p style={{textAlign:"center"}}>For qualified vendors, registration is <strong style={{color:"#2ca5cd"}}>100% free</strong> with no time limit. You can decide at any time to
       upgrade to any of our premium packages. More info about our packages click here</p>
     
       <br></br>
       <p style={{textAlign:"center"}}>Once your profile is approved, our team will setup your account and send you your credentials when it’s
       ready. All you need to do is to spend 1 minute to fill in the short form below and we’ll take care of the
       rest!</p>
       <form noValidate onSubmit={handleSubmit(onSubmit)}>

        <div className="row">
        <div className="col-lg-12">
            <div className="form-group">             
            <FormInput 
                id="companyname"
                type="text"                
                label="Company Name"
                // value={props.signupData.companyname}
                name="companyname"                
                register={register}
                error={errors.companyname}
            />            
        </div>
        </div>
            <div className="col-lg-6">
            <div className="form-group">        
                    <FormInput 
                        id="street"
                        type="text"
                        name="street"
                        label="Street Address"
                        //placeholder="Password"
                        register={register}
                        error={errors.street}
                    />            
            </div>
            </div>
    

            <div className="col-lg-6">
            <div className="form-group">            
                    <FormInput 
                        id="postalcode"
                        type="text"
                        name="postalcode"
                        label="Postal Code"
                        //placeholder="Password"
                        register={register}
                        error={errors.postalcode}
                    />
                 </div>           
            </div>
            <div className="col-lg-6 thm">
            <div className="form-group">            
                    <FormInput 
                        id="Company registration number"
                        type="number"
                        name="Company registration number"
                        label="Company registration number"
                        //placeholder="Password"
                        register={register}
                        error={errors.postalcode}
                    />
                 </div>           
            </div>
            <div className="col-lg-6 thm">
            <div className="form-group">            
                    <FormInput 
                        id="Vat number"
                        type="number"
                        name="Vat number"
                        label="Vat number"
                        //placeholder="Password"
                        register={register}
                        error={errors.postalcode}
                    />
                 </div>           
            </div>

            <div className="col-lg-6 thm">
            <div className="form-group">        
                    <FormAutocomplete
                        id="country"
                        name="country"
                        //defaultstate={["fdsafds","fsdafds"]}
                        label="Country"
                        options={top100Films}
                        placeholder="Select Country"
                        // register={register}
                        // error={errors.postalcode}
                        multiple
                        disableCloseOnSelect
                        limitTags={2}
                        defaultValue={{ title: 'The Matrix', year: 1999 }}
                        control={control} 
                        />
                 </div>           
            </div>
            <div className="col-lg-6 thm">
            <div className="form-group">            
                    {/* <FormInput 
                        id="Website"
                        type="text"
                        name="Website"
                        label="Website"
                        //placeholder="Password"
                        register={register}
                        error={errors.postalcode}
                    /> */}               
                     <FormRadio
                    name="gender"
                    label="Gender"
                    options={radioGroupOptions}
                    control={control}
                    row
                     />
                 </div>           
            </div>

            <div className="col-sm-4 ">
            <div className="form-group">            
                    <FormInput 
                        id="Firstame"
                        type="text"
                        name="Firstname"
                        label="Firstname"
                        //placeholder="Password"
                        register={register}
                        error={errors.postalcode}
                    />
                 </div>           
            </div>

            <div className="col-sm-4 thm">
            <div className="form-group">            
                    <FormInput 
                        id="Lastname"
                        type="text"
                        name="Lastname"
                        label="Lastname"
                        //placeholder="Password"
                        register={register}
                        error={errors.postalcode}
                    />
                 </div>           
            </div>

            <div className="col-sm-4 thm">
            <div className="form-group">            
                    <FormInput 
                        id="Jobtitle"
                        type="text"
                        name="Jobtitle"
                        label="Jobtitle"
                        //placeholder="Password"
                        register={register}
                        error={errors.postalcode}
                    />
                 </div>           
            </div>
            <div className="col-sm-4 thm">
            <div className="form-group">            
                    <FormInput 
                        id="Email"
                        type="email"
                        name="email"
                        label="email"
                        //placeholder="Password"
                        register={register}
                        error={errors.postalcode}
                    />
                 </div>           
            </div>
            <div className="col-sm-4 thm">
            <div className="form-group">            
                    <FormInput 
                        id="Mobile phone number"
                        type="Phone number"
                        name="phone number"
                        label="phone number"
                        //placeholder="Password"
                        register={register}
                        error={errors.postalcode}
                    />
                 </div>           
            </div>
            <div className="col-sm-4 thm">
            <div className="form-group">            
                    <FormInput 
                        id="Skypeid"
                        type="text"
                        name="Skype ID"
                        label="Skype ID"
                        //placeholder="Password"
                        register={register}
                        error={errors.postalcode}
                    />
                 </div>           
            </div>
          <div className="col-sm-12 thm">
          <div className="form-group">
              <FormFileInput
              name="uploadpicture"
              id="uploadpicture"
              type="file"
              label="Upload your picture"
             register={register}
             error={errors.postalcode}
            />
          </div>
        </div>

          <div className="col-sm-12 thm">
          <div className="form-group">
              <FormTextArea
              name="Example textarea"
              id="textarea1" rows="3"
              type="textarea"
              label="Example textarea"
             register={register}
             error={errors.postalcode}
  
              />
          </div>

          </div>
          <div className="col-sm-12 thm">
          <div className="form-group">
              <FormTextArea
              name="What is your objective to be on ChannelHub"
              id="objective" rows="3"
              type="textarea"
              label="What is your objective to be on ChannelHub? *"
             register={register}
             error={errors.postalcode}
  
              />
          </div>

          </div>

          <div className="col-sm-12 thm">
          <div className="form-group">
              
              <FormTextArea
              name="What would be your 280"
              id="tweetpitch" rows="3"
              type="textarea"
              label="What would be your 280 characters tweet to present your company to a buyer?*"
             register={register}
             error={errors.postalcode}
  
              />
          </div>

          </div>
          <div className="col-sm-12 thm">
          <div className="form-group">
              <FormInput
              id="brandname"
              name="brandname"
              type="text"
              label="Brand names of our product (multiple brands allowed)*"
             register={register}
             error={errors.postalcode} />
          </div>
           </div>
                                            {/* checkbox */}

           <div className="col-sm-12 thm">
            <h4>Product Categories you are offering? *</h4>
            <div className="form-group">
                <FormCheckBox
             id="checkbox1"
              type="checkbox"
              label="Audio and Video (speakers, earbuds, headphones etc.)"
           register={register}
             error={errors.postalcode} />
             </div>
         </div>

         
         <div className="col-sm-12 thm">
            <div className="form-group">
             <FormCheckBox
             id="checkbox2"
              type="checkbox"
              label="Automobile and biking (accessory for cars, bikes, cycles)"
           register={register}
             error={errors.postalcode} />
             </div>
         </div>

         
         <div className="col-sm-12 thm">
            <div className="form-group">
             <FormCheckBox
             id="checkbox3"
              type="checkbox"
              label="Fitness, sports and outdoors"
           register={register}
             error={errors.postalcode} />
             </div>
         </div>

         
         <div className="col-sm-12 thm">
            <div className="form-group">
             <FormCheckBox
             id="checkbox4"
              type="checkbox"
              label="Gaming"
           register={register}
             error={errors.postalcode} />
             </div>
         </div>

         
         <div className="col-sm-12 thm">
            <div className="form-group">
             <FormCheckBox
             id="checkbox5"
              type="checkbox"
              label="Health and Well-being"
           register={register}
             error={errors.postalcode} />
             </div>
         </div>
         
         <div className="col-sm-12 thm">
            <div className="form-group">
             <FormCheckBox
             id="checkbox6"
              type="checkbox"
              label="IT peripherals and accessories (PCs, 3D printers, scanners, printers, networking, accessories for PCs)"
           register={register}
             error={errors.postalcode} />
             </div>
         </div>
         
         <div className="col-sm-12 thm">
            <div className="form-group">
             <FormCheckBox
             id="checkbox7"
              type="checkbox"
              label="Mobile products and accessories"
           register={register}
             error={errors.postalcode} />
             </div>
         </div>

         
         <div className="col-sm-12 thm">
            <div className="form-group">
             <FormCheckBox
             id="checkbox8"
              type="checkbox"
              label="Photo/ Imaging"
           register={register}
             error={errors.postalcode} />
             </div>
         </div>


         
         <div className="col-sm-12 thm">
            <div className="form-group">
             <FormCheckBox
             id="checkbox9"
              type="checkbox"
              label="Yes, I have read and I accept the"
           register={register}
             error={errors.postalcode} />
             </div>
         </div>



         
         <div className="col-sm-12 thm">
            <div className="form-group">
             <FormCheckBox
             id="checkbox10"
              type="checkbox"
              label="Smart home"
           register={register}
             error={errors.postalcode} />
             </div>
         </div>




         
         <div className="col-sm-12 thm">
            <div className="form-group">
             <FormCheckBox
             id="checkbox11"
              type="checkbox"
              label="Smart personal objects (trackers, wearables)"
           register={register}
             error={errors.postalcode} />
             </div>
         </div>


         
         <div className="col-sm-12 thm">
            <div className="form-group">
             <FormCheckBox
             id="checkbox12"
              type="checkbox"
              label="Urban Mobility (scooter, hover board etc..)"
           register={register}
             error={errors.postalcode} />
             </div>
         </div>

         
         <div className="col-sm-12 thm">
            <div className="form-group">
             <FormCheckBox
             id="checkbox13"
              type="checkbox"
              label="Other (please specify)"
           register={register}
             error={errors.postalcode} />
             </div>
         </div>

         
      <div className="col-sm-12 thm">
          <div className="form-group">
              <FormInput
              id="customcategories"
              name="customcategories"
              type="text"
              label="If you have selected Other in the section above, add your categories below"
             register={register}
             error={errors.postalcode} />
          </div>
           </div>

           <div className="col-sm-4 thm">
          <div className="form-group">
              <FormInput
              id="sku"
              name="sku"
              type="text"
              label="Number of SKU in your offer*"
             register={register}
             error={errors.postalcode} />
          </div>
           </div>

           
           <div className="col-sm-4 thm">
          <div className="form-group">
              <FormInput
              id="retailpartners"
              name="retailpartners"
              type="text"
              label="Number of retail partners*"
             register={register}
             error={errors.postalcode} />
          </div>
           </div>

           
           <div className="col-sm-4 thm">
          <div className="form-group">
              <FormInput
              id="distributors"
              name="distributors"
              type="text"
              label="Number of distributors*"
             register={register}
             error={errors.postalcode} />
          </div>
           </div>
           <div className="col-sm-12 thm">
          <div className="form-group">
              <FormTextArea
              id="sellingproducts" rows="3"
              type="textarea"
              label="Key retailers selling your products*"
              name="Key retailers selling your products*"
              placeholder="Name + Country"
              register={register}
              error={errors.postalcode}
              />
          </div>

          </div>
          <div className="col-sm-12 thm">
          <div className="form-group">
              <FormInput
              id="Geographical"
              name="Geographical"
              type="text"
              label="Geographical coverage*"
              placeholder="Countries or region where you are currently covering"
             register={register}
             error={errors.postalcode} />
          </div>
           </div>

           
        <div className="col-sm-12 thm">
            <div className="form-group">
             <FormInput
             id="Geographical target"
              type="text"
              label="Geographical target*"
              name="Geographical target"
              placeholder="Countries or regions where you want to expand"
             register={register}
             error={errors.postalcode} />
             </div>
         </div>

             
        <div className="col-sm-12 thm">
            <div className="form-group">
             <FormCheckBox
             id="checkbox14"
              type="checkbox"
              label="Are you startup"
           register={register}
             error={errors.postalcode} />
             </div>
         </div>
       <div className="col-sm-12 thm">
          <div className="form-group">
              <FormFileInput
              name="uploadlogo"
              id="uploadlogo"
              type="file"
              label="Upload your logo"
             register={register}
             error={errors.postalcode}
            />
          </div>
        </div>

         <div className="col-sm-12 thm">
            <div className="form-group">
             <FormInput
             id="Invitation"
              type="text"
              label="Have you received an invitation ?"
              name="Have you received an invitation ?"
              placeholder="Name of the person/company who invited you"
             register={register}
             error={errors.postalcode} />
             </div>
         </div>

         <div className="col-sm-12 thm">
            <div className="form-group">
             <FormCheckBox
             id="checkbox15"
              type="checkbox"
              label="Yes, I have read and I accept the"
           register={register}
             error={errors.postalcode} />
             </div>
         </div>

         <legend style={{textAlign:"center",fontSize:"14px"}}>By sending this form, I agree that my information may be processed by ChannelHub for the creation and monitoring of my account and to access online services. I can exercise my rights of access,rectification,deletion and portability by writing to privacy@channel-hub.io with proof of my identity. For more information, see our Privacy Policy.</legend>

         <div className="col-sm-12 thm">
            <div className="form-group btn-001">
             < FormButton
               id="Submit"
               name="Submit"
               value="Submit"
           
           register={register}
             error={errors.postalcode} />
             </div>
         </div>

        

        
            
        </div>     
        </form>   
        
    </div>
    </div>
  );
}
const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
    { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
    { title: 'The Good, the Bad and the Ugly', year: 1966 },
    { title: 'Fight Club', year: 1999 },
    { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
    { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
    { title: 'Forrest Gump', year: 1994 },
    { title: 'Inception', year: 2010 },
    { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
    { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { title: 'Goodfellas', year: 1990 },
    { title: 'The Matrix', year: 1999 },
    { title: 'Seven Samurai', year: 1954 },
    { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
    { title: 'City of God', year: 2002 },
    { title: 'Se7en', year: 1995 },
    { title: 'The Silence of the Lambs', year: 1991 },
    { title: "It's a Wonderful Life", year: 1946 },
    { title: 'Life Is Beautiful', year: 1997 },
    { title: 'The Usual Suspects', year: 1995 },
    { title: 'Léon: The Professional', year: 1994 },
    { title: 'Spirited Away', year: 2001 },
    { title: 'Saving Private Ryan', year: 1998 },
    { title: 'Once Upon a Time in the West', year: 1968 },
    { title: 'American History X', year: 1998 },
    { title: 'Interstellar', year: 2014 },
  ];
  const radioGroupOptions = [
    {
      value: "female",
      label: "Female",
    },
    {
      value: "male",
      label: "Male",
    },
    {
      value: "other",
      label: "Other",
    },
  ];
export default Vendor;