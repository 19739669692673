import React, { useState, useEffect } from 'react';
import { Card, Container, CardHeader, UncontrolledTooltip } from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import BootstrapTable from "react-bootstrap-table-next";
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/PaginationActions';
import AddBrand from "views/Hoc/Dialog/AddBrand";
import classnames from "classnames";
import { Spinner } from '../../Hoc/Spinner';
import { SelectAutocompleteWithList, FormAutocomplete } from '_components/FormElements/FormInput';
import axios from 'axios';
import { commonService } from '_services/common.service';
import { useForm } from "react-hook-form";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
import { Fileuploaddialog } from "views/Hoc/Dialog/Fileuploaddialog";
import { Form, Row, Col, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";
import { Satellite, SportsVolleyballRounded } from '@material-ui/icons';

export const TravelList = () => {
    const [state, setState] = useState({ dataTable: [], total: 0,master:[] });
    
    //   let [searchList, setSearchList] = useState([]);
    //   let [search, setSearch] = useState(null);
    let [sort_by, setSortby] = useState(null);
    let [CT, setCT] = useState([]);
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [loading, setLoading] = useState(false);
    //   const [openadd, openAddnew] = useState(false);
    //   const [isEdit, setEdit] = useState(false);
    //   let [editBrand, setEditBrand] = useState(null);
    //   let [sort, setSort] = useState('asc');
    //   let [sortBrandLogo, setBrandLogo] = useState(2);
    //   let [sortText, setSortText] = useState('All Brand');
    //   let [deletedBrand, setDeletedBrand] = useState(false);
    const { control, register } = useForm({ mode: 'onBlur' });
    //   let [isModalPopup, setModalPopup] = useState(false);
    //   let [isFilePopup, setFilePopup] = useState(false);
    //   let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });
    useEffect(() => {
        getTravelDetails({tabChange:true});
        //getSearchDropdownList();
    }, []);
   
   
    const getTravelDetails = async (data) => {
        setLoading(true);
        

    if(data.tabChange){
        let res = await axios.post('/channelAdmin/getTravelList', {
            offset: page * rowsPerPage,
            limit: rowsPerPage,
            order: sort_by ? sort_by.id : 3,
            channelType: CT.length > 0 ? CT.map(x => x.id) : [2, 3, 4, 5],
            eventId: 3
        });
        
        if (res) {
            console.log("getTravels:", res);
            state.dataTable = res.data.list;
            state.master = res.data.master;
            state.total = res.data.total;

            setState({ ...state });
            //setLoading(false);
        } else {
            ToastsStore.error('Something Went Wrong!');
            state.dataTable = [];
            state.total = 0;
           
            setState({ ...state });
        }
    }else{
        
        let limit=page==0?rowsPerPage:rowsPerPage+page * rowsPerPage;
        state.dataTable = state.master.slice(page * rowsPerPage,limit);
        setState({ ...state });
    }
    setLoading(false);
}
    //   const getSearchDropdownList = () => {
    //     let deleted = deletedBrand ? 2 : 1;
    //     let logo = sortBrandLogo;// ? 1 : 0;
    //     axios.get('/master/brandNameList/' + deleted + '/' + logo).then(res => {
    //       if (res.data) {
    //         searchList = res.data;
    //         setSearchList(searchList);
    //       }
    //     });
    //   }
    const callPendingTravel = (data) => {
        page = 0;
        setPage(page);
        getTravelDetails(data);
    }

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(page);
        getTravelDetails({tabChange:false});
    };

    const handleChangeRowsPerPage = (event) => {
        rowsPerPage = parseInt(event.target.value, 10);
        console.log("rowperpage:",rowsPerPage)
        setRowsPerPage(rowsPerPage);
        callPendingTravel({tabChange:false});
    };
    // const handleChange = (input) => (e) => {
    //     search = e.target.value;
    //     // setSearch(search);
    //     // callPendingBrand();
    // }



    // const setSorting = (data) => {
    //     if (data != sort) {
    //       sort = data;
    //       setSort(sort);
    //       callPendingBrand();
    //     }

    //   }
      const sorting = (order) => {
        if(order === 'desc'){
            state.master=state.master.sort((a, b) => (a.companyName > b.companyName) ? 1 : -1)
            
        }
        else{
            state.master=state.master.sort((a, b) => (a.companyName < b.companyName) ? 1 : -1)

        }
        setState({...state});
        //callPendingTravel({tabChange:false});
      }

    //   const setSortingBrandLogo = (data) => {
    //     if (data != sortBrandLogo) {
    //       sortBrandLogo = data;
    //       if (data == 1)
    //         setSortText('Brand With Logo')
    //       else if (data == 0)
    //         setSortText('Brand Without Logo');
    //       else if (data == 2)
    //         setSortText('All Brand');
    //       setBrandLogo(sortBrandLogo);
    //       callPendingBrand();
    //     }

    //   }
    const handleChangeSelect = async (input, value) => {
        // if (input == 'brands') {
        //   console.log('value', value);
        //   setLoading(true);
        //   if (value?.isNew === '1') {
        //     let data = {
        //       brandName: value.name,
        //       isActive: '1',
        //       brandImage: value.documentpath
        //     }
        //     const editBrand = await commonService.postService(data, 'master/brand');
        //     if (editBrand.data) {
        //       setLoading(false);
        //       ToastsStore.success('Saved Successfully!');
        //       search = null;
        //       setSearch(search);
        //       sort = 'desc';
        //       setSort(sort);
        //       callPendingBrand();
        //     } else {
        //       setLoading(false);
        //       ToastsStore.error('Something Went Wrong!');
        //     }
        //   } else {
        //     state.dataTable.map(x => {
        //       if (x.id == value.id) {
        //         x.brandName = value.name;
        //         x.document.documentUrl = value.documentUrl
        //       }
        //     })
        //     setState({ ...state });
        //   }
        //   setLoading(false);
        // }
        // if (input == 'search') {
        //   search = value
        //   setSearch(search);
        //   callPendingBrand();
        // }
        if (input == 'sort_by') {
            //console.log('sort_by:', value.id);
            sort_by = value;
            setSortby(sort_by);
            callPendingTravel({tabChange:true});
        }
        if (input == 'CT') {
            CT = value;
            setCT(CT);
            callPendingTravel({tabChange:true});
        }
    }

    //   const openDialag = (value) => {
    //     console.log('value', value);
    //     editBrand = value;
    //     setEditBrand(editBrand);
    //     setEdit(true);
    //     openAddnew(true);
    //   }
    //   const addBrand = () => {
    //     setEdit(false);
    //     openAddnew(true);
    //   }

    const downloadTravelInfo = async () => {
        var fileDownload = require('js-file-download');
        setLoading(true);

        // await axios.post('/channelAdmin/downloadTravel', {
        //     offset: page * rowsPerPage,
        //     limit: 500,
        //     order: sort_by ? sort_by.id : 3,
        //     channelType: CT.length > 0 ? CT.map(x => x.id) : [2, 3, 4, 5],
        //     eventId: 3,
        //     responseType: 'blob', // Important

        // }).then((response) => {
        //     fileDownload(response.data, `TravelInfo.xlsx`);
        //     setLoading(false);
        // });

        axios({
            url: 'channelAdmin/downloadTravel',
            method: 'GET',
            params: { order: sort_by ? sort_by.id : 3, offset: page * rowsPerPage, limit: 500, eventId: 3, channelType: CT.length > 0 ? CT.map(x => x.id) : [2, 3, 4, 5] },
            responseType: 'blob', // Important
        }).then((response) => {
            fileDownload(response.data, `TravelInfo.xlsx`);
            setLoading(false);
        });

    }

    //   const showFileUpload = () => {
    //     setFilePopup(true)
    //   }

    //   const closeModalPopup = (value) => {
    //     openAddnew(false);
    //   }
    //   const deletePopup = (contactid) => {
    //     modalPopValue.message = 'Do you want to delete this brand?';
    //     modalPopValue.title = 'Delete User';
    //     modalPopValue.value = contactid;
    //     modalPopValue.popUpId = 1;
    //     modalPopValue.active = false;
    //     setModalPopValue({ ...modalPopValue });
    //     setModalPopup(true);
    //   }

    //   const activePopup = (contactid) => {
    //     modalPopValue.message = 'Do you want to Restore this brand?';
    //     modalPopValue.title = 'Restore Brand';
    //     modalPopValue.value = contactid;
    //     modalPopValue.popUpId = 1;
    //     modalPopValue.active = true;
    //     setModalPopValue({ ...modalPopValue });
    //     setModalPopup(true);
    //   }

    //   const closePopup = () => {
    //     setModalPopup(false);
    //   };

    //   const closeFilePopup = () => {
    //     setFilePopup(false);
    //   };
    //   const confirmPopup = (res) => {
    //     setModalPopup(false);
    //     if (res.active) {
    //       activateBrand(res.value)
    //     } else {
    //       deleteBrand(res.value)
    //     }

    //   }
    //   const deleteBrand = async (data) => {
    //     await axios.delete('master/deleteBrand/' + data.id).then(res => {
    //       ToastsStore.success('User Removed successfully!');
    //       if (searchList.length)
    //         removeBrandFromList(data.id)
    //       getBrand();
    //     }).catch(err => {
    //       ToastsStore.error('Something went wrong!');
    //     })
    //   }

    //   const activateBrand = async (data) => {
    //     await axios.patch('master/activateBrand/' + data.id).then(res => {
    //       ToastsStore.success('Brand Restored Successfully!');
    //       if (searchList.length)
    //         removeBrandFromList(data.id)
    //       getBrand();
    //     }).catch(err => {
    //       ToastsStore.error('Something went wrong!');
    //     })
    //   }

    //   const removeBrandFromList = (id) => {
    //     searchList = searchList.filter(x => x.id != id);
    //     setSearchList(searchList);
    //   }


    return (
        <div id="channelList">
            <SimpleHeader name="Product Brands" parentName="Master" />
            {/* <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} /> */}
            {/* <Fileuploaddialog isOpen={isFilePopup} handleClose={closeFilePopup} />
      <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
      <AddBrand aws_folderName="brandlogo" opneadd={openadd} closeModalPopup={closeModalPopup} value={editBrand} isEdit={isEdit} brandName={null} handleChangeSelect={handleChangeSelect} /> */}
            <Container className="mt--3" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <h3 className="mb-0">Travel List</h3>
                                {/* <p className="text-sm mb-0">
                        This is an exmaple of data table using the well known
                        react-bootstrap-table2 plugin. This is a minimal setup in
                        order to get started fast.
                      </p> */}
                            </CardHeader>
                            {loading ? <Spinner /> : <ToolkitProvider
                                ClassName="align-items-center table-flush" responsive
                                data={state.dataTable}
                                keyField="id"
                                columns={[
                                    {
                                        dataField: "event",
                                        text: "Event",
                                        // sort: true,
                                        formatter: (rowContent, row) => {
                                            return (
                                                <>
                                                    {row.event ? row.event : ''}
                                                </>
                                            )
                                        }
                                    },
                                    {
                                        dataField: "companyName",
                                        text: "Company Name",
                                        sort: true,
                                        onSort: (field, order) => {
                                            
                                            if (order === 'desc'){
                                                state.master=state.master.sort((a, b) => (a.companyName.toLowerCase() > b.companyName.toLowerCase()) ? -1:b.companyName.toLowerCase() > a.companyName.toLowerCase()? 1 : 0)
                                                setState({...state});
                                                callPendingTravel({tabChange:false});
                                                
                                            }
                                            else{
                                                state.master=state.master.sort((a, b) => (a.companyName.toLowerCase() < b.companyName.toLowerCase()) ? -1:b.companyName.toLowerCase() < a.companyName.toLowerCase()?1 : 0);
                                                setState({...state});
                                                callPendingTravel({tabChange:false});
                                                
                                            }
                                            
                                        },
                                        formatter: (rowContent, row) => {
                                            return (
                                                <>
                                                    {row.companyName ? row.companyName : ''}
                                                </>
                                            )
                                        }
                                    },
                                    {
                                        dataField: "firstName",
                                        text: "Contact Name",
                                        sort: true,
                                        onSort: (field, order) => {
                                            
                                            if (order === 'desc'){
                                                state.master=state.master.sort((a, b) => (a.firstName.toLowerCase() > b.firstName.toLowerCase()) ? -1:b.firstName.toLowerCase() > a.firstName.toLowerCase()?1 : 0)
                                                setState({...state});
                                                callPendingTravel({tabChange:false});
                                                
                                            }
                                            else{
                                                state.master=state.master.sort((a, b) => (a.firstName.toLowerCase() < b.firstName.toLowerCase()) ? -1:b.firstName.toLowerCase() < a.firstName.toLowerCase()?1 : 0);
                                                setState({...state});
                                                callPendingTravel({tabChange:false});
                                                
                                            }
                                            
                                        },
                                        formatter: (rowContent, row) => {
                                            return (
                                                <>
                                                    {row.firstName ? row.firstName : ''}
                                                </>
                                            )
                                        }
                                    },
                                    {
                                        dataField: "arrDate",
                                        text: "Arrival Date",
                                        sort: true,
                                        onSort: (field, order) => {
                                            
                                            if (order === 'desc'){
                                                state.master=state.master.sort((a, b) => (a.arrDate > b.arrDate) ? 1 : -1)
                                                setState({...state});
                                                callPendingTravel({tabChange:false});
                                                
                                            }
                                            else{
                                                state.master=state.master.sort((a, b) => (a.arrDate < b.arrDate) ? 1 : -1);
                                                setState({...state});
                                                callPendingTravel({tabChange:false});
                                                
                                            }
                                            
                                        },                                        
                                        formatter: (rowContent, row) => {
                                            let arrDate = row.arrDate?.split("-").reverse().join("-");
                                            console.log("date",arrDate)
                                            return (
                                                <>
                                                    {arrDate ? arrDate : ''}
                                                </>
                                            )
                                        }
                                    },
                                    {
                                        dataField: "depDate",
                                        text: "Depature Date",
                                        //sort: true,
                                        formatter: (rowContent, row) => {
                                            let depDate = row.depDate?.split("-").reverse().join("-");
                                            return (
                                                <>
                                                    {depDate ? depDate : ''}
                                                </>
                                            )
                                        }
                                    },
                                    {
                                        dataField: "arrTime",
                                        text: "Arrival Time",
                                        //sort: true,
                                        formatter: (rowContent, row) => {
                                            return (
                                                <>
                                                    {row.arrTime ? row.arrTime : ''}
                                                </>
                                            )
                                        }
                                    },
                                    {
                                        dataField: "depTime",
                                        text: "Depature Time",
                                        //sort: true,
                                        formatter: (rowContent, row) => {

                                            return (
                                                <>
                                                    {row.depTime ? row.depTime : ''}
                                                </>
                                            )
                                        }
                                    },
                                    {
                                        dataField: "arrMethod",
                                        text: "Arrival Method",
                                        //sort: true,
                                        onSort: (field, order) => {
                                            
                                            if (order === 'desc'){
                                                state.master=state.master.sort((a, b) => (a.arrMethod > b.arrMethod) ? 1 : -1)
                                                setState({...state});
                                                callPendingTravel({tabChange:false});
                                                
                                            }
                                            else{
                                                state.master=state.master.sort((a, b) => (a.arrMethod < b.arrMethod) ? 1 : -1);
                                                setState({...state});
                                                callPendingTravel({tabChange:false});
                                                
                                            }
                                            
                                        },           
                                        formatter: (rowContent, row) => {
                                            return (
                                                <>
                                                    {row.arrMethod ? row.arrMethod : ''}
                                                </>
                                            )
                                        }
                                    },
                                    {
                                        dataField: "depMethod",
                                        text: "Depature Method",
                                        //sort: true,
                                        formatter: (rowContent, row) => {
                                            return (
                                                <>
                                                    {row.depMethod ? row.depMethod : ''}
                                                </>
                                            )
                                        }
                                    },
                                    {
                                        dataField: "arrFlightNumber",
                                        text: "Arrival Flight Number",
                                        //sort: true,
                                        formatter: (rowContent, row) => {
                                            return (
                                                <>
                                                    {row.arrFlightNumber ? row.arrFlightNumber : ''}
                                                </>
                                            )
                                        }
                                    },
                                    {
                                        dataField: "depFlightNumber",
                                        text: "Depature Flight Number",
                                       // sort: true,
                                        formatter: (rowContent, row) => {
                                            return (
                                                <>
                                                    {row.depFlightNumber ? row.depFlightNumber : ''}
                                                </>
                                            )
                                        }
                                    },
                                    {
                                        dataField: "updatedAt",
                                        text: "Last Update",
                                        sort: true,
                                        onSort: (field, order) => {
                                            
                                            if (order === 'desc'){
                                                state.master=state.master.sort((a, b) => (a.updatedAt > b.updatedAt) ? 1 : -1)
                                                setState({...state});
                                                callPendingTravel({tabChange:false});
                                                
                                            }
                                            else{
                                                state.master=state.master.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1);
                                                setState({...state});
                                                callPendingTravel({tabChange:false});
                                                
                                            }
                                            
                                        },  
                                        formatter: (rowContent, row) => {
                                            return (
                                                <>
                                                    {row.updatedAt ? row.updatedAt : ''}
                                                </>
                                            )
                                        }
                                    },
                                    {
                                        dataField: "createdAt",
                                        text: "Created",
                                        sort: true,
                                        onSort: (field, order) => {
                                            
                                            if (order === 'desc'){
                                                state.master=state.master.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1)
                                                setState({...state});
                                                callPendingTravel({tabChange:false});
                                                
                                            }
                                            else{
                                                state.master=state.master.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1);
                                                setState({...state});
                                                callPendingTravel({tabChange:false});
                                                
                                            }
                                            
                                        },  
                                        formatter: (rowContent, row) => {
                                            return (
                                                <>
                                                    {row.createdAt ? row.createdAt : ''}
                                                </>
                                            )
                                        }
                                    },
                                    //         {
                                    //             dataField: "Invoices",
                                    //             text: "Action",
                                    //             formatter: (rowContent, row, index) => {
                                    //                 return (
                                    //                     <>
                                    //                         {/* <button 
                                    // onClick={()=>{openDialag(row)}} 
                                    // className="btn btn-icon btn-primary btn-sm" variant="contained" color="primary" >
                                    //   <span className="btn-inner--icon">Edit</span>
                                    // </button> */}
                                    //                         {/* <div className="res-i">
                                    //                             <a onClick={() => { openDialag(row) }} ><i id={'Edit' + index} class="fas fa-pencil-alt ml-4" style={{ color: "#46b88f" }}>
                                    //                                 <UncontrolledTooltip target={'Edit' + index} placement='bottom'> Edit </UncontrolledTooltip></i></a>
                                    //                             {!deletedBrand ?
                                    //                                 <>
                                    //                                     <a onClick={() => deletePopup(row)}><i id={'Del' + index} class="fas fa-trash-alt ml-4" style={{ color: "#fb6340" }}>
                                    //                                         <UncontrolledTooltip target={'Del' + index} placement='bottom'> Delete </UncontrolledTooltip></i></a>
                                    //                                 </> :

                                    //                                 <>
                                    //                                     <a onClick={() => activePopup(row)}><i id={'Act' + index} class="fas fa-trash-alt ml-4" style={{ color: "green" }}>
                                    //                                         <UncontrolledTooltip target={'Act' + index} placement='bottom'> Restore Brand </UncontrolledTooltip></i></a>
                                    //                                 </>
                                    //                             }

                                    //                         </div> */}
                                    //                     </>
                                    //                 )
                                    //             }
                                    //         }
                                ]}
                            >
                                {props => (
                                    <div className="py-2 table-responsive">
                                        <div
                                            id="datatable-basic_filter"
                                            className="dataTables_filter px-4 mb-2"
                                        >
                                            <Row>
                                                {/* <Col lg="3">
                                                    <div >
                                                        <SelectAutocompleteWithList
                                                            id="brandsearch"
                                                            type="text"
                                                            label="search"
                                                            name="search"
                                                            // options={searchList}
                                                            placeholder="search..."
                                                            disableCloseOnSelect
                                                            // defaultValue={search ? search : null}
                                                            control={control}
                                                            input='search'
                                                        // value={search ? search : null}
                                                        // handleChangeSelect={handleChangeSelect}
                                                        />


                                                    </div>

                                                </Col> */}
                                                <Col lg='6' className='p-0 text-center'>
                                                    {/* <AdminNavbar/> */}
                                                    <Form
                                                        className={classnames(
                                                            "navbar-search form-inline mr-sm-3",
                                                        )}
                                                    >
                                                        {/* <FormGroup className="mb-2 mt-2">
                  <InputGroup className="input-group-alternative input-group-merge">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Search Company or Products" type="text" 
                    // autoFocus
                    ref={ref}
                    value={search}
                    onChange={handleChange("search")}
                    />
                  </InputGroup>
                </FormGroup> */}
                                                        <div className="ml-2" style={{ width: '20%' }}>
                                                            {/* <Autocomplete
                                  multiple
                                  id="checkboxes-tags-demo"
                                  options={top100Films}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) => option.title}
                                  renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                    {option.title}
                                  </React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" label="Sort by" placeholder="Sort by" />
                                )}
                            /> */}

                                                            <FormAutocomplete
                                                                id="sort_by"
                                                                name="sort_by"
                                                                label="View by"
                                                                options={top100Films}
                                                                placeholder="View by"
                                                                //multiple
                                                                //disableCloseOnSelect
                                                                //blurOnSelect
                                                                limitTags={1}
                                                                input='sort_by'
                                                                value={sort_by}
                                                                handleChangeSelect={handleChangeSelect}
                                                                defaultValue={sort_by}
                                                                control={control}
                                                            />
                                                        </div>
                                                        <div className="ml-2" style={{ width: '20%' }}>
                                                            {/* <Autocomplete
                                  multiple
                                  id="checkboxes-tags-demo"
                                  options={top100Films}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) => option.title}
                                  renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                    {option.title}
                                  </React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" label="Sort by" placeholder="Sort by" />
                                )}
                            /> */}

                                                            <FormAutocomplete
                                                                id="CT"
                                                                name="CT"
                                                                label="Channel Type"
                                                                options={companyType}
                                                                placeholder="Channel  Type"
                                                                multiple
                                                                disableCloseOnSelect
                                                                blurOnSelect
                                                                limitTags={1}
                                                                input='CT'
                                                                value={CT}
                                                                handleChangeSelect={handleChangeSelect}
                                                                defaultValue={CT}
                                                                control={control}
                                                            />
                                                        </div>
                                                        <button class="btn btn-primary btn-sm pt-2  " type="button" onClick={() => downloadTravelInfo()}><i class="fa fa-download" aria-hidden="true"></i></button>

                                                        {/* {!sortBrandLogo && <>
                                                            <button class="btn btn-primary btn-sm pt-2  " type="button" onClick={() => downloadBrand()}><i class="fa fa-download" aria-hidden="true"></i></button>

                                                            <button class="btn btn-primary " type="button" onClick={() => showFileUpload()}>Import</button></>} */}


                                                        {/* <UncontrolledDropdown className="pl-3 mt-2 mb-2" group>
                <DropdownToggle caret className="p-2">
                  <h3
                    style={{ display: "inline-block" }}
                    className="font-weight-bold m-0"
                  >
                  
                   Brand Name {sort == 'asc' ? <i class="fas fa-sort-alpha-down"></i> : <i class="fas fa-sort-alpha-up"></i>}
                  </h3>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem

                    onClick={() => setSorting('asc')}
                  >
                    A-Z
                  </DropdownItem>
                  <DropdownItem

                    onClick={() => setSorting('desc')}
                  >
                    Z-A
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}


                                                        {/* <button  class="btn btn-primary " type="button" onClick={()=> addBrand()}>Import</button> */}



                                                    </Form>
                                                </Col>

                                                {/* <Col className='text-right pt-2  auto'>
                                                    <button class="btn btn-primary" style={{ margin: 0 }} type="button" onClick={() => setDeletedBrand(prev => !prev)}>Show {deletedBrand ? 'Active' : 'Deleted'} Brands</button>
                                                    <button class="btn btn-primary float-right" type="button" onClick={() => addBrand()}>Add Brand</button>
                                                </Col> */}


                                            </Row>

                                        </div>
                                        <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            // pagination={pagination}
                                            bordered={false}
                                            hover={true}

                                            rowClasses='pointer'
                                        />

                                        <TablePagination
                                            component="div"
                                            count={state.total}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>}
                        </Card>
                    </div>
                </Row>
            </Container>
        </div>
    );
}
export default TravelList;
const top100Films = [
    { name: 'Empty', id: '1' },
    // { name: 'Partially Completed', id: '2' },
    { name: 'Fully Completed', id: '3' }];
const companyType = [
    { name: 'Vendor', id: '2' },
    // { name: 'Partially Completed', id: '2' },
    { name: 'Distributor', id: '3' },
    { name: 'Retailer', id: '4' },
    { name: 'Reseller', id: '5' }];

const arrivalTypeOptions = [{
    id: '1', name: 'Car'
},
{
    id: '2', name: 'Train'
},
{
    id: '3', name: 'Flight'
}]