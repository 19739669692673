import React, { useEffect, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
  Button, Modal,
} from "reactstrap";
import { ProfileUploader } from '../ProfileUpload';
import { commonService } from '_services/common.service';
import { Spinner } from 'views/Hoc/Spinner';
import Reorder from 'views/pages/product/Reorder';
import Reordernew from 'views/pages/product/Reordernew';

export const ReOrderDialog = (props) => {
  const { opneadd, closeModalPopup, productList } = props;
  let [items, setItems] = useState([]);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    setOpen(true);
  }, [])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeModalPopups = () => {
    closeModalPopup(items);
  }

  const updateProductRank = (productList) => {
    setItems(productList);
    console.log("plist:", productList);
  }

  return (
    <div>

      <Modal
        className="modal-dialog-centered Reorderdialog"
        isOpen={opneadd} >
        <div className="modal-header">
          <h3 className="text-center">Re Order Product List</h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModalPopups}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Reordernew productList={productList} updateProductRank={updateProductRank} />

        </div>

        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={closeModalPopups}
          >
            Close
          </Button>

        </div>
      </Modal>
    </div>
  );
}
export default ReOrderDialog;