import React ,{useEffect, useState} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FormTextArea } from "_components/FormElements/FormInput";
import { useTheme } from '@material-ui/core/styles';
import { useForm} from "react-hook-form";
import {
  Card, CardBody, Col, Row, Badge
} from "reactstrap";
import {
Button, Modal,
} from "reactstrap";
import { ProfileUploader } from '../ProfileUpload';
import { commonService } from '_services/common.service';
import {Spinner} from 'views/Hoc/Spinner';
import axios from "axios";
import { FormCheckBox, FormAutocomplete} from "_components/FormElements/FormInput";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import ConfirmDialog from 'views/Hoc/Dialog/GlobalDialog';
import FadeLoader  from "react-spinners/FadeLoader";
import { history } from '../../../_helpers';
import { ToastsContainer, ToastsStore } from 'react-toasts';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}




export const MergeCompanyData=(props)=> {
const { opneadd, closeModalPopup } = props;
const [confirmAlert, setConfirmAlert] = useState(false);
  const [open, setOpen] = React.useState(false); 
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const [errorMessage, setErrorMessage] = useState({destination:'',reason:''}); 
const theme = useTheme();
const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

let [barloading, setLoading] = useState(true);
  let [color, setColor] = useState("green");
  
const [company, setCompany] = useState({ companyName: props.data.companyName, channelId: props.data.channelId, desinationCompanyName: '', destinationChannelId: '', mergeBy:'',mergeReason:'' });

const [progressStatus, setProgressStatus] = useState({ loadingText: '', progressDivShow: 'block' });

const override = {
  display: 'block',
  margin: "0 auto",
  borderColor: "green",
};
let datas = [];
useEffect(() => {
setOpen(false);

}, [])

const [state, setState] = useState({ companydata: datas});

useEffect(() => {
  
  setLoading(barloading);
  
}, []);


const getCompanyList = async() =>{
      setLoading(!barloading);
      let res=  await commonService.getServices('merge/allChannelListForMerge/'+props.data.channelId+'/'+props.data.channelTypeId);
      if(res){        
        setOptions([...res.data]);
      } else {
        state.companydata=[];
        setState({...state});
      }
}


React.useEffect(() => {
  getCompanyList()  
  let active = true;
  //
  if (!loading) {
    return undefined;
  }

  (async () => {
    await sleep(1e3); 

    if (active) {
      setOptions([...datas]);
    }
  })();

  return () => {
    active = false;
  };
}, [loading]);

React.useEffect(() => {
  if (!open) {
    setOptions([]);
  }
}, [open]);



 async function handleCompanySelect(event, value){
  
  let val = value.split(' # ');
   let adminUser = JSON.parse(localStorage.getItem('CHuser'));
  let mergeBy = adminUser.userId;
  setCompany({companyName: props.data.companyName, channelId: props.data.channelId,desinationCompanyName: val[0], destinationChannelId: +val[1], mergeBy: mergeBy, user:{channelId: +val[1]}})
 }

const closeModalPopups= () => {
  setState({});
  closeModalPopup();
}


const openConfirmMergeCompany = () => {
  if(!company.destinationChannelId){
    setConfirmAlert(false);
    setErrorMessage({destination:' Invalid Destination '});
  } else if (!company.mergeReason){
    setConfirmAlert(false);
    setErrorMessage({mergeReason:' Please  enter the reason for merge '});
  }  else {
     setConfirmAlert(true);
     setErrorMessage('');
  } 
}



const handleMerge =  async() => {
  
setConfirmAlert(false);
setLoading(true);

    let result = commonService.postService(company, 'merge/index').then(async(res) => {
        if (!res.data?.error) {
                
          //commonService.postService(company, 'merge/companyContacts')
          setProgressStatus({loadingText :'Company Contact Merged Successfully. Please wait...'});
          await commonService.postService(company, 'merge/companyContacts').then(async(res) => {
                setProgressStatus({loadingText : 'Processing contacts......'});
                  if (res.data.status == 'success') {
                        await commonService.postService(company, 'merge/companyMergeStage2').then(async(res) => {
                          setProgressStatus({loadingText : 'Processing ......'});
                        });
                            
                        await commonService.postService(company, 'merge/companyMergeStage3').then(async(res) => {
                          setProgressStatus({loadingText : 'Processing ......'});
                          check_merge_status(25);
                        });
                  } else {
                        setLoading(false);
                        setProgressStatus({loadingText:res.data?.error});
                  }
          });
                        
          
          } else {
            setLoading(false);
            setProgressStatus({loadingText:res.data?.error});
          }
      });
 
}

const check_merge_status = (timer) => {
  
  if(timer > 0){
    let dataObj = {
      sourceChannelId:company.channelId,
      destinationChannelId:company.destinationChannelId,
      channelId:company.destinationChannelId,
      mergeBy:company.mergeBy,
      companyName:company.companyName,
      desinationCompanyName:company.desinationCompanyName,
  }
    commonService.postService(dataObj, 'merge/checkStatus').then(async(res) => {
      if (!res.data?.error) {
        if (res.data?.status == 'Completed') {
          setLoading(false);
          timer = 0;
          setProgressStatus({loadingText:'Completed'});
          let synData = {"user":{'channelId':company.channelId}}
          commonService.postService(synData, 'merge/syncMergeData')
          ToastsStore.success('Channel Account merged successfully');
            setTimeout(() => {
              closeModalPopups()
            }, 1000);                 
          
        } else {
          timer = timer - 1;
          
          setTimeout(() => {
            check_merge_status(timer)        
          }, 7000);
        }
      }
    });
  }
}


const handleMergeReasonChange= e =>{
  company.mergeReason = e.target.value;
  setCompany({ ...company, mergeReason:company.mergeReason })

}
return (
<div>
<ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
<ConfirmDialog open={confirmAlert} value={{ title: 'Confirm', message: 'Are You Sure Want to Merge '+company.desinationCompanyName+' company', second_message: '' }} handleSave={() => handleMerge()} handleClose={() => setConfirmAlert(false)} />
<Modal
className="modal-dialog-centered Reorderdialog"
isOpen={opneadd} >
<div className="modal-header">
<h3 className="text-center">Merge</h3>
<button
aria-label="Close"
className="close"
data-dismiss="modal"
type="button"
onClick={closeModalPopups}
>
<span aria-hidden={true}>×</span>
</button>
</div>
<Card>
  <CardBody>
    <Row>
      <Col>
      <div>
        <div className="form-group">  
        <div className="row ">
          <div className="col-lg-12" >     
          <Autocomplete
            id="destination-company"
            sx={{ width: "100%"}}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            onInputChange= {handleCompanySelect}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.title + ' # ' + option.id }
            options={options}
            
            loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Destination Company"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
          />
          </div> 
          <div className="col-lg-12" > <span style={{color:'red'}}>{errorMessage.destination}</span>&nbsp;</div>
          <div className="col-lg-12" >   
                <FormTextArea 
                id="mergeReason"
                rows="5"
               
               aria-label="empty textarea"
               aria-hidden="true"
               class="qtytext buytext2"
               value={company.mergeReason}
                 placeholder="Reason For Merge"
                 onChange={handleMergeReasonChange}
                />
                
          </div>
          <div className="col-lg-12" > <span style={{color:'red'}}>{errorMessage.mergeReason}</span> &nbsp;</div>
          <div className="col-lg-12" >         
              <Button
                color="primary"
                data-dismiss="modal"
                type="button"
                onClick={openConfirmMergeCompany}
                >
            Merge Company
          </Button>
          </div> 

          </div>
         
          
          <div id="progressDiv" style={{'width':'100%'}}>
          
            <FadeLoader 
                  color={color}
                  loading={barloading}
                  cssOverride={override}
                  size={350}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
                <div style={{'text-align':'center', 'font-size': '16px', 'padding':'10px'}}>{progressStatus.loadingText}</div>
          </div>

          
        </div>
      </div>

</Col>
    </Row>
  </CardBody>

</Card>

<div className="modal-footer">


<Button
color="secondary"
data-dismiss="modal"
type="button"
onClick={closeModalPopups}
>
Close
</Button>


</div>
</Modal>
</div>
);
}
export default MergeCompanyData;