import React, { useEffect, useState } from 'react';

import { DataGrid, gridColumnsTotalWidthSelector, gridEditRowsStateSelector, GridToolbar } from '@mui/x-data-grid';

//import { DataGrid, GridToolbar } from '@material-ui/data-grid';

import { Card, CardBody, Row, Col, Button, CardHeader, Modal } from "reactstrap";

import { FormAutocomplete, FormInput } from "_components/FormElements/FormInput";
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FormControlLabel from '@mui/material/FormControlLabel';

import Checkbox from '@mui/material/Checkbox';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Spinner } from '../Hoc/Spinner'
import Moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}));

const Timeslot = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [startHr, setStartHr] = React.useState('');
    const [startMin, setStartMin] = React.useState('');
    const [endHr, setEndHr] = React.useState('');
    const [endMin, setEndMin] = React.useState('');

    const [functionId, setFunction] = useState('');
    const [eventId, setEvent] = useState();
    const [listing, setListing] = useState(null);

    const stateInitialValue = {
        eventDate: null,
        slotId: null,
    }

    const [state, setState] = useState(stateInitialValue);

    const [eventList, setEventList] = useState([]);
    const [functionList, setFunctionList] = useState([]);
    const [locationList, setLocationList] = useState([{ id: '1', name: 'Workshop Rooms' }, { id: '2', name: 'Exhibition Area' }]);

    const [agendaMaster, setAgendaMaster] = useState([]);
    const [agendaList, setAgenda] = useState([]);





    useEffect(() => {
        getEventDetails();
    }, [])

    useEffect(() => {
        getSlotList();
    }, [eventId])

    function getEventDetails() {
        setLoading(true);
        axios.get('/channelAdmin/getEventMeet', {
        }).then(res => {
            setEventList(res.data.map(x => {
                return { id: x.id, name: x.name }
            }))
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            console.log("Event Error:", err);
        })
    }

    function getSlotList() {
        setLoading(true);
        if (eventId)
            axios.get('/channelAdmin/slotList/' + eventId, {
            }).then(res => {
                setFunctionList(res.data[0].agenda.map(x => {
                    return { id: x.agendaId, name: x.functionDate.split('T')[0] + '(' + x.fromTime + ' - ' + x.toTime + ') ' + x.function, function: x.function }
                }))
                let slotList = [];
                res.data[0].timeslot.map(x => {

                    let agenda = res.data[0].agenda.filter(slot => (slot.agendaId == x.agendaId && slot.eventId == x.eventId))[0];

                    let loc = locationList.filter(loc => loc.id == agenda.location);
                    slotList.push({ id: x.slotId, agendaId: agenda.agendaId, time: x.fromTime + ' - ' + x.toTime, title: agenda.function,date:agenda.functionDate.split('T')[0],location: loc[0].name });

                })
                setAgendaMaster(slotList);
                setAgenda(slotList);
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                console.log("Event Error:", err);
            })
    }

    const clearState = () => {
        setState(stateInitialValue);
        setStartHr('');
        setStartMin('');
        setEndHr('');
        setEndMin('');
        setFunction('');
    }


    const eventChange = (value) => {
        setEvent(value);
        setListing(true);
    }

    const functionChange = (value) => {
        setFunction(value);
    }

    const agendaChange = (value) => {

        setFunction(value);
        if (value == 0)
            setAgenda(agendaMaster);
        else
            setAgenda(agendaMaster.filter(x => x.agendaId == value));


    }

    const save = () => {
        
         var slotDate = Moment.utc(state.eventDate).valueOf();

        let data = {
            slotId: state.slotId,
            eventId: eventId,
            slotDate: slotDate,//state.eventDate,
            agendaId: functionId,
            fromTime: startHr + ':' + startMin,
            toTime: endHr + ':' + endMin
        }
        let agenda = agendaMaster.filter(x => x.id == data.agendaId && x.time == data.fromTime + ' - ' + data.toTime);

        if (agenda.length == 0)
            axios.post('/channelAdmin/saveSlot', data).then(res => {
                if (res) {

                    // console.log("savetime:",res.data);
                    // let agenda = agendaMaster.filter(x => x.agendaId == res.data.agendaId)[0];

                    // let loc = locationList.filter(loc => loc.id == agenda.location);
                    // setAgendaMaster([...agendaMaster, { id:res.data.slotId,agendaId:agenda.agendaId,time: data.fromTime + ' - ' + data.toTime, title: agenda.function, location: loc[0].name }])
                    // setAgenda(agendaMaster);
                    getSlotList();
                    setListing(true);
                }

            }).catch(err => {
                console.log("saveAgenda Error:", err);
            })
        else
            console.log("already:", agenda)
    }

    const editTime = (id) => {

        axios.get('/channelAdmin/getTimeById/' + eventId + '/' + id).then(res => {

            if (res.data.length) {
                let response = res.data[0];
                setState({
                    ...state,
                    eventDate: response.slotDate
                });

                setFunction(response.agendaId);
                setStartHr(response.fromTime.split(':')[0]);
                setStartMin(response.fromTime.split(':')[1]);
                setEndHr(response.toTime.split(':')[0]);
                setEndMin(response.toTime.split(':')[1]);

                setListing(false);
            }

        })
            .catch(err => {
                console.log("delete agenda:", err);
            })

    }

    const deleteTimeSlot = (id) => {

        let data = {
            eventId: eventId,
            slotId: id,
        }

        axios.post('/channelAdmin/deleteTimeSlot', data).then(res => {
            getSlotList();
        })
            .catch(err => {
                console.log("delete agenda:", err);
            })

    }

    const agendaCol = React.useMemo(
        () => [

            {
                field: 'date',
                headerName: 'Date',
                width: 150,
                editable: false,
                sortable: false
            },
            {
                field: 'time',
                headerName: 'Time',
                width: 150,
                editable: false,
                sortable: false
            },
            {
                field: 'title',
                headerName: 'Title',
                width: 450,
                editable: false,
                sortable: false
            },
            {
                field: 'location',
                headerName: 'Location',
                width: 300,
                editable: false,
                sortable: false
            },

            {
                field: 'action',
                headerName: 'Actions',
                type: 'actions', headerAlign: 'center',
                width: 150,

                renderCell: (params) => {

                    return (
                        <>
                            <i onClick={() => editTime(params.id)} className="fas fa-pencil-alt ml-4"></i>
                            <i onClick={() => deleteTimeSlot(params.id)} className="fas fa-trash-alt ml-4"></i>

                        </>
                    );
                }
            }


        ]
    );

    const printMin = () => {
        const row = [];
        for (var i = 0; i <= 60; i++) {
            row.push(<MenuItem value={i<10?'0'+i:''+i}>{i<10?'0'+i:''+i}</MenuItem>);
        }
        console.log("rows:",row[0])
        return row;
    };

    return (
        <>

            <Card>
                <CardHeader className="p-3">
                    <Button onClick={() => { setListing(false); clearState() }}>Add Timeslot<span className="btn-inner--icon"><i class="fas fa-plus"></i></span></Button>

                    <FormControl sx={{ m: 1, minWidth: 320 }} size="small">

                        <InputLabel id="demo-select-small">Select Event</InputLabel>
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={eventId}
                            label="Select Event"
                            onChange={(e) => eventChange(e.target.value)}
                        >
                            {eventList.map(x =>
                                <MenuItem value={x.id}>{x.name}</MenuItem>
                            )}

                        </Select>
                    </FormControl>

                </CardHeader>

            </Card>

            {loading ? <Spinner /> : listing && <>
                Functions to be divided into one-on-one meetings time slot:
                <FormControl sx={{ m: 1, minWidth: 320 }} size="small">

                    <InputLabel id="demo-select-small">Select Function</InputLabel>
                    <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={functionId}
                        label="Select Function"
                        onChange={(e) => agendaChange(e.target.value)}
                    >
                        <MenuItem value='0'>All</MenuItem>
                        {functionList.map(x =>
                            <MenuItem value={x.id}>{x.name}</MenuItem>
                        )}


                    </Select>
                </FormControl>

                <DataGrid
                    rows={agendaList}
                    columns={agendaCol}
                    pageSize={100}
                    disableColumnMenu
                    pagination={false}

                    autoHeight
                /> </>}

            {listing === false && <Card>
                <Row>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            <FormControl sx={{ m: 1, minWidth: 320 }} size="small" className={classes.root}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Time slot date"
                                        InputLabelProps={{ shrink: true }}
                                        inputFormat="MM/dd/yyyy"
                                        timezone="UTC+2"
                                        value={state.eventDate}
                                        onChange={(value) => setState({ ...state, ['eventDate']: new Date(value).toDateString() })}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </div>
                    </div>

                    {/* <FormControlLabel control={<Checkbox defaultChecked={false} />} label="All day" /> */}

                    <div className="col-lg-6 mt-4">
                        <div className="form-group">
                            Time Start
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">

                                <InputLabel id="demo-select-small">Hour</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={startHr}
                                    label="Hour"
                                    onChange={(e) => setStartHr(e.target.value)}
                                >
                                    {
                                        hour.map(x =>
                                            <MenuItem value={x}>{x}</MenuItem>
                                        )}

                                </Select>
                            </FormControl>

                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">

                                <InputLabel id="demo-select-small">Min</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={startMin}
                                    label="Min"
                                    onChange={(e) => setStartMin(e.target.value)}
                                >
                                    {printMin()}

                                </Select>
                            </FormControl>

                            End
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">

                                <InputLabel id="demo-select-small">Hour</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={endHr}
                                    label="Hour"
                                    onChange={(e) => setEndHr(e.target.value)}
                                >
                                    {
                                        hour.map(x =>
                                            <MenuItem value={x}>{x}</MenuItem>
                                        )}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">

                                <InputLabel id="demo-select-small">Min</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={endMin}
                                    label="Min"
                                    onChange={(e) => setEndMin(e.target.value)}
                                >

                                    {printMin()}
                                </Select>
                            </FormControl>


                        </div>
                    </div>

                </Row>

                <Row>
                    <div className="col-lg-6 mt-4">
                        <div className="form-group">

                            <FormControl sx={{ m: 1, minWidth: 320 }} size="small" className={classes.root}>

                                <InputLabel id="demo-select-small">Select Function</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={functionId}
                                    label="Select Function"
                                    onChange={(e) => setFunction(e.target.value)}
                                >

                                    {functionList.map(x =>
                                        <MenuItem value={x.id}>{x.name}</MenuItem>
                                    )}


                                </Select>
                            </FormControl>


                        </div>
                    </div>
                </Row>

                <div className="text-center mt-4">
                    <button type="submit" className="btn btn-primary" onClick={() => save()}>
                        Save
                    </button>
                </div>
            </Card>
            }

        </>

    )

}

const companyType = [
    { name: 'Vendor', id: '2' },
    // { name: 'Partially Completed', id: '2' },
    { name: 'Distributor', id: '3' },
    { name: 'Retailer', id: '4' },
    { name: 'Reseller', id: '5' }];

const hour = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
const min = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

export default Timeslot;