import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";
import { InputLabel, Select, TextField, FormControl } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { useForm } from "react-hook-form";
import { FormAutocomplete, FormTextArea } from "_components/FormElements/FormInput";
import { commonService } from '_services/common.service';
import { Spinner } from 'views/Hoc/Spinner';
import { history } from '_helpers/history';
import { ProfileUploader } from "views/Hoc/ProfileUpload.js";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import axios from "axios";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import BootstrapTable from "react-bootstrap-table-next";
import TablePagination from '@material-ui/core/TablePagination';
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";

const Video_Template_Data = (props) => {

    const { register, handleSubmit, control, errors } = useForm({
        mode: 'onBlur',
        //resolver: yupResolver(TradeInformationSchema),
    });

    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(10);
    const [showDrawer, setshowDrawer] = useState(false);

    const [error, setError] = useState('');
    const [imageSrc, setImageSrc] = useState(null);
    const [image, setImage] = useState('');
    const [templates, setTemplates] = useState([]);
    const [fieldError, setFieldError] = useState(false)
    const { onClose, eventId } = props;


    let [isModalPopup, setModalPopup] = useState(false);
    let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null });
    const [deleteId, setDeleteId] = useState(null)


    const [loading, setLoading] = useState(false);

    const [state, setState] = useState({
        companyname: null,
        productname: null,
        category: null,
        embedId: null,
        awardlogo: null
    });


    const Templates = async () => {
        setLoading(true);
        await axios.get('/channelAdmin/Video_Template/' + eventId).then(res => {
            if (res.data.length) {
                setTemplates(res.data);
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        (async () => {
            if (props.visible) {
                setState({ ...state, companyname: null, productname: null, category: null, embedId: null, awardlogo: null })
                await Templates();
            }
        })();
    }, [props.visible])


    const openModalPopUp = (id) => {
        modalPopValue.title = 'Delete Template';
        modalPopValue.message = 'You want to Delete Template';
        modalPopValue.popUpId = 1;
        setModalPopValue({ ...modalPopValue })
        setDeleteId(id)
        setModalPopup(true);
    };

    const deleteTemplate = async () => {
        setLoading(true);
        await axios.delete(`channelAdmin/deleteVideoTemplate/${deleteId}`)
            .then((res) => Templates())
            .catch((err) => console.log(err))
        setModalPopup(false)
    };

    const handleChange = input => e => {
        setState({ ...state, [input]: e.target.value });
    };

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }




    const save = async () => {

        console.log("saveOffer:", state);
        setLoading(true);
        await commonService.postService({ templateDetail: { ...state, eventId: eventId }, type: "video" }, 'channelAdmin/eventtemplate');
        setLoading(false);
        closeDrawer();

    }

    const closeDrawer = () => {
        props.onClose();
        setshowDrawer(false);
    }

    const handleChangeImage = event => {
        const data = event.target.files[0];
        setError('');
        if (data && data['type'].split('/')[0] === 'image') {
            let file = data;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImage(
                    reader.result
                );
            };

        } else {
            setError('Jpg | Jpeg | PNG Image Formats Only Accepted');
        }

    };

    const handleCropClose = () => {
        setImage('');
    }

    const selectImg = async (data) => {
        if (data) {
            setImage('');
            //converting base64 string to file format.
            var random = Math.random();
            var fileName = random + '.png';
            var file = dataURLtoFile(data, fileName);
            const formData = new FormData();
            formData.append('file', file);
            setLoading(true);
            const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + "templateLogo");
            if (resImagePath) {
                setImageSrc(process.env.REACT_APP_CDN_URL_TEMP + resImagePath.data.url);
                setState({ ...state, awardlogo: process.env.REACT_APP_CDN_URL_TEMP + resImagePath.data.url });
                setLoading(false);
            }
        }

    }

    const editTemplate = async (id) => {
        const temp = templates.find(x => x.videoId == id)
        const selectedTemplate = {
            companyname: temp.companyname,
            productname: temp.productname,
            category: temp.category,
            embedId: temp.embedId,
            awardlogo: temp.awardlogo
        }
        setState(selectedTemplate);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setLoading(true)
    };

    return (

        <div>

            {loading ? <Spinner /> :

                <>

                    {isModalPopup &&
                        <Dialog open={isModalPopup} value={modalPopValue} handleSave={deleteTemplate} handleClose={() => setModalPopup(false)} >
                        </Dialog>
                    }

                    <ToolkitProvider
                        data={templates}
                        keyField="id"
                        columns={[
                            {
                                dataField: "companyname",
                                text: "Company",
                                sort: true,
                                width: 100
                            },
                            {
                                dataField: "productname",
                                text: "Product",
                                sort: true,
                                width: 100
                            },
                            {
                                dataField: "category",
                                text: "Category",
                                sort: true,
                                width: 100
                            },
                            {
                                dataField: "embedId",
                                text: "Youtube id",
                                sort: true,
                                width: 100
                            },
                            {
                                dataField: "awardlogo",
                                text: "Logo",
                                sort: true,
                                width: 100
                            },
                            {
                                dataField: "videoId",
                                text: "Action",
                                width: 100,
                                formatter: (rowContent, row) => (
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div onClick={() => editTemplate(rowContent)}>
                                            <EditIcon style={{ cursor: "pointer" }} />
                                        </div>
                                        <div onClick={() => openModalPopUp(rowContent)}>
                                            <DeleteIcon style={{ cursor: "pointer" }} />
                                        </div>


                                    </div>
                                )
                            },
                        ]}
                        search
                    >
                        {props => (
                            <div className="table-responsive">
                                {/* <div style={{ marginLeft: "2.7%", marginTop: "1.7%" }}> */}
                                <h3>Manage Templates</h3>
                                {/* </div> */}

                                <BootstrapTable
                                    bordered={false}
                                    classes={`table-striped table-hover`}
                                    {...props.baseProps}
                                    bootstrap4={true}
                                // expandRow={expandRow}
                                />
                            </div>
                        )}
                    </ToolkitProvider>
                    <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                    <Card id='Coupon'>
                        <CardBody>
                            <form className="coupon-form" noValidate autoComplete="off" onSubmit={handleSubmit(save)}>

                                <Row>
                                    <h4>Company name</h4>
                                </Row>
                                <Row>
                                    <TextField id="outlined-basic" value={state.companyname} onChange={handleChange('companyname')} variant="outlined" />
                                </Row>
                                <Row>
                                    <h4>Product name</h4>
                                </Row>
                                <Row>
                                    <TextField id="outlined-basic" value={state.productname} onChange={handleChange('productname')} variant="outlined" />
                                </Row>

                                <Row>
                                    <h4>Category</h4>
                                </Row>
                                <Row>
                                    <TextField id="outlined-basic" value={state.category} onChange={handleChange('category')} variant="outlined" />
                                </Row>
                                <Row>
                                    <h4>Youtube id</h4>
                                </Row>
                                <Row>
                                    <TextField id="outlined-basic" value={state.embedId} onChange={handleChange('embedId')} variant="outlined" />

                                </Row>

                                <Row>
                                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="logo" name="logo" ref={register} value={state.awardlogo} />
                                    {/* <h4>Event Logo *{!errors.eventLogo || state.eventLogo ? '' : <span style={{color: '#f44336', fontSize:'0.75rem'}}>   Please Select Event Logo</span>}</h4> */}

                                    <div>
                                        <h4>Logo * {error != '' ? <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   {error}</span> : fieldError == true && (imageSrc == (null || '')) ? <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select Template Logo</span> : ''}</h4>
                                        <p className="image-hint">Accepts Jpeg / Jpg / Png Only(Max size allowed 200 Kb)</p>

                                        <ProfileUploader img={(imageSrc != null || state.awardlogo != null) ? (imageSrc || state.awardlogo) : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
                                            handleChange={handleChangeImage} selectImg={selectImg} image={image} handleCropClose={handleCropClose}
                                        />
                                    </div>
                                </Row>




                                <div className="text-center mt-4">
                                    <button onClick={(e) => { e.preventDefault(); save() }} className="btn btn-primary" >Save</button>
                                    <button onClick={e => { e.preventDefault(); closeDrawer() }} className="btn btn-warning" >Cancel</button>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </>
            };
        </div >

    );
};

export default Video_Template_Data;