import React, { useEffect, useState } from 'react'
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Modal, Button, Nav, NavItem, Card, CardBody, Container, Row, Col, Media, CardHeader, ListGroupItem, ListGroup, UncontrolledTooltip, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";
import { SelectAutocomplete, FormAutocomplete } from '_components/FormElements/FormInput';
import Moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@material-ui/core/Typography';

import axios from 'axios';
import { Spinner } from '../../Hoc/Spinner'
import { history } from '_helpers';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import EditEventUser from 'views/Hoc/Dialog/EditEventUser';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { commonService } from '_services/common.service';
import { useForm } from "react-hook-form";
import EditEventUserPackage from 'views/Hoc/Dialog/EditEventUserPackage';
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/PaginationActions';
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
import zIndex from '@material-ui/core/styles/zIndex';


const agendaList = [
  {
    date: "05-01-2023", available: false, slotList: [{ slot: "09:00 - 09:07", available: false },
    { slot: "09:10 - 09:17", available: false },
    { slot: "09:20 - 09:27", available: false },
    { slot: "09:30 - 09:37", available: false },
    { slot: "09:40 - 09:47", available: false },
    { slot: "09:50 - 09:57", available: false },

    ]
  },


]


function EventConnection(props) {

  console.log("props.location?.state?.eventId:", props.location?.state?.eventId)
  let eventId = props.location?.state?.eventId ? props.location?.state?.eventId : 0;
  const { control } = useForm({ mode: 'onBlur' });
  let [token, settoken] = useState(null);
  let seller = [{ id: 2, name: 'VENDOR', status: false }, { id: 3, name: 'DISTRIBUTOR', status: false }, { id: 4, name: 'RETAILER', status: false }, { id: 5, name: 'RESELLER', status: false }];
  let buyer = [{ id: 2, name: 'VENDOR', status: false }, { id: 3, name: 'DISTRIBUTOR', status: false }, { id: 4, name: 'RETAILER', status: false }, { id: 5, name: 'RESELLER', status: false }];

  const [participant, setParticipant] = useState({ sender: [], receiver: [] });

  const [imageSrc, setImageSrc] = useState('');
  const [imageSrcBanner, setImageBanner] = useState('');

  const [statusData, setStatusData] = useState();

  let [agenda, setAgenda] = useState([]);
  
  // const [total, setTotal] = useState();

  const [state, setState] = useState([]);
  const [master, setMaster] = useState([]);

  const [slot, setSlot] = useState();
  const [date, setDate] = useState();
  const [eventRequestId, setEventRequestId] = useState();
  const [slotList, setSlotList] = useState();

  const [loading, setLoading] = useState(true);
  const [confRequest, setConfirmRequest] = useState(false);
  const [decline, setDecline] = useState(false);

  const [count, setCount] = useState({});

  const [openStatus, setChangeStatus] = useState(false);
  const [status, setStatus] = useState(false);



  const [searchState, setSearchState] = useState({
    dropdownList: [],
    search: null
  });

  const [requestType, setRequestType] = useState(null);
  const [meetingType, setMeetingType] = useState(null);

  var userData = JSON.parse(localStorage.getItem('CHuser'));


  useEffect(() => {


    (async () => {

      if (eventId) {
        await EventDetailsApi();
      }

    })();

  }, []);

  const EventDetailsApi = async () => {
    // getTimeSlot();
    getEventDetails();
    getCount();

    // getEventSearchList();
    // await eventAuth();
  }

  const getCount = async () => {
    const userData = JSON.parse(localStorage.getItem('CHuser'));

    let result = await axios.get('channel/eventRequestCounts/' + eventId)
      .then(res => { return res.data })
      .catch(err => console.log("getcount:", err));

    // let confbyvendor = result?.filter(req => (req.status == 4 && (req.sentype == 2 || req.rectype == 2)));

    // let sendbyven = confbyvendor.map(x => x.senderChannelId);
    // let recbyven = confbyvendor.map(x => x.receiverChannelId);

    // let confbybuyer = result?.filter(req => (req.status == 4 && (req.sentype != 2 || req.rectype != 2)));

    // let sendbybuyer = confbybuyer.map(x => x.senderChannelId);
    // let recbybuyer = confbybuyer.map(x => x.receiverChannelId);


    setCount({
      meetingbyvendor: result[0].subbyvendor,//result?.filter(req => (req.status == 3 && req.sentype == 2)).length,
      meetingbybuyer: result[0].subbybuyer,//result?.filter(req => (req.status == 3 && req.sentype != 2)).length,
      confirmedmeeting: result[0].confirmed,//result?.filter(req => (req.status == 4)).length,
      noconfirmbyvendor: result[0].noconfvendor,//result?.filter(req => (req.status != 4 && (!recbyven.includes(req.receiverChannelId) && req.rectype == 2) || (!sendbyven.includes(req.senderChannelId) && req.sentype == 2))).length,
      noconfirmbybuyer: result[0].noconfbuyer,//result?.filter(req => (req.status != 4 && (!recbybuyer.includes(req.receiverChannelId) && req.rectype != 2) || (!sendbybuyer.includes(req.senderChannelId) && req.sentype != 2))).length,
      noofdeclined: result[0].declined

    })




  }

  const getTimeSlot = async () => {

    await axios.get('/channelAdmin/getEventSlotDetails/' + eventId).then(res => {

      if (res) {
        var groupBy = function (xs, key) {
          return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
          }, {});
        };
        var groubedByTeam = groupBy(res.data, 'slotDate')

        const arrayOfObj = Object.entries(groubedByTeam).map((e) => (e[1]));

        console.log("agenda:", arrayOfObj);

      }

    });


  }

  const getEventDetails = async () => {
    setLoading(true);
    let data = {
      eventId: eventId,
    }

    await axios.post('/channelAdmin/connectionRequest/', data).then(res => {
      console.log('response Data', res.data);

      let result = [];
      if (res) {

        res.data.filter(x => x.receiver!=null).map(x => {
          let obj = {
            eventId: eventId,
            eventName: x.eventname,
            senderType: x.sender[0].channelTypeId,
            senderContact: x.sender[0].name,
            senderCompany: x.sender[0].companyName,
            senderJobTitle: x.sender[0].jobTitle,
            receiverContact: x.receiver[0].name,
            receiverCompany: x.receiver[0].companyName,
            receiverJobTitle: x.receiver[0].jobTitle,

            senderContactId: x.sender[0].contactId,
            receiverContactId: x.receiver[0].contactId,

            eventRequestId: x.eventRequestId,
            status: x.status,
            slot: x.slot,
            custom_slot: x.custom_slot,
            custom_date: x.custom_date,
            zoomLink: x.zoomLink
          }

          //console.log("results:",result)
          result.push(obj);

        })

        setMaster(result);

        
        setState(result.slice(0,10));

      }

      setLoading(false);
    }).catch(err => setLoading(false));

  };

  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });

  const confirmPopup = (res) => {

  }
  const closePopup = () => {
    setModalPopup(false);
  };



  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [sort, setSort] = useState('');
  let [isSort, setisSort] = useState(false);

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    console.log("rowsPerPage:",page,page * rowsPerPage,rowsPerPage)
    const newData=master.slice(page * rowsPerPage,(page * rowsPerPage+rowsPerPage))
    setState(newData);
    //getEventDetails({ tabChange: false });

  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    console.log("rowsPerPage:",page * rowsPerPage,rowsPerPage)
    setRowsPerPage(rowsPerPage);

    const newData=master.slice(page * rowsPerPage,rowsPerPage)
    setState(newData);
    //callPendingChannel({ tabChange: false });
  };

  const handleChangeSelect = (input, value) => {

    console.log("meet:", input, value);

    if (input == 'request' && value) {
      setRequestType(value);
      if (value.id == 2){
        const newData=master.filter(x => x.senderType == 2).slice(page * rowsPerPage,(page * rowsPerPage+rowsPerPage));      
        setState(newData);//.filter(x => x.senderType == 2))
      }
      else{
        const newData=master.filter(x => x.senderType != 2).slice(page * rowsPerPage,(page * rowsPerPage+rowsPerPage)); 
        setState(newData);//.filter(x => x.senderType != 2))
      }
    } else if (input == 'request') {
      const newData=master.slice(page * rowsPerPage,(page * rowsPerPage+rowsPerPage));  
      setRequestType(null);
      setState(newData)
    }

    if (input == 'meeting' && value) {
      setMeetingType(value);
      if (requestType && Object.keys(requestType).length){
        const newData=state.filter(x => x.status == value.id).slice(page * rowsPerPage,(page * rowsPerPage+rowsPerPage));
        setState(newData);//state.filter(x => x.status == value.id))
      }
      else{
        const newData=master.filter(x => x.status == value.id).slice(page * rowsPerPage,(page * rowsPerPage+rowsPerPage));
        setState(newData);//.filter(x => x.status == value.id))
      }
    } else if (input == 'meeting') {
      setMeetingType(null);
      const newData=master.slice(page * rowsPerPage,(page * rowsPerPage+rowsPerPage));
      setState(newData)
    }

    


  }

  const callPendingChannel = (data) => {
    // closeDrawer();
    // page = 0;
    setPage(page);
    //getEventDetails(data);
  }

  const setSorting = (data) => {
    isSort = true;
    setisSort(isSort);
    if (data != sort) {
      sort = data;
      setSort(sort);
      callPendingChannel({ tabChange: false });
    }
  }

  const clearSorting = () => {
    isSort = false;
    setisSort(isSort);
    callPendingChannel();
  }

  const handleClose = () => {
    setAgenda([]);
    setConfirmRequest(false);
    setDecline(false);
    setChangeStatus(false);
  };

  const saveConfirm = async () => {

    setLoading(true);

    // // let startTime = slot.split('-')[0].trim();
    // // // console.log("startTime:", startTime)
    // // let stime = startTime.split('.')[0] + ':' + startTime.split('.')[1] + ':00'

    // // let duration = (parseFloat(slot.split('-')[1].trim()) - parseFloat(slot.split('-')[0].trim())).toFixed(2);
    // // let start_time = date + 'T' + stime

    // // duration = duration < 1 ? parseInt(duration.toString().split('.')[1]) : parseFloat(duration)

    // // let data = await axios.post('channel/getZoomMeetingUrl', { duration: duration, start_time: start_time })
    //   .then(res => {
    //     let result = {
    //       status: 4,
    //       eventRequestId: eventRequestId,
    //       custom_date: date,
    //       custom_slot: slot,
    //       zoomLink: res.data,
    //       isAdmin: true
    //     }
    //     return result;
    //   });

    // if (data.zoomLink) {
    //   // console.log("updatedate:", data);
    //   // setSlotList([]);
    //   // setAgenda([]);
    //   // setSlot(null);
    //   // setDate(null);
    //   // agendaList.map(a => {
    //   //   a.slotList.map(s => {
    //   //     s.available = false
    //   //   })
    //   // })

    //   await axios.post('channel/udateEventRequest', data)
    //     .then(res => {
    //       let req = state.find(x => x.eventRequestId == eventRequestId);
    //       // console.log("req:",req)
    //       axios.post('mailerservice/eventRequestConfirmMail', { ...req, zoomLink: data.zoomLink, status: 4, custom_date: data.custom_date, custom_slot: data.custom_slot })
    //       getEventDetails();
    //     })
    //     .catch(err => setLoading(false));

    //   window.location.reload();
    // }

    let data = {
            status: 4,
            eventRequestId: eventRequestId,            
            slot: slot,           
            isAdmin: true
          }

     await axios.post('channel/udateEventRequest', data)
        .then(res => {
          let req = state.find(x => x.eventRequestId == eventRequestId);
          // console.log("req:",req)
          // axios.post('mailerservice/eventRequestConfirmMail', { ...req, zoomLink: data.zoomLink, status: 4, custom_date: data.custom_date, custom_slot: data.custom_slot })
          axios.post('mailerservice/eventRequestConfirmVegas', { status: 4, eventRequestId: eventRequestId })
          getEventDetails();
        })
        .catch(err => setLoading(false));

      window.location.reload();


    setConfirmRequest(false);
  }


  const openConfirm = (eventRequestId) => {

    let selectedUser = state.find(user => user.eventRequestId == eventRequestId);

    // let user = master.filter(mas => ((mas.eventRequestId != selectedUser.eventRequestId && mas.custom_date != null && mas.custom_slot != null) && (mas.senderContactId == selectedUser.senderContactId || mas.receiverContactId == selectedUser.receiverContactId)));

    let confirmedRecords = master.filter(mas => (mas.status == 4 && (mas.senderContactId == selectedUser.receiverContactId || mas.receiverContactId == selectedUser.receiverContactId || mas.senderContactId == selectedUser.senderContactId || mas.receiverContactId == selectedUser.senderContactId)))

    let confirmedSlots = confirmedRecords.map(x => x.slot);

    var date = new Date();

    // console.log('confirmedSlots:',confirmedSlots)

    var currentdate = date.getDate();
    var currentmonth = date.getMonth() + 1;
    var currenthour = date.getHours();
    var currentmin = date.getMinutes();


    console.log("user:", currentdate, currentmonth, currenthour, currentmin);


    // for (var i = 0; i < user.length; i++) {

    //   // console.log("diff:", user[i].custom_slot.split('-')[0].trim(), user[i].custom_slot.split('-')[1].trim())

    //   agendaList.map(a => {
    //     // console.log("user:", currentdate, a.date.split('-')[2]);

    //     let slist = a.slotList.map(x => x.slot);
    //     // console.log("slist:",slist,slist.includes(user.custom_slot));
    //     if (a.date == user[i].custom_date && slist.includes(user[i].custom_slot)) {
    //       a.slotList.map(s => {
    //         if (s.slot == user[i].custom_slot)
    //           s.available = true
    //       })
    //     }
    //     else {
    //       a.slotList.map(s => {
    //         s.available = false
    //       })
    //     }
    //   })

    // }

    // if (user.length == 0) {
    //   agendaList.map(a => {
    //     a.slotList.map(s => {
    //       s.available = false
    //     })
    //   })
    // }
    setSlot(null);

    agendaList[0].slotList.map(s => {
      if (confirmedSlots.includes(s.slot))
        s.available = true;
      else
        s.available = false;
    })


    // agendaList.map(a => {
    //   if ((parseInt(a.date.split('-')[1]) == currentmonth && parseInt(a.date.split('-')[2]) < currentdate) ||
    //     (parseInt(a.date.split('-')[1]) < currentmonth))
    //     a.available = true
    //   else
    //     a.slotList.map(s => {
    //       if ((parseInt(a.date.split('-')[1]) == currentmonth && parseInt(a.date.split('-')[2]) == currentdate && parseInt(s.slot.split('-')[0].trim()) == currenthour && parseInt(s.slot.split('-')[1].trim()) < currentmin) ||
    //         (parseInt(a.date.split('-')[1]) == currentmonth && parseInt(a.date.split('-')[2]) == currentdate && parseInt(s.slot.split('-')[0].trim()) < currenthour))
    //         s.available = true
    //     })
    // })


    setAgenda(agendaList);
    setEventRequestId(eventRequestId);
    setConfirmRequest(true)
  }

  const changeStatus = (eventRequestId, currentStatus) => {

    if (currentStatus == 4 || currentStatus == 5)
      openConfirm(eventRequestId)
    // setStatusData([{ id: 3, name: 'Pending' }, { id: 5, name: 'Declined' }])
    else if (currentStatus == 5) {
      setStatusData([{ id: 3, name: 'Pending' }])
      // setStatus(currentStatus);
      setEventRequestId(eventRequestId);
      setChangeStatus(true)
    }

  }

  const openDecline = (eventRequestId) => {
    setEventRequestId(eventRequestId);
    setDecline(true)
  }

  const confirmRequest = () => {


    return (
      <div>
        <Modal className="modal-dialog-centered" fade={false} isOpen={confRequest} size="xg" style={{ minWidth: 900, width: '30%' }}>
          <div className="modal-header">
            Confirm Meeting
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className="modal-body">

            <div className="container">

              <div className="row">

                <FormControl sx={{ m: 1, minWidth: 320 }} size="small">

                  <InputLabel id="demo-select-small">Select Meeting Date</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={date}
                    label="Date"
                    // onChange={(e) => { setSlotList(agenda.find(x => x.date == e.target.value).slotList); setDate(e.target.value) }}
                  >
                    {agenda.map(x =>
                      <MenuItem key={x.date} value={x.date} disabled={x.available}>
                        <Typography>{x.date}</Typography>
                      </MenuItem>
                    )}

                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 320 }} size="small">
                  <InputLabel id="demo-select-small">Select Meeting Slot</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={slot}
                    label="Slot"
                    onChange={(e) => setSlot(e.target.value)}
                  >
                    {agendaList[0].slotList?.map(x =>
                      <MenuItem key={x.slot} value={x.slot} disabled={x.available}>
                        <Typography>{x.slot}</Typography>
                      </MenuItem>
                    )}

                  </Select>
                </FormControl>



              </div>

            </div>
          </div>

          <div className="modal-footer">
            <Button color="primary" type="button" onClick={() => saveConfirm()}>Confirm Request</Button>
            <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
          </div>
        </Modal>
      </div>
    )
  }

  const statusRequest = () => {


    return (
      <div>
        <Modal className="modal-dialog-centered" fade={false} isOpen={openStatus} size="xg" style={{ minWidth: 900, width: '30%' }}>
          <div className="modal-header">
            Meeting Status
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className="modal-body">

            <div className="container">

              <div className="row">

                <FormControl sx={{ m: 1, minWidth: 320 }} size="small">

                  <InputLabel id="demo-select-small">Status</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={date}
                    label="Date"
                    onChange={(e) => { setStatus(e.target.value) }}
                  >
                    {statusData.map(x =>
                      <MenuItem key={x.id} value={x.id}>
                        <Typography>{x.name}</Typography>
                      </MenuItem>
                    )}

                  </Select>
                </FormControl>

              </div>

            </div>
          </div>

          <div className="modal-footer">
            <Button color="primary" type="button" onClick={() => saveStatusChange()}>Confirm</Button>
            <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
          </div>
        </Modal>
      </div>
    )
  }

  const saveStatusChange = async () => {

    let data = {
      status: status,
      eventRequestId: eventRequestId,
      isAdmin: true
    }
    await axios.post('channel/udateEventRequest', data)
      .then(res => {
        getEventDetails();
      })
      .catch(err => setLoading(false));

    window.location.reload();
  }

  const saveDecline = async () => {

    let data = {
      status: 5,
      eventRequestId: eventRequestId,
      isAdmin: true
    }
    await axios.post('channel/udateEventRequest', data)
      .then(res => {
        // let req = state.find(x => x.eventRequestId == eventRequestId);
        //   axios.post('mailerservice/eventRequestConfirmMail', { ...req, status: 5 })
        getEventDetails();
      })
      .catch(err => setLoading(false));

    window.location.reload();
  }

  const sendMail = async () => {
    axios.post('/mailerservice/recapschedule/', { eventId: eventId }).catch(err => {
      console.log("recapmailerror:", err)
    })
  }

  const eventReport = async () => {
    var fileDownload = require('js-file-download');
    setLoading(true);

    axios({
      url: 'master/downloadEventReport',
      method: 'GET',
      params: { eventId: eventId },
      responseType: 'blob', // Important
    }).then((response) => {
      fileDownload(response.data, `EventReport.xlsx`);
      setLoading(false);
    });

  }

  const retailConnect = async () => {
   
    await axios.get('publicChannel/getconnection/'+eventId)
    .then(res => {      
      axios.post('publicChannel/chretailconnect', res.data)      
    })
    .catch(err => setLoading(false));
  }

  



  const DeclineRequest = () => {


    return (
      <div>
        <Modal className="modal-dialog-centered" fade={false} isOpen={decline} size="xg" style={{ minWidth: 900, width: '30%' }}>
          <div className="modal-header">
            <strong>DECLINE INCOMING REQUEST</strong>

            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className="modal-body">


            <div className="container">
              <strong>You want to decline this meeting request</strong>
            </div>
          </div>



          <div className="modal-footer">
            <Button color="primary" type="button" onClick={() => saveDecline()}>Confirm</Button>
            <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
          </div>
        </Modal>
      </div>
    )
  }

  const channelTypeList = { "2": "VENDOR", "3": "DISTRIBUTOR", "4": "RETAILER", "5": "RESELLER" };

  return (
    <div>

      {confRequest &&
        confirmRequest()}

      {decline && DeclineRequest()}

      {openStatus && statusRequest()}


      <SimpleHeader name="Manage-Event" parentName="Event" />
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />


      <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
      {loading ? <Spinner /> : <>
        <Card>
          <CardHeader>

            <h3 className="mb-0">Events Meeting Request</h3>


          </CardHeader>

          <CardBody>
            <Row>
              <Col lg-6>
                <div className="mb-2"> <h4 className="mb-1">{`Total number of meetings submitted by vendor:${count.meetingbyvendor}`}</h4>
                  <h4 className="mb-1">{`Total number of meetings submitted by buyers:${count.meetingbybuyer}`}</h4>
                  <h4 className="mb-1">{`Total number of requests:${parseInt(count.meetingbybuyer)+parseInt(count.meetingbyvendor)}`}</h4>
                  <h4 className="mb-1">{`Total number of confirmed meetings:${count.confirmedmeeting}`}</h4></div>

              </Col>
              <Col lg-6>
                <div className="mb-2"><h4 className="mb-1">{`No of vendors with 0 confirmed meetings:${count.noconfirmbyvendor}`}</h4>
                  <h4 className="mb-1">{`No of buyers wit 0 confirmed meetings:${count.noconfirmbybuyer}`}</h4>
                  <h4 className="mb-1">{`Ratio of confirmed meetings/total number of requests:${Math.round((parseInt(count.confirmedmeeting) / (parseInt(count.meetingbyvendor) + parseInt(count.meetingbybuyer))) * 100)} %`}</h4>
                </div>

              </Col>
            </Row>



            <Row>
              <Col lg='3'>
                <FormAutocomplete
                  id="request"
                  name="request"
                  label="request"
                  options={[{ id: 2, name: 'vendors to buyers request' }, { id: 3, name: 'buyer to vendor requests' }]}
                  placeholder="Request Type ..."
                  // multiple
                  disableCloseOnSelect
                  limitTags={1}
                  control={control}
                  // open={true}
                  input='request'
                  getOptionSelected={(options, value) => options.name === value.name}
                  value={requestType}
                  handleChangeSelect={handleChangeSelect}
                  // popupIcon=''
                  disablePortal={true}
                  // ListboxProps={{ style: { maxHeight: '16rem' } }}
                  size='small'
                />
              </Col>

              <Col lg='3'>
                <FormAutocomplete
                  id="meeting"
                  name="meeting"
                  label="meeting"
                  options={[{ id: 4, name: 'Confirmed' }, { id: 3, name: 'Pending' }]}
                  placeholder="Meeting Type ..."
                  // multiple
                  disableCloseOnSelect
                  limitTags={1}
                  control={control}
                  // open={true}
                  input='meeting'
                  getOptionSelected={(options, value) => options.name === value.name}
                  value={meetingType}
                  handleChangeSelect={handleChangeSelect}
                  // popupIcon=''
                  disablePortal={true}
                  // ListboxProps={{ style: { maxHeight: '16rem' } }}
                  size='small'
                />
              </Col>

              <Button className="btn btn-icon btn-default btn-sm" variant="contained" color="danger" onClick={() => sendMail()} style={{background:"red !important"}}>
                Send Recap Mail

              </Button>

              <Button className="btn btn-icon btn-default btn-sm" variant="contained" color="danger" onClick={() => eventReport()}>
                Download Report
              </Button>

              <Button className="btn btn-icon btn-default btn-sm" variant="contained" color="danger" disabled={true}  onClick={() => retailConnect()}>
                Retail Connect
              </Button>

            </Row>



          </CardBody>
        </Card>

        {/* Table for channel */}
        <Container className="mt--3" fluid>
          <Row>
            <div className="col">
              <Card>

                {state.length>0 && <ToolkitProvider
                  ClassName="align-items-center table-flush" responsive
                  data={state}
                  keyField="eventRequestId"
                  columns={[

                    {
                      dataField: "eventRequestId",
                      text: "Meeting Id",
                    },
                    {
                      dataField: "senderCompany",
                      text: "Sender Company",
                    },
                    {
                      dataField: "receiverCompany",
                      text: "Receiver Company",
                    },
                    {
                      dataField: "senderContact",
                      text: "Sender Name",
                    },
                    {
                      dataField: "receiverContact",
                      text: "Receiver Name",

                    },
                    {
                      dataField: "custom_date",
                      text: "Date",
                      sort: true,
                    },
                    {
                      dataField: "slot",
                      text: "Slot",

                    },
                    {
                      dataField: "zoomLink",
                      text: "Link",

                    },
                    {
                      dataField: "status",
                      text: "Status",
                      //sort: true,
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {rowContent == 3 && "Pending"}
                            {rowContent == 4 && "Confirmed"}
                            {rowContent == 5 && "Declined"}

                          </>
                        )
                      }
                    },

                    {
                      dataField: "Actions",
                      text: "Actions",
                      formatter: (rowContent, row) => {
                        return (

                          <>
                            {row.status == 3 ? <>
                              <Button className="btn btn-icon btn-default btn-sm" variant="contained" color="danger" onClick={() => openConfirm(row.eventRequestId)}>
                                <span className="btn-inner--icon"><i id={'Confirm' + 0} className="fas fa-check">
                                  <UncontrolledTooltip target={'Confirm' + 0} placement='bottom'> Confirm Meeting </UncontrolledTooltip></i></span>
                              </Button>

                              <Button className="btn btn-icon btn-default btn-sm" variant="contained" color="danger" onClick={() => openDecline(row.eventRequestId)}>
                                <span className="btn-inner--icon"><i id={'Decline' + 0} className="fas fa-times">
                                  <UncontrolledTooltip target={'Decline' + 0} placement='bottom'> Decline Meeting </UncontrolledTooltip></i></span>
                              </Button>
                            </> : <>
                              {/* <Button className="btn btn-icon btn-default btn-sm" variant="contained" color="danger" onClick={() => changeStatus(row.eventRequestId, row.status)}>
                                <span className="btn-inner--icon"><i id={'Edit' + 0} className="fas fa-pencil-alt">
                                  <UncontrolledTooltip target={'Edit' + 0} placement='bottom'>Edit</UncontrolledTooltip></i></span>
                              </Button> */}
                              {row.status == 4 &&
                                <Button className="btn btn-icon btn-default btn-sm" variant="contained" color="danger" onClick={() => openDecline(row.eventRequestId)}>
                                  <span className="btn-inner--icon"><i id={'Decline' + 0} className="fas fa-times">
                                    <UncontrolledTooltip target={'Decline' + 0} placement='bottom'> Decline Meeting </UncontrolledTooltip></i></span>
                                </Button>} </>

                            }
                          </>
                        )
                      }
                    },



                  ]}
                >
                  {props => (
                    <>
                      <div className="py-2 table-responsive">
                        <div
                          id="datatable-basic_filter"
                          className="dataTables_filter px-4 mb-2"
                        >

                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          // expandRow={Connection}S
                          bootstrap4={true}
                          // pagination={pagination}
                          bordered={false}
                          hover={true}

                          rowClasses='pointer'
                        />
                      </div>

                      <TablePagination
                        component="div"
                        count={master.length}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </>
                  )}


                </ToolkitProvider>}
              </Card>
            </div>
          </Row>
        </Container>

      </>}
    </div>
  )
}

export default EventConnection



