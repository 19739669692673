import React, {useState,useEffect, useRef} from 'react';
import {Card,CardBody,Row,Col} from "reactstrap";
import {InputLabel,Select,TextareaAutosize ,FormControl} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { useForm} from "react-hook-form";
import {FormAutocomplete} from "_components/FormElements/FormInput";
import { commonService } from '_services/common.service';
import { Spinner} from 'views/Hoc/Spinner';
import { history } from '_helpers/history';
import axios from 'axios';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const UpdateOfflineSubscription = (props) => {
  const [showDrawer,setshowDrawer]=useState(false);

  const { handleSubmit } = useForm({      
    mode:'onBlur',  
    //resolver: yupResolver(TradeInformationSchema),
  });

  const notificationAlert = useRef(null);
  console.log('in drawer pops ',props);
  const {channelSubscriptionId} = props.channelsubsubscription.id;
  const {contactId} = props.channelsubsubscription.contactId;
  const {status} = props.channelsubsubscription.status;
  const {onClose, reCallView} =props;
  // console.log(subscriptionId, 'subscriptionId', props)  
  const [loading,setLoading] = useState(false);
  const [reason,setReason] = useState(null);
  
      useEffect(()=>{
       
      }, []);
         
      
      const closeDrawer =()=>{
        props.onClose();
        setshowDrawer(false);
        
      }
      const onHandleChange= ()=> e => {  
        console.log(e.target.value);
        setReason(e.target.value);
         
       }

      const save = async() => { 
          console.log('reason === ' , reason);
          console.log('status  === ' , props);
        if(reason == null && props.channelsubsubscription.status === false){
          ToastsStore.error('You must enter valid reason to dismiss the wire transfer request');
        } else {
          let data={
            status:(props.channelsubsubscription.status == 1)?true:false,
            reason:reason
          };
          let subId = props.channelsubsubscription.id;
          setLoading(true);   
          axios.post('/channelAdmin/updateSubscription/'+subId,data).then(res =>{
              if(res){
              //id==15?
                ToastsStore.success('Updated Successfully');
              
                axios.post('algorithm/channel/admin' , {'contactId':+props.channelsubsubscription.contactId,
                  'channelTypeId':+res.data.channel.channelTypeId,
                  'businessType':+res.data.channel.isOEM,
                  'channelId':+res.data.channelId,
                  'planId':+res.data.channel.planId,
                  'profileTypeId':+res.data.channel.profileTypeId});

                  axios.post('mailerservice/subscriptionEmail', {id: +res.data.channelId, ch: +res.data.channelId});
                reCallView();
              }
              }).catch((err)=>{
                ToastsStore.error('Something went wrong!...');
                setLoading(false);
              });
              setLoading(false);   
        closeDrawer();
        }  
        
      } 
     
    return (
      
        <div>    
        <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/> 
        {loading? <Spinner />: (          
            <Card>
                <CardBody>
                <form className="coupon-form" noValidate autoComplete="off" onSubmit={handleSubmit(save)}>
                    <Row>
                        <Col lg="6" className="mt-4">
                       Reason
                        </Col>
                        <Col lg="6" className="mt-4">
                             <TextareaAutosize aria-label="minimum height" rowsMin={3} placeholder="Reason" onChange={onHandleChange()}>
                               {reason}
                              </TextareaAutosize>                
                        </Col>
                        </Row>
                        
                    <div className="text-center mt-4">
                      <button type="submit"  className="btn btn-primary">
                        {(props.channelsubsubscription.status== 1)?'Approve':'Dismiss'}
                      </button>
                      <button onClick={e => {e.preventDefault(); closeDrawer()}}  className="btn btn-warning" >Cancel</button>
                    </div> 
                </form>
                </CardBody>
            </Card>
            )};
        </div>
                
    );
};

export default UpdateOfflineSubscription;