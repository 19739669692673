import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import axios from "axios";
import { ModalSpinner } from "views/Hoc/Spinner";
import { Box, Card, Grid, TextField } from "@material-ui/core";
import { ToastsStore } from 'react-toasts';
import Modal from '@mui/material/Modal';

import CloseIcon from '@mui/icons-material/Close';

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';

export default function SetRoom({ hotelName, hotelCountry, hotelAddress, roomModal, setRoomModal, hotelId, eventId, tblHotelByEventCountryId, eventFromDate, eventToDate }) {
  const [room, setRoom] = useState([]);
  const [eventDatesArray, setEventDatesArray] = useState([]);
  const [roomTypeId, setroomTypeId] = useState(null);
  const [loading, setLoading] = useState(false)
  const [dateWiseData, setDateWiseData] = useState([])
  const [totaleDateWiseData, setTotaleDateWisedata] = useState([])
  const [selectRoom, setSelectRoom] = useState(null);
  const [loading2, setLoading2] = useState(false);

  const handleOnChange = (value, name, index) => {
    if (Number(value) > selectRoom.avaliable || Number(value) < 0) {
      ToastsStore.warning('Room is not avaliable');
      value = 0;
    }

    if (name == "room") {
      const subBooking = dateWiseData[index].subBooking;
      if (Number(value) + Number(subBooking) > selectRoom.avaliable) {
        ToastsStore.warning('Room is not avaliable');
        value = 0;
      }
    } else if (name == "subBooking") {
      const room = dateWiseData[index].room;
      if (Number(value) + Number(room) > selectRoom.avaliable) {
        ToastsStore.warning('Room is not avaliable');
        value = 0;
      }
    }

    setDateWiseData(prevState => {
      const updatedData = [...prevState];
      updatedData[index] = {
        ...updatedData[index],
        [name]: value
      };
      return updatedData;
    });
  };


  useEffect(() => {
    getRooms()
  }, [])


  const handleDivClick = (item) => {
    setroomTypeId(item.roomId);
    setSelectRoom(item);
    let array = [];
    for (let i = 0; i < totaleDateWiseData.length; i++) {
      if (totaleDateWiseData[i].roomTypeId == item.roomId) {
        array.push(totaleDateWiseData[i]);
      }
    }

    array.sort((a, b) => new Date(a.date) - new Date(b.date))

    const startDate = new Date(eventFromDate);
    const endDate = new Date(eventToDate);
    startDate.setDate(startDate.getDate() - 3);
    endDate.setDate(endDate.getDate() + 3);
    const retArray = getDatesBetween(startDate, endDate)
    setEventDatesArray(retArray);

    if (array.length > 0) {
      setDateWiseData(array);
    } else {
      let dateArr = []
      for (let i = 0; i < retArray.length; i++) {
        dateArr[i] = { hotelId: hotelId, eventId: eventId, roomTypeId: item.roomId, tblHotelByEventCountryId: tblHotelByEventCountryId, date: retArray[i] }
      }
      setDateWiseData(dateArr)
    }
  };


  const imgStyle = {
    width: '81%',
    height: '90%',
    borderRadius: '10px',
    marginTop: "8%",
    transform: 'scale(1)',
    transition: 'transform 0.3s ease',
  };

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function getDatesBetween(fromDate, toDate) {
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);
    const datesArray = [];

    while (startDate <= endDate) {
      datesArray.push(formatDate(new Date(startDate)))
      startDate.setDate(startDate.getDate() + 1);
    }

    return datesArray;
  }

  const getRooms = async () => {
    setLoading(true)
    await axios.get(`/channelAdmin/getHotelRoomsAndDates/${hotelId}/${tblHotelByEventCountryId}`)
      .then((res) => {
        const roomdata = res.data.room.map((item) => ({ roomId: item.id, name: item.roomName, avaliable: item.roomCount, image: item.image }))
        setRoom(roomdata)
        const data = res.data.dateWiseData.map((item) => ({ ...item, hotelId: hotelId, eventId: eventId, tblHotelByEventCountryId: tblHotelByEventCountryId }))
        setTotaleDateWisedata(data);
        setLoading(false)
        setLoading2(false);
      })
      .catch((err) => console.log(err))
  }

  const validation = () => {
    if (roomTypeId == null) {
      ToastsStore.warning('Please select room type');
      return false;
    }

    return true
  }

  const save = async () => {
    setroomTypeId(null)
    if (!validation()) {
      return
    }
    setLoading(true)
    setLoading2(true)
    // setRoom([])
    setEventDatesArray([])
    await axios.post(`channelAdmin/setRoomdates`, dateWiseData)
      .then((res) => {
        // setLoading(false)
        // modalCloseHandle()
        getRooms()
        ToastsStore.success("Room Date is set successfully");
      }).catch((err) => {
        console.log(err)
        ToastsStore.warning('Something went wrong');
      })
  }

  const modalCloseHandle = () => {
    setroomTypeId(null);
    setRoomModal(false);
  }

  const handleRoomAndSubBookValue = (index, type) => {
    if (type == "Room") {
      if (dateWiseData[index].room == (null || undefined)) {
        dateWiseData[index].room = selectRoom.avaliable;
      }
      return dateWiseData[index].room;
    } else {
      if (dateWiseData[index].subBooking == (null || undefined)) {
        dateWiseData[index].subBooking = 0;
      }
      return dateWiseData[index].subBooking;
    }
  }
  return (
    <Modal
      open={roomModal}
      onClose={modalCloseHandle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <Card style={{ margin: "0", overflowY: "scroll", height: "100vh", padding: "14px", minWidth: "63%", maxWidth: "62%" }}>

        <div style={{ display: "flex", fontSize: "0.8rem", justifyContent: "space-between", width: "97%", marginLeft: "1.7%" }}>
          <h3 >{hotelName}<h4 style={{ fontSize: "0.8rem", color: "grey" }}>{`${hotelAddress}${"  -  "}${hotelCountry}`}</h4></h3>


          <button style={{ border: "none", backgroundColor: "white", marginBottom: "0.9rem" }} onClick={modalCloseHandle}><CloseIcon /></button>
        </div>

        {
          loading && loading2 == false && <ModalSpinner />
        }

        <Grid container style={{ width: "97%", height: "36%" }}>
          {room.map((item) => (
            <Grid style={{ display: "flex", justifyContent: "space-evenly", }} alignItems="center" alignContent="center" justifyContent="space-evenly" item key={item.id} xs={12} sm={6} md={6} lg={3} xl={3} >
              <Box style={{
                borderRadius: "9px", width: "83%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", cursor: 'pointer',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                border: roomTypeId === item.roomId ? '3px solid #11cdef' : '3px solid #e0e0e0',
                transition: 'border 0.3s ease, padding 0.3s ease',
              }} onClick={() => handleDivClick(item)}>
                <img
                  className="img-fluid"
                  style={imgStyle}
                  alt="ch-logo"
                  src={`${process.env.REACT_APP_CDN_URL_TEMP}${Array.isArray(item?.image) && item.image[0]?.documentPath ? item.image[0].documentPath : "document/media/1719060259465_1719060260318.png"}`}
                />
                <h4 style={{ fontSize: "0.7rem", marginTop: "0.7rem", marginBottom: "0.2rem", textAlign: "center" }}>{item.name.length < 19 ? item.name : `${item.name.substring(0, 19)}...`}</h4>
                <h4 style={{ fontSize: "0.7rem", textAlign: "center" }}>{`Room ${item.avaliable}`}</h4>
              </Box>
            </Grid>
          ))}
        </Grid>

        {
          loading && loading2 == true && <ModalSpinner />
        }

        {
          roomTypeId && eventDatesArray.length > 0 &&

          <TableContainer sx={{ margin: "1.8% 0 0 2.4%", width: "92.3%" }} component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Event Date</TableCell>
                  <TableCell align="center" >Total Room</TableCell>
                  <TableCell align="center">Sur Booking</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eventDatesArray.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell style={{ fontSize: "0.7rem" }} component="th" scope="row">
                      {eventDatesArray[index]}
                    </TableCell>

                    <TableCell align="center">
                      <TextField
                        type="number"
                        value={handleRoomAndSubBookValue(index, "Room")}
                        inputProps={{ style: { fontSize: "0.7rem", height: "1.5em", padding: "6px 14px" } }}
                        InputLabelProps={{ style: { fontSize: "0.7rem" } }}
                        style={{ height: "2em", padding: "6px 14px", margin: "0rem 0rem 0.7rem 5.9rem" }}
                        variant="outlined"
                        onChange={(e) => handleOnChange(e.target.value, "room", index)}
                      />
                    </TableCell>

                    <TableCell align="center">
                      <TextField
                        type="number"
                        value={handleRoomAndSubBookValue(index, "subBooking")}
                        inputProps={{ style: { fontSize: "0.7rem", height: "1.5em", padding: "6px 14px" } }}
                        InputLabelProps={{ style: { fontSize: "0.7rem" } }}
                        style={{ height: "2em", padding: "6px 14px", margin: "0rem 0rem 0.7rem 5.7rem" }}
                        variant="outlined"
                        onChange={(e) => handleOnChange(e.target.value, "subBooking", index)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }

        <button type="submit" className="btn btn-primary mt-4" style={{ marginLeft: "2.5%" }} onClick={save}>Save</button>
        <button className="btn btn-warning btn-md   mt-4" style={{ marginLeft: "2%" }} onClick={modalCloseHandle}>Cancel</button>

      </Card>

    </Modal>
  );
}
