import React, { useEffect, useState } from "react"; 
import BootstrapTable from "react-bootstrap-table-next";
import TablePagination from '@material-ui/core/TablePagination';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useForm } from "react-hook-form";
 
import { Card, } from "@material-ui/core";
import { SelectAutocomplete } from "_components/FormElements/FormInput";
import Modal from '@mui/material/Modal';
import axios from "axios";
import { ModalSpinner } from "views/Hoc/Spinner";
import CloseIcon from '@mui/icons-material/Close';
import BookingHotelUsersData from "./BookingHotelUsersData";
var _ = require('lodash');


const HotelRooms = ({hotelId, manageRooms, setManageRooms}) => {
  const months = [{name:"January"},{name:"February"},{name:"March"},{name:"April"},{name:"May"},{name:"June"},{name:"July"},{name:"August"},{name:"September"},{name:"October"},{name:"November"},{name:"December"}];
  const years = Array.from({ length: 6 }, (_, i) => ({ name: String(2019 + i) }));
  const[loading, setLoading] = useState(false)
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10); 
  const[count, setCount] = useState(10);
  const { control } = useForm({ mode: 'onBlur' });
  const[hotelArray, setHotelArray] = useState([])
  const[yearObj, setYearObj] = useState({ name:`${new Date().getFullYear()}`})
  const[monthObj, setMonthObj] = useState({  name: new Date().toLocaleString('en-US', { month: 'long' }) })
  const[selectMonth, setSelectMonth] = useState( new Date().toLocaleString('en-US', { month: 'long' }))

  const[selectYear, setSelectYear] = useState(new Date().getFullYear())
  const[eventData, setEventData] = useState([])
  const[selectEvent, setSelectEvent] = useState(null)
  
  const[tableShow, setTableShow] = useState(true)
  const[hotelBookingUserDataFlag, setHotelBookingUserDataFlag] = useState(false)
  const[roomTypeId, setRoomTypeId] = useState(null);
  
  useEffect(() => {
    const month = monthInNumeric(selectMonth)
     getRooms(hotelId, selectYear, month);
  }, [rowsPerPage, page]);

  
  const getRooms  = (hotleId,selectYear=null,selectMonth=null) =>{
    let data = { 
      page:page,
      limit:rowsPerPage, 
      year: selectYear,
      month:selectMonth
    }

    if(selectYear == null && selectMonth != null){
      return
    }else if(selectYear != null && selectMonth == null){
      return
    }
    setLoading(true);
    axios.defaults.headers.post['Content-Type'] = 'application/json'; 
    axios.post(`/channelAdmin/getRooms/${hotleId}`,data).then((res)=>{
      if(res.data != null){
      const roomDetail = res.data.rooms
      const roomParticipantType = res.data.roomParticipantsType
      for(let i=0; i<roomDetail.length; i++){
        let str = ""
        for(let j=0; j<roomParticipantType.length; j++){
          if(roomDetail[i].id === roomParticipantType[j].roomId){
            str += roomParticipantType[j].participantsType + " ";
          }
        }
        roomDetail[i].participantType = str;
      }
          setHotelArray(roomDetail)
          setCount(res.data.totalRooms[0].count)
          if(res.data.eventDetails != null){
            let eventsData = res.data.eventDetails
            for(let i=0; i<eventsData.length; i++){
              eventsData[i].name = `${eventsData[i].name}   ( Fromdate ${eventsData[i].fromDate} - Todate ${eventsData[i].toDate} )`
              // eventsData[i].name = `${eventsData[i].name} (${eventsData[i].fromDate} - ${eventsData[i].toDate})`

            }
             setEventData(eventsData);
             setTableShow(true)
             if(res.data.eventDetails.length == 0){
             setTableShow(false)
             } 
          }
      }else{
          setHotelArray([])
          setCount(0)
          setEventData([])
          setTableShow(false)
      }
         
    setLoading(false);
    }).catch((err)=>console.log(err))
  }

  const getRoomsByEvent = (hotleId, event) =>{
    let data = { 
      page:page,
      limit:rowsPerPage, 
      event:event
    }
 
    setLoading(true);
    axios.defaults.headers.post['Content-Type'] = 'application/json'; 
    axios.post(`/channelAdmin/getRoomsByEvent/${hotleId}`,data).then((res)=>{
      
      
      const roomDetail = res.data.rooms
      const roomParticipantType = res.data.roomParticipantsType
      for(let i=0; i<roomDetail.length; i++){
        let str = ""

        for(let j=0; j<roomParticipantType.length; j++){
          if(roomDetail[i].id === roomParticipantType[j].roomId){
            str += roomParticipantType[j].participantsType + " ";
          }
        }
        roomDetail[i].participantType = str;
      }
          setHotelArray(roomDetail)

          setCount(res.data.totalRooms[0].count)
    setLoading(false);
    }).catch((err)=>console.log(err))
  }
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
 
 
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  
  const handleChangeSelect = (input, value)=>{
    if(value== null){
      setYearObj(null);
      setSelectYear(null);
      return
    }
    setYearObj(value);
    setSelectYear(value.name);
    const month = monthInNumeric(selectMonth)
    getRooms(hotelId,value.name,month)
  }
  
  const handleChangeSelect2 = (input, value)=>{
    if(value== null){
      setMonthObj(null);
      setSelectMonth(null);
      return
    }
    setMonthObj(value);
    setSelectMonth(value.name);
    const month = monthInNumeric(value.name)
    getRooms(hotelId,selectYear,month)
  }

  const handleChangeSelect3 = (input, value)=>{
    if(value== null){
      setSelectEvent(null);
      return
    }
   
    setSelectEvent(value);
    console.log(value.name)
    console.log(value.fromDate)
    console.log(value.toDate)
    getRoomsByEvent(hotelId,value)
  } 
           

  const monthInNumeric = (month)=>{ 
      let monthCode;
    
      if (month === 'January') {
        monthCode = '01';
      } else if (month === 'February') {
        monthCode = '02';
      } else if (month === 'March') {
        monthCode = '03';
      } else if (month === 'April') {
        monthCode = '04';
      } else if (month === 'May') {
        monthCode = '05';
      } else if (month === 'June') {
        monthCode = '06';
      } else if (month === 'July') {
        monthCode = '07';
      } else if (month === 'August') {
        monthCode = '08';
      } else if (month === 'September') {
        monthCode = '09';
      } else if (month === 'October') {
        monthCode = '10';
      } else if (month === 'November') {
        monthCode = '11';
      } else if (month === 'December') {
        monthCode = '12';
      } else { 
        return null;
      }
    
    return monthCode
  }

  return (

    <>

    {hotelBookingUserDataFlag && <BookingHotelUsersData   roomTypeId ={roomTypeId} hotelBookingUserDataFlag={hotelBookingUserDataFlag} setHotelBookingUserDataFlag={setHotelBookingUserDataFlag}/>}
    <Modal
    open={manageRooms}
    onClose={()=>setManageRooms(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    style={{
     display: 'flex',
     alignItems: 'center',
     justifyContent: 'flex-end', 


   }}
  > 
        <Card style={{margin:"0", overflowY:"scroll",height:"100vh",  padding:"14px",minWidth:"74%", maxWidth:"80%"}}>
        <div style={{display:"flex", justifyContent:"space-between", width:"97%", marginLeft:"1%"}}>
                  <h3 style={{ textDecoration:"1px solid skyblue"}}>Manage Room</h3>
                  <button style={{border:"none", backgroundColor:"white"}} onClick={()=>setManageRooms(false)}><CloseIcon/></button>
                </div>


   <div >  
      <ToolkitProvider
        data={hotelArray}
        keyField="id"
        columns={[ 

         {
           dataField: "date",
           text: "Date",
           sort: true,
           formatter: (cell, row) => (
             cell != null && cell.length > 6 ? cell.substring(0, 10) : null
           )
         },

          {
            dataField: "roomName",
            text: "Room Type",
            sort: true,
            formatter: (cell, row) => (
              <>
              <div>{cell}</div>
              <div>{ `Available Room ${row.roomCount}`}</div> 
              </>
            )
          },

          {
            dataField: "totalBooking",
            text: "No Of Booking",
            style: (cell, row) => {
              return {
              cursor: row.totalBooking > 0 ? 'pointer' : 'default',
                textDecoration: row.totalBooking > 0 ? 'underline' : 'none',
                color: row.totalBooking > 0 ? 'purple' : 'black',
              };
            },
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                if (row.totalBooking > 0) {
                  setRoomTypeId(row.id);
                  setHotelBookingUserDataFlag(true);
                }
              }
            },
          },

          {
            dataField: "subBooking",
            text: "Sub Booking",
            sort:true,
            formatter: (cell, row) => (
              <>
              <div>{`Allowed ${cell}`}</div>
              <div>{`Current ${row.totalBooking - row.roomCount > 0 ? row.totalBooking - row.roomCount : 0}`}</div> 
              </>
            )
          },



          {
            dataField: "price",
            text: "Max Price",
            sort:true,
            formatter: (cell, row) => (
              cell != null ? `$ ${cell}` :''
            )
          },

          {
            dataField: "participantType",
            text: "Participant Types",
            formatter: (cell, row) => (
              cell != null  ? cell : null
            )
          },

          
          // {
          //   dataField: "roomDescription",
          //   text: "Notes",
          //   sort: true
          // }, 
        ]}
        search
      >
        {props => (
          <div className="table-responsive"> 
            <div
              id="datatable-basic_filter"
              className="dataTables_filter mb-2"
              style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
            >
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center",   paddingTop: "10px", paddingBottom: "10px" }}>
              
                <div style={{ marginLeft: "10px", marginTop:"1%" }}>
                <SelectAutocomplete
                        id="search"
                        type="text"                
                        label="Year"
                        name="year"
                        className="d-inline-block"
                        style={{ width:'100%'}}
                        options={years} 
                        disableCloseOnSelect
                        control={control}                         
                        input='search'
                        value={yearObj}
                        handleChangeSelect={handleChangeSelect}
                    /> 
                   

                    <SelectAutocomplete
                    id="eventfilter"
                    type="text"                
                    label="Month"
                    name="monthFilter"
                    className="d-inline-block"
                    style={{ width:'100%', marginLeft:'16%'}}
                    placeholder="filter events"
                    disableCloseOnSelect
                    control={control}                         
                    input='eventfilter'
                    options={months}
                    value={monthObj}
                    handleChangeSelect={handleChangeSelect2}
                />  
             </div>
              </div> 
            </div>


             {

           selectYear!= null && selectMonth != null &&  eventData.length > 0 ? 
            <>
              <h3 style={{margin:"2% 0% 2% 1.3%"}}>Events Details</h3>

            <div style={{display:"flex",alignItems:"center", width:"100%",marginBottom:"2%"}}>

            <SelectAutocomplete
                    id="eventfilter"
                    type="text"                
                    label="EVENT LIST"
                    name="eventfilter"
                    className="d-inline-block"
                    style={{ width:'24.1%', marginLeft:"1.2%"}}
                    placeholder="filter events"
                    disableCloseOnSelect
                    control={control}                         
                    input='eventfilter'
                    options={eventData}
                    value={selectEvent}
                    handleChangeSelect={handleChangeSelect3}
                />  
 
                 </div>
                 </>   
              : loading == false && selectYear!= null && selectMonth != null && <>
                   <h4 style={{marginBottom:"0", marginLeft:"1.5%"}}>NO EVENTS AT THIS TIME</h4>
                   </>
             }
             
              { loading == true && <ModalSpinner/>  }

           { 
            tableShow == true &&   loading == false &&  
            <BootstrapTable
              bordered={false}
              classes={`table-striped table-hover`}
              {...props.baseProps}
              bootstrap4={true}
            />
          }
          </div>
        )}
      </ToolkitProvider>
      {
      tableShow == true && loading == false &&  
      <TablePagination
        component="div"
        count={hotelArray.length == 0 ? 0 : count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> 
    }
      </div> 

 <div>

</div>
 
    </Card> 
        
  </Modal>
  </>
  )
}

export default HotelRooms