import React,{ useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import AppBar from '@material-ui/core/AppBar';
import {Tabs, Tab, Avatar}  from '@material-ui/core';
//import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import {Vendor} from 'views/pages/SignupPage';
import "./Signup.scss"

const initState = {  
  companyname: "",
  steet: ""
  
};

const TabPanel = props => {  
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
        {/* <Typography> */}
          {children}
          {/* </Typography> */}
      </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

// ------------------------------------new designs
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Select master blaster campaign settings', 'Create an ad group', 'Create an ad'];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return 'Select campaign settings...';
    case 1:
      return 'What is an ad group anyways?';
    case 2:
      return 'This is the bit I really care about!';
    default:
      return 'Unknown stepIndex';
  }
}

// -----------------------------new designs end

export const Signup= props => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

// -----------------------------new designs end

  // const classes = useStyles();
  // const [signupdata, setSignup] = useState(initState); 
  // const { formType } = props.location.state
  // const [value, setValue] = React.useState(formType);

  const handleChange = (event, newValue) => {
    //console.log(newValue);
  //  setValue(newValue);
  };

  return (
    // <div className={classes.root}>
    //   <AppBar position="static">
    //     <Tabs className="MuiTabs-root makeStyles-root-45" value={value} onChange={handleChange} aria-label="simple tabs example" centered>
    //       <Tab label="SELECT YOUR PROFILE" icon={<i className="ni ni-button-play" style={{fontSize:"30px"}}></i>}  wrapped  {...a11yProps(0)} />
    //       <Tab label="VENDOR" icon={<Avatar  variant="square" alt="test avatar" src="https://channelhub.net/wp-content/uploads/2018/09/vendor-180x180.png"/>}{...a11yProps(1)} />
    //       <Tab label="DISTRIBUTOR" icon={<Avatar  variant="square" alt="test avatar" src="https://channelhub.net/wp-content/uploads/2018/10/picto-profiles-distributor.png"/>} {...a11yProps(2)} />
    //       <Tab label="RETAILER" icon={<Avatar  variant="square" alt="test avatar" src="https://channelhub.net/wp-content/uploads/2018/10/picto-profiles-retailers.png"/>} {...a11yProps(3)} />
    //     </Tabs>
    //   </AppBar>
    //   <TabPanel value={value} index={0}>
       
    //   </TabPanel>
    //   <TabPanel value={value} index={1}>
    //   <Vendor signupData={signupdata} />
    //   </TabPanel>
    //   <TabPanel value={value} index={2}>
    //     Dist
    //   </TabPanel>
    //   <TabPanel value={value} index={3}>
    //     Retailer
    //   </TabPanel>
    // </div>

    <div class="main-content">
    <div class="header bg-gradient-primary py-2 py-lg-3 pt-lg-4">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Welcome!</h1>
              <p class="text-lead text-white">Use these awesome forms to login or create new account in your project for free.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>

    <div class="container mt--8 pb-5">
    <div class="row justify-content-center">
    <div class="col-lg-10 col-md-12">
    <div class="card bg-secondary border-0 mb-0">  
  
    <div class="card-body px-lg-5 py-lg-5">   
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <button onClick={handleReset}>Reset</button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <div>
              <button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </button>
              <button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>          
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>
  );

}

export default Signup;