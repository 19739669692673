import React ,{useEffect, useState} from 'react';
import { Button, Modal } from "reactstrap";
import { Card, CardBody, ListGroupItem, ListGroup, Row, Col } from "reactstrap";
import axios from 'axios';
import { Spinner} from 'views/Hoc/Spinner';
import { commonService } from '_services/common.service';

export  const EventPackDetails=(props)=> {

  const { handleClose, isOpen, channelId } = props;
  let logo = 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg';

  const [state,setState]=useState({
        eventPackDetails : [],
        total: 0,
        eventList:[]
  });
  const [loading,setLoading] = useState(false);
  const [eventList,setEventList] = useState([]);
  const [eventPackageList,setEventPackageList] = useState([]);

  useEffect(() => {
    
    if(isOpen){
        setLoading(true);
        (async() => {
            await axios.post('channelAdmin/getRegisteredEventContact', {channelId: channelId}).then(res => {
                let tot = 0;
                // res.data.map(x => {
                //     tot += (+x.price);
                // })
                
                
                setState({...state, eventPackDetails: res.data.result, eventList: res.data.event, total: tot});
              //  console.log('res.data.event');
                setEventList(res.data.event);
                setEventPackageList(res.data.result);
                
               
              })
        })();
        setLoading(false);
    }
    
    
  }, [isOpen]);


  const closeOpens=(e)=>{
    e.preventDefault();
    handleClose();
  }

  return (
    <div>
    {loading? <Spinner />:(
      <Modal
          className="modal-dialog-centered"
          isOpen={isOpen}
         size="xl"
        >
          <div className="modal-header">
           <h1>Event Pack Details</h1>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={e=> closeOpens(e)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body  d-flex justify-content-center pt-1 pb-1">
        <Card className="w-100 mb-0">
        <CardBody>
             
            
               {eventList.map((x,index) => (
                 <div id={index}>
                 <h1 className='text-center'>{x.name}</h1>
            
                
                {eventPackageList[x.id] && (eventPackageList[x.id]?.length) > 0 && eventPackageList[x.id]?.map((x1,i) => (
                        <div id={index + i}>
                          
                          <Row className="align-items-center">

                          <Col className="col-3">
                            <h4>{x1?.name}</h4>
                            </Col>

                            <Col className="col-3">
                            <h4>{x1?.packageName}</h4>
                            </Col>
                          
                            <Col className="col-3">
                            {x1?.price} 
                            </Col>
                          </Row>
                       
                        </div>
                    ))
                  }  
                </div>
                ))
                
              }   
              

              {/* {state.eventPackDetails.map(x => (
                
                <h1 className='text-center'>{x["14"][0].eventName}</h1>

                  <ListGroup className="list my--3" flush>
                    
                      <ListGroupItem className="px-0">
                      <Row className="align-items-center">

                      <Col className="col-3">
                         <h4>{x.name}</h4>
                        </Col>

                        <Col className="col-3">
                         <h4>{x.packageName}</h4>
                        </Col>
                       
                        <Col className="col-3">
                        {x.price} 
                        </Col>
                      </Row>
                    </ListGroupItem>
                  
                    </ListGroup>
              ))}  */}
             {/* {!!state.eventPackDetails.length && <h4 className='m-4 text-center'>Total - {state.total}</h4>} */}
             {state.eventPackDetails.length==0 && <h4 className='m-4 text-center'>Nil</h4>}
        </CardBody>
        </Card>


          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={e=>closeOpens(e)}
            >
              Close
            </Button>
           
          </div>
        </Modal>
    )}
    </div>
  );
}
export  default EventPackDetails;