import React ,{useEffect, useState} from 'react';
import {Container,Row,Button,Col,CardBody,CardHeader,Card,Nav, NavItem, NavLink,DropdownToggle,DropdownMenu,DropdownItem,UncontrolledDropdown,Badge} from "reactstrap";
import MeetingPopup from './MeetingPopup';
import { commonService } from '_services/common.service';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Spinner } from 'views/Hoc/Spinner';
import Moment from 'moment';
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/pagination/PaginationActions';
const MeetList = () => {
  const [datePopup,setDatePopup]=useState(false);
  let [meetingList,setMeetingList]=useState([]);
  const [loading, setLoading] = useState(true);
  let [data, setData] = useState(null);
  let [page, setPage] = useState(0);
  let [total, setTotal] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  useEffect(() => {
    getMeetingList();
}, []);
const handleChangePage = (event, newPage) => {
  page = newPage;
  setPage(page);
  getMeetingList();
};

const handleChangeRowsPerPage = (event) => {
  rowsPerPage = parseInt(event.target.value, 10);
  setRowsPerPage(rowsPerPage);
  getMeetingList();
};
const getMeetingList= async()=>{
  let list = await commonService.getServiceWithParams({
    offset: page * rowsPerPage,
    limit: rowsPerPage },'/Meeting');
  if(list){
    meetingList = list.data
    setMeetingList(meetingList);
    setTotal(meetingList[0].full_count);
  }
  console.log('meetingList', meetingList);
  setLoading(false);
}
  const openPopup=(info)=>{
    data=info;
    setData(data)
    setDatePopup(true);
  }
  const handleClose=()=>{
    setDatePopup(false);
  }
  const confirmMeeting= async(date, data)=>{
    handleClose();
    setLoading(true);
    console.log('date, data', date, data);
    let info = {
      meeting_Scheduled_At:Moment(date).format(),
      isMeetingConfirm: 1,
      meetingReqId: data.mettingreqid,
      Requester_Company: data.requester_company,
      Requester_Username: data.requester_username,
      requester_email: data.requester_email,
      Receiver_Company: data.receiver_company,
      Receiver_Username: data.receiver_username,
      receiver_email: data.receiver_email
    }
    console.log('date, data', info);
    let status = await commonService.postService(info, '/Meeting/updateMeeting');
    setLoading(false);
    if(status)
    ToastsStore.success('Meeting Confirmed successfully');
    else
    ToastsStore.error('Something Went Wrong!');
  }
    return (

      <div className='container-fluid mt-5'>
          <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
          <MeetingPopup isOpen={datePopup} handleClose={handleClose} submit={confirmMeeting} data={data} />
          {loading?<Spinner/>:<> 
            {meetingList.length>0?meetingList.map(x=>
        <Row>
              <Col lg="6" className="pr-1">
                <Card>
<CardBody className="text-left">
<h5>Sender</h5>
                    <h5 className="font-weight-light mb-1"> {x.requester_company} </h5>
                    <h5 className="font-weight-light mb-1">{x.requester_email}</h5>
                    <h5 className="font-weight-light mb-1"> {x.requester_username} </h5>
</CardBody>
                </Card>
              </Col>
              <Col lg="6" className="pl-1">
              <Card>
<CardBody className="text-left">
  <Row>
<Col lg="10" className="rightBorder">
<h5>Receiver</h5>
                    <h5 className="font-weight-light mb-1"> {x.receiver_company} </h5>
                    <h5 className="font-weight-light mb-1">{x.receiver_email}</h5>
                    <h5 className="font-weight-light mb-1"> {x.receiver_username} </h5>

  </Col>
  <Col lg="2" >
    
<h5>Action</h5>
                    <Button
                      type="button"
                      onClick={e=>openPopup(x)}
                      color="primary"
                      className="btn-sm"
                    >
                      Confirm
                    </Button>
  </Col>
            </Row>


</CardBody>
                </Card>
              </Col>
            </Row>
          ) : <></>}
          </>}
          <TablePagination
          component="div"
          count={total}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 20, 30, 40]}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
        </div>
    );
};

export default MeetList;