import React, { useState, useEffect } from "react";
import {
  Card, CardBody, CardHeader, Container, FormGroup, Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledTooltip
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { QuillEditor, FormInput, SingleAutocompleteGrouping, FormRadio, SingleAutocomplete, FormAutocompleteGrouping, AddFieldWithPopUpAutocomplete, FormCheckBox } from "_components/FormElements/FormInput";
import MediaLibrary from "views/Hoc/Dialog/MediaLibrary";
import { BrandCard, ProfileCard } from "views/Hoc/BrandCard";
import { history } from '_helpers';
import { dashboardActions } from '_actions';
import { TradeInformationSchema } from './validation';
import AddBrand from "views/Hoc/Dialog/AddBrand";
import { useBeforeFirstRender } from '_hooks/index'
import axios from 'axios';
import ImageLibrary from 'views/Hoc/Dialog/ImageLibrary';
import { ModalSpinner } from 'views/Hoc/Spinner';
import { InputLabel, Select, TextField, FormControl, InputAdornment, FormHelperText } from '@material-ui/core';
import { Spinner } from 'views/Hoc/Spinner';
import { connect, useSelector } from 'react-redux';
import { adduser } from '_actions/adduser.actions'
import { adduserActions } from "_actions/adduser.actions";

var _ = require('lodash');


const AddtradeInformation = (props) => {

  let maturitylevel = false;
  const [opneadd, opneAddnew] = useState(false);
  const [brandName, setBrandName] = useState('');
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, control, errors, watch } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(TradeInformationSchema),
  });

  const [state, setState] = useState({

    productGroup: [],
    productValue: [],
    productFamilies: [],

    intProductGroup: [],
    intProductValue: [],
    intProductFamilies: [],

    groupCount: '',


  })

  let data = useSelector(state => state.adduser)
  //localStorage
  var userData = props.userData;//JSON.parse(localStorage.getItem('CHuser'));

  let userParams = {
    channelId: +userData.channelId,
    userId: +userData.userId
  };

  let typeId = userData?.channelTypeId;

  useEffect(() => {

    setLoading(true);

    (async () => {

      const apiUrl = '/channelAdmin/getInitialProfile';
      await axios.post(apiUrl, props.userData).then(res => {
        state.productFamilies = res.data.productGroup;
      });

      if (props.contactId) {
        await axios.get('/setting/getContactProduct/' + props.contactId).then(res => {
          console.log('contatc', res.data);
          //  state.productFamilies=res.data.productGroup;
          state.productValue = res.data.productGroup;

        })
      }
      else {  //if (typeId == 2) {
        const apiUrl1 = '/channelAdmin/tradeinfo';
        await axios.post(apiUrl1, userParams).then(res => {
          //state.productFamilies=res.data.product;
          state.productValue = res.data.product;

        });
      }

      state.productFamilies = state.productFamilies.map(x => {
        state.productValue.map(y => {
          if (x.groupid == y.groupid && x.childid == y.childid)
            x.status = true;
        })
        return x;
      })

      setState({ ...state, productFamilies: state.productFamilies, productValue: state.productValue });
      setLoading(false);

    })();

  }, []);


  console.log('state tradeinfo', state);

  const selectedItem = (gname, check, optionName, optionValue) => {
    let opt = [];
    console.log("check:", check, gname);
    if (check == true) {
      state[optionName].map((item) => {

        if (item.groupname == gname || item.status == true) {
          item.status = check;
          opt.push(item);
        }
      });
    }
    if (check == false) {
      console.log("check:", check);
      state[optionName].map((item) => {
        if (item.groupname == gname && state[optionValue].length > 0)
          item.status = check;
        // if(state.productValue.length==0)
        //     item.status=false;
      });

      opt = state[optionValue].filter((item) => item.groupname != gname);
      opt.map(option => option.status = true);
    }

    state[optionValue] = opt;
    setState({ ...state, [optionValue]: state[optionValue] });
    setState({ ...state, [optionName]: state[optionName] });


    if (optionValue = 'productValue' && state.productValue.length == 0)
      errors.productCat = true;

  }

  const save = async () => {
    //setLoading(true);   

    console.log("state:", state);
    props.adduser(state, 2);
    console.log("state:", data);

    if (typeId == 2)
      props.toggleNavs("4");
    else
      props.toggleNavs("5");
    // if(typeId=='3'){
    //   Object.assign(data,{
    //     productGroup : state.productValue.map(x => +x.groupid),
    //     productCat: state.productValue.map(x => +x.childid),
    //     listOfService: listOfServices         
    //   });  
    // }
    // else{
    //   Object.assign(data,{
    //     productGroup : state.productValue.map(x => +x.groupid),
    //     productCat: state.productValue.map(x => +x.childid)         
    //   });
    // }
    //setLoading(false);    

  }


  const handleChangeSelect = (input, value) => {

    if (input == 'productFamily') {
      state.productFamilies.map((item) => {
        if (value.length == 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   if(v.childname!=item.childname)
          //     item.status=false;                 
          //   else if(v.childname==item.childname)            
          //     item.status=true;                       

          // });      
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.productValue = value
      setState({ ...state, productFamilies: state.productFamilies });
      setState({ ...state, productValue: state.productValue });

      // state.productValue= value
      // setState({...state, productValue:state.productValue});
      if (state.productValue.length == 0)
        errors.productValue = true;

      let Group = _.uniq(state.productValue.map(x => x.groupid));
      state.groupCount = Group.length;
      setState({ ...state, groupCount: state.groupCount });



    }


    if (input != 'productFamily')
      setState({ ...state, [input]: [value] });
  }


  const onSubmit = data => {
    if (data) {
      console.log("data:", data);
    }
  }




  return (
    <div className="main-content">

      {loading ? <Spinner /> : (
        <Container className="mt-4 col-lg-9 col-md-9 col-sm-12  " fluid>
          <form noValidate onSubmit={handleSubmit(save)}>
            {loading && <div style={{ top: '50%', left: '50%', zIndex: '1000', }}><ModalSpinner /></div>}
            <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="typeId" ref={register} name="typeId" value={typeId} control={control} />

            {/* ---------------------------Trade information information------------------------------           */}
            <Card>
              <CardHeader>
                <h3 className="text-center">Trade Information</h3>

              </CardHeader>

              <CardBody>

                <div className="col-lg-12 ">
                  <h4 >Select Product Families * <span id='algorithm'><i class="far fa-handshake didy"></i></span>
                    <UncontrolledTooltip className='didy2' className="handshak" delay={0} delay={0} placement="bottom" target="algorithm">This field is included in the matching algorithm</UncontrolledTooltip></h4>                  <div className="form-group">

                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="productCat" name="productCat" ref={register} value={state.productValue.length ? state.productValue[0].childname : ''} />
                    <FormAutocompleteGrouping
                      id="Select Product Families"
                      name="Select Product Families"
                      label="Select Product Families"
                      Options={state.productFamilies}
                      placeholder="Select Product Families"
                      input='productFamily'
                      errMessage={'Please Select a Product'}
                      error={!errors.productCat || state.productValue.length ? false : true}
                      //error={errors.ProductGroup}
                      //defaultValue={productFamilies}
                      value={state.productValue}

                      optionName='productFamilies'
                      optionValue='productValue'
                      defaultValue={state.productValue}
                      selectedItem={selectedItem}

                      handleChangeSelect={handleChangeSelect}
                      control={control}
                      register={register}
                    />
                  </div>
                </div>




                <div className="text-center mt-4">
                  <button onClick={e => { e.preventDefault(); props.toggleNavs("1"); }} className="btn btn-primary" >Back</button>
                  <button type="submit" className="btn btn-primary" >Next</button>
                </div>
              </CardBody>
            </Card>

          </form>

        </Container>
      )}
    </div>


  );
};


function mapState(state) {
  const { trade_info } = state.adduser;
  return { trade_info };
}

const actionCreators = {
  adduser: adduserActions.adduser
};


export default connect(mapState, actionCreators)(AddtradeInformation);

