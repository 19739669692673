import React, {useState,useEffect} from 'react';
import {Card,CardBody,Row,Col} from "reactstrap";
import {InputLabel,Select,TextField,FormControl} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { useForm} from "react-hook-form";
import {FormAutocomplete} from "_components/FormElements/FormInput";
import { commonService } from '_services/common.service';
import { Spinner} from 'views/Hoc/Spinner';
import { history } from '_helpers/history';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const Couponform = (props) => {

  

  const { register, handleSubmit, control, errors } = useForm({      
    mode:'onBlur',  
    //resolver: yupResolver(TradeInformationSchema),
  });
  
  const {couponId,getCouponListing} = props;

  const [loading,setLoading] = useState(false);
  
    const [state, setState] = useState({
      
        planOptions : [{id:1,name:"Free"},{id:7,name:"LITE"},{id:2,name:"STARTER"},{id:5,name:"PRO"},{id:4,name:"ADVANCED"},{id:3,name:"TRIAL"}],
        planValue : [],
        typeOptions : [{id:2,name:"VENDOR"},{id:3,name:"DISTRIBUTOR"},{id:4,name:"RETAILER"}],
        typeValue : [],
        subTypeOptions : [{id:1,name:"New"},{id:2,name:"Renewal"},{id:3,name:"UpGrade"}],
        subTypeValue : [],
        statusOptions : [{id:"1",name:"Active"},{id:'0',name:"InActive"}],
        statusValue : [],
        periodOptions : [{id:1,name:"Month"},{id:2,name:"Year"}],
        periodValue : [],
        discountOptions : [{id:2,name:"Percentage",  id:1,name:"Amount"}],
        discountValue : [],                
        couponDesc:'',
        couponCode:'',
        couponOfferValue:'',
        userThreshold:'',
        usageThreshold:'',
        fromDate:new Date('2014-08-18T21:11:54'),        
        toDate:new Date('2014-08-18T21:11:54'),        
      });

      
    //const [fromDate, setFromDate] = React.useState(new Date('2014-08-18T21:11:54'));
    //const [toDate, setToDate] = React.useState(new Date('2014-08-18T21:11:54'));

    useEffect(() => {
      getSubscriptionPlans();
      if(couponId!=0)
      {
        (async () => {
        //setLoading(true);             
        let coupons = await commonService.getServices('channelAdmin/channelCoupons/'+couponId);  
        console.log("coupons:",coupons.data[0].subscriptionPlan.subscriptionPlanId); 
        
        setState({...state,planValue:[{id:coupons.data[0].subscriptionPlan.subscriptionPlanId,name:coupons.data[0].subscriptionPlan.subscriptionPlanType}],
          subTypeValue:{id:coupons.data[0].subscriptionType.subscriptionTypeId,name:coupons.data[0].subscriptionType.subscriptionType},
          typeValue:{id:coupons.data[0].channelType.channelTypeId,name:coupons.data[0].channelType.channelType},
          periodValue:[{id:coupons.data[0].subscriptionPeriodId,name:coupons.data[0].subscriptionPeriodId==1?'Month':'Year'}],
          statusValue:{id:coupons.data[0].isActive,name:coupons.data[0].isActive==1?'Active':'InActive'},
          discountValue:{id:coupons.data[0].discountUnit,name:coupons.data[0].discountUnit==2?'Percentage':'Money'},
          couponDesc:coupons.data[0].couponDesc,
          couponCode:coupons.data[0].couponCode,
          couponOfferValue:coupons.data[0].couponOfferValue,
          userThreshold:coupons.data[0].userThreshold,
          usageThreshold:coupons.data[0].usageThreshold,
          fromDate:coupons.data[0].fromDate,
          toDate:coupons.data[0].toDate,
          
        });
                
        
        })();
      }
      else
      {
        setState({...state,planOptions : [{id:1,name:"Free"},{id:7,name:"LITE"},{id:2,name:"STARTER"},{id:5,name:"PRO"},{id:4,name:"ADVANCED"},{id:3,name:"TRIAL"}],
        planValue : [],
        typeOptions : [{id:2,name:"VENDOR"},{id:3,name:"DISTRIBUTOR"},{id:4,name:"RETAILER"}],
        typeValue : [],
        subTypeOptions : [{id:1,name:"New"},{id:2,name:"Renewal"},{id:3,name:"UpGrade"}],
        subTypeValue : [],
        statusOptions : [{id:"1",name:"Active"},{id:'0',name:"InActive"}],
        statusValue : [],
        periodOptions : [{id:1,name:"Month"},{id:2,name:"Year"}],
        periodValue : [],
        discountOptions : [{id:2,name:"Percentage", id:1,name:"Amount"}],
        discountValue : [],                
        couponDesc:'',
        couponCode:'',
        couponOfferValue:'',
        userThreshold:'',
        usageThreshold:'',
        fromDate:new Date('2014-08-18T21:11:54'),        
        toDate:new Date('2014-08-18T21:11:54')});        
      }
    }, [couponId]); 
  
    const  getSubscriptionPlans = async() => {
      await commonService.getServices('/channelAdmin/getSubscriptionPlan').then(res=>{
          setState({...state,planOptions : res.data});
        });
    }
    const handleChange = input => e => {          
    setState({...state, [input] : e.target.value});         
     };

      
      const save = async() => {
                
        let data={
        isActive:state.statusValue.id,
        subscriptionTypeId:+state.subTypeValue.id,
        channelTypeId:+state.typeValue.id,                 
        discountUnit:+state.discountValue.id,
        couponOfferValue:+state.couponOfferValue,
        userThreshold:+state.userThreshold,
        usageThreshold:+state.usageThreshold,
        couponDesc:state.couponDesc,
        couponCode:state.couponCode,
        fromDate:state.fromDate,
        toDate:state.toDate,
        }

        if(couponId==0)
        {
          Object.assign(data,{subscriptionPlans:state.planValue.map(x => x.id),
            subscriptionPeriods:state.periodValue.map(x => x.id)});
        }
        else
        {
          Object.assign(data,{ subscriptionPlanId:+state.planValue[0].id,
            subscriptionPeriodId:+state.periodValue[0].id})
        }
        
        setLoading(true);   
        if(couponId==0)
          await commonService.postService(data,'channelAdmin/addCoupon');  
        else
          await commonService.putService('channelAdmin/channelCoupons/'+couponId,data);  
        setLoading(false);   
        
       closeDrawer();

       getCouponListing();
       
      }    

      
      const handleFromDate = (date) => {        
        state.fromDate=date;
        setState({...state,fromDate:state.fromDate})
        //setFromDate(date);
      };

      const handleToDate = (date) => {        
        state.toDate=date;
        setState({...state,toDate:state.toDate})
        //setToDate(date);
      };

      const handleChangeSelect = (input,value) => {       
          setState({...state, [input]:value});  
      }

      const [showDrawer,setshowDrawer]=useState(false);
      console.log("text",showDrawer);
      const closeDrawer =()=>{
        props.onClose();
        setshowDrawer(false);
      }

    return (
      
        <div>     
        {loading? <Spinner />: (          
            <Card id='Coupon'>
                <CardBody>
                <form className="coupon-form" noValidate autoComplete="off" onSubmit={handleSubmit(save)}>
                    <Row>
                        <Col lg="6" className="mt-4">
                        <TextField id="outlined-basic" value={state.couponDesc} onChange={handleChange('couponDesc')} label="Coupon Description" variant="outlined" />
                        </Col>
                        <Col lg="6" className="mt-4">
                        <TextField id="outlined-basic" value={state.couponCode} label="Coupon Code" onChange={handleChange('couponCode')} variant="outlined" />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" className="mt-4">
                        <TextField id="outlined-basic" value={state.couponOfferValue} label="Coupon Offer Value" onChange={handleChange('couponOfferValue')} variant="outlined" />
                        </Col>
                        <Col lg="6" className="mt-4">
                        <FormControl variant="outlined">
                        <FormAutocomplete
                        id="discount"
                        name="discount"
                        label="Coupon Discount Unit"
                        options={state.discountOptions}
                        placeholder="Coupon Discount Unit"
                        input='discountValue'
                        errMessage={'Please Select Coupon Discount Unit'}
                        //error={!errors.subplan || state.subplan.length ?false:true}
                        value={state.discountValue}
                        defaultValue={state.discountValue}
                        handleChangeSelect={handleChangeSelect}
                        control={control}                 
                        //multiple
                        disableCloseOnSelect
                        blurOnSelect
                        getOptionSelected={(options, value) => options.name === value.name}
                        />
                        
                            </FormControl>
                        </Col>
                    </Row>



                    <Row>
                        <Col lg="6" className="mt-4">
                        <span>Coupon From Date</span>
                        <div className="awd-date">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="datefrom"
                                //label="Coupon From Date"
                                value={state.fromDate}
                                onChange={handleFromDate}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                        </MuiPickersUtilsProvider>
                        </div>
                        </Col>
                        <Col lg="6" className="mt-4">
                        <span>Coupon To Date</span>
                        <div className="awd-date">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="dateto"
                                // label="Coupon To Date"
                                value={state.toDate}
                                onChange={handleToDate}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                        </MuiPickersUtilsProvider>
                        </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="6" className="mt-4">
                        <FormControl variant="outlined">
                        <FormAutocomplete
                        id="status"
                        name="status"
                        label="Coupon Active/Inactive"
                        options={state.statusOptions}
                        placeholder="Coupon Active/Inactive"
                        input='statusValue'
                        errMessage={'Please Select Coupon Active/Inactive'}
                        //error={!errors.subplan || state.subplan.length ?false:true}
                        value={state.statusValue}
                        defaultValue={state.statusValue}
                        handleChangeSelect={handleChangeSelect}
                        control={control}                 
                        //multiple
                        disableCloseOnSelect
                        blurOnSelect
                        getOptionSelected={(options, value) => options.name === value.name}
                        />
                        
                            </FormControl>
                        </Col>
                        <Col lg="6" className="mt-4">
                        <FormControl variant="outlined">
                        <FormAutocomplete
                        id="subtype"
                        name="subtype"
                        label="Coupon subscription Type"
                        options={state.subTypeOptions}
                        placeholder="Coupon subscription Type"
                        input='subTypeValue'
                        errMessage={'Please Select Coupon subscription Type'}
                        //error={!errors.subplan || state.subplan.length ?false:true}
                        value={state.subTypeValue}
                        defaultValue={state.subTypeValue}
                        handleChangeSelect={handleChangeSelect}
                        control={control}                 
                        //multiple
                        disableCloseOnSelect
                        blurOnSelect
                        getOptionSelected={(options, value) => options.name === value.name}
                        />                        
                        </FormControl>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="6" className="mt-4">
                        <FormControl variant="outlined">
                        <FormAutocomplete
                        id="channeltype"
                        name="channeltype"
                        label="Coupon Channel Type"
                        options={state.typeOptions}
                        placeholder="Coupon Channel Type"
                        input='typeValue'
                        errMessage={'Please Select Coupon Channel Type'}
                        //error={!errors.subplan || state.subplan.length ?false:true}
                        value={state.typeValue}
                        defaultValue={state.typeValue}
                        handleChangeSelect={handleChangeSelect}
                        control={control}                 
                        //multiple
                        disableCloseOnSelect
                        blurOnSelect
                        getOptionSelected={(options, value) => options.name === value.name}
                        />                        
                        </FormControl>
                        </Col>
                        <Col lg="6" className="mt-4">
                        <FormControl variant="outlined">

                        <FormAutocomplete
                        id="SubscriptionPlan"
                        name="SubscriptionPlan"
                        label="Select Subscription Plan"
                        options={state.planOptions}
                        placeholder="Select Subscription Plan"
                        input='planValue'
                        errMessage={'Please Select Subscription Plan'}
                        //error={!errors.subplan || state.subplan.length ?false:true}
                        value={state.planValue}
                        defaultValue={state.planValue}
                        handleChangeSelect={handleChangeSelect}
                        control={control}                 
                        multiple
                        disableCloseOnSelect
                        blurOnSelect
                        getOptionSelected={(options, value) => options.name === value.name}
                        />
                        
                            </FormControl>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="6" className="mt-4">
                        <TextField id="outlined-basic" value={state.userThreshold} label="User Threshold" variant="outlined" onChange={handleChange('userThreshold')} />
                        </Col>
                        <Col lg="6" className="mt-4">
                        <TextField id="outlined-basic" value={state.usageThreshold} label="Usage Threshold" variant="outlined" onChange={handleChange('usageThreshold')}/>
                        </Col>
                    </Row>

                    <Row>
                    <Col lg="12" className="mt-4">
                        <FormControl variant="outlined">
                        <FormAutocomplete
                        id="SubPeriod"
                        name="SubPeriod"
                        label="Coupon Subscription Period"
                        options={state.periodOptions}
                        placeholder="Coupon Subscription Period"
                        input='periodValue'
                        errMessage={'Please Select Coupon Subscription Period'}
                        //error={!errors.subplan || state.subplan.length ?false:true}
                        value={state.periodValue}
                        defaultValue={state.periodValue}
                        handleChangeSelect={handleChangeSelect}
                        control={control}                 
                        multiple
                        disableCloseOnSelect
                        blurOnSelect
                        getOptionSelected={(options, value) => options.name === value.name}
                        />
                        
                            </FormControl>
                        </Col>
                    </Row>
                    <div className="text-center mt-4">
            <button type="submit"  className="btn btn-primary" >Save</button>
            <button onClick={e => {e.preventDefault(); closeDrawer()}}  className="btn btn-warning" >Cancel</button>
          </div> 
                </form>
                </CardBody>
            </Card>
            )};
        </div>
                
    );
};

export default Couponform;