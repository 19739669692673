import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button, Modal, Row, Col
} from "reactstrap";

import { ProfileUploader, BannerUploader } from 'views/Hoc/ProfileUpload';

import { FormInput, FormFileInput, FormSelect, FormButton, FormMultiSelect, FormTextArea, FormCheckBox, FormAutocomplete, FormRadio } from "_components/FormElements/FormInput";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { AddonPackageSchema1 } from './validation';
import { commonService } from '_services/common.service';
import ReactQuill, { Quill } from "react-quill";



export const PackageAddon = (props) => {
  const { handleClose, handleSave, value, visible, packageData, savePackage } = props;
  // const [open, Setopen] = useState(visible)
  const closeOpen = (e) => {
    e.preventDefault();
    // Setopen(false);
    handleClose();
  }

  const { register, handleSubmit, control, errors, watch, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(AddonPackageSchema1),
  });

  let initialState = {
    packageName: '',
    packageType: 2,
    meetingsAllowed: '',
    accessBadgeAllowed: '',
    packageUser: packageData.packageUser,
    description: '',
    belongsTo: null,
    price: '',
    discount: '',
    template: '',
    isPublic: '1',



    // boothSize: '',   
    // privateWebinar: '',
    // firesideChat: '',
    // membersAllowed: '',
    // additionalMembersCosting: '',
    // emailMarketing: false,
    // subscriptionToCH: false,


    // hotel_room: '',
    // Options_for_buyers: [{id: Math.random(), optvalue: '', optname: ''}],
    // marketing_options: [{id: Math.random(), optvalue: '', optname: ''}],
  };

  const [error, setError] = useState('');
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState('');

  const [state, setState] = useState(packageData['isAddon'] ? initialState : packageData);

  const watchAll = watch();



  const handleCropClose = () => {
    setImage('');
  }

  const handleChangeEditor = value => {

    console.log("richtext:", value, value.replace(/<[^>]+>/g, ''))
    setState({ ...state, description: value });

  }

  const handleChange = input => e => {
    if (input == 'description') {
      setState({ ...state, [input]: e.target.value.slice(0, 255) });
    }
    else {
      setState({ ...state, [input]: e.target.value });

    }
  }


  const handleChangeSelect = async (input, value) => {

    setState({ ...state, [input]: value });
  }




  const save = async () => {

    let AddonPackage = {};

    if (packageData.isAddon) {
      AddonPackage = {
        eventId: packageData.eventId,
        packageName: state.packageName,
        price: +state.price,
        packageType: 2,
        packageUser: state.packageUser['id'],
        belongsTo: packageData.packageId,
        isPublic: state.isPublic,
        description: state.description,
        discount: +state.discount,
        template: state.template,
        channelType: packageData.channelType,
        isBuyer: packageData.isBuyer,
        isSeller: packageData.isSeller,
      }
    } else {
      AddonPackage = {
        eventId: packageData.eventId,
        packageName: state.packageName,
        price: +state.price,
        packageType: 2,
        packageUser: state.packageUser['id'],
        packageId: packageData.packageId,
        isPublic: state.isPublic,
        description: state.description,
        discount: +state.discount,
        template: state.template,
        channelType: packageData.channelType,
        isBuyer: packageData.isBuyer,
        isSeller: packageData.isSeller,
      }
    }


    let data = { isNew: packageData.isAddon ? true : false, package: AddonPackage }

    // setState(initialState);
    console.log("packageData:", data)

    await axios.post('channelAdmin/packageaddon', data).then(res => {
      console.log("addon:", res.data);
      if (packageData.isAddon)
        savePackage(data.isNew,{ ...AddonPackage, packageId: res.data.packageId, packageType: packageTypeOption.find(p => p.id == res.data.packageType), packageUser: packageUserOption.find(p => p.id == res.data.packageUser) });
      else
        savePackage(data.isNew,{ ...AddonPackage, packageId: packageData.packageId, packageType: packageTypeOption.find(p => p.id == AddonPackage.packageType), packageUser: packageUserOption.find(p => p.id == AddonPackage.packageUser ) });
      handleClose();
    })
      .catch(err => {
        console.log('error', err);
        handleClose();
      })


  }




  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const selectImg = async (data) => {
    if (data) {
      setImage('');
      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + props.aws_folderName);
      console.log('resImagePath', resImagePath);
      if (resImagePath) {
        setImageSrc(resImagePath.data.url);
        setState({ ...state, eventLogo: resImagePath.data.url, eventLogoId: '' });
        setLoading(false);
      }

    }

  }

  const handleChangeImage = event => {
    const data = event.target.files[0];
    console.log('files', data)
    setError('');
    if (data && data['type'].split('/')[0] === 'image') {
      console.log('datat', data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log('file', file)
        console.log('reader.result', reader.result)
        setImage(
          reader.result
        );
        console.log('image', image)
        //setOpen(false);          
      };

    } else {
      setError('Jpg | Jpeg | PNG Image Formats Only Accepted');
    }

  };

  const deleteOptions = (item, i, input) => {

    if (input == 'marketing') {
      let temp = state.marketing_options.filter(x => x.id != item.id);
      setState({ ...state, marketing_options: temp });
    } else {
      let temp = state.Options_for_buyers.filter(x => x.id != item.id);
      setState({ ...state, Options_for_buyers: temp });
    }



  }

  console.log('watchall', watchAll)

  return (
    <div>
      <Modal className="modal-dialog-centered" size="lg" isOpen={visible}>

        <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="type" name="type" ref={register} value={packageData.isSeller} />

        <div className="modal-header">
          <h2><strong>Package Addon</strong></h2>
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={closeOpen}>
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <form noValidate onSubmit={handleSubmit(save)}>
          <div className="modal-body">

            <Row>

              {/* <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormAutocomplete
                    id="packageType"
                    name="packageType"
                    label="packageType"
                    options={[{ id: 1, name: 'Primary' }, { id: 2, name: 'Addon' }]}
                    placeholder="Select Package"
                    disableCloseOnSelect
                    blurOnSelect
                    limitTags={1}
                    control={control}
                    input="packageType"
                    getOptionSelected={(options, value) => options.name === value.name}
                    value={state.packageType}
                    handleChangeSelect={handleChangeSelect}
                    errMessage={"Please Select Package"}
                    error={errors.packageType}
                    defaultValue={state.packageType}
                  />
                </div>
              </div> */}

              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormAutocomplete
                    id="packageUser"
                    name="packageUser"
                    label="packageUser"
                    options={[state.packageUser]}
                    placeholder="Select Package"
                    disableCloseOnSelect
                    blurOnSelect
                    limitTags={1}
                    control={control}
                    input="packageUser"
                    getOptionSelected={(options, value) => options.name === value.name}
                    value={state.packageUser}
                    handleChangeSelect={handleChangeSelect}
                    errMessage={"Please Select User"}
                    error={errors.packageUser}
                    defaultValue={state.packageUser}
                  />
                </div>
              </div>


              <div className="col-lg-6 mt-3">
                <div className="form-group">
                  <FormRadio
                    name="isPublic"
                    className="text-sm d-inline mb-0"
                    label="Allow this package to show all"
                    options={allowPackageOption}
                    control={control}

                    register={register}
                    // row
                    onChange={handleChange('isPublic')}
                    value={state.isPublic}
                    defaultValue={state.isPublic}

                  />
                </div>
              </div>


              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormInput
                    className=""
                    id="NoofAccessBadgeallowed"
                    label="No of Access Badge allowed"
                    type="text"
                    name="accessBadge"
                    // autoComplete="current-password"
                    variant="outlined"
                    register={register}
                    error={errors.accessBadge}
                    defaultValue={state.accessBadgeAllowed}
                    value={watchAll.accessBadge}
                    onChange={handleChange('accessBadgeAllowed')}
                  />

                </div>
              </div>

              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormInput
                    className=""
                    id="NoofmeetingAllowed"
                    label="No of meeting Allowed"
                    type="number"
                    name="meetingsAllowed"
                    // autoComplete="current-password"
                    variant="outlined"
                    register={register}
                    error={errors.meetingsAllowed}
                    defaultValue={state.meetingsAllowed}
                    value={watchAll.meetingsAllowed}
                    onChange={handleChange('meetingsAllowed')}
                  />

                </div>
              </div>




              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormInput
                    className=""
                    id="PackageName"
                    label="Package Name"
                    type="text"
                    name='packageName'
                    register={register}
                    error={errors.packageName}
                    defaultValue={state.packageName}
                    value={watchAll.packageName}
                    onChange={handleChange('packageName')}

                    // autoComplete="current-password"
                    variant="outlined"
                  />

                </div>
              </div>


              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                Description
                <div className="form-group">
                  {/* <FormTextArea
                  className="buytext2 w-100 h-auto"
                  name="note"
                  id="note" 
                  rows="3"
                  type="textarea"
                  label="Package Description"
                //   placeholder="Tell us about the Event -max 280 characters *"
                       defaultValue={state.description}
                       onChange={handleChange('description')}
              />   */}
                  <ReactQuill
                    // theme="snow"
                    placeholder="Tell us about your company description and vision -max 2000 characters *"
                    onChange={handleChangeEditor}
                    value={state.description}
                    // ref={register}
                    id="description"
                    name="description"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline"],
                        [
                          {
                            list: "ordered"
                          },
                          {
                            list: "bullet"
                          }
                        ]
                      ]
                    }}
                  />
                </div>
              </div>


              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormInput
                    className=""
                    id="discount"
                    label="discount"
                    type="number"
                    name="discount"
                    // autoComplete="current-password"
                    variant="outlined"
                    register={register}
                    //    error={errors.discount}
                    defaultValue={state.discount}
                    value={watchAll.discount}
                    onChange={handleChange('discount')}
                  />

                </div>
              </div>

              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormInput
                    className=""
                    id="pricefree"
                    label="Price/Free"
                    type="number"
                    name="price"
                    // autoComplete="current-password"
                    variant="outlined"
                    register={register}
                    error={errors.price}
                    defaultValue={state.price}
                    value={watchAll.price}
                    onChange={handleChange('price')}
                  />

                </div>
              </div>

              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <h4>Package Logo{!errors.eventLogo || state.eventLogo ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select Event Logo</span>}</h4>

                  <ProfileUploader img={state.eventLogoId ? process.env.REACT_APP_CDN_URL + imageSrc : imageSrc ? process.env.REACT_APP_CDN_URL_TEMP + imageSrc : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
                    handleChange={handleChangeImage} selectImg={selectImg} image={image} handleCropClose={handleCropClose}
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormInput
                    className=""
                    id="template"
                    label="Template Id"
                    type="text"
                    name='template'
                    register={register}
                    error={errors.template}
                    defaultValue={state.template}
                    value={watchAll.template}
                    onChange={handleChange('template')}

                    // autoComplete="current-password"
                    variant="outlined"
                  />

                </div>
              </div>





            </Row>










          </div>

          <div className="modal-footer">
            <Button color="primary" type="submit">Save</Button>

            <Button color="secondary" data-dismiss="modal" type="button" onClick={e => handleClose()} >Cancel</Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}
export default PackageAddon;

const allowPackageOption = [
  { value: "1", label: "Yes" },
  { value: "2", label: "No" },
];

const packageTypeOption = [{ id: 1, name: 'Primary' }, { id: 2, name: 'Addon' }]

const packageUserOption = [{ id: 1, name: 'Primary' }, { id: 2, name: 'Secondary' }]