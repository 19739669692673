import React, { useState, useEffect } from "react";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import { history } from '_helpers/history';
import axios from 'axios';
import { Spinner } from 'views/Hoc/Spinner';

let datas = [];
const NewDashboard = (props) => {

  const [data, setData] = useState(null);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('/channelAdmin/getPendingCount').then(res => {
      console.log("api-->/channelAdmin/getPendingCount ",new Date())
      setData(res.data);
    }).catch((err=>{console.log("api-->/channelAdmin/getPendingCount error",err,"  ",new Date())}))
    setLoading(false);
  }, [])

  console.log("jjjkjkj",props,data)
  return (

    <div id='newdashboard' className="main-content">
      {loading ? <Spinner /> :
        <Card className='mt-4'>
          <CardBody>
            <h4 className="text-left text-uppercase">Approvals</h4>
            <div className="p-1" style={{ backgroundColor: '#f8f9fe' }}>
              <Container className="mt-4" fluid>
                <Row >
                  <Col className="p-0" lg="12" md="12" sm="12" >
                    <div class="row">
                      <div class="dash-card  p-0">
                        <div class="card card-stats">
                          <div class="card-body curs-point pl-0 pr-0" style={{ minHeight: '6.3rem' }} onClick={() => { history.push({ pathname: '/admin/CompanyListing', approval: { signUpStatusId: 14, fromEvent: 0, eventId: 0 } }) }}>
                            <div class="row">
                              <div class="col-8">
                                <h5 class="card-title text-uppercase text-muted mb-0">Channel</h5>
                                <span class="h2 font-weight-bold mb-0">{data?.channel_approval}</span>
                              </div>
                              <div class="col-4">
                                <div class="Counticon icon-shape text-white rounded-circle shadow" style={{ background: 'linear-gradient(87deg, #2c9db7 0, #79def5 100%)' }}>
                                  <i class="fa fa-user" aria-hidden="true"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="dash-card ">
                        <div class="card card-stats">
                          <div class="card-body curs-point pl-0 pr-0" style={{ minHeight: '6.3rem' }} onClick={() => { history.push({ pathname: '/admin/product', approval: 1 }) }}>
                            <div class="row">
                              <div class="col-8">
                                <h5 class="card-title text-uppercase text-muted mb-0">Product</h5>
                                <span class="h2 font-weight-bold mb-0">{data?.product_approval}</span>
                              </div>
                              <div class="col-4">
                                <div class="Counticon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                                  <i class="fab fa-product-hunt"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="dash-card  p-0">
                        <div class="card card-stats">
                          <div class="card-body curs-point pl-0 pr-0" style={{ minHeight: '6.3rem' }} onClick={() => { history.push({ pathname: '/admin/CompanyListing', approval: { signUpStatusId: 15, fromEvent: 0, eventId: 1 } }) }}>
                            <div class="row">
                              <div class="col-8">
                                <h5 class="card-title text-uppercase text-muted mb-0">Event </h5>
                                <span class="h2 font-weight-bold mb-0">{data?.event_approval}</span>
                              </div>
                              <div class="col-4">
                                <div class="Counticon icon-shape bg-gradient-default text-white rounded-circle shadow">
                                  <i class="far fa-calendar-check" aria-hidden="true"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="dash-card ">
                        <div class="card card-stats">
                          <div class="card-body curs-point pl-0 pr-0" style={{ minHeight: '6.3rem' }} onClick={() => { history.push({ pathname: '/admin/CompanyListing', approval: { signUpStatusId: 14, fromEvent: 1, eventId: 0 } }) }}>
                            <div class="row">
                              <div class="col-8">
                                <h5 class="card-title text-uppercase text-muted mb-0">SignUp/Event </h5>
                                <span class="h2 font-weight-bold mb-0">{data?.eventSignup_approval}</span>
                              </div>
                              <div class="col-4">
                                <div class="Counticon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                  <i class="far fa-calendar-check" aria-hidden="true"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="dash-card ">
                        <div class="card card-stats">
                          <div class="card-body curs-point pl-0 pr-0" style={{ minHeight: '6.3rem' }} onClick={() => { history.push({ pathname: '/admin/wiretransfer' }) }}>
                            <div class="row">
                              <div class="col-8">
                                <h5 class="card-title text-uppercase text-muted mb-0">WireTransfer</h5>
                                <span class="h2 font-weight-bold mb-0">{data?.wireTransfer_approval}</span>
                              </div>
                              <div class="col-4">
                                <div class="Counticon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                  <i class="fas fa-money-bill-alt"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="dash-card">
                        <div class="card card-stats">
                          <div class="card-body curs-point pl-0 pr-0" style={{ minHeight: '6.3rem' }} onClick={() => { history.push({ pathname: '/admin/manageEvent', state: { eventId: 3 } }) }}>
                            <div class="row">
                              <div class="col-8">
                                <h5 class="card-title text-uppercase text-muted mb-0">Event Cancel</h5>
                                <span class="h2 font-weight-bold mb-0">{data?.eventCancel_approval}</span>
                              </div>
                              <div class="col-4">
                                <div class="Counticon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                  <i class="far fa-calendar-check" aria-hidden="true"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="dash-card ">
                        <div class="card card-stats">
                          <div class="card-body curs-point pl-0 pr-0" style={{ minHeight: '6.3rem' }} onClick={() => { history.push({ pathname: '/admin/CompanyListing', approval: { associateUser: true } }) }}>
                            <div class="row">
                              <div class="col-9">
                                <h5 class="card-title text-uppercase text-muted mb-0">Associate User</h5>
                                <span class="h2 font-weight-bold mb-0">{data?.associateCount}</span>
                              </div>
                              <div class="col-3" style={{
                                position: "relative",
                                right: "16px"
                              }}>
                                <div class="Counticon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                  <i class="far fa-handshake"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </CardBody>
        </Card>}

      {/* <Card className='mt-4'>
        <CardBody>
          <h4 className="text-left text-uppercase">Channels</h4>
          <div className="p-1" style={{ backgroundColor: '#f8f9fe' }}>
            <Container className="mt-4" fluid>
              <Row >
                <Col className="p-0" lg="12" md="12" sm="12" >
                  <div class="row">
                    <div class="col  p-0">
                      <div class="card card-stats">
                        <div class="card-body curs-point pl-0 pr-0" style={{ minHeight: '6.3rem' }}>
                          <div class="row">
                            <div class="col">
                              <h5 class="card-title text-uppercase text-muted mb-0">Inbound</h5>
                              <span class="h2 font-weight-bold mb-0">350,897</span>
                            </div>
                            <div class="col-auto">
                              <div class="Counticon icon-shape text-white rounded-circle shadow" style={{ background: 'linear-gradient(87deg, #2c9db7 0, #79def5 100%)' }}>
                                <i class="fas fa-arrow-circle-down" aria-hidden="true"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col ">
                      <div class="card card-stats">
                        <div class="card-body curs-point pl-0 pr-0" style={{ minHeight: '6.3rem' }}>
                          <div class="row">
                            <div class="col">
                              <h5 class="card-title text-uppercase text-muted mb-0">Outbound</h5>
                              <span class="h2 font-weight-bold mb-0">2,356</span>
                            </div>
                            <div class="col-auto">
                              <div class="Counticon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i class="fas fa-arrow-circle-up"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col  p-0">
                      <div class="card card-stats">
                        <div class="card-body curs-point pl-0 pr-0" style={{ minHeight: '6.3rem' }}>
                          <div class="row">
                            <div class="col">
                              <h5 class="card-title text-uppercase text-muted mb-0">Confirmed</h5>
                              <span class="h2 font-weight-bold mb-0">350,897</span>
                            </div>
                            <div class="col-auto">
                              <div class="Counticon icon-shape bg-gradient-success text-white rounded-circle shadow">
                                <i class="fas fa-check-circle" aria-hidden="true"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col ">
                      <div class="card card-stats">
                        <div class="card-body curs-point pl-0 pr-0" style={{ minHeight: '6.3rem' }}>
                          <div class="row">
                            <div class="col">
                              <h5 class="card-title text-uppercase text-muted mb-0">Favourite channels</h5>
                              <span class="h2 font-weight-bold mb-0">2,356</span>
                            </div>
                            <div class="col-auto">
                              <div class="Counticon icon-shape text-white rounded-circle shadow" style={{ background: 'linear-gradient(87deg, #2c9db7 0, #79def5 100%)' }}>
                                <i class="fa fa-user" aria-hidden="true"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col p-0">
                      <div class="card card-stats">
                        <div class="card-body curs-point pl-0 pr-0" style={{ minHeight: '6.3rem' }}>
                          <div class="row">
                            <div class="col">
                              <h5 class="card-title text-uppercase text-muted mb-0">Favourite Product</h5>
                              <span class="h2 font-weight-bold mb-0">2,356</span>
                            </div>
                            <div class="col-auto">
                              <div class="Counticon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                                <i class="fab fa-product-hunt"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                   
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </CardBody>
      </Card> */}
    </div>
  );
}


export default NewDashboard;