import React ,{useState} from 'react';
import { Button, Modal } from "reactstrap";
import { FormTextArea } from "_components/FormElements/FormInput";
import { MediaLibrary } from "views/Hoc/Dialog/MediaLibrary";
export  const ChannelConnect=(props)=> {
  const { isOpen, handleClose, updateChannel, value }= props;
  let [ note, setNote ] = useState('');
  let [ introVideoId, setIntroVideo ] = useState(null);
  const conform=()=>{
    console.log('value', value);
    value.note=note;
    value.videoUrl=introVideoId;
    updateChannel(value);
  }
  const handleChange= e =>{
    console.log(e.target.value)
    note = e.target.value;
    setNote(note);
    console.log('note', note);
  }
  const introVideo = (data)=>{
    console.log('introVideoId',data.documentId);
    introVideoId=data.documentId;
    setIntroVideo(introVideoId);
  }
  return (
           <div>
          <Modal className="modal-dialog-centered" contentClassName="" isOpen={isOpen} size="lg">
          <div className="modal-header chlconnect">
           {/* <strong>{value.title}</strong> */}
           <h1>Channel Connect</h1>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={ handleClose}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
                <div className="modal-body text-left">
         
                <h1 style={{fontSize: '20px'}} className="modal-title text-center" id="modal-title-notification">IMPORTANT</h1>
                <div className="justify-content mt-2 ">
          <div className=" ">
          <h4>You are about to send an invitation to connect to another ChannelHub member. The recipient will receive an email with your company profile.</h4>
          <h4><strong>MAKE SURE THAT YOUR COMPANY PROFILE IS FULLY COMPLETE BEFORE PROCEEDING AS THE INVITATION EMAIL WILL INCLUDE MOST OF THE INFORMATION FROM YOUR COMPANY PROFILE</strong></h4>
          </div>
               <div className="text-center">
              <Button className='btn btn-warning' data-dismiss="modal" type="button" size="lg">CHECK MY COMPANY PROFILE</Button>
              </div>
              
              <div className="mt-2 ">
              <p>Click <strong>Here</strong> to preview a sample email and make sure to update the missing information before confirming this invitation.</p>
              <p><strong>Remember: The better the quality of your profile the higher the chances of being accepted</strong></p>
                <div className="text-center mt-2 ">
                <MediaLibrary style={{cursor:'pointer'}} text="you can also upload video to introduce you." introVideoId={introVideo} id='2'/>
                </div> 
              </div>
              
               <div className="mx-auto text-center mt-2">
              <div className=" mx-auto text-center">            
              <form>
                <FormTextArea
                  className="formtxtarea"
                  name="note"
                  id="note" 
                  rows="3"
                  type="textarea"
                  label=""
                  placeholder="Add a personal note (max 200 characters)"
                  // register={register}
                  value={note}
                  onChange={handleChange}
              />
              </form>         

      {/* <TextareaAutosize  width = "100%" /> */}
             </div>           
            </div>
            </div>
              <div className="modal-footer chnlcnt">
                <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Cancel</Button>
                <Button className='btn btn-primary' data-dismiss="modal" type="button" onClick={conform}>Confirm</Button>
              </div>     
              </div>
        </Modal>
    </div>
   
  );
}
