import React, { useState, useEffect } from "react";
import { Container, Row, Button, Col, CardBody, CardHeader, Card, Nav, NavItem, NavLink, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Badge, UncontrolledTooltip } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader"
import Title from "views/Hoc/Dialog/Title";
import { SelectAutocomplete, FormAutocomplete, FormRadio, FormCheckBox } from '_components/FormElements/FormInput';
import { useForm } from "react-hook-form";
import { history } from '_helpers'
import Myprodfilter from './Myprodfilter';
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/pagination/PaginationActions';
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
import { PlanUpgradeDialog } from "views/Hoc/Dialog/PlanUpgradeDialog";
import ReOrderDialog from "views/Hoc/Dialog/ReOrderDialog";
import HotTopics from "views/Hoc/Dialog/HotTopics";
import ProductBulkEdit from './ProductBulkEdit';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SortIcon from '@mui/icons-material/Sort';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Moment from 'moment';
import { width } from "@mui/system";
var _ = require('lodash');

const Myproductlist = (props) => {
  const [opneadd, opneAddnew] = useState(false);
  const [isBulkEdit, setBulkEdit] = useState(false);
  const [productList, setProductList] = useState([]);
  const [index, setIndex] = useState();

  let [tabs, setTab] = useState('1');
  let [tabCount, setTabCount] = useState({ active: 0, inActive: 0, approval: 0 });
  let [sort, setSort] = useState('desc');
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [total, setTotal] = useState(0);
  let [viaFilter, setViaFilter] = useState(0);
  let [isLoading, setLoading] = useState(true);
  let [data, setData] = useState([]);
  const [reordermodal, setReOrderModal] = useState(false);
  const [productIdOrder,setProductIdOrder] = useState("");
  const [newOrder,setNewOrder] = useState("");
  const [currentOrder,setCurrentOrder] = useState("");
  const [channelId,setChannelId] = useState("");
  const [channelProdLength,setChannelProdLength] = useState("");
  let [globalData, setGlobalData] = useState({ active: 0, inActive: 0, approval: 0, list: [] });

  let companyName = '';

  let [editProd, setEditProd] = useState(null);

  const [productLimit, setProductLimit] = useState(true);
  const { control } = useForm({ mode: 'onBlur' });
  const [filterValueState, setFilterValue] = useState({ Position: null, Vendor: [], Brand: [], Family: [], Category: [], tags: [], search: null, sort: true, hotValue: null, businessType: null, createdAt: null, updatedAt: null, createdAtLimit: null, updatedAtLimit: null });
  const [searchState, setSearch] = useState([]);
  const [planUpgrade, setplanUpgrade] = useState(false);
  let [isModalPopup, setModalPopup] = useState(false);
  let logo = "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg";
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });
  useEffect(() => {
    if (props?.location?.approval)
      setActiveTab('2');
    getMyProductList();
    getTabCount();
    //getProductLimit();

    // return () => {
    //   clean operation
    // }

  }, []);

// data.sort(function(channelId1,channelId2){
//   return channelId1 - channelId2;
// })

// useEffect(()=>{
//   if(data.product.channelId == channelId){
//     data.at.produ
//   }
// })

const getTabCount = async () => {
  let res = await axios.get('/master/allproductscount');//.then(res => return res);
 
  setTabCount(res.data);
}

  const getMyProductList = () => {
    setPage(0);
    getMyProductListing();
  }
  const setActiveTab = (tab) => {
    tabs = tab;
    globalData.list = [];
    setGlobalData({ ...globalData });
    setTab(tab);
    getMyProductList();
  }
  const setSorting = (data) => {
    //console.log('sorting:', data, sort);
    if (data != sort) {
      sort = data;
      setSort(sort);
      getMyProductList();
    }

  }

  const groupBy = (array, key) => {
    // Return the end result
    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate

      return result;
    }, {}); // empty object is the initial value for result object
  };

  const toggle = (current,productId,channelId,itemLength) => {
    setCurrentOrder(current)
    setProductIdOrder(productId)
    setChannelId(channelId);
    setChannelProdLength(itemLength)
    setReOrderModal(!reordermodal)
  };

 const finalReOrdering = async ()=>{
  if(newOrder == 0 || newOrder>channelProdLength){
    return ToastsStore.error(`Please enter value between 1 and ${channelProdLength}`);
  }
  setLoading(true);
  let res=await axios.post("/product/updateSortOrder",{
  newOrder:newOrder,
  currentOrder:currentOrder,
  productId:productIdOrder,
  channelId:channelId
})
  setLoading(false);
  window.location.reload();
 ToastsStore.success('Successfuly set the new order');
 setReOrderModal(!reordermodal)
}

const handleChangeOrder = (e)=>{
    setNewOrder(e.target.value)
}

  const opneAdd = (indexValue) => {
    setProductList(data[indexValue].product);
    setIndex(indexValue);
    opneAddnew(true);
  }

  function mapOrder(array, order, key) {

    array.sort(function (a, b) {
      var A = a[key], B = b[key];

      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }

    });

    return array;
  };

  const closeModalPopup = (items) => {
    opneAddnew(false);
    let updateList = [];
    let sorted = _.sortBy(data[index].product, x => _.findIndex(items, y => x.productId === y.productId))
    data[index].product = sorted;
    setData(data);

    items.map((x, index) => {
      let list = {
        productId: x.productId,
        order: index + 1
      }
      updateList.push(list);
    })

    commonService.putService('product/setProductOrder', updateList);

    //console.log("pitems:", sorted);

  }
  const getMyProductListing = async () => {
    setViaFilter(0);
    setSelectAll(0);
    setSelectedProducts([]);
    setSelectedCompanyNames([]);


    let postData = {
      filter: getFilterData(),
      order: sort ? sort : 'desc', //:'desc',
      search: filterValueState.search ? filterValueState.search.id : null,
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      isactive: tabs <2? tabs : '0',
      isApproved: tabs >=2  ? '0' : null,
      partActive:tabs==3?"1":null,
      isPending:tabs==2?"1":null
    }

    if (filterValueState?.search?.id) {
     // console.log("search:", filterValueState.search.id)
      viaFilter = 1;
      setViaFilter(viaFilter);
    }

   setLoading(true);
    let arr = [];
    //console.log("globalData.length:", viaFilter);
    let flag = 0;



    if (viaFilter != 0 || postData.search != null || globalData.list.length == 0 || editProd) {

      
      let res = await commonService.postService(postData, 'product/all-products').then(res => {
        return res;
      }).catch(err => setLoading(false));

      if (res && res.data.result) {
        setLoading(false);
               

        flag = 1;
        //console.log(res.data);
        data = res.data;

        let searchData = [];


        const productGroupedByCompany = groupBy(data.result ? data.result : [], 'companyName');

        for (var key in productGroupedByCompany) {
          if (productGroupedByCompany.hasOwnProperty(key)) {
            arr.push({ cname: key, product: productGroupedByCompany[key] });
            //console.log("productbo" + " -> " + productGroupedByCompany[key]);
          }
        }
        total = arr.length;
        globalData.list = arr;
        setGlobalData(globalData);


        arr = arr.slice(page == 0 ? 0 : (page * rowsPerPage), page == 0 ? rowsPerPage : rowsPerPage * (page + 1));
        arr = arr.map(item=>{
          item.product= item.product.sort((a,b)=>{
          return a.sortorder - b.sortorder;
        })
      return item
      
      })
        setData(arr);
        setTotal(total);

        if (postData.search == null && globalData.list.length == 0) {
          data.result.map(x => {
            searchData.push({ id: x.productId, name: x.product + '-' + x.companyName });
          })
          setSearch(searchData);
          // globalData.active = res.data.tabCount.active;
          // globalData.inActive = res.data.tabCount.inActive;
          // globalData.approval = res.data.tabCount.approval
          
        }

        // tabCount.active = res.data.tabCount.active
        // tabCount.inActive = res.data.tabCount.inActive
        // tabCount.approval = res.data.tabCount.approval
        // setTabCount(tabCount)

       
        // if (tabs === '1') {
        //   tabCount.active = total;
        // } else {
        //   tabCount.inActive = total;
        // }
        
       // setLoading(false);
      }
    }
    else {
      globalData.list = _.orderBy(globalData.list, 'cname', sort);

      let a = globalData.list.slice(page == 0 ? 0 : (page * rowsPerPage), page == 0 ? rowsPerPage : rowsPerPage * (page + 1));

      //console.log("globalData.list:", a);
      a = _.orderBy(a, 'cname', sort);
      a = a.map(item=>{
        item.product= item.product.sort((a,b)=>{
        return a.sortorder - b.sortorder;
      })
    return item
    })
      //console.log("afterglobalData.list:", a);
      flag = 1;
      // if (tabs === '1') {
      //   tabCount.active = globalData.list.length;
      // } else {
      //   tabCount.inActive = globalData.list.length;
      // }
      setTabCount(tabCount)
      setTotal(globalData.list.length);
      setData(a);
      setLoading(false);
    }

    //setData(arr);//data.result);

    if (flag == 0) {
      data = [];
      setData(data);
      total = 0;
      setTotal(total);
      setLoading(false);
    }
  }

  const getFilterData = () => {

    let filter = {
      position: null,
      vendor: null,
      brand: null,
      family: null,
      country: null,
      location: null,
      profile: null,
      category: null,
      tags: null,
      hot_topic: null,
      businessType: null,
      datelimit: null,
      updatedAt: null,
      createdAt: null,
      createdAtLimit: null,
      updatedAtLimit: null
    };
    //console.log("filterValueState:", filterValueState);
    if (filterValueState.Position) {
      filter.position = filterValueState.Position.id;
      viaFilter = 1;
      setViaFilter(viaFilter);
    }
    if (filterValueState.Vendor && filterValueState.Vendor.length > 0) {
      filter.vendor = filterValueState.Vendor.map(x => parseInt(x.id));
      viaFilter = 1;
      setViaFilter(viaFilter);
    }
    if (filterValueState.Brand && filterValueState.Brand.length > 0) {
      filter.brand = filterValueState.Brand.map(x => parseInt(x.id));
      viaFilter = 1;
      setViaFilter(viaFilter);
    }
    if (filterValueState.Family && filterValueState.Family.length > 0) {
      filter.family = filterValueState.Family.map(x => parseInt(x.id));
      viaFilter = 1;
      setViaFilter(viaFilter);
    }
    if (filterValueState.Category && filterValueState.Category.length > 0) {
      filter.category = filterValueState.Category.map(x => parseInt(x.id));
      viaFilter = 1;
      setViaFilter(viaFilter);
    }
    if (filterValueState.tags && filterValueState.tags.length > 0) {
      filter.tags = filterValueState.tags.map(x => parseInt(x.id));
      viaFilter = 1;
      setViaFilter(viaFilter);
    }
    if (filterValueState.hotValue) {// && filterValueState.hotValue.length > 0) {
      filter.hot_topic = filterValueState.hotValue.id; //map(x => parseInt(x.id));
      viaFilter = 1;
      setViaFilter(viaFilter);
    }
    if (filterValueState.businessType) {
      filter.businessType = filterValueState.businessType.id;
      viaFilter = 1;
      setViaFilter(viaFilter);
    }
    if (filterValueState.createdAt) {
      filter.createdAt = filterValueState.createdAt.value;
      viaFilter = 1;
      setViaFilter(1);
    }
    if (filterValueState.updatedAt) {
      filter.updatedAt = filterValueState.updatedAt.value;
      viaFilter = 1;
      setViaFilter(1);
    }
    if (filterValueState.updatedAtLimit) {
      filter.updatedAtLimit = filterValueState.updatedAtLimit;
      viaFilter = 1;
      setViaFilter(1);
    }
    if (filterValueState.createdAtLimit) {
      filter.createdAtLimit = filterValueState.createdAtLimit;
      viaFilter = 1;
      setViaFilter(1);
    }

    //console.log('filter', filter);
    return filter;
  }
  const handleChangeSelect = (input, value) => {
   // console.log('input, value', input, value);
    if (input === 'search') {
      filterValueState.search = value;
    }
    if (input === 'sort') {
      filterValueState.sort = value;
    }
    if (input === 'position') {
      filterValueState.Position = value;
    }
    if (input === 'vendor') {
      filterValueState.Vendor = value;
    }
    if (input === 'brand') {
      filterValueState.Brand = value;
    }
    if (input === 'family') {
      filterValueState.Family = value;
    }
    if (input === 'category') {
      filterValueState.Category = value;
    }
    if (input === 'tags') {
      filterValueState.tags = value;
    }
    if (input === 'hot_topics') {
      filterValueState.hotValue = value;
    }
    if (input === 'business_type') {
      filterValueState.businessType = value;
    }
    if (input === 'createdAt') {
      filterValueState.createdAt = value;
      filterValueState.createdAtLimit = null;
    }
    if (input === 'updatedAt') {
      filterValueState.updatedAt = value;
      filterValueState.updatedAtLimit = null;
    }
    if (input == 'createdAtLimit') {
      filterValueState.createdAtLimit = value;
      filterValueState.createdAt = null;
    }
    if (input == 'updatedAtLimit') {
      filterValueState.updatedAtLimit = value;
      filterValueState.updatedAt = null;
    }

    setFilterValue({ ...filterValueState })
   // console.log('filterValueState', filterValueState);
    getMyProductList();
  }
  const resetFilterValue = () => {
    filterValueState.Position = [];
    filterValueState.Vendor = [];
    filterValueState.Brand = [];
    filterValueState.Family = [];
    filterValueState.Category = null;
    filterValueState.tags = null;
    filterValueState.hotValue = null;
    filterValueState.businessType = null;
    filterValueState.search = null;
    filterValueState.sort = null;
    filterValueState.createdAt = null;
    filterValueState.updatedAt = null;
    filterValueState.createdAtLimit = null;
    filterValueState.updatedAtLimit = null;
    setFilterValue({ ...filterValueState });
  }
  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getMyProductListing();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    getMyProductListing();
  };
  const searchOption = (option) => {
    searchState.dropdownList = option;
    setSearch({ ...searchState });
  }
  const productEdit = (sku, id, channelId) => {
    window.open(window.location.origin + '/admin/productmanage?sku=' + sku + '&action=' + id + '&channelId=' + channelId, '_blank');
  }
  const productDelete = async (sku) => {
    setLoading(true);
    let res = await commonService.deleteService('product/' + sku).catch(err => ToastsStore.error('Something went wrong!...'));
    setLoading(false);
    if (res) {
      ToastsStore.success('Product deleted successfully!...');
      getMyProductList();
    }
  }
  const confirmPopup = (res) => {
    setModalPopup(false);
    productDelete(res.value);
  }
  const closePopup = () => {
    setModalPopup(false);
  };
  const deletePopup = (sku) => {
    modalPopValue.message = 'Do you want to delete the product?';
    modalPopValue.title = 'Product';
    modalPopValue.value = sku;
    modalPopValue.popUpId = 1;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);
  }

  //Move product towards Up
  const moveUp = async (sku, indexValue) => {
    let newdata = [];
    let prevItem = {};
    let flag = 0;
    let tempdata = data;

    if (tempdata[indexValue].product.length > 1) {
      tempdata[indexValue].product.map((x, index) => {
        if (x.productSKU == sku) {
          let p = tempdata[indexValue].product.slice(0, (index - 1));
          newdata.push(...p);
          newdata.push(x);
          newdata.push(prevItem);
          flag = 1;
        } else if (flag == 1) {
          newdata.push(x);
        }
        else if (flag == 0) {
          prevItem = x;
        }

      })

      let rorder = 0;
      newdata.map(x => {
        rorder = rorder + 1;
        Object.assign(x, { order: rorder })
      })

      tempdata[indexValue].product = newdata;
      setData({ ...tempdata });
      commonService.putService('product/setProductOrder', tempdata[indexValue].product);
      getMyProductListing();

    }
  }


  //Move product towards Down
  const moveDown = async (sku, indexValue) => {
    let newdata = [];
    let nextItem = {};
    let flag = 0;
    let tempdata = data;

    if (tempdata[indexValue].product.length > 1) {
      tempdata[indexValue].product.map((x, index) => {
        if (x.productSKU == sku) {
          let p = tempdata[indexValue].product.slice(0, index);
          newdata.push(...p);
          nextItem = x;
          flag = 1;
        } else if (flag == 1) {
          newdata.push(x);
          newdata.push(nextItem);
          flag = 2;
        }
        else if (flag == 2) {
          newdata.push(x);
        }

      })

      let rorder = 0;
      newdata.map(x => {
        rorder = rorder + 1;
        Object.assign(x, { order: rorder })
      })

      tempdata[indexValue].product = newdata;
      setData({ ...tempdata });
      commonService.putService('product/setProductOrder', tempdata[indexValue].product);
      getMyProductListing();

    }
  }

  const setPublicView = async (productId, isPublic, cname) => {
   // console.log("pubview:", isPublic, data);
    let item = {
      productId: +productId,
      isPublic: isPublic ? 1 : 0
    }
    try {
      data = data.map(x => {
        if (x.cname == cname) {
          x.product.map(p => {
            if (p.productId == item.productId)
              p.isPublic = item.isPublic;
            else
              p.isPublic = p.isPublic;
            // return p;
          })
          return x;
        }
        else
          return x;

      })
    }
    catch (e) {
      console.log("puberr:", e);
    }
    setData(data);

    await commonService.putService('product/setProductPublicView', item);

  }


  const setBusinessType = (productId, cname) => async e => {
    //console.log("pubview:", e.target.value, productId);
    let item = {
      productId: +productId,
      businessType: e.target.value
    }
    try {
      data = data.map(x => {
        if (x.cname == cname) {
          x.product.map(p => {
            if (p.productId == item.productId)
              p.businessType = item.businessType;
            else
              p.businessType = p.businessType;
            // return p;
          })
          return x;
        }
        else
          return x;

      })
    }
    catch (e) {
      console.log("puberr:", e);
    }
    setData(data);

    await commonService.putService('product/setProductType', item);

  }

  const getProductLimit = async () => {
    await commonService.getServices('product/productlimit').then(res => {
      if (res.data.allowed) {
        setProductLimit(res.data.allowed);
      }
    });
    // let res = await commonService.getServices('product/productlimit').catch(err => ToastsStore.error('Something went wrong!...'));
    // setLoading(false);
  }

  const openUpgradeDialog = () => {
    let productCount = parseInt(tabCount.active) + parseInt(tabCount.inActive);
    if (productLimit > productCount || productLimit === 'Unlimited') {
      history.push({ pathname: '/admin/Productform', state: { type: 2 } });
    } else {
      setplanUpgrade(true);
    }
  }

  const closeplanUpgrade = () => {
    setplanUpgrade(false);
  }

  const [pubFlag, setPublicFlag] = useState(false);
  const [publicFlagData, setPublicFlagData] = useState({});

  const openPublicFlag = (item) => {
    let data = {
      channelId: item.channelId,
      productId: item.productId,
      isFeatured: item.isFeatured == '1' ? true : false,
      eco_friendly: item.eco_friendly == '1' ? true : false,
      work_from_home: item.work_from_home == '1' ? true : false,
      life_style: item.life_style == '1' ? true : false,
      french_tech: item.french_tech == '1' ? true : false,
    };

    setPublicFlagData(data);
    setPublicFlag(true);

  }

  const closePublicFlag = () => {
    setPublicFlagData({});
    setPublicFlag(false);
  }

  const [selectAll, setSelectAll] = useState(0);
  let [selectedProducts, setSelectedProducts] = useState([]);
  let [selectedCompanyNames, setSelectedCompanyNames] = useState([]);

  const handleChangeCheck = (compname, id) => {
    let temp = [];
    let names = [];
    if (id == 1) {
      if (selectAll) {
        temp = [];
        names = [];
        setSelectAll(0);
      }
      else {
        data.map(x => {
          names.push(x.cname);
          if (x.product.length) {
            x.product.map(y => {
              temp.push(y.productId);
            })
          }
        });
        setSelectAll(1);
      }
      setSelectedProducts(temp);
      setSelectedCompanyNames(names);
    }
    else if (id == 2) {
      if (selectedCompanyNames.includes(compname)) {
        let temp = data.map(x => {
          if (x.cname == compname)
            return x;
        });
        temp = temp.filter(x => x);
        let productIds = [];
        let names = [];
        temp.map(x => {
          names.push(x.cname);
          x.product.map(y => productIds.push(y.productId));
        });

        selectedProducts = selectedProducts.filter(x => !productIds.includes(x));
        selectedCompanyNames = selectedCompanyNames.filter(x => !names.includes(x));
        setSelectedProducts(selectedProducts);
        setSelectedCompanyNames(selectedCompanyNames);
      } else {
        let temp = data.map(x => {
          if (x.cname == compname)
            return x;
        });
        temp = temp.filter(x => x);

        let productIds = [];
        temp.map(x => {
          selectedCompanyNames.push(x.cname);
          x.product.map(y => productIds.push(y.productId));
        });

        let temp1 = new Set(selectedProducts);
        temp1 = new Set(productIds.concat([...temp1]));
        selectedProducts = [...temp1];
        setSelectedProducts(selectedProducts);
        setSelectedCompanyNames(selectedCompanyNames);
      }
    }
    else if (id == 3) {
      if (selectedProducts.includes(compname)) {
        let prodname = [];
        data.map(x => x.product.map(y => {
          if (y.productId == compname) {
            prodname.push(x.cname);
          }
        }))

        selectedProducts = selectedProducts.filter(x => x != compname);
        selectedCompanyNames = selectedCompanyNames.filter(x => !prodname.includes(x));

        setSelectedProducts(selectedProducts);
        setSelectedCompanyNames(selectedCompanyNames);
      } else {

        let temp = selectedProducts;
        temp.push(compname);

        let cname = '';
        let prodArray = [];

        data.map(x => x.product.map(y => {
          if (y.productId == compname) {
            cname = x.cname;
            prodArray = x.product;
          }
        }));

        prodArray = prodArray.map(x => x.productId);

        if (!prodArray.some(val => !temp.includes(val))) {
          selectedCompanyNames.push(cname);
        }


        selectedProducts = temp;
        setSelectedProducts([...selectedProducts]);
        setSelectedCompanyNames(selectedCompanyNames)
      }
    }

  }

  const setSelectedAsInactive = async () => {
    let productIds = _.uniq(selectedProducts);
    setLoading(true);
    let data = {
      productIds,
      isactive: tabs == '0' ? '1' : '0'
    }

    await axios.post('/channelAdmin/bulkActivate', data).then(async res => {
      ToastsStore.success('Product ' + (tabs == '0' ? 'activated' : 'deactivated') + ' successfully');
      editProd = true;
      setEditProd(editProd);
      setPage(0);
      globalData.list = [];
      setGlobalData({ ...globalData });
      await getMyProductListing();
    }).catch(err => {
      ToastsStore.error('something went wrong');
      setEditProd(false);
      setLoading(false);
    });
    setLoading(false);
    setEditProd(false);
  }

  let [open, setModalOpen] = useState(false);
  const openModalPopUp = (productId, approvalType, channelId, productName,productSKU) => {
    if (approvalType == 1) {
      modalPopValue.message = 'You want to Approve';
      modalPopValue.title = "Approve Product";
      modalPopValue.value = { productId: productId, approvalType: approvalType, isApproved: '1', channelId: channelId, productName: productName , productSKU:productSKU };
    } else if (approvalType == 2) {
      modalPopValue.message = 'You want to Approve Product to be active';
      modalPopValue.title = "Activate Product Approval";
      modalPopValue.value = { productId: productId, approvalType: approvalType, isApproved: '1', channelId: channelId, productName: productName , productSKU:productSKU };
    }
    else {
      modalPopValue.message = 'You want to Dismiss';
      modalPopValue.title = "Dismiss Product";
      modalPopValue.value = { productId: productId, approvalType: approvalType, isApproved: '0', channelId: channelId, productName: productName , productSKU:productSKU };
    }
    modalPopValue.popUpId = 1;
    
    setModalPopValue({ ...modalPopValue })
    open = true;
    setModalOpen(open);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const closeBulkEdit = () => {
    setBulkEdit(false);
  };

  const saveBulkEdit = async (value) => {
    setBulkEdit(false);
    //console.log("saveproduct:", value);

    value.map(x => {
      x.isPublic = x.isPublic ? '1' : '0';
      x.isactive = (x.isApproved && x.productImage === 'With Image') ? '1' : x.isactive ? '1' : '0';
      x.isFeatured = +x.isFeatured ? '1' : '0';
      x.show_in_home_page = +x.show_in_home_page ? '1' : '0';
      x.eco_friendly = +x.eco_friendly ? '1' : '0';
      x.work_from_home = +x.work_from_home ? '1' : '0';
      x.life_style = +x.life_style ? '1' : '0';
      x.french_tech = +x.french_tech ? '1' : '0';
      x.taiwan_excellence = +x.taiwan_excellence ? '1' : '0';
      x.isApproved = tabs != '2' ? '1' : (x.isApproved && x.productImage === 'With Image') ? '1' : '0';
    })

    let data = {
      value: value,
      isApproval: tabs == '2' ? '1' : '0'
    }

    await axios.post('/channelAdmin/bulkEdit', data);

    //updateProduct(value.value);
  };


  const handleSave = (value) => {
    open = false;
    setModalOpen(open);
    //console.log('handleSave == ',value);
    approveProduct(value.value);
  };

  const approveProduct = async (data) => {

    setLoading(true);
    //console.log('SKU :: ', data)
    let postdata = {
      productId: data.productId,
      sku: data.productSKU,
      approvalType: data.approvalType,
      isApproved: data.isApproved,
      isActive: data.isApproved == '1' ? '1' : '0',
      channelId: data.channelId
    };

    await axios.post('channelAdmin/approveProduct', postdata).then(async res => {
    // console.log(res.data);
      if (res.data) {
        if(res.data == 'productLimitExcess'){
          ToastsStore.warning('This Channel exceeds the product Limit as per subscription plan');
          setLoading(false);
        }else if(res.data == 'invalidData'){
          ToastsStore.warning('Mandatory fields are missing, moved to In-Completed');
          await getMyProductListing();
          getTabCount()
        } else {
          ToastsStore.success('Product Approved Successfully');
          let maildata = {
            channelId: data.channelId,
            approvalType: data.approvalType,
            productName: data.productName,
            productId: data.productId
          };

          axios.post('mailerservice/productApprovalResponseMail', maildata);

          editProd = true;
          setEditProd(editProd);
          setPage(0);
          globalData.list = [];
          setSearch(null);
          setGlobalData({ ...globalData });
          await getMyProductListing();
          getTabCount()
        }
       
      }
      else {
        ToastsStore.warning('This Product didn\'t have any Product Images');
        setLoading(false);
      }

    }).catch(err => {
      ToastsStore.error('Something went wrong');
      editProd = false;
      setEditProd(editProd);
      setLoading(false);
    });

    setLoading(false);
  }

  //console.log("prods:", data.length, data, selectedProducts, selectedCompanyNames);
  return (

    <>

      {isLoading && <Spinner />}
      <SimpleHeader parentName="MyProduct" />
      {isBulkEdit &&
        <ProductBulkEdit open={isBulkEdit} editType={{ isApproval: tabs == 2 ? '1' : '0' }} products={{ ids: selectedProducts, list: data.map(x => x.product) }} handleClose={closeBulkEdit} handleSave={saveBulkEdit} />}
      <ReOrderDialog opneadd={opneadd} closeModalPopup={closeModalPopup} productList={productList} />
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      {planUpgrade && <PlanUpgradeDialog closeUserView={closeplanUpgrade} />}
      {publicFlagData.hasOwnProperty('channelId') && <HotTopics closeModalPopup={closePublicFlag} opneadd={pubFlag} data={publicFlagData} />}
      <Dialog open={open} value={modalPopValue} handleSave={handleSave} handleClose={handleClose}></Dialog>
      <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
      <div id='MyProduct' className="main-content">
        <Title title="My Product" desc={["List of your various products"]} />
        <Container fluid className="mt-4">
          <Nav tabs className="list-tab">
            <NavItem className="list-main">
              <NavLink className="active">

                <Row className="text-uppercase">
                  <Col onClick={() => setActiveTab('1')} xs="3" className={" mt-2 sub-a p-2 " + (tabs == 1 && "hilight")}>
                    <a style={{ cursor: 'pointer' }}>
                      <h3 className="mb-0">Active</h3>
                      <p class="count m-0">{tabCount.active} </p>
                    </a>
                  </Col>
                  <Col onClick={() => setActiveTab('0')} xs="3" className={" icon-tag2 mt-2 sub-a p-2 " + (tabs == 0 && "hilight")}>
                    <a style={{ cursor: 'pointer' }} >
                      <h3 className="mb-0">In Active</h3>
                      <p class="count m-0">{tabCount.inActive}</p>
                    </a>
                  </Col>
                  <Col onClick={() => setActiveTab('2')} xs="3" className={" icon-tag2 mt-2 sub-a p-2 " + (tabs == 2 && "hilight")}>
                    <a style={{ cursor: 'pointer' }} >
                      <h3 className="mb-0">Pending</h3>
                      <p class="count m-0">{tabCount.approval}</p>
                    </a>
                  </Col>
                  <Col onClick={() => setActiveTab('3')} xs="3" className={" icon-tag2 mt-2 sub-a p-2 " + (tabs == 3 && "hilight")}>
                    <a style={{ cursor: 'pointer' }} >
                      <h3 className="mb-0">Incomplete</h3>
                      <p class="count m-0">{tabCount.partActive}</p>
                    </a>
                  </Col>
                </Row>
              </NavLink>
            </NavItem>
            {!!selectedProducts.length &&

              <a className='p-2 text-center' style={{ cursor: 'pointer' }} onClick={() => setBulkEdit(true)}>
                {/* <a className='p-2 text-center' style={{ cursor: 'pointer' }}  onClick={() => setSelectedAsInactive()}></a> */}
                <div className='actin p-3'>
                  {/* <h3 >{tabs == '0' ? 'Set as Active' : 'Set as Inactive'}</h3> */}
                  <h3>Bulk Edit</h3>
                  <p className='m-0' >{selectedProducts.length}</p>
                </div>
              </a>
            }
            <NavItem className={selectedProducts.length ? "pl-2 right-search2" : "pl-2 right-search1"}>
              <SelectAutocomplete
                id="search"
                type="text"
                label="search"
                name="search"
                options={searchState}
                placeholder="search..."
                disableCloseOnSelect
                //defaultValue={searchState.search?searchState.search[0]:null}
                control={control}
                input='search'
                value={filterValueState.search}
                handleChangeSelect={handleChangeSelect}
              />
            </NavItem>
            <NavItem className={selectedProducts.length ? "right-sort2" : "right-sort1"}>
              <UncontrolledDropdown className="p-0" group>
                <DropdownToggle caret className="p-2">
                  <h3
                    style={{ display: "inline-block" }}
                    className="font-weight-bold m-0"
                  >
                    <i className="fa fa-bars  mr-2" aria-hidden="true"></i>
                    {sort == 'asc' ? 'A-Z' : 'Z-A'}
                  </h3>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem

                    onClick={() => setSorting('asc')}
                  >
                    A-Z
                  </DropdownItem>
                  <DropdownItem

                    onClick={() => setSorting('desc')}
                  >
                    Z-A
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
          </Nav>

          <Card>
            <CardBody>
              <Row>
                <Col lg="3" >
                  {isLoading==false &&
                  <Myprodfilter activeTab={tabs} handleChangeSelect={handleChangeSelect} filterValueState={filterValueState} resetFilterValue={resetFilterValue} searchOption={searchOption} />}
                </Col>
                <Col lg="9">

                  <Card className="bgback">
                    <CardHeader className="mb--4" style={{ backgroundColor: "#f8f9fe" }}>
                      <Row>
                        <Col lg="2">
                          <FormCheckBox
                            id="selectAll"
                            type="checkbox"
                            // label={selectAll ? 'Unselect All' : 'Select All'}
                            // label={item.name}
                            label={<h3>{selectAll ? 'Unselect All' : 'Select All'}</h3>}
                            checked={selectAll == 1}
                            label1={{ textAlign: "justify" }}
                            onClick={() => handleChangeCheck("", 1)}
                          //register={register}
                          />
                          {/* <h3>{selectAll ? 'Unselect All' : 'Select All'}</h3> */}
                        </Col>
                        <Col lg="10"><h3 style={{
                        textAlign:"center"
                      }}>Details</h3></Col>
                        {/* <Col lg="8" className="pad-less">
                          <Row>
                            <Col lg="2"><h3>SKU</h3></Col>
                            <Col lg="2"><h3>PRICE</h3></Col>
                            <Col lg="2"><h3>Status</h3></Col>
                            <Col lg="4"><h3>Action</h3></Col>
                            <Col lg="2"><h3>Public View</h3></Col>
                          </Row>
                        </Col> */}
                      </Row>
                    </CardHeader>
                    {data.length > 0 && data.map((item, index) => (
                      <CardBody className="pad-less  mt-4">
                        <div className="pt-4 pb-4 bgwhite">
                          <Row>
                            <Col lg='1' className='text-center'>
                              <FormCheckBox
                                id={item.cname}
                                type="checkbox"
                                // label={item.name}
                                checked={selectedCompanyNames.includes(item.cname)}
                                label1={{ textAlign: "justify" }}
                                onClick={() => handleChangeCheck(item.cname, 2)}
                              //register={register}
                              />
                            </Col>
                            <Col lg='8' className='text-center'>
                              <Button color="primary">{item.cname}</Button></Col>
                            <Col lg='3' className='text-right'>
                              {/* <Button onClick={() => opneAdd(index)} className="btn btn-secondary"> <i class="fa fa-cog mr-2" aria-hidden="true"></i>
                                Re Order Rank</Button> */}
                                </Col>
                          </Row>
                        </div>
                        {item.product.length > 0 && item.product.map((pro, index1) => (
                          <Row className="bgwhite" style={{ padding: "1rem", borderTop: "1px solid #ebecf1", height: "auto" }}>
                            <Col lg="1">
                            <Row>
                                {tabs == "1" ? <div style={{paddingTop:"1.5rem",width:"5rem"}}>
                                  <Col><p>{pro.sortorder}</p></Col>
                                  <Col><SortIcon onClick={()=>toggle(pro.sortorder,pro.productId,pro.channelId,item.product.length)} id="reOrder" cursor="pointer"/></Col> 
                                  <UncontrolledTooltip target={'reOrder'} placement='bottom'>
                                        Update Order
                                  </UncontrolledTooltip> 
                                  </div>:<></>}
                              </Row>
                            </Col> 
                            <Col lg="4" className="pad-less user-card">
                              <Row className="pt-4">
                                <Col lg='1' className='pt-5'>
                                  <FormCheckBox
                                    id={pro.productId}
                                    type="checkbox"
                                    // label={pro.product}
                                    checked={selectedProducts.includes(pro.productId)}
                                    label1={{ textAlign: "justify" }}
                                    onClick={() => handleChangeCheck(pro.productId, 3)}
                                  //register={register}
                                  />
                                </Col>
                                {/* <Col lg="2">
                                  <p>{index1+1}</p>
                                  <SortIcon onClick={()=>toggle(index1+1,pro.productId)} id="reOrder" cursor="pointer"/> 
                                  <UncontrolledTooltip target={'reOrder'} placement='bottom'>
                                    Update Order
                                  </UncontrolledTooltip> 
                                </Col> */}
                                <Col lg="4" className='p-0' style={{ textAlign: "-webkit-center" }}>
                                  <div className="ftl-img12">
                                    <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} alt="..." src={pro?.documentUrl ? pro?.documentUrl : logo} />
                                  </div>
                                </Col>
                                <Col lg="7" className="res-md">
                                  <h3 id={'Pro_name' + pro.productId}>{pro.product}</h3>
                                  <UncontrolledTooltip target={'Pro_name' + pro.productId} placement='bottom'>
                                    {pro.product}
                                  </UncontrolledTooltip>
                                  <p style={{ marginBottom: "0px" }}> Brand </p>
                                  <Badge className='outer_badge mr-2' style={{ height: "2rem", padding: "0.6rem", backgroundColor: "#2c9db7", color: "#fff", border: "1px solid #2c9db7" }}>
                                    {pro.brandName}
                                  </Badge>
                                  <div className="prod-des">
                                    <p id={'Pro_desc' + pro.productId} className="mt-2">{pro.usp.replace(/<[^>]+>/g, '')}</p>
                                    <UncontrolledTooltip target={'Pro_desc' + pro.productId} placement='right'>
                                      {pro.usp.replace(/<[^>]+>/g, '')}
                                    </UncontrolledTooltip></div>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg="7" className="pad-less">
                              <Row>
                                <Col lg="2" className="user-card pt4 pl-0 pr-0"><h3>{pro.productCategorie}</h3></Col>
                                <Col lg="2" className="user-card pt-4">
                                  <h3>Retail Price</h3>
                                  <Badge className='outer_badge' style={{ height: "2rem", padding: "0.6rem 0.3rem", border: "1px solid #2c9db7", color: "#2c9db7", minWidth: '85%' }}>
                                    {pro?.price_detail.split(',')[0] === '1' ? <i class="fas fa-euro-sign"></i> : <i class="fas fa-dollar-sign"></i>} {pro?.price_detail.split(',')[1]}
                                  </Badge>
                                </Col>
                                <Col lg="2" className="user-card pt4 pr-0">
                                  {/* <Badge className='outer_badge res-spa'> */}
                                  {/* {pro.isactive === '1' ? 'Active' : 'InActive'}
                                    <div className="tip"></div> */}
                                  <div>
                                    <FormRadio
                                      name="productType1"
                                      id="productType"
                                      className="text-sm mb-0"
                                      label=""
                                      options={businessTypeOptions}
                                      control={control}
                                      value={pro?.businessType ? pro?.businessType.toString() : '3'}
                                      //defaultValue={state.productType}
                                      row
                                      onChange={setBusinessType(pro.productId, pro.companyName)}
                                    />
                                  </div>

                                  {/* </Badge> */}
                                </Col>
                                <Col lg="4" className="user-card pt4 pr-0">
                                  <div>
                                    <span href="#" id={'copy' + index} onClick={() => productEdit(pro.productSKU, 2, pro.channelId)}><i class="far fa-copy" style={{ color: "#525f7f", cursor: 'pointer' }}></i></span>
                                    <span href="#" id={'Edit' + index} onClick={() => productEdit(pro.productSKU, 1, pro.channelId)}><i class="fas fa-pencil-alt ml-3" style={{ color: "#525f7f", cursor: 'pointer' }}></i></span>
                                    <span href="#" id={'View' + index} onClick={() => window.open('/admin/productDetail/' + pro.productSKU + '/' + pro.channelId, '_blank')}><i class="far fa-eye ml-3" style={{ color: "#525f7f", cursor: 'pointer' }}></i></span>
                                    <span href="#" id={'Accessiblity' + index} onClick={() => openPublicFlag(pro)}><i class="fa fa-universal-access ml-3" style={{ color: "#525f7f", cursor: 'pointer' }}></i></span>
                                    <span href="#" id={'Delete' + index} onClick={() => deletePopup(pro.productSKU)}><i class="far fa-trash-alt ml-3" style={{ color: "#525f7f", cursor: 'pointer' }}></i></span>
                                    <UncontrolledTooltip target={'copy' + index} placement='bottom'>
                                      Copy
                                    </UncontrolledTooltip>
                                    <UncontrolledTooltip target={'Edit' + index} placement='bottom'>
                                      Edit
                                    </UncontrolledTooltip>
                                    <UncontrolledTooltip target={'View' + index} placement='bottom'>
                                      View
                                    </UncontrolledTooltip>
                                    <UncontrolledTooltip target={'Accessiblity' + index} placement='bottom'>
                                      Accessiblity
                                    </UncontrolledTooltip>
                                    <UncontrolledTooltip target={'Delete' + index} placement='bottom'>
                                      Delete
                                    </UncontrolledTooltip>
                                  </div>

                                  <div>
                                    <p className='mb-1 pt-4'><span style={{ fontSize: '0.8rem' }}>created at- {pro.createdat ? Moment(pro.createdat).format("DD-MM-YYYY") : '-'}</span></p>
                                    <p><span style={{ fontSize: '0.8rem' }}>updated at - {pro.updatedat ? Moment(pro.updatedat).format("DD-MM-YYYY") : '-'}</span></p>

                                    {pro.isApproved == 0 && pro.approvalType &&
                                      <>

                                        <button id={'Approve' + index} onClick={() => { openModalPopUp(pro.productId, pro.approvalType, pro.channelId, pro.product,pro.productSKU) }} className="btn btn-icon btn-success btn-sm" variant="contained" color="success" >
                                          <span className="btn-inner--icon"><i className="ni ni-check-bold"></i></span>
                                          <UncontrolledTooltip target={'Approve' + index} placement='right'>
                                            Approve Product
                                          </UncontrolledTooltip>
                                        </button>


                                      </>}
                                  </div>
                                </Col>
                                <Col lg="2" className="user-card pt4 pr-0">
                                  <div>
                                    <label className="custom-toggle custom-toggle-success">
                                      <input type="checkbox" checked={+pro.isPublic} name="status" onClick={() => setPublicView(pro.productId, !(+pro.isPublic), pro.companyName)} />
                                      <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                                    </label>
                                    {/* <span href="#" onClick={() => moveUp(pro.productSKU, index)}><i class="fas fa-angle-up" style={{ color: "#525f7f", cursor: 'pointer' }}></i></span>
                                    <span href="#" onClick={() => moveDown(pro.productSKU, index)}><i class="fas fa-angle-down" style={{ color: "#525f7f", cursor: 'pointer' }}></i></span> */}

                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ))}

                      </CardBody>
                    ))}

                  </Card>

                  <TablePagination
                    component="div"
                    count={total}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 20, 30, 40]}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />

                </Col>
              </Row>
            </CardBody>

          </Card>
        </Container>
      </div>
      <div>
      <Modal isOpen={reordermodal} toggle={()=>toggle("","","","")}>
        <ModalHeader>
          <p><b>Update Order</b></p>
        </ModalHeader>
        <ModalBody>
          <p>Current Order - {currentOrder}</p>
          <Box
      sx={{
        width: 500,
        maxWidth: '100%',
      }}
    >
      <h3>Update New Order</h3>
      <TextField fullWidth label="New Sort Order" id="fullWidth" type="number" value = {newOrder} onChange={handleChangeOrder}/>
    </Box>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={finalReOrdering}>
            Submit Order
          </Button>
        </ModalFooter>
      </Modal>
    </div>
    </>
  );
}

export default Myproductlist;

const businessTypeOptions = [
  {
    value: "1",
    label: "B2C",
  },
  {
    value: "2",
    label: "B2B",
  },
  {
    value: "3",
    label: "Both",
  }
];