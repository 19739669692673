import React from 'react';
import axios from 'axios';
import Logo from 'views/pages/LoginPage/login-logo.js';
// reactstrap components
export const Pending = () => {
    


  return (
    <div className="main-content">
      <Logo/>
    <div className="header signup-header-bg py-4 py-lg-6 pt-lg-7">
      <div className="container">
        <div className="header-body text-center mb-7">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 className="text-white">Create Your Account</h1>
              <p className="text-lead text-white">
              You are only few minutes away of joining the largest community of consumer tech professionals in the World
              </p>
          
            </div>
          </div>
        </div>
      </div>
      <div className="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon className="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>

    <div className="container mt--8 pb-5">
    <div className="row justify-content-center">
    <div className="col-lg-10 col-md-12">
    <div className="card bg-secondary border-0 mb-0">  
  
    <div className="card-body px-lg-5 py-lg-5">     

    <div className="header-body text-center mb-7">
          <div className="row justify-content-center">
            <div className="col-xl-12 col-lg-10 col-md-12 px-5">
            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={require("assets/img/brand/wait.png")}/> 
              <h1 className="text-black text-lead text-marg">Thank you for your application.</h1>
              <h1 className="text-black text-lead">Our team will review your profile and come back to you shortly.</h1>
            </div>
          </div>
        </div>




                 </div>
             
              </div>
              </div>
              </div>
              </div>
              </div> 
              
  );
}
export default Pending;