import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Card, Container, Row, CardHeader,UncontrolledTooltip,  ListGroupItem,
  ListGroup,Col,Badge, Button} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Filter from "./filter";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { FormInput } from '_components/FormElements/FormInput';

import {Spinner} from '../../Hoc/Spinner'
import TablePagination from '@material-ui/core/TablePagination';
import {TablePaginationActions} from 'views/Hoc/PaginationActions';
import { commonService } from '_services/common.service';
import { useForm} from "react-hook-form";
import {ToastsContainer, ToastsStore} from 'react-toasts';
import Dialog from '../../Hoc/Dialog/GlobalDialog';
import Drawer from '../../Hoc/Dialog/Globaldrawer';
 const toggleSidenav = e => {
   console.log(e)

  if (document.getElementById('filter').classList.contains("g-sidenav-pinned")) {
    document.getElementById('filter').classList.remove("g-sidenav-pinned");
    document.getElementById('filter').classList.add("g-sidenav-hidden");
  } else {
    document.getElementById('filter').classList.add("g-sidenav-pinned");
    document.getElementById('filter').classList.remove("g-sidenav-hidden");
  }
  // function handleOrangeClick({
  //  sidenavOpen: setOpen(!sidenavOpen)
  // });
};
export const SupportList = (props) => {
  const { control, register } = useForm({ mode:'onBlur' });
  const [sidenavOpen,setOpen] = useState(false);
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [loading, setLoading]  = useState(true);
  let [total, setTotal]  = useState(0);
  let [data, setData]  = useState([]);
  let [search, setSearch] = useState(null);
  let [isModalPopup, setModalPopup]  = useState(false);
  const [filterValueState, setFilterValue] = useState({Company_type:null, status:null, complaints_Type:null});
  const [filterValueStateOption, setFilterValueOption] = useState({Company_typeList:[], statusList:[], complaints_TypeList:[]});
  let [modalPopValue, setModalPopValue]  = useState({message:null, title:null, value:null, popUpId:null });
  const [showDrawer,setshowDrawer]=useState(false);
  const [drawerInput, setDrawerInput] = useState(null);
  useEffect(() => {
      getList();
    }, []);
    const callList=()=>{
      page=0;
      setPage(page);
      getList();
    }
    const getList= async()=>{
      setLoading(true);
      // var filter = await getFilterData();
      let data = {
        search: search,
        filter : {
          complaintcategoryid: filterValueState.complaints_Type?parseInt(filterValueState.complaints_Type.id):null,
          channeltype: filterValueState.Company_type?filterValueState.Company_type.name:null,
          status:filterValueState.status?filterValueState.status.name:null
        },
        complainttype: "ContactType",
        offset:page * rowsPerPage,
        limit:rowsPerPage
      }
  let res=await commonService.postService(data,'master').catch(err => setLoading(false));
  if(res){
  data=res.data.results;
  setData(data);
  total=parseInt(res.data.pagination.total);
  setTotal(total);
  setFilterOptions(res.data.aggregations);
  }
  setLoading(false);
    }
    const setFilterOptions=(data)=>{
      let filterCompanyType = [];
      data.channeltype.map(x=>{
        filterCompanyType.push({name:x});
      })
      let filterStatus = [];
      data.status.map(x=>{
        filterStatus.push({name:x});
      })
      filterValueStateOption.Company_typeList=filterCompanyType;
      filterValueStateOption.statusList=filterStatus;
      filterValueStateOption.complaints_TypeList=data.category;
      setFilterValueOption({ ...filterValueStateOption });
      console.log('filterValueStateOption',filterValueStateOption);
    }
    const handleChangeSelect = (input, value)=>{
      console.log(input, value);
      filterValueState[input]= value;
      setFilterValue({ ...filterValueState });
      console.log('filterValueState',filterValueState)
      callList();
    }
    const handleChange=(e)=>{
      console.log(e.target.value);
      search=e.target.value;
      setSearch(search);
      getList();
    }
    const handleChangePage = (event, newPage) => {
      page=newPage;
      setPage(page);
      getList();
    };
  
    const handleChangeRowsPerPage = (event) => {
      rowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(rowsPerPage);
      setPage(0);
      getList();
    };

  const resetFilterValue =()=>{
    filterValueState.Company_type=null;
    filterValueState.status=null;
    filterValueState.complaints_Type=null;
    setFilterValue({ ...filterValueState });
    callList();
  }
  const deleteComplaint= async(value)=>{
    closePopup();
    let data={
      action: "closed"
    }
    setLoading(true);
    let res=await commonService.postService(data,'master/action/'+value.value.data).catch(err => setLoading(false));
    if(res){
      ToastsStore.success('Delete Success!...');
      callList();
    }else{
      ToastsStore.warning('Something went wrong!...');
    }
    setLoading(false);
  }
  const openModalPopUp = (data) => {
    modalPopValue.message= 'Do you want to Closed a general?';
    modalPopValue.title= "General";
    modalPopValue.value= { data };
  modalPopValue.popUpId= 1;
  setModalPopValue({...modalPopValue})
  setModalPopup(true);
};
const closePopup = () => {
  setModalPopup(false);
}
const closeDrawer =()=>{
  setshowDrawer(false);
}
const openComplaint = (data) => {
  setDrawerInput(data);
  setshowDrawer(true);
  console.log('datauser', data);
}
const setloading=(load)=>{
  setLoading(load);
}
const reCallView=()=>{
  closeDrawer();
  callList();
}
  return (
    <div id="channelList">
     <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
     <Dialog open={isModalPopup} value={modalPopValue} handleSave={deleteComplaint} handleClose={closePopup}></Dialog>
     <Drawer title="View" visible={showDrawer} ComplaintData={drawerInput} closeDrawer={closeDrawer} setloading={setloading} reCallView={reCallView} />
      {/* <div className={classes.root}> */}
      <Filter
        Filter={true}
        filterOption={filterValueStateOption}
        filterValue={filterValueState}
        resetFilterValue={resetFilterValue}
        handleChangeSelect={handleChangeSelect}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen} />
      
      <SimpleHeader name="General Contacts" parentName="Dashboard" />
        <Container className="mt--3" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">General Contact Reporting</h3>
                  {/* <p className="text-sm mb-0">
                    This is an exmaple of data table using the well known
                    react-bootstrap-table2 plugin. This is a minimal setup in
                    order to get started fast.
                  </p> */}
                </CardHeader>
        {loading? <Spinner/>: <ToolkitProvider
                ClassName="align-items-center table-flush" responsive
         
                  keyField="id"
                  columns={[
                    {
                      dataField: "id",
                      text: "ID",
                      sort: true,
                      formatter: (rowContent, row,rowIndex) => {
                        return(
                          <>
                <p>{(rowIndex+1) + (page * rowsPerPage)}</p>
                          </>
                        )
                      }
                    },
                    {
                      dataField: "contact",
                      text: "Contact Details",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return(
                          <>
                            <h3 className="mb-3">{ row.firstname } { row.firstname} </h3>   
                            {row?.channel && <p> {row?.channel?.companyName} </p>}
                            <p className="text-muted mb-0">{row.country.country} </p>     
                            <p className="text-muted mb-0"> {row.phoneno}</p>     
                            <p className="text-muted mb-2">{row.email} </p>     
                            <h3 className="text-muted d-inline mr-4 text-uppercase">Channel Type</h3>  
                                 <Badge className='outer_badge' style={{width:"30%",height:"2rem",padding:"0.6rem",border:"1px solid #46b88f",color:"#46b88f"}}>                                
                                  {row.channeltype}
                              </Badge>
                              {/* <Badge className='outer_badge' style={{width:"30%",height:"2rem",padding:"0.6rem",border:"1px solid #46b88f",color:"#46b88f"}}>                                
                              
                              distributor
                                </Badge>
                                <Badge className='outer_badge' style={{width:"30%",height:"2rem",padding:"0.6rem",border:"1px solid #46b88f",color:"#46b88f"}}>                                
                              
                              guest
                                </Badge> */}
                          </>

                        )
                      }
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      formatter: (rowContent, row) => {
                        return(
                          <>
                          
                                 <Badge className='outer_badge' style={{width:"fit-content",height:"2rem",padding:"0.6rem",border:row.status==='closed'?"1px solid tomato":"1px solid #2c9db7",color:row.status==='closed'?"warning":"#2c9db7"}}>
                                  {row.status}
                              </Badge>
                              {/* <Badge className='outer_badge' style={{width:"40%",height:"2rem",padding:"0.6rem",border:"1px solid #2c9db7",color:"#2c9db7"}}>                                
                              
                              pending
                                </Badge> */}
                          </>

                        )
                      }
                    },
                    {
                      dataField: "Type",
                      text: "Type",
                      formatter: (rowContent, row) => {
                        return(
                          <>
                          {row.complaintcategory.complaintcategory}
                          </>

                        )
                      }
                    },
                    {
                      dataField: "Subject",
                      text: "Subject",
                      formatter: (rowContent, row) => {
                        return(
                          <>
                          {row.subject}
                          </>

                        )
                      }
                    },
                    {
                      dataField: "action",
                      text: "Action",
                      sort: true,
                      formatter: (rowContent, row, index) => {
                        return(<>
                          {row.status!=='closed' && <>
                              <i id={'Edit' + index} onClick={()=>openComplaint(row)} style={{padding:"10px 10px 0 10px"}} class="fas fa-eye pointer">
                              <UncontrolledTooltip target={'Edit' + index} placement='bottom'> View </UncontrolledTooltip>
                              </i>
                              <i id={'Del' + index} onClick={()=>openModalPopUp(row.complaintid)}  style={{padding:"10px 10px 0 10px"}} class="fas fa-trash-alt pointer">
                              <UncontrolledTooltip target={'Del' + index} placement='bottom'> Delete </UncontrolledTooltip>
                              </i>
                          </>}</>
                        )
                      }
                    }
                  ]}
                >
                  {props => (
                    <div className="py-2 table-responsive">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 mb-2"
                      >
                <div style={{ width:'40%'}}>
                          {/* <SelectAutocomplete
                            id="search"
                            type="text"                
                            label="search"
                            name="search"
                            options={searchState.dropdownList}
                            placeholder="search..."
                            disableCloseOnSelect
                            // defaultValue={searchState.search?searchState.search[0]:null}
                           control={control}                         
                            input='search'
                            value={searchState.search?searchState.search[0]:null}
                            handleChangeSelect={handleChangeSelect}
                        />  */}
                  <FormInput
                  id='search'
                  label="search" 
                  name="search"
                  className="prodrev"
                  value={search}
                  onChange={(e)=>handleChange(e)}
                  register={register}
                  />
                        </div>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        data={ data }
                        // pagination={pagination}
                        bordered={false}
                        hover ={true}
                        
                     //   rowClasses= 'pointer' 
                      />

                  {total>10 && <TablePagination
                        component="div"
                        count={total}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />}
                    </div>
                  )}
                </ToolkitProvider>}
              </Card>
              </div>
              </Row>
              </Container>
              
    </div>
    
  );
};
export default SupportList;
