import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../_helpers';
import {Signup,Vendor} from 'views/pages/SignupPage';
import {Verification} from 'views/pages/SignupPage/Verification.js';
import  SignUpHeader  from 'views/pages/SignupPage/SignUpHeader';
import { alertActions } from '../_actions';
import {Login} from '../views/pages/LoginPage/login';
import AdminLayout from "../layouts/Admin.js";
import { setAxiosDefauls } from '../_setaxiosconfig/interceptor';
import profile from '../views/pages/profile/profile';
import {Pending} from 'views/pages/SignupPage/Pending.js';
import Channels from 'views/pages/Old-Channel-Listing/ChannelCreation';
import Brand from 'views/pages/brand/brand';
import {ForgotPassword} from 'views/pages/SignupPage/ForgotPassword.js';

setAxiosDefauls();
class App extends React.Component {
    constructor(props) {
        super(props);

        history.listen((location, action) => {
            // clear alert on location change
            this.props.clearAlerts();
        });
    }

    render() {
        const { alert } = this.props;
        
        return (
            <div className="main-content">            
            {alert.message &&            
                <div className={`alert ${alert.type}`} >{alert.message}</div>
            }  
                        <Router history={history}>
                            <Switch>                                
                                <Route exact path="/" component={Login} />
                                <Route exact path="/vendor" component={Vendor} />
                                <Route exact path="/profile" component={profile} />
                                <Route exact path="/Verification" component={Verification} />
                                <Route exact path="/Pending" component={Pending} />
                               
                                <Route path="/admin" render={props => <AdminLayout {...props} />} />
                                <Redirect from="*" to="/" />
                            </Switch>
                        </Router>

                        </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };