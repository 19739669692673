import { Height } from '@material-ui/icons';
import React, { useState } from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";
import { Badge } from "reactstrap";
import {UncontrolledTooltip} from "reactstrap";
export const Categories = (props)=>{
  const { data, title, id } = props;
  const [showMore, setMore] = useState(false);
  const more_click=()=>{
    if(!showMore) setMore(true);
    else setMore(false);
    }
  if(data.length>0){
    let _remain = [];
    let _elements = data.slice(0,6); // clone array  
    console.log("retailpro:",data);   

    _remain = data.slice(6,data.length);
    const text=(data,rem)=>{
      return(<>
          {data.length>0 && data.map((x,i) => (
              <>
              
              <Badge className="mt-3 mr-1 detailbadge"id={'text'+id+i+rem}>{x}</Badge>
              <UncontrolledTooltip target={'text'+id+i+rem} placement='bottom'>{x}</UncontrolledTooltip></>
          ))}
          </>
      )
  }
  return(
    <Card>
    <CardBody style={{minHeight:''}}>
      <h3>{title}</h3>
      {text(_elements,'')}
        {showMore && <>{text(_remain,'rem')}</> }
        {data.length>6 && <h3 onClick={more_click} className='float-right text-primary' style={{cursor:'pointer',position: 'absolute'  ,  right: '20px'}}>{!showMore ?  'Show More':'Show Less'}</h3>}
    </CardBody>
</Card>
  )
  }
}

  export const Profile = (props)=>{
    const { data, title, id } = props;

    if(data.length>0){
      const text=(data)=>{
        return(<>
            {data.length>0 && data.map((x,i) => (
                <>
                <Badge className="mt-2 mr-1 detailbadge"id={'text'+id+i}>{x}</Badge>
                <UncontrolledTooltip target={'text'+id+i} placement='bottom'>{x}</UncontrolledTooltip></>
            ))}
            </>
        )
    }
    return(
<>
        <h3 className='mb-0'>{title}</h3>
        {text(data)}
    </>
    )
    }
  }

  export const ArrayToString = (props)=>{
    const { data, title, id } = props;
    console.log('data',data);
    const [showMore, setMore] = useState(false);
    const more_click=()=>{
      if(!showMore) setMore(true);
      else setMore(false);
      }
    if(data.length>0){
      // let _remain = [];
      let _elements = data.slice(0,13); // clone array     
      let _elementString = _elements.toString();
      // _remain = data.slice(6,data.length);
      const _remainString = data.toString();
    return(
      <div classname="overdot">
      <p className="mt-2 mb-0">{title}</p>
      {!showMore && <h4 style={{color:'#747475'}}>
      { _elementString }
      {data.length>13 && <strong><p className="text-primary d-inline pointer float-right"    style={{color:'blue',cursor:"pointer"}} onClick={more_click} >+{data.length - _elements.length} Show More</p></strong>}
      </h4>}
      {showMore && 
              <h4 style={{color:'#747475'}}>
       { _remainString }
       <strong><p className="text-primary d-inline pointer float-right"    style={{color:'blue',cursor:"pointer"}} onClick={more_click} >Show Less</p></strong>
         </h4>}
      </div>
    )
    }
  }

  export const ExpandString = (props)=>{
    const { data, title, id } = props;
    console.log('data',data);
    const [showMore, setMore] = useState(false);
    const more_click=()=>{
      if(!showMore) setMore(true);
      else setMore(false);
      }
    if(data.length>0){
      // let _remain = [];
      let res =data.replace(/<[^>]+>/g, '');
      let _elements = res.slice(0,300); // clone array
      // _remain = data.slice(6,data.length);
      const _remainString = data;
    return(
      <div classname="overdot" id='cmp-bac'>
      {/* <p className="mt-2 mb-0">{title}</p> */}
      {!showMore && <p style={{color:'#747475'}}>
      <div style={{fontWeight:'400 !important'}} dangerouslySetInnerHTML={{ __html: _elements }} />
      {data.length>300 && <strong><p className="text-primary d-inline pointer float-right" style={{color:'#2c9db7',cursor:"pointer"}} onClick={more_click} > Show More</p></strong>}
      </p>}
      {showMore && 
      <p style={{color:'#747475'}}>
      <p dangerouslySetInnerHTML={{ __html: _remainString }} />
       <strong><p className="text-primary d-inline pointer float-right" style={{color:'#2c9db7',cursor:"pointer"}} onClick={more_click} >Show Less</p></strong>
         </p>}
      </div>
    )
    }
  }