import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";

const ModalImage = (props) => {
  const [image, setImage] = useState(props.src);
  const [cropData, setCropData] = useState("#");
  const [isOpen, setIsOpen] = useState(props.isOpen);

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  useEffect(() => {
    if (cropData && cropData != "#") {
      console.log("cropped", cropData);
      props.handleChange(cropData);
    }
  }, [cropData]);

  const getCropData = () => {
    if (image) {
      setCropData(image);
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <div>
        <div style={{ margin: "3rem 3rem 3rem 3rem" }}>
          <img src={image} style={{ maxWidth: "100%" }} />
        </div>
        <div className="text-center mt-4 mb-4">
          <button
            className=" btn btn-primary btn-md ml-2"
            onClick={getCropData}
          >
            Upload Image
          </button>

          <button
            className=" btn btn-warning btn-md ml-2"
            onClick={props.handleCropClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalImage ;
