import React,{useState,useEffect} from "react";
import { Link} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import {loginschema} from './validation'; 
import {InputAdornment }  from '@material-ui/core';
import {Email,Lock} from '@material-ui/icons';
import { userActions } from '_actions/user.actions';
import { LocalStorageService } from '_services';
import { connect } from 'react-redux';
import { Spinner } from '../../Hoc/Spinner'
import {FormInput,FormButton, FormCheckBox} from "_components/FormElements/FormInput";
import {UncontrolledAlert} from "reactstrap";
//import { Multiselect } from 'multiselect-react-dropdown';
//import Select from "react-select";
import logo from 'assets/img/brand/ch-logo.svg'

import axios from 'axios';

const Login = props => {  

  
  const [apptoken, setApptoken] = useState("");
  const [invalidAuth, setInvalidAuth] = useState(false)
  const [loading, setLoading] = useState(false)
 // const { loggingIn } = props;      
  
  const { register, handleSubmit, control, errors } = useForm({
//    mode:'onBlur',
    resolver: yupResolver(loginschema)
  });
  const onSubmit = data => {    
     if (data) {
         setLoading(true)
         props.login(data.email,data.password,apptoken,successCallback,errorCallback);
       //console.log("data:",data);
     }
  }

  const errorCallback = error => {
    setLoading(false)
    setInvalidAuth(true)
  };

  const successCallback = success => {
    setLoading(false)
  };
  
  
    useEffect( () => {
        //console.log("props:",props);
        const localStorageService = LocalStorageService.getService();
        axios.post('/app', { 
            clientKey:"clientAppFE",      
            secretKey:"!$Ch@nnelHub#*&"         
            }).then(res =>{
              localStorageService.setAppToken(res.data.Authorization);
                setApptoken(res.data.Authorization);
            });
    }, []);  
  
  return (
    <div class="main-content">
      <div className="head-logo mt-2 mb-2">
        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={logo}/>
        </div>
    <div class="header signup-header-bg loginpad">
      <div class="container">
        <div class="header-body text-center mb-4">
          <div class="row justify-content-center">
            <div class="col-xl-10 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Welcome!</h1>
              <p class="text-lead text-white">ChannelHub is the game changer for consumer tech channels. We help retailers and distributors find new products and qualified vendors from around the globe</p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>

    <div class="container loginmargin  pb-5">
    <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
    <div class="card bg-secondary border-0 mb-0">  
  
    <div class="card-body px-lg-5 py-lg-5">   
    <form onSubmit={handleSubmit(onSubmit)}>
                <div class="form-group mb-3" >
                  <div class="input-group input-group-merge input-group-alternative">                    
                    <FormInput 
        id="email"
        type="email"
        name="email"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Email />
            </InputAdornment>
          ),
        }}
        placeholder="Email Id"      
        register={register}
        error={errors.email}
        />
                  </div>
                </div>
                <div class="form-group">
                  <div class="input-group input-group-merge input-group-alternative">                   
                    <FormInput 
      id="password"
      type="password"
      name="password"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Lock />
          </InputAdornment>
        ),
      }}
      placeholder="Password"
      register={register}
      error={errors.password}
      />
                  </div>
                </div>
               
                    {/* <span className="alert-icon">
                      <i className="ni ni-like-2" />
                    </span>
                    <span className="alert-text ml-1">
                      <strong>Warning!</strong> This is a warning alert—check it
                      out!
                    </span> */}
                    {invalidAuth && <p style={{ color: "red", textAlign: "center", fontSize: '.9rem', fontWeight: 600, marginTop: '-1rem' }}>!Please check your email and password</p>}
              

                {/* <FormCheckBox 
                id="customCheckLogin"
                type="checkbox"
                label="Remember me"
                register={register}
                /> */}
                {/* <a className="forgot" href="dev-fo.channelhub.tech">Forgot Password</a> */}
                {/* <Row className=""> */}
                {loading ? <Spinner />:''}
                  <FormButton
                  className="btn-sin btn btn-primary"
      id="loginbutton"
      name="loginbutton"
      value="Sign in" 
      />
                

              </form>   
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>
              
  );
}

function mapState(state) {    
    const { loggingIn } =state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login
};

const connectedLoginPage = connect(mapState, actionCreators)(Login);
export { connectedLoginPage as Login };
