import React, { useState, useEffect } from 'react';
import { FormInput } from "_components/FormElements/FormInput";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPwdschema } from '../../pages/SignupPage/Validation';
import { commonService } from '_services/common.service';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Logo from 'views/pages/LoginPage/login-logo.js';
import { CardBody, Container } from "reactstrap";
import { Card } from 'antd';
import axios from 'axios';
import { Spinner } from "views/Hoc/Spinner";
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { history } from '../../../_helpers/history';
import { SelectAutocomplete, ButtonGroup } from '_components/FormElements/FormInput';
import { Dialog, ConfirmDialog } from "views/Hoc/Dialog/GlobalDialog";
import {
     Row, CardHeader, UncontrolledTooltip, ListGroupItem,
    ListGroup, Col, Badge, Button, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
  } from "reactstrap";
import { id } from 'date-fns/locale';
export const HubspotDuplicate = (props) => {
  let [formValue, setFormValue] = useState({ password: null, rePassword: null });
  let adminUser = JSON.parse(localStorage.getItem('CHuser'));
  const { control } = useForm({ mode: 'onBlur' });
  let [isLoading, setLoading] = useState(true);
  let [responseText, setResponseText] = useState(true);
  let [exp_err, setExp_error] = useState(false);
  const [searchState, setSearch] = useState({ mode: 2, bestSearch: null, dropdownList: [] , matchingDropdownList:[]});
  const [filterValueState, setFilterValue] = useState({ activeStatus :  [{id:"1",name:"Active"},{id:'0',name:"InActive"},{id:'2',name:"Both"}] ,status: 2,isOEM: 0, businessType :  [{id:"1",name:"B2B"},{id:'2',name:"B2C"},{id:'3',name:"Both"}] });
  // let [matchErr, setMatchErr] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);
  
  const [contactLst, setContactLst] = useState('none');
  const [contactData, setContactData] = useState([]);
  
  const [activePop, setActivePop] = useState(false);
  const { companyData, saved } = props;
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });
  let [isModalPopup, setModalPopup] = useState(false);

  //const handleClickShowPassword1 = () => { setShowPassword1(!showPassword1); }
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(resetPwdschema),
  });
  useEffect(() => {  console.log('++++++++++++++++++++', props);
    setLoading(false);
    searchState.search = null
    searchState.bestSearch = null
    setSearch({ ...searchState });
    setContactLst('none');
    setContactData([]);
    getPendingChannelsList();
    getMatchedChannelsList();
    
    document.getElementById('chsearch').style.display= 'none';
    document.getElementById('hsbestSearch').style.display= 'inline-block';
    
  }, [props])

 
  const handleChangeSelect = (input, value) => {
    setResponseText(null)
    console.log(input);
    if (input == 'search') {
      console.log(value);
      searchState.search = [value]
      searchState.mode = [1]
      setSearch({ ...searchState })
    } 
    else if (input == 'bestSearch') {
      searchState.bestSearch = [value]
      searchState.mode = [2]
      setSearch({ ...searchState })
    }
  }

  const setDuplicate = () => {
    let mode = searchState.mode?searchState.mode[0]:null;
    
    let channelId =  null
      if(mode === 2){
        channelId = searchState.bestSearch?searchState.bestSearch[0].id:null;
      } else {
       channelId = searchState.search?searchState.search[0].id:null;
      }
    //   let userHSId = companyData.cData.hubspotCompanyId
    //   if(companyId){
    //     axios.post('/hubspot/setDuplicate/' + companyId +'/'+ userHSId).then(res => {
    //       console.log(res);
    //     });
    //   }
  
    
      if(channelId){
        console.log('/hubspot/setDuplicate/')
        axios.post('/hubspot/setDuplicate/' + channelId , {'parentChannelId':companyData.cData.channelId}).then(res => {
          console.log(res);
          ToastsStore.success('Completed Sucessfully');
          searchContacts()
        }).catch(
          err => { ToastsStore.warning('Something went wrong!...'); console.log(err); }
        );
      }
      
    
  }
  
  const searchContacts = ()  =>  {
    
    let mode = searchState.mode?searchState.mode:null;
    console.log('mode ==== ' , mode[0]);
    
    let channelId = [];
     if(mode[0] === 2){
       channelId = searchState.bestSearch?searchState.bestSearch[0].id:null;
     } else{
       channelId = searchState.search?searchState.search[0].id:null;
     }
      
      axios.get('/hubspot/getCompanyContacts/' + channelId).then(res => {
        console.log(res);
        let contacts = [];
        if(res.data.length > 0){
          for(var i=0;i<res.data.length;i++) {
            contacts.push(res.data[i])  
          }  
          if(contacts.length == 0) {
            document.getElementById('primary').style.display= 'none';
            setContactData([])
            setContactLst('block');
            setResponseText('No Proper contact Found, Do you want to set the company as duplicate ')
          } else {
            document.getElementById('primary').style.display= 'inline-block';
            setContactLst('block');
            setContactData(contacts)
            setResponseText('Totally '+contacts.length+ ' contact wher found, Are you want to merge?')
          }
        } else {
          document.getElementById('primary').style.display= 'none';
          setContactLst('block');
          setContactData([])
          setResponseText('No Proper contact Found, Do you want to set the company as duplicate ')
        }
        // searchState.matchingDropdownList = hubspotData
        // setSearch({ matchingDropdownList: searchState.matchingDropdownList });
       });
    //}
  }
    const getMatchedChannelsList = () => {  
        

          axios.post('/hubspot/getMatchedCompany', { 'companyName': companyData.cData.companyName, 'channelId': companyData.cData.channelId }).then(res => {
            let hubspotData = [];
            hubspotData.push(...res.data)
            hubspotData = hubspotData.map(x => {
              return { name: x.companyName.trim(), id: x.channelId }
            });
            searchState.matchingDropdownList = hubspotData
            setSearch({ ...searchState, matchingDropdownList: searchState.matchingDropdownList });
          });

          
    }
  const getPendingChannelsList = () => {
    
    axios.get('/hubspot/channelsList/' + companyData.cData.channelId).then(res1 => {
      let data = [];
      data.push(...res1.data)
      data = data.map(x => {
        return { name: x.name.trim(), id: x.id }
      });

      searchState.dropdownList = data
      setSearch({...searchState, dropdownList: searchState.dropdownList });
    });
  }
  const setSearchToDisplay = (e) => {
    setResponseText(null);
    let mode = +e.target.value;  
    searchState.mode = [mode];
    if(mode == 2){ 
      document.getElementById('chsearch').style.display= 'none';
      document.getElementById('hsbestSearch').style.display= 'inline-block';
    } else {
      document.getElementById('chsearch').style.display= 'inline-block';
      document.getElementById('hsbestSearch').style.display= 'none';
    }
    
    setSearch({ ...searchState })
  }
  const closePopup = (e) => {

    setModalPopup(false);
    setActivePop(false);
  };
  const confirmSavePopup = (value) => {
    setActivePop(false);
    setDuplicate();
  }
  const confirmBeforeSave = () => {
    modalPopValue.message = "If you set this company as dupulicate then it will be deleted from CH Database, and all its contact are merged with parent company "+companyData.cData.companyName
    modalPopValue.title = 'Merge duplicate company with correct one';
    modalPopValue.value = 'Confirm';
    modalPopValue.popUpId = 2;
    setModalPopValue({...modalPopValue});
    setActivePop(true);
  }
  return (
    <div className="main-content">
      {isLoading && <Spinner />}
      <ConfirmDialog open={activePop} value={modalPopValue} handleSave={confirmSavePopup} handleClose={closePopup} />
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <Container fluid className="col-lg-12 justify-content-left">
        <Card>
          
            
              <div className="col-lg-12">

                <div className="col-lg-12">
                  <div className="form-group">
                  <strong>Choosen Company : </strong>  
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {companyData.cData.companyName}
                  </div>

                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                  <strong> Merge : </strong>  
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <input type="radio" id="chDatabase" name="mode" checked={searchState.mode == 1? 'checked':''} value="1" onClick={setSearchToDisplay}/>
                  <label for="chDatabase">CH Database</label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {/* <input type="radio" id="hubspot" name="mode" value="1" checked={searchState.mode == 1? 'checked':''} onClick={setSearchToDisplay}/>
                  <label for="hubspot">Hubspot</label> */}
                  <input type="radio" id="hubspot" name="mode" value="2" checked={searchState.mode == 2? 'checked':''} onClick={setSearchToDisplay}/>
                  <label for="hubspot">Best Search</label>

                  </div>

                </div>

                <div id="hsbestSearch" style={{display:"none"}} className="col-lg-12">
                    <SelectAutocomplete
                          id="bestSearch"
                          type="text"
                          label="bestSearch"
                          name="bestSearch"
                          options={searchState.matchingDropdownList}
                          placeholder="search..."
                        //  disableCloseOnSelect
                          defaultValue={searchState.bestSearch?searchState.bestSearch[0]:null}
                          control={control}
                          input='bestSearch'
                          value={searchState.bestSearch ? searchState.bestSearch[0] : null}
                          handleChangeSelect={handleChangeSelect}
                        /> 
                  
                </div>

                <div id="chsearch" style={{display:"none"}} className="col-lg-12">
                <SelectAutocomplete
                          id="search"
                          type="text"
                          label="Search"
                          name="search"
                          options={searchState.dropdownList}
                          placeholder="search..."
                         // disableCloseOnSelect
                          defaultValue={searchState.search?searchState.search[0]:null}
                          control={control}
                          input='search'
                          value={searchState.search?searchState.search[0]:null}
                          handleChangeSelect={handleChangeSelect}
                        />
                </div>
                
               

                <div ClassName="align-items-center" style={{display:contactLst, width:"100%"}}>
                  
                  <div style={{display:'inline-block', width:"40%" , padding: "16px 16px 16px 0", fontWeight: "bold"}}>User Name</div>
                  <div style={{display:'inline-block', width:"30%" , padding: "16px 16px 16px 0", fontWeight: "bold"}}>Email</div>
                  <div style={{display:'inline-block', width:"30%" , padding: "16px 16px 16px 0", fontWeight: "bold"}}>User Role</div>
                  
                  { contactData.map((data, index) => (
                    <Row>
                    
                  <div style={{display:'inline-block', width:"40%", padding: "0 16px 16px 0"}}>{ data.firstName } { data.lastName }</div>
                  <div style={{display:'inline-block', width:"30%", padding: "0 16px 16px 0"}}>{ data.email }</div>
                  <div style={{display:'inline-block', width:"30%", padding: "0 16px 16px 0"}} ClassName="align-items-center">{ (+data.user.role.roleId == 3)?'Primary':'Admin' }</div>
                    </Row>
                  ))} 

                  <div>
                    {responseText}
                  </div>

                  </div>
                 
                
                <div className="text-center mt-4">
                  <button type="button" className="btn btn-warning" onClick={searchContacts}>Search</button>
                {/* </div>
                <div className="text-center mt-4"> */}
                  <button type="button" id="primary" className="btn btn-primary" style={{display:"none"}} onClick={confirmBeforeSave}>Merge</button>
                </div>
              </div>
              <div>
                
              </div>
            
          
        </Card>
      </Container>
    </div>
  );
}
export default HubspotDuplicate;