import React, { useState } from 'react';

const IncrementDecrementBtn = ({ counters,hotelRoomDetailOnChange, name, value}) => {
  const handleIncrementCounter = (index) => {
    setCounterValues(prevCounters => {
      const newCounters = [...prevCounters];
      if (newCounters[index].count < newCounters[index].maxValue) {
        newCounters[index].count++;
      }

      if(hotelRoomDetailOnChange){
        const counterObj = {
          name:name,
          counter:newCounters[0].count
        }
        hotelRoomDetailOnChange('eisnull', counterObj)
      }
     
      return newCounters;
    });
  };

  const handleDecrementCounter = (index) => {
    setCounterValues(prevCounters => {
      const newCounters = [...prevCounters];
      if (newCounters[index].count > newCounters[index].minValue) {
        newCounters[index].count--;
      }

      if(hotelRoomDetailOnChange){
        const counterObj = {
          name:name,
          counter:newCounters[0].count
        }
        hotelRoomDetailOnChange('eisnull', counterObj)
      }
      return newCounters;
    });
  };

  const [counterValues, setCounterValues] = useState(counters.map(counter => ({ ...counter, count: counter.minValue })));

  return (
    <div style={{float:'right'}}>
      {counterValues.map((counter, index) => (
        <div key={index} className="btn-group">
             <button className="decrement-btn" onClick={() => handleDecrementCounter(index)}>
            <span className="material-symbols-outlined">-</span>
          </button>
          <p>{value != (null && undefined) ? value : counter.count}</p>
          <button className="increment-btn" onClick={() => handleIncrementCounter(index)}>
            <span className="material-symbols-outlined">+</span>
          </button>
        </div>
      ))}
    </div>
  );
};

export default IncrementDecrementBtn;