const roomData = [
    { "value": "Double Room", "label": "Double Room" },
    { "value": "Double Room with Private Bathroom", "label": "Double Room with Private Bathroom" },
    { "value": "Budget Double Room", "label": "Budget Double Room" },
    { "value": "Business Double Room with Gym Access", "label": "Business Double Room with Gym Access" },
    { "value": "Deluxe Double Room", "label": "Deluxe Double Room" },
    { "value": "Deluxe Double Room (1 adult + 1 child)", "label": "Deluxe Double Room (1 adult + 1 child)" },
    { "value": "Deluxe Double Room (1 adult + 2 children)", "label": "Deluxe Double Room (1 adult + 2 children)" },
    { "value": "Deluxe Double Room (2 Adults + 1 Child)", "label": "Deluxe Double Room (2 Adults + 1 Child)" },
    { "value": "Deluxe Double Room with Balcony", "label": "Deluxe Double Room with Balcony" },
    { "value": "Deluxe Double Room with Balcony and Sea View", "label": "Deluxe Double Room with Balcony and Sea View" },
    { "value": "Deluxe Double Room with Bath", "label": "Deluxe Double Room with Bath" },
    { "value": "Deluxe Double Room with Castle View", "label": "Deluxe Double Room with Castle View" },
    { "value": "Deluxe Double Room with Extra Bed", "label": "Deluxe Double Room with Extra Bed" },
    { "value": "Deluxe Double Room with Sea View", "label": "Deluxe Double Room with Sea View" },
    { "value": "Deluxe Double Room with Shower", "label": "Deluxe Double Room with Shower" },
    { "value": "Deluxe Double Room with Side Sea View", "label": "Deluxe Double Room with Side Sea View" },
    { "value": "Deluxe Double or Twin Room", "label": "Deluxe Double or Twin Room" },
    { "value": "Deluxe King Room", "label": "Deluxe King Room" },
    { "value": "Deluxe Queen Room", "label": "Deluxe Queen Room" },
    { "value": "Deluxe Room", "label": "Deluxe Room" },
    { "value": "Deluxe Room (1 adult + 1 child)", "label": "Deluxe Room (1 adult + 1 child)" },
    { "value": "Deluxe Room (1 adult + 2 children)", "label": "Deluxe Room (1 adult + 2 children)" },
    { "value": "Deluxe Room (2 Adults + 1 Child)", "label": "Deluxe Room (2 Adults + 1 Child)" },
    { "value": "Double Room (1 Adult + 1 Child)", "label": "Double Room (1 Adult + 1 Child)" },
    { "value": "Double Room - Disability Access", "label": "Double Room - Disability Access" },
    { "value": "Double Room with Balcony", "label": "Double Room with Balcony" },
    { "value": "Double Room with Balcony (2 Adults + 1 Child)", "label": "Double Room with Balcony (2 Adults + 1 Child)" },
    { "value": "Double Room with Balcony (3 Adults)", "label": "Double Room with Balcony (3 Adults)" },
    { "value": "Double Room with Balcony and Sea View", "label": "Double Room with Balcony and Sea View" },
    { "value": "Double Room with Extra Bed", "label": "Double Room with Extra Bed" },
    { "value": "Double Room with Garden View", "label": "Double Room with Garden View" },
    { "value": "Double Room with Lake View", "label": "Double Room with Lake View" },
    { "value": "Double Room with Mountain View", "label": "Double Room with Mountain View" },
    { "value": "Double Room with Patio", "label": "Double Room with Patio" },
    { "value": "Double Room with Pool View", "label": "Double Room with Pool View" },
    { "value": "Double Room with Sea View", "label": "Double Room with Sea View" },
    { "value": "Double Room with Shared Bathroom", "label": "Double Room with Shared Bathroom" },
    { "value": "Double Room with Shared Toilet", "label": "Double Room with Shared Toilet" },
    { "value": "Double Room with Spa Bath", "label": "Double Room with Spa Bath" },
    { "value": "Double Room with Terrace", "label": "Double Room with Terrace" },
    { "value": "Economy Double Room", "label": "Economy Double Room" },
    { "value": "King Room", "label": "King Room" },
    { "value": "King Room - Disability Access", "label": "King Room - Disability Access" },
    { "value": "King Room with Balcony", "label": "King Room with Balcony" },
    { "value": "King Room with Garden View", "label": "King Room with Garden View" },
    { "value": "King Room with Lake View", "label": "King Room with Lake View" },
    { "value": "King Room with Mountain View", "label": "King Room with Mountain View" },
    { "value": "King Room with Pool View", "label": "King Room with Pool View" },
    { "value": "King Room with Roll-In Shower - Disability Access", "label": "King Room with Roll-In Shower - Disability Access" },
    { "value": "King Room with Sea View", "label": "King Room with Sea View" },
    { "value": "King Room with Spa Bath", "label": "King Room with Spa Bath" },
    { "value": "Large Double Room", "label": "Large Double Room" },
    { "value": "Quarto Duplo com Casa de Banho Privada Exterior", "label": "Quarto Duplo com Casa de Banho Privada Exterior" },
    { "value": "Queen Room", "label": "Queen Room" },
    { "value": "Queen Room - Disability Access", "label": "Queen Room - Disability Access" },
    { "value": "Queen Room with Balcony", "label": "Queen Room with Balcony" },
    { "value": "Queen Room with Garden View", "label": "Queen Room with Garden View" },
    { "value": "Queen Room with Pool View", "label": "Queen Room with Pool View" },
    { "value": "Queen Room with Sea View", "label": "Queen Room with Sea View" },
    { "value": "Queen Room with Shared Bathroom", "label": "Queen Room with Shared Bathroom" },
    { "value": "Queen Room with Spa Bath", "label": "Queen Room with Spa Bath" },
    { "value": "Small Double Room", "label": "Small Double Room" },
    { "value": "Standard Double Room", "label": "Standard Double Room" },
    { "value": "Standard Double Room with Fan", "label": "Standard Double Room with Fan" },
    { "value": "Standard Double Room with Shared Bathroom", "label": "Standard Double Room with Shared Bathroom" },
    { "value": "Standard King Room", "label": "Standard King Room" },
    { "value": "Standard Queen Room", "label": "Standard Queen Room" },
    { "value": "Superior Double Room", "label": "Superior Double Room" },
    { "value": "Superior King Room", "label": "Superior King Room" },
    { "value": "Superior Queen Room", "label": "Superior Queen Room" }
  ]
  export default roomData;