import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { dashboard } from './dashboard.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { adduser } from './adduser.reducer';
import { distributionChannel } from './distributionChannel.reducer';

const rootReducer = combineReducers({
  authentication,
  dashboard,
//  registration,
  users,
  alert,
  adduser,
  distributionChannel
});

export default rootReducer;