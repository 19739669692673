import React, { useEffect, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
  Button, Modal, UncontrolledTooltip
} from "reactstrap";
import { ProfileUploader } from '../ProfileUpload';
import { commonService } from '_services/common.service';
import { Spinner } from 'views/Hoc/Spinner';
import { history } from '_helpers';
import { FormTextArea, FormInput, FormAutocomplete, SingleAutocompleteGrouping, FormRadio, FormCheckBox, FormAutocompleteGrouping } from "_components/FormElements/FormInput";
import { useForm } from "react-hook-form";
import {
  FormControl,
  InputAdornment,
} from "@material-ui/core";
import axios from "axios";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const EditEventUser = (props) => {
  const { opneadd, closeModalPopup, brandName } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isDelegateFull,setDelegate] = useState(false);
  const [additionalMembers,setAdditionalMembers] = useState(null);
  const [selectedPack,setSelectedPack] = useState(null);

  const { register, handleSubmit, control, errors, watch } = useForm({
    mode: "onBlur",
    // resolver: yupResolver(retailProfileSchema)
  });

  let defaultState = {
    channelId: props.data.channelId,
    eventId: props.data.eventId,
    cType: props.data.cType,
    pType: props.data.pType,
    participantOptions: [],
    participants: [],
    packageId: props.data.packageId,
    registeredBy: props.data.registeredBy,

    marketingOptions: props.data.marketingOptions,
    packageOptions: props.data.packageOptions,
    

    marketingOptionsSelected: [],
    packageOptionsSelected: [],
   
    package: props.data.packageOptions.find(x => x.id==props.data.packageId),//props.data.selectedPackage.,
    registeredUser: null

  };

  const [state, setState] = useState(defaultState);

  useEffect(() => {
    console.log("packoption:",state.packageOptions)
    getUsers();
  }, []);


  const getUsers = async () => {
    await axios.post('channelAdmin/getEventChannelDetails', { channelId: state.channelId, eventId: state.eventId, packageId: state.packageId }).then(res => {
      state.participantOptions = res.data.contacts;
      state.participants = res.data.participants;
      state.marketingOptions = res.data.marketingOptions;
      // state.packageOptions = res.data.packageOptions;

      let regUser = res.data.participants.filter(x => x.id == state.registeredBy);
      state.registeredUser = regUser.length ? regUser[0] : null;

      // if (state.marketingOptions.length && res.data.selectedMarketingOptions.length) {
      //   state.marketingOptions.map(x => {
      //     res.data.selectedMarketingOptions.map(y => {
      //       if (y == x.optionId) {
      //         state.marketingOptionsSelected.push(x);
      //       }
      //     })
      //   });
      // }

      // if (state.packageOptions.length && res.data.selectedPackageOptions) {
      //   state.packageOptions.map(x => {
      //     res.data.selectedPackageOptions.map(y => {
      //       if (y == x.optionId) {
      //         state.packageOptionsSelected.push(x);
      //       }
      //     })
      //   });
      // }


      // state.package = packageDropdownOptions1.filter(x => x.id == state.packageId)[0] : packageDropdownOptions.filter(x => x.id == state.packageId)[0];

      setState({ ...state });
    })
  };

  console.log('state', state);


  const save = async () => {

    if (state.package.length>0 && state.participants.length && state.registeredUser?.id) {
      let data = {
        contactId: state.participants.map(x => x.id),
        channelId: state.channelId,
        eventId: state.eventId,
        pType: state.pType,
        cType: state.cType,
        package:{eventId:state.eventId,channelId:state.channelId,list:state.package} 
      }

      if (state.registeredBy != state.registeredUser?.id) {
        data['registeredBy'] = state.registeredUser?.id;
      }

      let result = await axios.post('channelAdmin/boEventUsersManage', data).then(res => res).catch(err => ToastsStore.error('something went wrong...'))

      if (result)
        props.save('hllo');

      closeModalPopup();
    } else {
      ToastsStore.error('Please Select Atleast one participant and packageId and Main Delegate');
    }



  }

  const savePackageChange = async () => {

    let data = {
      liveContact:state.participants.map(x => +x.id),
      channelId:state.channelId,
      eventId:state.eventId,
      packageId:+selectedPack,      
      additionalMembers:state.participants.map(x => +x.id).length,
      accessBadgeAllowed:state.accessBadgeAllowed
    }

    // console.log("datas:",data)
    let result = await axios.post('channelAdmin/channelPackageChange', data).then(res => res).catch(err => ToastsStore.error('something went wrong...'))
    closeModalPopup();
  }

 
  const closeModalPopups = () => {
    setState({});
    closeModalPopup();
  }

  const handleChange = input => e => {

    // console.log("badge:",input,e.target.value);
    setState({ ...state, [input]: e.target.value });
  }

  const handleDelegateChange = (event) => {
    
    const {
      target: { value },
    } = event;
    console.log("list:", value);
    let changedId=+value[value.length-1].id;   
    console.log("exist:",changedId);

    if(value.length==additionalMembers){
    setDelegate(false);    
    }
    else if(state.participants.filter(x => [changedId].includes(+x.id)).length){
      
     
      state.participants=value.filter(x => x.id!=changedId);
      // setAdditionalMembers(state.participants.length);
      setState({...state});    

      console.log("changedId:",state.participants.length,additionalMembers); 
      if(state.participants.length==additionalMembers)
      setDelegate(false);   
    }
  };


  const handleChangeSelect = async (input, value) => {
    setSelectedPack(value.id);
    console.log("packs:",value)
    if(state.participants.length>value.accessBadgeAllowed){
      // console.log("packs:",state.participants.length,value.accessBadgeAllowed);  
      setAdditionalMembers(value.accessBadgeAllowed);
      setDelegate(true);
    }
   
   
    state[input] = value;
    setState({ ...state });
    
  };

  console.log('state', state);

  return (
    <div>
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <Modal
        className="modal-dialog-centered Reorderdialog editreorderdialog"
        isOpen={opneadd}  >
        <div className="modal-header">
          <h3 className="text-center">Event Users Manage</h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModalPopups}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        {state.hasOwnProperty('channelId') && <div className="modal-body">

          {/* <div className="row" style={{ paddingBottom: "17px" }}>
            <div className='col-sm-4'>
            <h4>Event Participants</h4>
            </div>
            <div className='col-sm-8'>
              <FormControl variant="outlined" style={{ width: "100%" }}>
            
                 <InputLabel className='whiteback'  id="demo-multiple-checkbox-label" style={{backgroundColor:'#fff'}}>Add more delegates</InputLabel>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              multiple                              
                              label="Add more delegates"
                              value={state.participants}
                              onChange={handleDelegateChange}
                              input={<OutlinedInput label="Tag" />}
                              renderValue={(selected) => selected.map(x => x.name).join(',')}
                              MenuProps={MenuProps}
                            >
                              {state.participantOptions.map((x) => (
                                <MenuItem key={x.id} value={x} disabled={!isDelegateFull?true:false}>

                                  <Checkbox checked={state.participants.filter(p => p.id == x.id).length > 0} />
                                  <img style={{ width: '24px', height: '24px', objectFit: 'cover', marginRight:'10px' }}
                                    alt="..."
                                    src={x?.documentPath ? process.env.REACT_APP_CDN_URL+x?.documentPath : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
                                  />
                                  <ListItemText primary={x.name} />

                                </MenuItem>
                              ))}

                           
                            </Select>
              </FormControl>
              {isDelegateFull &&
<p style={{color:'red'}}>Remove {state.participants.length-parseInt(additionalMembers)} Delegates for your selected Package</p>}

            </div>
           

          </div> */}

          <div className='row mt-3' style={{ paddingBottom: "17px" }}>
            <div className='col-sm-4'>
            <h4>Main Delegate</h4>
            </div>
            <div className="avatar-group mt-2 col-sm-8" >
              {!!state.registeredUser && <><a
                className="avatar avatar-sm rounded-circle"
                href="#pablo"
                id={'easytool' + state.registeredUser.id}
                onClick={e => e.preventDefault()}
              >
                <img
                  alt="..."
                  src={state.registeredUser.documentPath ? state.registeredUser.documentPath.includes(process.env.REACT_APP_CDN_URL) ? state.registeredUser.documentPath : process.env.REACT_APP_CDN_URL + state.registeredUser.documentPath : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}//{require("assets/img/theme/team-1.jpg")}
                />
              </a>
              <h4>
              {state.registeredUser.name}

              </h4>
              
                </>}
                
            </div>


            

          </div>

          {/* {!!state.marketingOptionsSelected.length &&
            <div className='mt-3'>
              <h3 className='mb-2'>Marketing Options Selected</h3>
              <p>{state.marketingOptionsSelected.map(x => <p>{x.text} - {x.value}</p>)}</p>
            </div>
          } */}

          {/* {!!state.packageOptionsSelected.length &&
            <div className='mt-3'>
              <h3 className='mb-2'>Package Options Selected</h3>
              <p>{state.packageOptionsSelected.map(x => <p>{x.text} - {x.value}</p>)}</p>
            </div>
          } */}

          {/* <div className="row mt-3" style={{ paddingBottom: "17px" }}>
            <div className='col-sm-4'>
              <h4>Event Package</h4>
            </div>

            <div className="col-sm-8">
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <FormAutocomplete
                  id="eventPack"
                  name="eventPack"
                  label="Event Package"
                  options={state.packageOptions}
                  placeholder="Select Package"
                  disableCloseOnSelect
                  blurOnSelect
                  // limitTags={1}
                  multiple={false}
                  control={control}
                  input="package"
                  getOptionSelected={(options, value) => options.name === value.name}
                  value={state.package}
                  handleChangeSelect={handleChangeSelect}
                  // errMessage={"Please Select Country"}
                  // error={errors.country}
                  defaultValue={state.package}
                />
              </FormControl>
            </div>
          </div> */}

          

          <div className="row mt-3" style={{ paddingBottom: "17px" }}>
            <div className='col-4'>
              <h4>No of Additional Access Badge</h4>
            </div>

            <div className="col-8">
            <FormControl variant="outlined" style={{ width: "100%" }}>
            <FormInput
                   className=""
                      id="NoofAccessBadgeallowed"
                      label="Additional Access Badge"
                      type="text"
                      name="accessBadge"
                      // autoComplete="current-password"
                      variant="outlined"
                      register={register}
                       error={errors.accessBadge}
                       defaultValue={state.accessBadgeAllowed}
                      //  value={watchAll.accessBadge} 
                       onChange={handleChange('accessBadgeAllowed')}
                    />  
                     </FormControl>
            </div>
          </div>


        </div>


        }

        <div className="modal-footer">


          <Button disabled={isDelegateFull?true:false}
            color="primary"
            data-dismiss="modal"
            type="button"
            onClick={savePackageChange}
          >
            Save
          </Button>

          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={closeModalPopups}
          >
            Close
          </Button>


        </div>
      </Modal>
    </div>
  );
}
export default EditEventUser;

const packageDropdownOptions = [
  {
    id: 201,
    name: "Hosted Buyer"
  },
  {
    id: 202,
    name: "Invited Buyer"
  },
  {
    id: 203,
    name: "Visiting Delegate"
  },
  {
    id: 204,
    name: "Extra Delegate Shared Room"
  },
  {
    id: 205,
    name: "Extra Delegate Single Room"
  }
]

const packageDropdownOptions2 = [
  {
    id: 4,
    name: "Package 2"
  },
  {
    id: 5,
    name: "Discovery Package"
  },
  {
    id: 6,
    name: "French Tech 4m²"
  },
  {
    id: 301,
    name: "Package 1"
  }
]


const packageDropdownOptions1 = [
  {
    id: 4,
    name: "Package 2"
  },
  {
    id: 5,
    name: "Discovery Package"
  },
  {
    id: 6,
    name: "French Tech 4m²"
  },
  {
    id: 301,
    name: "Package 1"
  }
]