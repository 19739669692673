import React, { useEffect, useState } from 'react';
import { Modal, Card, CardBody, Button } from "reactstrap";
import axios from 'axios';
import { commonService } from '_services/common.service';
import { Spinner } from 'views/Hoc/Spinner';
import { ListItem } from "_components/Listing/ListItem";

export  const ContactView=(props)=> {
  const [open, Setopen] = useState(true);
  const [loading, setLoading] = useState(false);
  let [state, setState] = useState(null);
  let [locations, setLocations] = useState([]);
  let [productCat, setProductCat] = useState([]);
  let [intproductCat, setIntProductCat] = useState([]);
  const {firstName, lastName, country, documentPath, jobTitle, roleId, contactId} = props.userData;
  console.log('userdata', props.userData);

  useEffect(() => {
    getContactData();
  }, []);
  const getContactData= async()=>{
    setLoading(true);
    await axios.get('channel/contactDetails/'+contactId).then(res =>{
      state=res.data
      setState(state);
      if(state?.locations?.length>0){
        state.locations.map(x=> locations.push({item_name:x.country.country}));
        setLocations(locations);
      }
      if(state?.productCategories?.length>0){
      state.productCategories.map(x=>productCat.push({item_name:x.productCategorie}));
      setProductCat(productCat);  
      }
      if(state?.IntProductCategories?.length>0){
        state.IntProductCategories.map(x=>intproductCat.push({item_name:x.productCategorie}));
        setIntProductCat(intproductCat);
      }
      setLoading(false);
    }).catch(err=>{
      console.log('err',err);
      commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'channel/contactDetails/'+contactId},'/error');
      setLoading(false);
    });
  }
const closeOpen=(e)=>{
  e.preventDefault();
  props.closeUserView();
  Setopen(false);
}
  return (
    <div>
      <Modal className="modal-dialog-centered" isOpen={open} size="sm" style={{maxWidth:'420px'}}>
           <div className="" id="profileview">
           <div class='ribbon-wrapper-5'>
                  <div class='ribbon-5 bg-green'> {roleId == '1' ? 'Account Owner' : roleId == '2' ? 'Admin' : 'Primary'} </div> 
                    </div>
                    </div>
          <div className="modal-header pb-0">
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={closeOpen}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body  d-flex justify-content-center pt-0 pb-0">
          {loading? <Spinner />:
          <Card>
                <CardBody>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={documentPath!=null ? process.env.REACT_APP_CDN_URL + documentPath : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
                      style={{ width: "140px" }}
                    />
                  </a>
                  <div className="pt-4 text-center">
                    <h5 className="h3 title">
                      <span className="d-block mb-1">{firstName + ' ' + lastName}</span>
                      <small className="p font-weight-light text-muted">
                      <i class="fas fa-briefcase pr-1"></i>{jobTitle}
                      </small><br/>
                      <small className="p font-weight-light text-muted">
                      <i class="fas fa-map-marker-alt pr-1"></i>{country}
                      </small>
                    </h5>
                    {locations.length >0 &&
                    <div className="mt-3">
                <h4>Trade Countries : 
                  
                        <p className="text-left h4 ml-1" style={{display:'inline'}}> 
                      <ListItem elements={locations} index={'loc'} limit={12}/></p></h4>
                    </div>}
                    {productCat.length>0 &&
                    <div className="mt-3">
                      <h4>Trade Categories : 
                      <p className="text-left h4 ml-1" style={{display:'inline'}}>
                      <ListItem elements={productCat} index={'pro'} limit={12}/></p></h4>
                    </div>}
                    {intproductCat.length>0 &&
                    <div className="mt-3">
                      <h4>Interested Categories : 
                      <p className="text-left h4 ml-1" style={{display:'inline'}}>
                      <ListItem elements={intproductCat} index={'int-pro'} limit={12}/></p></h4>
                    </div>}
                    
                  </div>
                </CardBody>
              </Card>
          }
          </div>
          <div className="modal-footer">
            <Button color="secondary" data-dismiss="modal" type="button" onClick={closeOpen}>
              Close
            </Button>
           
          </div>
        </Modal>
    </div>
  );
}
export default  ContactView;