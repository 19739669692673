import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import {
  Button, Modal, Row, Col
} from "reactstrap";

import { ProfileUploader, BannerUploader } from 'views/Hoc/ProfileUpload';

import { FormInput, FormFileInput, FormSelect, FormButton, FormMultiSelect, FormTextArea, FormCheckBox, FormAutocomplete, FormRadio } from "_components/FormElements/FormInput";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { PackageSchema1 } from './validation';
import { commonService } from '_services/common.service';
import ReactQuill, { Quill } from "react-quill";
import axios from 'axios';


export const Packages = (props) => {
  const { handleClose, handleSave, value, visible, packageData, savePackage } = props;
  // const [open, Setopen] = useState(visible)
  const closeOpen = (e) => {
    e.preventDefault();
    // Setopen(false);
    handleClose();
  }

  const { register, handleSubmit, control, errors, watch, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(PackageSchema1),
  });

  
  
  let initialState = {
    packageName: '',
    packageType: { id: 1, name: 'Package' },
    no_of_meeting_allowed: null,
    no_of_meeting_request: null,
    no_of_access_badge: null,
    packageUser: null,
    description: '',
    price: '',
    discount: '',
    template:'',
    delegateTemplate:'',
    dismissTemplate:'',
    approvalTemplate:'',
    receiptTemplate:'',
    cordTemplate:'',
    partialRefuseTemplate:'',
    isPublic: '1', 


    // boothSize: '',   
    // privateWebinar: '',
    // firesideChat: '',
    // membersAllowed: '',
    // additionalMembersCosting: '',
    // emailMarketing: false,
    // subscriptionToCH: false,


    // hotel_room: '',
    // Options_for_buyers: [{id: Math.random(), optvalue: '', optname: ''}],
    // marketing_options: [{id: Math.random(), optvalue: '', optname: ''}],
  };

  const [error, setError] = useState('');
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState('');

  const [offerOptions, setOfferOptions] = useState([]);

  const [channelList, setChannelList] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState([]);


  const [packageOffer, setPackageOffer] = useState([]);

  const [state, setState] = useState(packageData.packageName ? packageData : initialState);

  const watchAll = watch();

  // console.log("packageData:", packageData,value)


  useEffect(() => {
    getOffers();
    getChannels(packageData.channelType,packageData.eventId,packageData.packageId);
  },[]);

  const getOffers = async () => {
    await axios.get('/channelAdmin/eventOffers').then(res => {
      setOfferOptions(res.data);
      if(packageData?.offerIds)
      setPackageOffer(res.data.filter(x => packageData.offerIds.split(',').includes(''+x.id)))
    })
  }

  const getChannels = async (channelType,eventId,packageId) => {
    await axios.get('/channelAdmin/getChannelList/'+channelType+'/'+eventId+'/'+packageId).then(res => {
      const list=res.data.channels;
      list.unshift({id:0,name:"All"});
      if(res.data.channelIds.length){
        let ids=res.data.channelIds.map(x => x.channelId);
        setSelectedChannel(list.filter(x => ids.includes(x.id)));
      }else{
      setSelectedChannel([{id:0,name:"All Channels"}])
      }
      setChannelList(list);    
    })
  }

  const handleCropClose = () => {
    setImage('');
  }

  const handleChangeEditor = value => {

    console.log("richtext:", value, value.replace(/<[^>]+>/g, ''))
    setState({ ...state, description: value });

  }

  const handleChange = input => e => {
    if (input == 'description') {
      setState({ ...state, [input]: e.target.value.slice(0, 255) });
    }
    else {
      setState({ ...state, [input]: e.target.value });

    }
  }

  const handleOffer = async (input, value) => {
    setPackageOffer(value);
  }
  
  const handleChannel = async (input, value) => {
    // console.log("value:",value);
    if(value.filter(x => x.id==0).length){
      setSelectedChannel([{id:0,name:"All Channels"}]);
    }else
    setSelectedChannel(value);

    if(selectedChannel.filter(x => x.id!=0).length){
      setState({ ...state, ["isPublic"]: '0' });
    }
  }


  const save = async () => {
   
    // console.log("selectedChannel:",selectedChannel);
   
    let offerIds=packageOffer.map(x => +x.id).toString();
    if (packageData.packageId || packageData.id)
      savePackage({ ...packageData, ...state,packageOffer:offerIds,channelIds:state.isPublic=='0'?selectedChannel:[] });
    else {
      let random = '10' + Math.floor(Math.random() * 100000);
      savePackage({ ...packageData, ...state, id: +random,packageOffer:offerIds,channelIds:state.isPublic=='0'?selectedChannel:[] })
    }
    setState(initialState);
    handleClose();
  }

  const handleChangeOptions = (i, input) => e => {
    console.log('e', e.target.value, e.target);
    if (input === 'optname' || input === 'optvalue') {
      let options = [...state.Options_for_buyers];
      console.log('options', options);
      if (input === 'optname') {
        options[i].optname = e.target.value;
      }
      if (input === 'optvalue')
        options[i].optvalue = e.target.value;
      setState({ ...state, Options_for_buyers: options })
    }
    console.log("add", e.target.value, e.target);
  }

  const handleChangeMarketingOptions = (i, input) => e => {
    console.log('e', e.target.value, e.target);
    if (input === 'optname' || input === 'optvalue') {
      let options = [...state.marketing_options];
      console.log('options', options);
      if (input === 'optname') {
        options[i].optname = e.target.value;
      }
      if (input === 'optvalue')
        options[i].optvalue = e.target.value;
      setState({ ...state, marketing_options: options })
    }
    console.log("add", e.target.value, e.target);
  }

  const handleChangeSelect = async (input, value) => {

    // console.log("packs:",input,value)

    setState({ ...state, [input]: value });
  // console.log("offer:",state.packageOffer);
  }

  const addOptions = () => {


    state.Options_for_buyers.push({ id: Math.random(), optname: null, optvalue: null })

    setState({ ...state });
  }

  const addMarketingOptions = () => {


    state.marketing_options.push({ id: Math.random(), optname: null, optvalue: null })

    setState({ ...state });
  }

  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const selectImg = async (data) => {
    if (data) {
      setImage('');
      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + props.aws_folderName);
      console.log('resImagePath', resImagePath);
      if (resImagePath) {
        setImageSrc(resImagePath.data.url);
        setState({ ...state, eventLogo: resImagePath.data.url, eventLogoId: '' });
        setLoading(false);
      }

    }

  }

  const handleChangeImage = event => {
    const data = event.target.files[0];
    console.log('files', data)
    setError('');
    if (data && data['type'].split('/')[0] === 'image') {
      console.log('datat', data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log('file', file)
        console.log('reader.result', reader.result)
        setImage(
          reader.result
        );
        console.log('image', image)
        //setOpen(false);          
      };

    } else {
      setError('Jpg | Jpeg | PNG Image Formats Only Accepted');
    }

  };

  const deleteOptions = (item, i, input) => {

    if (input == 'marketing') {
      let temp = state.marketing_options.filter(x => x.id != item.id);
      setState({ ...state, marketing_options: temp });
    } else {
      let temp = state.Options_for_buyers.filter(x => x.id != item.id);
      setState({ ...state, Options_for_buyers: temp });
    }



  }

  // console.log('watchall', watchAll)

  return (
    <div>
      <Modal className="modal-dialog-centered" size="lg" isOpen={visible}>

        <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="type" name="type" ref={register} value={packageData.isSeller} />

        <div className="modal-header">
          <h2><strong>Package</strong></h2>
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={closeOpen}>
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <form noValidate onSubmit={handleSubmit(save)}>
          <div className="modal-body">

            <Row>

              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormAutocomplete
                    id="packageType"
                    name="packageType"
                    label="packageType"
                    options={[{ id: 1, name: 'Package' },{ id: 2, name: 'Addon' }]}
                    placeholder="Select Package"
                    disableCloseOnSelect
                    blurOnSelect
                    limitTags={1}
                    control={control}
                    input="packageType"
                    getOptionSelected={(options, value) => options.name === value.name}
                    value={state.packageType}
                    handleChangeSelect={handleChangeSelect}
                    errMessage={"Please Select Package"}
                    error={errors.packageType}
                    defaultValue={state.packageType}
                  />
                </div>
              </div>

              
              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormAutocomplete
                    id="packageUser"
                    name="packageUser"
                    label="packageUser"
                    options={[{ id: 1, name: 'Primary' }, { id: 2, name: 'Secondary' }]}
                    placeholder="Select Package"
                    disableCloseOnSelect
                    blurOnSelect
                    limitTags={1}
                    control={control}
                    input="packageUser"
                    getOptionSelected={(options, value) => options.name === value.name}
                    value={state.packageUser}
                    handleChangeSelect={handleChangeSelect}
                    errMessage={"Please Select User"}
                    error={errors.packageUser}
                    defaultValue={state.packageUser}
                  />
                </div>
              </div>

              <div className="col-lg-6 mt-3">
                <div className="form-group">
                  <FormRadio
                    name="isPublic"
                    className="text-sm d-inline mb-0"
                    label="Allow this package to show all"
                    options={allowPackageOption}
                    control={control}

                    register={register}
                    // row
                    onChange={handleChange('isPublic')}
                    value={state.isPublic}
                    defaultValue={state.isPublic}

                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormInput
                    className=""
                    id="PackageName"
                    label="Package Name"
                    type="text"
                    name='packageName'
                    register={register}
                    error={errors.packageName}
                    defaultValue={state.packageName}
                    value={watchAll.packageName}
                    onChange={handleChange('packageName')}

                    // autoComplete="current-password"
                    variant="outlined"
                  />

                </div>
              </div>

              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
              Description
                <div className="form-group">
                  {/* <FormTextArea
                  className="buytext2 w-100 h-auto"
                  name="note"
                  id="note" 
                  rows="3"
                  type="textarea"
                  label="Package Description"
                //   placeholder="Tell us about the Event -max 280 characters *"
                       defaultValue={state.description}
                       onChange={handleChange('description')}
              />   */}                  
                  <ReactQuill
                    // theme="snow"
                    placeholder="Tell us about your company description and vision -max 2000 characters *"
                    onChange={handleChangeEditor}
                    value={state.description}
                    // ref={register}
                    id="description"
                    name="description"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline"],
                        [
                          {
                            list: "ordered"
                          },
                          {
                            list: "bullet"
                          }
                        ]
                      ]
                    }}
                  />
                </div>
              </div>

              {state.packageType.id==1 && <>
              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">    
                <FormInput
                   className=""
                      id="no_of_access_badge"
                      label="No of Access Badge allowed"
                      type="text"
                      name="no_of_access_badge"
                      // autoComplete="current-password"
                      variant="outlined"
                      register={register}
                       error={errors.no_of_access_badge}
                       defaultValue={state.no_of_access_badge}
                       value={watchAll.no_of_access_badge} 
                       onChange={handleChange('no_of_access_badge')}
                    />  
                  
                </div>
                </div>


                <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">    
                <FormInput
                   className=""
                      id="no_of_meeting_request"
                      label="No of meeting Request"
                      type="number"
                      name="no_of_meeting_request"
                      // autoComplete="current-password"
                      variant="outlined"
                      register={register}
                       error={errors.no_of_meeting_request}
                       defaultValue={state.no_of_meeting_request}
                       value={watchAll.no_of_meeting_request} 
                       onChange={handleChange('no_of_meeting_request')}
                    />  
                  
                </div>
                </div>   

              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">    
                <FormInput
                   className=""
                      id="no_of_meeting_allowed"
                      label="No of meeting Allowed"
                      type="number"
                      name="no_of_meeting_allowed"
                      // autoComplete="current-password"
                      variant="outlined"
                      register={register}
                       error={errors.no_of_meeting_allowed}
                       defaultValue={state.no_of_meeting_allowed}
                       value={watchAll.no_of_meeting_allowed} 
                       onChange={handleChange('no_of_meeting_allowed')}
                    />  
                  
                </div>
                </div> </>}  

                <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormInput
                    className=""
                    id="pricefree"
                    label="Price/Free"
                    type="number"
                    name="price"
                    // autoComplete="current-password"
                    variant="outlined"
                    register={register}
                    error={errors.price}
                    defaultValue={state.price}
                    value={watchAll.price}
                    onChange={handleChange('price')}
                  />

                </div>
              </div>
              

              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormInput
                    className=""
                    id="discount"
                    label="discount"
                    type="number"
                    name="discount"
                    // autoComplete="current-password"
                    variant="outlined"
                    register={register}
                    //    error={errors.discount}
                    defaultValue={state.discount}
                    value={watchAll.discount}
                    onChange={handleChange('discount')}
                  />

                </div>
              </div>


              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <h4>Package Logo{!errors.eventLogo || state.eventLogo ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select Event Logo</span>}</h4>

                  <ProfileUploader img={state.eventLogoId ? process.env.REACT_APP_CDN_URL + imageSrc : imageSrc ? process.env.REACT_APP_CDN_URL_TEMP + imageSrc : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
                    handleChange={handleChangeImage} selectImg={selectImg} image={image} handleCropClose={handleCropClose}
                  />
                </div>
              </div>

              {state.packageType.id==1 && 
              <div className="col-lg-12 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormAutocomplete
                    id="packageOffer"
                    name="packageOffer"
                    label="packageOffer"
                    options={offerOptions}
                    placeholder="Select Package Offerss"
                    // disableCloseOnSelect
                    // blurOnSelect
                    // limitTags={1}
                    
                    control={control}
                    input="packageOffer"
                    multiple
                    getOptionSelected={(options, value) => options.name === value.name}
                    value={packageOffer}
                    handleChangeSelect={handleOffer}
                    errMessage={"Please Select offer"}
                    error={errors.packageOffer}
                    defaultValue={packageOffer}
                  />
                </div>
              </div>  }

                {state.isPublic=='0' &&
               <div className="col-lg-12 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormAutocomplete
                    id="channels"
                    name="channels"
                    label="channels"
                    options={channelList}
                    placeholder="Select Channels"
                    disableCloseOnSelect
                    //blurOnSelect
                    // limitTags={1}
                    
                    control={control}
                    input="packageOffer"
                    multiple
                    getOptionSelected={(options, value) => options.name === value.name}
                    value={selectedChannel}
                    handleChangeSelect={handleChannel}
                    // errMessage={"Please Select offer"}
                    // error={errors.packageOffer}
                    defaultValue={selectedChannel}
                  />
                </div>
              </div> }      

              
              {state.packageType.id==1 && <>
              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormInput
                    className=""
                    id="template"
                    label="Register Template"
                    type="text"
                    name='template'
                    register={register}
                    error={errors.template}
                    defaultValue={state.template}
                    value={watchAll.template}
                    onChange={handleChange('template')}

                    // autoComplete="current-password"
                    variant="outlined"
                  />

                </div>
              </div>

              {/* <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormInput
                    className=""
                    id="cordTemplate"
                    label="Registered as Event Co-ordinator Template"
                    type="text"
                    name='cordTemplate'
                    register={register}
                    error={errors.cordTemplate}
                    defaultValue={state.cordTemplate}
                    value={watchAll.cordTemplate}
                    onChange={handleChange('cordTemplate')}

                    // autoComplete="current-password"
                    variant="outlined"
                  />

                </div>
              </div> */}

              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormInput
                    className=""
                    id="partialRefuseTemplate"
                    label="Partial Refuse Template"
                    type="text"
                    name='partialRefuseTemplate'
                    register={register}
                    error={errors.partialRefuseTemplate}
                    defaultValue={state.partialRefuseTemplate}
                    value={watchAll.partialRefuseTemplate}
                    onChange={handleChange('partialRefuseTemplate')}

                    // autoComplete="current-password"
                    variant="outlined"
                  />

                </div>
              </div>

              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormInput
                    className=""
                    id="delegateTemplate"
                    label="Registered as Delegate Template"
                    type="text"
                    name='delegateTemplate'
                    register={register}
                    error={errors.delegateTemplate}
                    defaultValue={state.delegateTemplate}
                    value={watchAll.delegateTemplate}
                    onChange={handleChange('delegateTemplate')}

                    // autoComplete="current-password"
                    variant="outlined"
                  />

                </div>
              </div>

              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormInput
                    className=""
                    id="approvalTemplate"
                    label="Approval Template"
                    type="text"
                    name='approvalTemplate'
                    register={register}
                    error={errors.approvalTemplate}
                    defaultValue={state.approvalTemplate}
                    value={watchAll.approvalTemplate}
                    onChange={handleChange('approvalTemplate')}

                    // autoComplete="current-password"
                    variant="outlined"
                  />

                </div>
              </div>

              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormInput
                    className=""
                    id="dismissTemplate"
                    label="Dismiss Template"
                    type="text"
                    name='dismissTemplate'
                    register={register}
                    error={errors.dismissTemplate}
                    defaultValue={state.dismissTemplate}
                    value={watchAll.dismissTemplate}
                    onChange={handleChange('dismissTemplate')}

                    // autoComplete="current-password"
                    variant="outlined"
                  />

                </div>
              </div>

              <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">
                  <FormInput
                    className=""
                    id="receiptTemplate"
                    label="Receipt Template"
                    type="text"
                    name='receiptTemplate'
                    register={register}
                    error={errors.receiptTemplate}
                    defaultValue={state.receiptTemplate}
                    value={watchAll.receiptTemplate}
                    onChange={handleChange('receiptTemplate')}

                    // autoComplete="current-password"
                    variant="outlined"
                  />

                </div>
              </div> </>}





            </Row>










          </div>

          <div className="modal-footer">
            <Button color="primary" type="submit">Save</Button>

            <Button color="secondary" data-dismiss="modal" type="button" onClick={e => handleClose()} >Cancel</Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}
export default Packages;

const allowPackageOption = [
  { value: "1", label: "Yes" },
  { value: "0", label: "No" },
];