import React ,{useEffect, useState} from 'react';
import { Button, Modal } from "reactstrap";
import { Card,CardBody,Row,Col } from "reactstrap";
import { FormInput} from "_components/FormElements/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from 'axios';
import {ToastsContainer, ToastsStore} from 'react-toasts';

export  const RiteAPIEdit=(props)=> {
    console.log(props);
  const {  isOpen, handleClose, handleSave } = props;
  const { register, control, errors, watch } = useForm({
    mode: "onBlur",
    // resolver: yupResolver(retailProfileSchema)
  });
  let [currentAPIKey, setCurrentAPIKey] = useState([]);    
  let [newApiKey, setNewApiKey] = useState([]);
  let [domain_name, setDomain_name] = useState([]);
  let [response, setResponse] = useState([]);
  let [apiDiv, setApiDiv] = useState('none');
  let [searchDiv, setSearchDiv] = useState('none');
  let [imageUrl, setImageUrl] = useState(null);
  let [permananentUrl, setPermananentUrl] = useState(null);
  

  const checkCurrentId = () => {
    
    axios.get('/riteKiteAPI/getAPI').then(res1 => {
        if(res1.data){
        if(res1.data.apiKey){
            setCurrentAPIKey(res1.data.apiKey);
        } else {
            setCurrentAPIKey(res1.data.message);
        }
        }
    });
       
  }
  checkCurrentId();
  const handleHS = async() =>{

  }

  const saveNewKey = async() => {
    var newKey = document.getElementById('new_api_key').value;
    axios.get('/riteKiteAPI/updateApi/'+newKey).then(res1 => {
        if(res1.data){
        if(res1.data.apiKey){
            ToastsStore.success('New API updated successfully.');
            handleSave();
        } else {
            console.log(res1.data);
            ToastsStore.warning(res1.data);
        }
        }
    });
  }

  const onHandleChange=(e)=>{
    setNewApiKey(e.target.value);
  }
  const onHandleChangeDomain=(e)=>{
    setDomain_name(e.target.value);
  }

  const searchDomain = () => {
    axios.post('/riteKiteAPI/searchSomain',{'domainName':domain_name}).then(res1 => {
        if(res1.data.result){
          if(res1.data.url){
              let imageUrl = res1.data.url;
              let permananentUrl = res1.data.permanentUrl;
              setImageUrl(imageUrl);
              setPermananentUrl(permananentUrl);
          } 
        }else {
            console.log(res1.data);
            ToastsStore.warning(res1.data.message);
        }
    });
  }
  

    return (
    <div>
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />  
        

      <Modal
          className="modal-dialog-centered"
          isOpen={isOpen}
         size="xs"
        >
          <div className="modal-header">
          <h4>RiteKit API Details</h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={handleClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div id="APIKeyUpdate" style={{display:(props.type == 'api')?'block':'none'}}>
            <Card>
                <CardBody>
                   
                    <Row>
                        <Col lg="12" className="col-lg-6 ">
                        API Key : {currentAPIKey}
                        </Col>
                    </Row>  
                    <Row>
                        <Col lg="12" className="col-lg-6 ">
                          New API Key : <FormInput 
                                      id="new_api_key"
                                      type="text"
                                      name="new_api_key"
                                      label="New API Key"
                                      defaultValue={newApiKey}
                                      value={newApiKey}
                                      onChange={onHandleChange}
                          />
                        </Col>
                    </Row>  
                </CardBody>
            </Card>
            <div className="modal-footer">
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              id="updatebuton"
              onClick={() => saveNewKey()}
            >
              Update
            </Button>
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={handleClose}
            >
              Close
            </Button>
        </div>
        </div>
        <div id= "domainSearch" style={{display:(props.type == 'search')?'block':'none'}}>
        <Card>
              <CardBody>
                    <Row>
                        <Col lg="12" className="col-lg-6 ">
                           <FormInput 
                                      id="domain_name"
                                      type="text"
                                      name="domain_name"
                                      label="Search Domain Name"
                                      defaultValue={domain_name}
                                      value={domain_name}
                                      onChange={onHandleChangeDomain}
                          />
                        </Col>
                    </Row> 
                    <Row>
                        <Col lg="12" className="col-lg-6 ">
                          <div>
                            {imageUrl?<img src={imageUrl}></img>:''}
                          </div> 
                          <div>
                            {permananentUrl?<img src={permananentUrl}></img>:''}
                          </div>   
                        </Col>
                    </Row> 
                </CardBody>
            </Card>
          
        
          <div className="modal-footer">
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              id="updatebuton"
              onClick={() => searchDomain()}
            >
              Search
            </Button>

             
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={handleClose}
            >
              Close
            </Button>
           
  
           
        </div>
</div>
        
        </Modal>

        
    </div>
  );
}


export default RiteAPIEdit;