import React, { useState, useEffect, useRef } from 'react';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Card, CardBody, Row, Col, Container, UncontrolledTooltip, CardHeader } from "reactstrap";
import { commonService } from '_services/common.service';
import { Badge } from "reactstrap";
import { Connect } from 'views/Hoc/Dialog/Connect';
import Slider from "react-slick";
import { Spinner } from "views/Hoc/Spinner";
import { ArrayToString } from "views/Hoc/text-tags/TextTags";
import { KeyProducts } from "views/Hoc/keyBrands/KeyBrands";
import { OfficialDocument } from "views/Hoc/officialDocuments/officialDocuments";
import { KeyBrands, ChannelProducts } from "views/Hoc/keyBrands/KeyBrands";
import { Awards, AwardsReviews, KeyRetailReviews } from 'views/Hoc/Reviews';
import { ChannelConnect } from "views/Hoc/Dialog/ChannelConnect";
import NotificationAlert from "react-notification-alert";
import { history } from '../../../_helpers/history';
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
import axios from 'axios';
import { NoData } from 'views/Hoc/no-data/no-data';
import { dashboardConstants } from '_constants';
import { dashboardService } from '_services';
import { Categories } from "views/Hoc/text-tags/TextTags";
import { useDispatch } from 'react-redux'
//import {  SideBySideMagnifier} from "react-image-magnifiers";
//import ReactImageMagnify from 'react-image-magnify';
import ReactImageZoom from 'react-image-zoom';

import ReactImageMagnify from 'react-image-magnify';
import Moment from 'moment';
import { ProductVideoPlayer } from 'views/Hoc/Dialog/ProductVideoPlayer';
const ProductDetail = (props) => {
  const dispatch = useDispatch();
  let [loading, setLoading] = useState(true);
  const [image, setImage] = useState();
  let [productDetails, setChannelDetails] = useState(null);
  let [productDetailsInfo, setChannelDetailsInfo] = useState(null);
  let [contactList, setContactList] = useState([]);
  let [connect, setconnect] = useState(false);
  let [connectPopUp, setConnectPopUp] = useState(false);
  const [tabs, toggleNav] = useState("1");
  const [isAddCart, setAddCart] = useState(false);
  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });
  let [UpdateData, setUpdateData] = useState(null);
  let [imageGallery, setImageGallery] = useState([]);
  let [selectedImg, setSelectedImg] = useState({ cat: 'Product', index: 1 });
  const [isFavorite, setIsFavorite] = useState(false);
  let [productList, setProductList] = useState([]);
  let [isVideo, setVideoOpen] = useState(false);
  let [videoUrl, setVideoUrl] = useState(null);
  let [similarProductList, setSimilarProductList] = useState([]);
  let user = JSON.parse(localStorage.getItem('CHuser'));
  const notificationAlert = useRef(null);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    vertical: true,
    verticalSwiping: true,

  };


  // useEffect(() => {
  //   getProductDetails();
  // }, []);

  useEffect(() => {
    setLoading(true);
    getProductDetails();
    setTimeout(() => {
      imageZoom("myimage", "myresult");
    }, 7000);

  }, [props.location.pathname])

  const add_or_Remove_Favorite = () => {
    if (isFavorite) {
      axios.delete('favorite', {
        params: {
          id: +productDetails.productId,
        }
      }).then(res => {
        notify('warning', '  Favorite Removed Successfully');
        dashboardService.dashboard_count().then(dasboard_count => {
          //console.log("dashboard_count:",dasboard_count);
          dispatch({ type: dashboardConstants.COUNT_SUCCESS, dasboard_count });
        });
        setIsFavorite(false);
      });
    } else {
      axios.post('favorite/add', {
        type: "WISHLIST",
        id: +productDetails.productId,
      }).then(res => {
        notify('success', '  Favorite Added Successfully');
        //axios.post('mailerservice/Buyer_Favorites_Product', {productId:+productDetails.productId});      
        dashboardService.dashboard_count().then(dasboard_count => {
          //console.log("dashboard_count:",dasboard_count);
          dispatch({ type: dashboardConstants.COUNT_SUCCESS, dasboard_count });
        });
        setIsFavorite(true);
      });
    }
  }
  const getProductDetails = async () => {
    let res = await commonService.getServices('product/getAdminProduct/' + props.match.params.id + '/' + props.match.params.cid).catch(err => console.log('err', err));
    if (res) {
      productDetails = res.data;
      setChannelDetails(productDetails);
      setGalleryImage();
      setImage(productDetails?.defaultImage?.image?.documentUrl);
      getChannelContact();
      getProductDetailInfo();
      getChannelProduct(productDetails.channelId);
      // if(user.channelTypeId!=2)
      // setSimilarProduct(productDetails.channelId);
      setLoading(false);
    }
  }
  const getChannelProduct = async (channelId) => {
    let res = await commonService.postService({ excludedProductSku: props.match.params.id }, 'product/channel-products/' + channelId).catch(err => console.log('err', err));
    if (res) {
      productList = res.data;
      setProductList(productList);
    } else {
      productList = [];
      setProductList(productList);
    }
  }
  const setGalleryImage = () => {
    imageGallery = [];
    if (productDetails?.images?.PRODUCT)
      imageGallery.push(...productDetails?.images?.PRODUCT);
    if (productDetails?.images?.DISPLAY?.length > 0)
      imageGallery.push(...productDetails?.images?.DISPLAY);
    if (productDetails?.images?.PACKING?.length > 0)
      imageGallery.push(...productDetails?.images?.PACKING);
    if (productDetails?.productDetail?.videourl && productDetails?.productDetail?.videourl !== '')
      imageGallery.push({ imageid: 0, image: { documentUrl: productDetails?.productDetail?.videourl.replace("watch?v=", "embed/"), documentTypeId: 3 } });
    setImageGallery(imageGallery);
  }
  const getChannelContact = async () => {
    contactList = [];
    setContactList(contactList);
    let res = await commonService.postService('', 'channel/channelContacts/' + productDetails?.channelId).catch(err => console.log('err', err));
    if (res) {
      contactList = res.data;
      setContactList(contactList);
    }
  }
  const getProductDetailInfo = async () => {
    let res = await commonService.getServices('product/productActionInfo/' + props.match.params.id).catch(err => console.log('err', err));
    if (res) {
      productDetailsInfo = res.data;
      setChannelDetailsInfo(productDetailsInfo);
      setIsFavorite(productDetailsInfo.wishlist);
      // setLoading(false)
    }
  }
  const setSimilarProduct = async (channelId) => {
    let res = await commonService.getServices('product/channel-similarproducts/' + channelId).catch(err => console.log('err', err));
    if (res) {
      similarProductList = res.data;
      setSimilarProductList(similarProductList);
    } else {
      similarProductList = [];
      setSimilarProductList(similarProductList);
    }
  }
  const clickKeyRetailer = () => {
    toggleNav('3');
  }
  const productConnect = () => {

    if (contactList.records.length > 0) {
      let user = [];
      if (contactList.defaultContact && contactList.records.length > 0) {
        user = contactList.records.filter(x => parseInt(x.record.contactId) === parseInt(contactList.defaultContact));
        console.log("user_1", user);
        if (user.length === []) {
          user = contactList.records[0];
        } else {
          user = user[0];
        }
      } else if (!contactList.defaultContact && contactList.records.length > 0) {
        user = contactList.records[0];
      }
      if ((parseInt(user.relationId) == null || parseInt(user.relationId) === 0 || parseInt(user.relationId) === 1)) {
        setAddCart(true);
        // openConnectPopUp(user, 7, 'Connect');
        setconnect(true);
      } else {
        addToCart();
      }
    } else {
      addToCart();
    }
  }

  const addToCart = async () => {
    setAddCart(false);
    setLoading(true);
    const productid = parseInt(productDetails.productId, 10);
    const addCartData = {
      'id': productid,
      'quantity': 1
    };
    let res = await commonService.postService(addCartData, 'cart/item').catch(err => console.log('err', notify('warning', ' This product reached the limit')));
    setLoading(false);
    if (res) {

      //console.log("addcart:",res);
      dashboardService.dashboard_count().then(dasboard_count => {
        //console.log("dashboard_count:",dasboard_count);
        dispatch({ type: dashboardConstants.COUNT_SUCCESS, dasboard_count });
      });
    }
  }

  const closeConnect = () => {
    connect = !connect;
    setconnect(connect);
  }
  const SetImages = (e, val, cat, i) => {
    e.preventDefault();
    setImage(val);
    selectedImg.cat = cat === 'Image' ? 'Product' : cat === 'Packing' ? 'Packaging' : 'Display';
    selectedImg.index = i;
    setSelectedImg(selectedImg);
  }
  const connectPopUpClose = () => {
    setConnectPopUp(false);
  }
  const closePopup = () => {
    setModalPopup(false);
  };

  const openConnectPopUp = (contact, status, title) => {
    UpdateChannelData.connectionStatusTypeId = status;
    UpdateChannelData.channelJCTId = parseInt(contact.channelJCTId);
    UpdateChannelData.contactid = contact.record.contactId;
    UpdateData = UpdateChannelData;
    setUpdateData(UpdateData);
    setConnectPopUp(true);
  }
  const updateChannel = async (value) => {
    console.log('value', value);
    setLoading(true);
    setConnectPopUp(false);
    setconnect(false);
    let res = await commonService.postService(value, 'channel/updateChannel/' + productDetails.channelId).catch(err => console.log('err', notify('danger', 'Soming went wrong')));
    setLoading(false);
    if (res) {
      if (isAddCart)
        addToCart();
      getChannelContact();
      console.log(res);
    }
  }
  const notify = (type, message) => {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {type}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      // icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };
  const OpenProductDismissDialog = () => {
    modalPopValue.message = 'Do you want Dismiss this product?';
    modalPopValue.title = 'Dismiss';
    modalPopValue.value = '';
    modalPopValue.popUpId = 1;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);
  }
  const confirmProductDismissDialog = async () => {
    setModalPopup(false);
    let dismissProduct = {
      'productId': parseInt(productDetails.productId),
      'rejectreasonId': 1,
      'comment': ''
    };
    setLoading(true);
    let res = await commonService.postService(dismissProduct, 'Product/reject').catch(err => console.log('err', notify('danger', 'Soming went wrong')));
    setLoading(false);
    if (res) {
      notify('success', 'Product dismissed from your list');
      history.push('/admin/channel')
    }
  }
  const previewVideo = (urllink) => {
    const samp = urllink.match(/^https?:\/\/([\w-]+\.\w{2})\/([\w-]{11})?/);
    console.log('samp', samp);
    if (samp) {
      if (samp[1] === 'youtu.be') {
        let embedLink = 'https://www.youtube.com/embed/';
        urllink = embedLink + samp[2];
      }
      console.log(samp);
    }

    videoUrl = urllink;
    setVideoUrl(videoUrl);
    setVideoOpen(true);
  }
  const videoModalClose = () => {
    setVideoOpen(false);
  };

  const imageZoom = (imgID, resultID) => {
    var img, lens, result, cx, cy;
    img = document.getElementById(imgID);
    result = document.getElementById(resultID);
    lens = document.createElement("DIV");
    lens.setAttribute("class", "img-zoom-lens");
    if (!img) {
      setTimeout(() => {
        imageZoom("myimage", "myresult");
      }, 5000);

    } else {


      img.parentElement.insertBefore(lens, img);
      cx = result.offsetWidth / lens.offsetWidth;
      cy = result.offsetHeight / lens.offsetHeight;
      result.style.backgroundImage = "url('" + img.src + "')";
      result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
      const moveLens = (e) => {
        var pos, x, y;
        e.preventDefault();
        pos = getCursorPos(e);
        x = pos.x - (lens.offsetWidth / 2);
        y = pos.y - (lens.offsetHeight / 2);
        if (x > img.width - lens.offsetWidth) { x = img.width - lens.offsetWidth; }
        if (x < 0) { x = 0; }
        if (y > img.height - lens.offsetHeight) { y = img.height - lens.offsetHeight; }
        if (y < 0) { y = 0; }
        lens.style.left = x + "px";
        lens.style.top = y + "px";
        result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
      }
      lens.addEventListener("mousemove", moveLens);
      img.addEventListener("mousemove", moveLens);
      lens.addEventListener("touchmove", moveLens);
      img.addEventListener("touchmove", moveLens);

      const getCursorPos = (e) => {
        var a, x = 0, y = 0;
        e = e || window.event;
        a = img.getBoundingClientRect();
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        return { x: x, y: y };
      }
    }
  }
  useEffect(() => {

  }, [])
  const productimg = { zoomWidth: 500, img: image, };
  const imageProps = {
    smallImage: {
      alt: 'Phasellus laoreet',
      isFluidWidth: true,
      src: image,
      width: '100%',
      height: '100%'
    },
    largeImage: {
      src: image,
      width: 1200,
      height: 1800
    },
    enlargedImageContainerDimensions: {
      width: '100%',
      height: '100%'
    },
    enlargedImageContainerStyle: { background: '#fff', zIndex: 9 },
  };
  return (
    <div id='product-detail' className="main-content">
      <NotificationAlert ref={notificationAlert} />
      <ChannelConnect isOpen={connectPopUp} handleClose={connectPopUpClose} value={UpdateData} updateChannel={updateChannel} outboundRemaind={null} />
      <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmProductDismissDialog} handleClose={closePopup} outboundRemaind={null} />
      {props.location?.pathname?.includes('companysettings') ? <SimpleHeader location={props.match} /> : <SimpleHeader name={['Dashboard', 'Products', 'Product View']} />}
      <ProductVideoPlayer videoModalClose={videoModalClose} isOpen={isVideo} url={videoUrl} />
      {loading ? <Spinner /> :
        <Container className="mt-4" fluid>

          <Row >
            <Col className="card-wrapper" lg="1" md="1" sm="1" >

              <Card style={{ height: "97%" }}>


                <CardBody className="p-1">

                  <div class="d-flex flex-column bd-highlight  mb--3  pb-5" style={{ overflow: "hidden" }}>
                    <Slider {...settings}>

                      {imageGallery?.length > 0 && imageGallery?.map((item, i) => {
                        return (
                          <div className="product-imgs mb-3 active-img">
                            {item?.image?.documentTypeId === 3 ?
                              <div onClick={() => previewVideo(item?.image?.documentUrl)}>
                                <i style={{ fontSize: '2rem', position: 'absolute', top: '20%', left: '38%', color: '#FF6347' }} class="fa fa-play-circle" aria-hidden="true"></i>
                                <video src={item?.image?.documentUrl} style={{ width: '100%', height: 'auto' }}></video>
                              </div>
                              :
                              <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} alt="...." onClick={e => SetImages(e, item?.image?.documentUrl, item?.productimagetype, i)} src={item?.image?.documentUrl} />}
                          </div>
                        )
                      })}

                    </Slider>
                  </div>
                </CardBody>
              </Card>

            </Col>

            <Col lg="6" md="5" sm="5" >
              <Card className="mb-2" style={{ height: "97%" }}>
                <CardBody className="p-1 prod-img" style={{ textAlign: "-webkit-center" }}>
                  <ReactImageMagnify {...imageProps} />
                  {/* <div className="center-img"> */}
                  {/* <ReactImageZoom {...productimg} /> */}
                  {/* <div className="img-zoom-container">
    <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  id="myimage" src={image}/>
    
    <div id="myresult" class="img-zoom-result"></div>
    </div> */}
                  {/* <Magnifier src={image} />
    <p className="img-title mb-0">{selectedImg.index}/{imageGallery?.length}  { selectedImg.cat }</p> */}
                  {/* </div>
     */}
                </CardBody>
              </Card>

              {/* <Card >
          <CardBody className="p-1 d-flex justify-content-around">
           
    <ButtonGroup className="btn-group-toggle w-100" data-toggle="buttons">
           <Button className={classnames({ active: active === 0 })} color="secondary" onClick={() => setImageToDisplay(0)}>
             <input
               autoComplete="off"
               name="options"
               type="radio"
               value={active === 0}
             />
             Product
           </Button>
           {productDetails?.images?.DISPLAY?.length>0 && <Button className={classnames({ active:active === 1 })} color="secondary" onClick={() =>  setImageToDisplay(1)}>
             <input
               autoComplete="off"
               name="options"
               type="radio"
               value={active === 1}
             />
            Retail Display
           </Button>}
           {productDetails?.images?.PACKING?.length>0 && <Button className={classnames({ active: active === 2})} color="secondary" onClick={() =>  setImageToDisplay(2)}>
             <input
               autoComplete="off"
               name="options"
               type="radio"
               value={active === 2}
             />
             Packaging
           </Button>}
         </ButtonGroup>
         </CardBody>
            </Card> */}

            </Col>

            <Col className="pl-0" lg="5" md="4" sm="4" >
              <Card style={{ height: "97%" }}>
                <CardBody className="p-5">
                  <div>
                    {productDetails?.productTag.length > 0 && <h3><span className="mt-4 gestcomptitle">{productDetails?.productTag.map((prodTag) => prodTag?.tag).join(", ")}</span> </h3>}
                    <h1 className="mb-2 text-center font-weight-500 ">{productDetails?.product}</h1>
                    <h3>Vendor : <span className="gestcomptitle">{productDetails?.companyName}</span></h3>
                    {productDetails?.productDetail?.productshortdec && <h3>Short Description : <span className="gestcomptitle">{productDetails?.productDetail?.productshortdec.replace(/<[^>]+>/g, '')}</span> </h3>}
                    {productDetails?.productDetail?.usp && <h3>Tweet Pitch :  <span className="gestcomptitle">{productDetails?.productDetail?.usp.replace(/<[^>]+>/g, '')}</span> </h3>}
                    <h3 className="mt-4">
                      Suggested Retail Price:<span className="gestcomptitle prodheadfontsize"> {productDetails?.productPriceDetail?.businesscurrencyid === '1' ? ' €' : ' $'} {productDetails?.productPriceDetail?.retailerprice}</span>
                    </h3>
                  </div>

                  <div className="d-flex justify-content-between mt-2">
                    <div>
                      <h3> Category:<h3 className="mt-4 ml-2 mr-4 d-inline gestcomptitle">
                        {productDetails?.productCategory?.productCategorie}
                      </h3>
                        <h3 className="mt-2">
                          Product Position:<span className="mt-2 ml-2 gestcomptitle prodheadfontsize">
                            {productDetails?.productqualitylevel === 'Entry' && 'Entry Level'}
                            {productDetails?.productqualitylevel === 'Mid' && 'Mainstream'}
                            {productDetails?.productqualitylevel === 'High' && 'Premium'}</span>
                        </h3>
                      </h3>
                    </div>
                  </div>
                  {productDetails?.productKeyRetailer?.length > 0 && <KeyProducts title="Product Certifications" data={productDetails?.productKeyRetailer} clickKeyRetailer={clickKeyRetailer} />}
                  {productDetails?.productAwards?.length > 0 && <KeyProducts title="Awards" data={productDetails?.productAwards} />}
                  {productDetails?.productKeyRetailer?.length > 0 && <KeyProducts title="Key Retailers" data={productDetails?.productKeyRetailer} clickKeyRetailer={clickKeyRetailer} />}
                  {parseInt(productDetails?.channelId) !== parseInt(user?.channelId) && <div class='d-flex justify-content-between productIcon mt-6'>
                    <div data-placement="top" id="tooltip611234743" className="Icon1">
                      <i class="fas fa-thumbs-up heart-r" style={{ color: isFavorite ? 'red' : 'gray' }} onClick={() => add_or_Remove_Favorite()}></i>
                    </div>
                    <UncontrolledTooltip delay={0} placement="top" target="tooltip611234743">{isFavorite ? 'Remove from Wishlist' : 'Add to Wishlist'}</UncontrolledTooltip>
                    <div data-placement="top" id="Dismiss" className="Icon3" onClick={OpenProductDismissDialog}>
                      <i class="fas fa-minus"></i>
                    </div>
                    <UncontrolledTooltip delay={0} placement="top" target="Dismiss">Dismiss</UncontrolledTooltip>
                    <div data-placement="top" id="Share" className="Icon2">
                      <i class="fas fa-share-alt"></i>
                    </div>
                    <UncontrolledTooltip delay={0} placement="top" target="Share">Share with a Colleague</UncontrolledTooltip>
                    <div data-placement="top" onClick={productConnect} id="Request" className="Icon4">
                      <i class="ni ni-cart"></i>
                    </div>
                    <UncontrolledTooltip delay={0} placement="top" target="Request">I am Interested and Request Sample</UncontrolledTooltip>
                    <div onClick={() => { closeConnect() }} data-placement="top" id="Connect" className="Icon1">
                      <i class="fas fa-user-plus"></i>
                    </div>
                    <UncontrolledTooltip delay={0} placement="top" target="Connect">Connect</UncontrolledTooltip>
                  </div>}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Connect handleClose={closeConnect} isOpen={connect} openConnectPopUp={openConnectPopUp} contactList={contactList} />
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader className="p-3">
                  <h2 className="text-center m-0 text-uppercase">Product Details</h2>
                </CardHeader>
              </Card></Col>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* <h3 className="mb-4">Product Details</h3> */}
                  <h3 className="prodcompanytitle">Product Description</h3>
                  <p className="description text-justify">
                    {productDetails?.productDetail?.productdesc && productDetails?.productDetail?.productdesc.replace(/<[^>]+>/g, '')}
                  </p>
                  {productDetails?.variantdetails && <><h3 className="prodcompanytitle">Variants & Attributes</h3>
                    <p className="description">
                      {productDetails?.variantdetails}
                    </p></>}
                  <h3 className="prodcompanytitle">Logistics & Import information</h3>
                  <CardBody className="Cardborder p-2">
                    <Row>
                      <Col lg="6">
                        <h4 className="mt-2">Certification</h4>
                      </Col>
                      <Col lg="6" className="mb-3 mt-2">
                        <Badge className="badge-lg w-25">
                          {productDetails?.productDetail?.productWarranty ? productDetails?.productDetail?.productWarranty : 'N/A'}
                        </Badge>
                      </Col>
                      <Col lg="6">
                        <h4 className="mt-2">Product Warranty</h4>
                      </Col>
                      <Col lg="6" className="mb-3 mt-2">
                        <Badge className="badge-lg w-25">
                          {productDetails?.productDetail?.productWarranty ? productDetails?.productDetail?.productWarranty : 'N/A'}
                        </Badge>
                      </Col>
                      {productDetails?.productDetail?.productLocal && <><Col lg="6">
                        <h4>Localisation</h4>
                      </Col>
                        <Col lg="6" className="mb-3">
                          <Badge className="badge-lg w-25">
                            {productDetails?.productDetail?.productLocal ? productDetails?.productDetail?.productLocal : 'N?A'}
                          </Badge>
                        </Col></>}
                      <Col lg="6">
                        <h4>Product Availability</h4>
                      </Col>
                      <Col lg="6" className="mb-3">
                        <Badge style={{ backgroundColor: "#8b96a2", color: 'white' }}
                          className="badge-lg w-25"
                          color="light"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          {productDetails?.productDetail?.isavaialbleimmediate === '1' ? 'Immediate' : Moment(productDetails?.productDetail?.avaialblefrom).format('ll')}
                        </Badge>
                      </Col>
                      <Col lg="6">
                        <h4>MOQ</h4>
                      </Col>
                      <Col lg="6" className="mb-3">
                        <Badge className="badge-lg w-25">
                          {productDetails?.productPackageInfo?.minorderquantity} {productDetails?.productPackageInfo?.minorderquantity ? productDetails?.productPackageInfo?.orderunitdetail?.unit : 'N/A'}
                        </Badge>
                      </Col>
                      <Col lg="6">
                        <h4>No. of item in box</h4>
                      </Col>
                      <Col lg="6" className="mb-3">
                        <Badge className="badge-lg w-25">
                          {productDetails?.productPackageInfo?.noofpackageinbox} {productDetails?.productPackageInfo?.noofpackageinbox ? productDetails?.productPackageInfo?.noofpackageinboxunitdetail?.unit : 'N/A'}
                        </Badge>
                      </Col>
                    </Row>

                  </CardBody>
                </CardBody>
              </Card>

              {productList?.length > 0 && <ChannelProducts data={productList} title="Other Products from this Vendor" />}
              {similarProductList?.length > 0 && <ChannelProducts data={similarProductList} title="Similar Products" />}
              <Row>
                <Col lg='12' className='pad-less'><Card>
                  <CardHeader className="p-3">
                    <h2 className="text-center m-0 text-uppercase">Reviews</h2>
                  </CardHeader>
                </Card></Col>
                {(productDetails?.productAwards?.length > 0 || productDetails?.productArticleReview?.length > 0) && <Col lg='6' className='pl-0'><Card>
                  <CardBody>
                    <h3 className="mb-4">Media Reviews</h3>
                    {/* {((!productDetails?.productAwards || productDetails?.productAwards?.length===0) &&productDetails?.productArticleReview?.length===0)&&<NoData/>} */}
                    {/* {productDetails?.productAwards?.length>0 && <Awards data={productDetails?.productAwards} title="Awards"/>} */}
                    {/* {productDetails?.productAwards?.length>0 && } */}
                    {/* {(productDetails?.productAwards?.length>0 && productDetails?.productArticleReview?.length>0) && <><hr/></>} */}
                    {productDetails?.productArticleReview?.length > 0 && <> <AwardsReviews data={productDetails?.productArticleReview} title="" /></>}
                  </CardBody>
                </Card></Col>}
                {productDetails?.productExistRating?.length > 0 && <Col lg='6' className='pr-0'><Card>
                  <CardBody>
                    <h3 className="mb-4">End-users reviews</h3>
                    {/* {((!productDetails?.productKeyRetailer || productDetails?.productKeyRetailer?.length ===0) && productDetails?.productExistRating?.length ===0) && <NoData/>}
              {productDetails?.productKeyRetailer?.length>0 && <KeyBrands data={productDetails?.productKeyRetailer} title="Current Retail Partners" />}
              {productDetails?.productKeyRetailer?.length>0 && <hr/>} */}
                    {<KeyRetailReviews data={productDetails?.productExistRating} />}
                  </CardBody>
                </Card></Col>}
              </Row>
              {(productDetails?.locations?.TARGET?.length > 0 || productDetails?.productKeyRetailer?.length > 0 || productDetails?.productKeyDistributor?.length > 0 || productDetails?.productExistRating?.length > 0) && <Card>
                <CardHeader className="p-3">
                  <h2 className="text-center m-0 text-uppercase">Distribution Channels</h2>
                </CardHeader>
              </Card>}
              {(productDetails?.locations?.TARGET?.length > 0 || productDetails?.productKeyRetailer?.length > 0 || productDetails?.productKeyDistributor?.length > 0) && <Card>
                <CardHeader className="p-3">
                  {/* <h3 className="mb-0">Looking For Partners In</h3>
          <Categories id='4' data={productDetails?.locations?.TARGET}/>           */}
                  {productDetails?.productKeyRetailer?.length > 0 && <>
                    {/* <CardBody> */}
                    {/* <h3 className="mb-4">Key Retailers & End-users reviews</h3> */}
                    {/* {((!productDetails?.productKeyRetailer || productDetails?.productKeyRetailer?.length ===0) && productDetails?.productExistRating?.length ===0) && <NoData/>} */}
                    {<KeyBrands type={1} data={productDetails?.productKeyRetailer} title="Current Retail Partners" />}
                    {/* {productDetails?.productKeyRetailer?.length>0 && <hr/>} */}
                    {/* {productDetails?.productExistRating?.length>0 && <KeyRetailReviews data={productDetails?.productExistRating} title="End User Reviews"/>} */}
                    {/* </CardBody> */}
                  </>}

                  {productDetails?.productKeyDistributor?.length > 0 && <Card>
                    <CardBody>
                      {/* <h3 className="mb-4">Current Distributors</h3> */}
                      {productDetails?.productKeyDistributor?.length > 0 ? <KeyBrands type={1} data={productDetails?.productKeyDistributor} title="Current Distributors" /> : <><h3 className="mb-4">Current Distributors</h3><NoData /></>}
                    </CardBody>
                  </Card>}
                </CardHeader>
              </Card>}
              <Col lg='12' className='pad-less'><Card>
                <CardHeader className="p-3">
                  <h2 className="text-center m-0 text-uppercase">Product Brochers & Documents</h2>
                </CardHeader>
              </Card></Col>
              {productDetails?.images?.BROUCHER.length > 0 && <OfficialDocument Isprodet={true} type='2' data={productDetails?.images?.BROUCHER} title="" />}
            </Col>
          </Row>
        </Container>
      }
    </div>
  );
}

const UpdateChannelData = {
  connectionStatusTypeId: null,
  channelJCTId: null,
  dismissreasonid: null,
  otherreasons: null,
  leadManagementTag: null,
  partnerLeadManagementTag: null,
  note: null,
  contactid: null,
  videoUrl: null
}

export default ProductDetail;