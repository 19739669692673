import { userConstants } from '../_constants';
import { userService,LocalStorageService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';


export const userActions = {
    login,
    logout,
//    register,
  //  getAll,
    //delete: _delete
};


function login(username, password, apptoken,callbacksuccess,callbackerror) {

    const localStorageService = LocalStorageService.getService();

    return dispatch => {
        dispatch(request({ username }));

      userService.login(username, password, apptoken)
            .then(
                user => { 
                    dispatch(success(user));
                    console.log('user', user);
                    localStorageService.setToken(user.data.token); 
                    callbacksuccess(true)                  
                    history.push('/admin');
                    
                    
                },
                error => {
                    callbackerror(true)
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

