import * as yup from "yup";

const signupschema = yup.object().shape({
  companyemail: yup.string().required('Please Enter Email Address').email('Please Enter a valid Email Address'),
  password:yup
  .string()
  .required('Please enter your password').min(8, 'Password Must Have Min 8 Characters').max(25, 'Password Must Have Max 25 Characters')
  .matches(
    /^((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    'Password must contain at least one uppercase, one number and one special case character'
  ),
  rePassword: yup.string().required('Confirm password is required').oneOf([yup.ref('password'), null], 'Passwords must match.')
});


const signupschema1 = yup.object().shape({
    //companyName: yup.string().required('Please complete this required field'),
    jobposition: yup.string().required('Please Enter Job Position').min(2, 'Job Position Must Have Min 2 Characters'),
    firstName: yup.string().required('Please Enter First Name').min(2, 'First Name Must Have Min 2 Characters').max(30, 'First Name Must Have Max 30 Characters'),
    lastName: yup.string().required('Please Enter Last Name').max(30, 'Last Name Must Have Max 30 Characters'),
    companyName: yup.object().shape({
      name: yup.string().required().min(2, 'Company Legal name must be minimum 2 characters'),
      id: yup.string()
    }).required('Please Enter Company Name'),
    // product_categories: yup.array().of(yup.object().shape({
    //   //firstLetter: yup.string().required(),
    //     groupid: yup.string().required('1'),
    //     groupname: yup.string().required('2'),
    //     childid: yup.string().required('3'),
    //     childname: yup.string().required('4'),
    //     status: yup.string().required('5')
    // })).required('Please Select a Product Category')
    productCat: yup.string().required('Please Select a Product Category'),
    //isOEM: yup.string().required('Please complete this required field'),
    // mobile: yup.string().required('Please complete this required field'),
    // switchPhone: yup.string().required('Please complete this required field'),
  
});

const signupschema2 = yup.object().shape({
  //companyName: yup.string().required('Please complete this required field'),
  //jobposition: yup.string().required("Please complete this required field"),
  address: yup.string().required('Please Enter Address').max(255, 'Address Must Have Max 255 Characters'),
  city: yup.string().required('Please Enter City'),
  state: yup.string().required('Please Enter State'),
  country: yup.string().required('Please Select Your country'),
  postalCode: yup.string().required('Please Enter Postal Code').min(3, 'Postal Code Must Have Min 3 Characters').max(12, 'Postal Code Must Have Max 12 Characters'),
  websiteurl: yup.string().required('Please Enter Company Website URL').matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.'),
  // .url('Please Enter valid Website Url'),
  //product_categories: yup.string().required('Please complete this required field'),
  //isOEM: yup.string().required('Please complete this required field'),
  // mobile: yup.string().required('Please complete this required field'),
   switchPhone: yup.string().required('Please Enter Phone No').min(4, 'Phone No Must Have Min 4 Numbers').
   matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{1,4}?$/,
   'Please Enter Valid Phone Number').max(20, 'Phone No Must Have Max 20 Numbers'),

});

const signupschema3 = yup.object().shape({
   mobile: yup.string().required('Please Enter Mobile No').min(7, 'Mobile No Must Have Min 4 Numbers').
   matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
   'Please Enter Valid Mobile Number').max(20, 'Mobile No Must Have Max 20 Numbers'),
});

const forgotSchema = yup.object().shape({
  forgotemail: yup.string().email("Mail id invalid").required("Required"),
});

const resetPwdschema = yup.object().shape({
  password:yup
  .string()
  .required('Please enter your password').min(8, 'Password Must Have Min 8 Characters').max(25, 'Password Must Have Max 25 Characters')
  .matches(
    /^((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    'Password must contain at least one uppercase, one number and one special case character'
  ),
  rePassword: yup.string().required('Confirm password is required').oneOf([yup.ref('password'), null], 'Passwords must match.')
});

const documentNameSchema = yup.object().shape({
  name: yup.string().required('Please Enter Name'),
});
export {signupschema,signupschema1,signupschema2,signupschema3, forgotSchema, resetPwdschema, documentNameSchema};