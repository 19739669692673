import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Card, Container, Row, CardHeader, UncontrolledTooltip, ListGroupItem,
  ListGroup, Col, Badge, Button
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import BillingFilter from "components/Filter-Right/BillingFilter.js";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useForm } from "react-hook-form";
import { SelectAutocomplete } from '_components/FormElements/FormInput';
import axios from 'axios';
import { Spinner } from '../../Hoc/Spinner'
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/PaginationActions';
import Dialog from 'views/Hoc/Dialog/GlobalDialog';
import NotificationAlert from "react-notification-alert";
import UserView from 'views/Hoc/Dialog/UserView';
import Drawer from 'views/Hoc/Dialog/Globaldrawer';
import Connect from 'views/Hoc/Dialog/Connect';
import { history } from '_helpers/history';
import { ToastsContainer, ToastsStore } from 'react-toasts';

const toggleSidenav = e => {
  console.log(e)

  if (document.getElementById('filter').classList.contains("g-sidenav-pinned")) {
    document.getElementById('filter').classList.remove("g-sidenav-pinned");
    document.getElementById('filter').classList.add("g-sidenav-hidden");
  } else {
    document.getElementById('filter').classList.add("g-sidenav-pinned");
    document.getElementById('filter').classList.remove("g-sidenav-hidden");
  }
  // function handleOrangeClick({
  //  sidenavOpen: setOpen(!sidenavOpen)
  // });
};
const company_type_Option = [
  { name: "Vendor", id: 2 },
  { name: "Distributor", id: 3 },
  { name: "Retailer", id: 4 },
];
const is_OEM_Option = [
  { name: "Yes", id: 'yes' },
  { name: "No", id: 'no' },
]
export const WireTransfer = (props) => {
  const notificationAlert = useRef(null);
  const { control } = useForm({ mode: 'onBlur' });
  const [showDrawer, setshowDrawer] = useState(false);
  const [connect, setshowconnect] = useState(false);

  const [contactid, setContact] = useState();

  const [user, setUser] = useState({ firstName: '', lastName: '', jobTitle: '', country: '' });

  const [state, setState] = useState({ dataTable: [], total: 0 });

  const [eventState, setEventState] = useState({ dataTable: [], total: 0 });

  const [subscription, setSubscription] = useState({ value: { id: 1, name: 'ChannelSubscription' }, option: [{ id: 1, name: 'ChannelSubscription' }, { id: 2, name: 'EventSubscription' }] });

  const [searchState, setSearch] = useState({ search: null, dropdownList: [] });

  const [filterValueState, setFilterValue] = useState({ Company_type: [], plan: [], periods: [], channel_status: [], sub_status: [] });
  const [filterOptionState, setFilterOption] = useState({ channel_status_Option: [], periods_Option: [], company_type_Option: [], plan_Option: [], sub_status_Option: [] });

  let [loading, setLoading] = useState(true);
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [open, setModalOpen] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null });
  let [channelSubscriptionId, setChannelSubscriptionId] = useState();
  let [actions, setAction] = useState();

  const [drawerInput, setDrawerInput] = useState(null);

  console.log("sub:", subscription.value)
  useEffect(() => {
    //getAggregation(); 
    getPendingChannel();
    getEventWire();
    //getPendingChannelsList();

    //getProductCat();
  }, []);

  const getCountryList = () => {
    axios.post('/channelAdmin/getFilterCountry', {
      signupstatusid: [14]
    }).then(res => {
      filterOptionState.countryOption = res.data;
      setFilterOption({ ...filterOptionState, countryOption: filterOptionState.countryOption });
    })
  }

  const getFilterData = () => {
    let filter = {
      isPaid: "0",
      paymentMethodId: 2,
      transactionStatus: '0'
    };

    if (filterValueState.plan && filterValueState.plan.length > 0) {
      Object.assign(filter, { planId: filterValueState.plan.map(x => parseInt(x.id)) });
    }

    if (filterValueState.Company_type && filterValueState.Company_type.length > 0) {
      Object.assign(filter, { channelTypeId: filterValueState.Company_type.map(x => parseInt(x.id)) });
    }

    if (filterValueState.periods && filterValueState.periods.length > 0) {
      Object.assign(filter, { periodId: filterValueState.periods.map(x => parseInt(x.id)) });
    }

    if (filterValueState.subscription_status && filterValueState.subscription_status.length > 0) {
      Object.assign(filter, { subscription_status: filterValueState.subscription_status });
    }

    if (filterValueState.channel_status && filterValueState.channel_status.length > 0) {
      Object.assign(filter, { channel_status: filterValueState.channel_status });
    }


    return filter;
  }

  const getPendingChannel = async () => {
    loading = true;
    setLoading(loading);
    //var filter = await getFilterData();
    let filter = {
      isPaid: "0",
      paymentMethodId: 2,
      transactionStatus: '0',
      page: page * rowsPerPage,
      limit: rowsPerPage
    }
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.post('/channelAdmin/channelSubscription',
      filter, {
      isPaid: '0', isActive: '0', params: {
        page: page,
        limit: rowsPerPage, sort: 'desc'
      }
    }
    ).then(res => {
      console.log("billing:", res);
      state.dataTable = res.data.rows;
      state.total = res.data.count;

      filterOptionState.channel_status_Option = res.data.aggregations[0].subsciptionfilters['channelstatus'];
      filterOptionState.company_type_Option = res.data.aggregations[0].subsciptionfilters['channeltypes'];
      filterOptionState.periods_Option = res.data.aggregations[0].subsciptionfilters['periods'];
      filterOptionState.plan_Option = res.data.aggregations[0].subsciptionfilters['plans'];
      filterOptionState.sub_status_Option = res.data.aggregations[0].subsciptionfilters['subsciptionstatus'];

      setFilterOption({
        ...filterOptionState, channel_status_Option: filterOptionState.channel_status_Option,
        company_type_Option: filterOptionState.company_type_Option,
        periods_Option: filterOptionState.periods_Option,
        plan_Option: filterOptionState.plan_Option,
        sub_status_Option: filterOptionState.sub_status_Option

      });
      console.log("billing:", filterOptionState);
      setState({ ...state });
      loading = false;
      setLoading(loading);
    }).catch((err) => {
      state.dataTable = [];
      state.total = 0;
      loading = false;
      setLoading(loading);
      setState({ ...state });
    })
  }

  const getEventWire = async () => {
    loading = true;
    setLoading(loading);
    //var filter = await getFilterData();
    let filter = {
      isPaid: "0",
      //paymentMethodId:2,
      //transactionStatus:'0',
      page: page * rowsPerPage,
      limit: rowsPerPage
    }
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.post('/channelAdmin/eventSubscription',
      filter, {
      isPaid: '0', isActive: '0', params: {
        page: page,
        limit: rowsPerPage, sort: 'desc'
      }
    }
    ).then(res => {
      // console.log("billing:", res);
      eventState.dataTable = res.data.rows;
      eventState.total = res.data.count;

      filterOptionState.channel_status_Option = res.data.aggregations[0].subsciptionfilters['channelstatus'];
      filterOptionState.company_type_Option = res.data.aggregations[0].subsciptionfilters['channeltypes'];
      filterOptionState.periods_Option = res.data.aggregations[0].subsciptionfilters['periods'];
      filterOptionState.plan_Option = res.data.aggregations[0].subsciptionfilters['plans'];
      filterOptionState.sub_status_Option = res.data.aggregations[0].subsciptionfilters['subsciptionstatus'];

      setFilterOption({
        ...filterOptionState, channel_status_Option: filterOptionState.channel_status_Option,
        company_type_Option: filterOptionState.company_type_Option,
        periods_Option: filterOptionState.periods_Option,
        plan_Option: filterOptionState.plan_Option,
        sub_status_Option: filterOptionState.sub_status_Option

      });
      // console.log("billing:", filterOptionState);
      setEventState({ ...eventState });
      loading = false;
      setLoading(loading);
    }).catch((err) => {
      eventState.dataTable = [];
      eventState.total = 0;
      loading = false;
      setLoading(loading);
      setEventState({ ...eventState });
    })
  }

  //   const getPendingChannelsList = ()=>{
  //     axios.get('/channelAdmin/pendingChannelsList').then(res =>{
  //       let data = [];
  //       data.push(...res.data)
  //       data=data.map(x=>{
  //        return {name:x.name.trim(), id : x.id}
  //       });
  //       let contact = [];
  //       res.data.map(x=>{
  //         x.contacts.map(c=>{
  //           contact.push({name:c.firstName + ' ' + c.lastName, id:x.id, companyName:x.name.trim() });
  //         })
  //       })
  //       data.push(...contact);
  //       searchState.dropdownList = data
  //           setSearch({dropdownList:searchState.dropdownList });
  //         });
  //   }

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getPendingChannel();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    callPendingChannel();
  };

  const handleChangeSelect = (input, value) => {

    console.log("subs:", value);
    if (input === 'subscription' && value != null) {
      subscription.value = value;
      setSubscription({ ...subscription });

    }
    if (input == 'search') {
      filterValueState.Company_type = [];
      filterValueState.periods = [];
      filterValueState.plan = [];
      filterValueState.channel_status = [];
      filterValueState.sub_status = [];
      setFilterValue({ ...filterValueState });
      searchState.search = [value]
      setSearch({ ...searchState })
    }
    if (input == 'Company_type') {
      filterValueState.Company_type = value
      setFilterValue({ ...filterValueState })
    }
    if (input == 'periods') {
      filterValueState.periods = value
      setFilterValue({ ...filterValueState })
    }
    if (input == 'plan') {
      filterValueState.plan = value
      setFilterValue({ ...filterValueState })
    }
    if (input == 'channel_status') {
      filterValueState.channel_status = value
      setFilterValue({ ...filterValueState });
    }

    if (input == 'sub_status') {
      filterValueState.sub_status = value
      setFilterValue({ ...filterValueState });
    }
    if (value?.id == 1)
      callPendingChannel();
    else if (value?.id == 2)
      getEventWire();
  }

  const callPendingChannel = () => {
    page = 0;
    setPage(page);
    getPendingChannel();
  }
  const resetFilterValue = () => {
    filterValueState.Company_type = [];
    filterValueState.periods = [];
    filterValueState.plan = [];
    filterValueState.channel_status = [];
    filterValueState.sub_status = [];
    setFilterValue({ ...filterValueState });
    callPendingChannel();
  }
  const notify = (type, message) => {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {type}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      // icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const updateStatus = (id, data) => {
    loading = true;
    setLoading(loading);

  }

  const openDialag = (id, contactId, status) => {
    console.log('contactId ', contactId);
    let data = { 'id': id, 'contactId': contactId, 'status': status };
    setDrawerInput(data);
    //setChannelSubscriptionId(id); 
    //    setAction(status); 
    setshowDrawer(true);
  }

  const approveEvent = async (packageId, eventSubscriptionId, contactId, channelId, eventId, status) => {

    setLoading(true);
    axios.post('/channelAdmin/approveWireEvent', { status: status, eventSubscriptionId: eventSubscriptionId, contactId: contactId, channelId: channelId, eventId: eventId }).then(async res => {

      let data = {
        eventId: eventId,
        channelId: channelId,
        contactId: contactId,
        packageId: packageId
      }
      if (status) {
        await axios.post('channelAdmin/getEventSubscription/', data).then(res => {

          let subscription = res.data.subscription;
          // console.log("bill:",subscription[0].subcription[0]);
          let payment = subscription[0].subcription.length ? subscription[0].subcription[0] : null;

          let offers = res.data.offers;

          if (payment) {
            let eventData = {
              contactId: payment.contactId,
              channelId: payment.channelId,
              packageId: payment?.packageId,
              eventId: payment?.eventId,
              paymentTransactionId: "Wire Transfer",
              profileurl: process.env.REACT_APP_LOCALSITE,
              finalAmount: (parseFloat(payment?.finalAmount) + parseFloat(payment?.taxAmount)).toFixed(2)?.toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
              subTotal: parseFloat(payment?.actualAmount).toFixed(2)?.toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
              taxAmount: parseFloat(payment?.taxAmount).toFixed(2)?.toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
              vatNumber: payment?.address['VAT'],
              actualAmount: payment?.actualAmount?.toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
              paymentOption: payment?.totalSplit?.length > 1 ? 2 : 1,
              totalSplit: payment?.totalSplit?.sort((a, b) => a.splitId - b.splitId),
              splitId: payment?.totalSplit?.filter(x => x.wireTransfer == 1).sort((a, b) => a.splitId - b.splitId)[0].splitId,
              offer: offers?.filter(o => o.offerType == 2 && parseFloat(payment.couponAmount) > 0).length ? offers : offers?.filter(o => o.offerType == 1),
            };

            let paidDetails = {
              contactId: payment.contactId,
              channelId: payment.channelId,
              packageId: payment?.packageId,
              eventId: payment?.eventId,
              paymentMethod: 2,
              splitId: 1,
              paymentDate: new Date(),
              paymentStatus: '1',
              createdAt: new Date(),
              paidAt: new Date(),
              wireTransfer: '1',
              totalAmount: parseInt(eventData.finalAmount),
              percentage: 100,
              splitAmount: parseInt(eventData.finalAmount),
              //currCode: paymentDetails.currCode
            }

            const paymentData = { channelId: paidDetails.channelId, paidDetails: paidDetails }

            axios.post('channelAdmin/pennyinvoice/', paymentData)

            axios.post('mailerservice/eventPaymentMail', eventData).then(res => {

              ToastsStore.success('Wire Transfer Approved Successfully');

              console.log("page-->customer-->api-->channelAdmin/eventPaymentMail", new Date())

            }).catch(err => {
              console.log("page-->customer-->api-->mailerservice/eventPaymentMail error->", err, "  ", new Date())
            });

          }
        }).catch(err => {
        });
      }

      getEventWire();
      setLoading(false);
    })

  }

  const openModalPopUp = (ch_Id, id) => {
    if (id === 1) {
      modalPopValue.message = 'You want to Approve';
      modalPopValue.title = "Approve Payment";
      let data = { 'status': true };
      modalPopValue.value = { id: ch_Id, data: data };
    } else {
      let data = { 'status': false };
      modalPopValue.message = 'You want to Dismiss';
      modalPopValue.title = "Dismiss Payment";
      modalPopValue.value = { id: ch_Id, data: data };
    }
    modalPopValue.popUpId = 1;
    setModalPopValue({ ...modalPopValue })
    open = true;
    setModalOpen(open);
  };



  const closeDrawer = () => {
    setshowDrawer(false);
  }

  const closeConnect = () => {
    setshowconnect(false);
  }
  // style={{width:'46%',float:'right'}}
  const reCallView = () => {
    closeDrawer();
    callPendingChannel();
  }
  //const [openSide,setOpen] = React.useState(1);
  const [sidenavOpen, setOpen] = useState(false);
  return (
    <div id="channelList">


      <NotificationAlert ref={notificationAlert} />
      {/* <div className={classes.root}> */}


      <BillingFilter
        Filter={true}
        filterOption={filterOptionState}
        filterValue={filterValueState}
        resetFilterValue={resetFilterValue}
        handleChangeSelect={handleChangeSelect}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen} />

      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />

      <SimpleHeader name="Wire Transfer" parentName="Billing" />
      {/* <Drawer title="WireTransfer" visible={showDrawer} channelSubscriptionId={channelSubscriptionId} closeDrawer={closeDrawer}/> */}

      <Drawer title="WireTransfer" visible={showDrawer} cData={drawerInput} closeDrawer={closeDrawer} reCallView={reCallView} />

      <Container className="mt--3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Wire Transfer</h3>
                {/* <p className="text-sm mb-0">
                    This is an exmaple of data table using the well known
                    react-bootstrap-table2 plugin. This is a minimal setup in
                    order to get started fast.
                  </p> */}
              </CardHeader>
              {loading ? <Spinner /> : <ToolkitProvider
                ClassName="align-items-center table-flush" responsive
                data={subscription['value'].id == 2 ? eventState.dataTable : state.dataTable}
                keyField="contacts[0].email"
                columns={[
                  {
                    dataField: "companyLogo",
                    text: "Company Logo",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="avatar avatar-sm rounded-circle"
                            alt="..."
                            src={row.channel.channelDetail?.channelLogo?.documentPath ? process.env.REACT_APP_CDN_URL + row.channel.channelDetail.channelLogo.documentPath : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}//{require("assets/img/theme/team-1.jpg")}
                          />
                        </>
                      )
                    }
                  },
                  {
                    dataField: "companyName",
                    text: "Company Name",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {row.channel.companyName}
                        </>
                      )
                    }
                  },
                  {
                    dataField: "channelTypeId",
                    text: "Company Type",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {row.channel.channelType['channelType']}
                        </>
                      )
                    }
                  },

                  ...subscription.value?.id == 1 ?
                    [{
                      dataField: "subscriptionPlanType",
                      text: "Subscription Details",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <p>Plan : {row.subscription.subscriptionPlan.subscriptionPlanType}</p>
                            <p>Plan Cost : {row.finalAmount} / {row.subscription.subscriptionPeriod.periodDesc}</p>

                          </>
                        )
                      }
                    }] : [{
                      dataField: "subscriptionPlanType",
                      text: "Event Details",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <p>Event : {row.event.name}</p>
                            <p>Event Cost : {row.finalAmount} </p>

                          </>
                        )
                      }
                    }],

                  {
                    dataField: "Users",
                    text: "Contact Details",
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          <div className="avatar-group">
                            <a
                              className="avatar avatar-sm rounded-circle"
                              href="#pablo"
                              id={'easytool' + row.channel.contact.contactId}
                              onClick={e => e.preventDefault()}
                            >
                              <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }}
                                alt="..."
                                src={row.channel.contact?.document?.documentPath ? process.env.REACT_APP_CDN_URL + row.channel.contact?.document?.documentPath : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}//{require("assets/img/theme/team-1.jpg")}
                              />
                            </a>
                            {row.channel.contact.firstName + ' ' + row.channel.contact.lastName}
                            <p>{row.channel.contact.jobTitle}</p>
                            <p>{row.channel.contact?.country?.country}</p>
                          </div>
                        </>
                      )
                    }
                  },

                  ...subscription.value?.id == 1 ?
                    [{
                      dataField: "Invoices",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>

                            <button onClick={() => { openDialag(row.channelSubscriptionId, row.channel.contact.contactId, true) }} className="btn btn-icon btn-primary btn-sm" variant="contained" color="primary" >
                              <span className="btn-inner--icon">{`Approve Plan`}</span>
                            </button>

                            <button onClick={() => openDialag(row.channelSubscriptionId, row.channel.contact.contactId, false)} className="btn btn-icon btn-primary btn-sm" variant="contained" color="primary" >
                              <span className="btn-inner--icon">Dismiss</span>
                            </button>
                          </>
                        )
                      }
                    }] : [{
                      dataField: "Invoices",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>

                            <button onClick={() => { approveEvent(row.packageId, row.eventSubscriptionId, row.contactId, row.channel.channelId, row.eventId, true) }} className="btn btn-icon btn-primary btn-sm" variant="contained" color="primary" >
                              <span className="btn-inner--icon">{'Approve Event'}</span>
                            </button>

                            <button onClick={() => approveEvent(row.packageId, row.eventSubscriptionId, row.channel.contact.contactId, row.channel.channelId, row.eventId, false)} className="btn btn-icon btn-primary btn-sm" variant="contained" color="primary" >
                              <span className="btn-inner--icon">Dismiss</span>
                            </button>
                          </>
                        )
                      }
                    }]
                ]}
              >
                {props => (
                  <div className="py-2 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 mb-2"
                    >
                      <div style={{ width: '30%' }}>
                        <SelectAutocomplete
                          id="subscription"
                          type="text"
                          label="Subscription"
                          name="subscription"
                          options={subscription.option}
                          placeholder="search..."
                          disableCloseOnSelect
                          getOptionSelected={(options, value) => options.name === value.name}
                          defaultValue={subscription.value}
                          control={control}
                          input='subscription'
                          value={subscription.value}
                          handleChangeSelect={handleChangeSelect}
                        />
                      </div>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      // pagination={pagination}
                      bordered={false}
                      hover={true}

                      rowClasses='pointer'
                    />

                    <TablePagination
                      component="div"
                      count={subscription['value'].id == 2 ? eventState.total : state.total}
                      page={page}
                      onChangePage={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </div>
                )}
              </ToolkitProvider>}
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};
export default WireTransfer;
