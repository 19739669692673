import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Card, Container, Row, CardHeader,UncontrolledTooltip,  ListGroupItem,
  ListGroup,Col,Badge, Button} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import BillingFilter from "components/Filter-Right/BillingFilter.js";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useForm} from "react-hook-form";
import { SelectAutocomplete } from '_components/FormElements/FormInput';
import axios from 'axios';
import {Spinner} from '../../Hoc/Spinner'
import TablePagination from '@material-ui/core/TablePagination';
import {TablePaginationActions} from 'views/Hoc/PaginationActions';
import Dialog from 'views/Hoc/Dialog/GlobalDialog';
import NotificationAlert from "react-notification-alert";
import UserView from 'views/Hoc/Dialog/UserView';
import Drawer from 'views/Hoc/Dialog/Globaldrawer';
import Connect from 'views/Hoc/Dialog/Connect';
import {history} from '_helpers/history';
import {ToastsContainer, ToastsStore} from 'react-toasts';

import Moment from 'moment';

 const toggleSidenav = e => {
   console.log(e)
 
  if (document.getElementById('filter').classList.contains("g-sidenav-pinned")) {
    document.getElementById('filter').classList.remove("g-sidenav-pinned");
    document.getElementById('filter').classList.add("g-sidenav-hidden");
  } else {
    document.getElementById('filter').classList.add("g-sidenav-pinned");
    document.getElementById('filter').classList.remove("g-sidenav-hidden");
  }
  // function handleOrangeClick({
  //  sidenavOpen: setOpen(!sidenavOpen)
  // });
};
const company_type_Option =[
  { name: "Vendor", id: 2 },
  { name: "Distributor", id: 3 },
  { name: "Retailer", id: 4 },
];
const is_OEM_Option =[
  { name: "Yes", id: 'yes' },
  { name: "No", id: 'no' },
]
export const SubscriptionListing = (props) => {
  const notificationAlert = useRef(null);
  const { control } = useForm({ mode:'onBlur' });
  const [showDrawer,setshowDrawer]=useState(false);
  const [connect,setshowconnect]=useState(false);

  const [contactid,setContact]=useState();

  const [user,setUser]=useState({firstName:'',lastName:'',jobTitle:'',country:''});
  
  const [state, setState ] = useState({dataTable:[], total:0});

  
  const [searchState, setSearch] = useState({search:null, dropdownList:[]});
  const [filterValueState, setFilterValue] = useState({Company_type:[], plan:[], periods:[], channel_status:[],sub_status:[]});
  const [ filterOptionState, setFilterOption] = useState({channel_status_Option:[], periods_Option:[], company_type_Option:[], plan_Option:[],sub_status_Option:[]});
  let [isSort, setisSort] = useState(false);
  let [sort, setSort] = useState('');
  let [loading, setLoading]  = useState(true);
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [open, setModalOpen]  = useState(false);
  let [modalPopValue, setModalPopValue]  = useState({message:null, title:null, value:null, popUpId:null });
  const [drawerInput, setDrawerInput] = useState(null);
  let adminUser = JSON.parse(localStorage.getItem('CHuser'));
  var FileSaver = require('file-saver');
  useEffect(() => {
    //getAggregation(); 
    getPendingChannel();
    getPendingChannelsList();
    
    //getProductCat();
  }, []);


  const getPendingChannelsList = () => {
    axios.get('/channelAdmin/pendingChannelsList/' + adminUser.userId).then(res => {
      let data = [];
      data.push(...res.data)
      data = data.map(x => {
        return { name: x.name.trim(), id: x.id }
      });
      let contact = [];
      res.data.map(x => {
        x.contacts.map(c => {
          contact.push({ name: c.firstName + ' ' + c.lastName, id: x.id, companyName: x.name.trim() });
        })
      })
      data.push(...contact);
      searchState.dropdownList = data
      setSearch({ dropdownList: searchState.dropdownList });
    });
  }
  
  const getFilterData=()=>{    
    let filter = {
        invoiceno: "CHZ",
        isPaid: "1"
        };
    
    if(filterValueState.plan && filterValueState.plan.length>0){
      Object.assign(filter,{planId:filterValueState.plan.map(x=> parseInt(x.id))});
    }

    if(filterValueState.Company_type && filterValueState.Company_type.length>0){
        Object.assign(filter,{channelTypeId:filterValueState.Company_type.map(x=> parseInt(x.id))});
      }

     if(filterValueState.periods && filterValueState.periods.length>0){
        Object.assign(filter,{periodId:filterValueState.periods.map(x=> parseInt(x.id))});
      }

    if(filterValueState.subscription_status && filterValueState.subscription_status.length>0){
        Object.assign(filter,{subscription_status:filterValueState.subscription_status});
     }

     if(filterValueState.channel_status && filterValueState.channel_status.length>0){
        Object.assign(filter,{channel_status:filterValueState.channel_status});
     }

     
    return filter;
  }

  const setSorting = (data) => {
    isSort=true;
    setisSort(isSort);
    if (data != sort) {
      sort = data;
      setSort(sort);
      callPendingChannel();
    }
}

  const getPendingChannel = async ()=>{
    loading = true;
    setLoading(loading);
    //  var filter = await getFilterData();
    let data = {
      search: searchState.search ? searchState.search[0] ? searchState.search[0].id : null : null,
      page: page * rowsPerPage,
      order: isSort? sort:false,
      limit: rowsPerPage
    }
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.post('/channelAdmin/channelSubscription',data
      ).then(res =>{
        console.log("billing:",res);
        state.dataTable = res.data.rows;
        state.total = res.data.count;

        
        setState({ ...state });
        loading = false;
        setLoading(loading);
      }).catch((err)=>{
        state.dataTable = [];
        state.total = 0;
        loading = false;
        setLoading(loading);
        setState({ ...state });
      })
  }

  
  const handleChangePage = (event, newPage) => {
    page=newPage;
    setPage(page);
    getPendingChannel();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    callPendingChannel();
  };

  const handleChangeSelect = (input, value)=>{
    if(input=='search'){
      searchState.search= [value]
      setSearch({ ...searchState })
    }
    

    callPendingChannel();
  }

  const callPendingChannel=()=>{
    page=0;
    setPage(page);
    getPendingChannel();
  }
  const resetFilterValue =()=>{
    filterValueState.Company_type=[];
    filterValueState.periods=[];
    filterValueState.plan=[];
    filterValueState.channel_status=[];
    filterValueState.sub_status=[];
    setFilterValue({ ...filterValueState });
    callPendingChannel();
  }
  
  const senInvoice = async(ch, id) => {
    let responseType = 'view';
    if(id===15){
      responseType = 'mail';
    }
     let userdata = { email :  ch.channel.contact.email,
      channel : ch.channel,
      subscriptionId : ch.subscriptionId,
      channelSubscriptionId : ch.channelSubscriptionId,
      contactId :  ch.channel.contact.contactId,
      transaction :  ch.paymentTransaction };
      setLoading(true);
      let res =  await axios.post('channelAdmin/generateInvoice',{userdata: userdata, responseType: responseType}).then(res => {
        if(res) {

          setTimeout(async() => { setLoading(true);
              if(id===15){
              axios.post('mailerservice/subscriptionEmail',{id: +ch.channel.channelId, csId: +ch.channelSubscriptionId, ch: +ch}).then(res => {
                setLoading(false);
                ToastsStore.success('Mail Sent Successfully')
                }).catch(err =>console.log('err',  ToastsStore.error(err.response.data.message)));
              } else{
                
                let  fileName = ch.channelSubscriptionId+'-'+ch.subscriptionId+'.pdf';
                    await axios.get('channelAdmin/fileDwn/'+fileName, { responseType: 'arraybuffer'})
                    .then((response) => {
                        var blob = new Blob([response.data], { type: 'application/pdf' });
                        FileSaver.saveAs(blob, fileName);
                        
                        
                    });
                    //deleteDownloadedFile(fileName)
                    setLoading(false);
              }
          }, 3000);

         
        }
      }).catch(err =>console.log('err',  ToastsStore.error(err.response.data.message)));
      setLoading(false);
      // page=0;
      // setPage(page);
      // getPendingChannel();
  };

  const deleteDownloadedFile = (fileName) => {
    axios.get('channelAdmin/deleteFile/'+fileName, { responseType: 'arraybuffer'})
                    .then((response) => {
                   
                    
                    var blob = new Blob([response.data], { type: 'application/pdf' });
                        FileSaver.saveAs(blob, fileName);

                        deleteDownloadedFile(fileName)
                        setLoading(false);
                    });
  }
 
  const [userview, setuserview] = useState(false)
  const login=(contactId,firstName,lastName,jobTitle,country)=>{
    setContact(contactId);
    setUser({...user,firstName:firstName,lastName:lastName,jobTitle:jobTitle,country:country});
    setuserview(true);

  }
  
const closeConnect=()=>{
  setshowconnect(false);
}
  // style={{width:'46%',float:'right'}}
  // const User = {
  //   renderer: (row, rowIndex) => {
  //     console.log('rowuser', row, rowIndex);
  //     return(
  //     <div style={{width:'80%'}}>
  //       {row.contacts.length && row.contacts.map((x,i) => 
  //         <ListGroup className="list my--2" flush key={'table'+x.contactId}>
  //                     <ListGroupItem className="px-0">
  //                       <Row className="align-items-center">
  //                         <Col className="col-auto">
  //                           <a
  //                             className="avatar rounded-circle"
  //                             href="#pablo"
  //                             onClick={e => e.preventDefault()}
  //                           >
  //                             <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
  //                               alt="..."
  //                               src={x.document?.documentPath ? x.document.documentPath.includes(process.env.REACT_APP_CDN_URL) ? x.document.documentPath : process.env.REACT_APP_CDN_URL+x.document.documentPath  : process.env.REACT_APP_CDN_URL+'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}//{require("assets/img/theme/team-1.jpg")}
  //                             />
  //                           </a>
  //                         </Col>
  //                         <div className="col-lg-1 ml--2">
  //                           <p className="mb-0">
                            
  //                               {x.firstName + ' ' + x.lastName}
                            
  //                           </p>
  //                         <small>{x.email}</small>
  //                         <div><i onClick={() => login(x.contactId,x.firstName,x.lastName,x.jobTitle,x.country?.country)} style={{padding:"10px 10px 0 0 "}} class="fas fa-sign-in-alt pointer"></i>
  //                         <i onClick={()=>{openContact(Object.assign(x, {channelTypeId: row.channelTypeId, channelId: row.channelId, userId: row.user[0].userId}))}} style={{padding:"10px 10px 0 10px"}} class="fas fa-edit pointer"></i>
  //                         <i  onClick={()=>{openConnect()}} style={{padding:"10px 10px 0 10px"}} class="fas fa-exchange-alt pointer"></i></div>
                        
                        
  //                         </div>
  //                         <div className="col-lg-1 ml--2">
  //                           <p className="mb-0">
  //                            {x.jobTitle}
  //                           </p>
  //                         </div>
  //                         <div className="col-lg-1 ml--2">
  //                           <p className="mb-0">
  //                           {x.hubspotContactId ? x.hubspotContactId : '-'}
  //                           </p>
  //                         </div>
  //                         <div className="col-lg-2 ml--2">
  //                           <p className="mb-0" style={{overflow: 'hidden', textOverflow: 'ellipsis'}} id={'producttip'+x.contactId}>
  //                           {
  //                             x.productGroup?.length ? x.productGroup.map((y,i) => {
  //                               if(x.productGroup.length-1 != i)
  //                               return(y.productGroup.productGroup + ', ');
  //                               return(y.productGroup.productGroup);
  //                             }) : <>-</>
  //                           }
  //                           </p>
  //                           <UncontrolledTooltip delay={0} target={'producttip'+x.contactId}>
  //                           {
  //                             x.productGroup?.length ? x.productGroup.map((y,i) => {
  //                               if(x.productGroup.length-1 != i)
  //                               return(y.productGroup.productGroup + ', ');
  //                               return(y.productGroup.productGroup);
  //                             }) : <></>
  //                           }
  //                           </UncontrolledTooltip>
  //                         </div>
  //                         <div className="col-lg-1 ml--2">
  //                           <p className="mb-0">
  //                           {x.user?.role?.roleId == '1' ? 'Account Owner' : x.user?.role?.roleId == '2' ? 'Admin' : 'Primary'}
  //                           </p>
  //                         </div>
  //                         <div className="col-lg-1 ml--2">
  //                           <p className="mb-0">
  //                            {x.isShared=='0' ? 'Confidential' : 'Shared'}
  //                           </p>
  //                         </div>
  //                         <div className="col-lg-1 ml--2">
  //                           <p className="mb-0">
  //                            {x.country?.country}
  //                           </p>
  
  //                         </div>
  //                         <div className="col-lg-1 ml--2">
  
  //                         <Badge className='outer_badge' style={{width:"75%",height:"2rem",padding:"0.6rem",border:"1px solid #46b88f",color:"#46b88f"}}>                                
  //                             {x.isActive == '1' ? 'Active' : 'Inactive'}
                            
  //                             </Badge>
  
  //                         </div>
                     
  //                         {/* <Col className="col-lg-1">
  //                           <Button className="float-right" color="primary" size="sm" type="button">
  //                           <i class="fas fa-sign-in-alt pointer"></i>   Login
  //                           </Button>
  //                         </Col>
  //                         <Col className="col-lg-1">
  //                           <Button className="float-right" color="primary" size="sm" type="button">
  //                           <i class="fas fa-sign-in-alt pointer"></i>   Edit
  //                           </Button>
  //                         </Col>
  //                         <Col className="col-lg-1">
  //                           <Button className="float-right" color="primary" size="sm" type="button">
  //                           <i class="fas fa-sign-in-alt pointer"></i>   Replace
  //                           </Button>
  //                         </Col> */}
  //                       </Row>
  //                     </ListGroupItem>
                  
  //                     </ListGroup>)
  //       }        
  //     </div>
  //   )},
  //   className: 'bgrow'
  // };
  //const [openSide,setOpen] = React.useState(1);
  const [sidenavOpen,setOpen] = useState(false);
  return (
    <div id="channelList">
     
      {/* {userview &&
      // <UserView  closelogin={closelogin} contactid={contactid} user={user} />}
      {connect &&
      // <Connect opneUser={opneUser} closeConnect={closeConnect}/>
} */}
       
      
        
      <BillingFilter 
        Filter={true}
        filterOption={filterOptionState}
        filterValue={filterValueState}
        resetFilterValue={resetFilterValue}
        handleChangeSelect={handleChangeSelect}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen} />
        

      
      <SimpleHeader name="Channel List" parentName="Dashboard" />
      {/* <Drawer title="Edit User" visible={showDrawer} contactData={drawerInput} closeDrawer={closeDrawer}/> */}
        <Container className="mt--3" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Company Listing</h3>
                  {/* <p className="text-sm mb-0">
                    This is an exmaple of data table using the well known
                    react-bootstrap-table2 plugin. This is a minimal setup in
                    order to get started fast.
                  </p> */}
                </CardHeader>
                {loading? <Spinner/>:<ToolkitProvider
                ClassName="align-items-center table-flush" responsive
                  data={state.dataTable}
                  keyField="contacts[0].email"
                  columns={[
                    {
                      dataField: "companyLogo",
                      text: "Company Logo",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return(
                          <>
                          <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="avatar avatar-sm rounded-circle"
                        alt="..."
                        src={row.channel.channelDetail?.channelLogo?.documentPath ?  process.env.REACT_APP_CDN_URL+row.channel.channelDetail.channelLogo.documentPath  : process.env.REACT_APP_CDN_URL+'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}//{require("assets/img/theme/team-1.jpg")}
                      />                                                    
                          </>
                        )
                      }
                    },
                    {
                      dataField: "companyName",
                      text: "Company Name",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return(
                          <>
                          {row.channel.companyName}                          
                          </>
                        )
                      }
                    },
                    {
                      dataField: "channelTypeId",
                      text: "Company Type",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return(
                          <>
                          {row.channel.channelType['channelType']}                          
                          </>
                        )
                      }
                    },
                    {
                      dataField: "subscriptionPlanType",
                      text: "Plan",
                      sort: true,
                      formatter: (rowContent, row) => {
                          return (    
                            <>                                    
                          <button className="btn btn-icon btn-warning btn-sm" variant="contained" color="warning" >
                            <span>{row.subscription.subscriptionPlan.subscriptionPlanType}</span>
                        </button>
                                                           
                          </>
                          )
                      }
                    },
                    
                    // {
                    //   dataField: "subscriptionType",
                    //   text: "SubscriptionType",
                    //   sort: true,
                    //   formatter: (rowContent, row) => {
                    //     return(
                    //       <>
                    //       {row.subscriptionType.subscriptionType}                          
                    //       </>
                    //     )
                    //   }
                    // },
                    {
                      dataField: "paymentTransactionAt",
                      text: "SubscriptionDate",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return(
                          <>
                          {Moment(row.paymentTransaction?.paymentTransactionAt).format('DD-MM-YYYY')}
                                                  
                          </>
                        )
                      }
                    },
                    // {
                    //   dataField: "transactionNumber",
                    //   text: "TransactionNo",
                    //   sort: true,
                    //   formatter: (rowContent, row) => {
                    //     return(
                    //       <>
                    //       {row.paymentTransaction?.transactionNumber}                          
                    //       </>
                    //     )
                    //   }
                    // },
                    {
                      dataField: "finalAmount",
                      text: "SubscriptionCost",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return(
                          <>
                          {row.finalAmount}                          
                          </>
                        )
                      }
                    },
                    {
                      dataField: "Invoices",
                      text: "Invoices",
                      formatter: (rowContent, row) => {
                        return (                          
                          <>                                                  
                        
                        <button onClick={()=>senInvoice(row, 15)} className="btn btn-icon btn-primary btn-sm" variant="contained" color="primary" >
                          <span className="btn-inner--icon">Mail</span>
                        </button>                      
                      
                        
                        <button onClick={()=>senInvoice(row, 16)} className="btn btn-icon btn-default btn-sm" variant="contained" color="default">
                          <span className="btn-inner--icon"><span className="btn-inner--icon">View</span></span>
                        </button>
                        
                        
                        
                        </>
                        
                        )
                        }
                      }
                  ]}
                >
                  {props => (
                    <div className="py-2 table-responsive">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 mb-2"
                      >
                <div style={{ width:'30%'}}>
                          <SelectAutocomplete
                            id="search"
                            type="text"                
                            label="search"
                            name="search"
                            options={searchState.dropdownList}
                            placeholder="search..."
                            disableCloseOnSelect
                             defaultValue={searchState.search?searchState.search[0]:null}
                            control={control}                         
                            input='search'
                            value={searchState.search?searchState.search[0]:null}
                            handleChangeSelect={handleChangeSelect}
                        /> 
                        </div>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                       // expandRow={ }
                        bootstrap4={true}
                        // pagination={pagination}
                        bordered={false}
                        hover ={true}
                        
                        rowClasses= 'pointer' 
                      />

                  <TablePagination
                        component="div"
                        count={state.total}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </div>
                  )}
                </ToolkitProvider>}
              </Card>
              </div>
              </Row>
              </Container>
    </div>
  );
};
export default SubscriptionListing;
