import React, {useState,useEffect} from 'react';
import {FormInput,FormFileInput,FormSelect,FormButton,FormMultiSelect,FormTextArea, FormCheckBox, FormAutocomplete} from "_components/FormElements/FormInput";
import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import {signupschema} from './Validation'; 

import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { NewFieldAutocomplete } from '_components/FormElements/FormInput';
import axios from 'axios';

export default function Step5(props) {

  let comp={};

    const { register, control, errors, watch, handleSubmit } = useForm({            
        mode:'onBlur',  
        resolver: yupResolver(signupschema)
    });    

    
    const { handleChange, handleChangeSelect } = props;
  
    const {email, password, rePassword,errState} = props.values;

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);

    const handleClickShowPassword1 = () => {setShowPassword1(!showPassword1);}
    const handleClickShowPassword = () => {setShowPassword(!showPassword);}    
    
    const watchEmail = watch('companyemail', email);
    const watchPassword = watch('password', password);
    const watchRepassword = watch('rePassword', rePassword);
    
      useEffect(()=>{
          setCheckMail(0);
      }, [email])

      const [checkMailId, setCheckMail] = useState(0);
      const onSubmit  = (data) => {    
        console.log('email', watchEmail)
        console.log('pass', watchPassword)
        console.log('repass', watchRepassword)
        checkMail();
      }
      console.log('errors', errors)
      const checkMail = () => {
        const apiUrl = 'account/checkmail/'+watchEmail;

        axios.get(apiUrl).then(res => {
          console.log('res', res.data)
          if(res.data.isavialble == true){
            props.assignValues({
              email: watchEmail,
              password: watchPassword,
              rePassword: watchRepassword
            });
          }
          else{
            console.log('error already taken mail')
            setCheckMail(1)
          }
        })
        
      }
    

    return (
  
        <div className="main-content">
        <div className="container">
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="row">

        <div className="col-lg-12">
        <div className="form-group">
        <FormInput
            id="companyemail" 
            type="email" 
            label="Enter your Email" 
            name="companyemail" 
            register={register}
            value={watchEmail}
            //onChange={handleChange('email')}
            error={errors.companyemail ? errors.companyemail : checkMailId ? {message:"Email Id already taken", required: true} : ''}
            
            defaultValue={email}
         />
        </div>
        </div>
        


        {/* <div className="row">
        <div className="col-lg-12"> */}
            <div className="col-lg-6">
            <div className="form-group">
            <FormInput
                    id="password" 
                    type = {showPassword ? "text" : "password"}  
                    label="Password" 
                    name="password" 
                    error={errState.password!=''?{message:errState.password,type:'required'}:errors.password}
                    value={watchPassword}
                    //onChange={handleChange('password')}
                    register={register}
                    InputProps={{}}
                    defaultValue={password}

             />
             </div>
            </div>
        
    
        
            <div className="col-lg-6">
            <div className="form-group">    
            <FormInput
                id="rePassword" 
                type = {showPassword1 ? "text" : "password"} 
                label="Confirm Password" 
                name="rePassword" 
                error={errState.rePassword!=''?{message:errState.rePassword,type:'required'}:errors.rePassword}
                value={watchRepassword}
                //onChange={handleChange('rePassword')}
                register={register}
                defaultValue={rePassword}
                hintText="Password"
                 InputProps={{
                     endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword1}
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword1 ?  <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                     }}
             />
            </div>  
             {/* </div>
             </div>  */}

</div>

        </div>
        <button type="submit" className="btn btn-primary ch-btn-blue right-but" variant="contained" color="primary" >
            Next
          </button> 
        </form>
        
        </div>
    </div>
     
    )
}
const yes=[
  { title :'jgfygfjy'},
  { title :'Nokhvv'},
]

