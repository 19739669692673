import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import { TextField } from '@material-ui/core';
import { Tree } from 'antd';
import { Spinner } from 'views/Hoc/Spinner';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { boUser } from '../pages/profile/validation';
import { FormAutocomplete, FormInput } from "_components/FormElements/FormInput";
import axios from 'axios';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { commonService } from '_services/common.service';


const CreateBoUser = (props) => {
    const { contactData, saved } = props;
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState({
        firstName: null,
        lastName: null,
        email: '',
        role: null,
        managedCountry: [],
        managedChannelType: [],
    });
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [expandedKeys1, setExpandedKeys1] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [checkedKeys1, setCheckedKeys1] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selectedKeys1, setSelectedKeys1] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [autoExpandParent1, setAutoExpandParent1] = useState(true);

    const [preference, setPreference] = useState({
        leftData: [],
        rightData: []
    });


    const [stateList, setStateList] = useState({
        countryList: [],
        roleList: [{ id: 1, name: 'Super Admin' }, { id: 2, name: 'User' }, { id: 4, name: 'External' }],
        channelTypeList: [{ id: 2, name: 'Vendor' }, { id: 3, name: 'Distributor' }, { id: 4, name: 'Retailer' }, { id: 5, name: 'Reseller' }]
    })

    const { register, handleSubmit, control, errors, watch, setValue } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(boUser(state.role ? { role: state.role.id } : { role: null })),
    });
    const watchAll = watch();

    const handleChangeSelect = (input, value) => {
        setState({ ...state, [input]: value });
    }
    const handleChange = (input) => (e) => {
        setState({ ...state, [input]: e.target.value });
    };
    let adminUser = JSON.parse(localStorage.getItem('CHuser'));

    const clearState = () => {
        state.firstName = null;
        state.lastName = null;
        state.email = '';
        state.role = null;
        state.managedCountry = [];
        state.managedChannelType = [];
        setState({ ...state });
    }

    useEffect(() => {
        (async () => {
            console.log("cdata:", contactData);
            setLoading(true);
            if (contactData.key == 'edit') {
                clearState();
                //let reporting = await axios.post('/channelAdmin/reporting', { channelId: props.contactData.channelId, userId: props.contactData.user.userId }).catch(err => setLoading(false));
                let countrylist = await axios.post('/channelAdmin/getFilterCountry', {
                    signupstatusid: [14, 15],
                    userId: adminUser.userId
                });
                stateList.countryList = countrylist.data;
                let reportTo = [];
                //if (reporting) {
                // stateList.reportingList = reporting.data;
                // setStateList({ ...stateList });
                // if (reportTo.length) {
                //     reportTo = stateList.reportingList.find(x => x.id == props.contactData.rptContactId);
                //     state.reportingTo = reportTo;
                //     console.log('re[pr', reportTo);
                // }
                state.firstName = contactData?.fName;
                state.lastName = contactData?.lName;
                state.email = contactData?.emailId;

                // let country = stateList.countryList.find(x => parseInt(x.id) === parseInt(contactData.countryId));
                // console.log('country', country);
                if (contactData?.managedCountries.length > 0)
                    state.managedCountry = contactData?.managedCountries.map(cty => cty.countryid);
                else
                    state.managedCountry = [];

                if (contactData?.managedChannelTypes.length > 0)
                    state.managedChannelType = contactData?.managedChannelTypes;

                //console.log('stateList', stateList);
                state.role = stateList.roleList.filter(x => x.name == contactData?.role);
                state.role = state.role.length > 0 ? state.role[0] : {};
                console.log('stateList:', state.role);
                // state.mobileNo = contactData?.mobileNo;
                // state.jobTitle = contactData?.jobTitle;
                setStateList({ ...stateList });
                setState({ ...state });
                getPreference('edit');
                setLoading(false);
            }
            else if (contactData.key == 'add') {
                getPreference('add');
                let countrylist = await axios.post('/channelAdmin/getFilterCountry', {
                    signupstatusid: [14, 15],
                    userId: adminUser.userId
                });
                stateList.countryList = countrylist.data;
                setStateList({ ...stateList });

                state.firstName = null;
                state.lastName = null;
                state.email = '';
                state.role = '';
                state.managedCountry = [];
                state.managedChannelType = [];
                setState({ ...state });
                setLoading(false);
            }

        })();
    }, [props.contactData])

    const getPreference = (action) => {
        let left = []; let right = [];
        //setLoading(true);
        (async () => {

            setLoading(true);
            await axios.get('user/mailpreference').then(res => {
                setPreference({ ...preference, leftData: res.data.left, rightData: res.data.right });

                res.data.left.map(x => {

                    left.push(x.key)
                    if (x.children?.length) {
                        x.children.map(y => {
                            if (y.key)
                                left.push(y.key);
                        })
                    }

                });

                res.data.right.map(x => {

                    right.push(x.key)
                    if (x.children?.length) {
                        x.children.map(y => {
                            if (y.key)
                                right.push(y.key);
                        })
                    }

                });

                //console.log('left side', left, 'right side', right);

                if (action == 'add') {
                    setCheckedKeys(res.data.left.map(x => x.key));
                    setSelectedKeys(res.data.left.map(x => x.key));
                    setCheckedKeys1(res.data.right.map(x => x.key));
                    setSelectedKeys1(res.data.right.map(x => x.key));
                }
            })
            if (action == 'edit' && contactData?.preference.length > 0) {
                let keys = contactData?.preference.filter(x => [1, 2, 3, 4, 5, 6, 7, 8].includes(+x.id));
                let keys1 = contactData?.preference.filter(x => [11, 22, 33, 44, 55, 66].includes(+x.id));
                //console.log("pkeys:", keys);
                setCheckedKeys(keys.map(x => +x.id));
                setSelectedKeys(keys.map(x => +x.id));
                setCheckedKeys1(keys1.map(x => +x.id));
                setSelectedKeys1(keys1.map(x => +x.id));
                //setPreferenceRedux();
                //setState({...state, checkedKeys:res.data,selectedKeys:res.data});

            }
            //  setLoading(false);

        })();

    }


    //    console.log('state', state);
    const save = async () => {

        console.log("bouser:", props.contactData);
        let adminUserId = props.contactData?.key == 'add' ? 0 : props.contactData.adminUserId;

        setLoading(true);
        await axios.patch('admin/updateContact/' + adminUserId, { userData: state, preference: [...checkedKeys, ...checkedKeys1] }).then(async res => {
            console.log("bousers:", res.data);
            if (adminUserId == 0)
                axios.post('mailerservice/Add_BO_User', res.data);
            ToastsStore.success('Saved Successfully!');
            setLoading(false);
            saved();
        }).catch(err => {
            ToastsStore.error('Something Went Wrong!');
            setLoading(false);
            commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'updateContact' }, '/error');
        });
    }

    const onExpand = (expandedKeys) => {
        console.log('onExpand', expandedKeys); // if not set autoExpandParent to false, if children expanded, parent can not collapse.
        // or, you can remove all expanded children keys.

        setExpandedKeys(expandedKeys);
        setAutoExpandParent(true);
    };

    const onExpand1 = (expandedKeys) => {
        console.log('onExpand', expandedKeys); // if not set autoExpandParent to false, if children expanded, parent can not collapse.
        // or, you can remove all expanded children keys.

        setExpandedKeys1(expandedKeys);
        setAutoExpandParent1(true);
    };


    const onCheck = (checkedKeys) => {
        console.log('onCheck', checkedKeys);
        setCheckedKeys(checkedKeys);
        //setPreferenceRedux();
    };
    const onCheck1 = (checkedKeys) => {
        console.log('onCheck', checkedKeys);
        setCheckedKeys1(checkedKeys);
        //setPreferenceRedux();
    };

    const onSelect = (selectedKeys, info) => {
        console.log('onSelect', info);
        setSelectedKeys(selectedKeys);
        //setPreferenceRedux();
    };

    const onSelect1 = (selectedKeys, info) => {
        console.log('onSelect', info);
        setSelectedKeys1(selectedKeys);
        //setPreferenceRedux();
    };

    return (
        <div>
            <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
            {loading ? <Spinner /> :
                <Card>
                    <CardBody>
                        <form className="coupon-form" noValidate onSubmit={handleSubmit(save)} autoComplete="off">
                            {/* <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="roleId" ref={register} name="roleId" value={parseInt(props.contactData.user.role.roleId)} control={control} /> */}
                            <Row>
                                <div className="col-lg-6 mt-4">
                                    <div className="form-group">
                                        <FormInput
                                            id="firstName"
                                            type="text"
                                            name="firstName"
                                            label="First Name *"
                                            register={register}
                                            defaultValue={state.firstName}
                                            error={errors.firstName}
                                            value={watchAll.firstName}
                                            onChange={handleChange('firstName')}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4">
                                    <div className="form-group">
                                        <FormInput
                                            id="lastName"
                                            type="text"
                                            name="lastName"
                                            label="Last Name *"
                                            //placeholder="Password"                                            
                                            register={register}
                                            error={errors.lastName}
                                            value={watchAll.lastName}
                                            defaultValue={state.lastName}
                                            onChange={handleChange('lastName')}
                                        />
                                    </div>
                                </div>
                            </Row>
                            <Row>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <FormAutocomplete
                                            id="role"
                                            type="text"
                                            label="User Role"
                                            name="role"
                                            options={stateList.roleList}
                                            disableCloseOnSelect
                                            blurOnSelect
                                            //multiple
                                            //limitTags={1}
                                            getOptionSelected={(options, value) => options.name === value.name}
                                            control={control}
                                            input='role'
                                            value={state.role}
                                            handleChangeSelect={handleChangeSelect}
                                            errMessage={'Please Select User Role'}
                                            error={errors.role}
                                            defaultValue={state.role}
                                        />

                                    </div>
                                </div>

                                {/* <div className="col-lg-6">
                                    <div className="form-group">
                                        <FormInput
                                            id="role"
                                            type="text"
                                            label="User Role"
                                            name="role"
                                            control={control}
                                            register={register}
                                            error={errors.role}
                                            defaultValue={state.role}
                                            value={watchAll.role}
                                            onChange={handleChange('role')}
                                        />

                                    </div>
                                </div> */}

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <FormInput
                                            id="email"
                                            type="text"
                                            label="Contact Email"
                                            name="email"
                                            control={control}
                                            register={register}
                                            error={errors.email}
                                            defaultValue={state.email}
                                            value={watchAll.email}
                                            onChange={handleChange('email')}
                                        />
                                    </div>
                                </div>
                            </Row>

                            <Row>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <FormAutocomplete
                                            id="managedChannelType"
                                            type="text"
                                            label="ManagedChannels"
                                            name="managedChannelType"
                                            options={stateList.channelTypeList}
                                            disableCloseOnSelect
                                            blurOnSelect
                                            multiple
                                            //limitTags={1}
                                            getOptionSelected={(options, value) => options.name === value.name}
                                            control={control}
                                            input='managedChannelType'
                                            value={state.managedChannelType}
                                            handleChangeSelect={handleChangeSelect}
                                            errMessage={'Please Select Channels Type'}
                                            error={errors.managedChannelType}
                                            defaultValue={state.managedChannelType}
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <FormAutocomplete
                                            id="managedCountry"
                                            name="managedCountry"
                                            label="ManagedCountry"
                                            options={stateList.countryList}
                                            placeholder="Select Country"
                                            multiple
                                            disableCloseOnSelect
                                            blurOnSelect
                                            //limitTags={1}
                                            getOptionSelected={(options, value) => options.name === value.name}
                                            control={control}
                                            input='managedCountry'
                                            value={state.managedCountry}
                                            handleChangeSelect={handleChangeSelect}
                                            errMessage={'Please Select Country'}
                                            error={errors.managedCountry}
                                            defaultValue={state.managedCountry}
                                        />
                                    </div>
                                </div>
                            </Row>


                            <Card>
                                <CardHeader>
                                    <h3 className="text-center">User Notification Preference</h3>
                                    <p className="text-center">Select the modules that the user will be able to receive notification.Tick the checkbox to give privileges</p>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col lg="6">
                                            <Tree
                                                checkable
                                                onExpand={onExpand}
                                                expandedKeys={expandedKeys}
                                                autoExpandParent={autoExpandParent}
                                                onCheck={onCheck}
                                                checkedKeys={checkedKeys}
                                                onSelect={onSelect}
                                                selectedKeys={selectedKeys}
                                                treeData={preference.leftData}
                                            />
                                        </Col>
                                        <Col lg="6">
                                            <Tree
                                                checkable
                                                onExpand={onExpand1}
                                                expandedKeys={expandedKeys1}
                                                autoExpandParent={autoExpandParent1}
                                                onCheck={onCheck1}
                                                checkedKeys={checkedKeys1}
                                                onSelect={onSelect1}
                                                selectedKeys={selectedKeys1}
                                                treeData={preference.rightData}
                                            />
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>





                            <div className="text-center mt-4">
                                <button type="submit" className="btn btn-primary" >Save</button>
                                {/* <button className="btn btn-warning" >Cancel</button> */}
                            </div>
                        </form>
                    </CardBody>
                </Card>}
        </div>
    );
};

export default CreateBoUser;