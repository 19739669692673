import React, { useState } from "react";
// react library for routing
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";
// reactstrap components
import {
  Collapse,
  NavItem,
  NavbarBrand,
  Navbar,
  Nav,
  Button,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { FormAutocomplete } from "_components/FormElements/FormInput";
import { FormAutocompleteListBox } from "_components/FormElements/FormInput";
const Filter = (props) => {
  const { handleChangeSelect, toggleSidenav, sidenavOpen, resetFilterValue,handleFilter ,showFilterHeader} = props

  const { control } = useForm({ mode: 'onBlur' });
  const [collapseOpen, setCollapseOpen] = useState(false);
  const { countryOption, product_cat_Option, company_type_Option, is_OEM_Option, plan_Option, tag_Option, product_family_option, brand_Option, distProfile_Option, reseller_Option, retailer_Option, profile_Option, event_option ,event_manage_options} = props.filterOption;
  const { Company_type, is_OEM, country, product_Categories, plan, tag, product_families, brand, dist_profile, reseller_profile, retailer_profile, pending_profile, event_attd, event_manage} = props.filterValue;
  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes

  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
  };
  const closeFilter = () => {
    if (document.getElementById("filter").classList.contains("g-sidenav-pinned")) {
      document.getElementById("filter").classList.remove("g-sidenav-pinned");
      document.getElementById("filter").classList.add("g-sidenav-hidden");
      document.getElementById("filter-hide").classList.add("filter-hide-width");
    }
  };

  // let navbarBrandProps;

  const scrollBarInner = (
    <div className="scrollbar-inner">
      {/* style={{height: "60px"}}  */}

      
      <div className="sidenav-header d-flex justify-content-end ">
        {/* <NavbarBrand> */}
        {showFilterHeader?<>
          <div className='py-3 d-flex'>
            <Button style={{margin:"0",padding:"0 5px",height:"40px"}} className="btn-icon" color="secondary" type="button" onClick={resetFilterValue}>
              <span className="btn-inner--icon">
                <i className="fas fa-sync-alt"></i>              </span>
              <span className="btn-inner--text">Clear Filter</span>
            </Button>

          
          </div>
          <div className='py-3'>
            <Button style={{padding:"0 10px",height:"40px",marginLeft:'10px'}}  color="primary" type="button" onClick={handleFilter}>Apply</Button>
          </div>
        </>
        :<></>
    }
        {/* </NavbarBrand> */}

        <div className="">
          <div
            className={classnames("sidenav-toggler d-block", {
              active: sidenavOpen,
            })}
            onClick={closeFilter}
          >
            {/* <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div> */}
            <div>
              <i className="fa fa-times" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-inner">
        <Collapse navbar isOpen={true}>
          <hr className="my-3" />
          <Nav navbar>

            {/* Filter for Company Type */}
            <NavItem>
              <div id="filter" className="row">
                <div className="col-lg-12">
                  <div className="form-group" >
                  <FormAutocomplete
                      id="Company_type"
                      name="Company_type"
                      label="Company type"
                      options={company_type_Option}
                      placeholder="Select Company type"
                      multiple
                      disableCloseOnSelect
                      limitTags={2}
                      control={control}
                      input='Company_type'
                      value={Company_type}
                      handleChangeSelect={handleChangeSelect}
                    />
                  </div>
                </div>
              </div>
            </NavItem>

            {/* Filter for OEM */}

            <NavItem>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <FormAutocomplete
                      id="is_OEM"
                      name="is_OEM"
                      label="B2B/B2C"
                      options={is_OEM_Option}
                      placeholder="Select  Is B2B/B2C"
                      // multiple
                      disableCloseOnSelect
                      // limitTags={2}
                      control={control}
                      input='is_OEM'
                      value={is_OEM}
                      handleChangeSelect={handleChangeSelect}
                    />
                  </div>
                </div>
              </div>
            </NavItem>

{/* Filter for Family */}
<NavItem>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <FormAutocomplete
                      id="productFamilies"
                      name="productFamilies"
                      label="Product Families"
                      options={product_family_option}
                      placeholder="Select Product Family"
                      multiple
                      disableCloseOnSelect
                      limitTags={2}
                      control={control}
                      input='product_family'
                      value={product_families}
                      handleChangeSelect={handleChangeSelect}
                    />
                  </div>
                </div>
              </div>
            </NavItem>

            {/* Filter for Category */}

            <NavItem>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <FormAutocomplete
                      id="product_Categories"
                      name="product_Categories"
                      label="Product Categories"
                      options={product_cat_Option}
                      placeholder="Select Product Categories"
                      multiple
                      disableCloseOnSelect
                      limitTags={2}
                      control={control}
                      input='product_Categories'
                      value={product_Categories}
                      handleChangeSelect={handleChangeSelect}
                    />
                  </div>
                </div>
              </div>
            </NavItem>


            {/* Filter for Country */}
            <NavItem>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <FormAutocomplete
                      id="country"
                      name="country"
                      label="Country"
                      options={countryOption}
                      placeholder="Select Country"
                      multiple
                      disableCloseOnSelect
                      limitTags={2}
                      control={control}
                      input='country'
                      value={country}
                      handleChangeSelect={handleChangeSelect}
                    />
                  </div>
                </div>
              </div>
            </NavItem>

            {/* Filter for Roles */}

            <NavItem>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <FormAutocomplete
                      id="plan"
                      name="plan"
                      label="Subscription Plan"
                      options={plan_Option}
                      placeholder="Select Subscription Plan"
                      // multiple
                      disableCloseOnSelect
                      limitTags={2}
                      control={control}
                      input='plan'
                      value={plan}
                      handleChangeSelect={handleChangeSelect}
                    />
                  </div>
                </div>
              </div>
            </NavItem>

            {/* Filter for Tag */}

            <NavItem>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <FormAutocomplete
                      id="tag"
                      name="tag"
                      label="Tag Filter"
                      options={tag_Option}
                      placeholder="Select Tag"
                      //multiple
                      disableCloseOnSelect
                      limitTags={2}
                      control={control}
                      input='tag'
                      value={tag}
                      handleChangeSelect={handleChangeSelect}
                    />
                  </div>
                </div>
              </div>
            </NavItem>

            {/* Filter for Brand */}
            {/* Filter for Pending Profile */}

            <NavItem>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <FormAutocompleteListBox
                      id="brand"
                      name="brand"
                      label="Brand Filter"
                      options={brand_Option}
                      placeholder="Select Brand"
                      multiple
                      disableCloseOnSelect
                      limitTags={2}
                      control={control}
                      input='brand'
                      value={brand}
                      handleChangeSelect={handleChangeSelect}
                    />
                  </div>
                </div>
              </div>
            </NavItem>

            {/* Filter for Distprofile */}
            <NavItem>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <FormAutocomplete
                      id="distProfile"
                      name="distProfile"
                      label="Distributor Profile"
                      options={distProfile_Option}
                      placeholder="Distributor Profile"
                      multiple
                      disableCloseOnSelect
                      limitTags={2}
                      control={control}
                      input='distProfile'
                      value={dist_profile}
                      handleChangeSelect={handleChangeSelect}
                    />
                  </div>
                </div>
              </div>
            </NavItem>

            
            {/* Filter for Retailerprofile */}
            <NavItem>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <FormAutocomplete
                      id="retProfile"
                      name="retProfile"
                      label="Retailer Profile"
                      options={retailer_Option}
                      placeholder="Retailer Profile"
                      multiple
                      disableCloseOnSelect
                      limitTags={2}
                      control={control}
                      input='retailerProfile'
                      value={retailer_profile}
                      handleChangeSelect={handleChangeSelect}
                    />
                  </div>
                </div>
              </div>
            </NavItem>

            
            {/* Filter for Resellerprofile */}
            <NavItem>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <FormAutocomplete
                      id="resProfile"
                      name="resProfile"
                      label="Reseller Profile"
                      options={reseller_Option}
                      placeholder="Reseller Profile"
                      multiple
                      disableCloseOnSelect
                      limitTags={2}
                      control={control}
                      input='resellerProfile'
                      value={reseller_profile}
                      handleChangeSelect={handleChangeSelect}
                    />
                  </div>
                </div>
              </div>
            </NavItem>

            <NavItem>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
            <FormAutocomplete
                      id="pending_profile"
                      name="pending_profile"
                      label="Profile Pending"
                      options={profile_Option}
                      placeholder="Select remainder"
                      //multiple
                      disableCloseOnSelect
                      limitTags={2}
                      control={control}
                      input='pending_profile'
                      value={pending_profile}
                      handleChangeSelect={handleChangeSelect}
                    />
                    </div>
                </div>
              </div>
            </NavItem>

            {/* event attendance filter */}
            <NavItem>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
            <FormAutocomplete
                      id="event_attd"
                      name="event_attd"
                      label="Event Attendance"
                      options={event_option}
                      placeholder="Select Event options"
                      //multiple
                      disableCloseOnSelect
                      limitTags={2}
                      control={control}
                      input='event_attd'
                      value={event_attd}
                      handleChangeSelect={handleChangeSelect}
                    />
                    </div>
                </div>
              </div>
            </NavItem>
            <NavItem>
              <div id="filter" className="row">
                <div className="col-lg-12">
                  <div className="form-group" >
                    <FormAutocomplete
                      id="event_manage"
                      name="event_manage"
                      label="Events"
                      options={event_manage_options}
                      placeholder="Select Event type"
                      multiple
                      disableCloseOnSelect
                      limitTags={2}
                      control={control}
                      input='event_manage'
                      value={event_manage}
                      handleChangeSelect={handleChangeSelect}
                    />
                  </div>
                </div>
              </div>
            </NavItem>
            {showFilterHeader?
            <NavItem>
              <div className="row justify-content-end">
              
                <div className="col-lg-6">

               <Button style={{width:"100%"}} color="primary" type="button" onClick={handleFilter}>Apply</Button>
                </div>
              </div>
            </NavItem>
            :<></>}
          </Nav>
          <hr className="my-3" />
        </Collapse>
      </div>
    </div>
  );
  return (
    <Navbar
      id="filter-hide"
      className={
        "sidenav navbar-vertical navbar-expand-xs navbar-light bg-white filter-hide-width fixed-right"
      }
    >
      {navigator.platform.indexOf("Win") > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )}
    </Navbar>
  );
}

Filter.defaultProps = {
  toggleSidenav: () => { },
  sidenavOpen: false,
  rtlActive: false,
};

Filter.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool,
};

export default Filter;
