import React, { useState, useEffect } from "react";
import { Card,CardBody,CardHeader, Container,FormGroup ,Input,InputGroupAddon,InputGroupText,InputGroup,Col,Row } from "reactstrap";
import {BrandCard,ProfileCard} from "views/Hoc/BrandCard";
import {QuillEditor,FormInput,FormAutocomplete,SingleAutocompleteGrouping,FormTextArea,FormAutocompleteGrouping,AddFieldWithPopUpAutocomplete} from "_components/FormElements/FormInput";
import {InputLabel,Select,TextField,FormControl,InputAdornment} from '@material-ui/core';
import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import AddBrand from "views/Hoc/Dialog/AddBrand";
import axios from "axios";
import { commonService } from '_services/common.service';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { ModalSpinner } from "views/Hoc/Spinner";
import {profileEditSchema} from '../profile/validation';
var _ = require('lodash');

const Profileedit = (props) => {
    const { closeDrawer, changedData, userData} = props;
    // var userData = JSON.parse(localStorage.getItem('CHuser'));

    let typeId=userData?.channelTypeId;

    const [state, setState] = useState({
        profile: '',
        productBrand : [],
        brand : [],
        brandList:[],
        brandOption : [],
        productGroup:[],
        productValue : [{childid: "4",childname: "AV accessories", groupid: "1", groupname: "AV", status: true}],
        productFamilies : [],
        DistributorProfileOption:[],
        DistributorProfileValue: [],
        ChannelProfileValue : [{childid: "3",childname: "broadliner",status:true}],
        ChannelProfileOption : [],
        profileTypeId: '',
        RetailerOptions: [],
        DistributorOptions: [],
        testRetailerList: [],
        testDistributorList: [],
        speciality: [],
        specialityOptions: [],
    
        brandListUser: [], //dont worry about it. it's dummy but dont remove it.
      })
      const [brandName, setBrandName] = useState('');
      const [openadd, openAddnew] = useState(false);
      const [loading, setLoading] = useState(false);
      
      const selectedItem=(gname,check,optionName,optionValue) => {    
        let opt=[];    
        //console.log("check:",check,gname);
        if(check==true)
        {    
          state[optionName].map((item) => {      
      
                if(item.groupname==gname||item.status==true)
                {
                  item.status=check;      
                  opt.push(item);
                }     
          });
        }
        if(check==false)
        {  
          console.log("check:",check);
          state[optionName].map((item) => {      
              if(item.groupname==gname&&state[optionValue].length>0)
                  item.status=check;                          
              // if(state.productValue.length==0)
              //     item.status=false;
          });
      
          opt=state[optionValue].filter((item) => item.groupname!=gname);      
          opt.map(option => option.status=true);
        }
        
        state[optionValue]=opt;
        setState({...state, [optionValue]:state[optionValue]});    
        setState({...state, [optionName]:state[optionName]});    
      
        // if(optionValue='ChannelProfileValue' && state.ChannelProfileValue.length==0)
        //       errors.relevantChannelProfiles=true;
        // else 
        if(optionValue='productValue' && state.productValue.length==0)
              errors.productCat=true;
      }


      const deleteBrand = (id) =>{    
        const filteredList = state.brandList.filter((item) => item.id !== id);    
        state.brandList=filteredList;
        setState({...state, brandList:state.brandList});
       if(state.brandList.length==0)
        {
          setState({...state, brandValue: []});
          errors.Brands={message: 'Please select a Brand', type:'required'};  
        }
      }

      const { register, handleSubmit, control, errors} = useForm({      
        mode:'onBlur',  
        //resolver: yupResolver(profileEditSchema),
      });


      console.log('errros', errors);
      
      const handleChangeSelect =async (input,value) => {
        if(input=='productFamily'){        
          state.productFamilies.map((item) => {      
            if(value.length==0)
              item.status=false;
            else
            {
              // value.map(v => {          
              //   // if(v.childname!=item.childname)
              //   //   item.status=false;                 
              //    if(v.childname==item.childname)            
              //     item.status=true;                       
                
              // });      
              if(value.filter(e => e.childname == item.childname).length)
              item.status = true;
            else
              item.status = false;
            }
          });
      
          value.map(option => option.status=true);
          state.productValue= value
          setState({...state, productFamilies:state.productFamilies});
          setState({...state, productValue:state.productValue});
    
          // state.productValue= value
          // setState({...state, productValue:state.productValue});
          if(state.productValue.length==0)
            errors.productValue=true;
    
          let Group=_.uniq(state.productValue.map(x => x.groupid));
          state.groupCount=Group.length;
          setState({...state,groupCount:state.groupCount});     
    
    
          
        }
        else if(input=='brand' || input=='brands'){
          if(input=='brands')
            input = 'brand';
          if(value.name === 'Add Brand' && value.id === 0){
            setBrandName('');
            openAdd(true);
            return <></>;
          }
          if(value.hasOwnProperty('isNew')){
            state[input]=value;
          }else{
            value.isNew='0';
            state[input]=value;
          }
          
          setState({...state, [input]:state[input]});
          let temp = state[input];
          temp = state[input+'List'].filter(x => x.id == temp.id);
          if(temp.length){
            console.log('already entered');
          }else{
            console.log('no entry')
            if(value.isNew == '1')
              state[input+'ListUser'].push(state[input]);
            state[input+'List'].push(state[input]);
            setState({...state, [input+'List']:state[input+'List'], [input+'ListUser'] : state[input+'ListUser']});
          }
          
          console.log("brandList:",state[input+'list']);
        }
        else if(input=='relevantChannelProfile'){
          state.ChannelProfileOption.map((item) => {      
            if(value.length==0)
              item.status=false;
            else
            {
              // value.map(v => {          
              //   // if(v.childname!=item.childname)
              //   //   item.status=false;                 
              //    if(v.childname==item.childname)            
              //     item.status=true;                       
                
              // });      
              if(value.filter(e => e.childname == item.childname).length)
              item.status = true;
            else
              item.status = false;
            }
          });
    
          value.map(option => option.status=true);
          state.ChannelProfileValue= value
          setState({...state, ChannelProfileOption:state.ChannelProfileOption});
          setState({...state, ChannelProfileValue:state.ChannelProfileValue});
    
          if(state.ChannelProfileValue.length==0)
            errors.relevantChannelProfiles=true;
          // state.ChannelProfileValue= value
          // setState({...state, ChannelProfileValue:state.ChannelProfileValue});
        }
        else if(input=='relevantRetailerProfile'){
          state.ChannelProfileValue= value
          setState({...state, ChannelProfileValue:state.ChannelProfileValue});
        }
        else{
          if(input=="speciality"&&value.length<=3)      
          setState({...state, [input]:value});
          else if(input!="speciality")
          setState({...state, [input]:value});
    
        }
      }
      
      const openAdd = async(input,value) => {   
    //console.log('opneadd',opneadd);
    await setBrandName(input);
    openAddnew(true);
    }

    const closeModalPopup=()=>{
      setBrandName('');
      openAddnew(false);
    }

      useEffect(()=>{
        if(props.visible)
        getCompanyData();
      }, [props.visible]);

      let data = {
        oldChannelId: userData.oldChannelId,
        channelTypeId: userData.channelTypeId
      }

      const getCompanyData = async() => {
        setLoading(true);
        await axios.post('/channelAdmin/getOldInitialProfile', data).then(res => {
      
          state.brandOption=res.data.productBrand;
          state.productFamilies=res.data.productGroup;
          state.brandOption.push({name: 'Add Brand', id: 0, doc_id: '0', documentpath: 'https://d35w8j22j5uly8.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'})
  
          if(userData?.channelTypeId==2 || userData?.channelTypeId==3){
            let key=['Retailer', 'Reseller'];
            state.ChannelProfileOption=res.data.profileList.filter(i => key.includes( i.groupname ));
          }
          if(userData?.channelTypeId==4)
          {
          let key=['Retailer'];
          state.ChannelProfileOption = res.data.profileList.filter( i => key.includes( i.groupname ) );
          }
          if(userData?.channelTypeId==3)
          {
            let key=['Distributor'];
            state.DistributorProfileOption = res.data.distProfile.filter( i => key.includes( i.groupname ) );
          }
  
          console.log('channel profile', state.ChannelProfileOption);

          setState({...state, ChannelProfileOption:state.ChannelProfileOption});
          setState({...state, DistributorProfileOption:state.DistributorProfileOption});
            
        }).catch(err => {
          commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'/setting/getInitialProfile'},'/error')
        })

      //   await axios.get('/channelAdmin/channelProfileOld/'+userData.oldChannelId).then(res => {
      //     state.specialityOptions = res.data.specialityOptions.map(x => ({id: x.productGroupId, name: x.productGroup}));
      //     state.speciality = res.data.channelSpeciality.map(x => ({id: x.productGroup.id, name: x.productGroup.name}));
      //     state.frenchTech = res.data.isFrenchTech == '1' ? 'Yes' : 'No';
      //     if(res.data.profileType.length){
      //       state.DistributorProfileValue = Object.assign(res.data.profileType[0], {firstLetter: res.data.profileType[0].groupname});
      //       state.DistributorProfileOption = state.DistributorProfileOption.map(x => {
      //         if(x.childid == state.DistributorProfileValue.childid)
      //           return {...x, status: true}
      //         else
      //           return x;
      //       })
      //     }
      //     setState({...state, speciality: state.speciality, specialityOptions: state.specialityOptions});
      // })

      await axios.get('/channelAdmin/getOldChannelBrand/'+userData.oldChannelId).then(res =>{  
        state.brandList=res.data.brandList;                
      }).catch(err => {
        commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'/setting/getChannelBrand'},'/error')                
      });

      await axios.get('/channelAdmin/getOldProducts/'+userData.oldChannelId).then(res =>{                  
        if(res.data.productGroup.length && state.productFamilies.length){
          state.productFamilies = state.productFamilies.map(x => {
            res.data.productGroup.map(y => {
              if(x.groupid == y.groupid && x.childid == y.childid){
                x.status = true;
              }
            })
            return x;
          })
        }
  
        state.productValue=res.data.productGroup;
        setState({...state, productFamilies: state.productFamilies, productValue: state.productValue});
        
      }).catch(err => {
        commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'/setting/getChannelProduct'},'/error')                
      });

      await getChannelProfile();
      setLoading(false);
      }

      const getChannelProfile = async() => {
        const apiUrl = '/channelAdmin/channelProfileOld/';
         await axios.post(apiUrl, data).then(res =>{  
          state.specialityOptions = res.data.specialityOptions?.map(x => ({id: x.productGroupId, name: x.productGroup}));
          state.speciality = res.data.channelSpeciality?.map(x => ({id: x.productGroup?.id, name: x.productGroup?.name}));
   
          if(typeId == '3'){
            if(res.data.profileType.length){
              state.DistributorProfileValue = Object.assign(res.data.profileType[0], {firstLetter: res.data.profileType[0].groupname});
              state.DistributorProfileOption = state.DistributorProfileOption.map(x => {
                if(x.childid == state.DistributorProfileValue.childid)
                  return {...x, status: true}
                else
                  return x;
              })
            }
          }
          

          state.ChannelProfileValue=userData.channelTypeId=='4'?res.data.profile.length ? res.data.profile[0] : [] :res.data.profile;
            //  state.listOfServicesOption = res.data.serviceListOptions;
            //  state.listOfServiceValue = res.data.serviceListValues;
   
   //brandList:res.data.brand,productValue:res.data.product,storeCount:res.data.storeCount,no_of_skus:res.data.no_of_skus,no_of_staffs:res.data.empCnt.replace(/\s/g, "").trim(),annualTurnOver:res.data.turnoverRange.replace(/\s/g, "").trim(),
   //sales: res.data.sales?+res.data.sales:'', marketing: res.data.marketing?+res.data.marketing:'', technical: res.data.technical?+res.data.technical:'',
   //purchase: res.data.purchase?+res.data.purchase:'',domesticRevenue:typeId=='4'?res.data.turnoverOnStore:res.data.revD,internationalRev:typeId=='4'?res.data.onlineStore:res.data.revI,
             setState({...state,ChannelProfileValue:state.ChannelProfileValue,
               profileTypeId: +res.data.profileTypeId,
             });
             //console.log('tradestate',state);
             // getTradeLoc();
             // setLoading(false);
           
           
         }).catch(err => {
           //ToastsStore.error('Company Not Found!')
           commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'/setting/tradeinfo'},'/error')
         });
     }

     const save = async() => {
       setLoading(true);
       try{
        saveTrade();
       }
       catch(err){
        setLoading(false);
       }
     }

     //saving tradeinfo form merged fields data
const saveTrade = async() => {
  try{    
  setLoading(true);

    let productFamilies = [];
    let uniq = _.uniq(state.productValue.map(x => +x.groupid));
    uniq.map(x => {
      productFamilies.push({id: +x});
    })
    let productCategories = [];
    state.productValue.map(x => {
      productCategories.push({
        productFamilId: +x.groupid,
        productCategoriesId: +x.childid
      })
    })

    let specialityValue = state.speciality.map(x => ({specialityId: +x.id}));

    let data = {};
    if(typeId == '2'){
      data = {
        profileTypeId : +state.profileTypeId,
        brand : [].concat(state.brandList.map(x => ({id: +x.id, name: x.name, documentPath: x.documentpath, isNew: x.isNew}))),
      }  
    }else if(typeId == '3'){
      data = {
        profileTypeId : +state.DistributorProfileValue.childid,
        brand : [].concat(state.brandList.map(x => ({id: +x.id, name: x.name, documentPath: x.documentpath, isNew: x.isNew}))),
        ...specialityValue && {distributorSpeciality : specialityValue},
      }  
    }else if(typeId == '4' || typeId == '5'){
      data = {
        profileTypeId: +state.ChannelProfileValue.childid,
      }
    }

      
let finalData = {
  user: {oldChannelId : userData.oldChannelId},
  channelType: +typeId,
  productFamilies: productFamilies,
  productCategories: productCategories,
  customerProfiles: (typeId==4||typeId==5) ? [{profileTypeId:+state.ChannelProfileValue.childid,profileTypeClassificationId:+state.ChannelProfileValue.profiletypeclassificationid}] :[]//.concat(state.ChannelProfileValue.map(x => {return {profileTypeId:+x.childid,profileTypeClassificationId:+x.profiletypeclassificationid} })),
}

   finalData = typeId=='2' ? Object.assign(finalData, {tradeInformationVendor: data}) : typeId == '3' ?  Object.assign(finalData, {tradeInformationDistributor: data}) : Object.assign(finalData, {tradeInformationRetailer: data})

   let result=await axios.post('/channelAdmin/editProfileOld', finalData).catch(err=> {
    ToastsStore.error('Something Went Wrong!');
    commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'/setting/editProfileSave'},'/error')
     setLoading(false)
   });
   //console.log('saveresuult', result);
   if(result){      
    ToastsStore.success('Saved Successfully!');
    let brand = [];

    if(typeId != 4 &&  typeId != 5){
      state.brandList.map(x=>{
        brand.push({brandName:x.name , document:{documentUrl:x.documentpath}})
      });
    }
    

    let data={
      brand:brand,
      ChannelProfileValue:( typeId!='4' && typeId!='5' && state.ChannelProfileValue.length > 0) ? state.ChannelProfileValue.map(x=>x.childname) : [],
      speciality:typeId==='3'?state.speciality.map(x=>x.name):[],
      productValue:state.productValue.map(x=>x.childname),
      ...(typeId!='2' && {profileType:typeId=='3' ? state.DistributorProfileValue.childname : state.ChannelProfileValue.childname})
    }
    changedData(data,3);
    closeDrawer();
    setLoading(false);
    // getCompanyData();
    // getTradeInfo();
    // ToastsStore.success('Saved Successfully!');
    // return result;
   }

  }
  catch(err)
  {        
    ToastsStore.error('Something Went Wrong!');
    setLoading(false);    
    commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'updateTradeInfo'},'/error');       
  }       
 
}

    console.log('statet', state);

    return (
      <>
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
      {loading ? <ModalSpinner /> :<div>
         <form noValidate onSubmit={handleSubmit(save)}>
        <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="typeId" ref={register} name="typeId" value={typeId} control={control} />
        <div>
            {(typeId==2 || typeId==3) && (
            
            <div className="col-lg-12 ">
            <h4 >Brands *</h4>
            <div className="form-group mt-3">        
           
            {state.brandList[0] && <BrandCard  name="BMW" deleteBrand={deleteBrand} brandList={state.brandList}  img={"https://d35w8j22j5uly8.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"}/>}

            <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="brandList" name="brandList" ref={register} value={state.brandList[0] ? 'data' : ''} /> 
{/* Autocomplete with image */}
<AddFieldWithPopUpAutocomplete
        limitTags={2}
        name="Brands"
        id="Brands"
        options={state.brandOption}
        defaultValue={state.brandValue}
        value={state.brandValue}
        getOptionLabel={option => option.name}
        renderOption={option => (
         <>
         <div style={{display: '-webkit-inline-flex',
    padding: '1px 6px',
    width: '75px',
    margin: '10px 5px 0 0',
    float: 'left',
    border: '2px solid #e7e7e7',
    borderRadius: '5px',
    height: '38px'}}>

            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  style={{maxWidth: '63px',backgroundColor: '#fff',
    maxHeight: '34px',
    height: 'auto',
    width: 'auto',
    margin: '0 auto',
    cursor: 'pointer'}} src={option.documentpath} />    </div>
          <span>
            {option.name}
          </span>
          </>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Brands" placeholder="Brands" />
        )}
        label="Brands *"
        placeholder="Brands"
        input="brand"
        control={control}
        register={register}
        errMessage={'Please Select a Brand'}
        error={errors.Brands&&!state.brandList[0]? errors.Brands : (errors.brandList && !state.brandList[0]) ? true : false}
        handleChangeSelect={handleChangeSelect}
        opneAdd={openAdd}
      />
      {brandName && <AddBrand aws_folderName="brandlogo" opneadd={openadd} closeModalPopup={closeModalPopup} brandName={brandName} handleChangeSelect={handleChangeSelect}/>}

                 </div>           
            </div>
            )}

{/* {(typeId == '2' || typeId == '3') && 
  <div className="col-lg-12 ">
                  <h4 >Retail Customer Profile *</h4>
            <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="relevantChannelProfiles" name="relevantChannelProfiles" ref={register} value={state.ChannelProfileValue.length?state.ChannelProfileValue[0].childname:''} /> 
            <div className="form-group">        
            <FormAutocompleteGrouping
              id="relevantProfiles"
              name="relevantProfiles"
              label="Channel Profiles * "
              multiple={true}
              Options={state.ChannelProfileOption}
              placeholder="Channel Profiles"                        
              input='relevantChannelProfile'              
              errMessage={'Please Select Channel Profile'}
              error={!errors.relevantChannelProfiles || state.ChannelProfileValue.length ?false:true}
              value={state.ChannelProfileValue}                            

              optionName='ChannelProfileOption'
              optionValue='ChannelProfileValue'
              defaultValue={state.ChannelProfileValue}
              selectedItem={selectedItem}

              handleChangeSelect={handleChangeSelect}
              control={control}
              register={register}
              //defaultValue={state.ChannelProfileValue}
              />
            
                 </div>           
            </div>
} */}


  {(typeId == 4 || typeId == 5) && (
                  <div className="col-lg-12 ">
                    <h3 style={{ fontSize: "1rem" }}>Your profile *</h3>
                    {/* <h4>Retailer Profiles *</h4> */}
                    <div className="form-group">
                      <SingleAutocompleteGrouping
                        id="relevantProfiles"
                        name="relevantRetailerProfiles"
                        label={typeId == 4?"Retailer Profiles * ":"Reseller Profiles * "}
                        multiple={false}
                        Options={state.ChannelProfileOption}
                        placeholder="Channel Profiles"
                        input="relevantRetailerProfile"
                        errMessage={typeId == 4?"Please Select Retailer Profiles":"Please Select Reseller Profiles"}
                        error={errors.relevantRetailerProfiles}
                        value={state.ChannelProfileValue}
                        handleChangeSelect={handleChangeSelect}
                        control={control}
                        register={register}
                        defaultValue={state.ChannelProfileValue}
                      />
                    </div>
                  </div>
                )}


{typeId == 3 && 
 
 <div className="col-lg-12 ">
                 <h4 >{typeId==3 && 'Distributor Profile and speciality *'}</h4>
           <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="profile" name="profile" ref={register} value={Object.keys(state.DistributorProfileValue).length?state.DistributorProfileValue.childname!='broadliner' ? '1' : '0' : ''} /> 
           <div className="form-group">        
           <FormControl variant="outlined" style={{width:"100%"}}>
           <SingleAutocompleteGrouping
             id="DistributorProfiles"
             name="DistributorProfiles"                        
             label="Distributor Profiles * "                            
             multiple={false}
             Options={state.DistributorProfileOption}
             placeholder="Channel Profiles"                        
             input='DistributorProfileValue'              
             errMessage={'Please Select Distributor Profile'}
             errMessage='Please Select Distributor Profile'
             error={(!errors.profile || Object.keys(state.DistributorProfileValue).length) ? false : true}
             value={state.DistributorProfileValue}
             defaultValue={state.DistributorProfileValue}
             handleChangeSelect={handleChangeSelect}
             control={control}
             register={register}
             />
           </FormControl>
                </div>           
           </div>  
 }

 {(typeId==3 && Object.keys(state.DistributorProfileValue).length != 0 && state.DistributorProfileValue.childname!='broadliner') && (
 <div className="col-lg-12 ">
                 <h4 >Speciality *</h4>
           <div className="form-group">        

         <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="speciality" name="speciality" ref={register} value={state.speciality.length?state.speciality[0].name:''} /> 
         <FormControl variant="outlined" style={{width:"100%"}}>
                 <FormAutocomplete
                       id="speciality1"
                       name="speciality1"
                       label="Search Speciality *"
                       options={state.specialityOptions}
                       placeholder="Select Speciality"
                       input='speciality'
                       errMessage={'Please Select a Speciality'}
                       error={!errors.speciality || state.speciality.length ?false:true}
                       value={state.speciality}
                       defaultValue={state.speciality}
                       handleChangeSelect={handleChangeSelect}
                       control={control}                 
                       multiple
                       disableCloseOnSelect
                       blurOnSelect
                       getOptionSelected={(options, value) => options.name === value.name}
                       />
           </FormControl>
           </div>           
           </div>                      
        )} 

<div className="col-lg-12 ">
                  <h4 >Select Product Families *</h4>
            <div className="form-group">        

          <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="productCat" name="productCat" ref={register} value={state.productValue.length?state.productValue[0].childname:''} /> 
                  <FormAutocompleteGrouping
                        id="ProductGroup"
                        name="ProductGroup"
                        label="Product Families are you selling *"
                        Options={state.productFamilies}
                        placeholder="Select Product Families"
                        input='productFamily'
                        errMessage={'Please Select a Product'}
                        error={!errors.productCat || state.productValue.length ?false:true}
                        //error={errors.ProductGroup}
                        //defaultValue={productFamilies}
                        value={state.productValue}

                        optionName='productFamilies'
                        optionValue='productValue'
                        defaultValue={state.productValue}
                        selectedItem={selectedItem}

                        handleChangeSelect={handleChangeSelect}
                        control={control}
                        register={register}                        
                        />
            </div>           
            </div> 
        </div>
        <div className="text-center mt-4">
            <button type="submit" className="btn btn-primary" >Save</button>
        </div>
        </form></div>}
        </>
    );
};

export default Profileedit;