import React, { useEffect, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
  Button, Modal, UncontrolledTooltip
} from "reactstrap";
import { ProfileUploader } from '../ProfileUpload';
import { commonService } from '_services/common.service';
import { Spinner } from 'views/Hoc/Spinner';

import { FormTextArea, FormInput, FormAutocomplete, SingleAutocompleteGrouping, FormRadio, FormCheckBox, FormAutocompleteGrouping } from "_components/FormElements/FormInput";
import { useForm } from "react-hook-form";
import {
  FormControl,
  InputAdornment,
} from "@material-ui/core";
import axios from "axios";
import { ToastsContainer, ToastsStore } from 'react-toasts';

export const EditEventUserPackage = (props) => {
  const { opneadd, closeModalPopup, brandName } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { register, handleSubmit, control, errors, watch } = useForm({
    mode: "onBlur",
    // resolver: yupResolver(retailProfileSchema)
  });

  let defaultState = {
    contactId: props.data.contactId,
    channelId: props.data.channelId,
    eventId: props.data.eventId,
    cType: props.data.cType,
    pType: props.data.pType,

    packageId: props.data.packageId,
    selectedPackage: props.data.selectedPackage,
    package: [],
    isRegisteredUser: props.data.isRegisteredUser,


  };

  const [state, setState] = useState(defaultState);

  const [packageOptions, setPackageOptions] = useState([]);

  useEffect(() => {

    console.log("selectedPackage:", state.selectedPackage, state.packageId)
    let currentPack = state.selectedPackage.find(pack => pack.id == state.packageId);

    // if (state.cType != 2) {
    // if (state.isRegisteredUser) {
    setPackageOptions(state.selectedPackage.filter(x => x.packageUser == currentPack.packageUser && x.packageType == currentPack.packageType));
    state.package = state.selectedPackage.filter(x => x.id == state.packageId)[0];
    // } else {
    //   setPackageOptions(state.selectedPackage.filter(x => x.packageUser == 2));
    //   state.package = state.selectedPackage.filter(x => x.id == state.packageId)[0];
    // }

    // } else {
    //   setPackageOptions(packageDropdownOptions2)
    //   state.package = packageDropdownOptions2.filter(x => x.id == state.packageId)[0];
    // }
    setState({ ...state });
    console.log('state', state, packageOptions)
  }, [opneadd]);



  console.log('state', state);


  const save = async () => {

    if (state.package?.id) {
      let data = {
        contactId: state.contactId,
        channelId: state.channelId,
        eventId: state.eventId,
        pType: state.pType,
        cType: state.cType,
        packageId: state.package.id

      }

      let result = await axios.post('channelAdmin/eventPackageChange', data).then(res => res).catch(err => ToastsStore.error('something went wrong...'))

      if (result)
        props.save('hllo');

      closeModalPopup();
    } else {
      ToastsStore.error('Please Select Package');
    }



  }

  const closeModalPopups = () => {
    setState({});
    closeModalPopup();
  }

  const handleChange = input => e => {
    setState({ ...state, [input]: !state[input] });
  }

  const handleChangeSelect = async (input, value) => {

    state[input] = value;
    setState({ ...state });

  };

  console.log('state', state);

  return (
    <div>
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <Modal
        className="modal-dialog-centered Reorderdialog"
        isOpen={opneadd} >
        <div className="modal-header">
          <h3 className="text-center">Event Users Package Manage</h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModalPopups}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        {state.hasOwnProperty('channelId') && <div className="modal-body">

          <div className="row">
            <div className='col-6'>
              <h4>Event Package</h4>
            </div>

            <div className="col-6">
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <FormAutocomplete
                  id="eventPack"
                  name="eventPack"
                  label="Event Package"
                  options={packageOptions}
                  placeholder="Select Package"
                  disableCloseOnSelect
                  blurOnSelect
                  limitTags={1}
                  control={control}
                  input="package"
                  getOptionSelected={(options, value) => options.name === value.name}
                  value={state.package}
                  handleChangeSelect={handleChangeSelect}
                  // errMessage={"Please Select Country"}
                  // error={errors.country}
                  defaultValue={state.package}
                />
              </FormControl>
            </div>
          </div>


        </div>


        }

        <div className="modal-footer">

          <Button
            color="primary"
            data-dismiss="modal"
            type="button"
            onClick={save}
          >
            Save
          </Button>

          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={closeModalPopups}
          >
            Close
          </Button>


        </div>
      </Modal>
    </div>
  );
}
export default EditEventUserPackage;

const packageDropdownOptions = [
  {
    id: 201,
    name: "Hosted Buyer"
  },
  {
    id: 202,
    name: "Invited Buyer"
  },
  {
    id: 203,
    name: "Visiting Delegate"
  },
]

const packageDropdownOptions1 = [
  {
    id: 204,
    name: "Extra Delegate Shared Room"
  },
  {
    id: 205,
    name: "Extra Delegate Single Room"
  },
]

const packageDropdownOptions2 = [
  {
    id: 301,
    name: "Vendor Pack"
  }
]