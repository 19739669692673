import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useForm } from "react-hook-form";
import { ListGroupItem, ListGroup } from 'reactstrap';
import { commonService } from '_services/common.service';
import { FormAutocomplete } from "_components/FormElements/FormInput";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Myprodfilter = (props) => {

  const { activeTab, handleChangeSelect, filterValueState, resetFilterValue, searchOption } = props;
  const [filterOptionState, setFilterOption] = useState({
    PositionOption: [{ name: 'MID', id: 'Mid' }, { name: 'HIGH', id: 'High' }], Brand_Option: [], Profile_Option: [], Location_Option: [], Family_Option: [],
    Vendor_Option: [], Category_Option: [], tags: [{ name: 'Home page visibility', id: '1' }, { name: 'Featured product visibility', id: '2' }],
    hot_option: [
      { id: '1', name: 'Eco Friendly Gadgets' }, { id: '2', name: 'Work from Home Gadgets' }, { id: '3', name: 'Life Style Gadgets' },
      { id: '4', name: 'French Tech' }, { id: '5', name: 'Taiwan Excellence' }
    ],
    business_option: [
      { id: '1', name: 'B2C' }, { id: '2', name: 'B2B' }, { id: '3', name: 'Both' }
    ],
  });
  const user = JSON.parse(localStorage.getItem('CHuser'));
  const { control } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    if (activeTab)
      getSearchAggregation();
  }, [activeTab])
  const getSearchAggregation = async () => {
    //  resetFilterValue();
    //resetFilterOptions();
    let data = await commonService.getServices('/product/allProductsAgg/' + activeTab);

    if (data) {
      let options = data.data;
      if (options) {
        filterOptionState.Brand_Option = options.brand;
        filterOptionState.Family_Option = options.family;
        filterOptionState.Category_Option = options.category;
        filterOptionState.Vendor_Option = options.vendors;

        setFilterOption({ ...filterOptionState });
        // searchOption(filterOptionState.Brand_Option = options.search)
      }
    }
    console.log('filterOptionState', filterOptionState);
  }

  // const toggle = tab => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // }
  //const [activeTab, setActiveTab] = useState('1');
  //const { control } = useForm({ mode:'onBlur' });

  const useStyles = makeStyles({
    root: {
      width: "100%",
    },
  });
  function valuetext(value) {
    return `${value}°C`;
  }

  const classes = useStyles();
  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const style = { height: '10rem' };

  const onChangedate = (date) => {
    if (date) {
      handleChangeSelect('createdAtLimit', [date[0].toISOString().slice(0, 10), date[1].toISOString().slice(0, 10)])
    } else {
      handleChangeSelect('createdAtLimit', null);
    }
  }

  const onChangedate1 = (date) => {
    if (date) {
      handleChangeSelect('updatedAtLimit', [date[0].toISOString().slice(0, 10), date[1].toISOString().slice(0, 10)])
    } else {
      handleChangeSelect('updatedAtLimit', null);
    }
  }

  return (
    <div className='fav-ser1 p-2'>
      <div className={classes.root}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Positioning</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={style}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Positioning"
                        name="Positioning"
                        label=""
                        options={filterOptionState.PositionOption}
                        placeholder="Type Positioning"
                        //multiple
                        // disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='position'
                        value={filterValueState.Position}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Vendors filter */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Vendors</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={style}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Vendors"
                        name="Vendors"
                        label=""
                        options={filterOptionState.Vendor_Option}
                        placeholder="Type Vendors Name"
                        multiple
                        // disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='vendor'
                        value={filterValueState.Vendor}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Brand</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={style}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Brand"
                        name="Brand"
                        label=""
                        options={filterOptionState.Brand_Option}
                        placeholder="Type Brand"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='brand'
                        value={filterValueState.Brand}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Category</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={style}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Category"
                        name="Category"
                        label=""
                        options={filterOptionState.Category_Option}
                        placeholder="Type Category"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='category'
                        value={filterValueState.Category_Option}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Family</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={style}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Family"
                        name="Family"
                        label=""
                        options={filterOptionState.Family_Option}
                        placeholder="Type Family"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='family'
                        value={filterValueState.Family_Option}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Tag Filter</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={style}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="tags"
                        name="tags"
                        label=""
                        options={filterOptionState.tags}
                        placeholder="Select Tag"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='tags'
                        value={filterValueState.tags}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Hot Topics</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.hot_option.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="HotTopics"
                        name="HotTopics"
                        label=""
                        //getOptionSelected={(options, value) => options.name === value.name}
                        options={filterOptionState.hot_option}
                        placeholder="Hot Topics"
                        //multiple
                        // disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='hot_topics'
                        value={filterValueState.hotValue}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Business Type</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.business_option.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="BusinessType"
                        name="BusinessType"
                        label=""
                        //getOptionSelected={(options, value) => options.name === value.name}
                        options={filterOptionState.business_option}
                        placeholder="Business Type"
                        //multiple
                        // disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='business_type'
                        value={filterValueState.businessType}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Date of Creation</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={dateOptions.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="createdAt"
                        name="createdAt"
                        label=""
                        //getOptionSelected={(options, value) => options.name === value.name}
                        options={dateOptions}
                        placeholder="Date of creation"
                        //multiple
                        // disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='createdAt'
                        value={filterValueState.createdAt}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>

                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionDetails>
            <DateRangePicker
              onChange={onChangedate}
              value={filterValueState.createdAtLimit}
              name="custom range"
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Last Updated</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={dateOptions.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="updatedAt"
                        name="updatedAt"
                        label=""
                        //getOptionSelected={(options, value) => options.name === value.name}
                        options={dateOptions}
                        placeholder="Last Updated"
                        //multiple
                        // disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='updatedAt'
                        value={filterValueState.updatedAt}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionDetails>
            <DateRangePicker
              onChange={onChangedate1}
              value={filterValueState.updatedAtLimit}
              name="custom range"
            />
          </AccordionDetails>
        </Accordion>


      </div>
    </div>
  );
}

export default Myprodfilter;

const dateOptions = [
  {
    id: '1',
    name: '30 days',
    value: 30
  },
  {
    id: '2',
    name: '60 days',
    value: 60
  },
  {
    id: '3',
    name: '90 days',
    value: 90
  },
  {
    id: '4',
    name: '180 days',
    value: 180
  }
]