import React, { useState, useEffect } from "react";
import { Card,CardBody,CardHeader, Container,Row} from "reactstrap";
import { useForm} from "react-hook-form";
import {FormInput,FormRadio,FormCheckBox} from "_components/FormElements/FormInput";
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProductTradeDetailsSchema } from '../profile/validation';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
  formControl1: {
    minWidth:"100%",
  },
  formControl: {
    minWidth:"100%",
  },
  // margin: {
  //   margin: theme.spacing(1),
  // },
}));

const TradeDetails = (props) => {
  const { saveTradeDetails, isAdd, params, productDetail }=props;
  const { register, handleSubmit, control, errors, watch } = useForm({      
    mode:'onBlur',  
    resolver: yupResolver(ProductTradeDetailsSchema),
  });
  const [isdata, setData] = useState(true);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const classes = useStyles();

  const [state, setState] = useState({
    productAttributes : '0',
    priceDetails : '',
    businessCurrency : '',
    msrp : '',
    unit: '',
    MOQ : '',
    moqUnit : '',
    no_of_items_in_box : '',
    max_qty_for_sample : '',
    no_limit : '',
    productPositioning : 'Entry',
    availability : 'Immediate',
    text_about_sample : '',
    variantDetails: '',
  });

  useEffect(() => {
    if(!isAdd && productDetail){
      setData(true);
      console.log('productDetail', productDetail);
      state.productAttributes = productDetail?.hasanyvariant?productDetail?.hasanyvariant:'0';
      // state.priceDetails = productDetail?.,
      state.businessCurrency = parseInt(productDetail?.productPriceDetail?.businesscurrencyid);
      state.msrp = productDetail?.productPriceDetail?.retailerprice;
      state.unit = parseInt(productDetail?.productPriceDetail?.retailerpriceunitid);
      state.MOQ = productDetail?.productPackageInfo?.minorderquantity;
      state.moqUnit = productDetail?.productPackageInfo?.orderunitid;
      state.no_of_items_in_box = productDetail?.productPackageInfo?.noofpackageinbox;
      state.max_qty_for_sample = productDetail?.productPackageInfo?.samplethreshold;
      state.no_limit = productDetail?.productPackageInfo?.isunlimitsample?productDetail?.productPackageInfo?.isunlimitsample==='0'?false:true:false;
      state.productPositioning = productDetail?.productqualitylevel;
      state.availability = productDetail?.productDetail?.isavaialbleimmediate === '1'? 'Immediate':'AvailableFrom';
      state.text_about_sample = productDetail?.productDetail?.avaialbilitycomment;
      state.variantDetails=productDetail?.variantdetails;
      setState({...state });
      setData(false);
    }
  }, [])

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const watchAll = watch();
  console.log('watchAll', watchAll);
  console.log('errors', errors);
  console.log('state', state);
  

  const handleChange = input => e => {
    console.log("add",e.target.value);  
    setState({...state, [input] : e.target.value});
    if(input === 'productVisibility' || input === 'productVisibility1')
    {
      setState({...state, [input] : input === 'productVisibility' ? !state.productVisibility: !state.productVisibility1 });  
    } else{
      setState({...state, [input] : e.target.value});
    }
  }
  const save = async() => {
    let visibility=[];
      if(state.productVisibility)
          visibility.push({sharetypeid:3});
      if(state.productVisibility1)
          visibility.push({sharetypeid:4});

    let productAvailability={
      isavaialbleimmediate: state.availability === 'Immediate' ? '1' : '0',
      avaialblefrom: state.availability !== 'Immediate' ? selectedDate : null,
      avaialbilitycomment: state.availability !== 'Immediate' ? state.text_about_sample : null,
    }

    let productPrice={
      currencyid:parseInt(state.businessCurrency),
      retailerprice:+watchAll.msrp,
      retailerpriceunitid: parseInt(state.unit),
      samplethreshold : +watchAll.max_qty_sample,
		  isunlimitsample: watchAll.no_limit? "1" : "0",
    }
    let productPackageInfo={
     minorderquantity: +watchAll.moq,
				orderunitid: parseInt(state.moqUnit),
				packagesize : null,
				packageunit: null,
				noofpackageinbox : +watchAll.no_of_items_in_box,
				noofpackageinboxunitid: null,
				noofboxinpallet : null,
				noofboxinpalletunitid : null,
				approximateweightofpallet: null,
				approximateweightofpalletuniti: null,
				length : null,
				breadth : null,
				height: null,
				weight: null,
    }
    let productCode={
      productCodeTypes:[],//[{productuniversalcodetype:null,code:null}],
      hasanyvariant : watchAll.productAttributes,
      variantdetails : watchAll.productAttributes === '1' ? watchAll.variantDetails : null,
    }

    let productPosition={      
      productqualitylevel:watchAll.productPositioning,
    }    

    let product={
      productCode:productCode,
      productPrice:productPrice,
      productPackageInfo:productPackageInfo,
      productPosition:productPosition,
      productAvailability:productAvailability,
    }
    console.log('product', product);
    saveTradeDetails(product);
  }

return (
<div className="main-content">
      {(!isAdd && isdata)?'':<Container className="mt-4 col-lg-9 col-md-9 col-sm-12" fluid>
              <form noValidate onSubmit={handleSubmit(save)}>

                  {/* // ------------------------------------------------- Trade Details------------------------------------------------- */}
                  <Card> 
                  <CardHeader>
                      <h3 style={{textAlign:'center'}}>Trade Details</h3>
                        <p className="text-center">Enter Products awards, certification, product warranty, localization</p>
                        </CardHeader>  
                        <CardBody>    
                  <Row>
                  <div className="col-lg-9">
                  <h4 style={{textAlign:'left'}}>Product's Attributes</h4>
                  <h5>Does the Product have Different Variants / Attributes</h5>
      </div>
      <div className="col-lg-3  ">
                  <div className="form-group">        
                  <FormRadio
                name="productAttributes"
                id="productAttributes"
                className="text-sm mb-0"
                label=""
                options={radioGroupOptions3}
                control={control}
                value={state.productAttributes}
                defaultValue={state.productAttributes}
                row
                onChange={handleChange('productAttributes')}
                          />

                      </div>           
                  </div>
                  </Row>
                { state.productAttributes === '1' ?    
              <div className="col-lg-12">
                  <div className="form-group">        
                          <FormInput 
                              id="variantDetails"
                              type="text"
                              name="variantDetails"
                              placeholder="Add variant details like colors, size, type and other"
                              register={register}
                              value={watchAll.variantDetails}
                              defaultValue={state.variantDetails}
                          />            
                  </div>
                  </div> : ''}
                  
                  <Row>
                  <div class="col-lg-9">
                  <h4 style={{textAlign:'left'}}>Price Details</h4>
                  <h5>Update your product prices or ordering details</h5>
                  <Row>
                        <div className="col-lg-6 pad-les  mt-3">
              <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="busCurrency" name="busCurrency" ref={register} value={state.businessCurrency} /> 
              <FormControl variant="outlined" className={classes.formControl1} error={!errors.busCurrency || state.businessCurrency ? false : true}>
              <InputLabel id={!errors.busCurrency || state.businessCurrency ? 'demo-simple-select-outlined-label' : 'demo-simple-select-error-label'}>Business Currency</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                onChange={handleChange('businessCurrency')}
                label="Business Currency"
                value={state.businessCurrency}
              >
                <MenuItem value="">
                  <em>Select Business Currency</em>
                </MenuItem>
                <MenuItem value={1}>Euro</MenuItem>
                <MenuItem value={2}>Dollar</MenuItem>
              </Select>
              <FormHelperText style={{color: 'red'}}>{!errors.busCurrency || state.businessCurrency ?  '' : errors.busCurrency.message }</FormHelperText>
            </FormControl>
                        </div>
                        <div className="col-lg-3 pad-les  mt-3">
                        <div className="form-group">            
                          <FormInput 
                    id="MSRP *"
                    type="text"
                    name="msrp"
                    label="MSRP *"
                    register={register}
                    value={watchAll.msrp}
                    defaultValue={state.msrp}
                    error={errors.msrp}
                          />
                  </div> 
                        </div>
                        <div className="col-lg-3 pad-les  mt-3">
              <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="unit" name="unit" ref={register} value={state.unit} /> 
              <FormControl variant="outlined"  className={classes.formControl} error={!errors.unit || state.unit ? false : true }>
              <InputLabel id={!errors.unit || state.unit ? 'demo-simple-select-outlined-label' : 'demo-simple-select-error-label'}>Unit</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="unit"
                className="test"
                onChange={handleChange('unit')}
                label="Unit"
                name="unit"
                value={state.unit}
              >
                <MenuItem value={1}>Pcs</MenuItem>
                <MenuItem value={2}>Pack</MenuItem>
                <MenuItem value={3}>Gram</MenuItem>
                <MenuItem value={4}>Kgs</MenuItem>
                <MenuItem value={5}>Box</MenuItem>
              </Select>
              <FormHelperText style={{color: 'red'}}>{!errors.unit || state.unit ?  '' : errors.unit.message }</FormHelperText>
            </FormControl>
                        
                        </div>
                      </Row>
          </div>
          </Row>

          <Row>
                  <div class="col-lg-11">
                  <h4 style={{textAlign:'left'}}>Price Details</h4>
                  <h5>Update your product prices or ordering details</h5>
                  <Row>
                        <div className="col-lg-4 pad-les  mt-3">
                        <Row>
                        <div className='col-lg-9 pad-less'>
                        <div className="form-group">            
                          <FormInput 
                                    id="MOQ *"
                                    type="text"
                                    name="moq"
                                    label="MOQ *"
                                    register={register}
                                    defaultValue={state.MOQ}
                                    value={watchAll.moq}
                                    error={errors.moq}
                          />
                  </div>
                          </div> 
                          <div className='col-lg-3 pad-less'>        
                          <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="moqUnit" name="moqUnit" ref={register} value={state.moqUnit} /> 
              <FormControl variant="outlined" className={classes.formControl1} error={!errors.moqUnit || state.moqUnit ? false : true}>
              <InputLabel id={!errors.moqUnit || state.moqUnit ? 'demo-simple-select-outlined-label' : 'demo-simple-select-error-label'}>Unit</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                className="test"
                onChange={handleChange('moqUnit')}
                label="Moq Unit"
                value={state.moqUnit}
              >
                <MenuItem value={1}>Pcs</MenuItem>
                <MenuItem value={2}>Pack</MenuItem>
                <MenuItem value={3}>Gram</MenuItem>
                <MenuItem value={4}>Kgs</MenuItem>
                <MenuItem value={5}>Box</MenuItem>
              </Select>
              <FormHelperText style={{color: 'red'}}>{!errors.moqUnit || state.moqUnit ?  '' : errors.moqUnit.message }</FormHelperText>
            </FormControl>
            
            
                          </div> 
                          </Row>
                        </div>
                        <div className="col-lg-3 pad-les  mt-3">
                        <div className="form-group">            
                          <FormInput 
                                      id="No of items in Box"
                                      type="text"
                                      name="no_of_items_in_box"
                                      label="No of items in Box"
                                      register={register}
                                      defaultValue={state.no_of_items_in_box}
                                      value={watchAll.no_of_items_in_box}
                          />
                  </div> 
                        </div>
                        <div className="col-lg-3 pad-les  mt-3">
                        <div className="form-group">            
                          <FormInput 
              id="Max qty for Sample orders"
              type="text"
              name="max_qty_sample"
              label="Max qty for Sample orders"
              register={register}
              control={control}
              defaultValue={state.max_qty_for_sample}
              value={watchAll.max_qty_for_sample}
              disabled={ watchAll.no_limit }
              error={errors.max_qty_sample}
                          />
                  </div> 
                        </div>
                        <div className="col-lg-2 pad-less  mt-3">
                        <div className="form-group">
                        <FormCheckBox
                      id="visible"
                      type="checkbox"
                      name="no_limit"
                      label="No limit"
                      label1={{textAlign:"left"}}
                      register={register}
                      value={watchAll.no_limit}
                      defaultValue={state.no_limit}
                      />
                  </div>
                        </div>
                      </Row>
          </div>

          </Row>

          <Row>
                  <div className="col-lg-5">
                  <h4 style={{textAlign:'left'}}>Product Positioning</h4>
                  <h5>How do you position this product?</h5>
      </div>
      <div className="col-lg-7 ">
                  <div className="form-group">        
                  <FormRadio
                                name="productPositioning"
                                id="productPositioning"
                                className="text-sm mb-0"
                                label=""
                                options={radioGroupOptions1}
                                control={control}
                                defaultValue={state.productPositioning}
                                value={watchAll.productPositioning}
                                row
                                onChange={handleChange('productPositioning')}
                          />

                      </div>           
                  </div>
                  </Row>

                  <Row>
                  <div className="col-lg-5">
                  <h4 style={{textAlign:'left'}}>Availability</h4>
                  <h5>Tell us the product availability details. You can also add comments to detail the deployment schedule in different regions</h5>
      </div>
      <div className="col-lg-7 ">
                  <div className="form-group">        
                  <FormRadio
                            name="availability"
                            id="availability"
                            className="text-sm mb-0"
                            label=""
                            options={radioGroupOptions2}
                            control={control}
                            value={state.availability}
                            row
                            onChange={handleChange('availability')}
                          />

                      </div>           
                  </div>
                  <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="date" name="date" ref={register} value={selectedDate} /> 
                  </Row>

                {
                    state.availability === 'AvailableFrom' ? 
                  <Row>
                  <div className="col-lg-4 mt-2">
                  <div className="awd-date">
                  <FormControl className={classes.margin}> 
                  <MuiPickersUtilsProvider  utils={DateFnsUtils}>            
                  <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date1"
                name="date1"
                defaultValue={selectedDate}
                ref={register}
                control={control}
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              </MuiPickersUtilsProvider>
              <FormHelperText style={{color: '#f44336', float: 'right', fontSize:'0.75rem'}} >{errors.date?.message}</FormHelperText>
              </FormControl>
              </div>
      </div>
      <div className="col-lg-8">
      <div className="form-group">            
      <form>
        <textarea 
        class=" qtytext" 
        id="exampleFormControlTextarea1" 
        onChange={handleChange('text_about_sample')}
        rows="3" 
        value={state.text_about_sample}
        aria-label="empty textarea"
        placeholder="Max qty for Sample orders">
        </textarea>
      </form>         

            {/* <TextareaAutosize  width = "100%" /> */}
                  </div>           
                  </div>
                  </Row> : '' }

              <div className="text-center mt-4">
                  <button type="submit"  className="btn btn-primary" >Save</button>
                </div>  

                
              
                </CardBody>
                </Card>
                    </form>
      </Container>}
      </div>
    
   
  );
};

const radioGroupOptions3 = [
  {
    value: "1",
    label: "Yes",
  },
  {
    value: "0",
    label: "No",
  },
 
];
const radioGroupOptions1 = [
  {
    value: "Entry",
    label: "Entry Level",
  },
  {
    value: "Mid",
    label: "Mainstream ",
  },
  {
    value: "High",
    label: "Premium",
  }, 
];
const radioGroupOptions2 = [
  {
    value: "Immediate",
    label: "Immediate",
  },
  {
    value: "AvailableFrom",
    label: "Available From",
  },
 
];


export default TradeDetails;
