import React, { useState, useEffect } from "react";
import { Container, Row, Button, Col, CardBody, CardHeader, Card, Nav, NavItem, NavLink, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Badge } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader"
import Title from "views/Hoc/Dialog/Title";
import { SelectAutocomplete, FormAutocomplete } from '_components/FormElements/FormInput';
import { useForm } from "react-hook-form";
import { history } from '_helpers'
import MyprodfilterVendor from './MyprodfilterVendor';
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/pagination/PaginationActions';
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
// import { PlanFeatureDialog } from "views/Hoc/Dialog/PlanFeatureDialog";
import { userConstants } from '../../../_constants';
import { UncontrolledTooltip } from "reactstrap";
import ReOrderDialog from "views/Hoc/Dialog/ReOrderDialog";
import axios from 'axios';
import { channelAction } from '_actions/channel.action';
import { connect, useSelector, useDispatch } from 'react-redux';

var _ = require('lodash');

const MyproductlistVendor = (props) => {
  let [tabs, setTab] = useState('1');
  const [opneadd, opneAddnew] = useState(false);
  let [tabCount, setTabCount] = useState({ active: 0, inActive: 0 });
  let [sort, setSort] = useState('asc');
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [total, setTotal] = useState(0);
  let [isLoading, setLoading] = useState(true);
  let [data, setData] = useState([]);
  const [productLimit, setProductLimit] = useState(true);
  const { control } = useForm({ mode: 'onBlur' });
  const [filterValueState, setFilterValue] = useState({ Position: null, Brand: [], Family: [], Category: [], search: null, sort: true });
  const [searchState, setSearch] = useState({ search: null, dropdownList: [] });
  const [planUpgrade, setplanUpgrade] = useState(false);
  const [upgradeType, setUpgradeType] = useState('productAdd');
  let logo = "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg";

  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });
  useEffect(() => {
    getMyProductList();
    getProductLimit();

    // return () => {
    //   clean operation
    // }

  }, []);

  var user = JSON.parse(localStorage.getItem('CHuser'))?.userData;

  const getMyProductList = () => {
    setPage(0);
    getMyProductListing();
  }
  const setActiveTab = (tab) => {
    tabs = tab;
    setTab(tab);
    getMyProductList();
  }
  const setSorting = (data) => {
    if (data != sort) {
      sort = data;
      setSort(sort);
      getMyProductList();
    }

  }

  const userpostdata = {
    channelId: user.channelId,
    userId: user.userId
  }

  const getMyProductListing = async () => {
    let postData = {
      filter: getFilterData(),
      order: sort ? sort : 'asc', //:'desc',
      search: filterValueState.search ? filterValueState.search.id : null,
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      isactive: tabs,
      user: userpostdata
    }
    setLoading(true);

    let res = await commonService.postService(postData, 'channelAdmin/my-products').catch(err => setLoading(false));
    if (res) {
      //console.log(res.data);
      data = res.data;
      setData(data.result);
      total = res.data.total;
      setTotal(total);
      props.channelRedux(res.data.master, 'PRODUCTS');
      tabCount.active = res.data.tabCount.active
      tabCount.inActive = res.data.tabCount.inActive
      if (tabs === '1') {
        tabCount.active = total;
      } else {
        tabCount.inActive = total;
      }
      setTabCount(tabCount)
      setLoading(false);
    } else {
      data = [];
      setData(data);
      total = 0;
      setTotal(total);
      setLoading(false);
    }
  }

  const getFilterData = () => {
    let filter = {
      position: null,
      brand: null,
      family: null,
      country: null,
      location: null,
      profile: null,
      category: null,
    };
    console.log("filterValueState:", filterValueState);
    if (filterValueState.Position) {
      filter.position = filterValueState.Position.id;
    }
    if (filterValueState.Brand && filterValueState.Brand.length > 0) {
      filter.brand = filterValueState.Brand.map(x => parseInt(x.id));
    }
    if (filterValueState.Family && filterValueState.Family.length > 0) {
      filter.family = filterValueState.Family.map(x => parseInt(x.id));
    }
    if (filterValueState.Category && filterValueState.Category.length > 0) {
      filter.category = filterValueState.Category.map(x => parseInt(x.id));
    }

    console.log('filter', filter);
    return filter;
  }
  const handleChangeSelect = (input, value) => {
    console.log('input, value', input, value);
    if (input === 'search') {
      filterValueState.search = value;
    }
    if (input === 'sort') {
      filterValueState.sort = value;
    }
    if (input === 'position') {
      filterValueState.Position = value;
    }
    if (input === 'brand') {
      filterValueState.Brand = value;
    }
    if (input === 'family') {
      filterValueState.Family = value;
    }
    if (input === 'category') {
      filterValueState.Category = value;
    }
    setFilterValue({ ...filterValueState })
    console.log('filterValueState', filterValueState);
    getMyProductList();
  }
  const resetFilterValue = () => {
    filterValueState.Position = [];
    filterValueState.Brand = [];
    filterValueState.Family = [];
    filterValueState.Category = null;
    filterValueState.search = null;
    filterValueState.sort = null;
    setFilterValue({ ...filterValueState });
  }
  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getMyProductListing();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    getMyProductListing();
  };
  const searchOption = (option) => {
    searchState.dropdownList = option;
    setSearch({ ...searchState });
  }

  console.log('props.location', props.location)

  const productEdit = (sku, id, channelId) => {
    if (id == 1)
      history.push(props.location.pathname + '/EditProduct?sku=' + sku + '&action=' + id + '&channelId=' + channelId);
    if (id == 2)
      history.push(props.location.pathname + '/CloneProduct?sku=' + sku + '&action=' + id + '&channelId=' + channelId);
    if (id == 3)
    history.push(props.location.pathname + '/AddProduct?channelId=' + channelId);
  }
  const productDelete = async (sku) => {
    setLoading(true);
    let res = await commonService.deleteService('product/' + sku).catch(err => ToastsStore.error('Something went wrong!...'));
    setLoading(false);
    if (res) {
      ToastsStore.success('Product deleted successfully!...');
      getMyProductList();
    }
  }
  const confirmPopup = (res) => {
    setModalPopup(false);
    productDelete(res.value);
  }
  const closePopup = () => {
    setModalPopup(false);
  };
  const deletePopup = (sku) => {
    modalPopValue.message = 'Do you want to delete the product?';
    modalPopValue.title = 'Product';
    modalPopValue.value = sku;
    modalPopValue.popUpId = 1;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);
  }

  //Move product towards Up
  const moveUp = async (sku) => {
    let newdata = [];
    let prevItem = {};
    let flag = 0;
    data.map((x, index) => {
      if (x.productSKU == sku) {
        let p = data.slice(0, (index - 1));
        newdata.push(...p);
        newdata.push(x);
        newdata.push(prevItem);
        flag = 1;
      } else if (flag == 1) {
        newdata.push(x);
      }
      else if (flag == 0) {
        prevItem = x;
      }

    })

    let rorder = 0;
    newdata.map(x => {
      rorder = rorder + 1;
      Object.assign(x, { order: rorder })
    })

    setData(newdata);
    commonService.putService(data, 'product/setProductOrder');
  }


  //Move product towards Down
  const moveDown = async (sku) => {
    let newdata = [];
    let nextItem = {};
    let flag = 0;
    data.map((x, index) => {
      if (x.productSKU == sku) {
        let p = data.slice(0, index);
        newdata.push(...p);
        nextItem = x;
        flag = 1;
      } else if (flag == 1) {
        newdata.push(x);
        newdata.push(nextItem);
        flag = 2;
      }
      else if (flag == 2) {
        newdata.push(x);
      }

    })

    let rorder = 0;
    newdata.map(x => {
      rorder = rorder + 1;
      Object.assign(x, { order: rorder })
    })

    setData(newdata);
    commonService.putService(data, 'product/setProductOrder');
  }

  const setPublicView = async (productId, isPublic) => {
    let item = {
      productId: +productId,
      isPublic: isPublic ? 1 : 0
    }
    data = data.map(x => {
      if (x.productId == item.productId)
        x.isPublic = item.isPublic;
      else
        x.isPublic = x.isPublic;
      return x;
    })
    setData(data);

    await commonService.putService(item, 'product/setProductPublicView');

  }

  const opneAdd = () => {
    // setProductList(data[indexValue].product);
    // setIndex(indexValue);
    opneAddnew(true);
  }

  const closeModalPopup = (items) => {
    opneAddnew(false);
    let updateList = [];
    let sorted = _.sortBy(data, x => _.findIndex(items, y => +x.productId === +y.productId))
    data = sorted;
    setData(data);

    items.map((x, index) => {
      let list = {
        productId: +x.productId,
        order: index + 1
      }
      updateList.push(list);
    })

    commonService.putService(updateList, 'product/setProductOrder');

  }


  const getProductLimit = async () => {
    let featureList = user.featureList
    console.log('my feature lists  ======== ');
    // console.log(featureList[29]);
    if (featureList && featureList[userConstants.NO_OF_PRODUCT]) {
      setProductLimit(featureList[userConstants.NO_OF_PRODUCT]);
    }
    // await commonService.getServices('product/productlimit').then(res =>{ 
    //   if(res.data.allowed){
    //     setProductLimit(res.data.allowed);
    //   }     
    // });      

  }
  //history.push({pathname:'/admin/import', state: {type: 2}})
  const openUpgradeDialog = (action) => {

    let productCount = parseInt(tabCount.active);//+ parseInt(tabCount.inActive);
    if (productLimit > productCount || productLimit === 'Unlimited') {
      if (action == 'productImport') {
        history.push({ pathname: '/admin/Myproductlist/import', state: { type: 2 } })
      } else {
        history.push({ pathname: '/admin/Myproductlist/Productform', state: { type: 2 } });
      }
    } else {

      setplanUpgrade(true);
    }
  }

  const closeplanUpgrade = () => {
    setplanUpgrade(false);
  }
  return (

    <>
      {isLoading && <Spinner />}
      <SimpleHeader location={props.match} />
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <ReOrderDialog opneadd={opneadd} closeModalPopup={closeModalPopup} productList={data} />
      {/* {planUpgrade && <PlanFeatureDialog closeUserView={closeplanUpgrade} UpgradeType={upgradeType} />} */}
      <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
      <div id='MyProduct' className="main-content">
        <Title title="My Product" desc={["List of your various products"]} />
        <Container fluid className="mt-4">
          <Nav tabs className="list-tab">
            <div className="col-md-3">
            <NavItem className="list-main">
              <NavLink className="active">
                <Row className="text-uppercase">
                  <Col onClick={() => setActiveTab('1')} xs="6" className=" mt-2 sub-a">
                    <a style={{ cursor: 'pointer' }}>
                      <h3 className="mb-0">Active</h3>
                      <p class="count m-0">{tabCount.active} </p>
                    </a>
                  </Col>
                  <Col onClick={() => setActiveTab('0')} xs="6" className="icon-tag2 mt-2 sub-a">
                    <a style={{ cursor: 'pointer' }} >
                      <h3 className="mb-0">In Active</h3>
                      <p class="count m-0">{tabCount.inActive}</p>
                    </a>
                  </Col>
                </Row>
              </NavLink>
            </NavItem>
            </div>
            <div className="col-md-9">

            <NavItem>
              <div className="prodlsact" style={{ textAlign: '-webkit-right', paddingBottom: "2rem" }}><Button color="primary" onClick={() => productEdit("dummy", 3, user.channelId)} ><i class="fas fa-user-plus"></i>  Add Single Product</Button>
                <Button color="primary" onClick={() => openUpgradeDialog('productImport')} ><i class="fas fa-user-plus"></i>  Add Bulk Products</Button></div>

            </NavItem>
            <NavItem className="pl-2 right-search1">
              <SelectAutocomplete
                id="search"
                type="text"
                label="search"
                name="search"
                options={searchState.dropdownList}
                placeholder="search..."
                disableCloseOnSelect
                // defaultValue={searchState.search?searchState.search[0]:null}
                control={control}
                input='search'
                value={filterValueState.search}
                handleChangeSelect={handleChangeSelect}
              />
            </NavItem>
            <NavItem className="right-sort1">
              <UncontrolledDropdown className="p-0" group>
                <DropdownToggle caret className="p-2">
                  <h3
                    style={{ display: "inline-block" }}
                    className="font-weight-bold m-0"
                  >
                    <i className="fa fa-bars  mr-2" aria-hidden="true"></i>
                    {sort == 'asc' ? 'A-Z' : 'Z-A'}
                  </h3>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem

                    onClick={() => setSorting('asc')}
                  >
                    A-Z
                  </DropdownItem>
                  <DropdownItem

                    onClick={() => setSorting('desc')}
                  >
                    Z-A
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
            </div>
          </Nav>
          <Card>
            <CardBody>
              <Row>
                <Col lg="3">
                  <MyprodfilterVendor activeTab={tabs} handleChangeSelect={handleChangeSelect} filterValueState={filterValueState} resetFilterValue={resetFilterValue} searchOption={searchOption} />
                </Col>
                <Col lg="9">
                  {/* <Row>
                    <Col lg="6"></Col>
                    <Col lg="6"><div style={{ textAlign: '-webkit-right', paddingBottom: "2rem" }}><Button color="primary" onClick={() => openUpgradeDialog('addProduct')} ><i class="fas fa-user-plus"></i>  Add Product</Button>
                      <Button color="primary" onClick={() => openUpgradeDialog('productImport')} ><i class="fas fa-user-plus"></i>  Import/Export</Button></div></Col>
                  </Row> */}
                  <Card>
                    {/* <CardHeader style={{ backgroundColor: "#f8f9fe" }}>
                      <Row>
                        <Col lg="4"><h3>Details</h3></Col>
                        <Col lg="8" className="pad-less">
                          <Row>
                            <Col lg="2"><h3>SKU</h3></Col>
                            <Col lg="3"><h3>PRICE</h3></Col>
                            <Col lg="2"><h3>Status</h3></Col>
                            <Col lg="3"><h3>Action</h3></Col>
                            <Col lg="2"><h3>Public View</h3></Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardHeader> */}

                    <CardBody className="pad-less mt-1">
                      <div className="d-flex justify-content-end pt-4 pb-4 bgwhite">
                        {/* <Button color="primary">Re Order</Button> */}
                        <Button onClick={() => opneAdd()} className="btn btn-secondary"> <i class="fa fa-cog mr-2" aria-hidden="true"></i>
                          Re Order Rank</Button>
                      </div>
                      {data.length > 0 &&(
                      <table className="table table-responsive">
      <thead>
        <tr>
          <th>Image</th>
          <th>Product</th>
          <th>Brand</th>
          <th>Product SKU</th>
          <th>Retail Price</th>
          <th>Status</th>
          <th>Actions</th>
          <th>Public View</th>
          {data.length > 1 && <th>Move</th>}
        </tr>
      </thead>
      <tbody>

                     { data.map((item, index) => (

<tr key={index}>
<td>
  <img
    onError={(event) => {
      // Handle image error
      // ...
    }}
    className="img-fluid"
    alt="ch-logo"
    src={item?.documentUrl ? item?.documentUrl : logo}
  />
</td>
<td>{item?.product}</td>
<td>{item?.brandName}</td>
<td>{item?.productSKU}</td>
<td>
  {item?.businesscurrencyid === '1' ? (
    <i className="fas fa-euro-sign"></i>
  ) : (
    <i className="fas fa-dollar-sign"></i>
  )}{' '}
  {item?.retailerprice}
</td>
<td>{tabs === '1' ? 'Active' : 'InActive'}</td>
<td>
  <span
    onClick={() => productEdit(item.productSKU, 2, item.channelId)}
  >
    <i className="far fa-copy" style={{ color: "#525f7f", cursor: 'pointer' }}></i>
  </span>
  {/* Add other action buttons here */}
</td>
<td>
  <label className="custom-toggle custom-toggle-success">
    <input
      type="checkbox"
      checked={+item.isPublic}
      name="status"
      onClick={() => setPublicView(item.productId, !(+item.isPublic))}
    />
    <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
  </label>
</td>
{data.length > 1 && (
  <td>
    <span onClick={() => moveUp(item.productSKU)}>
      <i className="fas fa-angle-up" style={{ color: "#525f7f", cursor: 'pointer' }}></i>
    </span>
    <span onClick={() => moveDown(item.productSKU)}>
      <i className="fas fa-angle-down" style={{ color: "#525f7f", cursor: 'pointer' }}></i>
    </span>
  </td>
)}
</tr>

                      ))}
                      </tbody>
</table>
                      )}

                    </CardBody>

                  </Card>
                  {(data.length > 0 && total > 10) &&
                    <TablePagination
                      component="div"
                      count={total}
                      page={page}
                      onChangePage={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[10, 20, 30, 40]}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
}

//export default Myproductlist;
function mapState(state) {
  const general_data = state.channelListing;
  return general_data;
}

const actionCreators = {
  channelRedux: channelAction.channelRedux
};

export default connect(mapState, actionCreators)(MyproductlistVendor);