import React, { Component } from 'react';
import axios from 'axios';
export default class MultipleImageUploadComponent extends Component {

    fileObj = [];
    fileArray = [];

    constructor(props) {
        super(props)
        this.state = {
            file: [null]
        }
        this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
        this.uploadFiles = this.uploadFiles.bind(this)
    }

    uploadMultipleFiles(e) {
        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
        }
        this.setState({ file: this.fileArray })
    }

    uploadFiles(e) {
        e.preventDefault()
        console.log(this.state.file)
    }

    render() {
        return (
            <form>
                <div className="row">
                <div className="col-lg-12">
                <div className="col-lg-12 md-12 sm-6">
                <div className="form-group multi-preview">
                    {(this.fileArray || []).map(url => (
                        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={url} alt="..." />
                    ))}
                </div>
                </div>
                <div className="col-lg-12 md-12 sm-6">
                <div className="form-group preview-file">
                    <input type="file" className="form-control" onChange={this.uploadMultipleFiles} multiple />
                </div>
                </div>
                <button type="button" className="btn btn-primary btn-block" onClick={this.uploadFiles}>Upload</button>
                </div>
                </div>
            </form >
        )
    }
}