import React, { useEffect, useState } from 'react';
import { Button, Modal } from "reactstrap";
import { Card, CardBody, Row, Col } from "reactstrap";
import axios from 'axios';
import { Spinner } from 'views/Hoc/Spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';

import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export const EventContactDialog = (props) => {
    const { originalSenderId, originalReceiverId, closeContactDialog, Open, contactDetails, contactDetailsList , participantData , eventRequestId , getParticipants ,closeDrawer} = props;
    console.log(contactDetailsList);

    let logo = 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg';

    const [state, setState] = useState({
        contacts: [],
        setDefault: '',
    });
    const [loading, setLoading] = useState(false);
    const [updateData, setUpdateData] = useState({})
    const [contactDetailList, setContactDetailList] = useState([])
    const [participantsData, setParticipantsData] = useState({senderParticipants:[],receiverParticipants:[]})
    const [disableShowSlot, setDisableShowSlot] = useState(true)
    

    useEffect(() => {
        setDisableShowSlot(true)
        setUpdateData({})
        setContactDetailList(contactDetailsList);
      }, [contactDetailsList]);

    useEffect(() => {
        setParticipantsData(participantData);
      }, [participantData]);
    //   useEffect(() => {
    //     if(Open){    
    //     setLoading(true);
    //     (async() => {            
    //       await axios.post('channelAdmin/allcontacts', props.userData).then(res =>{
    //           console.log("allcontact:",res);          
    //           setState({...state,contacts:res.data});
    //           setLoading(false);
    //       });
    //     })();
    //   }
    //   }, [Open]);
    const closeOpens=(e)=>{
        e.preventDefault();
        closeContactDialog(false);
      }
    
    const toggle = (data)=>{
        if (!data.checked && data.agendaType === 'Flash Meeting') {
          const updatedList = contactDetailList.map((item) =>
           item.disabled === false ? { ...item, checked: false } : item
         );
        const updatedData = { ...data, checked: true };
        const dataIndex = updatedList.findIndex((item) => item.meetingTime === data.meetingTime);
        if (dataIndex !== -1) {
         updatedList[dataIndex] = updatedData;
         }
        setContactDetailList(updatedList);
          } else {
            const updatedList = contactDetailList.map((item) =>
              item === data ? { ...item, checked: !item.checked } : item
            );
            setContactDetailList(updatedList);
          }
        data.checked = !data.checked
        if(data.agendaType === 'Flash Meeting')
        setUpdateData(data)
    }

    const toggleSenderSlot = (data)=>{
        const updatedSenderParticipants = participantsData.senderParticipants.map((item) =>
        item === data ? { ...item, checked: !item.checked } : item
        );

        if(updatedSenderParticipants.filter((res)=>res.checked).length>0)
        setDisableShowSlot(false)
        else
        setDisableShowSlot(true)
          
          const updatedParticipantsData = { ...participantsData, senderParticipants: updatedSenderParticipants };
          setParticipantsData(updatedParticipantsData);
    }

    const toggleReceiverSlot = (data)=>{
        const updatedReceiverParticipants = participantsData.receiverParticipants.map((item) =>
            item === data ? { ...item, checked: !item.checked } : item
          );

          if(updatedReceiverParticipants.filter((res)=>res.checked).length>0)
          setDisableShowSlot(false)
          else
          setDisableShowSlot(true)


        const updatedParticipantsData = { ...participantsData, receiverParticipants: updatedReceiverParticipants };

        setParticipantsData(updatedParticipantsData);
  }

    const showSlots = (data)=>{
       setLoading(true)
       let participants = []
       participantsData.senderParticipants.map((res)=>{
        if(res.checked)
        {
            participants.push(res.contactId)
        }
       })

       participantsData.receiverParticipants.map((res)=>{
        if(res.checked)
        {
            participants.push(res.contactId)
        }
       })
       let requestObj = { 
        senderParticipants : participantsData.senderParticipants,
        receiverParticipants : participantsData.receiverParticipants,
        participants:participants,
        contactId: contactDetails.contactId,
        originalSenderId: originalSenderId,
        originalReceiverId: originalReceiverId,
        eventRequestId: eventRequestId,
       }
       axios.post(`/channelAdmin/showSlots`,requestObj).then(res => { 
           setLoading(false)
           setContactDetailList(res.data)
      });
    }

    const bookSlot = (data)=>{
        setLoading(true)
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        data.senders = participantsData.senderParticipants.filter((res)=>{if(res.checked)return res}).map((e)=>e.contactId)
        data.receivers = participantsData.receiverParticipants.filter((res)=>{if(res.checked)return res}).map((e)=>e.contactId)
        data.originalSenderId = originalSenderId
        data.originalReceiverId = originalReceiverId
        data.contactId= contactDetails.contactId
        data.eventRequestId = eventRequestId

        axios.post('/channelAdmin/bookSlot',
        data).then(res => {
         setLoading(false)
         closeDrawer()
         closeContactDialog()
         getParticipants()
      })
    }
    
    const declineRequest = (message)=>{
        setLoading(true)
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.post('/channelAdmin/declineRequest',
        {message:message,eventRequestId:eventRequestId}).then(res => {
         setLoading(false)
         closeDrawer()
         closeContactDialog()
         getParticipants()
      })
    }

    return (
        <div>
            {loading ? <Spinner /> : (
                <Modal
                    className="modal-dialog-centered"
                    isOpen={Open}
                    size="lg"
                >
                    <div className="modal-header" style={{ paddingBottom: 0 }}>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={e=> closeOpens(e)}

                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body  d-flex justify-content-center pt-1 pb-1">
                        <Card className="w-100 mb-0">
                            <h3 className="text-center"></h3>
                            <CardBody>
                                <Row>
                                    <Col sm={3}>
                                        <div className='cmp-logo rounded-circle'>
                                            <img src={contactDetails.documentPath!=null ? process.env.REACT_APP_CDN_URL + contactDetails.documentPath : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />
                                        </div>
                                    </Col>
                                    <Col sm={9}>
                                        <h4><span>{contactDetails.firstName}</span> <span style={{ paddingLeft: '10px' }}>{contactDetails.lastName}</span></h4>
                                        <div><span style={{ paddingRight: '20px' }}>{contactDetails.jobTitle}</span> <span style={{ paddingLeft: '20px' }}>{contactDetails.country}</span></div>
                                        <div><span style={{ paddingRight: '20px' }}>{contactDetails.email}</span> |  <span style={{ paddingLeft: '20px' }}>{contactDetails.mobileNo}</span></div>
                                        <div><span style={{ paddingRight: '20px' }}><EmailIcon /></span><span style={{ paddingRight: '20px' }}><WhatsAppIcon /></span> |  <span style={{ paddingLeft: '20px' }}>Company's contact :</span><span>{contactDetails.companyMail}</span> </div>
                                    </Col>
                                </Row>
                                <div>
                                    {/* <p><b>24-08-2023</b></p> */}
                                </div>
                                 <Row>
                                    <Col sm={6}>
                                       <div>Sender Participants</div> 
                                       { participantsData.senderParticipants.map((res)=>
                                       {
                                        return <div>{res.contactName} <Checkbox checked={res.checked} {...label} onClick={()=>toggleSenderSlot(res)}/></div>
                                       })
                                       }
                                    </Col>
                                    <Col sm={6}>
                                        <div>Receiver Participants</div>
                                    { 
                                    participantsData.receiverParticipants.map((res)=>
                                       {
                                        return <div>{res.contactName}   <Checkbox checked={res.checked} {...label} onClick={()=>toggleReceiverSlot(res)}/> </div>
                                        })
                                       }
                                    </Col>
                                </Row>
                                <Row>
                                   <Col sm={6}><Button className="btn-primary" variant="contained" onClick={()=>showSlots()} disabled={disableShowSlot}>Show Slots</Button></Col> 
                                </Row>
                                <br/>


                                <TableContainer component={Paper} style={{ marginBottom: '15px' }}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Localtime</TableCell>
                                                <TableCell>Agenda Date</TableCell>
                                                <TableCell>Meetings</TableCell>
                                                <TableCell>Available</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                            contactDetailList?.map((e)=> { 
                                                const availabilityColor = e.availability === 'Confirmed' ? { color: 'green' } : e.availability === 'Available'?{ color: 'black' }:{color: 'red'};
                                               return(
                                                <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{e.meetingTime}</TableCell>
                                                <TableCell>{e.agendaDate}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>{e.agendaType}</TableCell>
                                                <TableCell style={availabilityColor}>{e.availability}</TableCell>
                                                <TableCell >{e.disabled?<Checkbox checked={e.checked} {...label} disabled ={true}/>:<Checkbox checked={e.checked} onClick={()=>toggle(e)}/>}</TableCell>
                                            </TableRow>
                                               )
                                            })}
                                            {/* <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell scope="row">Local Time</TableCell>
                                                <TableCell align="right">20:00-20.07</TableCell>
                                                <TableCell align="right" style={{ fontWeight: 'bold' }}>Flash 1:1 Meetings</TableCell>
                                                <TableCell align="right" style={{ color: 'green' }}>Available</TableCell>
                                                <TableCell align="right"><Checkbox {...label} /></TableCell>
                                            </TableRow> */}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Stack direction="row" spacing={1}>
                                    <Button className="btn-sin btn btn-grey" variant="contained" disabled={!updateData?.checked}  onClick={()=>bookSlot(updateData)}>Book</Button>
                                    <Button className="btn-sin btn btn-grey" variant="contained" disabled={updateData?.checked}  onClick={()=>declineRequest('No Time Slot')}>No Time Slot</Button>
                                    <Button className="btn-sin btn btn-grey" variant="contained" disabled={updateData?.checked}  onClick={()=>declineRequest('Decline')}>Decline</Button>
                                    <Button className="btn-sin btn btn-grey " variant="contained" disabled={updateData?.checked} onClick={()=>declineRequest('Booth Visit')}>Booth Visit</Button>
                                    <Button className="btn-sin btn btn-grey" variant="contained" disabled={updateData?.checked} onClick={()=>declineRequest('Direct Contact')}>Direct Contact</Button>
                                    <Button className="btn-sin btn btn-grey" variant="contained" disabled={updateData?.checked} onClick={()=>declineRequest('Potential No-Show')}>Potential No-Show</Button>
                                    <Button className="btn-sin btn btn-grey" variant="contained" disabled={updateData?.checked} onClick={()=>declineRequest('Not Reachable')}>Not Reachable</Button>
                                    <Button className="btn-sin btn btn-grey" variant="contained" disabled={updateData?.checked} onClick={()=>declineRequest('Already Met')}>Already Met</Button>
                                </Stack>
                            </CardBody>
                        </Card>
                    </div>
                </Modal>
            )}
        </div>
    );
}
export default EventContactDialog;