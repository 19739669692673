import React, { useState, useEffect } from 'react';
import { commonService } from '_services/common.service';
import TablePagination from '@material-ui/core/TablePagination';
import {TablePaginationActions} from 'views/Hoc/PaginationActions';
import {Spinner} from '../../Hoc/Spinner';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Card, Container, CardHeader } from "reactstrap";
  import SimpleHeader from "components/Headers/SimpleHeader.js";
export const PostYourBuyingNeed=()=>{
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [loading, setLoading]  = useState(true);
    let [total, setTotal]  = useState(0);
    let [data, setData]  = useState([]);
    useEffect(() => {
        getPyb();
      }, []);
      const getPyb= async()=>{
        setLoading(true);
    let res=await commonService.getServiceWithParams({
        offset:page * rowsPerPage,
        limit:rowsPerPage,
        search:null,
        
    },'/channelAdmin/buyerNeed');
    if(res){
    data=res.data.result;
    setData(data);
    total=parseInt(res.data.total);
    setTotal(total);
    }
    setLoading(false);
      }
      const handleChangePage = (event, newPage) => {
        page=newPage;
        setPage(page);
        getPyb();
      };
    
      const handleChangeRowsPerPage = (event) => {
        rowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(rowsPerPage);
        setPage(0);
        getPyb();
      };
      return (
        <div id="usermanage">
          <SimpleHeader name="admin-users" parentName="Dashboard" />
            <Container className="mt--3" fluid>
                  <Card>
                    <CardHeader>
                      <h3 className="mb-0">Post Requirement</h3>
                     </CardHeader>
                     {loading? <Spinner/>:<ToolkitProvider
                      data={data}
                      keyField="fName"
                      columns={[
                        {
                            dataField: "companyName",
                            text: " Company Name",
                            // sort: true
                          },
                          {
                            dataField: "channelid",
                            text: " ChannelId",
                            // sort: true
                          },
                          {
                            dataField: "UserName",
                            text: "User Name",
                            sort: true,
                            formatter: (rowContent, row) => {
                              return(
                                <>
                                {row?.lastName}
                                </>
                              )
                            }
                          },
                          {
                            dataField: "ContactId",
                            text: "ContactId",
                            sort: true,
                            formatter: (rowContent, row) => {
                              return(
                                <>
                                {row?.contactid}
                                </>
                              )
                            }
                          },
                          {
                            dataField: "PostDescription",
                            text: "Post Description",
                            sort: true,
                            formatter: (rowContent, row) => {
                              return(
                                <>
                                {row?.requirements}
                                </>
                              )
                            }
                          },
                          {
                            dataField: "Categories",
                            text: "Categories",
                            sort: true,
                            formatter: (rowContent, row) => {
                              return(
                                <>
                                {row.category.map(x => x).join(', ')}
                                </>
                              )
                            }
                          }
                      ]}
                      search
                    >
                  {props => (
                        <div className="py-2 table-responsive">
                          <BootstrapTable
                            {...props.baseProps}
                            bootstrap4={true}
                            // pagination={pagination}
                            bordered={false}
                          />
    
                      {total >10 && <TablePagination
                            component="div"
                            count={total}
                            page={page}
                            onChangePage={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />}
                        </div>
                      )}
                    </ToolkitProvider>}
                  </Card>
                  </Container>
        </div>
      );
}

export default PostYourBuyingNeed;