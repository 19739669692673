import React, { useEffect, useState } from 'react'
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Nav, NavItem, Card, CardBody, Container, Row, Col, Media, CardHeader, ListGroupItem, ListGroup, UncontrolledTooltip, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";
import { SelectAutocomplete, FormAutocomplete } from '_components/FormElements/FormInput';
import Moment from 'moment';
import BookHotelModal from '@mui/material/Modal';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { Modal } from "reactstrap";
import axios from 'axios';
import { ModalSpinner, Spinner } from '../../Hoc/Spinner'
import { history } from '_helpers';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import EditEventUser from 'views/Hoc/Dialog/EditEventUser';
import EditEventBuyer from 'views/Hoc/Dialog/EditEventBuyer';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { commonService } from '_services/common.service';
import { useForm } from "react-hook-form";
import EditEventUserPackage from 'views/Hoc/Dialog/EditEventUserPackage';
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/PaginationActions';
import { Dialog, ConfirmDialog } from "views/Hoc/Dialog/GlobalDialog";
import { SplitEdit } from 'views/Hoc/Dialog/SplitEdit';
import Chip from '@mui/material/Chip';
import { GridCloseIcon } from '@material-ui/data-grid';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import RoomTypeImageModal from './RoomTypeImageModal';

function ManageEvent(props) {

  let eventId = props.location?.state?.eventId ? props.location?.state?.eventId : 0;
  const { control } = useForm({ mode: 'onBlur' });
  let [token, settoken] = useState(null);
  let seller = [{ id: 2, name: 'VENDOR', status: false }, { id: 3, name: 'DISTRIBUTOR', status: false }, { id: 4, name: 'RETAILER', status: false }, { id: 5, name: 'RESELLER', status: false }];
  let buyer = [{ id: 2, name: 'VENDOR', status: false }, { id: 3, name: 'DISTRIBUTOR', status: false }, { id: 4, name: 'RETAILER', status: false }, { id: 5, name: 'RESELLER', status: false }];

  const [imageSrc, setImageSrc] = useState('');
  const [imageSrcBanner, setImageBanner] = useState('');

  const [channelPackIds, setChannelPack] = useState([]);
  const [state, setState] = useState({
    eventId: '',
    eventCatalystId: null,
    eventName: '',
    eventDescription: '',
    fromDate: null,
    toDate: null,
    fromTime: null,
    toTime: null,
    timeZone: '',
    address: '',
    country: [],
    city: '',
    zipCode: '',
    type: '1',
    eventLogo: '',
    eventLogoId: '',
    eventBanner: '',
    eventBannerId: '',
    sellerOption: seller,
    buyerOption: buyer,
    countryOptions: [],
    buyerPackages: [],
    sellerPackages: [],
    deletedPackages: [],
    vendorStatus: null,
    buyerStatus: null,

    eventParticipants: [],
    eventChannels: [],
    master: [],
    channelTypeCount: [],
    usersCount1: {
      "2": { "channelType": '2', "approved": 0, "not_approved": 0 },
      "3": { "channelType": '3', "approved": 0, "not_approved": 0 },
      "4": { "channelType": '4', "approved": 0, "not_approved": 0 },
      "5": { "channelType": '5', "approved": 0, "not_approved": 0 },
    },
    usersCount: []
  });

  const [loading, setLoading] = useState(false);

  const [splitFlag, setSplitFlag] = useState(false);
  const [splitData, setSplitData] = useState(false);

  const [changeDelegateFlag, setChangeDelegate] = useState(false);



  const [editUser, editUserFlag] = useState(false);
  const [editBuyerChannel, setBuyerChannel] = useState(false);
  const [editUserData, setEditUserData] = useState({});
  const[bookHotelModal, setBookHotelModal] = useState(false);
  const[hotelName, setHotelName] = useState([]);
  const[room, setRoom] = useState([]);
  const[selectHotel, setSelectHotel] = useState(null);
  const[selectRoom, setSelectRoom] = useState(null);
  const[loading2, setLoading2] = useState(false);
  const[loading3, setLoading3] = useState(false);
  const[event_ID, setEventId] = useState(null);
  const[fromDate, setFromDate] = useState(null);
  const[toDate, setToDate] = useState(null);
  const[eventDatesArray, setEventDatesArray] = useState([]);
  const[dateLabel, setDateLabel] = useState([])
  const[userID, setUserID] = useState(null)
  const[contactID, setContactID] = useState(null)
  const[hotelBookByUser, setHotelBookByUsers] = useState([]);
  const[avliableHotelBooking, setAvaliableHotelBooking] = useState(null);
  const[avliableHotelBooking2, setAvaliableHotelBooking2] = useState(null);
  const[updateHotelFlag, setUpdateHotelFlag] = useState(false)
  let [isModalPopup2, setModalPopup2] = useState(false);
  let [modalPopValue2, setModalPopValue2] = useState({ message: null, title: null, value: null, popUpId: null });
  const [hotelCancelUserId, setHotelCancelUserId] = useState(null)
  const[lastBookingDate, setLastBookingDate] = useState(null);
  const[UpdateAndCancelLastDate, setUpdateAndCancelLastDate] = useState(null);
  const[roomTypeImage, setRoomTypeImage] = useState([])
  const[roomTypeImageIndex, setRoomTypeImageIndex] = useState(0);
  const[roomTypeImageModal,setRoomTypeImageModal] = useState(false);
  const[imageItem,setImageItem] = useState([])
  const [SelectedRoom_Id, setSelectedRoom_ID] = useState(null);
  const[roomNotAvaliable, setRoomNotAvaliable] = useState(false);
  const[roomAndSubBookData, setRoomAndSubBookData] = useState({});
  const openModalPopUp2 = (userId) => {
    modalPopValue2.title = 'Hotel Booking Cancel';
    modalPopValue2.message = 'You want to Cancel Booking';
    modalPopValue2.popUpId = 1;
    setModalPopValue2({ ...modalPopValue2 })
    setHotelCancelUserId(userId)
    setModalPopup2(true);
  };

  const closeModalPopup2 = ()=>{
    setModalPopup2(false);
  }

  const [searchState, setSearchState] = useState({
    dropdownList: [],
    search: null
  });

  let [channelType, setChannelType] = useState([]);
  let [paymentStatus, setPaymentStatus] = useState(null);
  let [catalystStatus, setCatalystStatus] = useState(null);

  var userData = JSON.parse(localStorage.getItem('CHuser'));
   
  const handleHotelModal = async(x)  =>{
    setContactID(x.user.contactId)
    setUserID(x.user.userId)
    setBookHotelModal(true);
    setLoading2(true)
    await axios.get(`/channelAdmin/getHotelsByEventId/${event_ID}`)
    .then((res)=>{
      const hotelData = res.data.map((item)=>({id:item.id, name:item.hotelName}));
      setHotelName(hotelData)
      setLoading2(false)})
    .catch((err)=>console.log(err))
  }

  const closeModal = ()=>{
    setSelectHotel(null)
    setSelectRoom(null)
    setBookHotelModal(false)
    setUpdateHotelFlag(false)
    setRoomTypeImage([])
    setRoomTypeImageIndex(0)
    setSelectedRoom_ID(null);
  }

  const labelChange = (e, value)  =>{ 
     const isChecked = e.target.checked
     const selectRoomdata = selectRoom;

     if(isChecked == true){
      //  setAvaliableHotelBooking(avliableHotelBooking-1)
       if(selectRoomdata.dateCounts[value]  == (null || undefined)){
        selectRoomdata.dateCounts[value] = 1
       }else{
        selectRoomdata.dateCounts[value] = selectRoomdata.dateCounts[value]+1
       }

 
       if( selectRoomdata.dateCounts[value] > getRoomAndSubBookByDate(value)){
          ToastsStore.warning("Room is not avaliable");
          selectRoomdata.dateCounts[value] = selectRoomdata.dateCounts[value]-1
       }else{
        const updateArray = [...dateLabel]
        updateArray.push(value)
        setDateLabel(updateArray)
       }

       setSelectRoom(selectRoomdata); 

     }else{
      const updateArray = dateLabel.filter((item)=>{
        return item != value
      })
       setDateLabel(updateArray)
      //  setAvaliableHotelBooking(avliableHotelBooking+1)
       selectRoomdata.dateCounts[value] = selectRoomdata.dateCounts[value]-1
       setSelectRoom(selectRoomdata); 
     }

 
  }
 
  const getRooms = async(id) =>{
    console.log(eventId)
    setLoading3(true)
        await axios.get(`/channelAdmin/getHotelRooms/${id}/${eventId}`)
      .then((res)=>{
        if(res.data.length == 0){
          setRoom(res.data)
        }else{
          const roomdata = res.data.result.map((item)=>({roomId:item.id, name:item.roomName, avaliable:item.roomCount,subBooking:item.subBooking, totalBooking:item.totalBooking, dateCounts:item.dateCounts, image:item.image}))
          setRoomAndSubBookData(res.data.roomAndSubBookData);
          setRoom(roomdata)
        }
        setLoading3(false)
    })
      .catch((err)=>console.log(err))
  }

  const getRoomAndSubBookByDate = (date)=>{
    let count = 0;
    let data = roomAndSubBookData[selectRoom.roomId]
    if(data == undefined){
      return 0;
    }

    for(let i=0; i<data.length; i++){
      if(data[i].date == date){
        count = data[i].room + data[i].subBooking
        break;
      }
    }
    return count
  }

  const handleHotelBooking = (input, value) =>{
     if(input == 'selectHotel'){
      setSelectRoom(null)
      setAvaliableHotelBooking(null)
      setAvaliableHotelBooking2(null)
      setDateLabel([])
      if(value == null){
        setSelectHotel(null);
        return
      }
        setSelectHotel(value)
        getRooms(value.id)

     }else if(input == 'selectRoom'){
      if(value == null){
        setSelectRoom(null);
      setAvaliableHotelBooking(null) 
      setAvaliableHotelBooking2(null)
      setRoomTypeImage([])
        return
      }

      setSelectRoom(value)
      setRoomTypeImage(value.image)
      setAvaliableHotelBooking(Number(value.avaliable) + Number(value.subBooking) - Number(value.totalBooking))
      setAvaliableHotelBooking2(Number(value.avaliable) + Number(value.subBooking))
     }
  }

  //handle roomTypeImageModal

  const handleRoomTypeImageModal = (item)=>{
    setRoomTypeImageModal(true)
    setImageItem(item.image);
  }

  //handle roomTypeImageModal


  const validation = ()=>{
  if(selectHotel == null){
      ToastsStore.warning('Please Select Hotel') 
      return false
    }else if(selectRoom == null){
      ToastsStore.warning('Please Select Room Type') 
      return false
    }else if(roomNotAvaliable){
      ToastsStore.warning('Room is not available') 
      return false
    }else if(dateLabel.length < 1){
      ToastsStore.warning('Please Select Booking Dates') 
      return false
    }else{
      return true;
    }
  }

  const hotelBooking = async() =>{
    if(validation() == false){
        return
    }
    const bookingDate = dateLabel.join(',');
    let data = {
      eventId:event_ID,
      hotelId: selectHotel.id,
      roomTypeId:selectRoom.roomId,
      userId:userID,
      contactId:contactID,
      bookingDate:bookingDate,
      totalBooking:dateLabel.length
    }

    closeModal();
    setLoading(true);
    await axios.post(`/channelAdmin/hotelBook`, data)
    .then(async(res)=>{
      await getHotelBookings(eventId)
      setLoading(false)
      ToastsStore.success('Hotel Booking Successfully'); 
    })
    .catch((err)=>{ToastsStore.warning('something went wrong');console.log(err)})
  }

  const updateBookingModel = async(x)=>{
    setUpdateHotelFlag(true)
    setContactID(x.user.contactId)
    setUserID(x.user.userId)
    setBookHotelModal(true);
    setLoading2(true)

    try {
      await axios.get(`/channelAdmin/getHotelsByEventId/${event_ID}`)
      .then((res)=>{
        const hotelData = res.data.map((item)=>({id:item.id, name:item.hotelName}));
        setHotelName(hotelData)})

        let value1;
        let value2;
        await axios.get(`/channelAdmin/getBookingDataByUserId/${x.user.userId}`)
        .then((res)=>{
          value1 = {id:res.data.userData[0].hotelId, name:res.data.userData[0].hotelName}
          setSelectHotel(value1)
          value2 = {roomId:res.data.userData[0].roomId, name:res.data.userData[0].roomName, avaliable:res.data.userData[0].roomCount,subBooking:res.data.userData[0].subBooking, totalBooking:res.data.totalBooking , dateCounts:res.data.dateCounts}
          setDateLabel(res.data.userData[0].bookingDate.split(','))
          setSelectRoom(value2)
          console.log(value2.roomId)
          setSelectedRoom_ID(value2.roomId)
          setAvaliableHotelBooking(Number(value2.avaliable) + Number(value2.subBooking) - Number(value2.totalBooking))
          setAvaliableHotelBooking2(Number(value2.avaliable) + Number(value2.subBooking))
        })

       await axios.get(`/channelAdmin/getHotelRooms/${value1.id}/${eventId}`)
      .then((res)=>{
        const roomdata = res.data.result.map((item)=>({roomId:item.id, name:item.roomName, avaliable:item.roomCount, dateCounts:item.dateCounts,image:item.image}))
        setRoom(roomdata)
        setRoomAndSubBookData(res.data.roomAndSubBookData)
        setLoading2(false)
       })
        

    } catch (error) {
      console.log(error)
    } 
  }


  const updateHotelBooking = async() =>{
    if(validation() == false){
        return
    }

    const bookingDate = dateLabel.join(',');
    let data = {
      eventId:event_ID,
      hotelId: selectHotel.id,
      roomTypeId:selectRoom.roomId,
      userId:userID,
      contactId:contactID,
      bookingDate:bookingDate,
      totalBooking:dateLabel.length
    }

    closeModal()
    setLoading(true);
  await  axios.put(`/channelAdmin/hotelBookingUpdate`, data)
    .then(async(res)=>{
      await getHotelBookings(eventId)
      
      setLoading(false) 
      ToastsStore.success('Hotel Booking Update Successfully'); 
    }).catch((err)=>{ToastsStore.warning('something went wrong');console.log(err)})
  }

 
  const hotelBookingCancel = ()=>{  
    setModalPopup2(false)
    setLoading(true); 
    axios.delete(`/channelAdmin/hotelBookingCancel/${hotelCancelUserId}`)
    .then(async(res)=>{
      await getHotelBookings(eventId)
      setLoading(false)      
      ToastsStore.success('Hotel Booking Cancel Successfully'); 
    })
    .catch((err)=>{ToastsStore.warning('something went wrong');console.log(err)})
  }


  const getHotelBookings = async(id)=>{
    await axios.get(`/channelAdmin/getHotelBookingsUsers/${id}`)
    .then((res)=>{
      let array = []
      for(let i=0; i<res.data.length; i++){
        array[res.data[i].userId] = res.data[i]
      }

      setHotelBookByUsers(array)
    })
    .catch((err)=>console.log(err))
  }

  useEffect(()=>{
    if(eventId == null) return
    getHotelBookings(eventId)
  },[eventId])



  const openEditUser = (item) => {

    // console.log('public view', item);
    let packageList = [];
    if (item.channel.channelTypeId == 2)
      state.sellerPackages.filter(x => x.channelType == item.channel.channelTypeId && x.isSeller == 1 && x.eventId == item.eventId).map(x => {
        packageList.push({ id: x.packageId, accessBadgeAllowed: +x.accessBadgeAllowed, name: x.packageName, packageType: x.packageType, isPublic: x.isPublic });
      })
    else
      state.buyerPackages.filter(x => x.channelType == item.channel.channelTypeId && x.isBuyer == 1 && x.eventId == item.eventId).map(x => {
        packageList.push({ id: x.packageId, accessBadgeAllowed: +x.accessBadgeAllowed, name: x.packageName, packageType: x.packageType, isPublic: x.isPublic });
      })

    const chPackIds = channelPackIds?.filter(x => x.channelId == item.channelId).length ? channelPackIds.filter(x => x.channelId == item.channelId) : [];

    let data = {
      channelId: item.channelId,
      eventId: item.eventId,
      cType: item.channel.channelTypeId,
      pType: item.channel.channelTypeId == '2' ? '1' : '2',
      packageId: item.packageId,
      registeredBy: item.registeredBy,
      packageOptions: packageList,
      // selectedPackage: packageList.filter(x => (x.isPublic == '1' || chPackIds?.map(p => +p.packageId).includes(+x.id)))
      selectedPackage: chPackIds.length > 0 ? packageList.filter(x => (chPackIds?.map(p => +p.packageId).includes(+x.id))) : packageList.filter(x => (x.isPublic == '1'))
    };

    setEditUserData(data);

    //if(item.channel.channelTypeId!=2)
    // setBuyerChannel(true);
    // else       
    editUserFlag(true);



  }

  const saveSplit = async (splitErr, paymentSplit) => {

    if (!splitErr) {
      // console.log("paymentSplit:", paymentSplit);
      await axios.post('/channelAdmin/updatePaymentSplit',
        paymentSplit).then(res => {
          setSplitFlag(false);
        });
    }

  }



  const paymentSplit = (item) => {

    let pack = { id: item.package['packageId'], price: item.package['price'] };

    const splitData = { splits: item.channel.eventPayment.filter(x => x.eventId == item.eventId && x.paymentMethod == 2), eventId: item.eventId, pack: pack, channel: item.channel, contactList: item.channel.contacts };

    setSplitData(splitData);
    setSplitFlag(true);
  }

  const handleClose = () => {
    setSplitFlag(false);
  }

  const split = () => {
    return (
      <div>
        <Modal className="modal-dialog-centered" fade={false} isOpen={splitFlag} size="xg" style={{ minWidth: 900, width: '30%' }}>
          <div className="modal-header">
            <strong>{'Payment Split'}</strong>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <SplitEdit paymentSplit={saveSplit} splits={splitData.splits} eventId={splitData.eventId} pack={splitData.pack} channel={splitData.channel} contactList={splitData.contactList} />

          </div>
        </Modal>
      </div>
    )
  }

  const closeEditUser = () => {
    setEditUserData({});
    setBuyerChannel(false);
    editUserFlag(false);
  }

  const getEventSearchList = async () => {
    await axios.post('/channelAdmin/pendingEventChannelsList', { userId: userData.userId, eventId: eventId }).then(res => {
      let data = [];
      data.push(...res.data)
      data = data.map(x => {
        return { name: x.name.trim(), id: x.id }
      });
      let contact = [];
      res.data.map(x => {
        x.contacts.map(c => {
          contact.push({ name: c.firstName + ' ' + c.lastName, id: x.id, companyName: x.name.trim() });
        })
      })
      data.push(...contact);
      searchState.dropdownList = data;
      setSearchState({ ...searchState });
    });
  }

  useEffect(() => {

    (async () => {

      if (eventId) {
        await EventDetailsApi();
      }

    })();

  }, []);

  const EventDetailsApi = async () => {
    getEventDetails({ tabChange: true });
    getEventSearchList();
    await eventAuth();
  }

  const eventAuth = () => {
    setLoading(true);
    // eventRegister();
    if (!localStorage.getItem('eventcatalyst_token')) {
      //   let body = new URLSearchParams();
      //   body.set('grant_type', process.env.REACT_APP_EVENTCAT_GRANT_TYPE);
      //   body.set('username', process.env.REACT_APP_EVENTCAT_USERNAME);
      //   body.set('password', process.env.REACT_APP_EVENTCAT_PASSWORD);
      //   const requestOptions = {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
      //     body: body.toString()
      // };
      // console.log('body',process.env);
      let req = {
        url: process.env.REACT_APP_EVENTCATALYST_API + 'oauth/token'
      }
      commonService.postService(req, 'account/eventcatalystToken').then(res => {
        if (res.data?.error) {
          setLoading(false);
          ToastsStore.warning('Something went wrong');
        } else {
          localStorage.setItem('eventcatalyst_token', JSON.stringify(res.data));
        }
      }).catch(err => {
        setLoading(false);
        console.log('err', err)
      })
    }
  }

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function getDatesBetween(fromDate, toDate) {
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);
    const datesArray = [];
 
    while (startDate <= endDate) { 
      datesArray.push(formatDate(new Date(startDate)))
      startDate.setDate(startDate.getDate() + 1);
    }

    return datesArray;
}

  const getEventDetails = async (data) => {
    setLoading(true);

    if (data.tabChange) {
      let data = {
        //search: searchState.search ? searchState.search[0] ? searchState.search[0].id : null : null,
        channelType: channelType ? channelType.map(x => x.id) : null,
        paymentStatus: paymentStatus ? paymentStatus.id : null,
        isEventCatalyst: catalystStatus?.id == 1 ? '1' : catalystStatus?.id == 2 ? '0' : null,
        signupstatusid: [14],
        userId: userData.userId,
        eventId: eventId,
        filter: {
        },
        page: page * rowsPerPage,
        order: isSort ? sort : false,
        limit: rowsPerPage
      }

      await axios.post('/channelAdmin/getEventDetails/', data).then(res => {
        console.log('response Data', res.data);

        if (res) {
          setEventId(res.data.eventDetails.id)
          const startDate = new Date(res.data.eventDetails.fromDate);
          const endDate = new Date(res.data.eventDetails.toDate); 
          startDate.setDate(startDate.getDate() - 3);
          endDate.setDate(endDate.getDate() + 3);
          const retArray = getDatesBetween(startDate, endDate)
          setEventDatesArray(retArray);

          //last booking date and last update and cancel booking date
          startDate.setDate(startDate.getDate() - 5);
          setLastBookingDate(endDate);
          setUpdateAndCancelLastDate(startDate)

          let event = res.data.eventDetails;
          setChannelPack(res.data.channelPackage);

          state.eventId = event.id;
          state.eventBanner = event.banner?.documentPath;
          state.eventCatalystId = event.eventCatalystId;
          setImageBanner(state.eventBanner);
          state.eventBannerId = event.eventBannerId;
          state.eventLogo = event.logo?.documentPath;
          setImageSrc(state.eventLogo);
          state.eventLogoId = event.eventLogoId;
          state.eventName = event.name;
          state.eventDescription = event.description;
          state.country = { name: event.country?.country, id: event.countryId };
          state.address = event.address;
          state.city = event.city;
          state.zipCode = event.zipcode;
          state.fromDate = event.fromDate;
          state.toDate = event.toDate;
          state.fromTime = event.fromTime;
          state.toTime = event.toTime;
          state.timeZone = event.timezone;
          state.type = event.type + '';
          state.buyerStatus = +event.buyerStatus;
          state.vendorStatus = +event.vendorStatus;

          if (event.type != 2) {
            state.buyerPackages = event.packages.length ? event.packages.filter(x => x.isBuyer == '1') : [];
            state.sellerPackages = event.packages.length ? event.packages.filter(x => x.isSeller == '1') : [];

            state.buyerPackages.map(x => {
              x.packageDescription = x.description;
              x.meetingsAllowed = x.no_of_meeting_allowed;
              x.accessBadgeAllowed = x.no_of_access_badge;
              x.privateWebinar = x.no_of_private_webinar;
              x.firesideChat = x.no_of_fireside_chat;
              x.subscriptionToCH = x.subs_to_chPlatform;
              x.emailMarketing = x.emailMarketing;
            })

            state.sellerPackages.map(x => {
              x.packageDescription = x.description;
              x.meetingsAllowed = x.no_of_meeting_allowed;
              x.accessBadgeAllowed = x.no_of_access_badge;
              x.privateWebinar = x.no_of_private_webinar;
              x.firesideChat = x.no_of_fireside_chat;
              x.subscriptionToCH = x.subs_to_chPlatform;
              x.emailMarketing = x.emailMarketing;
            })
          }

          state.buyerOption = state.buyerOption.map(x => {
            if (event.buyers.split(',').map(x => Number(x)).includes(x.id))
              x.status = true;
            return x;
          })

          state.sellerOption = state.sellerOption.map(x => {
            if (event.sellers.split(',').map(x => Number(x)).includes(x.id))
              x.status = true;
            return x;
          })

          // state.buyerOption = state.buyerOption.map(x => {
          //   if (state.buyerPackages.filter(y => y.channelType == x.id).length)
          //     x.status = true;
          //   return x;
          // })

          // state.sellerOption = state.sellerOption.map(x => {
          //   if (state.sellerPackages.filter(y => y.channelType == x.id).length)
          //     x.status = true;
          //   return x;
          // })

          state.master = res.data.eventChannels.rows;
          state.eventChannels = res.data.eventChannels.rows.slice(page * rowsPerPage, rowsPerPage + page * rowsPerPage);

          state.total = res.data.eventChannels.count;
          state.channelTypeCount = res.data.channelTypeCount;

          res.data.usersCount.map(x => {
            if (x.status == '1') {
              state.usersCount1[x.channelType].approved = x.count;
            } else {
              state.usersCount1[x.channelType].not_approved = x.count;
            }
          })

          let dummy = [];
          for (const keys in state.usersCount1) {
            dummy.push(state.usersCount1[keys]);
          }

          state.usersCount = dummy;
          setState({ ...state });

        }

        setLoading(false);
      }).catch(err => setLoading(false));
    }
    else {
      let search = searchState.search ? searchState.search[0] ? searchState.search[0].id : null : null;

      if (search) {
        state.eventChannels = state.master.filter(x => x.channelId == search);

      }
      else if (channelType.length > 0) {
        let filter = state.master.filter(x => { return channelType.map(x => x.id).includes(x.channel.channelTypeId); })

        state.eventChannels = filter.slice(page * rowsPerPage, rowsPerPage + page * rowsPerPage);
      }

      else
        state.eventChannels = state.master.slice(page * rowsPerPage, rowsPerPage + page * rowsPerPage);

      setState({ ...state });
      setLoading(false);
    }
  };


  const getChannelByHotelStatus = (input)=>{ 
    let bookHotelChannel = []
    let bookHotelChannelIndex = 0;
    let NoHotelbookChannel = []
    let NoHotelbookChannelIndex = 0;
 
    for(let i=0; i<state.master.length; i++){
      let channelId = state.master[i].channel.channelId
      let contacts = state.master[i].channel.contacts;
 
      let flag = true;
      let secondFlag = true;
      for(let j=0; j<contacts.length; j++){
        if (contacts[j] && contacts[j].user && (hotelBookByUser[contacts[j].user.userId] === null || hotelBookByUser[contacts[j].user.userId] === undefined)) {
           flag = false
        }else{
          secondFlag = false;
        }
      } 

    const array = state.master.filter(item => item.channelId == channelId)
 
    if(flag == false && secondFlag == true){
      for(let i=0; i<array.length; i++){
        NoHotelbookChannel[NoHotelbookChannelIndex] = array[i];
        NoHotelbookChannelIndex++;
      }
    }else if(flag == true){
      for(let i=0; i<array.length; i++){
        bookHotelChannel[bookHotelChannelIndex] = array[i];
        bookHotelChannelIndex++;
      }
    }

  }
 
 
  if(input == (null||undefined)){
  state.eventChannels = state.master.map(x => x);
  }else if(input.name == "Booked"){
    state.eventChannels =  bookHotelChannel;
  }else if(input.name == "Not Booked"){
    state.eventChannels =  NoHotelbookChannel;
  }else{
    state.eventChannels = state.master.map(x => x);
  }
  setState({ ...state });
    
}

  const boEventUserChange = (data) => {
    ToastsStore.success('saved');
    getEventDetails({ tabChange: true });
  }

  const eventRegister = async (channel) => {

    let contacts = channel.channel?.contacts ? channel.channel?.contacts : [];

    let userData = channel.channel;
    let newEventUsers = [];
    if (contacts.length) {
      contacts.map(x => {
        let latestEvent = x.eventParticipants.find(x => x.eventId == channel.eventId);
        if (latestEvent && latestEvent.isEventCatalyst == '0') {
          newEventUsers.push({ contactId: +x.contactId, eventId: channel.eventId });
        }
      })
    }

    if (newEventUsers.length) {
      await axios.post('channelAdmin/boToEventCatalyst', newEventUsers).then(res => {
        eventCatalystRegister(userData, res.data);
        ToastsStore.success('Successfully registered to Event Catalyst');
      }).catch(err => ToastsStore.warning('something went wrong'));
    }
    getEventDetails({ tabChange: true });
  }

  const getPublicToken = async (channelId) => {
    // return new Promise((resolve) => {
    // commonService.getServices('channel/eventRegister/' + contactId).then(async res => {
    // profileData = res.data;
    // setProfileData(profileData);
    // console.log('res.data', res.data);
    let tokenData = await commonService.getServices('account/getPublicToken/' + channelId);
    if (tokenData) {
      //console.log('tokenData', tokenData);
      token = tokenData.data.token;
      settoken(token);
      // resolve(true);
    }// else
    //resolve(false);
    // }, err => {
    //   resolve(false);
    //   console.log('err', err);
    // });
    //})
  }

  const eventCatalystRegister = async (userData, eventCatalystData) => {

    if (eventCatalystData.length) {
      await getPublicToken(userData.channelId);
      let req = {
        url: process.env.REACT_APP_EVENTCATALYST_API + 'oauth/token'
      }

      let catalystToken = await commonService.postService(req, 'account/eventcatalystToken').then(res => {
        if (res.data?.error) {
          ToastsStore.warning('Something went wrong');
        } else {
          return res.data;
        }
      }).catch(err => {
        console.log('err', err)
      })

      //sending first user to event catalyst
      const x = eventCatalystData[0];

      let pack = x.package.filter(p => p.contactId == x.contactId);

      let dec = x.detailDesc ? x.detailDesc : null
      console.log('dec', dec);
      if ((dec === null) || (dec === '')) {
        dec = null;
      } else {
        dec = dec.toString();
        dec = dec.replace(/(<([^>]+)>)/ig, '').trim();
      }

      let data = {
        "participantId": parseInt(x.contactId),
        "firstName": x.firstName,
        "lastName": x.lastName,
        "email": x.email,
        "companyName": x.companyName,
        "companyId": parseInt(userData.channelId),
        "eventId": state.eventCatalystId,
        "chEventId": state.eventId,
        "status": "Confirmed",
        "type": userData.channelTypeId === '2' ? "Vendor" : "Buyer",
        "typeId": parseInt(userData.channelTypeId),
        "timezone": "",
        "matchingScore": [],
        "country": x.isoCode,
        "profileUrl": process.env.REACT_APP_LOCALSITE + 'channelView/' + userData.channelId + '?token=' + token,
        "photoUrl": x.profileimage,
        "mobile": x.mobileNo,
        "jobTitle": x.jobTitle,
        "category": userData.channelTypeId === '2' ? 'Vendor' : userData.channelTypeId === '3' ? 'Distributor' : userData.channelTypeId === '4' ? 'Retailer' : 'Reseller',
        "logoUrl": x.companylogo,
        "existingCategory": x.ExistingCategory ? x.ExistingCategory.replace(/,/g, "|") : null,
        "interestedCategory": x.IntrestedCategory ? x.IntrestedCategory.replace(/,/g, "|") : null,
        "profileType": x.ProfileType ? x.ProfileType : null,
        "retailCustomerProfile": x.Retail_Customer_Profile ? x.Retail_Customer_Profile.replace(/,/g, "|").replace(/,/g, "|") : null,
        "detailDesc": dec,
        "password": x.password ? x.password : null,
        "package": pack.length > 0 ? pack[0] : {},
      }

      // // Status: Invited, Shortlist, Confirmed, Rejected, Cancelled, WaitingList, Declined
      let body = {
        token: catalystToken, //JSON.parse(localStorage.getItem('eventcatalyst_token')),
        url: process.env.REACT_APP_EVENTCATALYST_API + 'api/registration',
        body: data
      }


      await commonService.postService(body, 'account/postEventRegister').then(async res => {
        setLoading(false);
        await setEventContact(+x.contactId)
        await setEventParticipantStatus(eventId, +userData.channelId, +x.contactId);
      });
      //End sending first user to event catalyst


      if (eventCatalystData.length > 1) {
        setTimeout(async () => {
          eventCatalystData.slice(1).map(async x => {

            let pack = x.package.filter(p => p.contactId == x.contactId);

            let dec = x.detailDesc ? x.detailDesc : null
            console.log('dec', dec);
            if ((dec === null) || (dec === '')) {
              dec = null;
            } else {
              dec = dec.toString();
              dec = dec.replace(/(<([^>]+)>)/ig, '').trim();
            }

            let data = {
              "participantId": parseInt(x.contactId),
              "firstName": x.firstName,
              "lastName": x.lastName,
              "email": x.email,
              "companyName": x.companyName,
              "companyId": parseInt(userData.channelId),
              "eventId": state.eventCatalystId,
              "chEventId": state.eventId,
              "status": "Confirmed",
              "type": userData.channelTypeId === '2' ? "Vendor" : "Buyer",
              "typeId": parseInt(userData.channelTypeId),
              "timezone": "",
              "matchingScore": [],
              "country": x.isoCode,
              "profileUrl": process.env.REACT_APP_LOCALSITE + 'channelView/' + userData.channelId + '?token=' + token,
              "photoUrl": x.profileimage,
              "mobile": x.mobileNo,
              "jobTitle": x.jobTitle,
              "category": userData.channelTypeId === '2' ? 'Vendor' : userData.channelTypeId === '3' ? 'Distributor' : userData.channelTypeId === '4' ? 'Retailer' : 'Reseller',
              "logoUrl": x.companylogo,
              "existingCategory": x.ExistingCategory ? x.ExistingCategory.replace(/,/g, "|") : null,
              "interestedCategory": x.IntrestedCategory ? x.IntrestedCategory.replace(/,/g, "|") : null,
              "profileType": x.ProfileType ? x.ProfileType : null,
              "retailCustomerProfile": x.Retail_Customer_Profile ? x.Retail_Customer_Profile.replace(/,/g, "|").replace(/,/g, "|") : null,
              "detailDesc": dec,
              "password": x.password ? x.password : null,
              "package": pack.length > 0 ? pack[0] : {},
            }

            // // Status: Invited, Shortlist, Confirmed, Rejected, Cancelled, WaitingList, Declined
            let body = {
              token: catalystToken, //JSON.parse(localStorage.getItem('eventcatalyst_token')),
              url: process.env.REACT_APP_EVENTCATALYST_API + 'api/registration',
              body: data
            }

            await commonService.postService(body, 'account/postEventRegister').then(async res => {
              setLoading(false);
              await setEventContact(+x.contactId)
              await setEventParticipantStatus(eventId, +userData.channelId, +x.contactId);
            });

          })
        }, 5000);
      }
    }
  }

  const setEventContact = async (contactId) => {
    await commonService.getServices('channelAdmin/setEventContact/' + contactId).then(res => {

    }, err => {
      console.log('err', err)
    });
  }

  const setEventParticipantStatus = async (eventId, channelId, contactId) => {
    let data = {
      eventId: eventId,
      channelId: channelId,
      contactId: contactId
    }
    await commonService.postService(data, 'channelAdmin/setEventParticipantStatus/').then(res => {
      getEventDetails({ tabChange: true });
    }, err => {
      console.log('err', err)
    });
  }

  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });

  const confirmPopup = (res) => {


    if (res.popUpId == 2) {
      cancelEventApproval(res.value);
    }
    if (res.popUpId == 1) {
      removeChannelFromEvent(res.value);
    }
    if (res.popUpId == 4) {
      removeParticipantFromEvent(res.value);
    }
    if (res.popUpId == 3) {
      eventCancelDismiss(res.value);
    }
    setModalPopup(false);
  }
  const closePopup = () => {
    setModalPopup(false);
  };




  const handleDivClick = (item) => {
    setSelectedRoom_ID(item.roomId);
    setSelectRoom(item)
    setAvaliableHotelBooking(Number(item.avaliable) + Number(item.subBooking) - Number(item.totalBooking))
    setAvaliableHotelBooking2(Number(item.avaliable) + Number(item.subBooking))
    setDateLabel([])
  };

  const getContainerStyle = (roomID) => ({
    cursor: "pointer",
    width: "82%", 
    height:"110%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "0.9rem",
    marginTop: "0.7rem",
    marginLeft: "1rem",
    marginRight: "0.8rem",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    border: SelectedRoom_Id === roomID ? "3px solid #11cdef" : "3px solid #e0e0e0",
    transition: "border 0.3s ease, padding 0.3s ease",
  });
  const imgStyle = {
    width: "76%", 
    height:"110%", 
    borderRadius: "10px",
    marginTop: "27px",
    transform: "scale(1.1)",
    transition: "transform 0.3s ease",
  };
    
  const removeChannelPop = (data) => {

    let normalmessage = <>

      <p>Do you want to remove the channel {data.channel?.companyName} from event ? </p>
    </>;

    modalPopValue.message = normalmessage;
    modalPopValue.title = 'Event Channel Removal';
    modalPopValue.popUpId = 1;
    modalPopValue.isRemoveFromEvent = true;
    modalPopValue.value = data;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);
  }

  const removeParticipantPop = (data) => {

    //console.log("removeparti:", data);
    let pack = data.channel['contacts'].find(con => con.contactId == data.contactId).eventParticipants.find(part => part.contactId == data.contactId && part.eventId == data.eventId).package;

    let participant = data.channel['contacts'].find(con => con.contactId == data.contactId).eventParticipants.find(part => part.contactId == data.contactId && part.eventId == data.eventId);
    if (pack.packageType == 1 && pack.packageUser == 1 && data.registeredBy == participant.contactId)
      setChangeDelegate(true);
    else {
      let normalmessage = <>
        <p>Do you want to remove {data.firstName + ' ' + data.lastName} of {data.channel?.companyName} from event ? </p>
      </>;

      modalPopValue.message = normalmessage;
      modalPopValue.title = 'Event Participant Removal';
      modalPopValue.popUpId = 4;
      modalPopValue.isRemoveFromEvent = true;
      modalPopValue.value = data;
      setModalPopValue({ ...modalPopValue });
      setModalPopup(true);
    }


  }


  const removeChannelFromEvent = async (item) => {

    setLoading(true);
    let data = {
      eventId: eventId,
      channelId: item.channelId
    }

    let req = {
      url: process.env.REACT_APP_EVENTCATALYST_API + 'oauth/token'
    }

    let catalystToken = await commonService.postService(req, 'account/eventcatalystToken').then(res => {
      if (res.data?.error) {
        ToastsStore.warning('Something went wrong');
      } else {
        return res.data;
      }
    }).catch(err => {
      console.log('err', err)
    })

    await axios.delete('channelAdmin/removeChannelFromEvent', { data: data }).then(res => {
      ToastsStore.success('Channel Removed from Event Successfully');
      item.channel.contacts.map(async x => {
        let body = {
          token: catalystToken,//JSON.parse(localStorage.getItem('eventcatalyst_token')),
          url: process.env.REACT_APP_EVENTCATALYST_API + 'api/cancel',
          body: {
            eventId: state.eventCatalystId,
            participantId: +x.contactId
          }
        }
        await commonService.postService(body, 'account/removeParticipant');
      })
      getEventDetails({ tabChange: true });
    }, err => {
      console.log('err', err);
      ToastsStore.error('Something went wrong');
      setLoading(false);
    });
    setLoading(false);
  }

  const removeParticipantFromEvent = async (item) => {
    setLoading(true);





    let req = {
      url: process.env.REACT_APP_EVENTCATALYST_API + 'oauth/token'
    }

    let catalystToken = await commonService.postService(req, 'account/eventcatalystToken').then(res => {
      if (res.data?.error) {
        ToastsStore.warning('Something went wrong');
      } else {
        return res.data;
      }
    }).catch(err => {
      console.log('err', err)
    })


    let body = {
      token: catalystToken,//JSON.parse(localStorage.getItem('eventcatalyst_token')),
      url: process.env.REACT_APP_EVENTCATALYST_API + 'api/cancel',
      body: {
        eventId: state.eventCatalystId,
        participantId: item.contactId
      }
    }


    let data = {
      eventId: +item.eventId,
      contactId: +item.contactId,
      channelId: +item.channelId,
      additionalMembers: item.additionalMembers ? (+item.additionalMembers - 1) : null
    }

    await commonService.postService(body, 'account/removeParticipant').then(async res => {
      await axios.delete('channelAdmin/removeParticipantFromEvent', { data: data }).then(res => {
        ToastsStore.success('Participant Removed from Event Successfully');

        getEventDetails({ tabChange: true });
      }, err => {
        console.log('err', err);
        ToastsStore.error('Something went wrong');
        setLoading(false);
      });
    });


    setLoading(false);
  }

  const [editUserPackage, editUserPackageFlag] = useState(false);
  const [editUserPackageData, setEditUserPackageData] = useState({});

  const openEditUserPackage = (item) => {

    console.log('public viewss', item, state.buyerPackages);

    let packageList = [];
    if (item.eventParticipants?.find(part => part.eventId == item.eventId)?.package.isSeller == 1)
      state.sellerPackages.filter(x => x.channelType == item.channel.channelTypeId && x.isSeller == 1 && x.eventId == item.eventId).map(x => {
        packageList.push({ id: x.packageId, accessBadgeAllowed: +x.accessBadgeAllowed, name: x.packageName, packageType: x.packageType, packageUser: x.packageUser, isPublic: x.isPublic });
      })
    if (item.eventParticipants?.find(part => part.eventId == item.eventId)?.package.isBuyer == 1)
      state.buyerPackages.filter(x => x.channelType == item.channel.channelTypeId && x.isBuyer == 1 && x.eventId == item.eventId).map(x => {
        packageList.push({ id: x.packageId, accessBadgeAllowed: +x.accessBadgeAllowed, name: x.packageName, packageType: x.packageType, packageUser: x.packageUser, isPublic: x.isPublic });
      })

    const chPackIds = channelPackIds?.filter(x => x.channelId == item.channelId).length ? channelPackIds.filter(x => x.channelId == item.channelId) : [];

    let allPackage = packageList.filter(x => (x.isPublic == '1'));
    let data = {
      channelId: item.channelId,
      contactId: item.contactId,
      eventId: item.eventId,
      cType: item.channel.channelTypeId,
      pType: item.eventParticipants?.find(part => part.eventId == item.eventId)?.package.packageType,
      packageId: item.eventParticipants.find(part => part.eventId == item.eventId)?.packageId,
      isRegisteredUser: item.registeredBy == item.contactId,
      selectedPackage: chPackIds.length > 0 ? [...packageList.filter(x => (chPackIds?.map(p => +p.packageId).includes(+x.id))), ...allPackage] : allPackage //packageList.filter(x => (x.isPublic == '1'))

    };

    setEditUserPackageData(data);
    editUserPackageFlag(true);

  }

  const closeEditUserPackage = () => {
    setEditUserPackageData({});
    editUserPackageFlag(false);
  }

  const boUserPackageChange = () => {
    ToastsStore.success('saved');
    getEventDetails({ tabChange: true });
  }


  console.log('state', state);

  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [sort, setSort] = useState('');
  let [isSort, setisSort] = useState(false);
  let[hotelSearch, setHotelSearch] = useState(null);
  let[serachByTable, setSerachByTable] = useState(false);


  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);

    getEventDetails({ tabChange: false });

  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    callPendingChannel({ tabChange: false });
  };

  const handleChangeSelect = (input, value) => {
 
    setSerachByTable(true)
    if(input == "hotelSearch"){
        setHotelSearch(value); 
        getChannelByHotelStatus(value)
    }

    if (input == 'search') {
      searchState.search = [value]
      setSearchState({ ...searchState });
      callPendingChannel({ tabChange: false });
    }

    if (input == 'channelType') {
      // console.log("ctypes:", value);
      channelType = value;
      setChannelType(value);
      callPendingChannel({ tabChange: false });
    }

    if (input == 'paymentStatus') {
      console.log("paymentStatus:", value);
      paymentStatus = value;
      setPaymentStatus(value);
      callPendingChannel({ tabChange: true });
    }

    if (input == 'catalystStatus') {
      catalystStatus = value;
      setCatalystStatus(value);
      callPendingChannel({ tabChange: true });
    }


    if(value == null){
      setSerachByTable(false)
    }

  }

  const callPendingChannel = (data) => {
    // closeDrawer();
    page = 0;
    setPage(page);
    getEventDetails(data);
  }
  const setSorting = (data) => {
    isSort = true;
    setisSort(isSort);
    if (data != sort) {
      sort = data;
      setSort(sort);
      callPendingChannel({ tabChange: false });
    }
  }

  const clearSorting = () => {
    isSort = false;
    setisSort(isSort);
    callPendingChannel();
  }

  const openEventCancellation = (data) => {
    let normalmessage = <>

      <p>Do you want to allow the user {data.firstName + ' ' + data.lastName} of {data.channel?.companyName} to cancel Event Registration ? </p>
    </>;

    let maindelegatemsg = <p>Do you want to allow the Main Delegate {data.firstName + ' ' + data.lastName} of {data.channel?.companyName} to cancel Event Registration ? </p>

    modalPopValue.message = data.registeredBy == data.contactId ? maindelegatemsg : normalmessage;
    modalPopValue.title = 'Event Cancellation Approval ';
    modalPopValue.popUpId = 2;
    modalPopValue.value = data;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);

  }

  const eventCancelDismissPop = (data) => {
    let normalmessage = <>

      <p>Do you want to dismiss event cancellation request from the user {data.firstName + ' ' + data.lastName} of {data.channel?.companyName} ? </p>
    </>;


    modalPopValue.message = normalmessage;
    modalPopValue.title = 'Event Cancellation Dismissal ';
    modalPopValue.popUpId = 3;
    modalPopValue.value = data;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);

  }

  const cancelEventApproval = async (data) => {

    setLoading(true);
    let cancelData = {
      channelId: data.channelId,
      contactId: data.contactId,
      eventId: state.eventId
    };
    await axios.post('channelAdmin/eventCancelApproval', cancelData).then(res => {
      ToastsStore.success('Event Cancellation Request Accepted')
      axios.post('mailerservice/eventCancelApproval', {
        name: data.firstName + ' ' + data.lastName,
        eventName: state.eventName,
        packageName: data.eventParticipants[0]?.package?.packageName,
        approval: 'approved',
        email: data.email
      })
    }).catch(err => {
      ToastsStore.warning('Something went wrong');
      setLoading(false);
    });

    setLoading(false);
    await getEventDetails({ tabChange: true });

  }

  const eventCancelDismiss = async (data) => {

    setLoading(true);
    let cancelData = {
      channelId: data.channelId,
      contactId: data.contactId,
      eventId: state.eventId
    };
    await axios.post('channelAdmin/eventCancelDismiss', cancelData).then(res => {
      ToastsStore.success('Event Cancellation Request Dismissed')
      axios.post('mailerservice/eventCancelApproval', {
        name: data.firstName + ' ' + data.lastName,
        eventName: state.eventName,
        packageName: data.eventParticipants[0]?.package?.packageName,
        approval: 'rejected',
        email: data.email
      })
    }).catch(err => {
      ToastsStore.warning('Something went wrong');
      setLoading(false);
    });

    setLoading(false);
    await getEventDetails({ tabChange: true });

  }

  const User = {
    renderer: (row, rowIndex) => {
      console.log('rowuser', row, rowIndex);
      return (
        <div style={{ width: '100%' }}>
          {row.channel.contacts.length && row.channel.contacts.map((x, i) => {

            let paymentRecords = row.channel.eventPayment.filter(payment => payment.eventId == row.eventId && payment.contactId == x.contactId);
            let payment = '';
            if (paymentRecords.length) {
              if (paymentRecords.filter(x => x.eventId == row.eventId && x.paymentStatus == 0).length == 0) //.paymentstatus == '1')
                payment = 'Paid';
              else if (paymentRecords.filter(x => x.eventId == row.eventId && x.paymentStatus == 1).length && paymentRecords.filter(x => x.eventId == row.eventId && x.paymentStatus == 0).length)//(row.paymentstatus == '0' && row.paymentMethod == '2')
                payment = 'Partially Paid' //'waiting for approval'
              else if (paymentRecords.filter(x => x.eventId == row.eventId && x.paymentStatus == 1).length == 0 && paymentRecords.filter(x => x.eventId == row.eventId && x.paymentStatus == 0).length) //(row.paymentstatus!=1)
                payment = 'Pending';
            }
            else
              payment = 'Free';
            return <>
              <ListGroup className="list my--2" flush key={'table' + x.contactId} style={{overflowX:"auto", flexWrap:"whitespace"}}>
                <ListGroupItem className="px-0">
                  <Row className="align-items-center"  >
                    <Col className="col-1">
                      <a
                        className="avatar rounded-circle"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <img onError={(event) => { event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }}
                          alt="..."
                          src={x.document?.documentPath ? x.document.documentPath.includes(process.env.REACT_APP_CDN_URL) ? x.document.documentPath : process.env.REACT_APP_CDN_URL + x.document.documentPath : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}//{require("assets/img/theme/team-1.jpg")}
                        />
                      </a>
                    </Col>
                    <div className="col-lg-2 ml--2">
                      <p className="mb-0" style={{ fontSize: '0.7rem' }}>

                        {x.firstName + ' ' + x.lastName}

                      </p>
                      <small>{x.email}</small>



                    </div>

                    <div className="col-lg-1">
                      <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                        {x.jobTitle}
                      </p>
                    </div>
                    {/* <div className="col-lg-1 ml--2">
                              <p className="mb-0" style={{fontSize:'0.7rem'}}>
                              {x.hubspotContactId ? x.hubspotContactId : '-'}
                              </p>
                            </div> */}
                    <div className="col-lg-1 ">
                      <p className="mb-0" style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '0.7rem' }} id={'producttip' + x.contactId}>
                        {
                          x.productGroup?.length ? x.productGroup.map((y, i) => {
                            if (x.productGroup.length - 1 != i)
                              return (y.productGroup.productGroup + ', ');
                            return (y.productGroup.productGroup);
                          }) : <>-</>
                        }
                      </p>
                      <UncontrolledTooltip delay={0} target={'producttip' + x.contactId}>
                        {
                          x.productGroup?.length ? x.productGroup.map((y, i) => {
                            if (x.productGroup.length - 1 != i)
                              return (y.productGroup.productGroup + ', ');
                            return (y.productGroup.productGroup);
                          }) : <></>
                        }
                      </UncontrolledTooltip>
                    </div>

                    <div className="col-lg-1">
                      <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                        {payment == "Paid" ?
                          <Chip label={`${payment}`} style={{ backgroundColor: '#4caf50', color: '#fff' }} variant="filled" size="large" /> : payment == "Free" ? <Chip label={`${payment}`} style={{ backgroundColor: '#2c9db7', color: '#fff' }} variant="filled" size="large" /> :
                            <Chip label={`${payment}`} style={{ backgroundColor: '#fb6340', color: '#fff' }} variant="filled" size="large" />}

                      </p>
                    </div>

                    <div className="col-lg-1">
                      <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                        {/* {x.user?.role?.roleId == '1' ? 'Account Owner' : x.user?.role?.roleId == '2' ? 'Admin' : 'Primary'} */}
                        {(x.eventParticipants?.find(pack => pack.eventId == row.eventId)?.package?.packageType == 1 && x.eventParticipants?.find(pack => pack.eventId == row.eventId)?.package?.packageUser == 1) ? 'Primary' : 'Secondary'}
                      </p>
                    </div>
                   
                    <div className="col-lg-1">
                      <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                        {x.eventParticipants?.find(pack => pack.eventId == row.eventId)?.package?.packageName}
                        {/* {x.isShared == '0' ? 'Confidential' : 'Shared'} */}
                      </p>
                    </div>
                    {/* <div className="col-lg-1 ml--2">
                      <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                        {x.country?.country}
                      </p>
  
                    </div> */}

{
                    // Date.now() <= lastBookingDate &&  
                   
                    hotelBookByUser[x.user.userId] == (null && undefined)  ? 
                    <div className='col-lg-2 ml-1'>
                  
                    <button className='btn btn-icon btn-primary btn-sm' id='hotelBookingId' style={{fontSize:"0.7rem", border:"none"}} onClick={()=>handleHotelModal(x)}>Hotel Booking</button>
                    {/* <UncontrolledTooltip delay={0} target={'hotelBookingId'}>
                        Hotel Booking
                      </UncontrolledTooltip> */}
                      </div>
                      : 
                      <>
                     {  Date.now() > UpdateAndCancelLastDate &&
                  <div className='col-lg-2 ml-1'> 
            <p style={{fontSize:"0.7rem"}}>{hotelBookByUser[x.user.userId].hotelName.substring(0, 15)}...<span style={{fontSize:"0.7rem", marginLeft:"20px"}}>{hotelBookByUser[x.user.userId].roomName.substring(0, 15)}...</span></p>
          
                      
                      <button className='btn btn-icon btn-primary btn-sm' id='hotelBookingId1' style={{backgroundColor:"#4CAF50", border:"none", fontSize:"0.7rem"}} onClick={()=>updateBookingModel(x)}>Update Booking</button>
                    {/* <UncontrolledTooltip delay={0} target={'hotelBookingId1'}>
                       Update Booking
                      </UncontrolledTooltip> */}

                      <button className='btn btn-icon btn-primary btn-sm' id='hotelBookingId2' style={{backgroundColor:"crimson", border:"none", fontSize:"0.7rem"}} onClick={()=>openModalPopUp2(x.user.userId)}> Cancel Booking </button>
                    {/* <UncontrolledTooltip delay={0} target={'hotelBookingId2'}>
                       Cancel Booking
                      </UncontrolledTooltip> */}
                      </div>
                     }
                     </>
                  }
                    {

                      x.eventParticipants.length > 0 && <div className="col-lg-1">
                        <p className="mb-0" style={{ fontSize: '0.7rem', marginLeft:"3rem" }}>
                          {Moment(x.eventParticipants.find(pack => pack.eventId == row.eventId)?.updatedAt).format('d/MM/YYYY')}
                        </p>

                      </div>
                    }
                    {/* {(x.eventParticipants.length > 0 && x.eventParticipants[0].status == '1') && <div className="col-lg-1 ml--2">
                    <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                      Approved
                    </p>

                  </div>} */}

                    {/* {

                      x.eventParticipants.length > 0 && <button onClick={() => openEditUserPackage({ ...row, ...x })} className="btn btn-icon btn-default btn-sm" variant="contained" color="default">
                        <span className="btn-inner--icon"><i className="fas fa-pencil-alt"></i></span>
                      </button>

                    } */}

                    {row.channel.contacts.length > 1 && <>
                      <button onClick={() => removeParticipantPop({ ...row, ...x })} id={'participantcancell' + x.contactId} className="btn btn-icon btn-sm btn-danger" variant="contained" color="danger">
                        <span className="btn-inner--icon"><i className="fas fa-trash-alt"></i></span>
                      </button>
                      <UncontrolledTooltip delay={0} target={'participantcancell' + x.contactId}>
                        Remove Participant
                      </UncontrolledTooltip>
                      </>}

                    {/* {x.eventParticipants[0]?.isCancelled == '1' && <>
                    <button onClick={() => openEventCancellation({ ...row, ...x })} id={'eventcancell' + x.contactId} className="btn btn-icon btn-sm btn-danger" variant="contained" color="danger">
                      <span className="btn-inner--icon"><i className="fas fa-trash-alt"></i></span>
                    </button>
                    <UncontrolledTooltip delay={0} target={'eventcancell' + x.contactId}>
                      Event Cancellation Request
                    </UncontrolledTooltip>

                    <button onClick={() => eventCancelDismissPop({ ...row, ...x })} id={'eventcancelldis' + x.contactId} className="btn btn-icon btn-sm btn-danger" variant="contained" color="danger">
                      <span className="btn-inner--icon"><i className="ni ni-fat-remove"></i></span>
                    </button>
                    <UncontrolledTooltip delay={0} target={'eventcancelldis' + x.contactId}>
                      Event Cancellation Dismiss
                    </UncontrolledTooltip>
                  </>
                  } */}

                  
                  </Row>
                </ListGroupItem>

              </ListGroup></>
          })
          }
        </div>
      )
    },
    className: 'bgrow'
  };

  const channelTypeList = { "2": "VENDOR", "3": "DISTRIBUTOR", "4": "RETAILER", "5": "RESELLER" };

  return (
    <div>
      {splitFlag && split()}

      {
      bookHotelModal &&   <BookHotelModal
      open={bookHotelModal}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'flex-end', 
  
  
     }}
    > 
          <Card style={{margin:"0", overflowY:"scroll",height:"100vh",  padding:"14px",minWidth:"68%", maxWidth:"68%"}}>
          <div style={{display:"flex", justifyContent:"space-between", width:"97%",}}>
                    <h3 style={{marginLeft:"1.2rem"}}>Hotel Booking</h3>
                    <button style={{border:"none", backgroundColor:"white"}} onClick={closeModal}><GridCloseIcon/></button>
                  </div>
                  <div style={{margin:"1.1rem 0 0 0" }}>
                    {loading2 == true && <ModalSpinner/>}
                    

                  
                    {
                      loading2 == false &&
                      <SelectAutocomplete
                      id="search"
                      type="text"                
                      label="Hotel"
                      placeholder="Select Hotel Name"
                      name="hotelName"
                      className="d-inline-block"
                      style={{ width:'60%', marginLeft:"1.3rem"}}
                      options={hotelName} 
                      disableCloseOnSelect
                      control={control}                         
                      input='selectHotel'
                      defaultValue={selectHotel}
                      value={selectHotel}
                      handleChangeSelect={handleHotelBooking}  
                  /> 
                    }
               
             </div>
  
             <div style={{ marginLeft: "10px", marginTop:"3%" }}>
             {
              loading3 == true && <ModalSpinner/>
             }
             
             
             <div>
  {
    loading3 == false && loading2 == false && selectHotel != null && room.length > 0 &&
  <Grid container spacing={1}>
      {room.map((item) => (
        <Grid 
          item 
          key={item.id} 
          xs={12} 
          sm={6} 
          md={4} 
          lg={3} 
          xl={3}
          onClick={() => handleDivClick(item)}
          style={{ cursor: 'pointer' }}
        >
          <div style={getContainerStyle(item.roomId)}>
            <img 
              className="img-fluid"  
              style={imgStyle} 
              alt="ch-logo" 
              src={`${process.env.REACT_APP_CDN_URL_TEMP}${Array.isArray(item?.image) && item.image[0]?.documentPath ? item.image[0].documentPath : "document/media/1719060259465_1719060260318.png"}`}
            />
            <h4 style={{ fontSize: "0.7rem", marginTop: "10%", marginBottom: "0.2rem", textAlign: "center" }}>
              {item.name.length < 19 ? item.name : `${item.name.substring(0, 19)}...`}
            </h4>
            {SelectedRoom_Id === item.roomId ? (
              <h4 style={{ color: roomNotAvaliable ? "red" : "black", textAlign: "center", fontSize: "0.7rem" }}>
                {roomNotAvaliable ? "Room Not Available" : (
                  <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                    Room <span style={{ color: "green", marginLeft: "2px" }}>{item.avaliable}</span>
                    <h4 style={{ color: "purple", textAlign: "center", fontSize: "0.7rem", marginLeft: "10px" }} onClick={() => handleRoomTypeImageModal(item)}>View</h4>
                  </div>
                )}
              </h4>
            ) : (
              <h4 style={{ color: "black", textAlign: "center", fontSize: "0.7rem" }}>
                <div style={{ display: "flex", margin: 0, justifyContent: "space-evenly" }}>
                  Room <div style={{ color: "green", marginLeft: "2px" }}>{item.avaliable}</div>
                  <h4 style={{ color: "purple", textAlign: "center", fontSize: "0.7rem", marginLeft: "10px" }} onClick={() => handleRoomTypeImageModal(item)}>View</h4>
                </div>
              </h4>
            )}
          </div>
        </Grid>
      ))}
    </Grid>
  }
</div>

{
  roomTypeImageModal &&
  <RoomTypeImageModal roomTypeImageModal={roomTypeImageModal} setRoomTypeImageModal={setRoomTypeImageModal} item={imageItem}/>
}
                       
             </div>
             <div style={{display: "flex",marginTop:"5%",marginLeft:"1.7%", flexWrap:'wrap'}}>
        {selectRoom != null && loading2 == false &&
          eventDatesArray.map((item, index) => (
            <div key={index} style={{display:"flex", flexDirection:"column", position: "relative", width:"25%"}}>
              <FormControlLabel
                style={{margin:"1.4rem 0 0.4rem 0"}}
                required
                control={
                  <Checkbox 
                    fontSize={{fontSize:"0.7rem"}} 
                    checked={dateLabel.includes(eventDatesArray[index])} 
                    onChange={(e, value) => labelChange(e, eventDatesArray[index])} 
                  />
                }
                label={<h5 style={{margin:'0'}}>{eventDatesArray[index]}</h5>}
              />
       
                <h5 style={{margin:"0 0 0 2.7rem", color: '#525f7f', fontSize:"0.7rem", position:"absolute", bottom:"1px",left:"74px" }}>
                  {selectRoom.dateCounts[eventDatesArray[index]] != null && selectRoom.dateCounts[eventDatesArray[index]] != undefined ? 
                    `${"/  "} Book - ${selectRoom.dateCounts[eventDatesArray[index]]}` : 
                    `${"/  "}  Book - 0 `
                  }
                </h5>  
      {
       
       <h5 style={{ margin: "0 0 0 2.7rem", color: '#525f7f', fontSize: "0.7rem", position: "absolute", bottom: "1px" }}>
         {`Available - ${(Number(getRoomAndSubBookByDate(eventDatesArray[index]))) - (selectRoom.dateCounts[eventDatesArray[index]] != null && selectRoom.dateCounts[eventDatesArray[index]] != undefined ? Number(selectRoom.dateCounts[eventDatesArray[index]]) : 0) }`}
     </h5>
     
         
      }
            </div>
          ))
        }
    </div>
 
  
  { loading2 == false && 
    <div className="mt-5 ml-2">
      <button className="btn btn-primary ml-3" onClick={updateHotelFlag == true ? updateHotelBooking : hotelBooking }>Book</button>
       <button className=" btn btn-warning btn-md ml-2" onClick={closeModal}>Cancel</button>
    </div>
  }
  



   <div>
  
  </div>
   
      </Card> 
          
    </BookHotelModal>
    } 

      <SimpleHeader name="Manage-Event" parentName="Event" />
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      {editUserData.hasOwnProperty('channelId') && <EditEventUser closeModalPopup={closeEditUser} save={boEventUserChange} opneadd={editUser} data={editUserData} />}

      {editUserData.hasOwnProperty('channelId') && <EditEventBuyer closeModalPopup={closeEditUser} save={boEventUserChange} opneadd={editBuyerChannel} data={editUserData} />}

      {editUserPackageData.hasOwnProperty('channelId') && <EditEventUserPackage closeModalPopup={closeEditUserPackage} save={boUserPackageChange} opneadd={editUserPackage} data={editUserPackageData} />}

      <ConfirmDialog open={changeDelegateFlag} value={{ title: 'Event Participant Removal', message: "Not able to remove main delegate, kindly assign another user as main delegate", second_message: '' }} handleSave={() => setChangeDelegate(false)} handleClose={() => setChangeDelegate(false)} />

      <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
      <Dialog open={isModalPopup2} value={modalPopValue2} handleSave={hotelBookingCancel} handleClose={closeModalPopup2} />


      {loading ? <Spinner /> : <>
        <Card>
          <CardHeader>
            <h2 style={{ textAlign: "left", color: "#2ca5cd" }}>Event Details</h2>
            {(state.vendorStatus == 1 || state.buyerStatus == 1) &&
              <h2 style={{ textAlign: "left", color: "#2ca5cd" }}>Event Signup Url: {(state.vendorStatus == 1 && state.buyerStatus == 1) ? `https://channelhub.net/signup?eventId=${eventId}&eventType=${state.type}` : `https://channelhub.net/signup?eventId=${eventId}&pType=${state.vendorStatus == 1 ? 1 : 2}&eventType=${state.type}`}</h2>}
            <span>Event : <strong>{state.eventName}</strong>  |  </span>
            <span>   Sellers : {state.sellerOption.map(x => {
              if (x.status)
                return <span>{x.name}</span>
            })}
              <span className="pl-3"> Buyers : {state.buyerOption.map(x => {
                if (x.status)
                  return <span className="pl-2">{x.name}</span>
              })}</span>
            </span>

          </CardHeader>
          {/* <CardBody>
            <Row className="pt-4">
              <Col>
                Event : {state.eventName}
              </Col>

              <Col>
                Sellers : {state.sellerOption.map(x => {
                  if (x.status)
                    return <p>{x.name}</p>
                })}
              </Col>

              <Col>
                Buyers : {state.buyerOption.map(x => {
                  if (x.status)
                    return <p>{x.name}</p>
                })}
              </Col>

            </Row>
            <Row className="pt-4">
              <Col>
                Event Description : {state.eventDescription}
              </Col>

              <Col>
                <div>
                  <h3>Registered Channels Count</h3>
                  {
                    state.channelTypeCount?.map(x => (
                      <p>
                        {channelTypeList[x.channelTypeId]} - <span>{x.count}</span>
                      </p>
                    ))
                  }
                  Total : {state.channelTypeCount?.reduce((acc, obj) => acc + +obj.count, 0)}
                </div>
              </Col>

              <Col>
                <div>
                  <h3>Registered Users Count</h3>
                  {
                    state.usersCount?.map(x => (
                      <>
                        <span style={{ color: "rgb(44, 165, 205)" }}>{channelTypeList[x.channelType]}</span>
                        <p>Approved - {x.approved} | Not Approved - {x.not_approved}</p>
                      </>
                    ))
                  }
                  Total :
                  <p>Approved {state.usersCount?.reduce((acc, obj) => acc + +obj.approved, 0)} | Not Approved {state.usersCount?.reduce((acc, obj) => acc + +obj.not_approved, 0)} </p>
                </div>
              </Col>
            </Row>
            <Row className="pt-4">
              <Col className="ml-2" lg="2" md="5" sm="12" align-self-center>
                <div className="d-inline mr-4">
                  <div className="event-venue mb-2">Date and Venue</div>
                  <div className="event-venue-detail"><i class="fa fa-calendar mr-2" aria-hidden="true"></i>{Moment(state.fromDate).format('d MMMM YYYY')} - {Moment(state.toDate).format('d MMMM YYYY')}</div>
                  <div className="event-venue-detail"><i class="fa fa-map-marker mr-2" aria-hidden="true"></i>
                    <p>{state.address + ', ' + state.city + ', ' + state.country.name + ', ' + state.zipCode}</p>
                  </div>
                </div>
              </Col>
            </Row>

          </CardBody> */}
          <CardBody>
            <Row className="pt-4">
              <Col md="6" sm="12">
                <div>
                  <h3>Registered Channels Count</h3>
                  {
                    state.channelTypeCount?.map(x => (
                      // <p>
                      //   {channelTypeList[x.channelTypeId]} - <span>{x.count}</span>
                      // </p>
                      <span> {channelTypeList[x.channelTypeId]} - {x.count}</span>
                    ))
                  }
                  | <strong>Total : {state.channelTypeCount?.reduce((acc, obj) => acc + +obj.count, 0)}</strong>
                </div>
              </Col>

              <Col md="6" sm="12">
                <div>
                  <h3>Registered Users Count</h3>

                  {
                    state.usersCount?.map(x => (
                      <>
                        <span className="p-2">{channelTypeList[x.channelType]} : Approved - {x.approved} | Not Approved - <strong>{x.not_approved}</strong></span>
                      </>
                    ))
                  }
                  <p><strong>Total :
                    Approved {state.usersCount?.reduce((acc, obj) => acc + +obj.approved, 0)} | Not Approved {state.usersCount?.reduce((acc, obj) => acc + +obj.not_approved, 0)}</strong></p>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        {/* Table for channel */}
        <Container className="mt--3" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <Row>
                    <Col lg='2'>
                      <h3 className="mb-0 d-inline">Event Channels</h3>
                    </Col>
                    <Col lg='10'>
                      <Nav className="event-nav" style={{ float: "right", width: "100%", display: "initial" }}>


                        <NavItem className="pl-2 " style={{ float: "right", width: "19%", display: "inline-block" }}>
                          {/* style={listing === '/admin/mynetwork'? {top : '0.2rem'}:{top : '3.5rem'}} */}
                          <SelectAutocomplete
                            id="search"
                            type="text"
                            label="search"
                            name="search"
                            options={searchState.dropdownList}
                            placeholder="search..."
                            disableCloseOnSelect
                            defaultValue={searchState.search ? searchState.search[0] : null}
                            control={control}
                            input='search'
                            value={searchState.search ? searchState.search[0] : null}
                            handleChangeSelect={handleChangeSelect}
                          />
                        </NavItem>
                        <NavItem className="pl-2  chan-type " style={{ float: "right", width: "19%", display: "inline-block" }}>
                          <FormAutocomplete
                            id="channel_type"
                            name="channel_type"
                            label="Channel Type"
                            options={company_type_Option}
                            placeholder="Channel Type ..."
                            multiple
                            disableCloseOnSelect
                            limitTags={1}
                            control={control}
                            // open={true}
                            input='channelType'
                            getOptionSelected={(options, value) => options.name === value.name}
                            value={channelType}
                            handleChangeSelect={handleChangeSelect}
                            // popupIcon=''
                            disablePortal={true}
                            // ListboxProps={{ style: { maxHeight: '16rem' } }}
                            size='small'
                          />
                        </NavItem>

                        <NavItem className="pl-2  chan-type " style={{ float: "right", width: "19%", display: "inline-block" }}>
                          <FormAutocomplete
                            id="payment_status"
                            name="payment_status"
                            label="Payment Status"
                            options={payment_status_Option}
                            placeholder="Payment Status ..."
                            // multiple
                            disableCloseOnSelect
                            limitTags={1}
                            control={control}
                            // open={true}
                            input='paymentStatus'
                            getOptionSelected={(options, value) => options.name === value.name}
                            value={paymentStatus}
                            handleChangeSelect={handleChangeSelect}
                            // popupIcon=''
                            disablePortal={true}
                            // ListboxProps={{ style: { maxHeight: '16rem' } }}
                            size='small'
                          />
                        </NavItem>

                        <NavItem className="pl-2  chan-type " style={{ float: "right", width: "19%", display: "inline-block" }}>
                          <FormAutocomplete
                            id="catalyst_status"
                            name="catalyst_status"
                            label="Catalyst Status"
                            options={catalyst_status_Option}
                            placeholder="Catalyst Status ..."
                            // multiple
                            disableCloseOnSelect
                            limitTags={1}
                            control={control}
                            // open={true}
                            input='catalystStatus'
                            getOptionSelected={(options, value) => options.name === value.name}
                            value={catalystStatus}
                            handleChangeSelect={handleChangeSelect}
                            // popupIcon=''
                            disablePortal={true}
                            // ListboxProps={{ style: { maxHeight: '16rem' } }}
                            size='small'
                          />
                        </NavItem>

                          <NavItem className="pl-2  chan-type " style={{ float: "right", width: "19%", display: "inline-block" }}>
                          <FormAutocomplete
                            id="hotel_status"
                            name="hotelSearch"
                            label="Hotel Status"
                            options={hotel_status}
                            placeholder="Hotel Status ..."
                            // multiple
                            disableCloseOnSelect
                            limitTags={1}
                            control={control}
                            // open={true}
                            input='hotelSearch'
                            getOptionSelected={(options, value) => options.name === value.name}
                            value={hotelSearch}
                            handleChangeSelect={handleChangeSelect}
                            // popupIcon=''
                            disablePortal={true}
                            // ListboxProps={{ style: { maxHeight: '16rem' } }}
                            size='small'
                          />
                        </NavItem>

                        {/* <NavItem className="Evesort" >                          
                          <UncontrolledDropdown className="p-0" group>
                            <DropdownToggle caret className="p-2">
                              <h3 style={{ display: "inline-block" }} className="font-weight-bold m-0">
                                <i className="fa fa-bars  mr-2" aria-hidden="true"></i>
                                {isSort ? sort == 'asc' ? 'A - Z' : 'Z - A' : ''}
                              </h3>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={() => setSorting('asc')}>A-Z</DropdownItem>
                              <DropdownItem onClick={() => setSorting('desc')}>Z-A</DropdownItem>
                            </DropdownMenu>

                          </UncontrolledDropdown>
                          
                        </NavItem> */}
                      </Nav>
                    </Col>
                  </Row>

                  {/* <p className="text-sm mb-0">
                    This is an exmaple of data table using the well known
                    react-bootstrap-table2 plugin. This is a minimal setup in
                    order to get started fast.
                  </p> */}
                </CardHeader>
                {<ToolkitProvider
                  ClassName="align-items-center table-flush" responsive
                  data={state.eventChannels}
                  keyField="channel.channelId"
                  columns={[

                    {
                      dataField: "channel.companyName",
                      text: "Company Name",
                      sort: true,
                      onSort: (field, order) => {

                        if (order === 'desc') {
                          state.master = state.master.sort((a, b) => (a.channel.companyName.toLowerCase() > b.channel.companyName.toLowerCase()) ? -1 : b.channel.companyName.toLowerCase() > a.channel.companyName.toLowerCase() ? 1 : 0)
                          setState({ ...state });
                          callPendingChannel({ tabChange: false });

                        }
                        else {
                          state.master = state.master.sort((a, b) => (a.channel.companyName.toLowerCase() < b.channel.companyName.toLowerCase()) ? -1 : b.channel.companyName.toLowerCase() < a.channel.companyName.toLowerCase() ? 1 : 0);
                          setState({ ...state });
                          callPendingChannel({ tabChange: false });

                        }

                      }

                    },
                    {
                      dataField: "channel.contacts",
                      text: "Users",
                      formatter: (rowContent, row) => {
                        console.log('row contents', rowContent)
                        return (
                          <>
                            <div className="avatar-group">
                              {rowContent.length && rowContent.map((x, i) => <><a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id={'easytool' + x.contactId}
                                onClick={e => e.preventDefault()}
                              >
                                <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }}
                                  alt="..."
                                  src={x.document?.documentPath ? x.document.documentPath.includes(process.env.REACT_APP_CDN_URL) ? x.document.documentPath : process.env.REACT_APP_CDN_URL + x.document.documentPath : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}//{require("assets/img/theme/team-1.jpg")}
                                />
                              </a>
                                <UncontrolledTooltip delay={0} target={'easytool' + x.contactId}>
                                  {x.firstName + ' ' + x.lastName}
                                </UncontrolledTooltip></>)}

                            </div>
                          </>
                        )
                      }
                    },
                    {
                      dataField: "channel.channelTypeId",
                      text: "Channel Type",
                      //sort: true,
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {rowContent == 2 && "Vendor"}
                            {rowContent == 3 && "Distributor"}
                            {rowContent == 4 && "Retailer"}
                            {rowContent == 5 && "Reseller"}
                          </>
                        )
                      }
                    },

                    // {
                    //   dataField: "package.packageName",
                    //   text: "Package",

                    // },

                    {
                      dataField: "approvalStatus",
                      text: "Approval",
                      sort: true,
                      onSort: (field, order) => {

                        if (order === 'desc') {
                          state.master = state.master.sort((a, b) => (a.approvalStatus > b.approvalStatus) ? -1 : b.approvalStatus > a.approvalStatus ? 1 : 0)
                          setState({ ...state });
                          callPendingChannel({ tabChange: false });

                        }
                        else {
                          state.master = state.master.sort((a, b) => (a.approvalStatus < b.approvalStatus) ? -1 : b.approvalStatus < a.approvalStatus ? 1 : 0);
                          setState({ ...state });
                          callPendingChannel({ tabChange: false });

                        }

                      },
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {(rowContent == 0 || rowContent === null) && "Pending"}
                            {rowContent == 1 && "Approved"}
                            {/* {rowContent == 4 && "Retailer"}
                            {rowContent == 5 && "Reseller"} */}
                          </>
                        )
                      }
                    },

                    {
                      dataField: "paymentstatus",
                      text: "Payment Details",
                      headerStyle: { paddingLeft: "5rem" },
                      style:{paddingLeft:"5rem"},
                      sort: true,
                      onSort: (field, order) => {

                        if (order === 'desc') {
                          state.master = state.master.sort((a, b) => (a.paymentstatus > b.paymentstatus) ? -1 : b.paymentstatus > a.paymentstatus ? 1 : 0)
                          setState({ ...state });
                          callPendingChannel({ tabChange: false });

                        }
                        else {
                          state.master = state.master.sort((a, b) => (a.paymentstatus < b.paymentstatus) ? -1 : b.paymentstatus < a.paymentstatus ? 1 : 0);
                          setState({ ...state });
                          callPendingChannel({ tabChange: false });

                        }

                      },

                      formatter: (rowContent, row) => {
                        let payment = '';
                        if (row.channel.eventPayment.filter(x => x.eventId == row.eventId && x.paymentStatus == 0).length == 0) //.paymentstatus == '1')
                          payment = 'paid';
                        else if (row.channel.eventPayment.filter(x => x.eventId == row.eventId && x.paymentStatus == 1).length && row.channel.eventPayment.filter(x => x.eventId == row.eventId && x.paymentStatus == 0).length)//(row.paymentstatus == '0' && row.paymentMethod == '2')
                          payment = 'Partially Paid' //'waiting for approval'
                        else if (row.channel.eventPayment.filter(x => x.eventId == row.eventId && x.paymentStatus == 1).length == 0 && row.channel.eventPayment.filter(x => x.eventId == row.eventId && x.paymentStatus == 0).length) //(row.paymentstatus!=1)
                          payment = 'Pending';
                        else
                          payment = 'Free';

                        return (
                          <>
                            {payment}
                          </>
                        )
                      }
                    },
                    {
                      dataField: "Actions",
                      text: "Next Due",
                      //sort: true,
                      headerStyle: { paddingLeft: serachByTable == false ? "8rem" : hotelSearch==null?"8.6rem" : hotelSearch.name=="Not Booked"?"15.2rem":hotelSearch.name=="Booked"?"14rem":"8.6rem"},
                      style:{paddingLeft:serachByTable == false ? "8rem" : hotelSearch==null?"8.6rem" : hotelSearch.name=="Not Booked"?"15.2rem":hotelSearch.name=="Booked"?"14rem":"8.6rem"},
                      formatter: (rowContent, row) => {

                        let dueDate; let days;
                        if (row.channel.eventPayment.filter(x => x.eventId == row.eventId && x.paymentStatus == 0 && x.paymentMethod == 2).length) {
                          dueDate = Moment(row.channel.eventPayment.filter(x => x.eventId == row.eventId && x.paymentStatus == 0)?.sort((a, b) => a.slotId - b.slotId)[0].paymentDate);
                        }

                        if (dueDate) {
                          days = dueDate.diff(Moment(), 'days');
                        }


                        // console.log("days:",days)

                        return (
                          <>
                            {(dueDate && days > 0) &&
                              <>
                                <button id={'splitedit' + row.channelId} onClick={() => paymentSplit(row)} className="btn btn-icon btn-danger btn-sm" variant="contained" style={{ "background": "red" }}>
                                  <span className="btn-inner--icon">{Moment(dueDate).format('MM/DD/YYYY')}</span>
                                </button>
                                <UncontrolledTooltip delay={0} target={'splitedit' + row.channelId}>
                                  Payment Split
                                </UncontrolledTooltip>
                              </>}

                          </>
                        )
                      }
                    },

                    {
                      dataField: "id",
                      text: "Booking",
                      headerStyle: { paddingLeft: "4rem" },
                      style:{paddingLeft:"4rem"},
                      //sort: true,
                      formatter: (rowContent, row) => {

                        return (
                          <>
                          Hotel Booking Details
                          </>
                        )
                      }
                    },

                    {
                      dataField: "isNew",
                      text: "Pipeline",
                      //sort: true,
                      headerStyle: { paddingLeft: "11rem" },
                      style:{paddingLeft:"11rem"},
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {rowContent == '1' ? 'New' : 'Existing'}
                          </>
                        )
                      }
                    },
                    {
                      dataField: "Actions",
                      text: "Actions",
                      sort: true,
                      formatter: (rowContent, row) => {
                        let cancelEvent = false;
                        let isEventCatalyst = false;

                        if (row.channel?.contacts?.length) {
                          row.channel.contacts.map(x => {

                            let latestEvent = x.eventParticipants.find(x => x.eventId == row.eventId);

                            if (latestEvent && latestEvent.isCancelled == '1') {
                              cancelEvent = true;
                            }
                            if (latestEvent && latestEvent.isEventCatalyst == '0') {
                              isEventCatalyst = true;
                            }
                          });

                        }

                        return (
                          <>

                            <button id={'eventedit' + row.channelId} onClick={() => openEditUser(row)} className="btn btn-icon btn-default btn-sm" variant="contained" color="default">
                              <span className="btn-inner--icon"><i className="fas fa-pencil-alt"></i></span>
                            </button>
                            <UncontrolledTooltip delay={0} target={'eventedit' + row.channelId}>
                              Edit
                            </UncontrolledTooltip>


                            {isEventCatalyst && <>
                              <button id={'eventcat' + row.channelId} onClick={() => eventRegister(row)} className="btn btn-icon btn-default btn-sm" variant="contained" color="default">
                                <span className="btn-inner--icon"><i className="ni ni-check-bold"></i></span>
                              </button>
                              <UncontrolledTooltip delay={0} target={'eventcat' + row.channelId}>
                                Send to Catalyst
                              </UncontrolledTooltip>
                            </>
                            }



                            <button id={'eventremove' + row.channelId} onClick={() => removeChannelPop(row)} className="btn btn-icon btn-default btn-sm" variant="contained" color="danger">
                              <span className="btn-inner--icon"><i className="fas fa-trash-alt"></i></span>
                            </button>
                            <UncontrolledTooltip delay={0} target={'eventremove' + row.channelId}>
                              Remove Channel
                            </UncontrolledTooltip>


                            {/* {cancelEvent && <>
                              <button id={'eventcancell' + row.channelId} className="btn btn-icon btn-sm btn-danger" variant="contained" color="danger">
                                <span className="btn-inner--icon"><i className="fas fa-trash-alt"></i></span>
                              </button>
                              <UncontrolledTooltip delay={0} target={'eventcancell' + row.channelId}>
                                Event Cancellation Request
                              </UncontrolledTooltip>
                            </>
                            } */}

                          </>
                        )
                      }
                    },



                  ]}
                >
                  {props => (
                    <>
                      <div className="py-2 table-responsive">
                        <div
                          id="datatable-basic_filter"
                          className="dataTables_filter px-4 mb-2"
                        >

                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          expandRow={User}
                          bootstrap4={true}
                          // pagination={pagination}
                          bordered={false}
                          hover={true}

                          rowClasses='pointer'
                        />
                      </div>

                      <TablePagination
                        component="div"
                        count={state.total}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </>
                  )}


                </ToolkitProvider>}
              </Card>
            </div>
          </Row>
        </Container>

      </>}
    </div>
  )
}

export default ManageEvent


const company_type_Option = [
  { name: "Vendor", id: '2' },
  { name: "Distributor", id: '3' },
  { name: "Retailer", id: '4' },
  { name: "Reseller", id: '5' }
]

const payment_status_Option = [

  { name: "Pending", id: 1 },
  { name: "Paid", id: 2 },
  { name: "Partially Paid", id: 3 }

]

const catalyst_status_Option = [
  { name: "Already Sent", id: 1 },
  { name: "Ready to Send", id: 2 }
]

const hotel_status = [
  {name:"Booked", id:1},
  {name:"Not Booked", id:2},
  {name:"All", id:3}
]