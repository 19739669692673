import React ,{useEffect, useState} from 'react';
import { Button, Modal } from "reactstrap";
import { Card, CardBody } from "reactstrap";
import { FormRadio } from "_components/FormElements/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";


export  const ChannelType=(props)=> {
  const {  isOpen, handleClose, handleSave,type } = props;
  const [channelType,setChannelType]=useState(null);
  console.log(type,'id');
  let logo = 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg';
  const { register, control, errors, watch } = useForm({
    mode: "onBlur",
    // resolver: yupResolver(retailProfileSchema)
  });
  
  
    return (
    <div>
      <Modal
          className="modal-dialog-centered"
          isOpen={isOpen}
         size="xs"
        >
          <div className="modal-header">
           <h3>Change Channeltype</h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={handleClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body  d-flex justify-content-center pt-1 pb-1">
        <Card className="w-100 mb-0">
            <FormRadio
                        name="startup"
                        className="text-sm mb-0"

                        options={radioGroupOptions2.filter(x=>x.value!=type)}
                        control={control}
                        coloum
                        // defaultValue={state.areYouStartUp}
                        // value={state.areYouStartUp}
                        onChange={(e)=>setChannelType(e.target.value)}
                      />
        </Card>


          </div>
          <div className="modal-footer">
          <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={() => handleSave(channelType)}
            >
              Save
            </Button>
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={handleClose}
            >
              Close
            </Button>
           
          </div>
        </Modal>
    </div>
  );
}

const radioGroupOptions2 = [
    {
      value: "2",
      label: "Vendor",
    },
    {
      value: "3",
      label: "Distributor",
    },
    {
        value: "4",
        label: "Retailer",
      },
      {
        value: "5",
        label: "Reseller",
      },
  
  ];
export default ChannelType;