// import React from "react";
// import Dashboard from "views/pages/dashboards/Dashboard.js";
// import ComingSoon from "views/pages/dashboards/ComingSoon.js";
// import CompanyListing from "views/pages/Channel-Listing/Channel-List.js";
// import UserManage from "views/pages/Usermanagement/usermanage.js"

let Components = {};

Components['MeetList'] = require('views/pages/Meeting/MeetList.js').default;
Components['Dashboard'] = require('views/pages/Searchlist/DashboardSearch.js').default;
Components['ComingSoon'] = require('views/pages/dashboards/ComingSoon.js').default;
Components['CompanyListing'] = require('views/pages/Channel-Listing/Channel-List.js').default;
Components['Billings'] = require('views/pages/Channel-Listing/Subscription-List').default;
Components['UserManage'] = require('views/pages/Usermanagement/usermanage.js').default;
Components['Coupon'] = require('views/pages/Coupons/Coupon.js').default;
Components['Offer'] = require('views/pages/Coupons/Eventoffer.js').default;
Components['RequestIntro'] = require('views/pages/Request_Intro/RequestIntro.js').default;
Components['CompanySetting'] = require('views/pages/profile/Settings/SettingsWrapper').default;
Components['PostYourBuyingNeed'] = require('views/pages/PostYourBuyingNeed/PostYourBuyingNeed').default;
Components['Subscription'] = require('views/pages/Subscription/Subscription').default;
Components['SupportList'] = require('views/pages/support/Support').default;
Components['ProductList'] = require('views/pages/product/Myproductlist').default;
Components['UserManagement'] = require('views/pages/profile/Settings/Usermanage').default;
Components['MyProfile'] = require('views/pages/profile/Settings/MyProfile').default;
Components["Userprofileform"] = require('views/pages/profile/Settings/Userprofileform').Userprofileform;
Components["Settings"] = require('views/pages/profile/Settings/Settings').default;
Components["FailureTransaction"] = require('views/pages/Report/FailureTransaction').default;
Components['WireTransfer'] = require('views/pages/Subscription/WireTransfer').default;

Components["Events"] = require('views/pages/Events/Events').default;
Components["Hotels"] = require('views/pages/Events/Hotels').default;
Components["EventListing"] = require('views/pages/Events/EventsListing').default;
Components["TravelList"] = require('views/pages/Events/TravelList').default;
Components["ManageEvent"] = require('views/pages/Events/ManageEvent').default;

Components["EventConnection"] = require('views/pages/Events/EventConnection').default;


Components['EventPayment'] = require('views/pages/Events/EventPayment').default;
Components['OldChannelListing'] = require('views/pages/Old-Channel-Listing/OldChannelListing').default;
Components['OldChannelEdit'] = require('views/pages/Old-Channel-Listing/OldChannelEdit').default;
Components['Channels'] = require('views/pages/Old-Channel-Listing/ChannelCreation').default;
Components['EventHome'] = require('views/EventCatalyst/EventHome').default;
Components['Brand'] = require('views/pages/brand/brand').default;
Components['HubspotManager'] = require('views/pages/Channel-Listing/HubspotManager').default;
Components['RiteAPI'] = require('views/pages/ExternalAPI/RiteAPI').default;
Components['NavigationHistory'] = require('views/pages/NavigationHistory/NavigationHistory').default;

export default Components


// const routes = [
//   {
//     collapse: true,
//     name: "Dashboards",
//     icon: "fa fa-th-large sideicon-color",
//     state: "dashboardsCollapse",
//     views: [
//       {
//         path: "/dashboard",
//         name: "Dashboard",
//         miniName: "D",
//         component: 1,
//         layout: "/admin"
//       },
//       {
//         path: "/ComingSoon",
//         name: "Alternative",
//         miniName: "A",
//         component: ComingSoon,
//         layout: "/admin"
//       }
//     ]
//   },
//   {
//     collapse: false,
//     name: "Company Listing",
//     icon: "fa fa-wifi sideicon-color",
//     state: "examplesCollapse",
//     path: "/CompanyListing",
      
//       miniName: "I",
//       component: CompanyListing,
//       layout: "/admin"
//       }
//       ,
//       {
//         collapse: false,
//         name: "User Management",
//         icon: "fa fa-user-secret sideicon-color",
//         state: "examplesCollapse",
//         path: "/adminusers",
          
//           miniName: "U",
//           component: UserManage,
//           layout: "/admin"
//           }


//   //     {
//   //       collapse: false,
//   //       name: "Suggestion",
//   //       icon: "fa fa-lightbulb sideicon-color",
//   //       state: "examplesCollapse",
//   //       path: "/channel",
          
//   //         miniName: "A",
//   //         component: Channel,
//   //         layout: "/admin"
//   //         },
//   //         {
//   //           collapse: false,
//   //           name: "My Network",
//   //           icon: "fa fa-wifi sideicon-color",
//   //           state: "examplesCollapse",
//   //             path: "/ComingSoon",
              
//   //             miniName: "A",
//   //             component: ComingSoon,
//   //             layout: "/admin"
//   //             },
//   //             {
//   //               collapse: false,
//   //               name: "Online Events",
//   //               icon: "fa fa-circle sideicon-color",
//   //               state: "examplesCollapse",
//   //                 path: "/ComingSoon",
                  
//   //                 miniName: "A",
//   //                 component: ComingSoon,
//   //                 layout: "/admin"
//   //                 },
//   //                 {
//   //                   collapse: false,
//   //                   name: "Pitch-your-Product",
//   //                   icon: "fa fa fa-trophy sideicon-color",
//   //                   state: "examplesCollapse",
//   //                     path: "/ComingSoon",
                      
//   //                     miniName: "A",
//   //                     component: ComingSoon,
//   //                     layout: "/admin"
//   //                     },
                      
//   //                 {
//   //                   collapse: false,
//   //                   name: "Post Your Buying Need",
//   //                   icon: "fa fa fa-paper-plane sideicon-color",
//   //                   state: "examplesCollapse",
//   //                     path: "/ComingSoon",
                      
//   //                     miniName: "A",
//   //                     component: ComingSoon,
//   //                     layout: "/admin"
//   //                     },
                      
//   //                 {
//   //                   collapse: false,
//   //                   name: "Invitations",
//   //                   icon: "fa fa-address-book sideicon-color",
//   //                   state: "examplesCollapse",
//   //                     path: "/ComingSoon",
                      
//   //                     miniName: "A",
//   //                     component: ComingSoon,
//   //                     layout: "/admin"
//   //                     },
                      
//   // {
//   //   collapse: true,
//   //   name: "Help / Report",
//   //   icon: "ni ni-map-big sideicon-color",
//   //   state: "mapsCollapse",
//   //   views: [
//   //     {
//   //       path: "/google",
//   //       name: "Tutorials",
//   //       miniName: "G",
//   //       component: '#',
//   //       layout: "/admin"
//   //     },
//   //     {
//   //       path: "/vector",
//   //       name: "Support",
//   //       miniName: "V",
//   //       component: '',
//   //       layout: "/admin"
//   //     },
//   //     {
//   //       path: "/vector",
//   //       name: "Complaint",
//   //       miniName: "V",
//   //       component: '',
//   //       layout: "/admin"
//   //     },
//   //     {
//   //       path: "/vector",
//   //       name: "IPR",
//   //       miniName: "V",
//   //       component: '',
//   //       layout: "/admin"
//   //     }
//   //   ]
//   //},
  
// ];

//export default routes;

