import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import Drawer from 'views/Hoc/Dialog/Globaldrawer';

import {
  Card, Container, Row, CardHeader, UncontrolledTooltip, ListGroupItem, Modal,
  ListGroup, Col, Badge, Button, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Input
} from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { SelectAutocomplete } from '_components/FormElements/FormInput';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Spinner } from '../Hoc/Spinner'
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/PaginationActions';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { FormAutocomplete } from "_components/FormElements/FormInput";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

const Participants = (props) => {
  const [showDrawer, setShowDrawer] = useState(false);
  let [selectedEvent, setEvent] = React.useState(null);
  const [requestTypes, setRequestTypes] = useState({Request:[],Incoming:[],confirmRequest:[],declineRequest:[]});
 const [contactData, setContactData] = useState([])
  const openDrawer = () => {
    // setDrawerInput(data);
    setShowDrawer(true);
    console.log("Iam HI")
  }

  const closeDrawer = () => {
    setShowDrawer(false);
    // setPasswordDrawer(false);
  }

  const [alignment, setAlignment] = React.useState(null);

  const [filters, setFilters] = useState({
    eventValue: null,
    selectedCompanyValue: null,
    selectedCompany: '',
    eventOption: [],
    eventDate: null,
    eventDateOption: [],
    // participantType: null,
    participantType: [],
    requestStatus:null,
    participantTypeOption: [{ id: 2, name: 'Vendor' }, { id: 3, name: 'Distributor' },{ id: 4, name: 'Retailer' },{ id: 5, name: 'Reseller' }],
    requestStatusOption: [{ id: 3, name: 'Request' }, { id: 31, name: 'Incoming' },{ id: 4, name: 'Confirmed' },{ id: 5, name: 'Declined' }],
    sellers: [],
    buyers: [],
    search: [],

  });
  

  const [selected, setSelected] = useState(null);
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(100);
  const { control } = useForm({ mode: 'onBlur' });
  let [loading, setLoading] = useState(true);
  let [matchLoading,setMatchLoading] = useState(false);
  const [state, setState] = useState({ dataTable: [], master: [], request: [], total: 0, selectedSlot: null, eventInfo: [] });

  useEffect(() => {
    getEventList();
  }, [])

  const getEventList = async () => {
    setLoading(true);
    axios.get('/channelAdmin/getEventList').then(res => {
      filters.eventOption = res.data.currentEvents;
  
      //filters.eventValue = res.data.currentEvents[0];
      setFilters({ ...filters });
      //getParticipants(res.data.currentEvents[0].id);
    });
    setLoading(false);
  }

  const getParticipants = async () => {
    setLoading(true);
    let data = {
      page: page * rowsPerPage,
      limit: rowsPerPage,
      eventId: selectedEvent,// eventId
      filters:{
        participantType:filters.participantType.length === 0 ? null : filters.participantType,
        // participantType:filters.participantType?filters.participantType.id:null,
        requestStatus:filters.requestStatus?filters.requestStatus.id:null,
        search: filters.selectedCompany
      }
    }

    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.post('/channelAdmin/getEventParticipants',
      data).then(res => {

        // const list=res.data.rows.filter(x => x.channel!=null);

        state.dataTable = res.data.rows;
        state.master = res.data.rows;
        state.request = res.data.eventRequest;
        state.total = res.data.count;


        let funcDate = [];
        res.data.rows.map(row => {
          row.channel.contacts.map(x => {

            if (x.contactSlot.length) {
              x.contactSlot.map(slot => {

                if (!funcDate.map(s => s.name).includes(slot.sdate)) {
                  funcDate.push({ id: slot.contactSlotId, name: slot.sdate });
                }

              });
            }

          })
        })

        filters.eventDateOption = funcDate;
        //filters.eventDate=funcDate[0];
        setFilters({ ...filters });
        setState({ ...state });
        setLoading(false);
      })

      axios.get(`/channelAdmin/searchEventParticipants?eventId=${selectedEvent}`).then(res => {
        filters.search = res.data;
        setFilters({ ...filters });
        console.log(filters.search);
      })
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {

      let confirmRequest=[];let pendingRequest=[];let declineRequest=[];

      const requestList = state.request?.filter(req => (req.senderChannelId == row.channelId || req.receiverChannelId == row.channelId));
      
      requestList.map(x => {

        let obj={};

        if(x.senderChannelId==row.channelId){
        obj.eventRequestId=x.eventRequestId;
        obj.channelId=+x.receiverChannelId;
        obj.contactId=+x.receiverContactId;
        }else if(x.receiverChannelId==row.channelId){
          obj.eventRequestId=x.eventRequestId;
          obj.channelId=+x.senderChannelId;
          obj.contactId=+x.senderContactId;
        }        
        
        if(x.status==4)
        confirmRequest.push(obj);
        else  if(x.status==3)
        pendingRequest.push(obj);
        else  if(x.status==5)
        declineRequest.push(obj);      

      })

      setRequestTypes({ ...requestTypes, pendingRequest: pendingRequest,
      confirmRequest:confirmRequest,
      declineRequest:declineRequest});    

     
      setShowDrawer(true);
    }
  };
  
  const setTime = (channelId, selectedSlot) => {

    setLoading(true);
    setSelected(channelId);
    const temp = state.dataTable;
    temp.map(x => {
      if (x.channelId == channelId) {
        Object.assign(x, { slotSelection: true, selectedSlot: selectedSlot })
      } else {
        Object.assign(x, { slotSelection: false, selectedSlot: null })
      }

    })
    // state.dataTable=temp;
    setState({ ...state, dataTable: temp });
    // console.log("time:", channelId, temp)
    setLoading(false);
  }

  const handleChangeEvent = (input, value) => {
    selectedEvent=value.id;
    setEvent(selectedEvent);
    filters.eventValue = value;
    filters.sellers = value.sellers.split(',');
    filters.buyers = value.buyers.split(',');
    setFilters(filters);
    
    getParticipants();
  }

  const changeSearch = (input, value) => {
   filters.selectedCompanyValue = value;
    filters.selectedCompany = value.name;
   
    setFilters(filters);
    
    getParticipants();
  }


  const changeParticipant = (input, value) => {
    filters.participantType = value;
    setFilters(filters);
    getParticipants();
    
  }

  const changeRequest = (input, value) => {
    filters.requestStatus = value;
       
    setFilters(filters);
    getParticipants();
    
  }
  


  const changeFunction = (input, value) => {
    filters.eventDate = value;

    let newList = []; let oldList = state.master;
    if (filters.participantType.length > 0) {
        filters.participantType.map(x => {
          if (x.id == 1) {
            oldList = state.master.filter(x => filters.sellers.includes(x.channel.channelTypeId));
          
          } else if (x.id == 2) {
            oldList = state.master.filter(x => filters.buyers.includes(x.channel.channelTypeId));
          }
        })
      }


      // if (filters.participantType) {
      //   if (filters.participantType.id == 1) {
      //     oldList = state.master.filter(x => filters.sellers.includes(x.channel.channelTypeId));
          
      //   } else if (filters.participantType.id == 2) {
      //     oldList = state.master.filter(x => filters.buyers.includes(x.channel.channelTypeId));
          
      //   }
      // }
    if (value) {


      oldList.map(row => {
        let flag = false;
        row.channel.contacts.map(x => {

          if (x.contactSlot.length && x.contactSlot.map(slot => slot.sdate).includes(value.name)) {
            flag = true;
          }
        })
        if (flag) {
          newList.push(row);
        }
      })


      state.dataTable = newList;
      setState({ ...state });
    } else {
      state.dataTable = oldList;
      setState({ ...state });
    }
    setFilters({ ...filters });
    //getParticipants(value.id);
  }


  const handleChange = (event, newAlignment) => {

    state.selectedSlot = newAlignment;
    setState({ ...state });
  };

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getParticipants();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    getParticipants();
  };

  const activatePopup = (event)  => {
    setMatchLoading(true)
    axios.get('/channelAdmin/getEventRequests?contactId='+event.contactId).then(res => {  
      setContactData(res.data)
      setShowDrawer(true)
      setMatchLoading(false)
    });
  }

  const User = {
    renderer: (row, rowIndex, index) => {
      //console.log('rowuser', row, rowIndex);

      return (

        
        <div style={{ width: '100%' }}>


          {row.channel.contacts.length > 0 && row.channel.contacts.map((x, i) => {

            // console.log("conts:", x.contactSlot, row.selectedSlot);

            let confirmCount;
            let requestCount;
            let declinedCount;let incomingCount;


            confirmCount = state.request?.filter(req => (req.senderContactId == x.contactId || req.receiverContactId == x.contactId) && req.status == 4).length;
            requestCount = state.request?.filter(req => (req.senderContactId == x.contactId) && req.status == 3).length;
            incomingCount = state.request?.filter(req => (req.receiverContactId == x.contactId) && req.status == 3).length;
            declinedCount = state.request?.filter(req => (req.senderContactId == x.contactId || req.receiverContactId == x.contactId) && req.status == 5).length;

            return ( <ListGroup className="list my--2" flush key={'table' + x.contactId}>
                  <ListGroupItem className="px-0">
                    <Row className="align-items-center">
                      <Col className="col-1">
                        <a
                          className="avatar rounded-circle"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"); }}
                            alt="..."
                            src={x.document?.documentPath ? x.document.documentPath.includes(process.env.REACT_APP_CDN_URL) ? x.document.documentPath : process.env.REACT_APP_CDN_URL + x.document.documentPath : "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"}//{require("assets/img/theme/team-1.jpg")}
                          />
                        </a>
                      </Col>
                      <div className="col-lg-2 ml--2">
                        <p className="mb-0" style={{ fontSize: '0.7rem' }}>

                          {x.firstName + ' ' + x.lastName}

                        </p>

                        {/* onClick={() => login(x.contactId, x.firstName, x.lastName, x.jobTitle, x.country?.country, row.channelId, row.channelTypeId, x.document?.documentPath)} style={{ padding: "10px 10px 0 0 " }} */}

                        <div>

                          <button id={'Confirmed' + index} className="btn btn-icon btn-success btn-sm" variant="contained">
                            <span className="btn-inner--icon">{confirmCount}</span>
                            <UncontrolledTooltip target={'Confirmed' + index} placement='bottom'>Confirmed Request </UncontrolledTooltip>
                          </button>

                          <button id={'Sent' + index} className="btn btn-icon btn-info btn-sm" variant="contained">
                            <span className="btn-inner--icon">{requestCount}</span>
                            <UncontrolledTooltip target={'Sent' + index} placement='bottom'>Sent Request </UncontrolledTooltip>
                          </button>

                          <button id={'Incoming' + index} className="btn btn-icon btn-info btn-sm" variant="contained">
                            <span className="btn-inner--icon">{incomingCount}</span>
                            <UncontrolledTooltip target={'Incoming' + index} placement='bottom'>Incoming Request </UncontrolledTooltip>
                          </button>

                          <button id={'Declined' + index} className="btn btn-icon btn-warning btn-sm" variant="contained">
                            <span className="btn-inner--icon">{declinedCount}</span>
                            <UncontrolledTooltip target={'Declined' + index} placement='bottom'>Declined Request </UncontrolledTooltip>
                          </button>
                        </div>
                      </div>

                      <div className="col-lg-2 ml--2">
                        <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                          {x.jobTitle}
                        </p>
                      </div>

                      <div className="col-lg-2 ml--2">
                        <button className="btn btn-icon btn-primary btn-sm" variant="contained" onClick={() => activatePopup(x)}>Match</button>
                      </div>




                    </Row>
                  </ListGroupItem>

                </ListGroup>)

            // :  (<p>Sorry, No Contacts having selected slot</p>)
          })}
        </div >
      )
    },
    className: 'bgrow'
  };

  return (
    
    <Container className="mt--3" fluid>
      <Drawer title="Event Request" eventId={selectedEvent} contactData={contactData} visible={showDrawer} requestTypes={requestTypes} closeDrawer={closeDrawer}  getParticipants={getParticipants}/>

      {matchLoading ? <Spinner /> : ''}

      <Row>
        <div className="col">
          <Card>
            <CardHeader>
              <h3 className="mb-0">Event Participants</h3>

            </CardHeader>
            {loading ? <Spinner /> : <ToolkitProvider
              ClassName="align-items-center table-flush" responsive
              data={state.dataTable}

              keyField="channelId"
              columns={[

                {
                  dataField: "Action",
                  text: "Action",
                  formatter: (rowContent, row, index) => {
                    // let confirmCount;
                    // let pendingCount;
                    // let declineCount;


                    // confirmCount = state.request?.filter(req => (req.senderChannelId == row.channelId || req.receiverChannelId == row.channelId) && req.status == 4).length;
                    // pendingCount = state.request?.filter(req => (req.senderChannelId == row.channelId || req.receiverChannelId == row.channelId) && req.status == 3).length;
                    // declineCount = state.request?.filter(req => (req.senderChannelId == row.channelId || req.receiverChannelId == row.channelId) && req.status == 5).length;



                    return (
                      <>

                      {/* {(filters.requestStatus?.id==4||filters.requestStatus==null) && */}
                        <button id={'Confirmed' + index} className="btn btn-icon btn-success btn-sm" variant="contained">
                          <span className="btn-inner--icon">{row.confirmCount}</span>
                          <UncontrolledTooltip target={'Confirmed' + index} placement='bottom'>Confirmed Request </UncontrolledTooltip>
                        </button>

                        {/* {(filters.requestStatus?.id==3||filters.requestStatus==null) && */}
                        <button id={'Sent' + index} className="btn btn-icon btn-info btn-sm" variant="contained">
                          <span className="btn-inner--icon">{row.requestCount}</span>
                          <UncontrolledTooltip target={'Sent' + index} placement='bottom'>Sent Request </UncontrolledTooltip>
                        </button>

                        <button id={'Incoming' + index} className="btn btn-icon btn-info btn-sm" variant="contained">
                          <span className="btn-inner--icon">{row.incomingCount}</span>
                          <UncontrolledTooltip target={'Incoming' + index} placement='bottom'>Incoming Request </UncontrolledTooltip>
                        </button>

                        {/* {(filters.requestStatus?.id==5||filters.requestStatus==null) && */}
                        <button id={'Declined' + index} className="btn btn-icon btn-warning btn-sm" variant="contained">
                          <span className="btn-inner--icon">{row.declineCount}</span>
                          <UncontrolledTooltip target={'Declined' + index} placement='bottom'>Declined Request </UncontrolledTooltip>
                        </button>


                      </>

                    )
                  }
                },

                {
                  dataField: "companyName",
                  text: "Company Name",
                  sort: true,
                  formatter: (rowContent, row) => {
                    return (
                      <>
                        {row.channel.companyName}

                      </>
                    )
                  }

                },
                {
                  dataField: "channelTypeId",
                  text: "Company Type",
                  sort: true,
                  formatter: (rowContent, row) => {
                    return (
                      <>
                        {row.channel.channelTypeId == 2 && "Vendor"}
                        {row.channel.channelTypeId == 3 && "Distributor"}
                        {row.channel.channelTypeId == 4 && "Retailer"}
                        {row.channel.channelTypeId == 5 && "Reseller"}

                      </>
                    )
                  },
                  // headerEvents: {
                  //   onClick: (e, column, columnIndex) => { getSorting(column, e.target) }
                  // }
                },

                {
                  dataField: 'country.name',
                  text: "Company Country",
                  formatter: (rowContent, row) => {
                    return (
                      <>
                        {row.channel.country.country}

                      </>
                    )
                  }
                  // sort: true
                },
                //   {
                //     dataField: "Users",
                //     text: "Users",
                //     formatter: (rowContent, row) => {
                //       return (
                //         <>
                //           <div className="avatar-group">
                //             {row.contacts?.length && row.contacts.map((x, i) => <><a
                //               className="avatar avatar-sm rounded-circle"
                //               href="#pablo"
                //               id={'easytool' + x.contactId}
                //               onClick={e => e.preventDefault()}
                //             >
                //               <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"); }}
                //                 alt="..."
                //                 src={x.document?.documentPath ? x.document.documentPath.includes(process.env.REACT_APP_CDN_URL) ? x.document.documentPath : process.env.REACT_APP_CDN_URL + x.document.documentPath : 'http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png'}//{require("assets/img/theme/team-1.jpg")}
                //               />
                //             </a>
                //               <UncontrolledTooltip delay={0} target={'easytool' + x.contactId}>
                //                 {x.firstName + ' ' + x.lastName}
                //               </UncontrolledTooltip></>)}

                //           </div>
                //         </>
                //       )
                //     }
                //   },


                {
                  dataField: "selectedSlot",
                  text: "Event Slots",
                  formatter: (rowContent, row, index) => {

                    let slots = [];

                    //console.log("sss:", row.slotSelection, row.selectedSlot)
                    let confirmedSlot = [];

                    let conf = state.request.filter(req => (req.senderChannelId == row.channelId || req.receiverChannelId == row.channelId) && req.status == 4);
                    conf.map(r => { confirmedSlot.push(r.slot) });

                    row.channel.contacts.map(x => {

                      if (x.contactSlot.length) {
                        x.contactSlot.map(slot => {

                          if (slots.length > 0 && slots.map(s => s.stime).includes(slot.stime)) {

                            slots = slots.map(item => {
                              var temp = Object.assign({}, item);
                              if (temp.stime === slot.stime) {
                                temp.count = temp.count + 1;
                              }
                              return temp;
                            });
                          } else {
                            slots.push({ stime: slot.stime, count: 1 });
                          }

                        });
                      }


                    })
                    // if(slots.length)              
                    // console.log("slots:",conf,slots)
                    //slots = [...new Set(slots)];


                    return (

                      <>
                        {slots?.map((slot, index) =>
                          <ToggleButton style={(row.slotSelection && row.selectedSlot == slot.stime) ? { backgroundColor: "green" } : { backgroundColor: "" }}
                            value="check"
                            id={'time' + index + row.channelId}

                            //color={selected?style={{background-color}}}
                            disabled={confirmedSlot.filter(cslot => cslot === slot.stime).length == slot.count ? true : false}
                            selected={row.slotSelection ? true : false}
                            onChange={() => {
                              setTime(row.channelId, slot.stime)
                            }}
                          >
                            {slot.stime}
                          </ToggleButton>
                        )}
                      </>

                    )
                  }
                },



              ]}
            >
              {props => (
                <div className="py-2 table-responsive">
                  <div
                    id="datatable-basic_filter"
                    className="dataTables_filter px-4 mb-2"
                  >
                    <div style={{ width: '20%', display: 'inline-block' }}>
                      <SelectAutocomplete
                        id="event"
                        type="text"
                        label="Event"
                        name="event"
                        getOptionSelected={(options, value) => options.name === value.name}
                        options={filters.eventOption}
                        placeholder="Select Event..."
                        disableCloseOnSelect
                        // defaultValue={searchState.search?searchState.search[0]:null}
                        control={control}
                        input='event'
                        value={filters.eventValue}
                        handleChangeSelect={handleChangeEvent}
                      />
                    </div>

                    <div style={{ width: '20%', display: 'inline-block' }}>
                      {/* <SelectAutocomplete
                        // isMulti
                        multiple
                        id="participanttype"
                        type="participanttype"
                        label="Participant Type"
                        name="participanttype"
                        getOptionSelected={(options, value) => options.name === value.name}
                        options={filters.participantTypeOption}
                        placeholder="Select Participant Type..."
                        disableCloseOnSelect
                        // defaultValue={searchState.search?searchState.search[0]:null}
                        control={control}
                        input='participanttype'
                        value={filters.participantType}
                        handleChangeSelect={changeParticipant}
                      /> */}

                     
                              <FormAutocomplete
                                multiple
                                id="participanttype"
                                type="participanttype"
                                label="Participant Type"
                                name="participanttype"
                                getOptionSelected={(options, value) => options.name === value.name}
                                options={filters.participantTypeOption}
                                placeholder="Select Participant Type..."
                                disableCloseOnSelect
                                limitTags={1}
                                // defaultValue={searchState.search?searchState.search[0]:null}
                                control={control}
                                input='participanttype'
                                value={filters.participantType}
                                handleChangeSelect={changeParticipant}
                              />
                          
                    </div>

                    <div style={{ width: '20%', display: 'inline-block' }}>
                      <SelectAutocomplete
                        id="requeststatus"
                        type="requeststatus"
                        label="Request Status"
                        name="requeststatus"
                          getOptionSelected={(options, value) => options.name === value.name}
                        options={filters.requestStatusOption}
                        placeholder="Select Request Status..."
                        disableCloseOnSelect
                        // defaultValue={searchState.search?searchState.search[0]:null}
                        control={control}
                        input='requeststatus'
                        value={filters.requestStatus}
                        handleChangeSelect={changeRequest}
                      />
                    </div>

                    <div style={{ width: '20%', display: 'inline-block' }}>
                      <SelectAutocomplete
                        id="functiondate"
                        type="functiondate"
                        label="Function Date"
                        name="functiondate"
                        getOptionSelected={(options, value) => options.name === value.name}
                        options={filters.eventDateOption}
                        placeholder="Select Event Date..."
                        disableCloseOnSelect
                        // defaultValue={searchState.search?searchState.search[0]:null}
                        control={control}
                        input='functiondate'
                        value={filters.eventDate}
                        handleChangeSelect={changeFunction}
                      />
                    </div>

                    <div style={{ width: '20%', display: 'inline-block' }}>
                    <SelectAutocomplete
                          id="searchCompany"
                          type="text"
                          label="Search"
                          name="searchCompany"
                          options={filters.search}
                          placeholder="Search Company Name"
                          disableCloseOnSelect
                          // defaultValue={searchState.search?searchState.search[0]:null}
                          control={control}
                          input='searchCompany'
                          value={filters.selectedCompanyValue}
                          // onChange={(e)=>setSearchValue(e.target.value)}
                          handleChangeSelect={changeSearch}
                        />
                    </div>

                    
                  </div>


                  <BootstrapTable
                    {...props.baseProps}
                    expandRow={User}
                    bootstrap4={true}
                    // pagination={pagination}
                    bordered={false}
                    //rowEvents={ rowEvents }
                    hover={true}
                    rowClasses='pointer'
                  />

                  <TablePagination
                    component="div"
                    count={state.total}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </div>
              )}
            </ToolkitProvider>}
          </Card>
        </div>
      </Row>
    </Container>

  )

}

export default Participants;