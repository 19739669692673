import React ,{useEffect, useState} from 'react';
import { Button, Modal } from "reactstrap";
import { Card,CardBody, Col, Container, Row, CardHeader } from "reactstrap";
import { FormInput} from "_components/FormElements/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from 'axios';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { getDisplayDate } from '@material-ui/pickers/_helpers/text-field-helper';
import {Spinner} from '../../Hoc/Spinner'
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import TablePagination from '@material-ui/core/TablePagination';
import {TablePaginationActions} from 'views/Hoc/PaginationActions';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { SelectAutocomplete } from '_components/FormElements/FormInput';

export  const NavigationHistory=(props)=> {
const { handleClose, handleSave, value, visible, packageData, savePackage } = props;
let [page, setPage] = useState(0);
const { control } = useForm({ mode: 'onBlur' });
let [rowsPerPagein, setRowsPerPageIn] = useState(10);
const [state, setState ] = useState({dataTable:[], total:0});
let [loading, setLoading]  = useState(true);
let [isSort, setisSort] = useState(false);
let [sort, setSort] = useState('');
const [searchState, setSearch] = useState({ search: null, dropdownList: [] });
// const [open, Setopen] = useState(visible)
const closeOpen=(e)=>{
  e.preventDefault();
  // Setopen(false);
  handleClose();
}

useEffect(() => {
  getDisplayDate();
  getDroupDownData();
}, []);

const getDroupDownData = () => {
  axios.get('/channelAdmin/getUserNavigatedIP').then(res => {
    let ipaddress = [];
    let data = [];
    res.data.map(x => {
      if(x.ipaddress){
        ipaddress.push({ name: x.ipaddress, id: x.ipaddress });
      }
    });
    data.push(...ipaddress);
    searchState.dropdownList = data
    setSearch({ dropdownList: searchState.dropdownList });
  });
}


const getDisplayDate = async() => {
  let data = {
    search: searchState.search ? searchState.search[0] ? searchState.search[0].id : null : null,
    page: page * rowsPerPagein,
  //  order: isSort? sort:false,
    limit: rowsPerPagein
  }
  await axios.post('/channelAdmin/getUserNavigationHistory', data).then(res => {
    let navigationData = res.data
      if(navigationData){
        state.dataTable = navigationData.rows;
         state.total = navigationData.count;
         // setNavigationData(res.data);
         setState({ ...state });
         loading = false;
         setLoading(loading);
      }
    }).catch((err)=>{
        state.dataTable = [];
        state.total = 0;
        loading = false;
        setLoading(loading);
        setState({ ...state });
    });
    //  if(navigationData){
    //      state.dataTable = navigationData.rows;
    //      state.total = navigationData.count;
    //      // setNavigationData(res.data);
    //      setState({ ...state });
    //      loading = false;
    //      setLoading(loading);
    //    } else {
    // //     state.dataTable = [];
    // //     state.total = 0;
    // //     loading = false;
    // //     setLoading(loading);
    // //     setState({ ...state });
    //    }
}

const handleChangePage = (event, newPage) => {
  page=newPage;
  setPage(page);
  getDisplayDate();
};

const handleChangeRowsPerPage = (event) => {
  rowsPerPagein = parseInt(event.target.value, 10);
  setRowsPerPageIn(rowsPerPagein);
  getDisplayDate();
};


const handleChangeSelect = (input, value) => {
  if (input == 'search') {
    searchState.search = [value]
    setSearch({ ...searchState })
  }
  
  getDisplayDate();
}

  return (
    <div id="NavigationList">
       <SimpleHeader location={props.match} name="Navigation History" parentName="Dashboard" filter={false} />
       <Container className="mt--3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>      
       <h3 className="mb-0">Navigation History - public Page</h3>
               
              </CardHeader>
               {loading? <Spinner/>:<ToolkitProvider
                ClassName="align-items-center table-flush" responsive
                  data={state.dataTable}
                 keyField="navigate"
                  columns={[
                    {
                      dataField: "ipaddress",
                      text: "IP Address",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return(
                          <>
                          {row.ipaddress}                       
                          </>
                        )
                      }
                    },
                    {
                      dataField: "browser",
                      text: "Browser",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return(
                          <>
                          {row.browser}                          
                          </>
                        )
                      }
                    },
                    {
                      dataField: "navigatedPage",
                      text: "Navigated page",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return(
                          <>
                         {row.navigatedPage}     
                                                  
                          </>
                        )
                      }
                    },
                  ]}
                >
                  {props => (
                    <div className="py-2 table-responsive">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 mb-2"
                      >
                
                      </div>
                      <div style={{ width: '20%', display: 'inline-block' }}>
                        <SelectAutocomplete
                          id="search"
                          type="text"
                          label="search"
                          name="search"
                          options={searchState.dropdownList}
                          placeholder="search..."
                          disableCloseOnSelect
                          // defaultValue={searchState.search?searchState.search[0]:null}
                          control={control}
                          input='search'
                          value={searchState.search ? searchState.search[0] : null}
                          handleChangeSelect={handleChangeSelect}
                        />
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                       // expandRow={ }
                        bootstrap4={true}
                        // pagination={pagination}
                        bordered={false}
                        hover ={true}
                        
                        rowClasses= 'pointer' 
                      />

                  <TablePagination
                        component="div"
                        count={state.total}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPagein}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </div>
                  )}
                </ToolkitProvider>}
</Card>
</div>
</Row></Container>

              
    
    
    </div>
  );
};
export default  NavigationHistory;