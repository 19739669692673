import React, { useEffect, useState } from 'react';
import { Button, Modal } from "reactstrap";
import { Card, CardBody } from "reactstrap";
import { history } from '../../../_helpers/history';
import axios from 'axios';
import { FormAutocomplete } from "_components/FormElements/FormInput";
import { commonService } from '_services/common.service';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Spinner } from 'views/Hoc/Spinner';
var _ = require('lodash');
const ChangeMainDelegate = (props) => {
  const { isOpen, handleClose, value, handleSave } = props;
  const [loading, setLoading] = useState(false);
  var userData = JSON.parse(localStorage.getItem('CHuser'))?.userData;
  let typeId = parseInt(userData?.channelTypeId);
  let contactId = parseInt(userData.contactId);
  let [state, setState] = useState({mainDelegateOptions: [], mainDelegate: []});
  let logo = 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg';
  const { register, control, errors, handleSubmit } = useForm({
    mode: 'onBlur',

  });
  useEffect(() => {
    if(isOpen)
    setMainDelegateOptions();
  }, [isOpen]);

  const setMainDelegateOptions = async() => {
    setLoading(true);
        const apiUrl = '/setting/getContacts/'+userData?.channelId;
        await axios.get(apiUrl).then(res => {
          state.mainDelegateOptions = res.data.contacts.filter(x => x.id != value.deletedContactId);
          setState({...state});
        }).catch(err => {
          setLoading(false);
          commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getMainDelegate' }, '/error');
        });
        
        setState({...state});
        setLoading(false);
  }
  const handleChangeSelect = (input, value) => {
        state[input] = value;
        setState({...state});
  }

  
  const save = async() =>{
    try{
      setLoading(true);
  
      if(state.mainDelegate?.id){
        await axios.post('channelAdmin/changeMainDelegate/', {
          newMainDelegate: state.mainDelegate.id,
          oldMainDelegate: value.deletedContactId,
          eventId: value.eventId,
          userId: userData?.userId,
          channelId: userData?.channelId
        }).then(res => {
          ToastsStore.success('Main Delegate Changed Successfully');
        });
        handleSave({contactId: value.deletedContactId});
      }else{
        ToastsStore.error('Please Select Main Delegate')
        setLoading(false);
        return;
      }

    }
    catch (err) {
      console.log("err:", err);
      ToastsStore.error('Something Went Wrong!');
      setLoading(false);
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'LoginBuyerPopup' }, '/error');
    }
  }
  return (
    <div>
      {loading && <Spinner />}
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        size="lg"
      >
        <form noValidate onSubmit={handleSubmit(save)}>
        <div className="modal-header">
          {/* <h3>Connect</h3> */}
          <h2>Please Choose Alternate Main Delegate for Event</h2>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body  d-flex justify-content-center pt-1 pb-1">
          <Card className="w-100 mb-0">
            <CardBody className='p-0'>
                {/* <p>Our matching algorithm will propose you both vendors and product suggestions. The algorithm is using a combination of criteria (marked with <span id='algorithm1'><i class="far fa-handshake didy1"></i></span>
                      such as geography, channel profile, interest for startup product etc..)</p>
                <p>But most important are the <u><b className="curs-point" onClick={()=>{
                  localStorage.setItem('buyerPopup', '0');
                  history.push('/admin/profileform')
                  }}>product categories you are interested in</b></u>. And this is a user-based criteria and not company-based. Therefore it’s important to review this section in order to receive the most relevant matches.</p> */}
                {/* <div className='prodinter mb-4'><img className='img-fluid' src={require("assets/img/channelDetail/prodinter.png")} /></div> */}
                <div className="col-lg-12 ">
                  <div className="form-group">
  
                  <FormAutocomplete
                  id="mainDelegate"
                  name="ProductResponsibilities"
                  label="Main Delegate"
                  options={state.mainDelegateOptions}
                
                  placeholder="Main Delegate"
                  errMessage='Please Select Main Delegate'
                  error={!errors.mainDelegate || state.mainDelegate.length ? false : true}
                  defaultValue={state.mainDelegate}
                  value={state.mainDelegate}
                  input="mainDelegate"
                  handleChangeSelect={handleChangeSelect}
                  control={control}
                  register={register}
                  multiple={false}
                  />
                  </div>
                  </div>
                
            </CardBody>
          </Card>


        </div>
        <div className="modal-footer">
        <Button color="primary" type="submit">
                      Save
                    </Button>
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={handleClose}
          >
            Close
          </Button>

        </div>
        </form>
      </Modal>
    </div>
  );
}
export default  ChangeMainDelegate;