import React, { useEffect, useState } from 'react';
import { Modal, Card, CardBody, CardHeader, Form, Container, Col, Row, Table, Button } from "reactstrap";
import { history } from '_helpers/history';
import { Link } from 'react-router-dom';
import { Edit } from '@material-ui/icons';
// import video from '../../../assets/sample.mp4'
import axios from 'axios';
export const UserView = (props) => {
  const { url, closelogin, open, contactid, user, edit } = props;
  const { firstName, lastName, country, documentPath, jobTitle, roleId } = user;
  console.log('props', props.user)

  return (
    <div >

      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <Modal
        className="modal-dialog-centered"
        isOpen={open}
        size="sm"
        style={{ maxWidth: '420px' }}
      >
        <div className="" id="profileview">
          <div class='ribbon-wrapper-5'>
            <div class='ribbon-5 bg-green'>{roleId == '1' ? 'Account Owner' : roleId == '2' ? 'Admin' : 'Primary'}</div>
          </div>
        </div>
        <div className="modal-header pb-0">


          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closelogin}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body  d-flex justify-content-center pt-0 pb-0">
          <Card className="mb-0">

            <CardBody>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }}
                  alt="..."
                  className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                  src={documentPath ? process.env.REACT_APP_CDN_URL + documentPath : process.env.REACT_APP_CDN_URL + "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}//require("assets/img/theme/team-1.jpg")}
                  style={{ width: "140px" }}
                />
              </a>
              <div className="pt-4 text-center">
                <h5 className="h3 title">
                  <span className="d-block mb-1">{firstName + " " + lastName}</span>
                  <small className="p font-weight-light text-muted">
                    <i class="fas fa-briefcase"></i>  {jobTitle}
                  </small><br />
                  <small className="p font-weight-light text-muted">
                    <i class="fas fa-map-marker-alt"></i>  {country}
                  </small>
                </h5>
              </div>
              <div className="pt-12 text-center">
                <div className="mt-3">
                  <a href={process.env.REACT_APP_DIRECTLOGIN + contactid} target="_blank" style={{ color: "#fff" }}>
                    <Button
                      className="btn-icon my-2 mr-3"
                      color="primary"
                      type="button"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-sign-in-alt" />
                      </span>
                      <span className="btn-inner--text">Login</span>
                    </Button>
                  </a>
                  <Button
                    onClick={() => edit(contactid, user.channelId, user.channelTypeId)}
                    className="btn-icon my-2"
                    color="info"
                    type="button"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-edit pointer" />
                    </span>
                    <span className="btn-inner--text">Edit</span>
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>

        </div>
        <div className="modal-footer">
          <Button
            onClick={closelogin}
            color="secondary"
            data-dismiss="modal"
            type="button"
          //  onClick={videoModalClose}
          >
            Close
          </Button>

        </div>
      </Modal>
    </div>
  );
}
export default UserView;