import React, { useEffect, useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import { SelectAutocomplete } from '_components/FormElements/FormInput'
import { Card, CardBody, Container, Row, Col, Media, CardHeader,UncontrolledTooltip } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import UserFilter from "components/Filter-Right/UserFilter.js";
import pagination from "views/Hoc/pagination.js";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import axios from 'axios';
import { Spinner } from '../../Hoc/Spinner'
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/PaginationActions';
import UserView from "views/Hoc/Dialog/UserView";
import Drawer from 'views/Hoc/Dialog/Globaldrawer';
import { ToastsStore, ToastsContainer } from 'react-toasts';
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
// toggles collapse between mini sidenav and normal
const toggleSidenav = e => {

  if (document.getElementById('filter').classList.contains("g-sidenav-pinned")) {
    document.getElementById('filter').classList.remove("g-sidenav-pinned");
    document.getElementById('filter').classList.add("g-sidenav-hidden");
  } else {
    document.getElementById('filter').classList.add("g-sidenav-pinned");
    document.getElementById('filter').classList.remove("g-sidenav-hidden");
  }
};

const company_type_Option = [
  { name: "Vendor", id: 2 },
  { name: "Distributor", id: 3 },
  { name: "Retailer", id: 4 },
];

export const UserManage = (props) => {

  const { control } = useForm({ mode: 'onBlur' });

  const [viewuser, setsviewuser] = useState(false);
  const userview = () => {
    setsviewuser(true);
  }
  const closemodals = () => {
    setsviewuser(false);
  }
  const [showDrawer, setshowDrawer] = useState(false);
  const [drawerInput, setDrawerInput] = useState(null);
  let [loading, setLoading] = useState(true)
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);

  const [state, setState] = useState({ dataTable: [], total: 0 });
  const [searchState, setSearch] = useState({ search: null, dropdownList: [] });
  const [filterValueState, setFilterValue] = useState({ Company_type: null, country: [] });
  const [filterOptionState, setFilterOption] = useState({ countryOption: [], company_type_Option: company_type_Option });

  const [showPasswordDrawer, setPasswordDrawer] = useState(false);

  const openPassword = (data) => {
    setDrawerInput(data);
    setPasswordDrawer(true);
    console.log('datauser', data);
  }


  useEffect(() => {
    // 3) access a ref, for example 0

  }, []);
  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getUserListing();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    setPage(0);
    getUserListing();
  };

  useEffect(() => {
    getUserListing();
    getCountryList();
    getSearchList()
  }, []);

  const getCountryList = () => {
    axios.post('/admin/userCountry', {
    }).then(res => {
      let ctyFilter = res.data.map(x => x.countryid);
      filterOptionState.countryOption = ctyFilter;
      setFilterOption({ ...filterOptionState, countryOption: filterOptionState.countryOption });
    })
  }

  const getFilterData = () => {
    let filter = [];
    if (filterValueState.country && filterValueState.country.length > 0) {
      filter.countryId = filterValueState.country.map(x => parseInt(x.id));
    }
    if (filterValueState.Company_type) {
      filter.channelTypeId = filterValueState.Company_type.id;
    }
    return filter;
  }

  const getUserListing = async () => {
    loading = true;
    setLoading(loading);
    var filter = await getFilterData();
    let data = {
      search: searchState.search ? searchState.search[0] ? searchState.search[0].id : null : null,
      filter: {
        countryId: filter.countryId ? filter.countryId : null,
        channelTypeId: filter.channelTypeId ? filter.channelTypeId : null,
      },
      page: page * rowsPerPage,
      limit: rowsPerPage
    }
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.post('/admin/userListing', data
    ).then(res => {
      //console.log("userlisting:",res.data);      
      state.dataTable = res.data.rows;
      state.total = res.data.count;
      loading = false;
      setLoading(loading);
      setState({ ...state });
    }).catch((err) => {
      state.dataTable = [];
      state.total = 0;
      loading = false;
      setLoading(loading);
      setState({ ...state });
    })
  }

  const getSearchList = () => {
    axios.get('/admin/searchList').then(res => {
      let data = [];
      res.data.map(x => {
        data.push({ name: x.fName.trim() + ' ' + x.lName.trim(), id: x.id });
      })

      //data.push(...contact);
      searchState.dropdownList = data
      setSearch({ ...state, dropdownList: searchState.dropdownList });
    })
  }

  const handleChangeSelect = (input, value) => {
    if (input == 'search') {
      filterValueState.Company_type = null;
      filterValueState.country = [];
      setFilterValue({ ...filterValueState });
      searchState.search = [value]
      setSearch({ ...searchState })
    }
    if (input == 'Company_type') {
      filterValueState.Company_type = value
      setFilterValue({ ...filterValueState })
    }
    if (input == 'country') {
      filterValueState.country = value
      setFilterValue({ ...filterValueState })
    }
    getUserListing();
  }

  const resetFilterValue = () => {
    filterValueState.Company_type = null;
    filterValueState.country = [];
    setFilterValue({ ...filterValueState });
    getUserListing();
  }

  const openContact = (data) => {
    setDrawerInput(data);
    setshowDrawer(true);
    console.log('datauser', data);
  }

  const closeDrawer = () => {
    setshowDrawer(false);
    setPasswordDrawer(false);
  }



  const callRefreshList = () => {
    closeDrawer();
    page = 0;
    setPage(page);
    getUserListing();
  }

  const callPendingChannel = () => {
    // ToastsStore.success('Password Updated Successfully!');
    callRefreshList();
  }

  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });

  const deleteUser = async (contactId) => {
    setLoading(true);
    await axios.delete('channelAdmin/deleteBoAdminUser/' + contactId).then(res => {
      ToastsStore.success('User Removed successfully')
      callRefreshList();
    }).catch(err => {
      ToastsStore.error('Something went wrong');
      setLoading(false);
    })
    console.log("DeleteUser:", contactId);
  }

  const confirmPopup = (res) => {
    setModalPopup(false);

    deleteUser(res.value);

  }
  const closePopup = () => {
    setModalPopup(false);
  };

  const deletePopup = (data) => {
    modalPopValue.message = 'Do you want to delete the user?';
    modalPopValue.title = 'Delete User';
    modalPopValue.value = data.adminUserId;
    modalPopValue.popUpId = 1;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);
  }


  const [sidenavOpen, setOpen] = useState(false);
  return (
    <div id="usermanage">
      {/* {viewuser &&
                              <UserView closemodals={closemodals}/>} */}
      {/* <div className={classes.root}> */}
      <Drawer title={(showDrawer && drawerInput.key == 'edit') ? "Edit Users" : "Add Users"} visible={showDrawer} contactData={drawerInput} closeDrawer={closeDrawer} reload={callRefreshList} />
      {showPasswordDrawer && <Drawer title="Reset BoUserPassword" visible={showPasswordDrawer} contactData={drawerInput} closeDrawer={closeDrawer} reload={callPendingChannel} />}
      <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <UserFilter
        Filter={true}
        filterOption={filterOptionState}
        filterValue={filterValueState}
        resetFilterValue={resetFilterValue}
        handleChangeSelect={handleChangeSelect}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen} />

      <SimpleHeader name="admin-users" parentName="Dashboard" />

      <Container className="mt--3" fluid>

        <Card>
          <CardHeader>
            <h3 className="mb-0">Users Management</h3>
          </CardHeader>
          {loading ? <Spinner /> : <ToolkitProvider
            data={state.dataTable}
            keyField="fName"
            columns={[
              {
                dataField: "Name",
                text: "Name",
                sort: true,
                formatter: (rowContent, row) => {
                  return (
                    <>

                      <Media className="align-items-center">
                        <a
                          className="avatar rounded-circle mr-3"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }}
                            alt="..."
                            src={require("assets/img/theme/bootstrap.jpg")}
                          />
                        </a>
                        <Media>
                          <span className="name mb-0 text-sm">
                            {row.fName + ' ' + row.lName}
                          </span>
                        </Media>
                      </Media>

                    </>
                  )
                }
              },

              // {
              //   dataField: "jobTitle",
              //   text: " Job Title",
              //   sort: true
              // },
              {
                dataField: "role",
                text: "Role",
                sort: true
              },
              {
                dataField: "emailId",
                text: "E-mail Id",
                sort: true
              },
              // {
              //   dataField: "reportingTo",
              //   text: "Reporting To",
              //   sort: true
              // },                    
              {
                dataField: "managedChannelTypes",
                text: "ChannelType Managing",
                sort: true,
                formatter: (rowContent, row) => {
                  return (
                    <>
                      {row.managedChannelTypes.map(type => type.name).join(',')}
                    </>
                  )
                }
              },
              {
                dataField: "managedCountries",
                text: "Country Managing",
                sort: true,
                formatter: (rowContent, row) => {
                  return (
                    <>
                      {row.managedCountries.map(cty => cty.countryid.name).join(',')}
                    </>
                  )
                }
              },
              {
                dataField: "Action",
                text: "Action",
                formatter: (rowContent, row, index) => {
                  return (
                    <>
                      <i id={'Edit' + index} onClick={() => openContact(Object.assign(row, { key: 'edit' }))} style={{ padding: "10px 10px 0 10px" }} class="fas fa-edit pointer">
                      <UncontrolledTooltip target={'Edit' + index} placement='bottom'> Edit </UncontrolledTooltip>
                      </i>

                      <i id={'Rpwd' + index} onClick={() => { openPassword(row)}} style={{ padding: "10px 10px 0 10px" }} class="fas fa-key pointer"></i>
                      <UncontrolledTooltip target={'Rpwd' + index} placement='bottom'> ResetPassword </UncontrolledTooltip>

                      <a href="#" onClick={(e) => {e.preventDefault(); deletePopup(row)}}><i class="fas fa-trash-alt ml-4" style={{ color: "#fb6340" }}></i></a>

                    </>
                  )
                }
              }


            ]}
            search
          >
            {props => (
              <div className="py-2 table-responsive">
                <div
                  id="datatable-basic_filter"
                  className="dataTables_filter px-4 mb-2 "
                >
  
                  <div style={{ width: '100%' }}>
                    <SelectAutocomplete
                      id="search"
                      type="text"
                      label="search"
                      name="search"
                      className="d-inline-block"
                      style={{ width: '30%' }}
                      options={searchState.dropdownList}
                      placeholder="search..."
                      disableCloseOnSelect
                      // defaultValue={searchState.search?searchState.search[0]:null}
                      control={control}
                      input='search'
                      value={searchState.search ? searchState.search[0] : null}
                      handleChangeSelect={handleChangeSelect}
                    />
                  <button  class="btn btn-primary float-right" type="button" onClick={() => openContact({ key: 'add' })}>Add User</button>

                  </div>

                </div>
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  // pagination={pagination}
                  bordered={false}
                />

                <TablePagination
                  component="div"
                  count={state.total}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </div>
            )}
          </ToolkitProvider>}








        </Card>

        {/* <Card>
              <CardHeader className="border-0">
                  <h3 className="mb-0">Light table</h3>
                </CardHeader>
                <section id="users" className="table-responsive" ref={firstlist}>
              <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="fName" scope="col">
                      NAME
                      </th>
                      <th className="sort" data-sort="jobTitle" scope="col">
                      JOB TITL
                      </th>
                      <th className="sort" data-sort="role" scope="col">
                      ROLE
                      </th>
                      <th className="sort" data-sort="emailId" scope="col">
                      E-MAIL ID
                      </th>
                      <th className="sort" data-sort="reportingTo" scope="col">
                      REPORTING TO
                      </th>
                      <th className="sort" data-sort="managedChannelTypes" scope="col">
                      CHANNELTYPE MANAGING
                      </th>
                      <th className="sort" data-sort="managedCountries" scope="col">
                      COUNTRY MANAGING
                      </th>
                   
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                  {state.dataTable.map(data => (
          <tr key={data.adminUserId}>
            <td className="fName">{data.fName}</td>
            <td className="jobTitle">{data.jobTitle}</td>
            <td>{data.role}</td>
            <td>{data.emailId}</td>
            <td>{data.reportingTo}</td>
            <td>
            {data.managedChannelTypes.map(type => type.channelType).join(',')}
            </td>
            <td>
            {data.managedCountries.map(cty => cty.countryid.country).join(',')}</td>
          </tr>
        ))}

                 <tr style={{display:'none'}}>
                   <td className="fName">
                     teer
                   </td>
                 
                 </tr> 
              
                  </tbody>
                </Table>
                </section>
              </Card>
         */}
      </Container>
    </div>
  );
};
export default UserManage;
