import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import roomData from './hotel_room'; // Assuming 'hotel_room.js' is your external file
import { ToastsContainer, ToastsStore } from 'react-toasts';
 
const filter = createFilterOptions();

const RoomNames = ({hotelRoomDetailOnChange, value, selectRoomNameArray,hotelRoomDetails,roomId}) => {
  
  const find = (value) => {
    return selectRoomNameArray.some((item) => item == value);
  };

  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const filteredOptions = roomData
      .map(option => option.value)
      .filter(value => !find(value)); 
    setDropdownOptions(filteredOptions);
  }, [roomData, selectRoomNameArray]);


  const handleAddOption = (newOption) => {
    const updatedOptions = [...dropdownOptions, newOption];
    setDropdownOptions(updatedOptions);
    setSelectedOptions([...selectedOptions, newOption]);
    if(hotelRoomDetailOnChange){
      hotelRoomDetailOnChange(null,null,newOption)
    }
  };

  return (
    <>
    <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <Autocomplete
        // multiple
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push(`${params.inputValue}`);
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={dropdownOptions}
        value={value!=(undefined && null) ? value : selectedOptions}
        onChange={(e, newValue) => {
          if(newValue && newValue.length > 0 && newValue.charAt(0) == " "){
            newValue= newValue.substring(1);
          }

          if(newValue != null){
          for(let i=0; i<hotelRoomDetails.length; i++){
            if(hotelRoomDetails[i].roomName == newValue && hotelRoomDetails[i].id != roomId){
                ToastsStore.warning("This room name is already used"); 
                newValue = " "
              }
            }   
          }
 
          setSelectedOptions(newValue);
          if(hotelRoomDetailOnChange){
            hotelRoomDetailOnChange(null,null,newValue)
          }
        }}
        renderOption={(option) =>
            option.startsWith('Add "') ? (
              <button className='add_roomName' onClick={() => handleAddOption(option.slice(5, -1))}>Add</button>
            ) : (
              option
            )
          }
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select room names"
            variant="outlined"
          />
        )}
      />
    </>
  );
};

export default RoomNames;
