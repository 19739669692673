import React, { useState, useEffect } from "react";
import { Card, Button, CardHeader, CardBody, Container, Row, Col, UncontrolledTooltip, InputGroupAddon, InputGroupText, InputGroup } from "reactstrap";
import { useForm } from "react-hook-form";
import { FormInput, FormAutocomplete, FormRadio, FormCheckBox, FormAutocompleteGrouping,FormAutocompleteGroupingRegion, PhoneInput2 } from "_components/FormElements/FormInput";
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import ImageLibrary from 'views/Hoc/Dialog/ImageLibrary';
import axios from 'axios';
import { history } from '../../../_helpers';
import { ModalSpinner } from 'views/Hoc/Spinner';
import { Spinner } from 'views/Hoc/Spinner';
import { GeneralFormSchema1 } from '../profile/Settings/validation';
import { Drawer } from 'antd';
import Preference from '../product/Preference';
import SimpleHeader from "components/Headers/SimpleHeader";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { commonService } from '_services/common.service';
import { useDispatch } from 'react-redux'
var _ = require('lodash');


// const PreferenceForm = (props) => {
//   const { closeDrawer, visible, title, contactId, channelId, userData } = props;
//   return (
//     <div className="site-form-in-drawer-wrapper">
//       <Drawer title={title} onClose={closeDrawer} visible={visible}>
//         <Preference channelId={channelId} contactId={contactId} userData={userData} visible={visible} />
//       </Drawer>
//     </div>
//   );
// };

const AddUser = (props) => {
  const [loading, setLoading] = useState(false);
  const [showDrawer, setshowDrawer] = useState(false);
  const { register, handleSubmit, control, errors, watch, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(GeneralFormSchema1),
  });

  console.log("errors:", errors);

  var userData = props.location.state.channelData; //JSON.parse(localStorage.getItem('CHuser'));
  userData.userId = +userData?.user[0]?.userId;
  let user = userData;
  let typeId = parseInt(userData?.channelTypeId);

  const dispatch = useDispatch();

  let contactId = null
  let data = useSelector(state => state.adduser);
  let [mail, setMail] = useState(null);
  const [checkMail, setCheckMail] = useState(0);
  let [userId, setUserId] = useState(null);
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    reportingTo: '',
    phone: ['', '', ''],
    mobileNo: ['', '', ''],
    empId: '',
    HSContact: '',
    userType: 3,
    companyLogo: '',
    status: true,
    country: '',
    countryList: [],
    linkedinurl: '',
    twitterurl: '',
    isShared: '1',
    isRole: '0',
    documentId: null,
    reportingList: [],
    sellingCountries: [],
    sellingCountriesOption: [],
    targetCountries: [],
    targetCountriesOption: [],
    productValue: [],
    productOptions: [],
    suggestions: '1',
    networkInvitation: '1',

    countryCodemob: '',
    countryCodemob1: ''
  });

  let userParams = {
    channelId: +userData.channelId,
    userId: +userData?.user[0]?.userId
  };


  const watchAll = watch(['firstName', 'lastName', 'mobile', 'phone', 'email', 'jobposition', 'TwitterURL', 'LinkedinURL']);

  const selectedItem = (gname, check, optionName, optionValue) => {
    let opt = [];
    console.log("check:", check, gname);
    if (check === true) {
      state[optionName].map((item) => {
        console.log("groupname:", item.groupname);
        if (item.groupname === gname || item.status === true) {
          item.status = check;
          opt.push(item);
        }
      });
    }
    if (check === false) {
      console.log("check:", check);
      state[optionName].map((item) => {
        if (item.groupname === gname && state[optionValue].length > 0)
          item.status = check;
      });
      opt = state[optionValue].filter((item) => item.groupname !== gname);
      opt.map(option => option.status = true);
    }
    state[optionValue] = opt;
    setState({ ...state, [optionValue]: state[optionValue] });
    setState({ ...state, [optionName]: state[optionName] });

    if (optionValue === 'sellingCountries' && state.sellingCountries.length === 0)
      errors.selling = true;
    else if (optionValue === 'targetCountries' && state.targetCountries.length === 0)
      errors.target = true;
    else if (optionValue === 'productValue' && state.productValue.length === 0)
      errors.productCat = true;
  }
  useEffect(() => {
    // console.log('props.location.pathname',props.location.pathname);
    // console.log('userData',userData);
    // contactId = props.location.pathname==="/admin/profileform"? parseInt(userData.contactId):props?.location?.state?parseInt(props.location?.state?.contactId):null;
    if (contactId) {
      (async () => {
        await setTradelocation();
        await setUserDetail();
        await setProductFamilies();
      })();
    } else {

      (async () => {
        // await setTradelocation();
        setLoading(true);
        const apiUrl = '/channelAdmin/getInitialProfile';
        // await axios.post(apiUrl, userData).then(res => {
        //   state.productOptions = res.data.productGroup;
        //   setState({ ...state });
        // });

        await axios.post('channelAdmin/getUserProfile/', userParams).then(res => {

          let selling = [];
          let target = [];

          state.sellingCountriesOption = res.data.regions1;
          selling = res.data.regions1;
          state.targetCountriesOption = res.data.regions;
          target = res.data.regions;

          setState({ ...state, targetCountriesOption: state.targetCountriesOption });
          setState({ ...state, sellingCountriesOption: state.sellingCountriesOption });

        }).catch(err => {
          setLoading(false);
          commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getUserProfile' }, '/error')
        })

        // if (typeId == 2) {
        //   const apiUrl1 = '/channelAdmin/userProduct';
        //   await axios.post(apiUrl1, userParams).then(res => {
        //     //state.productFamilies=res.data.product;
        //     state.productValue = res.data.product;

        //   });
        // }

        state.productOptions = state.productOptions.map(x => {
          state.productValue.map(y => {
            if (x.groupid == y.groupid && x.childid == y.childid)
              x.status = true;
          })
          return x;
        })

        await axios.get('common/getSignup/' + userData?.channelTypeId).then(res => {
          state.countryList = res.data.country;
          //setState({...state, countryList:res.data.country});                  
        });

        await axios.get('channelAdmin/channelCountry/' + userData?.channelId).then(res => {
          state.countryCodemob = res.data.isoCode;
          state.countryCodemob1 = res.data.isoCode;
          let temp = state.countryList.filter(x => x.id == res.data.countryId);
          state.country = temp.length ? temp[0] : null;
        })

        // await axios.post('/channelAdmin/reporting', userParams).then(res => {
        //   state.reportingList = res.data;
        // });

        setState({ ...state, productFamilies: state.productFamilies, productValue: state.productValue });
        setLoading(false);
      })();
      // history.push('/admin/dashboard');
    }
  }, []);

  // useEffect(() => {  
  //   if(!_.isEmpty(errors)&&formState.isSubmitted)
  //   {
  //     ToastsStore.error(JSON.stringify(errors));
  //     commonService.postService({err:JSON.stringify(errors),reqUrl:window.location.pathname,service:'validation_err'},'/error')    
  //   }
  // }, [errors])


  const closeDrawer = () => {
    setshowDrawer(false);
  }
  const setUserDetail = async () => {
    let data = {
      channelId: +userData.channelId,
      userId: +userData.userId
    };
    try {
      setLoading(true);

      dispatch({ type: 'PREFERENCE', data: { checkedKeys: [], checkedKeys1: [] } });

      await axios.get('common/getSignup/' + userData?.channelTypeId).then(res => {
        state.countryList = res.data.country;
      }).catch(err => {
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'common/getSignup' }, '/error');
      });

      //setting default country as channel country , if contact has country data it will be updated on generalinfo api
      await axios.get('channelAdmin/channelCountry/' + userData?.channelId).then(res => {
        state.countryCodemob = res.data.isoCode;
        state.countryCodemob1 = res.data.isoCode;
        let temp = state.countryList.filter(x => x.id == res.data.countryId);
        state.country = temp.length ? temp[0] : null;
      })

      await axios.post('channelAdmin/reporting', data).then(res => {
        state.reportingList = res.data;
      }).catch(err => {
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/reporting' }, '/error');
      });
      await axios.post('channelAdmin/generalInfo', { contactId: contactId }).then(res => {
        console.log('response', res.data);
        userId = res.data.userId;
        setUserId(userId);
        let country = state.countryList.filter(x => parseInt(x.id) === parseInt(res.data.countryId));
        if (country.length) {
          state.country = country[0];
          state.countryCodemob = state.country.isoCode;
          state.countryCodemob1 = state.country.isoCode;
        }

        let report = state.reportingList.filter(x => parseInt(x.id) === parseInt(res.data.rptContactId));

        if (report.length) {
          state.reportingTo = report[0];
        }

        state.firstName = res.data.firstName;
        state.lastName = res.data.lastName;
        state.isRole = res.data.isMarkettingRole;
        state.empId = res.data.empId;
        state.HSContact = res.data.hubspotContactId;
        state.email = res.data.email;
        mail = res.data.email
        setMail(mail);
        state.jobTitle = res.data.jobTitle;
        state.linkedinurl = res.data.sociallink ? res.data.sociallink[0]?.contactSocialLink : '';
        state.twitterurl = res.data.sociallink ? res.data.sociallink[1]?.contactSocialLink : '';
        state.phone[1] = res.data.phone1;
        state.phone[0] = res.data.phone1 ? res.data.phone1.replace(/[^A-Z0-9]/ig, "") : '';
        state.mobileNo[1] = res.data.mobileNo;
        state.mobileNo[0] = res.data.mobileNo ? res.data.mobileNo.replace(/[^A-Z0-9]/ig, "") : ''; //.replace('-','');
        state.companyLogo = res.data.documentPath;
        state.contactId = contactId;
        state.documentId = res.data.documentId;
        state.isShared = res.data.isShared;
        state.userType = parseInt(res?.data?.roleId);
        state.status = parseInt(res?.data?.isActive) === 1 ? true : false;
        state.suggestions = res.data.preference.suggestion_comm ? res.data.preference.suggestion_comm : '1';
        state.networkInvitation = res.data.preference.invitation_comm ? res.data.preference.invitation_comm : '1';
      }).catch(err => {
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/generalInfo' }, '/error');
      });
      setState({ ...state });
      // setLoading(false);
    }
    catch (err) {
      //ToastsStore.error('User Not Found!');
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setUserDetail' }, '/error');
    }
  }
  const setTradelocation = async () => {
    let data = {
      channelId: +userData.channelId,
      userId: +userData.userId,
      channelTypeId: +userData.channelTypeId
    };
    try {
      let target = []; let selling = [];
      setLoading(true);
      //   await axios.post('channelAdmin/tradelocation', data).then(res =>{
      //     target=res.data.targetLocation;
      //     selling=res.data.sellingLocation;
      //     selling.map(x => {
      //       x.status=false;
      //     })
      //     target.map(x => {
      //       x.status=false;
      //     })
      //     state.targetCountriesOption=res.data.targetLocation;
      //     state.sellingCountriesOption=res.data.sellingLocation;
      //     console.log('state *******', state);
      //     setState({...state});
      //   }).catch(err=> {
      //     commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'setting/tradelocation'},'/error');                     
      //  });

      //   selling.map(x => {
      //     x.status=false;
      //   })
      //   target.map(x => {
      //     x.status=false;
      //   })

      await axios.post('channelAdmin/getUserProfile/', data).then(res => {

        state.sellingCountriesOption = res.data.regions1;
        selling = res.data.regions1;
        state.targetCountriesOption = res.data.regions;
        target = res.data.regions;

        setState({ ...state, targetCountriesOption: state.targetCountriesOption });
        setState({ ...state, sellingCountriesOption: state.sellingCountriesOption });

      }).catch(err => {
        setLoading(false);
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getUserProfile' }, '/error')
      })

      await axios.post('/channelAdmin/getContactLocation/' + contactId, data).then(res => {
        target = target.map(x => {
          res.data.targetArray.map(y => {
            if (x.groupid == y.groupid && x.childId == y.childId)
              x.status = true;
          })
          return x;
        });

        selling = selling.map(x => {
          res.data.sellingArray.map(y => {
            if (x.groupid == y.groupid && x.childId == y.childId)
              x.status = true;
          })
          return x;
        });
        state.targetCountriesOption = target;
        state.targetCountries = res.data.targetArray.length === 0 ? [] : res.data.targetArray;
        state.sellingCountries = res.data.sellingArray.length === 0 ? [] : res.data.sellingArray;
        state.sellingCountriesOption = selling
        setState({ ...state });
      }).catch(err => {
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getContactLocation' }, '/error');
      });
    }
    catch (err) {
      //ToastsStore.error('User Location Not Found!');
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setTradelocation' }, '/error');
    }

  }

  const setProductFamilies = () => {
    let data = {
      channelId: +userData.channelId,
      userId: +userData.userId
    };
    try {
      (async () => {
        const apiUrl = '/channelAdmin/getInitialProfile';
        await axios.post(apiUrl, data).then(res => {
          state.productOptions = res.data.productGroup;
        }).catch(err => {
          commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getInitialProfile' }, '/error');
        });

        await axios.get('/channelAdmin/getContactProduct/' + contactId + '/' + userData.channelId).then(res => {
          console.log('contatc', res.data);
          state.productValue = res.data.productGroup;

        }).catch(err => {
          commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getContactProduct' }, '/error');
        });
        state.productOptions = state.productOptions.map(x => {
          state.productValue.map(y => {
            if (x.groupid == y.groupid && x.childid == y.childid)
              x.status = true;
          })
          return x;
        })
        setState({ ...state });
        setLoading(false);
      })();
    }
    catch (err) {
      //ToastsStore.error('User Product Not Found!');
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setProductFamilies' }, '/error');
    }
  }


  const getCompanyLogo = (resImagePath) => {

    setState({ ...state, companyLogo: resImagePath.document.documentUrl, documentId: resImagePath.documentId });

  };



  const handleChange = input => e => {
    if (input === 'status') {
      setState({ ...state, [input]: e.target.checked });
    } else if (input === 'userType') {
      setState({ ...state, [input]: parseInt(e.target.value) });
    } else
      setState({ ...state, [input]: e.target.value });
  }

  //console.log('watchAll', state, watchAll);

  const handleChangeSelect = (input, value) => {
    console.log(input, value)
    if (input === 'product') {
      state.productOptions.map((item) => {
        if (value.length === 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   if(v.childname!==item.childname)
          //     item.status=false;                 
          //   else if(v.childname===item.childname)            
          //     item.status=true;                       

          // });      
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.productValue = value
      setState({ ...state });

      if (state.productValue.length === 0)
        errors.productCat = true;
    } else if (input === 'sellingCountries') {
      state.sellingCountriesOption.map((item) => {
        if (value.length === 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   if(v.childname!==item.childname)
          //     item.status=false;                 
          //   else if(v.childname===item.childname)            
          //     item.status=true;                       

          // });     
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.sellingCountries = value
      setState({ ...state });

      if (state.sellingCountries.length === 0)
        errors.selling = true;
    } else if (input === 'targetCountries') {
      state.targetCountriesOption.map((item) => {
        if (value.length === 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   if(v.childname!==item.childname)
          //     item.status=false;                 
          //   else if(v.childname===item.childname)            
          //     item.status=true;                       

          // });     
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.targetCountries = value
      setState({ ...state });

      if (state.targetCountries.length === 0)
        errors.target = true;
    }
    // else if(input==='product'){
    //   state.productOptions.map((item) => {      
    //     if(value.length===0)
    //       item.status=false;
    //     else
    //     {
    //       value.map(v => {          
    //         if(v.childname!==item.childname)
    //           item.status=false;                 
    //         else if(v.childname===item.childname)            
    //           item.status=true;                       

    //       });      
    //     }
    //   });

    //   value.map(option => option.status=true);
    //   state.productValue= value
    //   setState({...state});

    //   // state.productValue= value
    //   // setState({...state, productValue:state.productValue});
    //   if(state.productValue.length===0)
    //     errors.productValue=true;

    //   let Group=_.uniq(state.productValue.map(x => x.groupid));
    //   state.groupCount=Group.length;
    //   setState({...state});     



    // }
    else if (input === 'country' || input === 'reportingTo' || input == 'phone' || input == 'mobileNo') {
      state[input] = value;
      setState({ ...state });
    } else {
      state[input] = [value];
      setState({ ...state });
    }
  }


  const save = async (e) => {
    // e.preventDefault();
    console.log("userform");
    try {
      setLoading(true);
      let userData = state;
      if ((contactId && (userData.email.trim() !== mail.trim()) || !contactId)) {
        let testMail = contactId ? await commonService.postService({ userId: userId }, 'user/checkusermail/' + userData.email) : await commonService.getServices('account/checkmail/' + userData.email).then(res => {
          return res;
        });

        if (testMail?.data) {
          if (!testMail.data.isavialble) {
            setCheckMail(1);
            setLoading(false);
            return false;
          } else { setCheckMail(0); }
        } else
          setCheckMail(1);
      } else
        setCheckMail(0);
      let trade = state.productValue;
      // let loc = data.trade_loc;
      let sellingLocation = []; let targetLocation = [];

      if (typeId === 4 || typeId === 3 || typeId == 5)
        sellingLocation = state.sellingCountries.map(x => +x.regionCountryJCTId);
      if (typeId === 2)
        targetLocation = state.targetCountries.map(x => +x.regionCountryJCTId);


      let loc = {
        sellingLocation: sellingLocation,
        targetLocation: targetLocation
      }
      let contact = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        documentId: parseInt(userData.documentId),
        countryId: parseInt(userData.country.id),
        isActive: userData.status ? '1' : '0',
        isShared: userData.isShared,
        jobTitle: userData.jobTitle,
        linkedInUrl: userData.linkedinurl,
        googlePlusUrl: userData.twitterurl,
        phoneNo: userData.phone[2]?.length >= 4 ? userData.phone[1] : null,
        mobileNo: userData?.mobileNo[1],
        role: parseInt(userData.userType),
        rptContactId: userData.userType != 1 ? parseInt(userData.reportingTo?.id) : null,
        workNatureId: 1,
        suggestion_comm: +state.suggestions,
        invitation_comm: +state.networkInvitation,
        ...(typeId == 2 && { isMarkettingRole: state.isRole }),

      };
      let locations = [];

      let productCategories = [];
      //console.log('productValue',trade);
      trade.map(x => {
        let obj = {
          productFamilId: x.groupid,
          productCategoriesId: x.childid
        }
        productCategories.push(obj);
      });

      // let interestedProductCategories = [].push(trade.intProductValue.map(x => ({
      //   productFamilId: x.groupid,
      //   productCategoriesId: x.childid,
      // })));

      let productFamilies = _.uniq(trade.map(x => +x.groupid));
      productFamilies = productFamilies.map(x => ({ id: x }));

      // let interestedProductFamilies = _.uniq(trade.intProductValue.map(x => +x.groupid));
      // interestedProductFamilies = interestedProductFamilies.map(x => ({id: x}));

      if (typeId === 2 && state.isRole == '0')
        locations.push({
          locationType: 'TARGET',
          isUpdate: true,
          location: loc.targetLocation,
        });
      // if (typeId === 3)
      //   locations.push({
      //     locationType: 'SELLING',
      //     isUpdate: true,
      //     location: loc.sellingLocation,
      //   });
      // if (typeId === 4)
      //   locations.push({
      //     locationType: 'RETAIL',
      //     isUpdate: true,
      //     location: loc.sellingLocation,
      //   });
      // if (typeId === 5)
      //   locations.push({
      //     locationType: 'RESELLING',
      //     isUpdate: true,
      //     location: loc.sellingLocation,
      //   });

      console.log("data", data);
      let checkedKeys = [];
      let checkedKeys1 = [];
      let accessPermissions = [];
      if (data?.preference?.checkedKeys)
        checkedKeys = data?.preference?.checkedKeys
      if (data?.preference?.checkedKeys1)
        checkedKeys1 = data?.preference?.checkedKeys1
      if (checkedKeys.length > 0 || checkedKeys1.length > 0) {
        accessPermissions = checkedKeys.map(x => ({ id: +x }));
        accessPermissions = accessPermissions.concat(checkedKeys1.map(x => ({ id: +x })));
      }
      let sendData = {
        channelTypeId: +typeId,
        contact: contact,
        ...(typeId == 2 && state.isRole == '0' && { locations: locations }),
        productCategories: productCategories,
        productFamilies: productFamilies,
        accessPermissions: accessPermissions
      }
      if (sendData.accessPermissions?.length === 0)
        delete sendData.accessPermissions;
      // if(interestedProductFamilies.length)
      //   sendData = Object.assign(sendData, {interestedProductCategories: interestedProductCategories, interestedProductFamilies: interestedProductFamilies});
      //console.log('savingdata', sendData);    

      if (contactId) {
        await axios.patch('channelAdmin/profile/' + contactId + '/' + user.channelId + '/' + user.userId, sendData).then(async res => {
          ToastsStore.success('Saved Successfully!');
          axios.get('algorithm/channel/' + contactId);
          if (typeId != 2) {
            await axios.get('algorithm/product/' + contactId);
            //axios.post('mailerservice/Product_Suggestion_Mail', { contactId: user.contactId, channelId: user.channelId });
          }
          //console.log('response', res.data);      
          await setTradelocation();
          await setUserDetail();
          await setProductFamilies();
          setLoading(false);
          //history.push('admin/profileform');
        }).catch(err => {
          ToastsStore.error('Something Went Wrong!');
          setLoading(false);
          commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'UserEdit' }, '/error');
        });
      }
      else {


        if (sendData['channelTypeId'] == 2)
          sendData['accessPermissions'] = [{ id: 26 }, { id: 53 }, { id: 25 }, { id: 2111 }, { id: 2222 }, { id: 2 }, { id: 4 }, { id: 12111 }, { id: 12222 }, { id: 12 }, { id: 14 }, { id: 1 }, { id: 50111 }, { id: 50222 }]
        else
          sendData['accessPermissions'] = [{ id: 26 }, { id: 53 }, { id: 25 }, { id: 2111 }, { id: 2222 }, { id: 2 }, { id: 4 }, { id: 12111 }, { id: 12222 }, { id: 12 }, { id: 14 }, { id: 1 }]

        sendData["user"] = {
          userId: user.userId,
          channelId: user.channelId
        };

        await axios.post('channelAdmin/addUserBo', sendData).then(res => {
          setLoading(false);
          // Mail Code
          let maildata = {
            to: res.data.created.email,
            name: res.data.created.contact.firstName,// + " " + res.data.created.contact.lastName,
            profileurl: process.env.REACT_APP_LOCALSITE,
            userId: res.data.created.email,
            password: res.data.created.password,
            accountOwner: +res.data.created.contact.rptContactId,
          }
          axios.post('mailerservice/Add_User_Mail', maildata);

          history.push({ pathname: '/admin/CompanyListing/'});
        }).catch(err => console.log('useradderror', err));

      }

    }

    catch (err) {
      console.log("sellerr:", typeId);
      ToastsStore.error('Something Went Wrong!');
      setLoading(false);
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'UserEdit' }, '/error');
    }

  }
  const revomeProfileImage = () => {
    setState({ ...state, companyLogo: "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png", documentId: 1 });
  }

  const valid = () => {
    if (!errors.mobile || state.mobileNo[2].length >= 4)
      return true;
    return 'Mobile No Must Have Min 4 Numbers';
  }

  const valid1 = () => {
    if (!errors.phone || state.phone[2].length >= 4)
      return true;
    return 'Phone No Must Have Min 4 Numbers';
  }

  return (
    <>
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <SimpleHeader location={props.match} />
      <div className="main-content">
        {loading ? <Spinner /> : (
          <Container className="mt-4 col-lg-12 col-md-9 col-sm-12" fluid>
            <form noValidate onSubmit={handleSubmit(save)}>
              {loading && <div style={{ top: '50%', left: '50%', zIndex: '1000', }}><ModalSpinner /></div>}
              <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="typeId" ref={register} name="typeId" value={typeId} control={control} />
              <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="roleId" ref={register} name="roleId" value={state.userType} control={control} />
              <Card>
                <CardHeader>
                  <h3 className="text-center mt-4">User Profile</h3>
                </CardHeader>
                <CardBody>
                  <Row>

                    <Col lg="6">
                      <h3>Your Picture *{!errors.companyLogo || state.companyLogo ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select Profile Image</span>}</h3>
                      <Row>
                        <Col xl="2" lg="3" md="4" sm="5" style={{ padding: "0px" }}>
                          <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="companyLogo" name="companyLogo" ref={register} value={state.companyLogo} />
                          <div>
                            <ImageLibrary userLogo={true} getDocumentId={getCompanyLogo} aws_folderName='account-s3' id={2} size={1}
                              img={state.companyLogo ? state.companyLogo : "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"} channelData={userData} />
                          </div>
                        </Col>
                        <Col style={{ paddingTop: "2.5rem" }} xl="10" lg="9" md="8" sm="7">
                          <span>Accepts Jpeg / Jpg / Png Only</span>
                          <br></br>
                          <span>(Max size allowed 200 Kb)</span>
                          {(state.documentId && state.documentId != 1) && <div class="d-block mt-1">
                            <i onClick={revomeProfileImage} id="imgr" class="fa fa-trash pointer" aria-hidden="true"></i>
                            <UncontrolledTooltip delay={0} placement="bottom" target="imgr">Remove Picture</UncontrolledTooltip>
                          </div>}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="6">
                      <div className="form-group text-sm mb-0">
                        <h3>User Type <span id='algorithm2'><i class="far fa-handshake didy"></i></span>
                          <UncontrolledTooltip className='didy2 handshak' delay={0} placement="bottom" target="algorithm2">This field is included in the matching algorithm</UncontrolledTooltip></h3>
                        {state.userType !== 1 && <FormRadio
                          name="User type"
                          className="text-sm mb-0"

                          options={radioGroupOptions1}
                          control={control}
                          row
                          defaultValue={state.userType}
                          value={state.userType}
                          onChange={handleChange('userType')}
                        />}
                      </div>
                      {state.userType === 1 && <span> Acccount Owner</span>}
                      {state.userType !== 1 && <Row>
                        <Col lg="2" style={{ padding: "0px" }}><h3>Status</h3></Col>
                        <Col lg="10">
                          <div className="form-group">
                            <label className="custom-toggle custom-toggle-success">
                              <input defaultChecked checked={state.status} type="checkbox" ref={register} name="status" onChange={handleChange('status')} />
                              <span className="custom-toggle-slider rounded-circle" data-label-off="Inactive" data-label-on="Active"></span>
                            </label>
                          </div>
                        </Col>
                      </Row>}
                    </Col>
                  </Row>

                  {/* { addOrEdit==='EDIT' && <div className="row">
                    <div className="col-lg-6">
                        <FormInput 
                           id="empid"
                           type="text"
                           name="empid"
                           label="Employee ID *"
                           onChange={handleChange('empid')}
                           defaultValue={state.empId}
                           value={watchAll.empid}
                           disabled={true}/>
                    </div>
                    <div className="col-lg-6">
                        <FormInput 
                           id="hscontact"
                           type="text"
                           name="hscontact"
                           label="HS Contact ID *"
                           onChange={handleChange('hscontact')}
                           defaultValue={state.HSContact}
                           value={watchAll.hscontact}
                           disabled={true}
                      />
                    </div>
  </div>} */}

                  <Row>
                    <div className="col-lg-6 mt-4">
                      <div className="form-group">
                        <FormInput
                          id="firstName"
                          type="text"
                          name="firstName"
                          label="First Name *"
                          register={register}
                          defaultValue={state.firstName}
                          error={errors.firstName}
                          value={watchAll.firstName}
                          onChange={handleChange('firstName')}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                      <div className="form-group">
                        <FormInput
                          id="lastName"
                          type="text"
                          name="lastName"
                          label="Last Name *"
                          //placeholder="Password"
                          register={register}
                          error={errors.lastName}
                          value={watchAll.lastName}
                          defaultValue={state.lastName}
                          onChange={handleChange('lastName')}
                        />
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <FormInput
                          id="email"
                          type="text"
                          label="Contact Email *"
                          name="email"
                          control={control}
                          register={register}
                          error={errors.email ? errors.email : checkMail ? { message: "Email Id already taken", required: true } : ''}
                          defaultValue={state.email}
                          value={watchAll.email}
                          onChange={handleChange('email')}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <FormAutocomplete
                          id="country"
                          name="country"
                          label="Country"
                          options={state.countryList}
                          placeholder="Select Country"
                          // multiple
                          disableCloseOnSelect
                          blurOnSelect
                          limitTags={1}
                          control={control}
                          input='country'
                          value={state.country}
                          handleChangeSelect={handleChangeSelect}
                          errMessage={'Please Select Country'}
                          error={errors.country}
                          defaultValue={state.country}
                        />
                      </div>
                    </div>
                  </Row>

                  <Row>
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">

                        {/* <label className="form-control-label" htmlFor=""> */}
                        {/* <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="phone" ref={register} name="phone"/> */}
                        {/* </label> */}
                        {/* <InputGroup  className="reg reg_12">
  <InputGroupAddon addonType="prepend">
    <InputGroupText></InputGroupText>
  </InputGroupAddon> */}

                        <h3>Contact Phone Number</h3>
                        <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="phone" name="phone" ref={register} value={state.phone[0]} />
                        <PhoneInput2
                          id="phone"
                          label="Contact Phone Number"
                          name="phone"
                          placeholder={'Contact Phone Number'}
                          mobile={state.phone[0]}
                          error={errors.phone}
                          register={register}
                          control={control}
                          // onFocus={(value, country, e, formatted) => setFocused(true)}
                          country_code={state.countryCodemob1 ? state.countryCodemob1 : ''}
                          handleChangeSelect={handleChangeSelect}
                          defaultErrorMessage={(!errors.phone || state.phone[2].length >= 4) ? '' : 'Please Enter Valid No.'}
                          isValid={valid1}
                        />


                        {/* <FormInput
                          className="comp-name12"
                          id="phone"
                          type="text"
                          label="Contact Phone Number"
                          name="phone"
                          register={register}
                          error={errors.phone}
                          defaultValue={state.phone}
                          value={watchAll.phone}
                          onChange={handleChange('phone')}
                        /> */}
                        {/* </InputGroup> */}
                      </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">

                        {/* <label className="form-control-label" htmlFor=""> */}
                        {/* <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="mobile" ref={register} name="mobile"/> */}
                        {/* </label> */}
                        {/* <InputGroup  className="reg reg_12">
  <InputGroupAddon addonType="prepend">
    <InputGroupText></InputGroupText>
  </InputGroupAddon> */}
                        <h3>Mobile No *</h3>
                        <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="mobile" name="mobile" ref={register} value={state.mobileNo[0]} />
                        <PhoneInput2
                          id="switchPhone1"
                          label="Mobile No"
                          name="mobileNo"
                          placeholder={'Mobile No *'}
                          mobile={state.mobileNo[0]}
                          error={errors.mobile}
                          register={register}
                          control={control}
                          // onFocus={(value, country, e, formatted) => setFocused(true)}
                          country_code={state.countryCodemob ? state.countryCodemob : ''}
                          handleChangeSelect={handleChangeSelect}
                          defaultErrorMessage={(!errors.mobile || state.mobileNo[2].length >= 4) ? '' : 'Please Enter Valid No.'}
                          isValid={valid}
                        />

                        {/* <FormInput
                          id="mobile"
                          className="comp-name12"
                          type="text"
                          label="Mobile Number *"
                          name="mobile"
                          register={register}
                          error={errors.mobile}
                          defaultValue={state.mobileNo}
                          value={watchAll.mobile}
                          onChange={handleChange('mobileNo')}
                        /> */}
                        {/* </InputGroup> */}
                      </div>
                      {/* <div className="form-group">
          <FormInput
              id="mobile" 
              type="text" 
              label="Mobile Number *" 
              name="mobile" 
              register={register}
              error={errors.mobile}
              defaultValue={state.mobileNo}
              value={watchAll.mobile}
              onChange={handleChange('mobileNo')}
           />
          </div> */}
                    </div>
                  </Row>

                  <Row>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <FormInput
                          id="jobposition"
                          type="text"
                          label="Job Position"
                          name="jobposition"
                          control={control}
                          register={register}
                          error={errors.jobposition}
                          defaultValue={state.jobTitle}
                          value={watchAll.jobposition}
                          onChange={handleChange('jobTitle')}
                        />
                      </div>
                    </div>
                    {state.userType != '1' && <div className="col-lg-6">
                      <div className="form-group">
                        <FormAutocomplete
                          id="reportingTo"
                          type="text"
                          label="Reporting To"
                          name="reportingTo"
                          options={state.reportingList}
                          disableCloseOnSelect
                          blurOnSelect
                          limitTags={1}
                          control={control}
                          input='reportingTo'
                          value={state.reportingTo}
                          handleChangeSelect={handleChangeSelect}
                          errMessage={'Please Select Reporting Id'}
                          error={errors.reportingTo}
                          defaultValue={state.reportingTo}
                        />

                      </div>
                    </div>}
                  </Row>
                  <Row>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <FormInput
                          id="LinkedinURL"
                          type="text"
                          label="Linkedin URL"
                          name="LinkedinURL"
                          register={register}
                          error={errors.LinkedinURL}
                          defaultValue={state.linkedinurl}
                          value={watchAll.LinkedinURL}
                          onChange={handleChange('linkedinurl')}
                        />
                      </div>
                    </div>


                    <div className="col-lg-6" style={{display:'none'}}>
                      <div className="form-group">
                        <FormInput
                          id="TwitterURL"
                          type="text"
                          label="Twitter URL"
                          name="TwitterURL"
                          register={register}
                          error={errors.TwitterURL}
                          defaultValue={state.twitterurl}
                          value={watchAll.TwitterURL}
                          onChange={handleChange('twitterurl')}
                        />
                      </div>
                    </div>
                  </Row>


                  <Col lg="6">
                    <h3 style={{ textAlign: 'left' }}> Visibility</h3>
                    <div className="form-group">
                      <FormRadio
                        name="Visibility"
                        className="text-sm mb-0"

                        options={radioGroupOptions3}
                        control={control}
                        row

                        value={state.isShared}
                        onChange={handleChange('isShared')}
                      />
                    </div>
                  </Col>
                  {/* 
                  {typeId == 2 &&
                    <Col lg="6">
                      <h3 style={{ textAlign: 'left' }}> I have a marketing role</h3>
                      <div className="form-group">
                        <FormRadio
                          name="role"
                          className="text-sm mb-0"
                          register={register}
                          options={radioGroupOptions2}
                          control={control}
                          row

                          value={state.isRole}
                          defaultValue={state.isRole}
                          onChange={handleChange('isRole')}
                        />
                      </div>
                    </Col>} */}

                </CardBody>
              </Card>
              {/* <Card>


                <CardHeader>
                  <h3 className="text-center">Product Interest  <span id='algorithm1'><i class="far fa-handshake didy"></i></span>
                    <UncontrolledTooltip className='didy2 handshak' delay={0} placement="bottom" target="algorithm1">This field is included in the matching algorithm</UncontrolledTooltip></h3>
                  <p className="text-center">{userData.channelTypeId == 2 ? 'Select the product categories that you are in charge of' : 'Select the products categories that you are interested in'} </p>
                </CardHeader>
                <CardBody>
                  <div className="col-lg-12 ">
                    <div className="form-group">
                      <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="productCat" name="productCat" ref={register} value={state.productValue.length ? state.productValue[0].childname : ''} />
                      <FormAutocompleteGrouping
                        id="ProductResponsibilities"
                        name="ProductResponsibilities"
                        label="Product Interest"
                        Options={state.productOptions}
                        optionName='productOptions'
                        optionValue='productValue'
                        placeholder="Product Interest"
                        errMessage='Please Select Product'
                        error={!errors.productCat || state.productValue.length ? false : true}
                        defaultValue={state.productValue}
                        value={state.productValue}
                        input="product"
                        selectedItem={selectedItem}
                        handleChangeSelect={handleChangeSelect}
                        control={control}
                        register={register}
                      />
                    </div>
                  </div>

                </CardBody>
              </Card> */}

              {(typeId === 2) && (
                <Card>

                  <CardHeader>
                    <h3 className="text-center" >{typeId == 3 ? 'Countries of operation' : 'Trade Locations'}  <span id='algorithm'><i class="far fa-handshake didy"></i></span>
                      <UncontrolledTooltip className='didy2 handshak' delay={0} placement="bottom" target="algorithm">This field is included in the matching algorithm</UncontrolledTooltip></h3>                  <p className="text-center" >{typeId == 3 ? 'Update the list of countries you are in charge of' : 'Update the list of countries you are in charge of or you are targetting.'}</p>
                  </CardHeader>
                  <CardBody>
                    {typeId == 2 &&
                      <div>
                        <p> *Note: If you don't have a sales role and you are not in change of any specific country please select the option "I have a marketing role" below</p>
                        <h3 style={{ textAlign: 'left' }}> I have a marketing role</h3>
                        <div className="form-group">
                          <FormCheckBox
                            name="role"
                            id="role"
                            type="checkbox"
                            label="Yes"
                            value={1}
                            register={register}
                            control={control}
                            checked={state.isRole == 1}
                            onClick={handleChange('isRole')}
                          />
                          <FormCheckBox
                            name="role"
                            id="role1"
                            type="checkbox"
                            label="NO"
                            value={0}
                            control={control}
                            register={register}
                            checked={state.isRole == 0}
                            onClick={handleChange('isRole')}
                          />
                        </div>
                      </div>}
                    {/* {(typeId === 3 || typeId === 4 || typeId === 5) && (
                    <div className="col-lg-12 ">
                      <div className="form-group">
                        <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="selling" name="selling" ref={register} value={state.sellingCountries.length ? state.sellingCountries[0].childname : ''} />
                        <FormAutocompleteGrouping
                          input="sellingCountries"
                          id="SellingCountries"
                          label='Selling Locations'
                          name="SellingCountries"
                          Options={state.sellingCountriesOption}
                          placeholder="Search"
                          errMessage='Please choose Selling Locations'
                          error={!errors.selling || state.sellingCountries.length ? false : true}
                          optionName='sellingCountriesOption'
                          optionValue='sellingCountries'
                          defaultValue={state.sellingCountries}
                          value={state.sellingCountries}
                          // loading={true}
                          isGroup='0'
                          selectedItem={selectedItem}
                          handleChangeSelect={handleChangeSelect}
                          control={control}
                          register={register}
                        />
                      </div>
                    </div>
                  )} */}

                    {state.isRole == '0' && (
                      <div className="col-lg-12 ">
                        <div className="form-group">
                          <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="target" name="target" ref={register} value={state.targetCountries.length ? state.targetCountries[0].childname : ''} />
                          <FormAutocompleteGrouping
                            input="targetCountries"
                            id="TargetCountries"
                            label="Target Locations"
                            name="TargetCountries"
                            Options={state.targetCountriesOption}
                            placeholder="Search"
                            errMessage={'Please choose Target Locations'}
                            error={!errors.target || state.targetCountries.length ? false : true}
                            optionName='targetCountriesOption'
                            optionValue='targetCountries'
                            defaultValue={state.targetCountries}
                            value={state.targetCountries}
                            //  loading={true}
                            isGroup='0'
                            selectedItem={selectedItem}
                            handleChangeSelect={handleChangeSelect}
                            control={control}
                            register={register}
                          />
                        </div>
                      </div>
                    )}
                    {/* {(contactId != userData?.contactId) && !!(contactId) && <div onClick={() => { setshowDrawer(true) }} style={{ cursor: "pointer" }}>User Preference</div>}
                    <PreferenceForm title="User Preference" visible={showDrawer} closeDrawer={closeDrawer} contactId={contactId} channelId={userData.channelId} userData={userData} /> */}


                  </CardBody>
                </Card>)}
              {/* )} */}

              {/* <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="isPreference" ref={register} name="isPreference" value={(contactId != userData?.contactId) ? '1' : ''} control={control} /> */}

              {(contactId != userData?.contactId) &&
                <Card>
                  <CardBody>
                    {/* <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="preference" ref={register} name="preference" value={(data?.preference?.checkedKeys?.length > 0 || data?.preference?.checkedKeys1?.length > 0) ? 'pass' : ''} control={control} /> */}
                    <Preference contactId={contactId} channelId={userData?.channelId} userData={userData} />

                    {/* {(!errors.preference || (data?.preference?.checkedKeys?.length > 0 || data?.preference?.checkedKeys1?.length > 0)) ?  <></> :
                <span style={{ color: '#f44336', fontSize: '0.75rem', float: 'right' }}>{errors.preference.message}</span> } */}
                  </CardBody>
                </Card>
              }


              <Card style={{display:'none'}}>
                <CardHeader>
                  <h3 className="text-center">Communication Preference <span id='algorithm5'><i class="far fa-handshake didy"></i></span>
                    <UncontrolledTooltip className='didy2 handshak' delay={0} placement="bottom" target="algorithm5">This field is included in the matching algorithm</UncontrolledTooltip></h3>
                </CardHeader>
                <CardBody>
                  <div className="row ">
                    <div className="col-sm-12 mt-4">
                      <div className="form-group ">
                        <FormRadio
                          name="CommunicationPreference1"
                          className="text-sm mb-0"
                          label="1.How frequently do you wish to receive suggestions? As they come!"
                          options={radioGroupOptions}
                          control={control}
                          register={register}
                          row
                          onChange={handleChange('suggestions')}
                          value={state.suggestions}
                          defaultValue={state.suggestions}
                          error={errors.CommunicationPreference1}
                        />
                      </div>
                    </div>
                  </div>


                  {typeId == 2 && <div className="row mt-2">
                    <div className="col-sm-12">
                      <div className="">
                        <FormRadio
                          name="CommunicationPreference2"
                          className=""
                          label="2.	How do you want to manage your network invitation coming from vendors and distributors? "
                          options={radioGroupOptions}
                          register={register}
                          control={control}
                          row
                          onChange={handleChange('networkInvitation')}
                          value={state.networkInvitation}
                          defaultValue={state.networkInvitation}
                          error={errors.CommunicationPreference2}
                        />
                      </div>
                    </div>
                  </div>}


                  
                </CardBody>
              </Card>
              <div className=" text-center m-4">
                    <Button color="primary" type="submit">
                      Save
                    </Button>
                    {/* <Button color="primary" type="button" href="#">
                   Cancel
                  </Button> */}
                  </div>
            </form>
          </Container>
        )}
      </div>
    </>
  );
};

const radioGroupOptions = [
  { value: "0", label: "As they come!" },
  { value: "1", label: "One a  week" },
  { value: "2", label: "Once per 2 weeks " },
  { value: "3", label: "Once per month" },
  { value: "4", label: "Never" }
];
const radioGroupOptions3 = [
  { value: "1", label: "Shared" },
  { value: "0", label: "Confidential" }
];
const radioGroupOptions2 = [
  { value: "1", label: "YES" },
  { value: "0", label: "NO" }
];
const radioGroupOptions1 = [
  { value: 3, label: "Primary" },
  { value: 2, label: "Admin " }
];

// function mapState(state) {
//     const { general_data } = state.adduser;
//     return { general_data };
// }

// const actionCreators = {
//   adduser: adduserActions.adduser
// };

// export default connect(mapState,actionCreators)();
export default AddUser