import React, { useState, useEffect } from 'react';
import { FormInput } from "_components/FormElements/FormInput";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPwdschema } from '../../pages/SignupPage/Validation';
import { commonService } from '_services/common.service';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Logo from 'views/pages/LoginPage/login-logo.js';
import { CardBody, Container } from "reactstrap";
import { Card } from 'antd';
import { Spinner } from "views/Hoc/Spinner";
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { history } from '../../../_helpers/history';
export const ResetPassword = (props) => {
  let [formValue, setFormValue] = useState({ password: null, rePassword: null });
  let [isLoading, setLoading] = useState(true);
  let [exp_err, setExp_error] = useState(false);
  // let [matchErr, setMatchErr] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const { contactData, saved } = props;

  const handleClickShowPassword1 = () => { setShowPassword1(!showPassword1); }
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(resetPwdschema),
  });
  useEffect(() => {
    setLoading(false);
  }, [])

  // const checkToken = async () => {
  //   setLoading(true);
  //   console.log("forgotOnSubmit:", props.match.params.token);
  //   let res = await commonService.getServices('account/resetPassword/' + props.match.params.token).catch(err => setExp_error(true));
  //   setLoading(false);
  //   if (res) {
  //     // setEmail(null);
  //   }
  // }
  const onSubmit = async (data) => {
    console.log("forgotOnSubmit:", data);

    let postData = {
      channelId: contactData.channelId,
      contactId: contactData.contactId,
      userId: contactData.userId,
      password: data.password
    }

    let res = await commonService.postService(postData, 'account/resetPasswordBO/').catch(err => ToastsStore.error('Something went wrong!...'));
    if (res) {
      ToastsStore.success("Password Changed Successfully!");
      saved();
    }
  }
  return (
    <div className="main-content">
      {isLoading && <Spinner />}
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <Container fluid className="mt-8 justify-content-center wek-cent">
        <Card>
          <CardBody>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <div className="text-center mt-4">

                <div className="col-lg-12">
                  <div className="form-group">
                    <FormInput
                      id="password"
                      type="password"
                      label="Enter New Password"
                      name="password"
                      error={errors.password}
                      value={formValue.password}
                      register={register}
                      InputProps={{}}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <FormInput
                      id="rePassword"
                      type={showPassword1 ? "text" : "password"}
                      label="Confirm Password"
                      name="rePassword"
                      error={errors.rePassword}
                      value={formValue.rePassword}
                      register={register}
                      defaultValue=''
                      hintText="Password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword1}
                            >
                              {showPassword1 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </div>
                <div className="text-center mt-4">
                  <button type="submit" className="btn btn-warning">Reset Password</button>
                </div>
              </div>
            </form>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
export default ResetPassword;