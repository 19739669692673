import React ,{useEffect, useState} from 'react';
import { Button, Modal } from "reactstrap";
import { Card, CardBody, ListGroupItem, ListGroup, Row, Col } from "reactstrap";
import CountryFlag from "views/Hoc/countryFlag/countryFlag";
import { commonService } from '_services/common.service';
import { ModalSpinner } from 'views/Hoc/Spinner';
import axios from 'axios';
export  const Fileuploaddialog=(props)=> {
  const {  isOpen, handleClose, channelId, openConnectPopUp, contactList } = props;
  let logo = 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg';
  // const openPopUp=async (contact, status, title)=>{
  //   UpdateChannelData.connectionStatusTypeId=status;
  //   UpdateChannelData.channelJCTId=parseInt(contact.channelJCTId);
  //   UpdateChannelData.contactid=contact.record.contactId;
  //   openConnectPopUp(UpdateChannelData);
  // }
  return (
    <div>
      <Modal
          className="modal-dialog-centered"
          isOpen={isOpen}
         size="md"
        >
          <div className="modal-header">
          <h3>Select Your File</h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={ handleClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body  d-flex justify-content-center pt-1 pb-1">
        <Card className="w-100 mb-0">
        <CardBody>
        <form action="/file-upload" class="dropzone primary" id="dropzone">
        <div class="fallback primary">
        <input name="file" type="file" multiple />
        </div>
        </form>   
        </CardBody>
        </Card>


          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              color="primary"
              type="button"
            >
              Upload
            </Button>
           
          </div>
        </Modal>
    </div>
  );
}
export default Fileuploaddialog;