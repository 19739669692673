import React, { useState,useEffect } from "react";
import {useBeforeFirstRender} from '_hooks/index'
import Awards from "./Dialog/Awards";
import axios from 'axios';
var _ = require('lodash');
const BrandCard = props => {
  let brandList=[];
if(props?.brandList)
brandList=_.orderBy(props.brandList, [brand => brand.name.toLowerCase()]);

const [delBrand, setDelete] = useState('0'); 
const [award, setaward] = useState(false); 
const [data, setData] = useState({});

//  useEffect(() => {
//    if(delBrand!=='0')
//    {
//      props.deleteBrand(delBrand);
//     console.log("deleteValue:",brandList);
//     //setBrand(filteredList);    
//    }  
//   }, [delBrand])

  const deleteBrand = item => {
    console.log('id', item)
    // setDelete(item.id);    
    if(item.id != 0)
   {
     props.deleteBrand(item.id);  
   }
    
  }
  const addAward=()=>{
    setaward(!award);
  }

  const deleteAward = item => {
    props.deleteAwards(item);
  }

  const editAward = (item) => {
    setData(item);
    setaward(!award);
  }

  const closeAward = () => {
    setaward(!award);
    setData({});
  }

  return (

<div className="brand mb-3">
                <div className="scroll">

              {!props.award&&brandList.map((item) => (
                    <div className="brandCard">
                        <button onClick={e => deleteBrand(item)} className="xbtn" type="button"><svg style={{borderRadius: "50%",background: "#f8f8f8"}} className="BrandCloseSvg" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path></svg></button>
                        <div  className="imgContiner">
                        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}   
                        className="Brandimg" 
                        src={(item.isNew === '1' && item.documentpath) ? process.env.REACT_APP_CDN_URL_TEMP+item.documentpath : item.documentpath? item.documentpath:  process.env.REACT_APP_CDN_URL+'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />
                     </div>
                    <div className="BrandName">{item.name} </div>
                    </div>
                 ))}                     
                      {props.award&&props.awardList.map((item) => (
                        <div className="brandCard">
                        <button onClick={e => deleteAward(item)} className="xbtn" type="button"><svg style={{borderRadius: "50%",background: "#f8f8f8"}} className="BrandCloseSvg" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path></svg></button>
                        <div  className="imgContiner">
                      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}   className="Brandimg" onClick={e => editAward(item)} src={item.awardImg ? item.awardImg.includes('document/media') ? process.env.REACT_APP_CDN_URL_TEMP+item.awardImg : process.env.REACT_APP_CDN_URL+item.awardImg : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />
                     </div>
                    {/* <div className="BrandName">{item.awardName} </div> */}
                    </div>
                      ))}
                    {props.award && 
                      <div onClick={addAward} className="brandCard">
                        <div  id='award'  className="imgContiner">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                     </div>
                    <div className="BrandName">&nbsp;</div>
                    </div>
                    }
                    {award &&
                          <Awards open={true} closeAward={closeAward} data={data?data:{}} addAwards={props.addAwards}/>
                    }
                </div>
            </div>            
  );
}
const ProfileCard = props => {  

  
  return (


                    <div className="brandCard">
                      
                        <div  className="imgContiner">
                  <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}   className="Brandimg" src={props.img}/>
                     </div>
                    </div>
                   
                   
  );
}

export {
  BrandCard,
  ProfileCard
} 
