import React, { useEffect, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { FormTextArea, FormInput, FormAutocomplete, SingleAutocompleteGrouping, FormRadio, FormCheckBox, FormAutocompleteGrouping } from "_components/FormElements/FormInput";
import { useForm } from "react-hook-form";
import {
  FormControl,
  InputAdornment,
} from "@material-ui/core";
import {
  Button, Modal
} from "reactstrap";
import { LaptopWindows } from '@material-ui/icons';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { height } from '@mui/system';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Checkbox from '@mui/material/Checkbox';
import { PaymentSplit } from 'views/Hoc/Dialog/PaymentSplit';
import ChannelType from '../../pages/Channel-Listing/ChannelType';
import { Card, CardBody } from "reactstrap";

export const Dialog = (props) => {

  const { open, handleClose, handleSave, value } = props;
  const [packageData, setPackage] = useState([]);
  const [channelType, setChannelType] = useState(null);

  const [regType, setRegType] = useState(null);

  const [showPackage, setShowPackage] = useState(false);

  const [paymentSplitFlag, setPaymentSplitFlag] = useState(false);

  const [showCord, setShowCord] = useState(false);


  let [packageOptionsDrop, setPackageOptions] = useState([]);
  let [packageOptionsValue, setPackageValue] = useState([]);


  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { register, handleSubmit, control, errors, watch } = useForm({
    mode: "onBlur",
    // resolver: yupResolver(retailProfileSchema)
  });



  const handleChangeSelect = async (input, data) => {
    //setPackage(data);



    if (value.popUpId == 3 && input === "regType") {

      if (data.name === "Seller") {

        packageOptionsDrop = value.value.eventInfo.find(x => x.id == value.eventToRegister).hasPackage.filter(x => x.isSeller == '1' && x.channelType == value.value?.channel?.channelTypeId && x.packageUser == value.value?.packageUser);
        // console.log("regtype:", data, packageOptionsDrop);
        setPackageOptions(packageOptionsDrop);
      } else if (data.name === "Buyer") {
        packageOptionsDrop = value.value.eventInfo.find(x => x.id == value.eventToRegister).hasPackage.filter(x => x.isBuyer == '1' && x.channelType == value.value?.channel?.channelTypeId && x.packageUser == value.value?.packageUser);
        // console.log("regtype:", data, packageOptionsDrop);
        setPackageOptions(packageOptionsDrop);
      }
      packageOptionsValue = [];
      setPackageValue(packageOptionsValue);

      setRegType(data);
    }
    else if (input === 'eventList') {

      console.log("regevent:", regType, value?.value.eventInfo.find(x => x.id == data.id).hasPackage);

      if (value.popUpId == 3 && value.value.eventInfo.length > 1) {

        Object.assign(value, { value: { ...value.value, NO_SEATS_FLAG: true } })

        let primaryCount = value?.value.channel?.contacts.filter(x => x.eventParticipants.filter(e => e.eventId == data.id).length > 0).length;


        let packList = value?.value.eventInfo.find(x => x.id == data.id).hasPackage?.filter(p => p.channelType == value?.value.channel?.channelTypeId)



        let seatFlag = true;
        for (let user = 1; user <= 2; user++) {
          for (let pack = 1; pack <= 2; pack++) {
            let currentPack = packList.filter(x => x.packageUser == user && x.packageType == pack);



            if (currentPack.length) {



              let registeredCount = value?.value.channel?.contacts.filter(x => x.eventParticipants.filter(e => e.eventId == data.id && e.packageId == currentPack[0].id).length > 0).length;

              // console.log("currentpack:",primaryCount,registeredCount,currentPack[0])

              if (registeredCount == 0 && (primaryCount == 0 || currentPack[0].packageUser == 2) && seatFlag) {

                // console.log("regs:",currentPack,registeredCount,seatFlag)
                seatFlag = false;
                // setSeatsFlag(seatFlag);
                //setPackageId(currentPack[0].id);
                Object.assign(value, { value: { ...value.value, NO_SEATS_FLAG: false, packageId: currentPack[0].id, packageUser: currentPack[0].packageUser } })
                //publicPack = [...currentPack[0]];
                //move to subscription page with package selection
              }
              else if (registeredCount > 0 && registeredCount < currentPack[0].no_of_access_badge && seatFlag) {

                // console.log("currentpacks:",registeredCount,currentPack[0])
                Object.assign(value, { value: { ...value.value, NO_SEATS_FLAG: false, packageId: currentPack[0].id, packageUser: currentPack[0].packageUser } })
                seatFlag = false;
                // setSeatsFlag(false);
                // setPackageId(currentPack[0].id);
                //publicPack = [...currentPack[0]];
                //register user as a delegate 
              }
              // if(currentPack[0].accessBadge==currentPack[0].registeredCount){
              //   completedPackage.push(currentPack[0].packageId);
              // }
            }

            //move to next package of first user and repeat the same process
          }
          //move to second user and repeat the same process    
        }

        if (value.value.NO_SEATS_FLAG) {
          Object.assign(value, { message: "Maximum limit of participants reached for all packages." });
        }

      }






      if (value?.value.registeredEventId.includes(data.id) && !value?.value.multiEventApproval) {
        // console.log("pvalue:",data)
        ToastsStore.success('Already registered for this Event');
        // data.type == 1 ? setShowPackage(true) : setShowPackage(false)
      }
      else {
        Object.assign(value, { eventType: data.type, hasPackage: data.hasPackage, eventToRegister: data.id });
        if (data.type == 1 && data.hasPackage && !value?.value.multiEventApproval) {
          // console.log("packs11:", value, data.hasPackage)
          if (value.popUpId == 3 && value.value?.packageId) {

            setPackageOptions(data.hasPackage.filter(x => x.eventId == data.id && x.channelType == value.value?.channel?.channelTypeId && x.packageUser == value.value?.packageUser));
            setPackageValue(data.hasPackage.filter(x => x.id == value.value.packageId));

          } else {
            setPackageOptions(data.hasPackage.filter(x => x.eventId == data.id && x.channelType == value.value?.channel?.channelTypeId && x.packageUser == value.value?.packageUser));
          }
          setShowPackage(true);
          if (value.popUpId != 3)
            getChannelPack();
          //Object.assign(value, { packageId: data.id,eventCord: false  });

        } else if (data.type == 1 && data.hasPackage && value?.value.multiEventApproval) {


          // let regId=value?.value?.contact[0].find(x => x.status==null || x.status==0).contactId;
          const regUser = value?.value?.contactList.filter(x => x.eventParticipants.filter(p => p.eventId == value.eventToRegister && (p.status == null || p.status == 0)).length > 0);
          if (regUser.length) {
            // setUserName(regUser[0].firstName+' '+regUser[0].lastName);
            Object.assign(value, { message: value.value.id == 15 ? `You want to Approve user "${regUser[0].firstName + ' ' + regUser[0].lastName}" for Selected Event` : `You want to Dismiss user "${regUser[0].firstName + ' ' + regUser[0].lastName}" for Selected Event` })
          }

          let packId = value?.value?.eventChannel.find(ch => ch.eventId == value.eventToRegister)?.packageId;
          const packName = value.hasPackage.filter(pack => pack.id == packId);
          if (value.value.eventInfo.find(x => x.id == value.value.eventId).hasPackage) {
            const packList = value.value.eventInfo.find(x => x.id == value.eventToRegister).hasPackage.filter(x => x.packageUser == value.packageUser && x.channelType == value.channelType && x.eventId == value.eventToRegister)
            setPackageOptions(packList);
            // if(packList.length==1){
            // Object.assign(value, { packageId: packList[0].id });         
            // setPackageValue(packList[0]);
            // }            
          }
          Object.assign(value, { packageId: packId });
          setPackageValue(packName[0]);
          setShowPackage(true);
          if (value.value.id == 15)
            setShowCord(true);
          console.log("packs1:", value)
        }
        else
          setShowPackage(false)
        if (value.value.id == 15)
          setShowCord(true);
      }

    }

    else if (input === 'package') {

      Object.assign(value, { packageId: data ? data.id : value.value.packageId });
      const selectedPack = packageOptionsDrop.find(p => p.id == data.id);
      if (selectedPack?.price == 0) {
        Object.assign(value, { paymentSplit: [] });
      }

      // console.log("packss:", packageOptionsDrop, selectedPack, data)

      setPackageValue(selectedPack);
    }


  };

  useEffect(() => {
    console.log("split:", packageOptionsValue?.price)
    if (packageOptionsValue?.price > 0 && value.value.fromEvent != 2)
      setPaymentSplitFlag(true);
    else
      setPaymentSplitFlag(false);
  }, [packageOptionsValue])



  useEffect(() => {
    console.log("packs:", value)


    if (value.isEventSignup && value.popUpId != 3) {
      let cType = value.value.contact.eventParticipants?.length ? value.value.contact.eventParticipants[0].channelType : value.channelType;
      if (value.value.eventInfo.find(x => x.id == value.value.eventId).hasPackage) {
        const packList = value.value.eventInfo.find(x => x.id == value.value.eventId).hasPackage.filter(x => x.packageUser == value.packageUser && x.channelType == cType && x.eventId == value.value.eventId)
        setPackageOptions(packList);
        if (packList.length == 1) {
          Object.assign(value, { packageId: packList[0].id });
          setPackageValue(packList[0]);
        } else {
          const selectedPack = packList.filter(x => x.id == value.value.packageId);
          setPackageValue(selectedPack[0]);
        }

      }
    }
    if (value.value?.eventInfo?.length == 1 && value.value?.isEventRegister && value.popUpId != 3) {
      // if (value.value?.channel?.channelTypeId == 2) {
      //   packageOptionsDrop = packageDropdownOptions2;
      // }
      // if (value.value?.channel?.channelTypeId != 2) {
      //   packageOptionsDrop = value.value?.isMainDelegate ? packageDropdownOptions : packageDropdownOptions1;
      // }
      if (value.value.eventInfo.find(x => x.id == value.value.eventId).hasPackage)
        setPackageOptions(value.value.eventInfo['hasPackage']);

      if (value.value.eventInfo.find(x => x.id == value.value.eventId).hasPackage.length == 1) {
        Object.assign(value, { packageId: value.value.eventInfo['hasPackage'][0].id });
        setPackageValue(value.value.eventInfo['hasPackage'][0]);
      }
    }
    if (value.value?.eventInfo?.length == 1 && value.popUpId == 3) {
      Object.assign(value, { eventType: value.value?.eventInfo[0].type, hasPackage: value.value?.eventInfo[0].hasPackage, eventToRegister: value.value?.eventInfo[0].id });

      setPackageOptions(value.value?.eventInfo[0].hasPackage.filter(x => x.channelType == value.value?.channel?.channelTypeId && x.packageUser == value.value?.packageUser));
      setPackageValue(value.value?.eventInfo[0].hasPackage.filter(x => x.id == value.value.packageId));


      //getChannelPack();
    }




  }, [open]);

  const paymentSplit = (splitErr, paymentSplit, addons) => {
    console.log("paymentSplit:", splitErr, paymentSplit, addons);

    // const empty = paymentSplit.filter(p => p.percent == null || p.selectedPeriod == null);
    if (splitErr) {
      setPaymentSplitFlag(true);
    } else {
      setPaymentSplitFlag(false);
      Object.assign(value, { paymentSplit: paymentSplit, addons: addons });
    }

  }



  // useEffect(() => {
  //  console.log("chapack");


  // },[showPackage])

  const getChannelPack = () => {
    // console.log("eid:",value,value.eventToRegister)

    axios.get('/channelAdmin/getChannelPackage/' + value.value?.channel['channelId'] + '/' + value.eventToRegister).then(
      res => {

        let publicPack = [];



        //before grc 23
        // if(value.value?.channel?.eventChannel.length && value.value?.channel?.channelTypeId==2)
        // publicPack=value.hasPackage.filter(x => x.eventId==value.eventToRegister && x.id==value.value?.channel?.eventChannel[0].packageId&& x.channelType==value.value?.channel?.channelTypeId && x.isPublic=='1');        
        // else
        if (value.popUpId == 3 && value.value.packageId) {
          publicPack = value.hasPackage.filter(x => x.eventId == value.eventToRegister && x.channelType == value.value?.channel?.channelTypeId && x.packageUser == value.value?.packageUser);// && x.id==value.value.packageId);
        } else {
          publicPack = value.hasPackage.filter(x => x.eventId == value.eventToRegister && x.channelType == value.value?.channel?.channelTypeId && x.isPublic == '1' && x.packageUser == value.value?.packageUser);
        }

        const channelPack = value.hasPackage.filter(x => res.data.map(p => +p.packageId).includes(+x.id));


        // const pack=[...publicPack,...channelPack.filter(x => x.isPublic=='0' && x.packageUser==value.value?.packageUser)];
        let pack;
        if (channelPack.length > 0)
          pack = [...channelPack.filter(x => x.packageUser == value.value?.packageUser)];
        else {

          pack = [...publicPack, ...channelPack];
        }

        // console.log('ppack:', publicPack,channelPack,value);

        if (pack.length == 1) {
          Object.assign(value, { packageId: pack[0].id });
          //console.log("packageOptionsValue:",pack);
          setPackageValue(pack[0]);
        } else {
          const defaultPack = pack.filter(x => x.eventId == value.eventToRegister && x.channelType == value.value?.channel?.channelTypeId && x.isPublic == '1' && x.id == value.value.packageId);
          Object.assign(value, { packageId: defaultPack[0].id });
          // console.log("packageOptionsValue:",pack);
          setPackageValue(defaultPack[0]);
        }

        setPackageOptions(pack);

        // setLoading(false);
      }
    ).catch(err => {
      console.log('error', err);
      // setLoading(false);
    })

  }

  console.log('ismaindelegatesss', packageOptionsDrop)

  return (
    <div>
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />

      <Modal className="modal-dialog-centered" isOpen={open} size="xg" style={{ minWidth: 900, width: '30%' }}>
        <div className="modal-header">
          <strong>{value.title}</strong>



          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body" style={{ padding: '0 1.5rem' }}>

          {/* {value.value?.multiEventApproval && userName && `You want to Approve user "${userName}" for Selected Event`} */}
          {value.profileType == 6 ? <div style={{ width: "100%", marginLeft: "25px", marginTop: "55px" }}>

            <div className="row mt-3">
              <h3>Please select profile</h3></div>
            <div className="row mt-3">
              <FormRadio
                name="startup"
                className="text-sm mb-0"

                options={radioGroupOptions2}
                control={control}
                coloum
                // defaultValue={state.areYouStartUp}
                // value={state.areYouStartUp}
                onChange={(e) => { Object.assign(value, { selectedProfile: e.target.value }) }}
              /></div>
          </div> : <>
            {(!value.value?.multiEventApproval && value.value?.brandApprove === true || value.value?.brandApprove === false) ? value.message + "created By '" + value.value.firstName + "' for '" + value.value.companyName + "'" : value.message}
            {!value.value?.multiEventApproval && value?.second_message && <p className="mt-2">{value?.second_message}</p>}</>}
        </div>



        {(value.value?.eventInfo && value.popUpId != 3) && <>
          {(!value.value?.multiEventApproval && !value.value?.eventListFlag || value.value?.eventInfo?.length === 1) && !value.isRemoveFromEvent ? (
            <span>{value.value?.eventInfo.map(x => {
              if (x.id == value.value?.eventId) {
                return <>
                  <p style={{ paddingLeft: "25px" }}> Event Name: {x.name}</p>
                  {(value.value.id != 33 && value.value.fromEvent != 2 && value.value.id != 0 && value.value.id != 16 && packageOptionsDrop.length > 0) && <>

                    <div className="col-8">
                      <FormControl variant="outlined" style={{ width: "100%", marginLeft: "8px" }}>
                        <FormAutocomplete
                          id="eventPack"
                          name="eventPack"
                          label="Event Package"
                          options={packageOptionsDrop}
                          placeholder="Select Package"
                          disableCloseOnSelect
                          blurOnSelect
                          limitTags={1}
                          control={control}
                          input="package"
                          getOptionSelected={(options, value) => options.name === value.name}
                          value={packageOptionsValue}
                          handleChangeSelect={handleChangeSelect}
                        // errMessage={"Please Select Country"}
                        // error={errors.country}
                        // defaultValue={state.package}
                        />
                      </FormControl>
                    </div> <br /> {packageOptionsValue?.price > 0 && <PaymentSplit regType={regType ? regType.name : null} paymentSplit={paymentSplit} packageList={value.hasPackage} eventId={value.value?.eventId} pack={packageOptionsValue} channel={value.value?.channel} popupId={value.popUpId} contact={value.value?.contact} eventChannel={value.value?.eventChannel} contactList={value.value?.contactList} />} </>}

                </>
              }
            })}</span>
          ) :
            (value.value?.eventInfo && value.value?.eventInfo?.length > 1 && (value.value?.eventListFlag || value.value?.multiEventApproval) && !value.isRemoveFromEvent) &&
            <>
              <div className="row mt-3">
                <div className='col-4'>
                  <h4 style={{
                    fontSize: " 16px",
                    paddingTop: " 14px",
                    color: "#66728e"
                  }}>Select Event:</h4>
                </div>


                <div className="col-8">
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <FormAutocomplete
                      id="eventList"
                      name="eventList"
                      label="Event List"
                      options={value.value?.eventInfo}
                      placeholder="Select Event"
                      disableCloseOnSelect
                      disableClearable={true}
                      blurOnSelect
                      limitTags={1}
                      control={control}
                      input="eventList"
                      getOptionSelected={(options, value) => options.name === value.name}
                      //value={state.package}
                      handleChangeSelect={handleChangeSelect}
                    // errMessage={"Please Select Country"}
                    // error={errors.country}
                    // defaultValue={state.package}
                    />
                  </FormControl>
                </div>
              </div>

              {(value.popUpId == 3 && value.eventToRegister && value.value?.channel.eventChannel?.filter(ec => ec.eventId == value.eventToRegister && ec.approvalStatus == 1)?.length == 0 && value.value?.channel?.channelTypeId != 2) &&
                <div style={{ marginTop: '20px' }}>
                  <div className="row mt-3">
                    <div className='col-4'>
                      <h4 style={{
                        fontSize: " 16px",
                        paddingTop: " 14px",
                        color: "#66728e"
                      }}>Register As:</h4>
                    </div>


                    <div className="col-8">
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <FormAutocomplete
                          id="regType"
                          name="regType"
                          label="Register As"
                          options={[{ id: '1', name: "Seller" }, { id: '2', name: "Buyer" }]}
                          placeholder="Select Registration Type"
                          disableCloseOnSelect
                          disableClearable={true}
                          blurOnSelect
                          limitTags={1}
                          control={control}
                          input="regType"
                          getOptionSelected={(options, value) => options.name === value.name}
                          value={regType}
                          handleChangeSelect={handleChangeSelect}
                        // errMessage={"Please Select Country"}
                        // error={errors.country}
                        // defaultValue={state.package}
                        />
                      </FormControl>
                    </div>
                  </div></div>}


              {(value.eventType == 1 && !value.value.NO_SEATS_FLAG && showPackage) &&
                <div style={{ marginTop: '20px' }}>
                  <div className="row mt-3">
                    <div className='col-4'>
                      <h4 style={{
                        fontSize: " 16px",
                        paddingTop: " 14px",
                        color: "#66728e"
                      }}>Event Packages:</h4>
                    </div>

                    <div className="col-8">
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <FormAutocomplete
                          id="eventPack"
                          name="eventPack"
                          label="Event Package"
                          options={packageOptionsDrop}
                          placeholder="Select Package"
                          disableCloseOnSelect
                          blurOnSelect
                          limitTags={1}
                          control={control}
                          input="package"
                          getOptionSelected={(options, value) => options.name === value.name}
                          value={packageOptionsValue}
                          handleChangeSelect={handleChangeSelect}
                        // errMessage={"Please Select Country"}
                        // error={errors.country}
                        // defaultValue={state.package}
                        />
                      </FormControl>
                    </div>
                  </div>
                  <br /> {packageOptionsValue?.price > 0 && <PaymentSplit regType={regType ? regType.name : null} paymentSplit={paymentSplit} eventId={value.value?.eventId ? value.value.eventId : value.eventToRegister} pack={packageOptionsValue} packageList={value.hasPackage} channel={value.value?.channel} popupId={value.popUpId} contact={value.value?.contact} eventChannel={value.value?.eventChannel} contactList={value.value?.contactList} />}

                </div>
              }

              {(value.popUpId == 3 && value.eventToRegister && value.value?.channel.eventChannel?.filter(ec => ec.eventId == value.eventToRegister && ec.approvalStatus == 1)?.length == 0 && value.value?.channel?.channelTypeId == 2) &&
                <div className="form-group" style={{ marginLeft: '2%' }}>
                  <Checkbox
                    // checked={state.hasParticipant}
                    // color="warning"
                    onChange={(e) => { Object.assign(value, { eventCord: e.target.checked ? true : false }) }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  /> Only Event Coordinator
                </div>



              }


              {(value.popUpId != 3 && value.eventToRegister && value.eventType == 1 && showCord && value.value?.eventChannel.filter(ec => ec.eventId == value.eventToRegister && ec.approvalStatus == 1)?.length == 0 && value.channelType == 2) &&
                <div className="form-group" style={{ marginLeft: '34%' }}>
                  <Checkbox
                    // checked={state.hasParticipant}
                    // color="warning"
                    onChange={(e) => { Object.assign(value, { eventCord: e.target.checked ? true : false }) }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  /> Only Event Coordinator
                </div>}
            </>}
        </>
        }

        {value.popUpId == 3 && value.eventType == 1 && !value.value.NO_SEATS_FLAG && value.value?.contact.eventParticipants[0]?.status != '1' && value.value?.eventInfo.length == 1 && value.value?.eventInfo[0].isPackage == 1 &&
          <span>{value.value?.eventInfo.map(x => {
            if (value.popUpId == 3) {
              return <>
                <div className="col-8">
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <FormAutocomplete
                      id="eventPack"
                      name="eventPack"
                      label="Event Package"
                      options={packageOptionsDrop}
                      placeholder="Select Package"
                      disableCloseOnSelect
                      blurOnSelect
                      limitTags={1}
                      control={control}
                      input="package"
                      getOptionSelected={(options, value) => options.name === value.name}
                      value={packageOptionsValue}
                      handleChangeSelect={handleChangeSelect}
                    // errMessage={"Please Select Country"}
                    // error={errors.country}
                    // defaultValue={state.package}
                    />
                  </FormControl>
                </div>
                <br /> {packageOptionsValue?.price > 0 && <PaymentSplit regType={regType ? regType.name : null} paymentSplit={paymentSplit} packageList={value.hasPackage} eventId={value.value?.eventId ? value.value?.eventId : value.eventToRegister} popupId={value.popUpId} pack={packageOptionsValue} eventChannel={value.value?.eventChannel} channel={value.value?.channel} contact={value.value?.contact} contactList={value.value?.contactList} />}

                {(value.value?.channel.eventChannel?.length == 0 && value.value?.channel?.channelTypeId == 2) &&
                  <div className="form-group" style={{ marginLeft: '2%' }}>
                    <Checkbox
                      // checked={state.hasParticipant}
                      // color="warning"
                      onChange={(e) => { Object.assign(value, { eventCord: e.target.checked ? true : false }) }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    /> Only Event Coordinator
                  </div>}

              </>
            }
          })}</span>
        }

        <div className="modal-footer">
          <Button color="primary" disabled={(value?.value?.NO_SEATS_FLAG || paymentSplitFlag) ? true : false} type="button" onClick={() => handleSave(value)}>Ok</Button>
          <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
        </div>
      </Modal>
    </div >
  );
}




export const UpgradeModal = (props) => {
  // const {open, handleClose,  subscriptionTurn, value } = props;
  const { open, handleClose, value } = props;
  const gotoWallet = (event) => {
    let cid = props.value.cid;
    if (cid) {
      window.location.href = '../admin/ewallet/' + cid + '/' + props.value.JctId;
    }
  }

  const subscriptionTurn = (event) => {
    window.location.href = '../admin/Subscription/';
  }


  return (
    <div>
      <Modal className="modal-dialog-centered" isOpen={open}>
        <div className="modal-header">
          <strong>Upgrade</strong>
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            Your current plan is restricted. If you wish you unveil this company’s details and connect with them, you have 2 options:
          </p>
          <p>
            -	Upgrade your plan with a monthly plan at 99€ or an annual plan starting at 999€/year. <span style={{ cursor: 'pointer', color: 'blue' }} onClick={subscriptionTurn}>Click here </span>to view the premium plans
          </p>
          <p>
            -	Purchase a single record view with your e-Wallet. Simply recharge your e-Wallet and use it at your convenience. Once disclosed you can view the company’s profile and send a connection request. And if you do not get an answer our team will give you full support. Each record view + connection is priced at 20€.
          </p>
          <p>
            <span id={props.value.cid} style={{ cursor: 'pointer', color: 'blue' }} onClick={gotoWallet}>Click here</span> to use your e-Wallet.
          </p>
        </div>
        <div className="modal-footer">
          <Button color="primary" type="button" onClick={subscriptionTurn}>Upgrade</Button>
          <Button color="secondary" data-dismiss="modal" type="button" onClick={gotoWallet}>e-Wallet</Button>
        </div>
      </Modal>
    </div>
  );
}


export const ConfirmDialog = (props) => {

  const { open, handleClose, handleSave, value } = props;
  //console.log(props)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Modal className="modal-dialog-centered" isOpen={open}>
        <div className="modal-header">
          <strong>{value.title}</strong>
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {value.message}
          {value?.second_message && <p className="mt-2">{value?.second_message}</p>}
        </div>
        <div className="modal-footer">
          <Button color="primary" type="button" onClick={() => handleSave(value)}>Confirm</Button>
          <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Cancel</Button>
        </div>
      </Modal>
    </div>
  );
}

export const BrandMergePopup = (props) => {

  const [associateBrandId, setAssociateBrand] = useState(null);

  const { open, handleClose, handleSave, data } = props;
  //console.log(props)



  return (
    <div>
      <Modal isOpen={open} className="modal-dialog-centered">
        <div className="modal-header">
          <strong>{'Selected Brands are going to merge'}</strong>
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">

          <TextField
            InputLabelProps={{ shrink: true }}
            id="filled-password-input"
            label="Enter Brand Id to Associate"
            type="text"
            // value={state.function}
            onChange={(e) => setAssociateBrand(e.target.value)}
          />

        </div>
        <div className="modal-footer">
          <Button color="primary" type="button" onClick={() => handleSave(associateBrandId)}>Confirm</Button>
          <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Cancel</Button>
        </div>
      </Modal>
    </div>
  );
}


export default Dialog;
const packageDropdownOptions = [
  {
    id: 201,
    name: "Hosted Buyer"
  },
  // {
  //   id: 202,
  //   name: "Invited Buyer"
  // },
  // {
  //   id: 203,
  //   name: "Visiting Delegate"
  // }
]

const packageDropdownOptions2 = [
  {
    id: 4,
    name: "Package 2"
  },
  {
    id: 5,
    name: "Discovery Package"
  },
  {
    id: 6,
    name: "French Tech 4m²"
  },
  {
    id: 301,
    name: "Package 1"
  }
]

const packageDropdownOptions1 = [
  {
    id: 204,
    name: "Extra Delegate Shared Room"
  },
  {
    id: 205,
    name: "Extra Delegate Single Room"
  },
]

const radioGroupOptions2 = [
  {
    value: "2",
    label: "Vendor",
  },
  {
    value: "3",
    label: "Distributor",
  },
  {
    value: "4",
    label: "Retailer",
  },
  {
    value: "5",
    label: "Reseller",
  },

];