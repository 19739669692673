import React from 'react';
import ModalImageCropper from './ModalImageCropper';
import ModalImage from './ModalImage'
import axios from 'axios';

const FileUploaderBtn = (props) => {
  const hiddenFileInput = React.useRef(null);
  
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
  };
  return (
    <>
      <button className="btn btn-primary" onClick={handleClick}>
       {props.text}
      </button>
      <input type="file"
             ref={hiddenFileInput}
             onChange={handleChange}
             style={{display:'none'}} 
      /> 
    </>
  );
};


const ProfileUploader = (props) => {
  let { getDocumentUrl, img, handleChange, selectImg, image, handleCropClose} = props;  
  const hiddenFileInput = React.useRef(null);
  
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  
  
  return (
    <>
    <div onClick={handleClick} className="brandCard">
                      <div  className="imgContiner">
                <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}   className="Brandimg" src={img} />
                   </div>
                  </div>
      <input type="file"
             ref={hiddenFileInput}
             accept="image/*"
             onChange={handleChange}
             style={{display:'none'}} 
      /> 
      {image ? <ModalImageCropper isOpen={true}  src={image} handleChange={selectImg}  handleCropClose={handleCropClose}/> : <></>}
    </>
  );
};

const BannerUploader = (props) => {
  let { getDocumentUrl, img, handleChange, selectImg, image, handleCropClose} = props;  
  const hiddenFileInput = React.useRef(null);
  
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  
  
  return (
    <>
    <div onClick={handleClick} className="ban-preview">
                      <div  className="imgContiner1">
                <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}   className="Brandimg" src={img} />
                   </div>
                  </div>
      <input type="file"
             ref={hiddenFileInput}
             accept="image/*"
             onChange={handleChange}
             style={{display:'none'}} 
      /> 
      {image ? <ModalImageCropper isOpen={true}  src={image} handleChange={selectImg}  handleCropClose={handleCropClose} type="banner"/> : <></>}
    </>
  );
};

const MailBannerUploader = (props) => {
  let { img, handleChange, selectImg, image, handleCropClose} = props;  
  const hiddenFileInput = React.useRef(null);
  
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  
  return (
    <>
    <div onClick={handleClick} className="ban-preview">
                      <div  className="imgContiner1">
                <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}   className="Brandimg" src={img} />
                   </div>
                  </div>
      <input type="file"
             ref={hiddenFileInput}
             accept="image/*"
             onChange={handleChange}
             style={{display:'none'}} 
      /> 
      {image ? <ModalImage isOpen={true}  src={image} handleChange={selectImg}  handleCropClose={handleCropClose} type="banner"/> : <></>}
    </>
  );
};

export const ArrayUploader = (props) => {
  let { img, handleChange, selectImg, image, handleCropClose } = props;
  const hiddenFileArrayInput = React.useRef(null);
  const handleClick = () => {
    hiddenFileArrayInput.current.click();
  };

  return (
    <div id='10'>
      {/* onClick={handleClick} */}
    <div className="brandCard">
        <div  className="imgContiner">
            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}   className="Brandimg" src={img} alt="..."/>
        </div>
    </div>
      <input type="file" accept="image/*" id="hiddenFileArrayInput" ref={hiddenFileArrayInput} onChange={handleChange} style={{display:'none'}}/> 
      {image ? <ModalImageCropper isOpen={true} src={image} handleChange={selectImg} handleCropClose={handleCropClose} /> : <></>} 
    </div>
  );
};
export {FileUploaderBtn,
  ProfileUploader,
  BannerUploader,
  MailBannerUploader,
}