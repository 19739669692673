import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink,  Row, Col } from 'reactstrap';
import classnames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Button, Modal } from "reactstrap";
import { Radio } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import MultiUpload from '../MultiUpload';
// import { Aws_service } from '.../../_services/aws-s3.service';
import { commonService } from '_services/common.service';
import {Spinner} from 'views/Hoc/Spinner';
import {Rerocder} from 'views/Hoc/Dialog/recorder';
import VideoPreview from 'views/Hoc/Dialog/VideoPreview';
import {UncontrolledTooltip} from "reactstrap";
import {Aws_service} from '.../../_services/aws-s3.service';
import axios from 'axios';
// const BootstrapInput = withStyles((theme) => ({
//   root: {
//     'label + &': {
//       marginTop: theme.spacing(3),
//     },
//   },
//   input: {
//     borderRadius: 4,
//     position: 'relative',
//     backgroundColor: theme.palette.background.paper,
//     border: '1px solid #ced4da',
//     fontSize: 16,
//     padding: '10px 26px 10px 12px',
//     transition: theme.transitions.create(['border-color', 'box-shadow']),
//     // Use the system font instead of the default Roboto font.
  
//     '&:focus': {
//       borderRadius: 4,
//       borderColor: '#80bdff',
//       boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
//     },
//   },
// }))(InputBase);



export const MediaLibrary=(props)=> {
  const { introVideoId, id, text } = props;
  let [loading, setLoading]  = useState(false);
  let [selectData, setSelectData ] = useState(null);
  let [selectIndex, setSelectIndex] = React.useState(null);
  let [list, setList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  let [isVideo, setVideoOpen]  = useState(false);
  let [videoUrl, setVideoUrl]  = useState(null);
  
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  const handleClickOpen = () => {
    getMediaLibrary();
    setOpen(true);
  };

  const handleClose = () => {
    setActiveTab('1');
    setOpen(false);
  };
  const handleUpload=()=>{
    setOpen(false);
    introVideoId(selectData);
  }
  const videoModalClose = () => {
    setVideoOpen(false);
  };
  const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  //localStorage
  let userData = props.channelData; //JSON.parse(localStorage.getItem('CHuser'));
  
    const getMediaLibrary = async()=>{
      loading=true;
      setLoading(loading);
      const MediaLibrary = await commonService.getServiceWithParams({
          // channelId:userData?.channelId,
          // contactId:userData?.contactId

          ...(userData?.channelId && {channelId: +userData.channelId}),
          ...(userData?.contactId && {contactId: +userData.contactId}),
          ...(userData?.oldChannelId && {oldChannelId: +userData.oldChannelId}),

        }, '/channelAdmin/medialibraryByContact');
      console.log("MediaLibrary",MediaLibrary.data);
          list=MediaLibrary.data;
          setList(list);
          loading=false;
          setLoading(loading);
          handleChange(null,null);
    }
    const handleChange = (index, data) => {
      console.log('data', data); 
      selectIndex = index;
      setSelectIndex(selectIndex);
      selectData=data;
      setSelectData(selectData);
    };
    const previewVideo =(data)=>{
      videoUrl = data.document.documentUrl;
      setVideoUrl(videoUrl);
      setVideoOpen(true);
    }
    const deleteVideo =async(data)=>{
      loading=true;
      setLoading(loading);
      const uploadMediaLibrary = await commonService.postService({
        documentId:data.documentId,
        user:{
          channelId:userData?.channelId,
          contactId:userData?.contactId
        }}, '/channelAdmin/deleteMediaVideo');
        getMediaLibrary();
    }
    const selectVideo = async(data)=>{
      if(data.length>0){
        loading=true;
        setLoading(loading);
        const formData = new FormData();
        formData.append('file', data[0]);

      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/introVideo');
      
      // const resImagePath = await Aws_service.fileUpload(data[0], '/mediaupload/upload/introVideo', 'channelhub-dev-public');
      // const tempImagePath = await Aws_service.fileUpload(data[0], '/mediaupload/upload/introVideo', 'channelhub-dev-privatev2');
      if(resImagePath){
        const uploadMediaLibrary = await commonService.postService({
          Key:resImagePath.data.url,
          user:{
            // channelId:userData?.channelId,
            // contactId:userData?.contactId
            ...(userData?.contactId && {contactId: userData?.contactId}),
            ...(userData?.channelId && {channelId:userData?.channelId}),
            ...(userData?.oldChannelId && {oldChannelId: userData?.oldChannelId})
          }}, '/channelAdmin/introVideoUpload');
        list.unshift(uploadMediaLibrary.data);
        setList(list);
        handleChange(0, list[0]);
        toggle('3');
        loading=false;
        setLoading(loading);
        previewVideo(list[0]);
      }else{
        loading=false;
        setLoading(loading);
      }
      }
    }
  return (
    <div>
      {id==='1' && <Button variant="outlined" color="primary" onClick={handleClickOpen}>{text}</Button>}
      {id==='2' && <span onClick={handleClickOpen}>{text}</span>}
      {selectIndex !== null && <span style={{color:'orange',marginLeft:'2rem'}} onClick={()=>previewVideo(selectData)}>Preview Video</span>}
      <Modal scrollable={true}
          className="modal-dialog-centered"
          isOpen={open} size="lg">
          <div className="modal-header">
          <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            Upload File
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
          Record
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => { toggle('3'); }}
          >
          Media library
          </NavLink>
        </NavItem>
     
      </Nav>
     
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={ handleClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
          
          </div>
          <div className="modal-body p-0">
       
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          {loading? <Spinner/> : <Row>
            <Col sm="12">
            <MultiUpload acceptedFiles={['video/mp4','video/webm']} handleChange={selectVideo} size={props.size}/>
            </Col>
          </Row>}
        </TabPane>
        <TabPane tabId="2">
          {loading? <Spinner/> :<Row>
      {/* {activeTab === '2'&&  */}
      <Rerocder open = {activeTab} handleChange={selectVideo}/>
          {/* } */}
          </Row>}
        </TabPane>
        <TabPane tabId="3">
        {list.length<=0 && <div className="no-records ">
          {/* <span>No Records</span> */}
          <div className="no-img text-center">
          <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")}/></div>
        </div> }
        {loading? <Spinner/> :<>
          <div className="container">
        <div className="dropdown-divider m-0"></div>
        </div>
          <Row>
    {list && list.map((x, i)=>{
      return(
        <Col sm="2" lg="2" className="mr-3">
        <div className="brandCard cursor-point">
          <div onClick={()=>handleChange(i,x)}>
        <GreenCheckbox style={{width:'50px',position:'absolute',top:'-12px',right:'-15px'}} checked={selectIndex === i } name="checked" />
            <div>
            <div  className="imgContiner">
            <i className="fa fa-play" aria-hidden="true"></i>
        <video type="video/mp4" src={x.document.documentUrl}>
        </video>
           </div>
           </div>
           </div>
         
           <div className="row medd-view">
          <div className="col-6 text-right">
            <div id='eye' className="editeye text-center" onClick={()=>previewVideo(x)}>
            <i  class="fa fa-eye"></i> 
          </div> 
          <UncontrolledTooltip content='preview video' target='eye' placement='bottom'>
              preview
            </UncontrolledTooltip></div>
           <div className="col-6 text-left">
             <div  id='delete' className="deleteicon text-center"  onClick={()=>deleteVideo(x)}>
           <i class="fas fa-trash-alt"></i>
          </div>
          <UncontrolledTooltip content='Delete video' target='delete' placement='bottom'>
              delete
            </UncontrolledTooltip></div>
           </div>
          
          {/* <div className="dropdown-divider m-0"></div> */}
        {/* <div className="BrandName"> {x.name} </div> */}
        </div>
     </Col>
      )
    })}
          </Row></>}
        </TabPane>
      </TabContent>

          </div>
          <div className="modal-footer">
          {activeTab === '3' &&<>
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={()=>handleUpload(selectData)} disabled={selectIndex === null}>
              Upload
            </Button></>}
            
          </div>
        </Modal>
        <VideoPreview videoModalClose={videoModalClose} isOpen={isVideo} url={videoUrl}/>
    </div>
  );
}
export default MediaLibrary;