import React ,{useEffect} from 'react';

import {
  Button, Modal,
} from "reactstrap";
// import video from '../../../assets/sample.mp4'

export  const VideoPreview=(props)=> {
  const { url, videoModalClose, isOpen } = props;

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <Modal
          className="modal-dialog-centered"
          isOpen={isOpen}
         size="lg"
        >
          <div className="modal-header">
           
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={ videoModalClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body  d-flex justify-content-center">
          <video src={url} autoPlay style={{width:'100%',height:'30rem'}}></video>
        
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={videoModalClose}
            >
              Close
            </Button>
           
          </div>
        </Modal>
    </div>
  );
}
export default  VideoPreview;