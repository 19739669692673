import React, { useState, useEffect } from "react";
import { Card,CardBody,CardHeader, Container,FormGroup ,Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,Col,Row } from "reactstrap";
import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import {QuillEditor,FormInput,FormAutocomplete} from "_components/FormElements/FormInput";
import MediaLibrary from "views/Hoc/Dialog/MediaLibrary";
import {BrandCard,ProfileCard} from "views/Hoc/BrandCard";
import {PdfCard} from "views/Hoc/PdfCard";
import { history } from '../../../../_helpers';
import { dashboardActions } from '../../../../_actions';
import { companyProfileSchema } from '../validation';
import {InputLabel,Select,TextField,FormControl} from '@material-ui/core';
import AddBrand from "views/Hoc/Dialog/AddBrand";
import {useBeforeFirstRender} from '_hooks/index'
import axios from 'axios';
import ImageLibrary from 'views/Hoc/Dialog/ImageLibrary';
import {ModalSpinner, Spinner} from 'views/Hoc/Spinner';
import MultiUpload from "views/Hoc/MultiUpload";
import { commonService } from '_services/common.service';
import { ImageLibraryMultipleUploads } from 'views/Hoc/Dialog/ImageLibraryMultipleUploads';
import VideoPreview from 'views/Hoc/Dialog/VideoPreview';
import {BannerUploader} from 'views/Hoc/ProfileUpload';

var _ = require('lodash');


const Initialprofile = (props) => {

  const [prodImage, setProdImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, control, errors, watch,setValue  } = useForm({      
    mode:'onBlur',  
    resolver: yupResolver(companyProfileSchema),
  });

  const [initLoad, setInitLoad] = useState(true);
  const [initialVideo, setInitialVideo] = useState('');
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [bannerId, setBannerId] = useState('');
  const [state, setState] = useState({
    yearOfEstablishment: '',
    companyRegNumber : '',
    companyLogo : null,
    tweetPitch : '',
    introVideo : null,
    documentId : '',
    sourcing : '',    
    awards: [],
    companyName: '',
    legalAddress: '',
    country: '',
    countryList: [],
    zipCode: '',
    city: '',
    phoneNumber1: '',
    phoneNumber2: '',
    mobile: '',
    email: '',
    websiteUrl: '',
    linkedIn: '',
    insta: '',
    tradeUrls: '',
    cerificateName:'',
    isVat: '',
    regAddressId: '',
    billAddressId: '',
    b2bList: [{id: Math.random(), value: ''}],
    deleted_B2B: [],
    certificateId: '',
    vatId: '',
    social_linkedin_id: null,
    social_instagram_id: null,
    deletedAwards: [],
    deletedDocuments: []
  })

  //Multiupload
const [mediaState, setMediaState] = useState({
  productBrochures : [],
  bannerImages : []
});



const watchAll = watch(['companyName', 'CRN', 'VATN', 'websiteUrl', 'city', 'insta', 'linkedIn',  'zipCode', 'Yearestablishment' ]);//'tradeUrls',

    //localStorage
    var userData = props.channelData;//JSON.parse(localStorage.getItem('CHuser'));
    console.log('userData', userData);
    useEffect(() => {
      (async() =>{  
        const countryUrl = '/common/getSignup/'+userData.channelTypeId;
        const apiUrl = '/channelAdmin/company';
        console.log('companyProfile', apiUrl);        
        await axios.post('/channelAdmin/getInitialProfile', userData).then(res => {
          state.isVat = res.data.country[0].isVat;  
        }).catch(err=>setInitLoad(false));


        await axios.post(apiUrl, userData).then(res =>{  
         // console.log("profiledata:",res.data);
          state.companyLogo=res.data.channel.channelDetail.channelLogo.documentPath;
          state.companyName=res.data.channel.companyName;        
          state.documentId=res.data.channel.channelDetail.channelLogo?.documentId;
          state.introVideo=res.data.channel.channelDetail.welcomevideo?.documentId;
          if(res.data.channel.channelDetail.welcomevideo)
            setInitialVideo(process.env.REACT_APP_CDN_URL + res.data.channel.channelDetail.welcomevideo.documentUrl)
          //setValue('companyName', res.data.channel.companyName, { shouldValidate: true })        
          state.yearOfEstablishment=res.data.channel.channelDetail.estYear;
          state.legalAddress=res.data.channel.regAddress.address;
          state.regAddressId=res.data.channel.regAddressId;
          state.billAddressId=res.data.channel.billAddressId;
          //setValue('city', res.data.channel.regAddress.city)
          //setValue('zipCode', res.data.channel.regAddress.postalCode)       
          state.zipCode=res.data.channel.regAddress.postalCode;
          state.city=res.data.channel.regAddress.city;
          if(res.data.channel.channelCertificate){
            res.data.channel.channelCertificate.map(x => {
              if(x.cerificateTypeId=='1'){
                state.companyRegNumber=x.cerificateNumber;
                state.certificateName=x.cerificateName;   
                state.certificateId=x.channelCerificateId;       
              }
              if(x.cerificateTypeId=='2'){
                state.vatNumber=x.cerificateNumber;
                state.vatId=x.channelCerificateId;
              }
            })
          }

          if(res.data.channelPdf?.length){
            res.data.channelPdf.map(x => {
              mediaState.productBrochures.push(x);
            })
          }
          
          // state.vatNumber=res.data.channel.channelCertificate[1].cerificateNumber;
          
          state.phoneNumber1=+res.data.channel.channelDetail.phone1.substring(res.data.channel.channelDetail.phone1.indexOf('-')+1,res.data.channel.channelDetail.phone1.length);
          state.phoneNumber2=res.data.channel.channelDetail.phone2 ? res.data.channel.channelDetail.phone2 : '';
          state.mobile=res.data.channel.channelDetail.mobileNo;  
          if(res.data.channel.channelDetail.webSiteUrl)
            state.websiteUrl=res.data.channel.channelDetail.webSiteUrl.substring(0, 2)=='//'?res.data.channel.channelDetail.webSiteUrl.substring(2,res.data.channel.channelDetail.webSiteUrl.length):res.data.channel.channelDetail.webSiteUrl;
          state.email=res.data.channel.companyMail;
          state.awards=res.data.channel.channelAward?res.data.channel.channelAward:[];
          
          if(res.data.channel?.channelSocial?.length){
            res.data.channel.channelSocial.map(x => {
              if(x.socialSite.socialSiteId == 1)
                {
                  state.social_linkedin_id=x.channelSocialLinkId;
                  state.linkedIn=x.channelSocialLink;
                }
              if(x.socialSite.socialSiteId == 3)
              {
                state.social_instagram_id=x.channelSocialLinkId;
                state.insta=x.channelSocialLink;
              }
                
              if(x.socialSite.socialSiteId == 2)
                state.tradeUrls=x.channelSocialLink
            })
          }

          if(res.data.channel?.channelB2B?.length){
            state.b2bList = (res.data.channel.channelB2B.map(x => 
              ({channel_B2B_id: x.channelB2BId, value: x.channelB2B})
            ))
          }
          
          state.tweetPitch=res.data.channel.channelDetail.detailDesc;
          state.sourcing=res.data.channel.channelDetail.expectations;
          state.country = {name: res.data.channel.country, id: res.data.channel.countryId};
          // state.insta=res.data.channel.channelSocial[0].channelSocialLink;
          // state.linkedIn=res.data.channel.channelSocial[1].channelSocialLink;
          if(res.data.bannerImg?.documentPath){
            setImageSrc(res.data.bannerImg?.documentPath);
            setBannerId(res.data.bannerImg?.documentId);
            setMediaState({...mediaState, bannerImages: [{image: res.data.bannerImg?.documentPath, id: res.data.bannerImg?.documentId}]});
          }
          setState({...state});
          setInitLoad(false);
          //setState({...state, countryList:res.data.country});
        }).catch(err => setInitLoad(false));
        
        
        await axios.get(countryUrl).then(res =>{  
          console.log("profiledata:",res.data);          
          state.countryList=res.data.country;
          setState({...state});
        }).catch(err=>setInitLoad(false));;
        setInitLoad(false);
      })();
  }, [])

  // useBeforeFirstRender(() => {
    
    
  // })

  const pdfDelete = (data) => {
    let filteredState = mediaState.productBrochures.filter(item => {
      if(item.documentId == data.documentId && item.channelDocumentId)
      { 
        let temp = state.deletedDocuments;
        temp.push(+item.channelDocumentId);
        setState({...state, deletedDocuments: temp});
      }
      return item.documentId != data.documentId
    });
    if(filteredState){
      setMediaState({...mediaState, productBrochures: filteredState});
      console.log('after delete', mediaState);
    }
  }
  
const introVideoId = (data)=>{
  console.log('introVideoId',data.documentId);
  setInitialVideo('');
  setState({...state, introVideo: data.documentId});
}
  
const getCompanyLogo = (resImagePath) => {
  
  setState({...state, companyLogo: resImagePath.document.documentPath, documentId: resImagePath.documentId});

};


  


const save = async() => {
  setLoading(true);
  
  // let award_details=state.awards ? [].concat(state.awards.map(x => {
  //   return {channelAwardId : x.id, details : x.award_details, issued_by : x.awardName, valid_upto : x.date!=='1' ? x.date : '0', isLifeTime : x.date==='1' ? '1' : '0', imageId: x.documentId ? x.documentId : null, image: x.awardImg ? x.awardImg : null }
  // })) : [];

  let award_details=state.awards ? [].concat(state.awards.map(x => {
    if(x.isOld)
    {
      let isLifeTime = x.lifeTime==='1' || x.lifeTime==true ? '1' : '0';
      let valid_upto = x.date!=='1' ? x.date : null;
      if(!valid_upto && isLifeTime == '0')
        valid_upto = x.date;
      return {channel_award_id : x.id, details : x.award_details, issued_by : x.awardName, valid_upto : valid_upto, isLifeTime : x.lifeTime==='1' || x.lifeTime==true ? '1' : '0', image: x.awardImg ? x.awardImg : null, documentId: x.documentId ? x.documentId : null, }
    }
      return { details : x.award_details, issued_by : x.awardName, valid_upto : x.date!=='1' ? x.date : null, isLifeTime : x.lifeTime==='1' || x.lifeTime==true ? '1' : '0', image: x.awardImg ? x.awardImg : null }
  })) : [{}];

  let deleted_award_details = state.deletedAwards.length?state.deletedAwards:[null];

 let bannerImage = mediaState.bannerImages.length ? [].concat(mediaState.bannerImages.map(x => {
    return {image: x.image, id: x.id ? x.id : ''}
  })): [];//[{image:null}],

  // let productBrochure={
  //   productpdf:mediaState.productBrochures.length ? [].concat(mediaState.productBrochures.map(x => {
  //     return {channelDocumentId: x.channelDocumentId?x.channelDocumentId:null,documentId: x.documentId, image: x.document.documentUrl, documentName: null, documentDescription: null};
  //   })) : []//{image:null,documentName:null,documentDescription:null}]
  // }

  let productBrochure={
    productpdf:mediaState.productBrochures.length ? [].concat(mediaState.productBrochures.map(x => {
      return {channelDocumentId: x.channelDocumentId?+x.channelDocumentId:null,documentId: +x.documentId,document: x.document.documentUrl};
    })) : [{document: 'null'}]//{image:null,documentName:null,documentDescription:null}]
  }
    // let data = {
    //   companyRegNumber : watchAll.CRN,
    //   certificateName: state.cerificateName,
    //   vatNumber : state.isVat=='1'?watchAll.VATN:null,
    //   companyLogo : state.documentId,
    //   tweetPitch : state.tweetPitch,
    //   introVideo : state.introVideo,
    //   sourcing : state.sourcing,
    //   country: state.country.id,
    //   companyLegalName: watchAll.companyName,
    //   legalAddress: state.legalAddress,
    //   zipCode: watchAll.zipCode,
    //   city: watchAll.city,
    //   phoneNumber1: state.phoneNumber1,
    //   phoneNumber2: state.phoneNumber2,
    //   mobile: state.mobile,
    //   email: state.email,
    //   websiteUrl: watchAll.websiteUrl,
    //   award_details: award_details,
    //   linkedIn: watchAll.linkedIn,
    //   insta: watchAll.insta,
    //   tradeUrls: watchAll.tradeUrls,
    //   productBrochure: productBrochure,
    //   bannerImage: bannerImage
    // }
let tax_cert = [];
let reg_cert = [];
  reg_cert.push({name: state.certificateName, id: watchAll.CRN, channel_certificate_id: parseInt(state.certificateId)});
  if(state.isVat == '1')
  tax_cert.push({name:'VAT', id: watchAll.VATN, channel_certificate_id: parseInt(state.vatId) });

let data = {
      companyRegNumber : watchAll.CRN,
      certificateName: state.certificateName,
      vatNumber : state.isVat=='1'?parseInt(watchAll.VATN):null,
      registration_certificate: reg_cert,
      // company_logo_url: state.companyLogo,
      // companyLogo : state.documentId,
      company_logo_url: state.documentId,
      year_of_estabilishment: +watchAll.Yearestablishment,
      company_name: watchAll.companyName,
      company_about : state.tweetPitch,
      company_introvideo : state.introVideo,
      sourcing : state.sourcing,
      is_same_address: 0,
      reg_address_id: +state.regAddressId,
      reg_country_id: +state.country.id,
      reg_address: state.legalAddress,
      reg_postal_code: watchAll.zipCode,
      reg_city: watchAll.city,
      // bill_address_id: +state.billAddressId,
      // bill_country_id: state.country.id,
      // bill_address: state.legalAddress,
      // bill_postal_code: watchAll.zipCode,
      // bill_city: watchAll.city,
      company_phone1: state.phoneNumber1+'',
      company_phone2: state.phoneNumber2+'',
      company_mobile_no: state.mobile,
      company_email: state.email,
      company_website_url: watchAll.websiteUrl,
      award_details: award_details,
      social_linkedin_url: watchAll.linkedIn,
      social_instagram_url: watchAll.insta,
      social_linkedin_id: state.social_linkedin_id,
      social_googleplus_id: null,
      social_instagram_id: state.social_instagram_id,
      contact_linkedin_id: null,
      deleted_B2B:state.deleted_B2B.length?state.deleted_B2B : [null],
      tradeUrls: watchAll.tradeUrls,
      channel_document: productBrochure.productpdf,
      bannerImage: bannerImage,
      deleted_award_details: deleted_award_details,
      user: {channel: userData, userId: userData.userId},
    }
      

    if(state.b2bList.length){
       state.b2bList = [].concat(state.b2bList.map(x => {
        
          if(x.channel_B2B_id && x.value)
            return {B2B_url: x.value, channel_B2B_id: x.channel_B2B_id}
          else if(x.channel_B2B_id && !x.value)
          {
            state.deleted_B2B.push(x.channel_B2B_id);
            return null;
          }else if(x.value && !x.channel_B2B_id)
          {
            return {B2B_url: x.value}
          }          
        
      }));
      state.b2bList = state.b2bList.filter((el)=> el!=null);
      data = Object.assign(data, {deleted_B2B: state.deleted_B2B.length ? [... new Set(state.deleted_B2B)] : [null]});
      if(state.b2bList.length)
      data = Object.assign(data, {B2B_url: state.b2bList});
    }

    if(tax_cert.length)
      data=Object.assign(data, {tax_certificate: tax_cert});

    if(state.deletedDocuments.length)
      data=Object.assign(data, {deleted_channel_documents: state.deletedDocuments});

   let result=await dashboardActions.updateCompanyInfo({data}).catch(err=>setLoading(false));
   console.log('saveresuult', result);
   if(result) 
    setLoading(false);
  // history.push('admin/dashboard');
  
  console.log('save', data);
}
  
  const handleChange = input => e => {
    if(input == 'tradeUrls'){
      let b2b = [...state.b2bList];
      console.log('b2b', b2b, e.target.value, e.target);
      b2b[e.target.id].value = e.target.value;
      setState({...state, b2bList: b2b})
    }else{
      setState({...state, [input] : e.target.value});
    }
    console.log("add",e.target.value, e.target);  
      
  }
  

  const handleChangeQuill1 = (content, delta , source, editor) => { 
    setState({...state, 'tweetPitch' : editor.getText().trim().replace( /[\r\n]+/gm, "" )});
  }

  const handleChangeQuill2 = (content, delta , source, editor) => { 
    setState({...state, 'sourcing' : editor.getText().trim().replace( /[\r\n]+/gm, "" )});
  }

  const handleChangeSelect = (input,value) => {
      setState({...state, [input]:value});
  }

console.log('satet', state);
console.log('watchAll', watchAll);
console.log('media', mediaState);

const onSubmit = data => {    
if (data) {        
    console.log("data:",data);
}    
}
console.log('errrors', errors);

//maturity level single or multiple decision making code
let typeId=userData?.channelTypeId;
//Award
const addAwards = (data) => {
  console.log('data',data);
  let index = state.awards.findIndex((obj => obj.id == data.id));
  if(index != -1){
    if(state.awards[index].isOld)
      state.awards[index] = Object.assign(data, {documentId:state.awards[index].documentId, isOld: true});
    else
    state.awards[index] = Object.assign(data, {documentId:state.awards[index].documentId});
    setState({...state, awards: state.awards});
    console.log('updated', data);
  }
  else{
  let temp = state.awards;
  temp.push(Object.assign(data, {documentId: null}));
  setState({...state, awards: temp});
  console.log('added', data);
  }
}

const deleteAwards = (data) => {
  const filteredList = state.awards.filter((item) => {
    if(data.id == item.id && item.isOld)
      state.deletedAwards.push(item.id);
    return item.id !== data.id;
  });
  state.awards = filteredList;
  setState({...state, awards: state.awards, deletedAwards: state.deletedAwards});
}

const handleChangeFileSelect = async(input, value, filename) => {
  if(input === 'productBrochures'){
    console.log('brochures');
    let temp = mediaState.productBrochures;
    let newFiles = value.filter(file => !mediaState.productBrochures.find(f => f.documentId === file.documentId));
    
    console.log('temp', temp, temp.length + newFiles.length)
    if(temp.length + newFiles.length <= 5){
      temp.push(...newFiles);
      setMediaState({...mediaState, [input]:temp});
    }
    else{
       console.log('Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb');
    }
  }
  else{
  let res = await handleOnDrop(input, value, filename);
   if(res){
      console.log('res', res);
      setMediaState({...mediaState, [input]:value})
   };
  }
}

const handleChangeFileDelete = async(input, fileToBeRemoved) => {
  let filteredState = await mediaState[input].filter(item => item.filename != fileToBeRemoved);
  if(filteredState){
    setMediaState({...mediaState, [input]: filteredState});
    console.log('after delete', mediaState);
  }
}

//functions for image
const handleOnDrop = async(input, data, file_name) => {
  if(data){
    setLoading(true);
    console.log('datat', data);
    let file = data;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      console.log('file', file)
      console.log('reader.result' ,reader.result)
      selectImg(input, reader.result, file_name)
      //setOpen(false);          
    };
    
  }
}

const handleChangeBanner = event => {
  const data = event.target.files[0];
  console.log('files', data)

    if(data && data['type'].split('/')[0] === 'image'){
      console.log('datat', data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log('file', file)
        console.log('reader.result' ,reader.result)
        setImage(
          reader.result
        );
        console.log('image', image)
        //setOpen(false);          
      };
      
    }
  
};

const selectBannerImg = async(data)=>{
  if(data){
    setImage('');
    //converting base64 string to file format.
    var random = Math.random(); 
    var fileName =random +'.png';
    var file = dataURLtoFile(data, fileName);    
    const formData = new FormData();
    formData.append('file', file);
    setLoading(true);
    const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/'+'banner');
    console.log('resImagePath', resImagePath);
    if(resImagePath){
      setImageSrc(resImagePath.data.url);
      setBannerId('');
      setMediaState({...mediaState, 'bannerImages': [{image: resImagePath.data.url}]});
      setLoading(false);
    }
      
  }
  
}

function dataURLtoFile(dataurl, filename) {

  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
      
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  
  return new File([u8arr], filename, {type:mime});
}

const addNewB2B = () => {
  setState({...state,  b2bList:[...state.b2bList, {id: Math.random(), value: ''}]});
}

const delB2B = (id) => {
  console.log('id', id);
  let del = state.deleted_B2B;
  state.b2bList.map((x,i) => {
    if(id == i && x.channel_B2B_id)
      del.push(x.channel_B2B_id);
  })
  let temp = state.b2bList.filter((r,i) => i !== id);
  setState({...state, b2bList: temp, deleted_B2B: del});
}

const selectImg = async(input, data, file_name)=>{
  if(data.length>0){
    
    //converting base64 string to file format.
    var random = Math.random(); 
    var fileName =random +'.png';
    var file = dataURLtoFile(data, fileName);    
    const formData = new FormData();
    formData.append('file', file);

    let aws_folder = input === 'bannerImages' ? 'banner' : '' ;


    const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/'+aws_folder);
    if(resImagePath){
    if(input === 'bannerImages'){
      let temp = mediaState.bannerImages;
      temp.push({image: resImagePath.data.url, filename: file_name})
      setMediaState({...mediaState, [input]: temp});
    }
    setLoading(false);

  }else{
     loading=false;
     setLoading(loading);
  }
  
  }
  // setOpen(false);
}

return (
<div className="main-content">
{initLoad ? <Spinner /> : <Container className="mt-4 col-lg-10 col-md-9 col-sm-12  " fluid>
        <form noValidate onSubmit={handleSubmit(save)}>
        {loading && <div style={{ top: '50%', left: '50%', zIndex: '1000',}}><Spinner /></div>}
      <Card>
      <CardHeader className="col-lg-12 justify-content-center">
                  <h3 className="text-center">Registration Information</h3>
                  <p className="text-center">Enter Company legal details.</p>
                  </CardHeader>
          {/* <CardBody className="Profad"> */}
                 
          <CardBody >      
<div className="row"> 
    <div className="col-sm-12">
    <div className="row">
    <div className="col-sm-2">
    <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="companyLogo" name="companyLogo" ref={register} value={state.companyLogo} /> 
    <h4>Company Logo *{!errors.companyLogo || state.companyLogo ? '' : <span style={{color: '#f44336', fontSize:'0.75rem'}}>   Please Select Company Logo</span>}</h4>
        <div>
       
       <ImageLibrary getDocumentId={getCompanyLogo} aws_folderName='CompanyLogo' id={1} userData={userData}
               img={state.companyLogo ? process.env.REACT_APP_CDN_URL+state.companyLogo : process.env.REACT_APP_CDN_URL+"document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}/>
   </div>
      
        

        

    </div>
    <div className="col-sm-2 mt-6">
        <span>Accepts Jpeg / Jpg / Png Only</span>
        <br></br>
        <span>(Max size allowed 200 Kb)</span>
        </div> 
        
        <div className="col-sm-2 mt-2">
    
        </div> 

        <div className="col-sm-6 mt-2">
        <FormInput 
        className="comp-name mt-2" 
        id="Yearestablishment" 
        name="Yearestablishment" 
        label="Year of Establishment"
        placeholder="Year of Establishment" 
        type="text" 
        variant="outlined" 
        value={watchAll.Yearestablishment} defaultValue={state.yearOfEstablishment} error={errors.Yearestablishment}
        register={register}
        

        />
        </div> 
         </div>
                  
    </div>
     
</div>
<div className="row">
  <div className="col-lg-12 mt-2">
  {/* <h4 >Company Legal Name *</h4> */}
  <FormInput className="comp-name mt-2" id="companyName" name="companyName" label="Company Legal Name *" type="text" variant="outlined" value={state.companyName} defaultValue={state.companyName} error={errors.companyName}
  register={register}
  value={watchAll.companyName}

  />
  </div>
</div>

<div className="row">
  <div className="col-lg-6 mt-3">
  {/* <h4 >Registered/Legal Address</h4> */}
  <FormInput className="comp-name mt-2 addrs" id="outlined-search" label="Registered/Legal Address" type="text" variant="outlined" value={state.legalAddress}
    onChange={handleChange('legalAddress')}
  />
  
  </div>

  <div className="col-lg-6 mt-4 ">
  <div className="row">
  {/* <h4 >Country *</h4> */}
  {/* <FormInput className="comp-name mt-2" id="outlined-search" label="Country *" type="text" variant="outlined" /> */}
  <FormControl variant="outlined" style={{width:"100%"}}>
  <FormAutocomplete
                        id="country"
                        name="country"
                        label="Country"
                        options={state.countryList}
                        placeholder="Select Country"
                        // multiple
                        disableCloseOnSelect
                        blurOnSelect
                        limitTags={1}
                        control={control}                         
                        input='country'
                        value={ state.country }
                        handleChangeSelect={handleChangeSelect}
                        errMessage={'Please Select Country'}
                        error={errors.country }
                        defaultValue={state.country}
                        />
        </FormControl>
  </div>
  <div className="row">
  {/* <h4 >Country *</h4> */}
  <FormInput className="comp-name mt-4" id="outlined-search" name="zipCode" placeholder='' label="Zip/Pincode *" type="text" variant="outlined" 
  value={watchAll.zipCode} 
  defaultValue={state.zipCode}
  onChange={handleChange('zipCode')}  
  register={register}
  error={errors.zipCode}
  />
  </div>
  <div className="row">
  {/* <h4 >Country *</h4> */}
  <FormInput className="comp-name mt-4" id="outlined-search1" name="city" label="City *" type="text" variant="outlined" value={watchAll.city}
  defaultValue={state.city}
  onChange={handleChange('city')}  
  error={errors.city}
  register={register}
  />
  </div>
  </div>
</div>

<div className="row mt-3"> 
    <div className="col-sm-6 mt-3">
            <div className="form-group">            

           <label className="form-control-label" htmlFor="">
           
           </label>
           <InputGroup className="reg" >
             <InputGroupAddon addonType="prepend">
             <InputGroupText>{state.certificateName}</InputGroupText>
             </InputGroupAddon>
             <FormInput  className="reg12"
                   id="CRN"
                   type="text"
                   name="CRN"
                   label="Company registration number*"
                   register={register}
                   error={errors.CRN}
                   defaultValue={state.companyRegNumber}
                   value={watchAll.CRN}
                   onChange={handleChange('companyRegNumber')}
             />
           </InputGroup>
       
             </div>  

                
  
               </div>
    <div className="col-sm-6 mt-3">
    <div className="form-group">            

<label className="form-control-label" htmlFor="">
<input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="isVat" ref={register} name="isVat" value={state.isVat} control={control} />
</label>
{state.isVat=='1' && (
<InputGroup  className="reg">
  <InputGroupAddon addonType="prepend">
    <InputGroupText id="Vat">VAT</InputGroupText>
  </InputGroupAddon>
  <FormInput
  className="reg21"
  id="VATN"
  type="text"
  name="VATN"
  label="VAT number"
  register={register}
  error={errors.VATN}
  defaultValue={state.vatNumber}
  value={watchAll.VATN}
  onChange={handleChange('vatNumber')}
  />
</InputGroup> )}

  </div>  
    </div>
</div>
</CardBody>
</Card>

<Card>
  <CardHeader>
  <h3 className="text-center">Communication</h3>
  <p className="text-center">Enter your general communication information: switchboard number, web site, general email</p>
  </CardHeader>
  <CardBody>
    <Row>
    <Col lg="6">
    <FormInput className="comp-name mt-4" id="outlined-search" label="PhoneNumber1" type="number" variant="outlined" value={state.phoneNumber1} defaultValue={state.phoneNumber1}
    onChange={handleChange('phoneNumber1')}
     />
    </Col>
    <Col lg="6">
    <FormInput className="comp-name mt-4" id="outlined-search" label="PhoneNumber2" type="number" variant="outlined" value={state.phoneNumber2} 
    onChange={handleChange('phoneNumber2')}
    />
    </Col>
    </Row>
  
    <Row>
    <Col lg="6">
    <FormInput className="comp-name mt-4" id="outlined-search" label="MobileNumber" type="number" variant="outlined" value={state.mobile}
    onChange={handleChange('mobile')}
    />
    </Col>
    <Col lg="6">
    {/* <FormInput className="comp-name mt-3" id="outlined-search" label="EmailID" type="text" variant="outlined" /> */}
    <div className="form-group mt-4">
        <FormInput
            id="companyemail" 
            type="email" 
            label="Enter your Email" 
            name="companyemail" 
            register={register}
            value={state.email}
            onChange={handleChange('email')}
            //error={errors.companyemail ? errors.companyemail : checkMailId ? {message:"Email Id already taken", required: true} : ''}
            
            //defaultValue={email}
         />
        </div>
    </Col>
    </Row>
    <Row>
      <Col lg="12">
      <FormInput className="comp-name mt-2" id="outlined-search" name="websiteUrl" label="Website URL *" type="text" variant="outlined" value={watchAll.websiteUrl}
      defaultValue={state.websiteUrl}
      register={register}
      error={errors.websiteUrl}
      />
      </Col>
    </Row>
  </CardBody>
</Card>


<Card>
  <CardHeader>
  <h3 className="text-center">Awards</h3>
  <p className="text-center">Raise your profile by adding your Awards.</p>
  </CardHeader>
  <CardBody>
  <div className="col-lg-12 ">          
  <BrandCard award={true} addAwards={addAwards} deleteAwards={deleteAwards} awardList={state.awards} name="BMW" img="https://cdn.ces.tech/ces/media/events-experiences-images/innovation-awards/2020/honoree.png"/>
</div>
  </CardBody>
</Card>

<Card>
  <CardHeader>
  <h3 className="text-center">Social Profiles</h3>
  <p className="text-center">Add your company’s social profiles like Company Website URL, linkedin, Instragram Profile Pages.</p>
  </CardHeader>
  <CardBody>
    <Row>
      <Col lg="12">
      <FormInput className="comp-name mt-4" id="outlined-search" name="linkedIn" label="Linkedin URL" type="text" variant="outlined" value={watchAll.linkedIn}
      onChange={handleChange('linkedIn')}
      register={register}
      error={errors.linkedIn}
      defaultValue={state.linkedIn}
      />
      </Col>
    </Row>
    <Row>
      <Col lg="12">
      <FormInput className="comp-name mt-4" id="outlined-search" name="insta" label="Instagram URL" type="text" variant="outlined" value={watchAll.insta}
      onChange={handleChange('insta')}
      register={register}
      error={errors.insta}
      defaultValue={state.insta}
      />
      </Col>
    </Row>
    {/* <Row>
      <Col lg="11">
      <FormInput className="comp-name mt-4" id="outlined-search" name="tradeUrls" label="Other B2B or Trade Portals Website URLs" type="text" variant="outlined" value={state.tradeUrls}
      onChange={handleChange('tradeUrls')}
      register={register}
      error={errors.tradeUrls}
      defaultValue={state.tradeUrls}
      id='1'
      />
      </Col>
      <Col lg="1">
        <div className="faplus">
      <i  class="fa fa-plus-circle" onClick={addNewB2B}></i>
      </div>
      </Col>
    </Row> */}

    {state.b2bList && state.b2bList.map((x,i) => <Row>
      <Col lg="11">
      <FormInput className="comp-name mt-4" id={i} name="tradeUrls" label="Other B2B or Trade Portals Website URLs" type="text" variant="outlined" value={watchAll.tradeUrls}
      onChange={handleChange('tradeUrls')}
      register={register}
      error={errors.tradeUrls}
      defaultValue={x.value}
      />
      </Col>
      {i == 0 ? <Col lg="1">
        <div className="faplus">
      <i  class="fa fa-plus-circle" onClick={addNewB2B}></i>
      </div>
      </Col> : 
      <Col lg="1">
        <div className="faminus">
        <i class="fa fa-minus-circle " onClick={() => delB2B(i)}></i>
      </div>
      </Col>}
    </Row>)}
  </CardBody>
</Card>

<Card>
  <CardHeader>
  <h3 className="text-center">Welcome Video</h3>
  <p className="text-center">Add an introduction video that buyers will view on your company profile (max 90 seconds).</p>
  </CardHeader>
  <CardBody>
  <div className="form-group">
          <form action="/file-upload" className="dropzone">
  <div className="fallback">    
  <div style={{flex: 1, display:'flex'}}><MediaLibrary text="Upload Intro Video" introVideoId={introVideoId} id='1' size={2}/>
  {initialVideo && <span style={{color:'orange', display:'flex', alignItems:'center', marginLeft: '2rem',cursor:"pointer"}} onClick={()=>setOpen(true)}>Preview Video</span>}</div>
  <VideoPreview videoModalClose={()=>setOpen(false)} isOpen={open} url={initialVideo}/>
  </div>
</form>
        </div>
  </CardBody>
</Card>
<Card>
  <CardBody>
  <div className="row"> 
                <div className="col-sm-12 mt-4 ">
                <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="tweetPitch" name="tweetPitch" ref={register} value={state.tweetPitch} /> 
                {!errors.tweetPitch || (state.tweetPitch.length > 5 && state.tweetPitch.length < 280) ? <h3 style={{fontSize:'1rem'}}>Your Company - Tweet Pitch *</h3> :
             <h4>Your Company - Tweet Pitch * <span style={{color: '#f44336', float: 'right', fontSize:'0.75rem'}}>{
                  state.tweetPitch.length <= 1? 'TweetPitch can\'t be empty.' : state.tweetPitch.length <= 5 ? 'TweetPitch must have min 5 characters' : state.tweetPitch.length > 280 ? 'TweetPitch must have max 280 characters' : ''
                  }</span></h4> 
                }
          <div className="form-group">
          <QuillEditor  placeholder="Tell us about your company background and vision -max 280 characters *" handleChange={handleChangeQuill1} value={state.tweetPitch} />
        </div>
        </div>
</div>

<div className="row"> 
                <div className="col-sm-12 mt-4 ">
                <h3>Sourcing Priority & Strategy </h3>
                {/* <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="sourcing" name="sourcing" ref={register} value={state.sourcing} />  */}
                {/* {!errors.sourcing || (state.sourcing.length > 5 && state.sourcing.length < 280) ? <h3 style={{fontSize:'1rem'}}>Sourcing Priority & Strategy</h3> :
             <h4>Sourcing Priority & Strategy <span style={{color: '#f44336', float: 'right', fontSize:'0.75rem'}}>{
                  state.tweetPitch.length <= 1? 'TweetPitch can\'t be empty.' : state.tweetPitch.length <= 5 ? 'TweetPitch must have min 5 characters' : state.tweetPitch.length > 280 ? 'TweetPitch must have max 280 characters' : ''
                  }</span></h4> 
                } */}
          <div className="form-group">
          <QuillEditor  placeholder="Tell us about your company background and vision -max 280 characters *" handleChange={handleChangeQuill2} value={state.sourcing}/>
        </div>
        </div>
</div>
  </CardBody>
</Card>

<Card>
  <CardBody>

  <div className="col-lg-12 ">
            <h4 style={{textAlign:'left',display:'inline-block'}}>Banner Image</h4>
            <h5 style={{float:'right'}}>(Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 5 mb)</h5>
            <div className="form-group mt-3" style={{height: '10rem'}}>  
                {/* <MultiUpload multiple={true} limit={1} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="bannerImages"/> */}
                <BannerUploader img={bannerId ? process.env.REACT_APP_CDN_URL+imageSrc : imageSrc ? process.env.REACT_APP_CDN_URL_TEMP+imageSrc : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
                handleChange={handleChangeBanner} selectImg={selectBannerImg} image={image} handleCropClose={e=>setImage('')}
                /> 
                {loading && <div style={{position: 'relative', display:'block',
    top: '50%',
    left: '50%',
    zIndex: '1000',
    }}><Spinner /></div>}
                   </div>        
            </div>
</CardBody>
</Card>

<Card>
  <CardBody>
  <div className="col-lg-12">
            <h4 style={{textAlign:'left',display:'inline-block'}}>Product's Brochures and Technical spec sheets</h4>
            <h5 style={{float:'right'}}>(Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb)</h5>
            {/* This below div is just used to inherit mui-dropzone css classes */}
            <div style={{display: 'none'}}><MultiUpload multiple={true} limit={1} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="bannerImages"/></div>
                <span onClick={() => setProdImage(true)}>
               {
               !mediaState.productBrochures.length ? 
               <div className="form-group mt-3">        
               <div tabindex="0" class="MuiDropzoneArea-root">
               <div class="MuiDropzoneArea-textContainer">
                 <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">Drag and drop a file here or click</p>
                 <svg class="MuiSvgIcon-root MuiDropzoneArea-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                </div></div></div> 
                : 
                <PdfCard pdfList={mediaState.productBrochures} deletePdf={pdfDelete} />
                }
                </span>
                
            {prodImage && <ImageLibraryMultipleUploads id={1} isOpen={prodImage} multiple={true} limit={1} acceptedFiles={['application/pdf']} getBrochuresIds={handleChangeFileSelect} input="productBrochures" aws_folderName="broucher" handleClose={()=>setProdImage(false)}/>}
                            
            </div>
            <div className="text-center mt-4">
            <button type="submit"  className="btn btn-primary" >Save</button>
          </div>
  </CardBody>
</Card>
  
                {/* ---------------------------Trade information information------------------------------          
                <Card>
                <CardHeader>
                <h3 className="text-center">Trade Information</h3>
                  <p className="text-center">Key informations for showing matching</p>
                  </CardHeader>
                  <CardBody>

{typeId==3 && (
<div className="col-lg-12 ">
                  <h4 >{typeId==3 && 'Distributor Profile and speciality *'}</h4>
            <div className="form-group">        
            <SingleAutocompleteGrouping
              id="DistributorProfiles"
              name="DistributorProfiles"                        
              label="Distributor Profiles * "                            
              multiple={false}
              Options={state.DistributorProfileOption}
              placeholder="Channel Profiles"                        
              input='DistributorProfileValue'              
              errMessage={'Please Select Distributor Profile'}
              error={errors.DistributorProfiles }
              value={state.DistributorProfileValue}

              handleChangeSelect={handleChangeSelect}
              control={control}
              register={register}
             
              />
            
                 </div>           
            </div>                      
        )}

{(typeId==3 && Object.keys(state.DistributorProfileValue).length != 0 && state.DistributorProfileValue.childname!='broadliner') && (
<div className="col-lg-12 ">
                  <h4 >Select Product Families *</h4>
            <div className="form-group">        

          <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="productCat" name="productCat" ref={register} value={state.productValue.length?state.productValue[0].childname:''} /> 
                  <FormAutocompleteGrouping
                        id="ProductGroup"
                        name="ProductGroup"
                        label="Product Families are you selling"
                        Options={state.productFamilies}
                        placeholder="Select Product Families"
                        input='productFamily'
                        errMessage={'Please Select a Product'}
                        error={!errors.productCat || state.productValue.length ?false:true}
                        value={state.productValue}

                        optionName='productFamilies'
                        optionValue='productValue'
                        defaultValue={state.productValue}
                        selectedItem={selectedItem}

                        handleChangeSelect={handleChangeSelect}
                        control={control}
                        register={register}                        
                        />
            </div>           
            </div>                      
        )}

     
          {(typeId==2 || typeId==3) && (
            
            <div className="col-lg-12 ">
            <h4 >Brands *</h4>
            <div className="form-group mt-3">        
           
            {state.brandList[0] && <BrandCard  name="BMW" deleteBrand={deleteBrand} brandList={state.brandList}  img={"https://d35w8j22j5uly8.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"}/>}

            <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="brandList" name="brandList" ref={register} value={state.brandList[0] ? 'data' : ''} /> 
<AddFieldWithPopUpAutocomplete
        limitTags={2}
        name="Brands"
        id="Brands"
        options={state.brandOption}
        defaultValue={state.brandValue}
        value={state.brandValue}
        getOptionLabel={option => option.name}
        renderOption={option => (
         <>
         <div style={{display: '-webkit-inline-flex',
    padding: '1px 6px',
    width: '75px',
    margin: '10px 5px 0 0',
    float: 'left',
    border: '2px solid #e7e7e7',
    borderRadius: '5px',
    height: '38px'}}>

            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  style={{maxWidth: '63px',backgroundColor: '#fff',
    maxHeight: '34px',
    height: 'auto',
    width: 'auto',
    margin: '0 auto',
    cursor: 'pointer'}} src={option.documentpath} />    </div>
          <span>
            {option.name}
          </span>
          </>
        )}
        renderInput={(params) => (
          <FormInput {...params} variant="outlined" label="Brands" placeholder="Brands" />
        )}
        label="Brands *"
        placeholder="Brands"
        input="brands"
        control={control}
        register={register}
        errMessage={'Please Select a Brand'}
        error={errors.Brands&&!state.brandList[0]? errors.Brands : (errors.brandList && !state.brandList[0]) ? true : false}
        handleChangeSelect={handleChangeSelect}
        opneAdd={opneAdd}
      />
      {brandName && <AddBrand aws_folderName="brandlogo" opneadd={opneadd} closeModalPopup={closeModalPopup} brandName={brandName} handleChangeSelect={handleChangeSelect}/>}

                 </div>           
            </div>

            )}

        
            {(typeId==2 || typeId==3) && (
                  <div className="col-lg-12 ">
                  <h4 >Relevant Channel Profiles *</h4>
            <div className="form-group">        
            <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="relevantChannelProfiles" name="relevantChannelProfiles" ref={register} value={state.ChannelProfileValue.length?state.ChannelProfileValue[0].childname:''} /> 
            <FormAutocompleteGrouping
              id="relevantProfiles"
              name="relevantProfiles"
              label="Channel Profiles * "
              multiple={true}
              Options={state.ChannelProfileOption}
              placeholder="Channel Profiles"                        
              input='relevantChannelProfile'              
              errMessage={'Please Select Channel Profile'}
              error={!errors.relevantChannelProfiles || state.ChannelProfileValue.length ?false:true}
              value={state.ChannelProfileValue}                            

              optionName='ChannelProfileOption'
              optionValue='ChannelProfileValue'
              defaultValue={state.ChannelProfileValue}
              selectedItem={selectedItem}

              handleChangeSelect={handleChangeSelect}
              control={control}
              register={register}
              
              />
            
                 </div>           
            </div>
            )}


            {(typeId==4) && (
            <div className="col-lg-12 ">
                  <h4 >Retailer Profiles *'</h4>
            <div className="form-group">        
            <SingleAutocompleteGrouping
              id="relevantProfiles"
              name="relevantRetailerProfiles"
              label="Retailer Profiles * "
              multiple={false}
              Options={state.ChannelProfileOption}
              placeholder="Channel Profiles"                        
              input='relevantRetailerProfile'              
              errMessage={'Please Select Retailer Profiles'}
              error={errors.relevantRetailerProfiles }
              value={state.ChannelProfileValue}                    
              handleChangeSelect={handleChangeSelect}
              control={control}
              register={register}
             
              />
            
                 </div>           
            </div>

            )}

                

            {(typeId==2 || typeId==4) && (
            <div className="col-lg-12">
            <h4>{typeId==2?"No of SKU's":"No of Store's"} *</h4>
                  <h5  >{typeId==2?'Enter the number of products that you handle':'Enter the number of stores that you handle'}</h5>
            <div className="form-group">    
          <FormInput
              id="No of SKU's"
              type="number"
              name="no_of_skus"
              label={typeId==2?"No of SKU's *":"No of Store's *"}
              InputProps={{
                inputProps: { 
                    max: 100, min: 10 
                }
                }}
              value={state.no_of_skus}
              onChange={handleChange('no_of_skus')}
              register={register}
              defaultValue={state.no_of_skus}
              value={watchAll.no_of_skus}
              error={errors.no_of_skus}
          /> 
        </div>
        </div>

)}

   
        <div className="text-center">
        <button type="submit" className="btn btn-primary" >Save</button> 
          </div>  
 
    
        
          
        
          </CardBody>
    
        
          </Card> */}
         
          </form>
              </Container>}
      </div>
    
   
  );
};

const startUp = [
  {name : 'Prototype', id: 1, value: 'PROTOTYPE'},
  {name : 'Shipping to first backers', id: 2, value: 'S_BACKER'},
  {name : 'Shipping in volume', id: 3, value: 'S_VOLUME'},
];

const radioGroupOptions = [
  {
    value: "1",
    label: "Yes",
  },
  {
    value: "0",
    label: "No",
  },
 
];


export default Initialprofile;

