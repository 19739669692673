import React ,{useState} from 'react';
import {Row,Col } from "reactstrap";
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import axios from 'axios';
export const AwardsReviews=(props)=> {
  const { data, title } = props;
  const [showMore, setMore] = useState(false);
  const more_click=()=>{
    if(!showMore) setMore(true);
    else setMore(false);
    }
    if(data.length>0){
      let _remain = [];
      const _elements = data.slice(0,5); // clone array     

      _remain = data.slice(5,data.length);
  return (
    <>
    <h3 className="prodcompanytitle">{title}</h3>
      <Row className="d-flex">
        {_elements.map((item)=>{
          return(
            <Col lg="3" md="2" sm="6" className='awd-gri'>
            <div class='key-card' style={{cursor:'pointer'}} onClick={()=>{
                let url=item.articleurl;
                  if (!url.match(/^https?:\/\//i) || !url.match(/^http?:\/\//i)) {
                    url = '//' + url;
                  }
                  window.open(url,'_blank');
                }}>
                <div class='set-image'>
                <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  alt={item.productreviewername} src={item?.reviewmagazinelogo && item?.reviewmagazinelogo?.documentUrl?item.reviewmagazinelogo.documentUrl : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}/>
                </div>
                <div class='set-headline mt-2 text-center'>
                <h4>{item.productreviewername}</h4>

                {/* <UncontrolledTooltip placement='bottom'>
                        Add Favourite
                      </UncontrolledTooltip> */}
                </div>
              </div>
            </Col>)
            })}
      </Row>

{showMore && <Row className="d-flex justify-content-center">
{_remain.map((item)=>{
  return(
    <Col lg="2" md="2" sm="6" className='awd-gri'>
    <div class='key-cardawd'>
        <div class='set-image'>
        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  alt={item.productreviewername} src={item?.reviewmagazinelogo && item?.reviewmagazinelogo?.documentUrl?item.reviewmagazinelogo.documentUrl : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}/>
        </div>
        <div class='set-headline mt-2 text-center'>
        <h4>{item.productreviewername}
    </h4>
        </div>
      </div>
    </Col>)
    })}
</Row>}
{data.length>5 && <h3 onClick={more_click} className='float-right text-primary' style={{cursor:'pointer', zIndex:'1'}}>{!showMore ?  'Show More':'Show Less'}</h3>}

</>
  );
}
}

export const Awards=(props)=> {
  const { data, title } = props;
  const [showMore, setMore] = useState(false);
  const more_click=()=>{
    if(!showMore) setMore(true);
    else setMore(false);
    }
    if(data.length>0){
      let _remain = [];
      const _elements = data.slice(0,5); // clone array     

      _remain = data.slice(5,data.length);
  return (
    <>
    <h3 className="prodcompanytitle">{title}</h3>
      <Row className="d-flex">
        {_elements.map((item)=>{
          return(
            <Col lg="2" md="2" sm="6" className='awd-gri'>
            <div class='key-cardawd'>
                <div class='set-image'>
                <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  alt={item.awardDetail} src={item.document && item.document.documentUrl?item.document.documentUrl : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}/>
                </div>
                <div class='set-headline mt-2 text-center'>
                <h4>{item.awardDetail}
            </h4>
                </div>
              </div>
            </Col>)
            })}
      </Row>

{showMore && <Row className="d-flex justify-content-center">
{_remain.map((item)=>{
  return(
    <Col lg="2" md="2" sm="6" className='awd-gri'>
    <div class='key-cardawd'>
        <div class='set-image'>
        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  alt={item.awardDetail} src={item.document && item.document.documentUrl?item.document.documentUrl : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}/>
        </div>
        <div class='set-headline mt-2 text-center'>
        <h4>{item.awardDetail}
    </h4>
        </div>
      </div>
    </Col>)
    })}
</Row>}
{data.length>5 && <h3 onClick={more_click} className='float-right text-primary' style={{cursor:'pointer', zIndex:'1'}}>{!showMore ?  'Show More':'Show Less'}</h3>}

</>
  );
}
}

export const KeyRetailReviews=(props)=> {
  const { data, title } = props;
  const [showMore, setMore] = useState(false);
  const more_click=()=>{
    if(!showMore) setMore(true);
    else setMore(false);
    }
    if(data.length>0){
      let _remain = [];
      const _elements = data.slice(0,5); // clone array     

      _remain = data.slice(5,data.length);
  return (
    <>
    <h3 className="prodcompanytitle">{title}</h3>
      <Row className="d-flex ">
        {_elements.map((item,i)=>{
          return(
            <Col lg="3" md="2" sm="6">
            <div class='key-card' style={{cursor:'pointer'}} onClick={()=>{
              let url = item.ratingproofvalidationurl;
                  if (!url.match(/^https?:\/\//i) || !url.match(/^http?:\/\//i)) {
                    url = '//' + url;
                }
              window.open(url,'_blank')
              }}>
                <div class='set-image'>
                <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  alt={item.awardDetail} src={item.ratingproofvalidationlogo && item?.ratingproofvalidationlogo?.documentUrl?item?.ratingproofvalidationlogo?.documentUrl : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}/>
                </div>
                <div class='set-headline mt-2 text-center'>
                <h4>{item?.ratingproofvalidationname}
            </h4>
            <div className="text-center">
              <Box component="fieldset" mb={3} borderColor="transparent">
                    <Rating
                      name={`productexistingrating[${i}]`}
                      value={item?.existproductrating?item?.existproductrating:0}
                      defaultValue={item?.existproductrating?item?.existproductrating:0}
                      disabled
                    />
                  </Box>
              </div>
                </div>
              </div>
            </Col>)
            })}
      </Row>

{showMore && <Row className="d-flex justify-content-center">
{_remain.map((item)=>{
  return(
    <Col lg="2" md="2" sm="6">
            <div class='key-card'>
                <div class='set-image'>
                <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  alt={item.awardDetail} src={item.ratingproofvalidationlogo && item?.ratingproofvalidationlogo?.documentUrl?item?.ratingproofvalidationlogo?.documentUrl : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}/>
                </div>
                <div class='set-headline mt-2 text-center'>
                <h4>{item?.ratingproofvalidationname}
            </h4>
                </div>
              </div>
    </Col>)
    })}
</Row>}
{data.length>5 && <h3 onClick={more_click} className='float-right text-primary' style={{cursor:'pointer'}}>{!showMore ?  'Show More':'Show Less'}</h3>}

</>
  );
}
}

