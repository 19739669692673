import React, { useEffect, useState } from 'react';
import { FormInput } from "_components/FormElements/FormInput";
// import "./ImageUpload.css"; // Import your CSS file
import axios from 'axios';
const ImageUpload = ({handleRoomImage,imageType,handleRoomImage2, roomTypeImages, hotelId, deleteHotelImage, setDeleteHotelImage, setLoading, roomImageMinWidth=null}) => {
  const [images, setImages] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [dragging, setDragging] = useState(false);
 useEffect(()=>{
  setLoading(true)
    if(hotelId != null){
      getHotelImage(hotelId)
    }
    setLoading(false)
    setLoading(false)


    if(imageType == "roomImageType"){
      setImages(roomTypeImages)
    }


 },[])

  
 useEffect(()=> {
  if(imageType == "hotelImageType"){
    handleRoomImage(images);
  }else if(imageType == "roomImageType"){
    handleRoomImage2(images);
  }
}, [images])


 async function getHotelImage(id){
  if(imageType == "hotelImageType"){
  await axios.get(`/channelAdmin/getHotelImage/${id}`)
  .then((res)=> {
    setImages(res.data)
  })
  .catch((err)=>console.log(err))
  }else  if(imageType == "roomImageType"){
    setImages(roomTypeImages)
  }

 } 
 
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
        setShowPopup(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleUpload = () => {
    if (previewImage && inputValue.trim() !== '') {
      const newImage = { image: previewImage, caption: inputValue };
 

      setImages(prevImages => [...prevImages, newImage]);
      if(imageType == "roomImageType"){
        handleRoomImage2(newImage);
      }
      setPreviewImage(null);
      setInputValue('');
      console.log(previewImage);
      setShowPopup(false); // Close the popup after upload
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
        setShowPopup(true);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDelete = (index, id) => {
    setImages(prevImages => prevImages.filter((_, i) => i !== index));
    if(id != null){
      setDeleteHotelImage([...deleteHotelImage, id])
    }
  };

  const handleDropAreaClick = () => {
    console.log("Drop area clicked!"); // Debug statement
    handleUpload(); // Call handleUpload when the drop area is clicked
  };

  return (
    <div className='room-image' style={roomImageMinWidth != null ? {minWidth:roomImageMinWidth}:{}}>
     <div
  className={`drop-area ${dragging ? 'dragging' : ''}`}
  onDragOver={handleDragOver}
  onDragLeave={handleDragLeave}
  onDrop={handleDrop}
  onClick={handleDropAreaClick} // Ensure this line is correctly set up
>
    <input type="file" accept="image/*" onChange={handleImageChange}  />
    {previewImage ? null :<div > <p style={{marginBottom: '0'}}>Drag and drop an image here, or click to select</p> </div>
}
        <ul className='view-list'>
        {images.length > 0 && images.map((imageObj, index) => 
        (
          <li key={index}>
            <div className='view-image'>
              <img src={imageObj.documentPath != (null&&undefined)?`${process.env.REACT_APP_CDN_URL_TEMP}${imageObj.documentPath}`:imageObj.image} alt={`Image ${index}`} width="200" />
            </div>
            
            <p className='view-caption'>{imageObj.caption}</p>
           
            <button onClick={() => handleDelete(index, imageObj.id !=(null&&undefined) ?imageObj.id:null)}>X</button>
          </li>
       ) )}
      </ul>
      </div>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={() => setShowPopup(false)}>&times;</span>
            <div className='upload-img'>
              <img src={previewImage} alt="Preview" width="200" /><br />
            </div>
            <FormInput
              className="view-name"
              id="view-name "
              name="view-name "
              placeholder=""
              value={inputValue} onChange={handleInputChange}
              label="Enter the name of view"
              type="text"
              variant="outlined"
              style={{ margin: '20px' }}
            />
            <br />
            <button className='btn btn-primary' onClick={handleUpload} disabled={!inputValue.trim()}>Upload</button>
          </div>
        </div>
      )}
     
    </div>
  );
};

export default ImageUpload;
