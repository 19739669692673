import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import BootstrapTable from "react-bootstrap-table-next";
import TablePagination from '@material-ui/core/TablePagination';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell'; 
import TableRow from '@mui/material/TableRow';
import { history } from '_helpers';
import axios from "axios";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
import { ModalSpinner } from "views/Hoc/Spinner";
import { Button, Card, TextField } from "@material-ui/core";
import { SelectAutocomplete } from "_components/FormElements/FormInput";
import HotelRooms from "./HotelRooms";
import { ToastsContainer, ToastsStore } from 'react-toasts';

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const { id } = props;
  const { getEventHotel } = props;
  const { setLoading } = props;

  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null });
  const [deleteId, setDeleteId] = useState(null)

  const openModalPopUp = (id) => {
      modalPopValue.title = 'Delete Hotel';
      modalPopValue.message = 'You want to Delete Hotel';
      modalPopValue.popUpId = 1;
      setModalPopValue({ ...modalPopValue })
      setDeleteId(id)
      setModalPopup(true);
  };

  const deleteHotel = async () => {
    setLoading(true);
    await axios.delete(`channelAdmin/deleteHotel/${deleteId}`)
      .then((res) => getEventHotel())
      .catch((err) => console.log(err))
    setModalPopup(false)
  };

  return (
    <React.Fragment>
      {isModalPopup &&
        <Dialog open={isModalPopup} value={modalPopValue} handleSave={deleteHotel} handleClose={() => setModalPopup(false)}></Dialog>
      }
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.totalroom}</TableCell>
        <TableCell align="right">{row.available}</TableCell>
        <TableCell align="right">
          <button onClick={() => {
            history.push({ pathname: `/admin/hotel/${id}` })
          }} className="btn btn-icon btn-primary btn-sm">
            <EditIcon />
          </button>
          <button className="btn btn-icon btn-warning btn-sm"
            onClick={() => openModalPopUp(row.id)}
          >
            <DeleteIcon />
          </button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody className="roomavai-tabe">
                  {row.history && row.history.map((historyRow) => (
                    <TableRow key={historyRow.roomname}>
                      <TableCell>{historyRow.roomname}</TableCell>
                      <TableCell align="right">{historyRow.totalroom}</TableCell>
                      <TableCell align="right">
                        {historyRow.available}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    totalroom: PropTypes.number.isRequired,
    available: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        roomname: PropTypes.string.isRequired,
        totalroom: PropTypes.number.isRequired,
        available: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default function EventsHostel() {
  let [loading, setLoading] = useState(true);
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  const[count, setCount] = useState(10);
  const [hotel, setHotel] = useState([]);
  const [hotelRooms, setHotelRooms] = useState([]);
  const { control } = useForm({ mode: 'onBlur' });
  const [searchState, setSearch] = useState({name:''});
  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null });
  const [deleteId, setDeleteId] = useState(null)
  const[option, setOption] = useState([])
  const[countrySelect, setCountrySelect] = useState({name:''})
  const[countryOption, setCountryOption] = useState([])
  const[hotelBookingData, setHotelBookingData] = useState([])

  const hotelIsBooked = (hotelId) =>{
    let roomBooked = false;
    for(let i=0; i<hotelBookingData.length; i++){
       if(hotelBookingData[i].hotelId == hotelId){
         roomBooked = true;
         break;
       }
    }
    return roomBooked;
   }
   
  const openModalPopUp = (id) => { 
    if(!hotelIsBooked(id)){
      modalPopValue.title = 'Delete Hotel';
      modalPopValue.message = 'You want to Delete Hotel';
      modalPopValue.popUpId = 1;
      setModalPopValue({ ...modalPopValue })
      setDeleteId(id)
      setModalPopup(true);
    }else{
      ToastsStore.warning("You are unable to delete this hotel because there are bookings associated with it");
    }
  };

  const deleteHotel = async () => {
    setModalPopup(false)
    setLoading(true);
    await axios.delete(`channelAdmin/deleteHotel/${deleteId}`)
      .then(async(res) =>{ await getEventHotel()
        ToastsStore.success("Hotel delete successfully")
      })
      .catch((err) => console.log(err))
  };

  useEffect(() => {
     getEventHotel(searchState,countrySelect);
  }, [rowsPerPage, page]);


  
  const[manageRooms, setManageRooms] = useState(false)
  const[manageRoomId, setManageRoomId]  = useState(null) 
 
  const createData = (id, name, totalroom, available) => {
    let hotelData = [];

    for (let i = 0; i < hotelRooms.length; i++) {
      if (hotelRooms[i].hotelId === id) {
        hotelData.push({
          roomname: hotelRooms[i].roomName || "",
          totalroom: hotelRooms[i].roomCount || "",
          available: hotelRooms[i].roomCount || "",
        });
      }
    }
    return {
      id,
      name,
      totalroom,
      available,
      history: hotelData,
    };
  };

  const getEventHotel = async (selectHotel=null, selectCountry=null) => {
    
    let data = { 
      page:page,
      limit:rowsPerPage, 
      search: selectHotel,
      country:selectCountry
    }

    setLoading(true)

    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.post('/channelAdmin/getEventHotels',data
    ).then((res) => {
      setHotel(res.data.eventHotels);
      setHotelRooms(res.data.eventHotelRooms);
      setCount(res.data.allHotelData.length);
      setHotelBookingData(res.data.hotelBookingData);
      const options = [...new Set(res.data.allHotelData.map((hotel) => hotel.hotelName))].map((name) => ({
        name: name
      }));
      const countryOptions = [...new Set(res.data.allHotelData.map((hotel) => hotel.country))].map((country) => ({
        name: country
      }));
        
      setOption(options)
      setCountryOption(countryOptions)
      setLoading(false);
    });
  };

  const rows = hotel.map((hotelItem) => createData(hotelItem.id, hotelItem.hotelName, hotelItem.name, hotelItem.mobile));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

   
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setLoading(true)
  };

  

  const expandRow = {
    renderer: row => { 
      const filteredRooms = hotelRooms.filter(item => item.hotelId === row.id);
      if (filteredRooms.length === 0) {
        return (
          <div>
            Rooms Are Not Available
          </div>
        );
      }
  
      // Render the table head only once
      let tableHeadRendered = false;
  
      return (
        <div>
          <table key="table" className="table">
            {filteredRooms.map((item, index) => {
              const shouldRenderTableHead = !tableHeadRendered && index === 0;
              tableHeadRendered = shouldRenderTableHead ? true : tableHeadRendered; // Update tableHeadRendered if shouldRenderTableHead is true
              
              return (
                <React.Fragment key={index}>
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />

                  {shouldRenderTableHead && (
                    <thead>
                      <tr>
                        <th style={{ color:"black"}}>Room Type</th>
                        <th style={{ color:"black"}}>Room Available</th>
                        <th style={{ color:"black"}}>Room Price</th>
                        <th style={{ color:"black"}}>Room Available Date</th>
                        <th style={{ color:"black"}}>Room Note</th>
                      </tr>
                    </thead>
                  )}
                  <tbody key="tbody">
                    <tr>
                      <td>{item.roomName}</td>
                      <td>{item.roomCount}</td>
                      <td>{item.price != null ? `$ ${item.price}` : ''}</td>
                      <td>{item.date ? item.date.substring(0, 10) : null}</td>
                      <td>{item.roomDescription}</td>
                    </tr>
                  </tbody>
                </React.Fragment>
              );
            })}
        </table>
     </div>
   )
    }
  };

  const handleChangeSelect = (input, value)=>{
    if(value == null) {
    setSearch({name:''});
    getEventHotel({name:''}, countrySelect)
    }else{
    setSearch(value);      
    getEventHotel({name:value.name}, countrySelect)
    }
  }


  const handleCountrySelect= (input, value)=>{
    if(value == null) {
    setCountrySelect({name:''});
    getEventHotel(searchState, {name:''})
    }else{
      setCountrySelect(value)
      getEventHotel(searchState,{name:value.name})
    }
  }

  return (
    <>
    {
        loading && <ModalSpinner />  
    }
   
     
     {  loading === false && manageRooms === true && 
 
       <HotelRooms  manageRooms={manageRooms} setManageRooms={setManageRooms} hotelId={manageRoomId} loading={loading} setLoading={setLoading}/>
     }
    
    

        <div style={{display:loading ? "none":"block"}}> 
          {isModalPopup &&
            <Dialog open={isModalPopup} value={modalPopValue} handleSave={deleteHotel} handleClose={() => setModalPopup(false)} >
            </Dialog>
          }
          <ToolkitProvider
            data={hotel}
            keyField="id"
            columns={[
              {
                dataField: "hotelName",
                text: "Hotel",
                sort:true,

                formatter: (cell, row) => (
                  <div style={{display:"flex"}}>
                    <img src={`${process.env.REACT_APP_CDN_URL_TEMP}${row.hotelLogo}`} alt="Hotel Logo" style={{ width: '50px', height: '50px', borderRadius:"50%"}} />
                    <div style={{ marginLeft:"1.5%"}}>
                    <div>{cell}</div>
                    <div style={{marginTop:"2%"}}>{row.address}</div>
                    </div>
                  </div>
                )
                 
              },
              {
                dataField: "name",
                text: "Contact",
                sort:true,
                formatter:(cell, row)=>(
                  <>
                    <div>{cell}</div>
                    <div style={{marginTop:"7%"}}>{row.mobile}</div>
                  </>
                )
              }, 
              {
                dataField:"country",
                sort:true,
                text:"Country"
              },
              {
                dataField:"roomCount",
                text:"Room Count",
                style: { cursor: 'pointer', textDecoration: 'underline', color:'purple' },
                sort:true
              },

              {
                dataField:"id",
                text:"Manage Rooms",
                formatter:(cell, row)=>(
                  <button onClick={()=>{
                    setManageRooms(true)
                    setManageRoomId(row.id)
                  }} id="View150" class="btn btn-icon btn-primary btn-sm" variant="contained" color="primary"><span class="btn-inner--icon"><i class="far fa-eye"></i></span></button>
                ) 
              },

              {
                dataField: "id",
                text: "Action",
                width: 100,
                formatter: (rowContent, row) => (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div onClick={() => {
                      history.push({ pathname: `/admin/hotel/${rowContent}` })
                    }}
                    >
                      <button id="Edit150" class="btn btn-icon btn-primary btn-sm" variant="contained" color="primary"><span class="btn-inner--icon"><i class="fas fa-pencil-alt"></i></span></button>
                    </div>
                    <div onClick={() => openModalPopUp(rowContent)} style={{marginLeft:"19.6%"}}>
                    <button id="Del150" class="btn btn-icon btn-warning btn-sm" variant="contained" color="#fb6340"><span class="btn-inner--icon"><i class="fas fa-trash-alt"></i></span></button>
                    </div>


                  </div>
                )
              },
            ]}
            search
          >
            {props => (
              <div className="table-responsive">
                  <div style={{marginLeft:"2.7%", marginTop:"1.7%"}}>
                      <h3>Manage Hotels</h3>
                    </div>
                <div
                  id="datatable-basic_filter"
                  className="dataTables_filter mb-2"
                  style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: "25px", paddingTop: "10px", paddingBottom: "10px" }}>
                  
                    <div style={{ marginLeft: "10px", marginTop:"1%" }}>
                    
                    
                    <SelectAutocomplete
                            id="search"
                            type="text"                
                            label="Search"
                            name="search"
                            className="d-inline-block"
                            style={{ width:'100%'}}
                            options={option}
                            placeholder="search..."
                            disableCloseOnSelect
                            control={control}                         
                            input='search'
                            value={searchState}
                            handleChangeSelect={handleChangeSelect}
                        /> 
                       

                        <SelectAutocomplete
                        id="eventfilter"
                        type="text"                
                        label="Filter"
                        name="eventfilter"
                        className="d-inline-block"
                        style={{ width:'100%', marginLeft:'16%'}}
                        placeholder="filter events"
                        disableCloseOnSelect
                        control={control}                         
                        input='eventfilter'
                        options={countryOption}
                        value={countrySelect}
                        handleChangeSelect={handleCountrySelect}
                    />  
                 </div>
                  </div>
                  <div style={{ paddingTop: "10px", paddingBottom: "10px", marginRight: "50px" }}>
                    <button onClick={() => history.push({ pathname: '/admin/hotel' })} style={{ float: "right" }} className="btn btn-primary">
                      Add Hotel
                    </button>
                  </div>
                </div>
                <BootstrapTable
                  bordered={false}
                  classes={`table-striped table-hover`}
                  {...props.baseProps}
                  bootstrap4={true}
                  expandRow={ expandRow }
                />
              </div>
            )}
          </ToolkitProvider>
          <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </div> 
    </>

  );
}
