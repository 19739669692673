import React, { useState, useEffect } from "react";
import { Card,CardBody,CardHeader, Container,FormGroup ,Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup } from "reactstrap";
import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import {QuillEditor,FormInput, SingleAutocompleteGrouping,FormRadio,FormAutocomplete,FormAutocompleteGrouping,AddFieldWithPopUpAutocomplete,FormCheckBox} from "_components/FormElements/FormInput";
import MediaLibrary from "views/Hoc/Dialog/MediaLibrary";
import {BrandCard,ProfileCard} from "views/Hoc/BrandCard";
import { history } from '../../../../_helpers';
import { dashboardActions } from '../../../../_actions';
import { TradeInformationSchema } from '../validation';
import AddBrand from "views/Hoc/Dialog/AddBrand";
import AddKeyPartner from "views/Hoc/Dialog/AddKeyPartner";
import {useBeforeFirstRender} from '_hooks/index'
import axios from 'axios';
import ImageLibrary from 'views/Hoc/Dialog/ImageLibrary';
import {ModalSpinner} from 'views/Hoc/Spinner';
import { Spinner} from 'views/Hoc/Spinner';
import {InputLabel,Select,TextField,FormControl,InputAdornment,FormHelperText} from '@material-ui/core';
var _ = require('lodash');

const TradeInformation = (props) => {
  
  let maturitylevel=false;
  const [opneadd, opneAddnew] = useState(false);
  const [openDist, setOpenDist] = useState(false);
  const [openRetailer, setOpenRetailer] = useState(false);
  const [brandName, setBrandName] = useState('');
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, control, errors, watch } = useForm({      
    mode:'onBlur',  
    resolver: yupResolver(TradeInformationSchema),
  });

  const [state, setState] = useState({
    profile: '',
    areYouStartUp : '0',
    no_of_staffs : '',
    startUpType: '',
    sales : null,    
    marketing : null,
    technical : null,
    purchase : null,
    domesticRevenue : null,
    internationalRev : null,
    frenchTech : 'No',
    annualTurnOver : '',
    productBrand : [],
    brand : [],
    brandList:[],
    brandOption : [],
    productGroup:[],
    productValue : [{childid: "4",childname: "AV accessories", groupid: "1", groupname: "AV", status: true}],
    productFamilies : [],
    DistributorProfileOption:[],
    DistributorProfileValue: [],
    Distributor: [],
    DistributorList: [],
    RetailerProfileOption:[],
    Retailer: [],
    RetailerList: [],
    ChannelProfileValue : [{childid: "3",childname: "broadliner",status:true}],
    ChannelProfileOption : [],
    listOfServicesOption : [],
    listOfServiceValue : [],
    groupCount:0,
    no_of_skus : null,
    no_of_distributors: null,
    no_of_retailers: null,
    distributorDesc: '',
    RetailerDesc: '',
    startUpType1: false,
    startUpType2: false,
    startUpType3: false,
    profileTypeId: '',
    RetailerOptions: [],
    DistributorOptions: [],
    testRetailerList: [],
    testDistributorList: [],
    deletedRetailer: [],
    deletedDistributor: [],
    speciality: [],
    specialityOptions: [],
    countryList: [],

    brandListUser: [], //Dont worry about it, its dummy but don't remove it
    RetailerListUser: [],
    DistributorListUser: [],
  })


    //localStorage
    var userData = props.channelData;//JSON.parse(localStorage.getItem('CHuser'));

  //maturity level single or multiple decision making code
  let typeId=userData.channelTypeId;
  if(typeId==3||typeId==4)
    maturitylevel=true;

  useEffect(() => {

    setLoading(true);
    (async() =>{  

      const countryUrl = '/common/getSignup/'+typeId;
      await axios.get(countryUrl).then(res =>{  
        console.log("profiledata:",res.data);          
        state.countryList=res.data.country;
        setState({...state});
      });

      await axios.post('channelAdmin/getInitialProfile', userData).then(res =>{      
        state.productFamilies=res.data.productGroup;

        state.company_regid=res.data.country[0].registrationcertificate;
        state.isVat=res.data.country[0].isVat;
        if(props.channelTypeId==2)
          state.ChannelProfileOption=res.data.profileList;
        if(props.channelTypeId==4 || props.channelTypeId==3)
        {
        let key=['Retailer'];
        state.ChannelProfileOption = res.data.profileList.filter( i => key.includes( i.groupname ) );
        }
        if(props.channelTypeId==3)
        {
          let key=['Distributor'];
          state.DistributorProfileOption = res.data.profileList.filter( i => key.includes( i.groupname ) );
        }
          
    
        state.brandOption=res.data.productBrand;
        state.brandOption.push({name: 'Add Brand', id: 0, doc_id: '0', documentpath: 'https://d35w8j22j5uly8.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'})
        setState({...state, brandOption:state.brandOption});
        setState({...state, productFamilies:state.productFamilies});
        setState({...state, ChannelProfileOption:state.ChannelProfileOption});
        setState({...state, DistributorProfileOption:state.DistributorProfileOption});
        setState({...state, company_regid:state.company_regid});
        setState({...state, isVat:state.isVat});
        
        
      });

      const apiUrl = '/channelAdmin/tradeinfo';
      await axios.post(apiUrl, userData).then(res =>{  
        console.log("profiledata:",res.data);          
        //state.countryList=res.data.country;
        if(maturitylevel && res.data.maturitylevel)
          {
            res.data.maturitylevel.map(x => {
              if(x == 'PROTOTYPE') state.startUpType1 = true;
              if(x == 'S_BACKER') state.startUpType2 = true;
              if(x == 'S_VOLUME') state.startUpType3 = true;
              state.startUpType = 'set';
            })
          }else{
            if(res.data.maturitylevel)
              state.startUpType=res.data.maturitylevel[0];
          }

          state.DistributorOptions = res.data.keyPartners.keyDistributorOptions;
          state.RetailerOptions = res.data.keyPartners.keyRetailerOptions;
          state.DistributorList = res.data.channelKeyDistributor;
          state.RetailerList = res.data.channelKeyRetailer;
          state.listOfServicesOption = res.data.serviceListOptions;
          state.listOfServiceValue = res.data.serviceListValues;
          state.testRetailerList = res.data.channelKeyRetailer.map(x => x.id);
          state.testDistributorList = res.data.channelKeyDistributor.map(x => x.id);
          state.specialityOptions = res.data.specialityOptions.map(x => ({id: x.productGroupId, name: x.productGroup}));
          state.speciality = res.data.channelSpeciality.map(x => ({id: x.productGroup.id, name: x.productGroup.name}));

          if(res.data.profileType.length){
            state.DistributorProfileValue = Object.assign(res.data.profileType[0], {firstLetter: res.data.profileType[0].groupname});
            state.DistributorProfileOption = state.DistributorProfileOption.map(x => {
              if(x.childid == state.DistributorProfileValue.childid)
                return {...x, status: true}
              else
                return x;
            })
          }

          if(res.data.product.length && state.productFamilies.length){
            state.productFamilies = state.productFamilies.map(x => {
              res.data.product.map(y => {
                if(x.groupid == y.groupid && x.childid == y.childid){
                  x.status = true;
                }
              })
              return x;
            })
          }

          setState({...state,brandList:res.data.brand,productValue:res.data.product,ChannelProfileValue: userData.channelTypeId == '4' ? res.data.profile[0] : res.data.profile,
            areYouStartUp:res.data.startup,no_of_skus:typeId=='4'?res.data.storeCount:res.data.no_of_skus,no_of_staffs:res.data.empCnt,annualTurnOver:res.data.turnoverRange,
            domesticRevenue:typeId=='4'?res.data.turnoverOnStore:res.data.revD,internationalRev:typeId=='4'?res.data.onlineStore:res.data.revI,no_of_distributors:res.data.no_of_distributors,
            no_of_retailers:res.data.no_of_retailers,startUpType1:state.startUpType1,startUpType2:state.startUpType2,startUpType3:state.startUpType3,startUpType:state.startUpType,
            profileTypeId: +res.data.profileTypeId,sales: res.data.sales?+res.data.sales:'', marketing: res.data.marketing?+res.data.marketing:'', technical: res.data.technical?+res.data.technical:'',
            purchase: res.data.purchase?+res.data.purchase:'', distributorDesc: res.data.keydistributors, RetailerDesc: res.data.keyretailers,
            frenchTech: res.data.isFrenchTech == '1' ? 'Yes' : 'No',productFamilies: state.productFamilies,
          });
          console.log('state',state);
          setLoading(false);
        
        
      });     
      

    })();
    
  }, []);

  const  isEqual = (a, b) =>
{ 
 
  // if length is not equal 
  if(a.length!=b.length) 
   return false; 
  else
  { 
  // comapring each element of array 
   for(var i=0;i<a.length;i++) 
   if(a[i]!=b[i]) 
    return false; 
    return true; 
  } 
} 

  const deleteBrand = (id) =>{    
    const filteredList = state.brandList.filter((item) => item.id !== id);    
    state.brandList=filteredList;
    setState({...state, brandList:state.brandList});
   if(state.brandList.length==0)
    {
      setState({...state, brandValue: []});
      errors.Brands={message: 'Please select a Brand', type:'required'};  
    }
  }

  const deleteBrand1 = (id) =>{    
    const filteredList = state.DistributorList.filter((item) => {
    
     if(item.id == id && item.keypartnerid){
       let temp = state.deletedDistributor;
       temp.push(+item.keypartnerid);
       setState({...state, deletedDistributor: temp});
     }
     if(item.id == id && item.isNew == '1'){
      state.DistributorListUser = state.DistributorListUser.filter(x => x.id != item.id)
    }
     return item.id !== id
    });    
    state.DistributorList=filteredList;
    setState({...state, DistributorList:state.DistributorList, DistributorListUser:state.DistributorListUser});
   if(state.DistributorList.length==0)
    {
      setState({...state, Distributor: []});
      // errors.Distributor={message: 'Please select Distributor', type:'required'};  
    }
  }

  const deleteBrand2 = (id) =>{    
    const filteredList = state.RetailerList.filter((item) => {
      if(item.id == id && item.keypartnerid)
        {
          let temp = state.deletedRetailer;
          temp.push(+item.keypartnerid);
          setState({...state, deletedRetailer: temp});
        }
        if(item.id == id && item.isNew == '1'){
          state.RetailerListUser = state.RetailerListUser.filter(x => x.id != item.id );
        }
      return item.id !== id
    });    
    state.RetailerList=filteredList;
    setState({...state, RetailerList:state.RetailerList, RetailerListUser:state.RetailerListUser});
   if(state.RetailerList.length==0)
    {
      setState({...state, Retailer: []});
      // errors.Retailers={message: 'Please select a Brand', type:'required'};  
    }
  }


//   useBeforeFirstRender(() => {
//     (async() =>{  
 
//     })();
    
// })

const selectedItem=(gname,check,optionName,optionValue) => {    
  let opt=[];    
  console.log("check:",check,gname);
  if(check==true)
  {    
    state[optionName].map((item) => {      

          if(item.groupname==gname||item.status==true)
          {
            item.status=check;      
            opt.push(item);
          }     
    });
  }
  if(check==false)
  {  
    console.log("check:",check);
    state[optionName].map((item) => {      
        if(item.groupname==gname&&state[optionValue].length>0)
            item.status=check;                          
        // if(state.productValue.length==0)
        //     item.status=false;
    });

    opt=state[optionValue].filter((item) => item.groupname!=gname);      
    opt.map(option => option.status=true);
  }
  
  state[optionValue]=opt;
  setState({...state, [optionValue]:state[optionValue]});    
  setState({...state, [optionName]:state[optionName]});    

  if(optionValue='ChannelProfileValue' && state.ChannelProfileValue.length==0)
        errors.relevantChannelProfiles=true;
  else if(optionValue='productValue' && state.productValue.length==0)
        errors.productCat=true;
}
  
  const watchAll = watch(['no_of_retailers','no_of_distributors','no_of_skus' ]);
  console.log('watchall', watchAll);


  

  const save = async() => {
    setLoading(true);
    let mLevel=[];
    let listOfServices= [];
    if(typeId=='2' && state.areYouStartUp === '1'){
      mLevel.push(state.startUpType);
    }      
    else if((typeId=='3' || typeId=='4') && state.areYouStartUp == '1')
      { 
        if(state.startUpType1) mLevel.push(startUp[0].value);
        if(state.startUpType2) mLevel.push(startUp[1].value);
        if(state.startUpType3) mLevel.push(startUp[2].value);
        listOfServices = state.listOfServiceValue.map(x => ({id: +x.id}));
      }
      console.log('maturity',mLevel);

      let productFamilies = [];
      let uniq = _.uniq(state.productValue.map(x => +x.groupid));
      uniq.map(x => {
        productFamilies.push({id: +x});
      })
      let productCategories = [];
      state.productValue.map(x => {
        productCategories.push({
          productFamilId: +x.groupid,
          productCategoriesId: +x.childid
        })
      })
      
      //for key distributor
      let keyDistributors = [];

      if(isEqual(state.DistributorList.map(x=>x.id).sort(), state.testDistributorList.sort())){
        console.log('no change');
      }else{
        state.DistributorList.map(x => {
          let temp = {};
          if(x.active){
            temp.keypartnerid = x.keypartnerid;   
            temp.partnername = x.name;
            temp.existchannelid = x.id;
            temp.countryid = x.active.country.country_id;
            temp.image = x.documentPath ? x.documentPath : null;
            temp.imageid = x.documentPath ? x.active.logo.logo_id : null;
          }else if(x.old){
            temp.keypartnerid = x.keypartnerid;   
            temp.partnername = x.name;
            temp.oldchannelid = x.id;
            temp.countryid = x.old.country.country_id;
            temp.image = x.documentPath ? x.documentPath : null;
            temp.imageid = x.documentPath ? x.old.logo.logo_id : null;
          }
          else if(x.new){
            temp.keypartnerid = x.keypartnerid;   
            temp.partnername = x.name;
            temp.countryid = x.new.country.country_id;
            temp.image = x.documentPath ? x.documentPath : null;
            temp.imageid = x.documentPath ? x.new.logo.logo_id : null;
          }
          else if(x.isNew!='1'){
            if(x.oldchannelid)
              temp.oldchannelid = x.oldchannelid;
            else if(x.channelId)
              temp.existchannelid = x.channelId;
            
            temp.partnername = x.companyName;
            temp.image = x.companylogo ? x.companylogo.documentPath : null;
            temp.imageid = x.companylogo ? x.companylogo.documentId : null;
            temp.countryid = x.regaddresscountry.countryId;
            
          }
          if(x.isNew == '1')
            {
              
              if(x.documentpath)
                temp.image = x.documentpath;
              
              temp.partnername = x.name;
              temp.countryid = +x.countryId;
            }
          keyDistributors.push(temp);
        })
        console.log('fasle');
      }

      //for key retailer

      let keyRetailer = [];

      if(isEqual(state.RetailerList.map(x=>x.id).sort(), state.testRetailerList.sort())){
        console.log('no change');
      }else{
        state.RetailerList.map(x => {
          let temp = {};
          if(x.active){
            temp.keypartnerid = x.keypartnerid;   
            temp.partnername = x.name;
            temp.existchannelid = x.id;
            temp.countryid = x.active.country.country_id;
            temp.image = x.documentPath ? x.documentPath : null;
            temp.imageid = x.documentPath ? x.active.logo.logo_id : null;
          }else if(x.old){
            temp.keypartnerid = x.keypartnerid;   
            temp.partnername = x.name;
            temp.oldchannelid = x.id;
            temp.countryid = x.old.country.country_id;
            temp.image = x.documentPath ? x.documentPath : null;
            temp.imageid = x.documentPath ? x.old.logo.logo_id : null;
          }
          else if(x.new){
            temp.keypartnerid = x.keypartnerid;   
            temp.partnername = x.name;
            temp.countryid = x.new.country.country_id;
            temp.image = x.documentPath ? x.documentPath : null;
            temp.imageid = x.documentPath ? x.new.logo.logo_id : null;
          }else if(x.isNew!='1'){
            if(x.oldchannelid)
              temp.oldchannelid = x.oldchannelid;
            else if(x.channelId)
              temp.existchannelid = x.channelId;
            
            temp.partnername = x.companyName;
            temp.image = x.companylogo ? x.companylogo.documentPath : null;
            temp.imageid = x.companylogo ? x.companylogo.documentId : null;
            temp.countryid = x.regaddresscountry.countryId;
            
          }if(x.isNew == '1')
          {
            // temp.countryid = x.countryId;
            if(x.documentpath)
              temp.image = x.documentpath;
            
            temp.partnername = x.name;
            temp.countryid = +x.countryId;
          }
          
          keyRetailer.push(temp);
        })
        console.log('fasle');
      }

      let specialityValue = state.speciality.map(x => ({specialityId: +x.id}));

      let data = {};
      if(typeId == '2'){
        data = {
          keydistributors : state.distributorDesc,
          keyretailers : state.RetailerDesc,
          profileTypeId : +state.profileTypeId,
          brand : [].concat(state.brandList.map(x => ({id: +x.id, name: x.name, documentPath: x.documentpath, isNew: x.isNew}))),
          no_of_skus : +watchAll.no_of_skus,
          domesticSales: state.sales?+state.sales:null,
          internationalSales: state.marketing?+state.marketing:null,
          domesticMarketing: state.technical?+state.technical:null,
          internationalMarketing: state.purchase?+state.purchase:null,
          startup : state.areYouStartUp,
          maturitylevel : state.areYouStartUp=="1"?mLevel:[],        
          domesticRevenue: state.domesticRevenue?+state.domesticRevenue:null,
          internationalRevenue: state.internationalRev?+state.internationalRev:null,
          no_of_distributors: watchAll.no_of_distributors?+watchAll.no_of_distributors:state.no_of_distributors,
          no_of_retailers: watchAll.no_of_retailers?+watchAll.no_of_retailers:state.no_of_retailers,
        }  
      }else if(typeId == '3'){
        data = {
          profileTypeId : +state.DistributorProfileValue.childid,
          keyretailers : state.RetailerDesc,
          brand : [].concat(state.brandList.map(x => ({id: +x.id, name: x.name, documentPath: x.documentpath, isNew: x.isNew}))),
          listOfServices : [].concat(state.listOfServiceValue.map(x => ({id: +x.id}))),
          domesticSales: state.sales?+state.sales:null,
          internationalSales: state.marketing?+state.marketing:null,
          domesticMarketing: state.technical?+state.technical:null,
          internationalMarketing: state.purchase?+state.purchase:null,
          startup : state.areYouStartUp,
          maturitylevel : state.areYouStartUp=="1"?mLevel:[],        
          domesticRevenue: state.domesticRevenue?+state.domesticRevenue:null,
          internationalRevenue: state.internationalRev?+state.internationalRev:null,
          no_of_retailers: watchAll.no_of_retailers?+watchAll.no_of_retailers:0,
          ...listOfServices.length && {listOfServices: listOfServices} ,
          ...specialityValue && {distributorSpeciality : specialityValue},
        }  
      }else if(typeId == '4'){
        data = {
          profileTypeId: +state.ChannelProfileValue.childid,
          physicalStore: state.domesticRevenue?+state.domesticRevenue:null,
          OnlineStore:  state.internationalRev?+state.internationalRev:null,
          storeCount: +state.no_of_skus,
          startup:  state.areYouStartUp,
          maturitylevel : state.areYouStartUp=="1"?mLevel:[],        
        }
      }

      //deleted retailer
      if(state.deletedRetailer.length){
        if(state.RetailerList.length){
          state.RetailerList.map(x => {
            if(x.keypartnerid)
              {
                let temp = state.deletedRetailer.filter(id => x.keypartnerid != id)
                setState({...state, deletedRetailer: temp});
              }
          })
        }
      }

      //deleted distributor
      if(state.deletedDistributor.length){
        if(state.DistributorList.length){
          state.DistributorList.map(x => {
            if(x.keypartnerid)
              {
                let temp = state.deletedDistributor.filter(id => x.keypartnerid != id)
                setState({...state, deletedDistributor: temp});
              }
          })
        }
      }

      //key retailer value
      if(keyRetailer.length){
        data = Object.assign(data, {channelKeyRetailer : keyRetailer})
      }

      //key distributor value
      if(keyDistributors.length){
        data = Object.assign(data, { channelKeyDistributor : keyDistributors })
      }

      //delete retailer value
      if(state.deletedRetailer.length){
        data = Object.assign(data, {deletedkeyretailer: state.deletedRetailer})
      }

      //delete distributor value
      if(state.deletedDistributor.length){
        data = Object.assign(data, {deletedkeydistributor: state.deletedDistributor});
      }

      //for vendor
      if(typeId=='2' && state.areYouStartUp=='1' && state.startUpType=='S_VOLUME'){
        
      }
      
      if(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(state.no_of_staffs))
        data['employeesRange'] = state.no_of_staffs
      else
        data['employeesCount'] = +state.no_of_staffs

      if(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(state.annualTurnOver))
        data['annualTurnoverRange'] = state.annualTurnOver
      else
        data['annualTurnover'] = +state.annualTurnOver
        
  let finalData = {
    channelType: +typeId,
    productFamilies: productFamilies,
    productCategories: productCategories,
    customerProfiles: (typeId==4)?[{profileTypeId:+state.ChannelProfileValue.childid,profileTypeClassificationId:+state.ChannelProfileValue.profiletypeclassificationid}]:[].concat(state.ChannelProfileValue.map(x => {return {profileTypeId:+x.childid,profileTypeClassificationId:+x.profiletypeclassificationid} })),
    is_french_tech: state.frenchTech=="Yes" ? '1' : '0',  
    user: {channel: userData, userId: userData.userId, channelId: userData.channelId},
  }
  
     finalData = typeId=='2' ? Object.assign(finalData, {tradeInformationVendor: data}) : typeId == '3' ?  Object.assign(finalData, {tradeInformationDistributor: data}) : Object.assign(finalData, {tradeInformationRetailer: data})

     let result=await dashboardActions.updateTradeInfo(finalData).catch(err=>setLoading(false));
     console.log('saveresuult', result);
     if(result) 
          setLoading(false);
        
    //  history.push('admin/dashboard');
    
      console.log('save', data);
  }
  
  const handleChange = input => e => {
    console.log("add",e.target.value);  
    setState({...state, [input] : e.target.value});
    if(input === 'startUpType1' || input === 'startUpType2' || input === 'startUpType3')
    {      
      state[input] = !state[input];  
      state.startUpType = state.startUpType1?'set':state.startUpType2?'set':state.startUpType3?'set':'';
      setState({...state, startUpType : state.startUpType, [input]: state[input]});  
    }
    // if(input=='areYouStartUp'&&value=='0'){
    //   state.maturitylevel=null;
    //   setState({...state, maturitylevel:state.maturitylevel});
    // }
    // else    
  }

  const handleChangeSelect = (input,value) => {

    if(input=='productFamily'){        
      state.productFamilies.map((item) => {      
        if(value.length==0)
          item.status=false;
        else
        {
          // value.map(v => {          
          //   // if(v.childname!=item.childname)
          //   //   item.status=false;                 
          //    if(v.childname==item.childname)            
          //     item.status=true;                       
            
          // });      
          if(value.filter(e => e.childname == item.childname).length)
              item.status = true;
            else
              item.status = false;
        }
      });
  
      value.map(option => option.status=true);
      state.productValue= value
      setState({...state, productFamilies:state.productFamilies});
      setState({...state, productValue:state.productValue});

      // state.productValue= value
      // setState({...state, productValue:state.productValue});
      if(state.productValue.length==0)
        errors.productValue=true;

      let Group=_.uniq(state.productValue.map(x => x.groupid));
      state.groupCount=Group.length;
      setState({...state,groupCount:state.groupCount});     


      
    }
    else if(input=='relevantChannelProfile'){
      state.ChannelProfileOption.map((item) => {      
        if(value.length==0)
          item.status=false;
        else
        {
          // value.map(v => {          
          //   // if(v.childname!=item.childname)
          //   //   item.status=false;                 
          //    if(v.childname==item.childname)            
          //     item.status=true;                       
            
          // });  
          if(value.filter(e => e.childname == item.childname).length)
              item.status = true;
            else
              item.status = false;    
        }
      });

      value.map(option => option.status=true);
      state.ChannelProfileValue= value
      setState({...state, ChannelProfileOption:state.ChannelProfileOption});
      setState({...state, ChannelProfileValue:state.ChannelProfileValue});

      if(state.ChannelProfileValue.length==0)
        errors.relevantChannelProfiles=true;
      // state.ChannelProfileValue= value
      // setState({...state, ChannelProfileValue:state.ChannelProfileValue});
    }
    else if(input=='relevantRetailerProfile'){
      state.ChannelProfileValue= value
      setState({...state, ChannelProfileValue:state.ChannelProfileValue});
    }
    // else if(input=='listOfServiceValue'){
    //   state.listOfServiceValue= value
    //   setState({...state, listOfServiceValue:state.listOfServiceValue});
    // }   
    

    else if(input=='brand' || input=='brands' || input=='Distributor' || input=='Retailer'){
      if(input=='brands')
        input = 'brand';
      if(value.name === 'Add Brand' && value.id === 0){
        setBrandName('');
        opneAdd(true);
        return <></>;
      }
      if(value.hasOwnProperty('isNew')){
        state[input]=value;
      }else{
        value.isNew='0';
        state[input]=value;
      }
      
      setState({...state, [input]:state[input]});
      let temp = state[input];
      temp = state[input+'List'].filter(x => x.id == temp.id);
      if(temp.length){
        console.log('already entered');
      }else{
        console.log('no entry')
        if(value.isNew == '1')
          state[input+'ListUser'].push(state[input]);
        state[input+'List'].push(state[input]);
        setState({...state, [input+'List']:state[input+'List'], [input+'ListUser'] : state[input+'ListUser']});
      }
      
      console.log("brandList:",state[input+'list']);
    }
    if(input!='productFamily'&&input!='relevantChannelProfile'&& input!='relevantRetailerProfile'&&input!='brand'&&input!='brands'&&input!="Distributor"&&input!='Retailer'){
      setState({...state, [input]:value});
    }
      
  }

  
  const opneAdd = async(input,value) => {   
  console.log('opneadd',opneadd);
  await setBrandName(input);
  opneAddnew(true);
  }  
  const opneAdd1 = async(input,value) => {   
    console.log('opneadd',opneadd);
    await setBrandName(input);
    setOpenDist(true);
    }  
  const opneAdd2 = async(input,value) => {   
  console.log('opneadd',opneadd);
  await setBrandName(input);
  setOpenRetailer(true);
  }  
  const closeModalPopup=()=>{
    setBrandName('');
    opneAddnew(false);
    setOpenRetailer(false);
    setOpenDist(false);
  }
  
console.log('satet', state);
//console.log('watchAll', watchAll)

const onSubmit = data => {    
if (data) {        
    console.log("data:",data);
}    
}
console.log('errrors', errors);




return (
<div className="main-content">
{loading? <Spinner />:(

        <Container className="mt-4 col-lg-10 col-md-9 col-sm-12  " fluid>
        <form noValidate onSubmit={handleSubmit(save)}>
        {loading && <div style={{ top: '50%', left: '50%', zIndex: '1000',}}><ModalSpinner /></div>}
        <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="typeId" ref={register} name="typeId" value={typeId} control={control} />

 {/* ---------------------------Trade information information------------------------------           */}
                <Card>
                  <CardBody>
                  {typeId == 3 && 
 
  <div className="col-lg-12 ">
                  <h4 >{typeId==3 && 'Distributor Profile and speciality *'}</h4>
            <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="profile" name="profile" ref={register} value={Object.keys(state.DistributorProfileValue).length?state.DistributorProfileValue.childname!='broadliner' ? '1' : '0' : ''} /> 
            <div className="form-group">        
            <FormControl variant="outlined" style={{width:"100%"}}>
            <SingleAutocompleteGrouping
              id="DistributorProfiles"
              name="DistributorProfiles"                        
              label="Distributor Profiles * "                            
              multiple={false}
              Options={state.DistributorProfileOption}
              placeholder="Channel Profiles"                        
              input='DistributorProfileValue'              
              errMessage={'Please Select Distributor Profile'}
              errMessage='Please Select Distributor Profile'
              error={(!errors.profile || Object.keys(state.DistributorProfileValue).length) ? false : true}
              value={state.DistributorProfileValue}
              defaultValue={state.DistributorProfileValue}
              handleChangeSelect={handleChangeSelect}
              control={control}
              register={register}
              />
            </FormControl>
                 </div>           
            </div>  
  }
  
  {(typeId==3 && Object.keys(state.DistributorProfileValue).length != 0 && state.DistributorProfileValue.childname!='broadliner') && (
  <div className="col-lg-12 ">
                  <h4 >Speciality *</h4>
            <div className="form-group">        

          <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="speciality" name="speciality" ref={register} value={state.speciality.length?state.speciality[0].name:''} /> 
          <FormControl variant="outlined" style={{width:"100%"}}>
                  <FormAutocomplete
                        id="speciality1"
                        name="speciality1"
                        label="Search Speciality"
                        options={state.specialityOptions}
                        placeholder="Select Speciality"
                        input='speciality'
                        errMessage={'Please Select a Speciality'}
                        error={!errors.speciality || state.speciality.length ?false:true}
                        value={state.speciality}
                        defaultValue={state.speciality}
                        handleChangeSelect={handleChangeSelect}
                        control={control}                 
                        multiple
                        disableCloseOnSelect
                        blurOnSelect
                        getOptionSelected={(options, value) => options.name === value.name}
                        />
            </FormControl>
            </div>           
            </div>                      
         )} 


     
          {(typeId==2 || typeId==3) && (
            
            <div className="col-lg-12 ">
            <h4 >Brands *</h4>
            <div className="form-group mt-3">        
           
            {state.brandList[0] && <BrandCard  name="BMW" deleteBrand={deleteBrand} brandList={state.brandList}  img={"https://d35w8j22j5uly8.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"}/>}

            <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="brandList" name="brandList" ref={register} value={state.brandList[0] ? 'data' : ''} /> 
{/* Autocomplete with image */}
<AddFieldWithPopUpAutocomplete
        limitTags={2}
        name="Brands"
        id="Brands"
        options={state.brandOption}
        defaultValue={state.brandValue}
        value={state.brandValue}
        getOptionLabel={option => option.name}
        renderOption={option => (
         <>
         <div style={{display: '-webkit-inline-flex',
    padding: '1px 6px',
    width: '75px',
    margin: '10px 5px 0 0',
    float: 'left',
    border: '2px solid #e7e7e7',
    borderRadius: '5px',
    height: '38px'}}>

            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  style={{maxWidth: '63px',backgroundColor: '#fff',
    maxHeight: '34px',
    height: 'auto',
    width: 'auto',
    margin: '0 auto',
    cursor: 'pointer'}} src={option.documentpath} />    </div>
          <span>
            {option.name}
          </span>
          </>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Brands" placeholder="Brands" />
        )}
        label="Brands *"
        placeholder="Brands"
        input="brand"
        control={control}
        register={register}
        errMessage={'Please Select a Brand'}
        error={errors.Brands&&!state.brandList[0]? errors.Brands : (errors.brandList && !state.brandList[0]) ? true : false}
        handleChangeSelect={handleChangeSelect}
        opneAdd={opneAdd}
      />
      {brandName && <AddBrand aws_folderName="brandlogo" opneadd={opneadd} closeModalPopup={closeModalPopup} brandName={brandName} handleChangeSelect={handleChangeSelect}/>}

                 </div>           
            </div>

            )}

{(typeId==4) && (
            <div className="col-lg-12 ">
                  <h4 >Retailer Profiles *'</h4>
            <div className="form-group">        
            <SingleAutocompleteGrouping
              id="relevantProfiles"
              name="relevantRetailerProfiles"
              label="Retailer Profiles * "
              multiple={false}
              Options={state.ChannelProfileOption}
              placeholder="Channel Profiles"                        
              input='relevantRetailerProfile'              
              errMessage={'Please Select Retailer Profiles'}
              error={errors.relevantRetailerProfiles }
              value={state.ChannelProfileValue}                    
              handleChangeSelect={handleChangeSelect}
              control={control}
              register={register}
              defaultValue={state.ChannelProfileValue}
              />
            
                 </div>           
            </div>

            )}
        
{/* {((typeId==2 || typeId==3)) && ( */}
<div className="col-lg-12 ">
                  <h4 >Select Product Families *</h4>
            <div className="form-group">        

          <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="productCat" name="productCat" ref={register} value={state.productValue.length?state.productValue[0].childname:''} /> 
                  <FormAutocompleteGrouping
                        id="ProductGroup"
                        name="ProductGroup"
                        label="Product Families are you selling"
                        Options={state.productFamilies}
                        placeholder="Select Product Families"
                        input='productFamily'
                        errMessage={'Please Select a Product'}
                        error={!errors.productCat || state.productValue.length ?false:true}
                        //error={errors.ProductGroup}
                        //defaultValue={productFamilies}
                        value={state.productValue}

                        optionName='productFamilies'
                        optionValue='productValue'
                        defaultValue={state.productValue}
                        selectedItem={selectedItem}

                        handleChangeSelect={handleChangeSelect}
                        control={control}
                        register={register}                        
                        />
            </div>           
            </div>                      
        {/* )} */}

            {(typeId==2) && (
            <div className="col-lg-12">
            <h4>{typeId==2?"No of SKU's":"No of Store's"} *</h4>
                  <h5  >{typeId==2?'Enter the number of products that you handle':'Enter the number of stores that you handle'}</h5>
            <div className="form-group">    
          <FormInput
              id="No of SKU's"
              type="number"
              name="no_of_skus"
              label={typeId==2?"No of SKU's *":"No of Store's *"}
              InputProps={{
                inputProps: { 
                    max: 100, min: 10 
                }
                }}
              value={state.no_of_skus}
              onChange={handleChange('no_of_skus')}
              register={register}
              defaultValue={state.no_of_skus}
              value={watchAll.no_of_skus}
              error={errors.no_of_skus}
          /> 
        </div>
        </div>

)}        
          </CardBody>
          </Card>

              <Card    >                   
              {/* <CardHeader>
                 <h3 className="text-center">Facts and Figures</h3>
                  <p className="text-center">Tell us about your company legal informations</p>
                  </CardHeader> */}
                  <CardBody  >
                     
                  
            <span style={{color: '#f44336',float: 'right', fontSize:'0.75rem'}}>{!errors.startUpType || (state.areYouStartUp=='1' && state.startUpType) || state.areYouStartUp=='0' ? '' : 'Please Choose Maturity Level' }</span>
              <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12  row ">
            <div className="form-group text-center text-sm mb-0"> 
            <h4>Are you a startup * </h4>       
            <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="areYouStartUp" name="areYouStartUp" ref={register} value={state.areYouStartUp} /> 
            <FormRadio
                    name="startup"
                    className="text-sm mb-0"
            
                    options={radioGroupOptions}
                    control={control}
                    row
                    
                    value={state.areYouStartUp} 
                    onChange={handleChange('areYouStartUp')}
                     />
                 </div>           
            </div>

            

         {(state.areYouStartUp == '1' && (typeId == '3'|| typeId == '4')) &&  <div className="col-lg-4 col-md-6 col-sm-12">
           <h4>What type of start-up you would like to see?</h4>
                    <div className="chck">
          <FormCheckBox
                    id="Prototype"
                    type="checkbox"
                    label="Prototype"
                    label1={{textAlign:"justify"}}
                    //register={register}
                    checked={state.startUpType1}
                    onClick={handleChange('startUpType1')}
                    value={state.startUpType1}
                    /></div>
                    
                    <div className="chck">
          <FormCheckBox
                    id="Shipping to first backers"
                    type="checkbox"
                    label="Shipping to first backers"
                    label1={{textAlign:"justify"}}
                    //register={register}
                    checked={state.startUpType2}
                    onClick={handleChange('startUpType2')}
                    value={state.startUpType2}
                    /></div>

      <div className="chck">
          <FormCheckBox
                    id="Shipping in volume"
                    type="checkbox"
                    label="Shipping in volume"
                    label1={{textAlign:"justify"}}
                    //register={register}
                    checked={state.startUpType3}
                    onClick={handleChange('startUpType3')}
                    value={state.startUpType3}
                    /></div>
                      
    </div>}

    {(state.areYouStartUp == '1' && typeId=='2') && <div className="col-lg-4 col-md-6 col-sm-12">
           <h4>What type of start-up you would like to see?</h4>

           <FormRadio
                    name="startup"
                    className="text-sm mb-0"
            
                    options={startUp}
                    control={control}
                    row
                    
                    value={state.startUpType} 
                    onChange={handleChange('startUpType')}
                     />
            </div>}
     
    </div>
    <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="startUpType" name="startUpType" ref={register} value={state.startUpType} /> 
    { ((typeId=='2' && state.areYouStartUp == '1' && state.startUpType=='S_VOLUME') || (typeId == '2' && state.areYouStartUp == '0') || (typeId == '3')) && <>
<div className="row">
<div className="col-lg-12 ">
  {/* Key Distributors */}

  {typeId == '2' && <><div className="col-lg-4 col-md-6 col-sm-12">
  <h4 >No.of Distributors </h4>
    <TextField className="comp-name mt-3" id="no_of_distributors" name="no_of_distributors" label="No. of Distributors *" type="number" variant="outlined" value={watchAll.no_of_distributors}
    error={errors.no_of_distributors}
    defaultValue={state.no_of_distributors}
    onChange={handleChange('no_of_distributors')}
    inputRef={register}
    />
    </div>
     <br/>
        
<div className="row">
<div className="col-lg-12 ">
            <h4 >Key Distributors </h4>
            <div className="form-group mt-3">        
           
            {state.DistributorList[0] && <BrandCard  name="BMW" deleteBrand={deleteBrand1} brandList={state.DistributorList}  img={"https://d35w8j22j5uly8.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"}/>}

            {/* <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="DistributorList" name="DistributorList" ref={register} value={state.DistributorList[0] ? 'data' : ''} />  */}
{/* Autocomplete with image */}
<AddFieldWithPopUpAutocomplete
        limitTags={2}
        name="Distributor"
        id="Distributor"
        options={state.DistributorOptions}//{state.DistributorProfileOption}
        defaultValue={state.Distributor}
        value={state.Distributor}
        getOptionLabel={option => option.name}
        renderOption={option => (
         <>
         <div style={{display: '-webkit-inline-flex',
    padding: '1px 6px',
    width: '75px',
    margin: '10px 5px 0 0',
    float: 'left',
    border: '2px solid #e7e7e7',
    borderRadius: '5px',
    height: '38px'}}>

            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  style={{maxWidth: '63px',backgroundColor: '#fff',
    maxHeight: '34px',
    height: 'auto',
    width: 'auto',
    margin: '0 auto',
    cursor: 'pointer'}} src={option.documentpath} />    </div>
          <span>
            {option.name}
          </span>
          </>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Key Distributors" placeholder="Key Distributors" />
        )}
        label="Key Distributors"
        placeholder="Key Distributors"
        input="Distributor"
        control={control}
        register={register}
        // errMessage={'Please Select  Key Distributors'}
        // error={errors.Distributor&&!state.DistributorList[0]? errors.Distributor : (errors.DistributorList && !state.DistributorList[0]) ? true : false}
        handleChangeSelect={handleChangeSelect}
        opneAdd={opneAdd1}
      />
      {brandName && <AddKeyPartner aws_folderName="brandlogo" opneadd={openDist} closeModalPopup={closeModalPopup} brandName={brandName} handleChangeSelect={handleChangeSelect} partner_type={3} input="Distributor" countryList={state.countryList}  userList={state.DistributorListUser} channelId={userData.channelId}/>}

                 </div>           
            </div>
</div>
<div className="row">
    <div className="col-lg-12">
    <div className="form-group">            
<form>
  <textarea 
  class="qtytext" 
  id="exampleFormControlTextarea1" 
  onChange={handleChange('distributorDesc')}
  rows="3" 
  value={state.distributorDesc}
  aria-label="empty textarea"
  placeholder="Others Key Distributors Description">
  </textarea>
</form> 
             </div>
    </div>
</div></>}

  {/* Key Retailers */}

  <div className="col-lg-4 col-md-6 col-sm-12">
  <h4 >No.of Retailers </h4>
    <TextField className="comp-name mt-3" name="no_of_retailers" id="no_of_retailers" label="No. of Retailers *" type="number" variant="outlined" value={watchAll.no_of_retailers}
    onChange={handleChange('no_of_retailers')}
    defaultValue={state.no_of_retailers}
    error={errors.no_of_retailers}
    inputRef={register}
    />
    </div>
    <br />
            <h4 >Key Retailers </h4>
            <div className="form-group mt-3">        
           
            {state.RetailerList[0] && <BrandCard  name="BMW" deleteBrand={deleteBrand2} brandList={state.RetailerList}  img={"https://d35w8j22j5uly8.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"}/>}

            {/* <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="retailerList" name="retailerList" ref={register} value={state.RetailerList[0] ? 'data' : ''} />  */}
{/* Autocomplete with image */}
<AddFieldWithPopUpAutocomplete
        limitTags={2}
        name="Retailers"
        id="Retailers"
        options={state.RetailerOptions} //{state.RetailerProfileOption}
        defaultValue={state.Retailer}
        value={state.Retailer}
        getOptionLabel={option => option.name}
        renderOption={option => (
         <>
         <div style={{display: '-webkit-inline-flex',
    padding: '1px 6px',
    width: '75px',
    margin: '10px 5px 0 0',
    float: 'left',
    border: '2px solid #e7e7e7',
    borderRadius: '5px',
    height: '38px'}}>

            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  style={{maxWidth: '63px',backgroundColor: '#fff',
    maxHeight: '34px',
    height: 'auto',
    width: 'auto',
    margin: '0 auto',
    cursor: 'pointer'}} src={option.documentpath} />    </div>
          <span>
            {option.name}
          </span>
          </>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Retailers" placeholder="Retailers" />
        )}
        label="Key Retailers"
        placeholder="Key Retailers"
        input="Retailer"
        control={control}
        register={register}
        // errMessage={'Please Select a Key Retailers'}
        // error={errors.Retailers&&!state.RetailerList[0]? errors.Retailers : (errors.retailerList && !state.RetailerList[0]) ? true : false}
        handleChangeSelect={handleChangeSelect}
        opneAdd={opneAdd2}
      />
      {brandName && <AddKeyPartner aws_folderName="brandlogo" opneadd={openRetailer} closeModalPopup={closeModalPopup} brandName={brandName} handleChangeSelect={handleChangeSelect} partner_type={4} input="Retailer" countryList={state.countryList}  userList={state.RetailerListUser} channelId={userData.channelId}/>}

                 </div>           
            </div>
</div>
<div className="row">
    <div className="col-lg-12">
    <div className="form-group">            
<form>
  <textarea 
  class="qtytext" 
  id="exampleFormControlTextarea1" 
  onChange={handleChange('RetailerDesc')}
  rows="3" 
  value={state.RetailerDesc}
  aria-label="empty textarea"
  placeholder="Others Key Retailers Description">
  </textarea>
  </form> 
             </div>
    </div>
</div>
</>}

{(typeId == '2' || typeId == '3') && 
  <div className="col-lg-12 ">
                  <h4 >Retail Customer Profile *</h4>
            <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="relevantChannelProfiles" name="relevantChannelProfiles" ref={register} value={state.ChannelProfileValue.length?state.ChannelProfileValue[0].childname:''} /> 
            <div className="form-group">        
            <FormAutocompleteGrouping
              id="relevantProfiles"
              name="relevantProfiles"
              label="Channel Profiles * "
              multiple={true}
              Options={state.ChannelProfileOption}
              placeholder="Channel Profiles"                        
              input='relevantChannelProfile'              
              errMessage={'Please Select Channel Profile'}
              error={!errors.relevantChannelProfiles || state.ChannelProfileValue.length ?false:true}
              value={state.ChannelProfileValue}                            

              optionName='ChannelProfileOption'
              optionValue='ChannelProfileValue'
              defaultValue={state.ChannelProfileValue}
              selectedItem={selectedItem}

              handleChangeSelect={handleChangeSelect}
              control={control}
              register={register}
              //defaultValue={state.ChannelProfileValue}
              />
            
                 </div>           
            </div>
}
            { typeId=='3' && <div className="col-lg-12 ">
                  <h4 >List of Services</h4>
            <div className="form-group">        
            <FormAutocomplete
              id="ListofServices"
              name="ListofServices"
              label="List of Services"
              multiple={true}
              options={state.listOfServicesOption}
              placeholder="List of Services"                        
              input='listOfServiceValue'              
              errMessage={'Please Select List of Services'}
              error={errors.ListOfServices }
              value={state.listOfServiceValue}                    
              handleChangeSelect={handleChangeSelect}
              control={control}
              register={register}
              // defaultValue={state.listOfServiceValue}
              />
            
                 </div>           
            </div>}
            </CardBody>
            </Card>
<Card>
    <CardBody>
<div className="row" > 
 <div className="col-lg-8">
 <h4  className="text-sm mb-0" >No of Staffs *{!errors.no_of_staffs || state.no_of_staffs ? '' : <span style={{color: '#f44336', fontSize:'0.75rem'}}>   Please Select or Enter No Of Employees</span>}</h4>
 <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="no_of_staffs" ref={register} name="no_of_staffs" value={state.no_of_staffs} control={control} />
 <div className="row" > 
    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm" >
           <input className={state.no_of_staffs==='1-10' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md" }
                  type="button" id="emprange" name="emprange"  
                  value="1-10" onClick={handleChange('no_of_staffs')} />
    </div>
    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm"  >
        <input className={state.no_of_staffs==='11-15' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md" } 
                  type="button" id="emprange" name="emprange" 
                  value="11-15" onClick={handleChange('no_of_staffs')} />
    </div> 
    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm" >
        <input className={state.no_of_staffs==='51-100' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md" } 
                  type="button" id="emprange" name="emprange" 
                  value="51-100" onClick={handleChange('no_of_staffs')} />
    </div>
    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm" >
         <input className={state.no_of_staffs==='100-Above' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md" }
                  type="button" id="emprange" name="emprange" 
                  value="100-Above" onClick={handleChange('no_of_staffs')} />
    </div>
    </div> 
      </div>
    <div className="col-lg-4 col-sm-12 mt-4 "> 
    <div className="form-group  mt-2"> 
                     
                     <FormInput 
                         id="empcnt"
                         type="number"
                         name="empcnt"
                         label="Enter Custom Value *"
                         placeholder="Enter Your Value"
                         InputProps={{
                          startAdornment: <InputAdornment position="start"></InputAdornment>
                        }}
                         value={state.no_of_staffs}
                         onChange={handleChange('no_of_staffs')}
                     />
              </div>
              </div>
          </div>
          { (typeId == '2' || typeId == '3') && <> 
          <p className="ml-3">Please specify the breakdown of your employees in the below categories.</p>
          <div style={{padding:"15px 15px 35px",backgroundColor:"#f7fafc"}}>
          <div className="row">
              <div className="col-lg-3">
                    <h4>Sales</h4>
                    <FormInput className="comp-name" name="sales"  variant="outlined" label="Sales" placeholder="" value={watchAll.sales}
                    defaultValue={state.sales}
                    register={register}
                    error={errors.sales}
                    onChange={handleChange('sales')}
                    type="number"
                    />
              </div>
              <div className="col-lg-3">
                    <h4>Marketing</h4>
                    <FormInput className="comp-name" name="marketing" variant="outlined" label="Marketing" placeholder="" value={watchAll.marketing}
                    defaultValue={state.marketing}
                    register={register}
                    error={errors.marketing}
                    onChange={handleChange('marketing')}
                    type="number"
                    />
              </div>
              <div className="col-lg-3">
                    <h4>Technical</h4>
                    <FormInput className="comp-name" name="technical"  variant="outlined" label="Technical" placeholder="" value={watchAll.technical}
                    onChange={handleChange('technical')}
                    defaultValue={state.technical}
                    register={register}
                    error={errors.technical}
                    type="number"
                    />
              </div>
              <div className="col-lg-3">
                    <h4>Purchase</h4>
                    <FormInput className="comp-name" name="purchase"  variant="outlined" label="Purchase" placeholder="" value={watchAll.purchase}
                    defaultValue={state.purchase}
                    register={register}
                    error={errors.purchase}
                    onChange={handleChange('purchase')}
                    type="number"
                    />
              </div>
          </div>
          </div></>}
<div className="row ">
<div className="col-lg-8 col-md-8 mt-3">
<h4 className="text-sm mb-0">Annual Turnover *{!errors.annualTurnOver || state.annualTurnOver ? '' : <span style={{color: '#f44336', fontSize:'0.75rem'}}>   Please Select or Enter Annual Turnover</span>}</h4>   
<input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="annualTurnOver" name="annualTurnOver" ref={register} value={state.annualTurnOver} /> 
<div className="row "> 
    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm ">
        <input className={state.annualTurnOver==='<1M€' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}                   type="button" id="emprange" name="emprange"
                  value="<1M€"  onClick={handleChange('annualTurnOver')}/></div>
  <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm ">
        <input className={state.annualTurnOver==='1-10M€' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"} 
                  type="button" id="emprange" name="emprange"
                  value="1-10M€"  onClick={handleChange('annualTurnOver')}/></div>
  <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm  ">
        <input className={state.annualTurnOver==='10-100M€' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"} 
                  type="button" id="emprange" name="emprange"
                  value="10-100M€" onClick={handleChange('annualTurnOver')}/></div>
 <div className="col-lg-3 col-md-3 col-xs-3 mt-3 pl-4 text-center res-sm  ">
        <input className={state.annualTurnOver==='>100M€' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"} 
                  type="button" id="emprange" name="emprange"
                  value=">100M€" onClick={handleChange('annualTurnOver')}/></div>
   </div>
    </div>

  
  
                <div className="col-lg-4 col-md-4 col-xs-3 mt-5 ">
         <div className="form-group "> 
                     
                     <FormInput 
                         id="empcnt"
                         type="number"
                         name="empcnt"
                         label="Enter Value in M€"
                         InputProps={{
                           startAdornment: <InputAdornment position="start">M€</InputAdornment>
                         }}
                         value={state.annualTurnOver}
                         onChange={handleChange('annualTurnOver')}
                     />
              </div>
              </div>
                </div>
                <input type="number" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="revenue" name="revenue" ref={register} value={(+state.domesticRevenue)+(+state.internationalRev)} /> 
                <p className="ml-3">Please specify the breakdown of your revenue in the below categories.<span style={{color: '#f44336', float: 'right', fontSize:'0.75rem'}}>{
                errors.revenue?typeId=='4'?'The total of Physical Store and Online Store should not exceed 100 %' : errors.revenue.message : ''
                  
              }</span></p>
                <div style={{padding:"15px 15px 35px",backgroundColor:"#f7fafc"}}>
                <div className="row">
                <div className="col-lg-4">
                    <h4>{typeId == '2' || typeId == '3' ? 'Domestic Revenue in %' : 'Physical Store in %' }</h4>
                    <FormInput className="comp-name" name="domestic" variant="outlined" label="Enter Value" placeholder="" value={watchAll.domestic}
                    onChange={handleChange('domesticRevenue')}
                    defaultValue={state.domesticRevenue}
                    register={register}
                    inputProps={{ min: "0", max: "100", step: "1", maxlength: 3, pattern: '^[1-9][0-9]?$|^100$', inputMode: 'numeric' }}
                    error={errors.domestic}
                    type="number"
                    />
              </div>
              <div className="col-lg-4">
                    <h4>{typeId == '2' || typeId == '3' ? 'International Revenue in %' : 'Online Store in %'}</h4>
                    <FormInput className="comp-name" name="interRev"  variant="outlined" label="Enter Value" placeholder="" 
                    onChange={handleChange('internationalRev')}
                    inputProps={{ min: "0", max: "100", step: "1", maxlength: 3, pattern: '^[1-9][0-9]?$|^100$', inputMode: 'numeric' }}
                    value={watchAll.interRev}
                    defaultValue={state.internationalRev}
                    error={errors.interRev}
                    register={register}
                    type="number"
                    
                    />
              </div>
              
              {typeId=='4' && 
                <div className="col-lg-4">
              <h4>No. of Stores</h4>
              <FormInput
              id="No of SKU's"
              type="number"
              name="no_of_skus"
              label={typeId==2?"No of SKU's *":"No of Store's *"}
              InputProps={{
                inputProps: { 
                    max: 100, min: 10 
                }
                }}
              value={state.no_of_skus}
              onChange={handleChange('no_of_skus')}
              register={register}
              defaultValue={state.no_of_skus}
              value={watchAll.no_of_skus}
              error={errors.no_of_skus}
          /> 
              </div>}
                </div>
                </div>
</CardBody>
</Card>
{typeId=='2' || typeId == '3' && <Card>
    <CardBody>
    <div className="row ">
    <div className="col-lg-8">
    <h4>French Tech label</h4>
    <div className="row ">
    <div className="col-lg-2" style={{textAlign:"-webkit-center"}}>
        <div className="ftl-img">
            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://d35w8j22j5uly8.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"/>
        </div>
    </div>
    <div className="col-lg-5" style={{padding: "1.5rem 0px"}}>
        <h4>Are you part of La French Tech community?</h4>
    </div>
    <div className="col-lg-5" style={{padding: "1rem"}}>
    <div className="text-center">
        <input type='button'  className={state.frenchTech==='Yes' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"} value='Yes' onClick={handleChange('frenchTech')}/> 
        <input type='button'  className={state.frenchTech==='No' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"} value='No' onClick={handleChange('frenchTech')}/>
          </div> 
    </div>
    </div>
    </div>
    </div>
    
    </CardBody>
</Card>}
        <div className="text-center">
        <button type="submit" className="btn btn-primary" >Save</button> 
          </div> 
          </form>
          
              </Container>
)}
      </div>
    
   
  );
};

const top100Films = [
  { name: 'The Shawshank Redemption', id: 1994 },
  { name: 'The Godfather', id: 1972 },
  { name: 'The Godfather: Part II', id: 1974 },
  { name: 'The Dark Knight', id: 2008 },
  { name: 'The Shawshank Redemption', id: 1994 },
  { name: 'The Godfather', id: 1972 },
  { name: 'The Godfather: Part II', id: 1974 },
  { name: 'The Dark Knight', id: 2008 },
  { name: 'The Shawshank Redemption', id: 1994 },
  { name: 'The Godfather', id: 1972 },
  { name: 'The Godfather: Part II', id: 1974 },
  { name: 'The Dark Knight', id: 2008 },
]

const startUp = [
  {label : 'Prototype', id: 1, value: 'PROTOTYPE'},
  {label : 'Shipping to first backers', id: 2, value: 'S_BACKER'},
  {label : 'Shipping in volume', id: 3, value: 'S_VOLUME'},
];


const radioGroupOptions = [
  {
    value: "1",
    label: "Yes",
  },
  {
    value: "0",
    label: "No",
  },
 
];

export default TradeInformation;
