import { dashboardConstants } from '../_constants';
import { dashboardService } from '../_services';
import { alertActions } from './';
//import { history } from '../_helpers';


export const dashboardActions = {
    dashboard_count,
    updateCompanyInfo,
    updateTradeInfo,
    updateSettingInfo,
   // logout,
//    register,
  //  getAll,
    //delete: _delete
};


function dashboard_count() {


   return dispatch => {
        dispatch(request());

      dashboardService.dashboard_count()
            .then(               
                   // console.log("result:",count.data),
                    count => dispatch(success(count)),
                error => dispatch(failure(error.toString()))
              
            );
    };

    function request() { return { type: dashboardConstants.COUNT_REQUEST } }
    function success(count) { return { type: dashboardConstants.COUNT_SUCCESS, count } }
    function failure(error) { return { type: dashboardConstants.COUNT_FAILURE, error } }
}

// function logout() {
//     userService.logout();
//     return { type: userConstants.LOGOUT };
// }

function updateCompanyInfo(company) {
  console.log("company:",company);
  return dashboardService.update_company_profile_save(company.data);
}

function updateTradeInfo(product){
  console.log('updateTradeInfo', product);
  return dashboardService.updateTradeInfo(product);
}

function updateSettingInfo(data){
  //console.log('updateTradeInfo', product);
  return dashboardService.updateSettingInfo(data);
}