import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Card, Container, Row, CardHeader, UncontrolledTooltip, ListGroupItem,
  ListGroup, Col, Badge, Button
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import OldFilter from "components/Filter-Right/OldFilter.js";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useForm } from "react-hook-form";
import { SelectAutocomplete } from '_components/FormElements/FormInput';
import axios from 'axios';
import { Spinner } from '../../Hoc/Spinner'
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/PaginationActions';
import Dialog from 'views/Hoc/Dialog/GlobalDialog';
import NotificationAlert from "react-notification-alert";
import UserView from 'views/Hoc/Dialog/UserView';
import Drawer from 'views/Hoc/Dialog/Globaldrawer';
import Connect from 'views/Hoc/Dialog/Connect';
import { history } from '_helpers/history';
import CompanyPublicSettings from 'views/Hoc/Dialog/CompanyPublicSettings';
import { commonService } from '_services/common.service';
const toggleSidenav = e => {
  console.log(e)

  if (document.getElementById('filter').classList.contains("g-sidenav-pinned")) {
    document.getElementById('filter').classList.remove("g-sidenav-pinned");
    document.getElementById('filter').classList.add("g-sidenav-hidden");
  } else {
    document.getElementById('filter').classList.add("g-sidenav-pinned");
    document.getElementById('filter').classList.remove("g-sidenav-hidden");
  }
  // function handleOrangeClick({
  //  sidenavOpen: setOpen(!sidenavOpen)
  // });
};
const company_type_Option = [
  { name: "Vendor", id: 2 },
  { name: "Distributor", id: 3 },
  { name: "Retailer", id: 4 },
  { name: "Reseller", id: 5 }
];
const is_OEM_Option = [
  { name: "Yes", id: 'yes' },
  { name: "No", id: 'no' },
]
export const OldChannelListing = (props) => {
  const notificationAlert = useRef(null);
  const { control } = useForm({ mode: 'onBlur' });
  const [showDrawer, setshowDrawer] = useState(false);
  const [connect, setshowconnect] = useState(false);

  const [contactid, setContact] = useState();

  const [user, setUser] = useState({ firstName: '', lastName: '', jobTitle: '', country: '' });

  const [state, setState] = useState({ dataTable: [], total: 0 });
  const [searchState, setSearch] = useState({ search: null, dropdownList: [] });
  const [filterValueState, setFilterValue] = useState({ Company_type: null, is_OEM: null, country: [], plan: [], product_Categories: [], product_families: [], brand: [], dist_profile: [], reseller_profile: [], retailer_profile: [] });
  const [filterOptionState, setFilterOption] = useState({ countryOption: [], product_cat_Option: [], plan_Option: [], company_type_Option: [], is_OEM_Option: is_OEM_Option, product_family_option: [], brand_Option: [], distProfile_Option: [], reseller_Option: [], retailer_Option: [] });

  let [loading, setLoading] = useState(true);
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [open, setModalOpen] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null });
  let adminUser = JSON.parse(localStorage.getItem('CHuser'));
  const [drawerInput, setDrawerInput] = useState(null);

  useEffect(() => {
    if (adminUser.accessId == 3 || adminUser.accessId == 4)
      filterOptionState.company_type_Option = company_type_Option.filter(i => [3, 4, 5].includes(i.id));
    else
      filterOptionState.company_type_Option = company_type_Option;

    setFilterOption({ ...filterOptionState, company_type_Option: company_type_Option });

    getPendingChannel();
    getPendingChannelsList(3);
    getCountryList();
    getProductFamily();
    getProductCat();
    getOldBrandOption();
    getOldProfileOption();
    // getPlanList();
  }, []);

  const getCountryList = () => {
    axios.post('/channelAdmin/getFilterCountry', {
      signupstatusid: [14, 15],
      userId: adminUser.userId
    }).then(res => {
      filterOptionState.countryOption = res.data;
      setFilterOption({ ...filterOptionState, countryOption: filterOptionState.countryOption });
    })
  }
  const getProductFamily = () => {
    axios.post('/channelAdmin/getOldProductFamily', {
      signupstatusid: [14, 15],
      userId: adminUser.userId,
      accessId: +adminUser.accessId
    }).then(res => {
      filterOptionState.product_family_option = res.data;
      setFilterOption({ ...filterOptionState, product_family_Option: filterOptionState.product_family_option });
    })
  }

  const getProductCat = () => {
    axios.post('/channelAdmin/getOldProductCat', {
      signupstatusid: [14, 15],
      userId: adminUser.userId,
      accessId: +adminUser.accessId
    }).then(res => {
      filterOptionState.product_cat_Option = res.data;
      setFilterOption({ ...filterOptionState, product_cat_Option: filterOptionState.product_cat_Option });
    })
  }

  const getOldBrandOption = () => {
    axios.post('/channelAdmin/getOldChannelBrandOptions', {
      signupstatusid: [14, 15],
      userId: adminUser.userId,
      accessId: +adminUser.accessId
    }).then(res => {
      filterOptionState.brand_Option = res.data;
      setFilterOption({ ...filterOptionState, brand_Option: filterOptionState.brand_Option });
    });
  }

  const getOldProfileOption = () => {
    axios.post('/channelAdmin/getOldChannelProfileOptions', {
      signupstatusid: [14, 15],
      userId: adminUser.userId,
      accessId: +adminUser.accessId
    }).then(res => {
      let key = ['Distributor'];
      filterOptionState.distProfile_Option = res.data.profileList?.filter(x => key.includes(x.groupname));
      key = ['Retailer'];
      filterOptionState.retailer_Option = res.data.profileList?.filter(x => key.includes(x.groupname));
      key = ['Reseller'];
      filterOptionState.reseller_Option = res.data.profileList?.filter(x => key.includes(x.groupname));
      console.log('filter option state', filterOptionState)
      setFilterOption({ ...filterOptionState });
    });
  }

  const getPlanList = () => {
    axios.get('/channelAdmin/getSubscriptionPlan').then(res => {
      filterOptionState.plan_Option = res.data;
      setFilterOption({ ...filterOptionState, plan_Option: filterOptionState.plan_Option });
    })
  }

  const getFilterData = () => {
    let filter = [];
    if (filterValueState.country && filterValueState.country.length > 0) {
      filter.countryId = filterValueState.country.map(x => parseInt(x.id));
    }
    if (filterValueState.plan && filterValueState.plan.length > 0) {
      filter.planId = filterValueState.plan.map(x => parseInt(x.id));
    }

    if (filterValueState.Company_type) {
      filter.channelTypeId = filterValueState.Company_type.id;
    }
    if (filterValueState.is_OEM) {
      filter.is_OEM = filterValueState.is_OEM.id;
    }
    if (filterValueState.product_families && filterValueState.product_families.length > 0) {
      filter.product_families = filterValueState.product_families.map(x => parseInt(x.id));
    }
    if (filterValueState.product_Categories && filterValueState.product_Categories.length > 0) {
      filter.product_Categories = filterValueState.product_Categories.map(x => parseInt(x.id));
    }
    if (filterValueState.brand && filterValueState.brand.length > 0) {
      filter.brand = filterValueState.brand.map(x => parseInt(x.id));
    }
    //profile 
    if (filterValueState.dist_profile && filterValueState.dist_profile.length > 0) {
      filter.retailerprofileId = filterValueState.dist_profile.map(x => parseInt(x.id));
    }
    if (filterValueState.reseller_profile && filterValueState.reseller_profile.length > 0) {
      filter.retailerprofileId = filter.retailerprofileId?.length ? filter.retailerprofileId.push(...filterValueState.reseller_profile.map(x => parseInt(x.id))) : filterValueState.reseller_profile.map(x => parseInt(x.id));
    }
    if (filterValueState.retailer_profile && filterValueState.retailer_profile.length > 0) {
      filter.retailerprofileId = filter.retailerprofileId?.length ? filter.retailerprofileId.push(...filterValueState.retailer_profile.map(x => parseInt(x.id))) : filterValueState.retailer_profile.map(x => parseInt(x.id));
    }
    return filter;
  }
  const getPendingChannel = async () => {

    loading = true;
    setLoading(loading);
    var filter = await getFilterData();
    let data = {
      search: searchState.search ? searchState.search[0] ? searchState.search[0].id : null : null,
      signupstatusid: [14],
      userId: adminUser.userId,
      filter: {
        countryId: filter.countryId ? filter.countryId : null,
        planId: filter.planId ? filter.planId : null,
        typeId: filter.channelTypeId ? filter.channelTypeId : null,
        is_OEM: filter.is_OEM ? filter.is_OEM : null,
        productCategoryId: filter.product_Categories ? filter.product_Categories : null,
        productFamilyId: filter.product_families ? filter.product_families : null,
        brandId: filter.brand ? filter.brand : null,
        retailerprofileId: filter.retailerprofileId ? filter.retailerprofileId : null,
        specialityId: filter.specialityId ? filter.specialityId : null
      },
      page: page * rowsPerPage,
      limit: rowsPerPage
    }
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.post('/channelAdmin/oldChannels',
      data).then(res => {
        console.log("pending:", res);
        state.dataTable = res.data.results;
        state.total = res.data.total;
        loading = false;
        setLoading(loading);
        setState({ ...state });
        if (filter.channelTypeId)
          getPendingChannelsList(filter.channelTypeId ? filter.channelTypeId : 3);
      }).catch((err) => {
        state.dataTable = [];
        state.total = 0;
        loading = false;
        setLoading(loading);
        setState({ ...state });
      })
  }

  const getPendingChannelsList = (channeltype) => {
    axios.get('/channelAdmin/oldChannelsList/' + adminUser.userId + '/' + channeltype).then(res => {
      let data = [];
      data.push(...res.data)
      data = data.map(x => {
        return { name: x.name.trim(), id: x.id }
      });
      let contact = [];
      res.data.map(x => {
        x.oldContacts.map(c => {
          contact.push({ name: c.firstName + ' ' + c.lastName, id: x.id, companyName: x.name.trim() });
        })
      })
      data.push(...contact);
      searchState.dropdownList = data
      setSearch({ dropdownList: searchState.dropdownList });
    });
  }

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getPendingChannel();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    callPendingChannel();
  };

  const handleChangeSelect = (input, value) => {
    if (input == 'search') {
      filterValueState.Company_type = null;
      filterValueState.is_OEM = null;
      filterValueState.country = [];
      filterValueState.plan = [];
      filterValueState.product_Categories = [];
      filterValueState.product_families = [];
      filterValueState.brand = [];
      filterValueState.retailer_profile = [];
      filterValueState.reseller_profile = [];
      filterValueState.dist_profile = [];
      setFilterValue({ ...filterValueState });
      searchState.search = [value]
      setSearch({ ...searchState })
    }
    if (input == 'Company_type') {
      filterValueState.Company_type = value
      setFilterValue({ ...filterValueState })
    }
    if (input == 'is_OEM') {
      filterValueState.is_OEM = value
      setFilterValue({ ...filterValueState })
    }
    if (input == 'country') {
      filterValueState.country = value
      setFilterValue({ ...filterValueState })
    }
    if (input == 'plan') {
      filterValueState.plan = value
      setFilterValue({ ...filterValueState })
    }
    if (input == 'product_Categories') {
      filterValueState.product_Categories = value
      setFilterValue({ ...filterValueState });
    }
    if (input == 'product_family') {
      filterValueState.product_families = value;
      setFilterValue({ ...filterValueState });
    }
    if (input == 'brand') {
      filterValueState.brand = value;
      setFilterValue({ ...filterValueState });
    }
    if (input == 'distProfile') {
      filterValueState.dist_profile = value;
      setFilterValue({ ...filterValueState });
    }
    if (input == 'retailerProfile') {
      filterValueState.retailer_profile = value;
      setFilterValue({ ...filterValueState });
    }
    if (input == 'resellerProfile') {
      filterValueState.reseller_profile = value;
      setFilterValue({ ...filterValueState });
    }
    callPendingChannel();
  }

  const callPendingChannel = () => {
    closeDrawer();
    page = 0;
    setPage(page);
    getPendingChannel();
  }
  const resetFilterValue = () => {
    filterValueState.Company_type = null;
    filterValueState.is_OEM = null;
    filterValueState.country = [];
    filterValueState.plan = [];
    filterValueState.product_Categories = [];
    filterValueState.product_families = [];
    filterValueState.brand = [];
    filterValueState.retailer_profile = [];
    filterValueState.reseller_profile = [];
    filterValueState.dist_profile = [];
    setFilterValue({ ...filterValueState });
    callPendingChannel();
  }
  const notify = (type, message) => {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {type}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      // icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const updateStatus = (data, id, contact) => {
    console.log('contact', contact);
    loading = true;
    setLoading(loading);
    axios.put('/channelAdmin/channelApproval', {
      channelId: data,
      signUpStatusId: id
    }).then(res => {
      if (res) {
        id == 15 ? notify('success', 'Channel Approved successfully') : notify('warning', 'Channel Dismiss successfully');
        if (id == 15) {
          let maildata = {
            to: contact.email,  
            contactId: contact.contactId,
            profileurl: process.env.REACT_APP_LOCALSITE + 'login'
          }
          axios.post('mailerservice/Channel_Approval_Mail', maildata);
        }
        else {
          let maildata = {
            to: contact.email,
            contactId: contact.contactId,
            //profileurl: process.env.REACT_APP_LOCALSITE + 'login'
          }
          axios.post('mailerservice/Channel_Dismiss_Mail', maildata);
        }
        callPendingChannel();
        searchState.search = null
        setSearch({ ...searchState, search: searchState.search })
      }
    }).catch((err) => {
      console.log('err', err)
      loading = false;
      setLoading(loading);
      notify('danger', 'Soming went wrong');
    })
  }

  const openContact = (data) => {
    setDrawerInput(data);
    setshowDrawer(true);
    console.log('datauser', data);
  }

  const openModalPopUp = (ch_Id, id, contact) => {
    if (id === 15) {
      modalPopValue.message = 'You want to Approve';
      modalPopValue.title = "Approve Channel";
      modalPopValue.value = { channelId: ch_Id, id: id, contact: contact };
    } else {
      modalPopValue.message = 'You want to Dismiss';
      modalPopValue.title = "Dismiss Channel";
      modalPopValue.value = { channelId: ch_Id, id: id, contact: contact };
    }
    modalPopValue.popUpId = 1;
    setModalPopValue({ ...modalPopValue })
    open = true;
    setModalOpen(open);
  };

  const handleClose = () => {
    open = false;
    setModalOpen(open);
    console.log(open, 'open');
  };
  const handleSave = (value) => {
    open = false;
    setModalOpen(open);
    updateStatus(value.value.channelId, value.value.id, value.value.contact);
  };
  const [userview, setuserview] = useState(false)
  const login = (contactId, firstName, lastName, jobTitle, country, channelId, channelTypeId, documentPath) => {
    setContact(contactId);
    setUser({ firstName: firstName, lastName: lastName, jobTitle: jobTitle, country: country, channelId: channelId, channelTypeId: channelTypeId, documentPath: documentPath });
    setuserview(true);

  }
  const closelogin = () => {
    setuserview(false);
  }

  const closeDrawer = () => {
    setshowDrawer(false);
  }

  const closeConnect = () => {
    setshowconnect(false);
  }
  const openConnect = () => {
    setshowconnect(true);
  }
  const opneUser = () => {
    closeConnect();
    setshowDrawer(true);
  }
  // style={{width:'46%',float:'right'}}
  const User = {
    renderer: (row, rowIndex, index) => {
      console.log('rowuser', row, rowIndex);
      return (
        <div style={{ width: '100%' }}>
          {row.contacts.length && row.contacts.map((x, i) =>
            <ListGroup className="list my--2" flush key={'table' + x.contactId}>
              <ListGroupItem className="px-0">
                <Row className="align-items-center">
                  <Col className="col-1">
                    <a
                      className="avatar rounded-circle"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }}
                        alt="..."
                        src={x.document?.documentPath ? x.document.documentPath.includes(process.env.REACT_APP_CDN_URL) ? x.document.documentPath : process.env.REACT_APP_CDN_URL + x.document.documentPath : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}//{require("assets/img/theme/team-1.jpg")}
                      />
                    </a>
                  </Col>
                  <div className="col-lg-2 ml--2">
                    <p className="mb-0" style={{ fontSize: '0.7rem' }}>

                      {x.firstName + ' ' + x.lastName}

                    </p>
                    <small>{x.email}</small>
                    <div><i id={'Sin' + index} onClick={() => login(x.contactId, x.firstName, x.lastName, x.jobTitle, x.country?.country, row.channelId, row.channelTypeId, x.document?.documentPath)} style={{ padding: "10px 10px 0 0 " }} class="fas fa-sign-in-alt pointer">
                      <UncontrolledTooltip target={'Sin' + index} placement='bottom'> SignIn </UncontrolledTooltip>
                    </i>
                      <i id={'Edit' + index} onClick={() => { openContact(Object.assign(x, { channelTypeId: row.channelTypeId, channelId: row.channelId, userId: row.user[0].userId })) }} style={{ padding: "10px 10px 0 10px" }} class="fas fa-edit pointer">
                        <UncontrolledTooltip target={'Edit' + index} placement='bottom'> Edit </UncontrolledTooltip>
                      </i>
                      <i id={'Exc' + index} onClick={() => { openConnect() }} style={{ padding: "10px 10px 0 10px" }} class="fas fa-exchange-alt pointer">
                        <UncontrolledTooltip target={'Exc' + index} placement='bottom'> Exchange </UncontrolledTooltip></i></div>


                  </div>

                  <div className="col-lg-1 ml--2">
                    <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                      Last Login
                    </p>
                    <small>{new Date(x.user.lastLogin).toLocaleDateString()}</small>
                  </div>

                  <div className="col-lg-2 ml--2">
                    <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                      {x.jobTitle}
                    </p>
                  </div>
                  {/* <div className="col-lg-1 ml--2">
                            <p className="mb-0" style={{fontSize:'0.7rem'}}>
                            {x.hubspotContactId ? x.hubspotContactId : '-'}
                            </p>
                          </div> */}
                  <div className="col-lg-2 ml--2">
                    <p className="mb-0" style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '0.7rem' }} id={'producttip' + x.contactId}>
                      {
                        x.productGroup?.length ? x.productGroup.map((y, i) => {
                          if (x.productGroup.length - 1 != i)
                            return (y.productGroup.productGroup + ', ');
                          return (y.productGroup.productGroup);
                        }) : <>-</>
                      }
                    </p>
                    <UncontrolledTooltip delay={0} target={'producttip' + x.contactId}>
                      {
                        x.productGroup?.length ? x.productGroup.map((y, i) => {
                          if (x.productGroup.length - 1 != i)
                            return (y.productGroup.productGroup + ', ');
                          return (y.productGroup.productGroup);
                        }) : <></>
                      }
                    </UncontrolledTooltip>
                  </div>
                  <div className="col-lg-1 ml--2">
                    <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                      {x.user?.role?.roleId == '1' ? 'Account User' : x.user?.role?.roleId == '2' ? 'Admin' : 'Primary'}
                    </p>
                  </div>
                  <div className="col-lg-1 ml--2">
                    <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                      {x.isShared == '0' ? 'Confidential' : 'Shared'}
                    </p>
                  </div>
                  <div className="col-lg-1 ml--2">
                    <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                      {x.country?.country}
                    </p>

                  </div>
                  <div className="col-lg-1 ml-2">

                    <Badge className='outer_badge' style={{ width: "100%", height: "2rem", padding: "0.6rem", border: "1px solid #46b88f", color: "#46b88f" }}>
                      {x.isActive == '1' ? 'Active' : 'Inactive'}

                    </Badge>

                  </div>

                  {/* <Col className="col-lg-1">
                            <Button className="float-right" color="primary" size="sm" type="button">
                            <i class="fas fa-sign-in-alt pointer"></i>   Login
                            </Button>
                          </Col>
                          <Col className="col-lg-1">
                            <Button className="float-right" color="primary" size="sm" type="button">
                            <i class="fas fa-sign-in-alt pointer"></i>   Edit
                            </Button>
                          </Col>
                          <Col className="col-lg-1">
                            <Button className="float-right" color="primary" size="sm" type="button">
                            <i class="fas fa-sign-in-alt pointer"></i>   Replace
                            </Button>
                          </Col> */}
                  {
                    x.eventParticipants.length > 0 && <div class='ribbon-wrapper-5'>
                      <div class='ribbon-5 bg-green'>Event</div>
                    </div>
                  }
                </Row>
              </ListGroupItem>

            </ListGroup>)
          }
        </div>
      )
    },
    className: 'bgrow'
  };
  //const [openSide,setOpen] = React.useState(1);
  const [sidenavOpen, setOpen] = useState(true);

  const [pubFlag, setPublicFlag] = useState(false);
  const [publicFlagData, setPublicFlagData] = useState({});

  const openPublicFlag = (item) => {

    console.log('public view', item);

    let data = {
      channelId: item.channelId,
      isFeatured: item.isFeatured == '1' ? true : false,
    };

    setPublicFlagData(data);
    setPublicFlag(true);

  }

  const closePublicFlag = () => {
    setPublicFlagData({});
    setPublicFlag(false);
  }

  const edit = async (contactid, channelId, channelTypeId) => {
    setuserview(false);
    let contactData = await commonService.getServices('/channelAdmin/getContact/' + contactid);
    if (contactData) {
      let data = Object.assign(contactData.data, { channelTypeId: channelTypeId, channelId: channelId, userId: null });
      setDrawerInput(data);
      setshowDrawer(true);
      console.log('datauser', data);
    }
  }


  return (
    <div id="channelList">

      {/* {userview && */}
      <UserView open={userview} closelogin={closelogin} contactid={contactid} user={user} edit={edit} />
      {/* } */}
      {connect &&
        <Connect opneUser={opneUser} closeConnect={closeConnect} />
      }

      <Dialog open={open} value={modalPopValue} handleSave={handleSave} handleClose={handleClose}></Dialog>
      {publicFlagData.hasOwnProperty('channelId') && <CompanyPublicSettings closeModalPopup={closePublicFlag} opneadd={pubFlag} data={publicFlagData} />}

      <NotificationAlert ref={notificationAlert} />
      {/* <div className={classes.root}> */}
      <OldFilter
        Filter={true}
        filterOption={filterOptionState}
        filterValue={filterValueState}
        resetFilterValue={resetFilterValue}
        handleChangeSelect={handleChangeSelect}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen} />

      <SimpleHeader name="Channel List" parentName="Dashboard" filter={true} />
      <Drawer title="Edit User" visible={showDrawer} contactData={drawerInput} closeDrawer={closeDrawer} reload={callPendingChannel} />
      <Container className="mt--3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Company Listing</h3>
                {/* <p className="text-sm mb-0">
                    This is an exmaple of data table using the well known
                    react-bootstrap-table2 plugin. This is a minimal setup in
                    order to get started fast.
                  </p> */}
              </CardHeader>
              {loading ? <Spinner /> : <ToolkitProvider
                ClassName="align-items-center table-flush" responsive
                data={state.dataTable}
                keyField="cid"
                columns={[

                  {
                    dataField: "Action",
                    text: "Action",
                    formatter: (rowContent, row) => {
                      console.log('rowContent', row, rowContent)

                      const handleSubmitedit = () => {
                        const win = window.open(window.location.origin + '/admin/oldChannelListing/oldChannelDetail/' + row.cid, "_blank");
                      }

                      return (
                        <>

                          {/* <button className="btn btn-icon btn-primary btn-sm" variant="contained" color="primary" onClick={e => {history.push('/admin/oldChannelListing/oldChannelDetail/'+row.cid)}}> */}

                          <button className="btn btn-icon btn-primary btn-sm" variant="contained" color="primary" onClick={handleSubmitedit}>

                            <span className="btn-inner--icon"><i className="fas fa-pencil-alt"></i></span>
                          </button>

                        </>

                      )
                    }
                  },
                  {
                    dataField: "channeltypeid",
                    text: "Company Type",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {rowContent == 2 && "Vendor"}
                          {rowContent == 3 && "Distributor"}
                          {rowContent == 4 && "Retailer"}
                          {rowContent == 5 && "Reseller"}
                        </>
                      )
                    }
                  },
                  {
                    dataField: "companyname",
                    text: " Company Name",
                    sort: true
                  },
                  // {
                  //   dataField: "hubspotCompanyId",
                  //   text: "Company hubspot ID",
                  //   // sort: true,
                  // },
                  {
                    dataField: 'country',
                    text: "Company Country",
                    sort: true
                  },
                  // {
                  //   dataField: 'regAddress.address',
                  //   text: "Company Address",
                  //   sort: true
                  // },
                  // {
                  //   dataField: "channelDetail.phone1",
                  //   text: "Company Phone No",
                  //   sort: true
                  // },

                  // {
                  //   dataField: 'channelProductGroup',
                  //   text: "Product Families",
                  //   // sort: true,
                  //   formatter: (rowContent) => {
                  //     let data = rowContent.map(x=>x.productGroup.name);
                  //     data = data.toString();
                  //     return(
                  //       <>
                  //       {data}
                  //       </>
                  //     )
                  //   }
                  // },
                  // {
                  //   dataField: "contacts[0].firstName",
                  //   text: "Contact Name",
                  //   sort: true,
                  //   formatter: (rowContent, row) => {
                  //     return(
                  //       <>
                  //       {row.contacts[0].firstName +' '+ row.contacts[0].firstName}
                  //       </>
                  //     )
                  //   }
                  // },
                  // {
                  //   dataField: 'contacts[0].jobTitle',
                  //   text: "Job Title",
                  //   sort: true
                  // },
                  // {
                  //   dataField: 'contacts[0].email',
                  //   text: "Contact Email",
                  //   sort: true
                  // },
                  // {
                  //   dataField: "contacts[0].mobileNo",
                  //   text: "Contact Mobile No",
                  //   sort: true,
                  //   formatter: (rowContent, row) => {
                  //     return(
                  //       <>
                  //       {row.contacts[0].mobileNo?.replace(/[^\w\s]/gi, '')}
                  //       </>
                  //     )
                  //   }
                  // },
                  // {
                  //   dataField: "contacts[0].hubspotContactId",
                  //   text: "Contact hubspot ID",
                  //   sort: true,
                  //   formatter: (rowContent, row) => {
                  //     return(
                  //       <>
                  //       {row.contacts[0].hubspotContactId? row.contacts[0].hubspotContactId : '-'}
                  //       </>
                  //     )
                  //   }
                  // },
                  // {
                  //   dataField: "createdAt",
                  //   text: "Registered On",
                  //   sort: true
                  // },

                  // {
                  //   dataField: "status",
                  //   text: "Status",
                  //   sort: true,
                  //   formatter: (rowContent, row) => {
                  //       return (    
                  //         <>        
                  //         {row.signUpStatusId==14?(
                  //         <button className="btn btn-icon btn-warning btn-sm" variant="contained" color="warning" >
                  //         <span>Pending</span>
                  //     </button>):(
                  //       <button className="btn btn-icon btn-primary btn-sm" variant="contained" color="primary" >
                  //         <span>Approved</span>
                  //     </button>
                  //     )}                                               
                  //       </>
                  //       )
                  //   }
                  // } ,

                  // {
                  //   dataField: "Public Settings",
                  //   text: "Public Settings",
                  //   formatter: (rowContent, row) => {
                  //     return (                          
                  //       <>                          

                  //     {row.signUpStatusId==15 && <button className="btn btn-icon btn-primary btn-sm" variant="contained" color="primary" onClick={()=>openPublicFlag(row)}>
                  //         <span>Public View</span>
                  //     </button>}

                  //     </>

                  //     )
                  //     }

                  // }
                ]}
              >
                {props => (
                  <div className="py-2 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 mb-2"
                    >
                      <div style={{ width: '20%' }}>
                        <SelectAutocomplete
                          id="search"
                          type="text"
                          label="search"
                          name="search"
                          options={searchState.dropdownList}
                          placeholder="search..."
                          disableCloseOnSelect
                          // defaultValue={searchState.search?searchState.search[0]:null}
                          control={control}
                          input='search'
                          value={searchState.search ? searchState.search[0] : null}
                          handleChangeSelect={handleChangeSelect}
                        />
                      </div>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      // expandRow={User}
                      bootstrap4={true}
                      // pagination={pagination}
                      bordered={false}
                      hover={true}

                      rowClasses='pointer'
                    />

                    <TablePagination
                      component="div"
                      count={state.total}
                      page={page}
                      onChangePage={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </div>
                )}
              </ToolkitProvider>}
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};
export default OldChannelListing;
