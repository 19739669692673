import React, { useState,useEffect } from "react";
import { Card,CardBody,CardHeader, Container,Col,Row,Badge} from "reactstrap";
import {FormRadio,FormCheckBox} from "_components/FormElements/FormInput";
import {InputLabel,Select,TextField,FormControl} from '@material-ui/core';
import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Spinner} from 'views/Hoc/Spinner';
import { TradeLocationSchema } from '../validation';
import { FormAutocompleteGrouping, SingleAutocomplete } from '../../../../_components/FormElements/FormInput';
import axios from 'axios';
import {useBeforeFirstRender} from '_hooks/index'
import { commonService } from "_services/common.service";

const Tradelocation = (props) => {
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, control, errors } = useForm({      
        mode:'onBlur',  
        resolver: yupResolver(TradeLocationSchema),
      });

    //localStorage
    var userData = props.channelData;//JSON.parse(localStorage.getItem('CHuser'));
    var typeId = userData.channelTypeId;

    const [fl, setFl] = useState(false);

      const [state, setState] = useState({
        sellingCountries : [],
        sellingCountriesOption : [],
        targetCountries : [],
        targetCountriesOption : [],
        locationType:(typeId == 2 || typeId == 3) ? 'Target Locations' : 'Selling Locations',
        title: (typeId == 2 || typeId == 3) ? 'TARGET' : 'SELLING',
        sellingZones: [],
        EMEA: false,
        APAC: false,
        AMERICAS:false,
        EMEA1: false,
        APAC1: false,
        AMERICAS1:false
      });

    useEffect(() => {

      setLoading(true);
      (async() =>{  
        await axios.get('setting/getUserProfile/'+userData?.channelId).then(res =>{    
          
          state.targetCountriesOption=res.data.regions1;
          state.sellingCountriesOption=res.data.regions;
          
          setState({...state, targetCountriesOption:state.targetCountriesOption, sellingCountriesOption:state.sellingCountriesOption});          
          console.log("pro:",res);
        });

        const apiUrl = '/channelAdmin/tradelocation';
        await axios.post(apiUrl, userData).then(res =>{            
          console.log("profiledata:",res.data);  

          if(res.data.targetLocation.length && state.targetCountriesOption){
            state.targetCountriesOption = state.targetCountriesOption.map(x => {
              res.data.targetLocation.map(y => {
                if(x.groupid == y.groupid && x.childId == y.childId)
                  x.status = true;
              })
              return x;
            })
          }

          if(res.data.sellingLocation.length && state.sellingCountriesOption){
            state.sellingCountriesOption = state.sellingCountriesOption.map(x => {
              res.data.sellingLocation.map(y => {
                if(x.groupid == y.groupid && x.childId == y.childId)
                  x.status = true;
              })
              return x;
            })
          }

          res.data.zone.tzone.map(x => {
            if(x.zoneId==='1')
                state.EMEA=true;
            if(x.zoneId==='3')
                state.APAC=true;
            if(x.zoneId==='2')
                state.AMERICAS=true;
          })

          res.data.zone.szone.map(x => {
            if(x.zoneId==='1')
                state.EMEA1=true;
            if(x.zoneId==='3')
                state.APAC1=true;
            if(x.zoneId==='2')
                state.AMERICAS1=true;
          })

          setState({...state,targetCountries:res.data.targetLocation,sellingCountries:res.data.sellingLocation,sellingZones:res.data.zone, sellingCountriesOption: state.sellingCountriesOption, targetCountriesOption: state.targetCountriesOption});          
          setLoading(false);
          setFl(true);
        });     
        
  
      })();
      
    }, []);


    useEffect(() => {
      if(fl)
      getSearchRegion();
    }, [state.EMEA, state.AMERICAS, state.APAC, state.EMEA1, state.AMERICAS1, state.APAC1]);

    const getSearchRegion = async() => {
      let zone = [];
      if(state.title == 'TARGET')
      {
        if(state.EMEA) zone.push(1);
        if(state.AMERICAS) zone.push(2);
        if(state.APAC) zone.push(3);
      }
      else{
        if(state.EMEA1) zone.push(1);
        if(state.AMERICAS1) zone.push(2);
        if(state.APAC1) zone.push(3);
      }        
      
      if(zone.length){
        let res = await axios.post('/channelAdmin/searchRegion', {exclude: true,
          locationType: state.title, zone: zone, region: '', user: userData}).then(res => {
          if(state.title == 'TARGET'){
            state.targetCountriesOption = res.data;

            let temp = [];
            if(state.targetCountries.length){
              temp = state.targetCountries.filter(el => state.targetCountriesOption.find(({groupid}) => groupid==el.groupid));
              state.targetCountriesOption = state.targetCountriesOption.map(x => {
                temp.map(y => {
                  if(x.groupid==y.groupid && x.childId==y.childId)
                    x.status = true;
                })
                return x;
              })
              state.targetCountries=temp;
            }

          }else if(state.title == 'SELLING'){
            state.sellingCountriesOption = res.data;

            let temp = [];
            if(state.sellingCountries.length){
              temp = state.sellingCountries.filter(el => state.sellingCountriesOption.find(({groupid}) => groupid==el.groupid));
              state.sellingCountriesOption = state.sellingCountriesOption.map(x => {
                temp.map(y => {
                  if(x.groupid==y.groupid && x.childId==y.childId)
                    x.status = true;
                })
                return x;
              })
              state.sellingCountries=temp;
            }
          }
          setState({...state});
          }).catch(err => console.log('zoneerr', err));
      }
      else{
        if(state.title == 'TARGET'){
          state.targetCountries = [];
          state.targetCountriesOption = [];
        }else if(state.title == 'SELLING'){
          state.sellingCountries = [];
          state.sellingCountriesOption = [];
        }
        setState({...state});
      }
      }

  
      
      const selectedItem=(gname,check,optionName,optionValue) => {    
        let opt=[];    
        console.log("check:",check,gname,optionName,optionValue);
        if(check==true)
        {    
          state[optionName].map((item) => {      
            console.log("groupname:",item.groupname);
                if(item.groupname==gname||item.status==true)
                {
                  item.status=check;      
                  opt.push(item);
                }     
          });
        }
        if(check==false)
        {  
          console.log("check:",check);
          state[optionName].map((item) => {      
              if(item.groupname==gname&&state[optionValue].length>0)
                  item.status=check;                          
              // if(state.productValue.length==0)
              //     item.status=false;
          });
    
          opt=state[optionValue].filter((item) => item.groupname!=gname);      
          opt.map(option => option.status=true);
        }
        
        state[optionValue]=opt;
        setState({...state, [optionValue]:state[optionValue]});    
        setState({...state, [optionName]:state[optionName]});    
    
        if(optionValue='sellingCountries' && state.sellingCountries.length==0)
            errors.selling=true;
        else if(optionValue='targetCountries' && state.targetCountries.length==0)
            errors.target=true;
        
        
    
      }

    const handleChange = input => e => {
        console.log("add",e.target.value);  
        if(input==='locationType' && state.locationType!=e.target.value){

          // state.EMEA=false;                
          // state.APAC=false;
          // state.AMERICAS=false;

          // if(e.target.value==='Target Locations')
          // {
          //   state.sellingZones.szone.map(x => {
          //     if(x.zone==='EMEA')
          //         state.EMEA=true;                
          //     if(x.zone==='APAC')
          //         state.APAC=true;
          //     if(x.zone==='AMERICAS')
          //         state.AMERICAS=true;
          //   })
          // }

          // if(e.target.value==='Selling Locations')
          // {
          //   state.sellingZones.tzone.map(x => {
          //     if(x.zone==='EMEA')
          //         state.EMEA1=true;                
          //     if(x.zone==='APAC')
          //         state.APAC1=true;
          //     if(x.zone==='AMERICAS')
          //         state.AMERICAS1=true;
          //   })
          // }

          errors.target=false;
          errors.selling=false;
          setState({...state, [input] : e.target.value, title: e.target.value==='Target Locations' ? 'TARGET' : 'SELLING'});  
        }else if(input === 'AMERICAS' || input === 'EMEA' || input === 'APAC' || input === 'AMERICAS1' || input === 'EMEA1' || input === 'APAC1'){
          setState({...state, [input]: !state[input]});
        }
        else{
          setState({...state, [input] : e.target.value});
        }
    }
  

    console.log('state', state);    

    const save = async() => {
      setLoading(true);
      
    let location;let locationType;
    
    if(typeId==4)      
    location=state.sellingCountries.map(x => +x.regionCountryJCTId);
    if(typeId==2)       
        location=state.targetCountries.map(x => +x.regionCountryJCTId);
                                      
    if(typeId==3){
    if(state.title == 'TARGET')
    location=state.targetCountries.map(x => +x.regionCountryJCTId);
    if(state.title == 'SELLING')
    location=state.sellingCountries.map(x => +x.regionCountryJCTId);
    }
                                          
      
      let data={
        location:location,
        locationType:(typeId==2||typeId==3)?state.title:'RETAIL',
        user: {channel: userData, userId: userData.userId, channelId: userData.channelId}
      }

      console.log('save', data);
      let result = await commonService.updateTradeLocation(
      data,'channelAdmin/updateChannelLocation').catch(err => setLoading(false));
        
         if(result) setLoading(false);
       //history.push('admin/dashboard');
    
      
    }
    
    console.log('state', state);
    console.log('err', errors)
    const onSubmit = data => {    
    if (data) {        
        console.log("data:",data);
    }    
    }

    const handleChangeSelect = (input,value) => {      
  
        if(input=='product'){
          state.productOptions.map((item) => {      
            if(value.length==0)
              item.status=false;
            else
            {
              // value.map(v => {          
              //   if(v.childname!=item.childname)
              //     item.status=false;                 
              //   else if(v.childname==item.childname)            
              //     item.status=true;                       
                
              // });      
              if(value.filter(e => e.childname == item.childname).length)
              item.status = true;
            else
              item.status = false;
            }
          });
      
          value.map(option => option.status=true);
          state.productValue= value
          setState({...state, productOptions:state.productOptions});
          setState({...state, productValue:state.productValue});
      
          if(state.productValue.length==0)
            errors.productCat=true;
        }
        else if(input=='sellingCountries'){
          state.sellingCountriesOption.map((item) => {      
            if(value.length==0)
              item.status=false;
            else
            {
              // value.map(v => {          
              //   // if(v.childname!=item.childname)
              //   //   item.status=false;                 
              //  if(v.childname==item.childname)            
              //     item.status=true;                       
                
              // });      
              if(value.filter(e => e.childname == item.childname).length)
              item.status = true;
            else
              item.status = false;
            }
          });
      
          value.map(option => option.status=true);
          state.sellingCountries= value
          setState({...state, sellingCountries:state.sellingCountriesOption});
          setState({...state, sellingCountries:state.sellingCountries});
      
          if(state.sellingCountries.length==0)
            errors.selling=true;
        }
        else if(input=='targetCountries'){
          state.targetCountriesOption.map((item) => {      
            if(value.length==0)
              item.status=false;
            else
            {
              // value.map(v => {          
              //   // if(v.childname!=item.childname)
              //   //   item.status=false;                 
              //    if(v.childname==item.childname)            
              //     item.status=true;                       
                
              // });     
              if(value.filter(e => e.childname == item.childname).length)
              item.status = true;
            else
              item.status = false; 
            }
          });
      
          value.map(option => option.status=true);
          state.targetCountries= value
          setState({...state, targetCountriesOption:state.targetCountriesOption});
          setState({...state, targetCountries:state.targetCountries});
      
          if(state.targetCountries.length==0)
            errors.target=true;
        }
  
        if(input!='product'&&input!='sellingCountries'&&input!='targetCountries')
         setState({...state, [input]:[value]});
      }

    return (
      
        <div className="main-content">
        {loading? <Spinner />:(
        <Container className="mt-4 col-lg-10 col-md-9 col-sm-12  " fluid>
        <form noValidate onSubmit={handleSubmit(save)}>
            <Card>
                <CardHeader>
                <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="typeId" ref={register} name="typeId" value={typeId} control={control} /><input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="locationType" ref={register} name="locationType" value={state.title} control={control} />
                <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="selling" name="selling" ref={register} value={state.sellingCountries.length?state.sellingCountries[0].childname:''} control={control} /> 
                <div className="text-center mt-4">
                    {(typeId==3 || typeId==2) && (<input type="button" onClick={handleChange('locationType')} className={state.locationType=='Target Locations' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md" } value="Target Locations" />)}
                    {(typeId==4 || typeId==3) && (<input type="button" onClick={handleChange('locationType')} className={state.locationType=='Selling Locations' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md" } value="Selling Locations" />)}
                </div>
                {state.locationType === 'Target Locations' && <><h3 className="text-center mt-4">Select the countries where you wish to expand your business, starting with Zone, then Region. The country list will be populated from the selected region.</h3>
                <a href="#"><p className="text-center">Download list to view zones, regions and related countries</p></a></>}

                {state.locationType==='Selling Locations' && <><h3 className="text-center mt-4">Select the countries where you are currently have a presence, starting with Zone, then Region. The country list will be populated from the selected regions.</h3>
                <a href="#"><p className="text-center">Download list to view zones, regions and related countries</p></a></>}

                </CardHeader>
                <CardBody>
                    <Row>
                        <Col lg="12">
                        <h4>1 - Choose {state.title} Zones</h4>
                        <Row>
                               <Col lg="2">
                               <div className="chck">
                                    <FormCheckBox
                                        id="EMEA"
                                        type="checkbox"
                                        checked={state.title=='TARGET' ? state.EMEA : state.EMEA1}
                                        //label=""
                                        //label1={{textAlign:"justify"}}
                                        //register={register}
                                        value='EMEA'
                                        onClick={state.title=='TARGET' ? handleChange('EMEA') : handleChange('EMEA1')}
                                    />
                                    <Badge style={{backgroundColor: "#2c9db7",color: "white",fontSize: "0.9000rem",position: "absolute",top: "5px",left: "45px"}}>
                                    EMEA
                                    </Badge>
                        
                                </div>
                               </Col>


                               <Col lg="2">
                               <div className="chck">
                                    <FormCheckBox
                                        id="APAC"
                                        type="checkbox"
                                        label="T/T "
                                        label1={{textAlign:"justify"}}
                                        //register={register}
                                        checked={state.title=='TARGET' ? state.APAC : state.APAC1}
                                        value='APAC'
                                        onClick={state.title=='TARGET' ? handleChange('APAC') : handleChange('APAC1')}
                                    />
                                    <Badge style={{backgroundColor: "#2c9db7",color: "white",fontSize: "0.9000rem",position: "absolute",top: "5px",left: "45px"}}>
                                    APAC
                                    </Badge>
                                </div>
                               </Col>
                               {state.locationType==='Selling Locations' && <Col lg="2">
                               <div className="chck">
                                    <FormCheckBox
                                        id="L/A"
                                        type="checkbox"
                                        //label=""
                                        //label1={{textAlign:"justify"}}
                                        //register={register}
                                        checked={state.title=='TARGET' ? state.AMERICAS : state.AMERICAS1}
                                        value="AMERICAS"
                                        onClick={state.title=='TARGET' ? handleChange('AMERICAS') : handleChange('AMERICAS1')}
                                    />
                                    <Badge style={{backgroundColor: "#2c9db7",color: "white",fontSize: "0.9000rem",position: "absolute",top: "5px",left: "45px"}}>
                                    AMERICAS
                                    </Badge>
                        
                                </div>
                               </Col>}
                               </Row>
                                {/* <div className="text-center mt-4">
                                 <button type="submit" className="btn btn-primary" >Save</button> 
                                </div> */}
                        </Col>
                    </Row>
                    {state.locationType === 'Target Locations' ?
                     <Row>
                        <Col lg="12">
                            <h4 className="mt-4">2 - Choose {state.title} Regions</h4>
                        <div className="form-group">               
                        <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="target" name="target" ref={register} value={state.targetCountries.length?state.targetCountries[0].childname:''} control={control} /> 
                        <FormAutocompleteGrouping
                            input="targetCountries"
                            id="Countries"
                            label="Countries"
                            name="Countries"
                            Options={state.targetCountriesOption}
                            placeholder="Search"              
                            errMessage={'Please choose your Countries'}
                            error={!errors.target || state.targetCountries.length ?false:true}
                            optionName='targetCountriesOption'
                            optionValue='targetCountries'
                            defaultValue={state.targetCountries}
                            value={state.targetCountries}         
                            loading={true}
                            selectedItem={selectedItem}
                            handleChangeSelect={handleChangeSelect}
                            control={control}
                            register={register}
                             />
                        </div>
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col lg="12">
                        <h4>3 - Choose {state.title} Countries</h4>
                        <div className="form-group">               
                        
                        <FormAutocompleteGrouping
                            input="sellingCountries"
                            id="Countries"
                            label="Countries"
                            name="Countries"
                            Options={state.sellingCountriesOption}
                            placeholder="Search"              
                            errMessage={'Please choose your Countries'}
                            error={!errors.selling || state.sellingCountries.length ?false:true}
                            optionName='sellingCountriesOption'
                            optionValue='sellingCountries'
                            defaultValue={state.sellingCountries}
                            value={state.sellingCountries}         
                            loading={true}
                            selectedItem={selectedItem}
                            handleChangeSelect={handleChangeSelect}
                            control={control}
                            register={register}
                             />
                        </div>
                        </Col>
                    </Row>
                    }

                  {/* { state.locationType === 'Selling Locations' && <><Row>
                        <Col lg="12">
                        <h4>1 - Choose Selling Zones</h4>
                        <Row>
                               <Col lg="2">
                               <div className="chck">
                                    <FormCheckBox
                                        id="L/C"
                                        type="checkbox"
                                        //label=""
                                        //label1={{textAlign:"justify"}}
                                        //register={register}
                                    />
                                    <Badge style={{backgroundColor: "#2c9db7",color: "white",fontSize: "0.9000rem",position: "absolute",top: "5px",left: "45px"}}>
                                    EMEA
                                    </Badge>
                        
                                </div>
                               </Col>
                               <Col lg="2">
                               <div className="chck">
                                    <FormCheckBox
                                        id="T/T "
                                        type="checkbox"
                                        label="T/T "
                                        label1={{textAlign:"justify"}}
                                        //register={register}
                                    />
                                    <Badge style={{backgroundColor: "#2c9db7",color: "white",fontSize: "0.9000rem",position: "absolute",top: "5px",left: "45px"}}>
                                    APAC
                                    </Badge>
                                </div>
                               </Col>
                               <Col lg="2">
                               <div className="chck">
                                    <FormCheckBox
                                        id="D/D"
                                        type="checkbox"
                                        label="D/D"
                                        label1={{textAlign:"justify"}}
                                        //register={register}
                                    />
                                    <Badge style={{backgroundColor: "#2c9db7",color: "white",fontSize: "0.9000rem",position: "absolute",top: "5px",left: "45px"}}>
                                    AMERICAS
                                    </Badge>
                                </div>
                               </Col>
                               </Row>
                                <div className="text-center mt-4">
                                 <button type="submit" className="btn btn-primary" >Save</button> 
                                </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <h4 className="mt-4">2 - Choose Selling Regions</h4>
                        <div className="form-group">               
                        <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="target" name="target" ref={register} value={state.targetCountries.length?state.targetCountries[0].childname:''} /> 
                        <FormAutocompleteGrouping
                            input="Countries"
                            id="Countries"
                            label="Countries"
                            name="Countries"
                            Options={state.targetCountriesOption}
                            placeholder="Search"              
                            errMessage={'Please choose your Countries'}
                            error={!errors.target || state.targetCountries.length ?false:true}
                            optionName='targetCountriesOption'
                            optionValue='targetCountries'
                            defaultValue={state.targetCountries}
                            value={state.targetCountries}         
                            loading={true}
                            selectedItem={selectedItem}
                            handleChangeSelect={handleChangeSelect}
                            control={control}
                            register={register}
                             />
                        </div>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col lg="12">
                        <h4>3 - Choose Selling Countries</h4>
                        <div className="form-group">               
                        <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="target" name="target" ref={register} value={state.targetCountries.length?state.targetCountries[0].childname:''} /> 
                        <FormAutocompleteGrouping
                            input="Countries"
                            id="Countries"
                            label="Countries"
                            name="Countries"
                            Options={state.targetCountriesOption}
                            placeholder="Search"              
                            errMessage={'Please choose your Countries'}
                            error={!errors.target || state.targetCountries.length ?false:true}
                            optionName='targetCountriesOption'
                            optionValue='targetCountries'
                            defaultValue={state.targetCountries}
                            value={state.targetCountries}         
                            loading={true}
                            selectedItem={selectedItem}
                            handleChangeSelect={handleChangeSelect}
                            control={control}
                            register={register}
                             />
                        </div>
                        </Col>
                    </Row></> */}
                 <div className="text-center mt-4">
            <button type="submit" className="btn btn-primary" >Save</button> 
            </div>

                </CardBody>
            </Card>
          
            </form>
        </Container>
        )}
        </div>
        
    );
};

export default Tradelocation;