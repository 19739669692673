import React, { useEffect, useState , useRef } from 'react';
import { useForm} from "react-hook-form";
import { SelectAutocomplete,FormInput } from '_components/FormElements/FormInput'
import { Card, CardBody, Container, Row, Col,Media,  CardHeader } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import UserFilter from "components/Filter-Right/UserFilter.js";
import pagination from "views/Hoc/pagination.js";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import axios from 'axios';
import {Spinner} from '../../Hoc/Spinner'
import TablePagination from '@material-ui/core/TablePagination';
import {TablePaginationActions} from 'views/Hoc/PaginationActions';
import Drawer from 'views/Hoc/Dialog/Globaldrawer'
import { commonService } from '_services/common.service';
import { history } from '_helpers';

 // toggles collapse between mini sidenav and normal
 const toggleSidenav = e => {
 
  if (document.getElementById('filter').classList.contains("g-sidenav-pinned")) {
    document.getElementById('filter').classList.remove("g-sidenav-pinned");
    document.getElementById('filter').classList.add("g-sidenav-hidden");
  } else {
    document.getElementById('filter').classList.add("g-sidenav-pinned");
    document.getElementById('filter').classList.remove("g-sidenav-hidden");
  }
};


export const RequestIntroView = (props) => {

  const { control } = useForm({ mode:'onBlur' });
  
let [loading, setLoading]  = useState(true)
let [page, setPage] = useState(0);
let [rowsPerPage, setRowsPerPage] = useState(10);
const [showDrawer,setshowDrawer]=useState(false);

const [couponId,setCouponId]=useState();

const [state, setState ] = useState({dataTable:[], total:0});
const [searchState, setSearch] = useState({search:null, dropdownList:[]});

useEffect(() => {
  getReqListing();    
}, []);
  
const getReqListing = async ()=>{   
    
    loading = true;
    setLoading(loading);
    let data = {};
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.post('/channelAdmin/request_Intro/'+props.match.params.channelId,data
    ).then(res =>{      
        state.dataTable = res.data.result;
        state.total = res.data.result.length;
        loading = false;    
        setLoading(loading);
        setState({ ...state });
    }).catch((err)=>{
        state.dataTable = [];
        state.total = 0;
        loading = false;
        setLoading(loading);
        setState({ ...state });
    })
}

const save = async (jct,statusid) => {
  let rec=state.dataTable.filter(x => x.jct==jct);
  let obj=rec[0];  
  let data={
    channelJCTId:obj.jct,
    connectionStatusTypeId:statusid,
    contactid:obj.scon,
    dismissreasonid:6,
    otherreasons:"isAdmin",
    retailerChannelTypeId:4,
    retailerChannelid:+props.match.params.channelId,
    retailerContactid:obj.bcon,
    retailerUserId:obj.buserid,
    sellerChannelTypeId:obj.channeltypeid,
    sellerChannelid:obj.sellerid
  }
  await axios.post('/channelAdmin/approveChannel',data
  ).then(res =>{      
    history.push('/admin/reqintroview/'+props.match.params.channelId);      
  });
  
}



const [sidenavOpen,setOpen] = useState(false);
  return (
    <div id="reqintroview">
      
      <SimpleHeader name="View" parentName="Dashboard" filter={'request_intro'} />
      
        <Container className="mt--3" fluid>
        
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Request Intro</h3>
                 </CardHeader>
                 {loading? <Spinner/>:<ToolkitProvider
                  data={state.dataTable}
                  keyField="Id"
                  columns={[                                          
                    { 
                      dataField: 'S.No',
                      text: " S.No",                      
                      formatter: (rowContent, row, rowIndex) => {
                        return(
                          <>                          
                          {rowIndex+1+(page*10)}                          
                          </>

                        )
                      }
                    },
                    {
                      dataField: "seller",
                      text: " Seller",                      
                    },
                    {
                      dataField: "sname",
                      text: " SellerName",
                      formatter: (rowContent, row) => {
                        return(
                          <>
                          {row.sname[0]}                          
                          </>

                        )
                      }
                    },                    
                    {
                      dataField: "channeltypeid",
                      text: " SellerType",
                      formatter: (rowContent, row) => {
                        return(
                          <>
                          {row.channeltypeid==2?'Vendor':'Distributor'}                          
                          </>
                        )
                      }
                    },             
                    {
                      dataField: "buyer",
                      text: " Buyer",                      
                    },
                    {
                      dataField: "bname",
                      text: " BuyerName",
                      formatter: (rowContent, row) => {
                        return(
                          <>
                          {row.bname[0]}                          
                          </>

                        )
                      }
                    },                    
                    {
                      dataField: "channeltypeid",
                      text: " SellerType",
                      formatter: (rowContent, row) => {
                        return(
                          <>
                          Retailer
                          </>
                        )
                      }
                    },                    
                    {
                      dataField: "Action",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (    
                          <>                          
                         <button onClick={() => save(row.jct,2) } className="btn btn-icon btn-primary btn-sm" variant="contained" color="primary" >
                            <span>Approve</span>
                        </button>

                        <button onClick={() => save(row.jct,5) } className="btn btn-icon btn-warning btn-sm" variant="contained" color="warning" >
                            <span>Dismiss</span>
                        </button>
                         
                        </>
                        )
                        }
                      }
                    
                   
                  ]}
            
                >
                {props => (
                    <div className="py-2 table-responsive">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 mb-2"
                      >
               
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        // pagination={pagination}
                        bordered={false}
                        hover ={true}
                        rowClasses= 'pointer' 
                      />

                  
                    </div>
                  )}
             
                </ToolkitProvider>}

                
              </Card>
        
                            </Container>
    </div>
  );
};
export default RequestIntroView;
