import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {useEffect,useState} from 'react';
import {
    Button, Modal,Row,Col
} from "reactstrap";

import {FormInput,FormFileInput,FormSelect,FormButton,FormMultiSelect,FormTextArea, FormCheckBox, FormAutocomplete, FormRadio} from "_components/FormElements/FormInput";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { PackageSchema } from './validation';



export  const EventPackage=(props)=> {
const { handleClose, handleSave, value, visible, packageData, savePackage } = props;
// const [open, Setopen] = useState(visible)
const closeOpen=(e)=>{
  e.preventDefault();
  // Setopen(false);
  handleClose();
}

const { register, handleSubmit, control, errors, watch,formState } = useForm({      
  mode:'onBlur',
  resolver: yupResolver(PackageSchema),
});

  let initialState = {
    packageName: '',
    packageDescription: '',
    price: '',
    boothSize: '',
    meetingsAllowed: '',
    accessBadgeAllowed: '',
    privateWebinar: '',
    firesideChat: '',
    membersAllowed: '',
    additionalMembersCosting: '',
    emailMarketing: false,
    subscriptionToCH: false,

    hotel_room: '',
    Options_for_buyers: [{id: Math.random(), optvalue: '', optname: ''}],
    marketing_options: [{id: Math.random(), optvalue: '', optname: ''}],
  };

  const [state, setState] = useState(packageData.packageName ? packageData : initialState);

  const watchAll = watch();

  const handleChange = input => e => {    
    if(input == 'packageDescription'){
      setState({...state, [input]: e.target.value.slice(0, 255)});
    }
    else                
        {
          setState({...state, [input] : e.target.value});         

        }
  }

  console.log('packageData', packageData, state);

  const save = async() => {
    if(packageData.packageId || packageData.id)
      savePackage({...packageData, ...state});
    else
    {
      let random = '10' + Math.floor(Math.random() * 100000);
      savePackage({...packageData, ...state, id: +random})
    }


    setState(initialState);
    handleClose();
  }

  const handleChangeOptions = (i, input) => e => {
    console.log('e', e.target.value, e.target);
    if (input === 'optname' || input === 'optvalue') {
      let options = [...state.Options_for_buyers];
      console.log('options', options);
      if (input === 'optname') {
        options[i].optname = e.target.value;
      }
      if (input === 'optvalue')
        options[i].optvalue = e.target.value;
      setState({ ...state,Options_for_buyers: options })
    } 
    console.log("add", e.target.value, e.target);
  }

  const handleChangeMarketingOptions = (i, input) => e => {
    console.log('e', e.target.value, e.target);
    if (input === 'optname' || input === 'optvalue') {
      let options = [...state.marketing_options];
      console.log('options', options);
      if (input === 'optname') {
        options[i].optname = e.target.value;
      }
      if (input === 'optvalue')
        options[i].optvalue = e.target.value;
      setState({ ...state,marketing_options: options })
    } 
    console.log("add", e.target.value, e.target);
  }

  const addOptions = () => {
    
    
      state.Options_for_buyers.push({ id: Math.random(), optname: null, optvalue: null })
    
    setState({ ...state });
  }

  const addMarketingOptions = () => {
    
    
    state.marketing_options.push({ id: Math.random(), optname: null, optvalue: null })
  
  setState({ ...state });
}

  const deleteOptions = (item, i, input) => {
      
        if(input=='marketing'){
          let temp = state.marketing_options.filter(x => x.id!=item.id);
          setState({...state, marketing_options: temp});
        }else{
          let temp = state.Options_for_buyers.filter(x => x.id!=item.id);
          setState({...state, Options_for_buyers: temp});
        }
        
      
      
    }

    console.log('watchall', watchAll)

  return (
    <div>
      <Modal className="modal-dialog-centered" size="lg" isOpen={visible}>

      <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="type" name="type" ref={register} value={packageData.isSeller} /> 

      <div className="modal-header">
           <h2><strong>Package</strong></h2>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={closeOpen}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <form noValidate onSubmit={handleSubmit(save)}>
          <div className="modal-body">
          
                <Row>
                <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">    
                <FormInput
                   className=""
                      id="PackageName"
                      label="Package Name"
                      type="text"
                      name='packageName'
                      register={register}
                       error={errors.packageName}
                       defaultValue={state.packageName}
                       value={watchAll.packageName} 
                       onChange={handleChange('packageName')}

                      // autoComplete="current-password"
                      variant="outlined"
                    />  
                  
                </div>
                </div>

                <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">    
                <FormInput
                   className=""
                      id="pricefree"
                      label="Price/Free"
                      type="number"
                      name="price"
                      // autoComplete="current-password"
                      variant="outlined"
                      register={register}
                       error={errors.price}
                       defaultValue={state.price}
                       value={watchAll.price} 
                       onChange={handleChange('price')}
                    />  
                  
                </div>
                </div>
            

                {packageData.isSeller=='1' && <><div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">    
                <FormInput
                   className=""
                      id="BoothSize"
                      label="Booth Size"
                      type="number"
                      name="boothSize"
                      // autoComplete="current-password"
                      variant="outlined"
                      register={register}
                       error={errors.boothSize}
                       defaultValue={state.boothSize}
                       value={watchAll.boothSize} 
                       onChange={handleChange('boothSize')}
                    />  
                  
                </div>
                </div></>}

                <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">    
                <FormInput
                   className=""
                      id="NoofmeetingAllowed"
                      label="No of meeting Allowed"
                      type="number"
                      name="meetingsAllowed"
                      // autoComplete="current-password"
                      variant="outlined"
                      register={register}
                       error={errors.meetingsAllowed}
                       defaultValue={state.meetingsAllowed}
                       value={watchAll.meetingsAllowed} 
                       onChange={handleChange('meetingsAllowed')}
                    />  
                  
                </div>
                </div>
           

            {/* <Row> */}
                <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">    
                <FormInput
                   className=""
                      id="NoofAccessBadgeallowed"
                      label="No of Access Badge allowed"
                      type="text"
                      name="accessBadge"
                      // autoComplete="current-password"
                      variant="outlined"
                      register={register}
                       error={errors.accessBadge}
                       defaultValue={state.accessBadgeAllowed}
                       value={watchAll.accessBadge} 
                       onChange={handleChange('accessBadgeAllowed')}
                    />  
                  
                </div>
                </div>

               { packageData.isSeller=='1' && <><div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">    
                <FormInput
                   className=""
                      id="NoofPrivatewebinar"
                      label="No of Private webinar"
                      type="number"
                      name="privateWebinar"
                      // autoComplete="current-password"
                      variant="outlined"
                      register={register}
                       error={errors.privateWebinar}
                       defaultValue={state.privateWebinar}
                       value={watchAll.privateWebinar} 
                       onChange={handleChange('privateWebinar')}
                    />  
                  
                </div>
                </div>
            {/* </Row> */}

            {/* <Row> */}
                <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">    
                <FormInput
                   className=""
                      id="NoofFiresidechatallowed"
                      label="No of Fireside chat allowed"
                      type="number"
                      name="firesideChat"
                      // autoComplete="current-password"
                      variant="outlined"
                      register={register}
                       error={errors.firesideChat}
                       defaultValue={state.firesideChat}
                       value={watchAll.firesideChat} 
                       onChange={handleChange('firesideChat')}
                    />  
                  
                </div>
                </div>
                </>
}
                {/* <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">    
                <FormInput
                   className=""
                      id="membersAllowed"
                      label="members Allowed"
                      type="number"
                      name="membersAllowed"
                      // autoComplete="current-password"
                      variant="outlined"
                      register={register}
                       error={errors.membersAllowed}
                       defaultValue={state.membersAllowed}
                       value={watchAll.membersAllowed} 
                       onChange={handleChange('membersAllowed')}
                    />  
                  
                </div>
                </div> */}
            {/* </Row> */}

            
                <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">
                <div className="form-group">    
                <FormInput
                   className=""
                      id="AdditionalMembersCosting"
                      label="Additional Members Costing"
                      type="number"
                      name="additionalCosting"
                      // autoComplete="current-password"
                      variant="outlined"
                      register={register}
                       error={errors.additionalCosting}
                       defaultValue={state.additionalMembersCosting}
                       value={watchAll.additionalCosting} 
                       onChange={handleChange('additionalMembersCosting')}
                    />  
                  
                </div>
                </div>

                


                {/* <div className="col-lg-6 col-md-4 col-xs-12 mt-2 addkey">

                <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="packageDescription" name="packageDescription" ref={register} value={state.packageDescription} /> 
    {!errors.packageDescription || (state.packageDescription.length > 5 && state.packageDescription.length < 180) ? <h4 >Package Description * (255 Characters Max)
      {state.packageDescription?.length>0 && <span style={{color:state.packageDescription?.length>180?"red":"#9ea2a5"}}> remaining characters  {255-state.packageDescription.length}</span> }
    </h4> : 
    <h4 >Package Description * (255 Characters Max) <span style={{color: '#f44336', float: 'right', fontSize:'0.75rem'}}>{
     state.packageDescription.length <= 1? 'Package Description can\'t be empty.' : state.packageDescription.length <= 5 ? 'Package Description must have min 5 characters' : state.packageDescription.length > 255 ? 'Package Description must have max 255 characters' : ''
    }</span> </h4> }   

                <div className="form-group">    
                <FormTextArea
                  className="buytext2 w-100 h-auto"
                  name="note"
                  id="note" 
                  rows="3"
                  type="textarea"
                  // label="Package Description *"
                  placeholder="Tell us about the Event -max 280 characters *"
                       defaultValue={state.packageDescription}
                       onChange={handleChange('packageDescription')}
              />  
                  
                </div>
                </div> */}
           

           
                {packageData.isSeller=='1'  && 
        //         <div className="col-lg-6 col-sm-12 ">
                 
                 
        //                 <div className="chck">
        //                             <FormCheckBox
        //                                 id="EmailMarketing"
        //                                 type="checkbox"
        //                                 label="Email Marketing"
        //                                 checked={state.emailMarketing}
        //                                 // label1={{textAlign:"justify"}}
        //                                 // onClick={handleChange(x.name)}
        //                                 // data-type="1"
        //                                 //register={register}
        //                                 onChange={handleChange('emailMarketing')}
        //                                 value={state.emailMarketing}
        //                             />
        //                         </div>
                            

                    
        //     </div>

        //     <div className="col-lg-6 col-sm-12 mt-4">
        //     <div className="chck">
        //                             <FormCheckBox
        //                                 id="SubscriptionCH"
        //                                 type="checkbox"
        //                                 label="Subscription to CH Platform"
        //                                 checked={state.subscriptionToCH}
        //                                 // label1={{textAlign:"justify"}}
        //                                 // onClick={handleChange(x.name)}
        //                                 // data-type="1"
        //                                 //register={register}
        //                                 onChange={handleChange('subscriptionToCH')}
        //                                 value={state.subscriptionToCH}
        //                             />
        //                         </div>
                          
        // </div>

        <>
                <div className="col-lg-6  col-xs-12 mt-2  addkey">
                <div className="form-group">    
                <FormInput
                   className=""
                      id="emailMarketing"
                      label="No.of. Email Marketing"
                      type="number"
                      name="emailMarketing"
                      // autoComplete="current-password"
                      variant="outlined"
                      register={register}
                       error={errors.emailMarketing}
                       defaultValue={state.emailMarketing}
                       value={watchAll.emailMarketing} 
                       onChange={handleChange('emailMarketing')}
                    />  
                  
                </div>
                </div>

                <div className="col-lg-6  col-xs-12 mt-2 addkey">
                <div className="form-group">    
                <FormInput
                   className=""
                      id="subscriptionToCH"
                      label="No.of. year CH Subscription"
                      type="number"
                      name="subscriptionToCH"
                      // autoComplete="current-password"
                      variant="outlined"
                      register={register}
                       error={errors.subscriptionToCH}
                       defaultValue={state.subscriptionToCH}
                       value={watchAll.subscriptionToCH} 
                       onChange={handleChange('subscriptionToCH')}
                    />  
                  
                </div>
                </div>
        </>

        }

            <div className="col-lg-12 col-md-12 col-xs-12 mt-2 addkey">
                <div className="form-group">    
                <FormInput
                   className=""
                      id="HotelRooms"
                      label="Hotel Rooms"
                      type="text"
                      name="hotel_room"
                      // autoComplete="current-password"
                      variant="outlined"
                      register={register}
                       error={errors.additionalCosting}
                       defaultValue={state.hotel_room}
                       value={watchAll.hotel_room} 
                       onChange={handleChange('hotel_room')}
                    />  
                  
                </div>
                </div>

                </Row>
    
    <h3 className="mb-2">Options for Buyers</h3>
   
    
    {state.Options_for_buyers && state.Options_for_buyers.map((x,i) => 
      <Row>
      <Col lg="6">
      <FormInput className="comp-name mt-4" id={`Options_for_buyers[${i}].optname`} name={`Options_for_buyers[${i}].optname`} label="text" type="text" variant="outlined" 
      value={watchAll[`Options_for_buyers[${i}].optname`]}
      onChange={handleChangeOptions(i, 'optname')}
      register={register}
      error={errors.Options_for_buyers ? errors.Options_for_buyers[i]?.optname : ''}
      defaultValue={x.optname}
      />
      </Col>

      <Col lg="5">
      <FormInput className="comp-name mt-4" id={`Options_for_buyers[${i}].optvalue`} name={`Options_for_buyers[${i}].optvalue`} label="value" type="text" variant="outlined" 
      value={watchAll[`Options_for_buyers[${i}].optvalue`]}
      onChange={handleChangeOptions(i, 'optvalue')}
      register={register}
      error={errors.Options_for_buyers ? errors.Options_for_buyers[i]?.optvalue : ''}
      defaultValue={x.optvalue}
      />
      </Col>
      {i == 0 ? <Col lg="1">
        <div className="faplus">
      <i  class="fa fa-plus-circle" onClick={addOptions}></i>
      </div>
      </Col> : 
      <Col lg="1">
        <div className="faminus">
        <i class="fa fa-minus-circle " onClick={() => deleteOptions(x, i, 'options')}></i>
      </div>
      </Col>}
    </Row>)}

    {packageData.isSeller=='1' && 
    
    <h3 className="mb-2 mt-2">Marketing Options</h3>
    
    }

    {packageData.isSeller=='1' && state.marketing_options && state.marketing_options.map((x,i) => 
      <Row>
      <Col lg="6">
      <FormInput className="comp-name mt-4" id={`marketing_options[${i}].optname`} name={`marketing_options[${i}].optname`} label="text" type="text" variant="outlined" 
      value={watchAll[`marketing_options[${i}].optname`]}
      onChange={handleChangeMarketingOptions(i, 'optname')}
      register={register}
      error={errors.marketing_options ? errors.marketing_options[i]?.optname : ''}
      defaultValue={x.optname}
      />
      </Col>

      <Col lg="5">
      <FormInput className="comp-name mt-4" id={`marketing_options[${i}].optvalue`} name={`marketing_options[${i}].optvalue`} label="value" type="text" variant="outlined" 
      value={watchAll[`marketing_options[${i}].optvalue`]}
      onChange={handleChangeMarketingOptions(i, 'optvalue')}
      register={register}
      error={errors.marketing_options ? errors.marketing_options[i]?.optvalue : ''}
      defaultValue={x.optvalue}
      />
      </Col>
      {i == 0 ? <Col lg="1">
      <div className="faplus">
      <i  class="fa fa-plus-circle" onClick={addMarketingOptions}></i>
      </div>
      </Col> : 
      <Col lg="1">
        <div className="faminus">
        <i class="fa fa-minus-circle " onClick={() => deleteOptions(x, i, 'marketing')}></i>
      </div>
      </Col>}
    </Row>)}

    

        
          </div>
          
          <div className="modal-footer">
          <Button color="primary" type="submit">Save</Button>

          <Button color="secondary" data-dismiss="modal" type="button" onClick={e => handleClose()} >Cancel</Button>
          </div>
          </form>
        </Modal>
    </div>
  );
}
export default  EventPackage;