//import { authHeader } from '../_helpers';

import axios from 'axios';

export const dashboardService = {
    dashboard_count,    
    update_company_profile_save,
    updateTradeInfo,
    updateSettingInfo,
};

function dashboard_count() {
    return axios.get('/getDashboardcount?subscriptionId=19').then(res =>{
         console.log("login:",res.data);
          return res.data;
        });

}

async function update_company_profile_save(company) {
  
    console.log("service:",company);
      return await axios.post('/channelAdmin/updateCompany',company).then(res =>{
           console.log("company:",company);
            return 'ok';
          });
  }


async function updateTradeInfo(product) {
console.log("service:",product);
    return await axios.post('/channelAdmin/updateTradeInfo',product).then(res =>{
        //console.log("company:",user);
        return 'ok';
        });
}

async function updateSettingInfo(data) {
    //console.log("service:",data);
      return await axios.post('/channelAdmin/updatesettings',data).then(res =>{
           //console.log("company:",user);
            return 'ok';
          });
  }
  


