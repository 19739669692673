import React, { useState, useEffect } from 'react';
import {Card,CardBody,Row,Col,Container,NavItem,NavLink,Nav,TabContent,TabPane} from "reactstrap";
import {SortableContainer, SortableElement,arrayMove} from 'react-sortable-hoc';
import axios from 'axios';
import { commonService } from '_services/common.service';
var _ = require('lodash');
let items =
[
  {
      "title": "Item 0",
      "index": 0,
      "id": 1,
      "imageSrc": "https://d35w8j22j5uly8.cloudfront.net/document/product/packing/0fz2iZqt_1614278863657_1614278899685.png"
  },
  {
      "title": "Item 1",
      "index": 1,
      "id": 2,
      "imageSrc": "https://d35w8j22j5uly8.cloudfront.net/document/product/display/3yw4y1Xh_1588601863406_1588602029491.jpg"
  },
  {
      "title": "Item 2",
      "index": 2,
      "id": 3,
      "imageSrc": "https://d35w8j22j5uly8.cloudfront.net/document/product/packing/iTVflOSF_1599464112143_1599464181583.png"
  },
  {
      "title": "Item 3",
      "index": 3,
      "id": 4,
      "imageSrc": "https://d35w8j22j5uly8.cloudfront.net/document/product/image/fVLo3nES_1614079406445_1614079421638.png"
  },
  {
      "title": "Item 4",
      "index": 4,
      "id": 5,
      "imageSrc": "https://d35w8j22j5uly8.cloudfront.net/document/product/image/TkRDe97k_1614078945999_1614078980807.png"
  }
];

    const Reorder = (props) => {
      const { productList } = props;
      items=productList;
      
const [state, setstate] = useState({items})     
 
const onSortEnd = ({ oldIndex, newIndex }) => {  
  let list=state.items;   
  list.map((x,index) => {
    if(index==newIndex-1)    
    {
      //if(oldIndex>newIndex)
        list[oldIndex-1].product_rank_order=newIndex;      
      // else if(oldIndex>newIndex)
      //   list[newIndex-1].product_rank_order=product_rank_order-1;      
      list.map((y,index1) => {
        if(index1>=index && index1<oldIndex-1 && oldIndex>newIndex)
        {
          y.product_rank_order=y.product_rank_order+1;
        }   
        else if(index1>oldIndex-1 && index1<=newIndex-1 && oldIndex<newIndex)
        {
          y.product_rank_order=y.product_rank_order-1;
        }
      })
    }      

  })

  state.items=list;
  setstate({
      items: _.orderBy(list,'product_rank_order','asc') //arrayMove(state.items, oldIndex, newIndex)
    });

    commonService.putService('product/setProductOrder',state.items);        
    //console.log("reorder:",state.items,oldIndex,newIndex);
  };
      const SortableItem = SortableElement(({ item }) => (
           <Col lg="4" xl="4" md="4" sm="12">
           <div class='key-card pointer'>
               <div class='set-image'>
                   <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}   src={item.documentUrl}/>
               </div>
               <div class='set-headline mt-2 text-center'>
                   <h3> {item.product} </h3>
               </div>
           </div>
       </Col>
      ));
  
      const SortableList = SortableContainer(({ items }) => (
        <div className="row">
          {items.map((item, index) => (
            <SortableItem
              key={`${item.productId}`}
              index={+item.product_rank_order}
              item={item}
            />
          ))}
        </div>
      ));

        return (
            <>

<SortableList
        items={state.items}
        onSortEnd={onSortEnd}
        axis="xy"
        helperClass="SortableHelper"
      />
          </>
         );
    }


export default Reorder;