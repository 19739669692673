import { authHeader } from '../_helpers';
import { LocalStorageService } from '../_services';
import axios from 'axios';
import { history } from '_helpers/history';

export const userService = {
    login,
    logout,
    register,
    getAll,
    getById,
    update,
    delete: _delete
};

function login(username, password, apptoken) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return axios.post('auth/login', {
        username: username,//email:username,
        password: password + "-admin",

    },
        {
            headers: {
                'Authorization': apptoken
            }
        }).then(res => {

            console.log("login:", res.data);
            let user = {
                userId: res.data.user.adminUserId,
                firstName: res.data.user.firstName,
                lastName: res.data.user.lastName,
                accessId: res.data.user.accessId,
                isAdmin: true,
            }

            localStorage.setItem('CHuser', JSON.stringify(user));



            //  if(res.data.length>0)
            return res;
            //   else
            //   {
            //   console.log("abcd");
            //   return Promise.reject('Invalid Username or password');
            //   }

        }).catch(error => {
            //console.log("error:",error.response.data[0].errors[0].message);
            return Promise.reject('invalid');
        });


    // return fetch(`/users/authenticate`, requestOptions)
    //     .then(handleResponse)
    //     .then(user => {
    //         // store user details and jwt token in local storage to keep user logged in between page refreshes
    //         localStorage.setItem('user', JSON.stringify(user));

    //         return user;
    //     });
}

async function logout() {
    // remove user from local storage to log user out
    const localStorageService = LocalStorageService.getService();
    await axios.get('auth/logout').then(res => {
        console.log('logged out');
    }).catch(err => {
        console.log('something went wrong while logging out');
    });
    // window.location.assign('/');
    history.push('/');
    localStorageService.clearToken();
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/users`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`/users/${user.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    console.log("responose:", response);
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}