import * as React from 'react';
import Button from '@mui/material/Button';
import GroupsIcon from '@mui/icons-material/Groups';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import SettingsIcon from '@mui/icons-material/Settings';

import MenuItem from '@mui/material/MenuItem';

import HoverMenu from 'material-ui-popup-state/HoverMenu'

import Stack from '@mui/material/Stack';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import ChevronRight from '@material-ui/icons/ChevronRight'
import { makeStyles } from '@material-ui/styles'

import { Card, CardBody, Container, Row, Col, Media, CardHeader, UncontrolledTooltip } from "reactstrap";

import {
  usePopupState,
  bindHover,
  bindMenu,
  bindFocus,
} from 'material-ui-popup-state/hooks'
import Agenda from './Agenda';
import Timeslot from './Timeslot';
import Participants from './Participants';

const useStyles = makeStyles((theme) => ({

  menuItem: {
    "&:hover": {
      backgroundColor: "#009900"
    }
  }
}));


const CascadingContext = React.createContext({
  parentPopupState: null,
  rootPopupState: null,
})

function CascadingMenuItem({ onClick, ...props }) {
  const { rootPopupState } = React.useContext(CascadingContext)
  if (!rootPopupState) throw new Error('must be used inside a CascadingMenu')
  const handleClick = React.useCallback(
    (event) => {
      rootPopupState.close(event)
      if (onClick) onClick(event)
    },
    [rootPopupState, onClick]
  )

  return <MenuItem {...props} onClick={handleClick} />
}

function CascadingSubmenu({ title, popupId, ...props }) {
  // const classes = useCascadingMenuStyles()
  const { parentPopupState } = React.useContext(CascadingContext)
  const popupState = usePopupState({
    popupId,
    variant: 'popover',
    parentPopupState,
  })
  return (
    <React.Fragment>
      <MenuItem {...bindHover(popupState)} {...bindFocus(popupState)}>
        <span>{title}</span>
        <ChevronRight />
      </MenuItem>
      <CascadingMenu
        {...props}
        classes={{ ...props.classes }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        popupState={popupState}
      />
    </React.Fragment>
  )
}

function CascadingMenu({ popupState, ...props }) {
  const { rootPopupState } = React.useContext(CascadingContext)
  const context = React.useMemo(
    () => ({
      rootPopupState: rootPopupState || popupState,
      parentPopupState: popupState,
    }),
    [rootPopupState, popupState]
  )

  return (
    <CascadingContext.Provider value={context}>
      <HoverMenu {...props} {...bindMenu(popupState)} />
    </CascadingContext.Provider>
  )
}



const EventHome = (props) => {
  
  const classes = useStyles();

  const [id, setId] = React.useState(0);

  const popupState = usePopupState({ variant: 'popover', popupId: '1' })

  const popupState1 = usePopupState({ variant: 'popover', popupId: '2' })

  const popupState3 = usePopupState({ variant: 'popover', popupId: '3' })

  const menuClick = (id) => {
    let menuState = popupState + id;
    menuState.close();
    setId(id);
  }

  return (

    <div id="usermanage">


      {/* <SimpleHeader name="admin-users" parentName="Dashboard" /> */}

      <Container className="mt--3" fluid>

        <Card>
          <CardHeader>
            <h3 className="mb-0">Event Meet</h3>
          </CardHeader>

          <React.Fragment>

            <Stack direction="row" spacing={2}>
              <Button className="btn-sin btn btn-primary" variant="contained"  startIcon={<GroupsIcon/>} onClick={() => setId(1)}  >Participants</Button>

              <Button className="btn-sin btn btn-primary" variant="contained"  startIcon={<MarkEmailUnreadIcon />}>Communication</Button>

              <Button className="btn-sin btn btn-primary" variant="contained" {...bindHover(popupState3)} startIcon={<EventNoteIcon />} >Agenda</Button>

              <Button className="btn-sin btn btn-primary" variant="contained"  startIcon={<ConnectingAirportsIcon />}>Travel</Button>

              <Button className="btn-sin btn btn-primary" variant="contained"  startIcon={<SettingsIcon />}>Travel</Button>


            </Stack>

            <HoverMenu {...bindMenu(popupState)}>
              {/* <MenuItem onClick={popupState.close}>Profile</MenuItem>
              <MenuItem onClick={popupState.close}>My account</MenuItem>
              <MenuItem onClick={popupState.close}>Logout</MenuItem> */}
            </HoverMenu>

            <HoverMenu {...bindMenu(popupState1)}>

              {/* <MenuItem onClick={popupState1.close} className={classes.menuItem} >My account</MenuItem>
              <MenuItem onClick={popupState1.close} className={classes.menuItem}>Logout</MenuItem> */}

              <CascadingSubmenu
                popupId="moreChoicesCascadingMenu"
                title="More Choices"
              >
                {/* <CascadingMenuItem>Cheesecake</CascadingMenuItem>
                <CascadingMenuItem>Cheesedeath</CascadingMenuItem> */}

              </CascadingSubmenu>

            </HoverMenu>

            <HoverMenu {...bindMenu(popupState3)}>

              <MenuItem onClick={() => setId(31)}>Event</MenuItem>
              <MenuItem onClick={() => setId(32)}>Event Agenda</MenuItem>
              <MenuItem onClick={() => setId(33)}>One-on-one timeslots</MenuItem>


            </HoverMenu>

          </React.Fragment>


        </Card>
        {id == 32 &&
          <Agenda />
        }

        {id == 33 &&
          <Timeslot />
        }

{id == 1 &&
          <Participants />
        }


      </Container>
    </div>



  );

}

export default EventHome;