import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Card, Container, Row, CardHeader, UncontrolledTooltip, ListGroupItem,
  ListGroup, Col, Badge, Button
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Filter from "components/Filter-Right/Filter.js";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useForm } from "react-hook-form";
import { SelectAutocomplete } from '_components/FormElements/FormInput';
import axios from 'axios';
import { Spinner } from '../../Hoc/Spinner'
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/PaginationActions';
import Dialog from 'views/Hoc/Dialog/GlobalDialog';
import NotificationAlert from "react-notification-alert";
import UserView from 'views/Hoc/Dialog/UserView';
import Drawer from 'views/Hoc/Dialog/Globaldrawer';
import Connect from 'views/Hoc/Dialog/Connect';
import { history } from '_helpers/history';
import CompanyPublicSettings from 'views/Hoc/Dialog/CompanyPublicSettings';
import { commonService } from '_services/common.service';
import DateFnsUtils from '@date-io/date-fns';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { $CombinedState } from 'redux';


const toggleSidenav = e => {
  console.log(e)

  if (document.getElementById('filter').classList.contains("g-sidenav-pinned")) {
    document.getElementById('filter').classList.remove("g-sidenav-pinned");
    document.getElementById('filter').classList.add("g-sidenav-hidden");
  } else {
    document.getElementById('filter').classList.add("g-sidenav-pinned");
    document.getElementById('filter').classList.remove("g-sidenav-hidden");
  }
  // function handleOrangeClick({
  //  sidenavOpen: setOpen(!sidenavOpen)
  // });
};
const company_type_Option = [
  { name: "Vendor", id: 2 },
  { name: "Distributor", id: 3 },
  { name: "Retailer", id: 4 },
  { name: "Reseller", id: 5 }
];
const is_OEM_Option = [
  { name: "Yes", id: 'yes' },
  { name: "No", id: 'no' },
]
export const FailureTransaction = (props) => {
  const notificationAlert = useRef(null);
  const { control } = useForm({ mode: 'onBlur' });
  const [showDrawer, setshowDrawer] = useState(false);
  const [connect, setshowconnect] = useState(false);
  const [subscriptionId,setSubscriptionId]=useState();
  const [contactid, setContact] = useState();

  const [user, setUser] = useState({ firstName: '', lastName: '', jobTitle: '', country: '' });

  const [state, setState] = useState({ dataTable: [], total: 0 });
  const [searchState, setSearch] = useState({ search: null, dropdownList: [], fromDate:null , toDate:null });
  const [filterValueState, setFilterValue] = useState({ Company_type: null, is_OEM: null, country: [], plan: [], product_Categories: [] });
  const [filterOptionState, setFilterOption] = useState({ countryOption: [], product_cat_Option: [], plan_Option: [], company_type_Option: [], is_OEM_Option: is_OEM_Option });

  let [loading, setLoading] = useState(true);
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [open, setModalOpen] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null });
  let adminUser = JSON.parse(localStorage.getItem('CHuser'));
  const [drawerInput, setDrawerInput] = useState(null);

  useEffect(() => {
    if (adminUser.accessId == 3 || adminUser.accessId == 4)
      filterOptionState.company_type_Option = company_type_Option.filter(i => [3, 4, 5].includes(i.id));
    else
      filterOptionState.company_type_Option = company_type_Option;

    setFilterOption({ ...filterOptionState, company_type_Option: company_type_Option });

    getPendingChannel();
    getPendingChannelsList();
    
  }, []);


  const getFilterData = () => {
    let filter = [];
    if (filterValueState.country && filterValueState.country.length > 0) {
      filter.countryId = filterValueState.country.map(x => parseInt(x.id));
    }
    if (filterValueState.plan && filterValueState.plan.length > 0) {
      filter.planId = filterValueState.plan.map(x => parseInt(x.id));
    }

    if (filterValueState.Company_type) {
      filter.channelTypeId = filterValueState.Company_type.id;
    }
    if (filterValueState.is_OEM) {
      filter.is_OEM = filterValueState.is_OEM.id;
    }
    if (filterValueState.product_Categories && filterValueState.product_Categories.length > 0) {
      filter.product_Categories = filterValueState.product_Categories.map(x => parseInt(x.id));
    }
    return filter;
  }
  const getPendingChannel = async () => {

    loading = true;
    setLoading(loading);
    var filter = await getFilterData();
    let data = {
      methodId: searchState.search ? searchState.search[0] ? searchState.search[0].id : 3 : 3,
      fromDate: searchState.fromDate ? searchState.fromDate[0] :null,
      toDate: searchState.toDate ? searchState.toDate[0] : null,
      // filter: {
      //   countryId: filter.countryId ? filter.countryId : null,
      //   planId: filter.planId ? filter.planId : null,
      //   channelTypeId: filter.channelTypeId ? filter.channelTypeId : null,
      //   is_OEM: filter.is_OEM ? filter.is_OEM : null,
      //   product_Categories: filter.product_Categories ? filter.product_Categories : null
      // },
      page: page * rowsPerPage,
      limit: rowsPerPage
    }
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.post('/report/getTransactionReport',
      data).then(res => {
        //console.log("pending:",res);
        state.dataTable = res.data.rows;
        state.total = res.data.count;
        loading = false;
        setLoading(loading);
        setState({ ...state });
      }).catch((err) => {
        state.dataTable = [];
        state.total = 0;
        loading = false;
        setLoading(loading);
        setState({ ...state });
      })
  }

  const getPendingChannelsList = () => {
   
     let data = [
 {name: 'Alipay', id: '1'},
 {name: 'Card', id: '1'},
{name: 'Paypal', id: '3'}];

      searchState.dropdownList = data
      setSearch({ dropdownList: searchState.dropdownList });
   
  }

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getPendingChannel();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    callPendingChannel();
  };
  
  const handleChangeFromDate = (input, value) => {
    if (searchState.fromDate < searchState.toDate){
      searchState.fromDate=value;
      setSearch({ ...searchState })
      setFilterValue({ ...filterValueState })
    } else {
      ToastsStore.error('To Date Must be greater than to date.');
    }
  }
  const handleChangeToDate = (input, value) => {
    if (searchState.fromDate < searchState.toDate){
      searchState.toDate=value;
      setSearch({ ...searchState })
      setFilterValue({ ...filterValueState })
    } else {
      ToastsStore.error('To Date Must be greater than to date.');
    }
  }
  const handleChangeSelect = (input, value) => {
    
    if (input == 'search') {
      setFilterValue({ ...filterValueState });
      searchState.search = [value]
      setSearch({ ...searchState })
    }
    
  }

  const callPendingChannel = () => {
    closeDrawer();
    page = 0;
    setPage(page);
    getPendingChannel();
  }
  const resetFilterValue = () => {
    filterValueState.Company_type = null;
    filterValueState.is_OEM = null;
    filterValueState.country = [];
    filterValueState.plan = [];
    filterValueState.product_Categories = [];
    setFilterValue({ ...filterValueState });
    callPendingChannel();
  }
  const notify = (type, message) => {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {type}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      // icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const updateStatus = (data, id, contact) => {
    console.log('contact', contact);
    loading = true;
    setLoading(loading);
    axios.put('/channelAdmin/channelApproval', {
      channelId: data,
      signUpStatusId: id
    }).then(res => {
      if (res) {
        id == 15 ? notify('success', 'Channel Approved successfully') : notify('warning', 'Channel Dismiss successfully');
        if (id == 15) {
          let maildata = {
            to: contact.email,
            contactId: contact.contactId,
            profileurl: process.env.REACT_APP_LOCALSITE + 'login'
          }
          axios.post('mailerservice/Channel_Approval_Mail', maildata);
        }
        else {
          let maildata = {
            to: contact.email,
            contactId: contact.contactId,
            //profileurl: process.env.REACT_APP_LOCALSITE + 'login'
          }
          axios.post('mailerservice/Channel_Dismiss_Mail', maildata);
        }
        callPendingChannel();
        searchState.search = null
        setSearch({ ...searchState, search: searchState.search })
      }
    }).catch((err) => {
      console.log('err', err)
      loading = false;
      setLoading(loading);
      notify('danger', 'Soming went wrong');
    })
  }

  const openContact = (data) => {
    setDrawerInput(data);
    setshowDrawer(true);
    console.log('datauser', data);
  }

  const openModalPopUp = (ch_Id, id, contact) => {
    if (id === 15) {
      modalPopValue.message = 'You want to Approve';
      modalPopValue.title = "Approve Channel";
      modalPopValue.value = { channelId: ch_Id, id: id, contact: contact };
    } else {
      modalPopValue.message = 'You want to Dismiss';
      modalPopValue.title = "Dismiss Channel";
      modalPopValue.value = { channelId: ch_Id, id: id, contact: contact };
    }
    modalPopValue.popUpId = 1;
    setModalPopValue({ ...modalPopValue })
    open = true;
    setModalOpen(open);
  };

  const handleClose = () => {
    open = false;
    setModalOpen(open);
    console.log(open, 'open');
  };
  const handleSave = (value) => {
    open = false;
    setModalOpen(open);
    updateStatus(value.value.channelId, value.value.id, value.value.contact);
  };
  const [userview, setuserview] = useState(false)
  const login = (contactId, firstName, lastName, jobTitle, country, channelId, channelTypeId, documentPath) => {
    setContact(contactId);
    setUser({ firstName: firstName, lastName: lastName, jobTitle: jobTitle, country: country, channelId: channelId, channelTypeId: channelTypeId, documentPath: documentPath });
    setuserview(true);

  }
  const closelogin = () => {
    setuserview(false);
  }

  const closeDrawer = () => {
    setshowDrawer(false);
  }

  const closeConnect = () => {
    setshowconnect(false);
  }
  const openConnect = () => {
    setshowconnect(true);
  }
  const opneUser = () => {
    closeConnect();
    setshowDrawer(true);
  }
  // style={{width:'46%',float:'right'}}
  const response = {
    
    renderer: (row, rowIndex) => {
      
      return (
        <div style={{ width: '100%' }}>
          
          {/* {row.length && row.map((x, i) => */}
          
            <ListGroup className="list my--2" flush key={'table' + row.failureTransactionId}>
            
              <ListGroupItem className="px-0">
                <Row className="align-items-center">
                { console.log(row.response) }
                 {row.response}
                </Row>
              </ListGroupItem>

            </ListGroup>)
         {/* } */ }
        </div>
      )
    },
    className: 'bgrow'
  };
  //const [openSide,setOpen] = React.useState(1);
  const [sidenavOpen, setOpen] = useState(false);

  const [pubFlag, setPublicFlag] = useState(false);
  const [publicFlagData, setPublicFlagData] = useState({});

  const openPublicFlag = (item) => {

    console.log('public view', item);

    let data = {
      channelId: item.channelId,
      isFeatured: item.isFeatured == '1' ? true : false,
    };

    setPublicFlagData(data);
    setPublicFlag(true);

  }

  const closePublicFlag = () => {
    setPublicFlagData({});
    setPublicFlag(false);
  }

  const edit = async (contactid, channelId, channelTypeId) => {
    setuserview(false);
    let contactData = await commonService.getServices('/channelAdmin/getContact/' + contactid);
    if (contactData) {
      let data = Object.assign(contactData.data, { channelTypeId: channelTypeId, channelId: channelId, userId: null });
      setDrawerInput(data);
      setshowDrawer(true);
      console.log('datauser', data);
    }
  }

  return (
    <div id="channelList">
      <Drawer title="Subscription" visible={showDrawer} subscriptionId={subscriptionId} closeDrawer={closeDrawer}/>

      <NotificationAlert ref={notificationAlert} />
      {/* <div className={classes.root}> */}
      <Filter
        Filter={true}
        filterOption={filterOptionState}
        filterValue={filterValueState}
        resetFilterValue={resetFilterValue}
        handleChangeSelect={handleChangeSelect}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen} />

     
      <Container className="mt--3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Company Listing</h3>
              </CardHeader>
              {loading ? <Spinner /> : <ToolkitProvider
                ClassName="align-items-center table-flush" responsive
                data={state.dataTable}
                keyField="contacts[0].email"
                columns={[
                  // {
                  //   dataField: "Action",
                  //   text: "Action",
                  //   formatter: (rowContent, row) => {
                  //     return (
                  //       <>
                  //          <button className="btn btn-icon btn-primary btn-sm" variant="contained" onClick={()=>{setshowDrawer(true);setSubscriptionId(6)}} color="primary" >
                  //           <span className="btn-inner--icon"><i className="far fa-eye"></i></span>
                  //         </button>
                  //       </>
                  //     )
                  //   }
                    //},
                    {
                      dataField: "channelTypeId",
                      text: "Company Type",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {rowContent == 2 && "Vendor"}
                            {rowContent == 3 && "Distributor"}
                            {rowContent == 4 && "Retailer"}
                            {rowContent == 5 && "Reseller"}
                          </>
                        )
                      }
                    },
                  {
                    dataField: "companyName",
                    text: " Company Name",
                    sort: true
                  },
                  {
                    dataField: "rowContent",
                    text: "Details",
                    // formatter: (rowContent, row) => {
                    //   return (                      

                    //     <>
                    //     <div className="avatar-group">
                    //       {rowContent.length && rowContent.map((x, i) => <><a
                    //         className="avatar avatar-sm rounded-circle"
                    //         href="#pablo"
                    //         id={'easytool' + x.failureTransactionId}
                    //         onClick={e => e.preventDefault()}
                    //       >
                    //         Details
                    //       </a>
                    //         <UncontrolledTooltip delay={0} target={'easytool' + x.failureTransactionId}>
                    //           Details
                    //         </UncontrolledTooltip></>)}
                          
                    //     </div>
                    //     </>

                    //   )
                    // }
                  },
                  
                  {
                    dataField: "paymentTransactionAt",
                    text: "Transaction At",
                    sort: true
                  },
                ]}
              >
                {props => (
                  <div className="py-2 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 mb-2"
                    >
                      <div style={{ width: '20%' }}>
                        <SelectAutocomplete
                          id="search"
                          type="text"
                          label="search"
                          name="search"
                          options={searchState.dropdownList}
                          placeholder="search..."
                          disableCloseOnSelect
                          // defaultValue={searchState.search?searchState.search[0]:null}
                          control={control}
                          input='search'
                          value={searchState.search ? searchState.search[0] : null}
                          handleChangeSelect={handleChangeSelect}
                        />
                    
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="From_Date"
                                name="From_Date"
                                label="From Date"
                                value={searchState.fromDate}
                                onChange={handleChangeFromDate}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              /></MuiPickersUtilsProvider>

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="to_date"
                                name="To_Date"
                                label="To Date"
                                value={searchState.toDate}
                                onChange={handleChangeToDate}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              /></MuiPickersUtilsProvider>  
                      </div> 
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      expandRow={response}
                      bootstrap4={true}
                      // pagination={pagination}
                      bordered={false}
                      hover={true}

                      rowClasses='pointer'
                    />

                    <TablePagination
                      component="div"
                      count={state.total}
                      page={page}
                      onChangePage={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </div>
                )}
              </ToolkitProvider>}
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};
export default FailureTransaction;
