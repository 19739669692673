import React,{useState, useEffect} from "react";
import { Drawer } from 'antd';
import classnames from "classnames";
import { commonService } from '_services/common.service';
import {Spinner} from 'views/Hoc/Spinner';
import UserView from 'views/Hoc/Dialog/UserView';
import Globaldrawer from 'views/Hoc/Dialog/Globaldrawer';
import {FormGroup,Form,CardBody, Input,InputGroupAddon,InputGroupText,InputGroup,Row,Col} from "reactstrap";
import {history} from '_helpers/history';
import axios from 'axios';
const TopSearchList = (props) => {
    const { closeDrawer, visible, title} = props;
    let [search, setSearch] = useState('');
    let [contactList, setContactList] = useState([]);
    let [companyList, setCompanyList] = useState([]);
    let [productList, setProductList] = useState([]);
    let [loading, setLoading]  = useState(false);
    const [drawerInput, setDrawerInput] = useState(null);
    const [showDrawer,setshowDrawer]=useState(false);
    const [userview, setuserview] = useState(false);
    const [contactid,setContact]=useState();
    const [user,setUser]=useState({firstName:null,lastName:null,jobTitle:null,country:null, channelId:null, channelTypeId:null});
    const ref = React.useRef(null);
    useEffect(() => {
        if(visible){
            console.log('ref',ref)
            ref.current &&  ref.current.focus();
        }
    }, [visible]);
    const handleChange = (input)=> (e) => {
        search=e.target.value;
        setSearch(search);
        console.log('search', search);
        getSearchList();
    }
    const getSearchList =async ()=>{
        closeEditDrawer();
        setLoading(true);
        let contactData = await commonService.postService({search:search}, '/channelAdmin/getContactSearchList');
        let companyData = await commonService.postService({search:search}, '/channelAdmin/getCompanySearchList');
        let productData = await commonService.postService({search:search}, '/Product/getProductSearchList');
        contactList = contactData.data;
        companyList = companyData.data;
        productList = productData.data;
        setContactList(contactList);
        setCompanyList(companyList);
        setProductList(productList);
        setLoading(false);
    }
    const close=()=>{
        setLoading(true);
        search=null;
        contactList = [];
        companyList = [];
        productList = [];
        setSearch(search);
        setContactList(contactList);
        setCompanyList(companyList);
        setProductList(productList);
        setLoading(false);
        closeDrawer();
    }
    //Login Popup
    const login=(contactId,firstName,lastName,jobTitle,country, channelId, channelTypeId)=>{
      setContact(contactId);
      setUser({firstName:firstName,lastName:lastName,jobTitle:jobTitle,country:country, channelId: channelId, channelTypeId:channelTypeId});
      setuserview(true);
    }

    const closelogin=()=>{
        setuserview(false);
      }

      const edit= async(contactid, channelId, channelTypeId)=>{
        setuserview(false);
        let contactData = await commonService.getServices('/channelAdmin/getContact/'+contactid);
        if(contactData){
        let data = Object.assign(contactData.data, {channelTypeId: channelTypeId, channelId: channelId, userId: null});
        setDrawerInput(data);
        setshowDrawer(true);
        console.log('datauser', data);
        }
      }
      const closeEditDrawer =()=>{
        setshowDrawer(false);
      }

      const productView=(x)=>{
        closeDrawer();
        history.push('/admin/productDetail/'+x.productSKU+'/'+x.channelId);
      }

    return (
        <div className="site-form-in-drawer-wrapper">
            <Globaldrawer title="Edit User" visible={showDrawer} contactData={drawerInput} closeDrawer={closeEditDrawer} reload={getSearchList}/>
            <UserView open={userview} closelogin={closelogin} contactid={contactid} user={user} edit={edit} />
        <Drawer
        className='TopDrawer'
        //   title={title}
          placement="top"
          height="100%"
          onClose={close}
          visible={visible}
        >
        <div className='searchlist'>
            <CardBody>
                <Row>
                <Col lg='6' className='p-0 text-center'>
                    {/* <AdminNavbar/> */}
                <Form
                className={classnames(
                  "navbar-search form-inline mr-sm-3",
                )}
              >
                <FormGroup className="mb-4">
                  <InputGroup className="input-group-alternative input-group-merge">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Search Company or Products" type="text" 
                    // autoFocus
                    ref={ref}
                    value={search}
                    onChange={handleChange("search")}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
                </Col>
                <Col lg='6' className='text-right pt-3'>
                    {/* <Form>
                    <FormGroup>
                        <h4><i class="fas fa-sliders-h"></i> Filters</h4>
                    </FormGroup>
                    </Form> */}
                </Col>
                </Row>
                {loading? <Spinner/> : (search || search!=='') && <>
            {contactList.length>0 &&<>
                            <h2 className='pb-4'>Contact</h2>
                            <Row className='pb-4 '>
            {contactList.map(x=>(
                <Col lg='3' onClick={() => login(x.contactId,x.firstName,x.lastName,x.jobTitle,x.country?.country, x.channel.channelId, x.channel.channelTypeId)}>
                    <Row>
                        <Col lg='2'>
                            <a class="avatar avatar-sm rounded-circle" href="#pablo" id="easytool41">
                            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  alt="..."
                             src={x?.document?.documentUrl? x?.document?.documentUrl :"http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} /></a>
                        </Col>
                        <Col lg='10' className='pl-0'>
                            <h4>{x?.firstName} {' '} {x?.lastName}</h4>
                            <h6>{x?.jobTitle}, { 'company of '+ x?.channel?.companyName}</h6>
                        </Col>
                    </Row>
                </Col>
            ))} </Row> </>}

            {companyList.length>0 &&<>
                            <h2 className='pb-4'>Company</h2>
                            <Row className='pb-4 '>
                                 {companyList.map(x=>(
                <Col lg='3'>
                    <Row>
                        <Col lg='2'>
                            <a class="avatar avatar-sm rounded-circle" id="easytool41"><img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
                            alt="..." src={x?.channelDetail?.channelLogo?.documentUrl? x?.channelDetail?.channelLogo?.documentUrl :"http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}

onClick={e=>{close(); history.push({pathname:'/admin/companysettings', state: {channelData: Object.assign(x, {userId: x.user[0].userId, contactId: x.user[0].contactId})}})}}

                             /></a>
                        </Col>
                        <Col lg='10' className='pl-0'>
                            <h4>{x?.companyName}</h4>
                            <h6>{x?.country?.country}, {x?.channelTypeId==='2'?"Vendor":x?.channelTypeId==='3'?'Distributor':x?.channelTypeId==='4'?'Retailer':'Reseller'}</h6>
                        </Col>
                    </Row>
                </Col>
            ))}
            </Row></>}

            {productList.length>0 && 
            <>
            <h2 className='pb-4'>Product</h2>
            <Row className='pb-4 '>
            {productList.map(x=>(
                <Col lg='3' onClick={()=>productView(x)}>
                    <Row>
                        <Col lg='2'>
                            <a class="avatar avatar-sm rounded-circle" href="#pablo" id="easytool41"><img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
                            alt="..." src={x?.logo?.image?.documentUrl? x?.logo?.image?.documentUrl :"http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} /></a>
                        </Col>
                        <Col lg='10' className='pl-0'>
                            <h4>{x?.name}</h4>
                            <h6>{x?.productSKU}, {x?.brand?.brandName}</h6>
                        </Col>
                    </Row>
                </Col>
            ))}</Row> 
            </>}
                
                </>}
            </CardBody>
        </div>
        </Drawer>
        </div>
    );
};

export default TopSearchList;