import React ,{useEffect, useState} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
Button, Modal,
} from "reactstrap";
import { ProfileUploader } from '../ProfileUpload';
import { commonService } from '_services/common.service';
import {Spinner} from 'views/Hoc/Spinner';
import {FormCheckBox} from "_components/FormElements/FormInput";
import axios from "axios";

export const HotTopics=(props)=> {
const { opneadd, closeModalPopup, brandName } = props;
const [open, setOpen] = React.useState(false);
const theme = useTheme();
const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
useEffect(() => {
setOpen(true);

}, [])

const [state, setState] = useState({});

useEffect(() => {
  getFlags();
}, []);


const getFlags = async() => {
  await axios.get('product/getPublicProductFlags/'+props.data.productId).then(res => {
    let data = {
      eco_friendly: res.data.eco_friendly == '1' ? true : false,
      work_from_home: res.data.work_from_home == '1' ? true : false,
      life_style: res.data.life_style == '1' ? true : false,
      french_tech: res.data.french_tech == '1' ? true : false,
      taiwan_excellence: res.data.taiwan_excellence == '1' ? true : false,
      isFeatured: res.data.isFeatured == '1' ? true : false,
      isPublic: res.data.isPublic == '1' ? true : false,
      show_in_home_page: res.data.show_in_home_page == '1' ? true : false,
      channelId: res.data.channelId,
      productId: res.data.productId
    }
    setState(data);
  })
};

console.log('state', state);

const handleClickOpen = () => {
setOpen(true);
};

const handleClose = () => {
setOpen(false);
};

const save = async() => {
  

  let data = {
    eco_friendly: state.eco_friendly  ? '1' : '0',
    work_from_home: state.work_from_home  ? '1' : '0',
    life_style: state.life_style  ? '1' : '0',
    french_tech: state.french_tech  ? '1' : '0',
    taiwan_excellence: state.taiwan_excellence ? '1' : '0',
    isFeatured: (state.show_in_home_page || state.isFeatured)  ? '1' : '0',
    isPublic: (state.show_in_home_page || state.isPublic)  ? '1' : '0',
    show_in_home_page: state.show_in_home_page  ? '1' : '0',
    channelId: state.channelId,
    productId: state.productId
  }

  await axios.post('product/setProductPublicFlags', data).then(res => {
    console.log('saved');
  }).catch(err => {
    console.log(err);
  })
  setState({});
  closeModalPopup();

}

const closeModalPopups= () => {
  setState({});
  closeModalPopup();
}

const handleChange  = input => e => {
  setState({...state, [input]:!state[input]});
}

return (
<div>
  
  <Modal
className="modal-dialog-centered Reorderdialog"
isOpen={opneadd} >
<div className="modal-header">
<h3 className="text-center">Guest Page Settings</h3>
<button
aria-label="Close"
className="close"
data-dismiss="modal"
type="button"
onClick={closeModalPopups}
>
<span aria-hidden={true}>×</span>
</button>
</div>
{ state.hasOwnProperty('productId') && <div className="modal-body">

<div style={{display: 'flex', justifyContent: 'space-between'}}>
<h4>Show in Home Page:</h4>
<label className="custom-toggle custom-toggle-success">
                             <input type="checkbox" checked={state.show_in_home_page}  name="status" onClick={handleChange('show_in_home_page')}  />
                             <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                             </label>                             
</div>

<div style={{display: 'flex', justifyContent: 'space-between'}}>
<h4>Is Featured Product:</h4>
<label className="custom-toggle custom-toggle-success">
                             <input type="checkbox" checked={state.show_in_home_page || state.isFeatured} disabled={state.show_in_home_page}  name="status" onClick={handleChange('isFeatured')}  />
                             <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                             </label>                             
</div>

<div style={{display: 'flex', justifyContent: 'space-between'}}>
<h4>Is Public:</h4>
<label className="custom-toggle custom-toggle-success">
                             <input type="checkbox" checked={state.show_in_home_page || state.isPublic} disabled={state.show_in_home_page}  name="status" onClick={handleChange('isPublic')}  />
                             <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                             </label>                             

</div>


<div className="mt-4">
           <h4>Hot Topics Settings</h4>
                <div className="row">
                    <div className="chck col">
          <FormCheckBox
                    id="eco_friendly"
                    type="checkbox"
                    label="Eco Friendly"
                    label1={{textAlign:"justify"}}
                    //register={register}
                    checked={state.eco_friendly}
                    onClick={handleChange('eco_friendly')}
                    value={state.eco_friendly}
                    /></div>
                    
                    <div className="chck col">
          <FormCheckBox
                    id="Work From Home"
                    type="checkbox"
                    label="Work From Home"
                    label1={{textAlign:"justify"}}
                    //register={register}
                    checked={state.work_from_home}
                    onClick={handleChange('work_from_home')}
                    value={state.work_from_home}
                    /></div>

      <div class="w-100"></div>

      <div className="chck col">
          <FormCheckBox
                    id="Life Style Gadgets"
                    type="checkbox"
                    label="Life Style Gadgets"
                    label1={{textAlign:"justify"}}
                    //register={register}
                    checked={state.life_style}
                    onClick={handleChange('life_style')}
                    value={state.life_style}
                    /></div>

      <div className="chck col">
                <FormCheckBox
                          id="French Tech"
                          type="checkbox"
                          label="French Tech"
                          label1={{textAlign:"justify"}}
                          //register={register}
                          checked={state.french_tech}
                          onClick={handleChange('french_tech')}
                          value={state.french_tech}
                          /></div>

    <div class="w-100"></div>
    <div className="chck col">
    <FormCheckBox
                          id="Taiwan Excellence"
                          type="checkbox"
                          label="Taiwan Excellence"
                          label1={{textAlign:"justify"}}
                          //register={register}
                          checked={state.taiwan_excellence}
                          onClick={handleChange('taiwan_excellence')}
                          value={state.french_tech}
                          /></div>
    </div>
    </div>
    </div>
}

<div className="modal-footer">

<Button
color="primary"
data-dismiss="modal"
type="button"
onClick={save}
>
Save
</Button>

<Button
color="secondary"
data-dismiss="modal"
type="button"
onClick={closeModalPopups}
>
Close
</Button>


</div>
</Modal>
</div>
);
}
export default HotTopics;