import React, { useState, useEffect } from 'react';
import {
  Card, Container, Row, CardHeader, UncontrolledTooltip, ListGroupItem, Modal,
  ListGroup, Col, Badge, Button, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import Stack from '@mui/material/Stack';

import { SelectAutocomplete } from '_components/FormElements/FormInput';
import { Spinner } from '../../Hoc/Spinner';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/PaginationActions';
import ToggleButton from '@mui/material/ToggleButton';
import { EventContactDialog } from './EventContactDialog';
const EventSlider = (props) => {
  console.log("event slider", props);
  const [showDrawer, setShowDrawer] = useState(false);
  const [show, setShow] = React.useState(false);
  const openDrawer = () => {
    // setDrawerInput(data);
    setShowDrawer(true);
    console.log("Iam HI")
  }
  const closeDrawer = () => {
    setShowDrawer(false);
    // setPasswordDrawer(false);
  }

  const [alignment, setAlignment] = React.useState(null);

  const [filters, setFilters] = useState({
    eventValue: null,
    eventOption: [],
    eventDate: null,
    eventDateOption: [],
    participantType: null,
    participantTypeOption: [{ id: 1, name: 'Vendor' }, { id: 2, name: 'buyer' }],
    sellers: [],
    buyers: []

  });

  const [selected, setSelected] = useState(null);
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [loading, setLoading] = useState(true);
  const [state, setState] = useState({ dataTable: [], master: [], request: [], total: 0, selectedSlot: null, eventInfo: [] });
  const [recieverState, setRecieverState] = useState({ dataTable: [], master: [], request: [], total: 0, selectedSlot: null, eventInfo: [] });
  const [receiverEventData,setReceiverEventData] = useState([])
  const [activeButton,setActiveButton] = useState('pending')
  const [activeReceiverButton,setActiveReceiverButton] = useState('pending')
  const [eventContactsData,setEventContactsData] = useState({})
  const [eventContactsDataList,setEventContactsDataList] = useState([])
  const [participantData,setParticipantsData] = useState({senderParticipants:[],receiverParticipants:[]})
  const [originalSenderId,setOriginalSenderId] = useState('')
  const [originalReceiverId,setOriginalReceiverId] = useState('')
  const [eventRequestId,setEventRequestId] = useState('')
  const [filterData,setFilterData] = useState([])
  
  useEffect(() => {
    let filteredData = props.contactData.filter((res)=>res.status=='3' && res.requestType == '1')
    setFilterData(filteredData)
    setState({...state,dataTable:filteredData.slice(page*rowsPerPage,rowsPerPage),total:filteredData.length})
    setActiveButton('pending')
    setActiveReceiverButton('pending')
    getEventList();
  }, [props.contactData])
  const [OpenContactDialog, setOpenContactDialog] = useState(false)
  const openContactDialog = (event) => {
    // e.preventDefault();
    setLoading(true);
    setOriginalSenderId(event.originalSenderId)
    setOriginalReceiverId(event.originalReceiverId)
    setEventRequestId(event.eventRequestId)
    axios.get(`/channelAdmin/getEventContactDetails?contactId=${event.receiverContactId}&eventId=${props.eventId}&eventRequestId=${event.eventRequestId}`).then(res => { 
      // setShow(false)
      setEventContactsData(res.data.finalReasult);
      setEventContactsDataList(res.data.meetingList);
      setParticipantsData({senderParticipants:res.data.senderParticipants,receiverParticipants:res.data.receiverParticipants});
      setLoading(false);
      setOpenContactDialog(true);
    });

  
  }
  const closeContactDialog = () => {
    setOpenContactDialog(false);
  }

  const getEventList = async () => {
    axios.get('/channelAdmin/getEventList').then(res => {
      filters.eventOption = res.data.currentEvents;

      //filters.eventValue = res.data.currentEvents[0];
      setFilters({ ...filters });
      //getParticipants(res.data.currentEvents[0].id);
    });
    setLoading(false);
  }

  const getParticipants = async (eventId) => {
    setLoading(true);
    let data = {
      page: page * rowsPerPage,
      limit: rowsPerPage,
      eventId: eventId
    }

    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.post('/channelAdmin/getEventParticipants',
      data).then(res => {

        state.dataTable = res.data.rows;
        state.master = res.data.rows;
        state.request = res.data.eventRequest;
        state.total = res.data.count;

        let funcDate = [];
        res.data.rows.map(row => {
          row.channel.contacts.map(x => {

            if (x.contactSlot.length) {
              x.contactSlot.map(slot => {

                if (!funcDate.map(s => s.name).includes(slot.sdate)) {
                  funcDate.push({ id: slot.contactSlotId, name: slot.sdate });
                }

              });
            }

          })
        })

        filters.eventDateOption = funcDate;
        //filters.eventDate=funcDate[0];
        setFilters({ ...filters });
        setState({ ...state });
        setLoading(false);
      })
  }

  const setTime = (channelId, selectedSlot) => {

    setLoading(true);
    setSelected(channelId);
    const temp = state.dataTable;
    temp.map(x => {
      if (x.channelId == channelId) {
        Object.assign(x, { slotSelection: true, selectedSlot: selectedSlot })
      } else {
        Object.assign(x, { slotSelection: false, selectedSlot: null })
      }

    })
    // state.dataTable=temp;
    setState({ ...state, dataTable: temp });
    // console.log("time:", channelId, temp)
    setLoading(false);
  }

  const handleChangeEvent = (input, value) => {
    filters.eventValue = value;
    filters.sellers = value.sellers.split(',');
    filters.buyers = value.buyers.split(',');
    setFilters(filters);
    getParticipants(value.id);
  }

  let channelTypeMap = {
    '2': 'Vendor',
    '3': 'Distributor',
    '4': 'Retailer',
    '5': 'Reseller',
 };

  const changeParticipant = (input, value) => {
    filters.participantType = value;

    if (value) {
      if (value.id == 1) {

        const filterData = state.master.filter(x => filters.sellers.includes(x.channel.channelTypeId));
        state.dataTable = filterData;
      } else if (value.id == 2) {

        const filterData = state.master.filter(x => filters.buyers.includes(x.channel.channelTypeId));
        state.dataTable = filterData;
      }
      setState({ ...state });
    } else {
      state.dataTable = state.master;
      setState({ ...state });
    }

    setFilters(filters);

  }


  const changeFunction = (input, value) => {
    filters.eventDate = value;

    let newList = []; let oldList = state.master;

    if (filters.participantType) {
      if (filters.participantType.id == 1) {
        oldList = state.master.filter(x => filters.sellers.includes(x.channel.channelTypeId));

      } else if (filters.participantType.id == 2) {
        oldList = state.master.filter(x => filters.buyers.includes(x.channel.channelTypeId));

      }
    }





    if (value) {


      oldList.map(row => {
        let flag = false;
        row.channel.contacts.map(x => {

          if (x.contactSlot.length && x.contactSlot.map(slot => slot.sdate).includes(value.name)) {
            flag = true;
          }
        })
        if (flag) {
          newList.push(row);
        }
      })


      state.dataTable = newList;
      setState({ ...state });
    } else {
      state.dataTable = oldList;
      setState({ ...state });
    }
    setFilters({ ...filters });
    //getParticipants(value.id);
  }


  const handleChange = (event, newAlignment) => {

    state.selectedSlot = newAlignment;
    setState({ ...state });
  };

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    setState({...state,dataTable:filterData.slice(page*rowsPerPage,(page+1)*rowsPerPage),total:filterData.length})
    // getParticipants();
  };

  const handleChangeRowsPerPage = (event) => {
    page=0
    setPage(page);
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    setState({...state,dataTable:filterData.slice(page*rowsPerPage,(page+1)*rowsPerPage),total:filterData.length})
    // getParticipants();
  };

  const filterList = (statusId,requestType,activeButton) =>{
    page = 0 
    setPage(page)
    let filteredData = props.contactData.filter((res)=>res.status==statusId && res.requestType == requestType)
    setFilterData(filteredData)
    setState({...state,dataTable:filteredData.slice(page*rowsPerPage,(page+1)*rowsPerPage),total:filteredData.length})
    setActiveButton(activeButton)
  }

  const receiverFilterList = (statusId,requestType,activeButton) =>{
    page = 0 
    setPage(page)
    let filteredData = receiverEventData.filter((res)=>res.status==statusId && res.requestType == requestType)
    setRecieverState({...recieverState,dataTable:filteredData.slice(page*rowsPerPage,(page+1)*rowsPerPage),total:filteredData.length})
    setActiveReceiverButton(activeButton)
  }

  const openReceiverDetails = (event)  => {
    setLoading(true)
    axios.get('/channelAdmin/getEventRequests?contactId='+event.receiverContactId).then(res => {  
      setShow(true)
      setReceiverEventData(res.data)
      let filteredData = res.data.filter((res)=>res.status=='3'&&res.requestType=='1')
      setRecieverState({...recieverState,dataTable:filteredData})
      setActiveReceiverButton('pending')
      setLoading(false)
      //setContactData(res.data)
    });
  }


  const User = {
    renderer: (row, rowIndex, index) => {
      //console.log('rowuser', row, rowIndex);

      return (


        <div style={{ width: '100%' }}>


          {row.channel.contacts.length > 0 && row.channel.contacts.map((x, i) => {

            // console.log("conts:", x.contactSlot, row.selectedSlot);

            let confirmCount;
            let sentCount;
            let recvCount;

            confirmCount = state.request?.filter(req => (req.senderContactId == x.contactId || req.receiverContactId == x.contactId) && req.status == 4).length;
            sentCount = state.request?.filter(req => req.senderContactId == x.contactId && req.status == 3).length;
            recvCount = state.request?.filter(req => req.receiverContactId == x.contactId && req.status == 3).length;

            return (x.contactSlot.map(s => s.stime).includes(row.selectedSlot)) &&
              (
                <ListGroup className="list my--2" flush key={'table' + x.contactId}>
                  <ListGroupItem className="px-0">
                    <Row className="align-items-center">
                      <Col className="col-1">
                        <a
                          className="avatar rounded-circle"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"); }}
                            alt="..."
                            src={x.document?.documentPath ? x.document.documentPath.includes(process.env.REACT_APP_CDN_URL) ? x.document.documentPath : process.env.REACT_APP_CDN_URL + x.document.documentPath : "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"}//{require("assets/img/theme/team-1.jpg")}
                          />
                        </a>
                      </Col>
                      <div className="col-lg-2 ml--2">
                        <p className="mb-0" style={{ fontSize: '0.7rem' }}>

                          {x.firstName + ' ' + x.lastName}

                        </p>

                        {/* onClick={() => login(x.contactId, x.firstName, x.lastName, x.jobTitle, x.country?.country, row.channelId, row.channelTypeId, x.document?.documentPath)} style={{ padding: "10px 10px 0 0 " }} */}

                        <div>

                          <button id={'Confirmed' + index} className="btn btn-icon btn-success btn-sm" variant="contained">
                            <span className="btn-inner--icon">{confirmCount}</span>
                            <UncontrolledTooltip target={'Confirmed' + index} placement='bottom'>Confirmed Count </UncontrolledTooltip>
                          </button>

                          <button id={'Sent' + index} className="btn btn-icon btn-info btn-sm" variant="contained">
                            <span className="btn-inner--icon">{sentCount}</span>
                            <UncontrolledTooltip target={'Sent' + index} placement='bottom'>Sent Count </UncontrolledTooltip>
                          </button>

                          <button id={'Received' + index} className="btn btn-icon btn-warning btn-sm" variant="contained">
                            <span className="btn-inner--icon">{recvCount}</span>
                            <UncontrolledTooltip target={'Received' + index} placement='bottom'>Received Count </UncontrolledTooltip>
                          </button>
                        </div>
                      </div>

                      <div className="col-lg-2 ml--2">
                        <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                          {x.jobTitle}
                        </p>
                      </div>

                      <div className="col-lg-2 ml--2">
                        <button className="btn btn-icon btn-primary btn-sm" variant="contained">Match</button>
                      </div>




                    </Row>
                  </ListGroupItem>

                </ListGroup>)

            // :  (<p>Sorry, No Contacts having selected slot</p>)
          })}
        </div >
      )
    },
    className: 'bgrow'
  };

  return (

    <Container className="mt--3" fluid>
      <EventContactDialog originalSenderId={originalSenderId} originalReceiverId={originalReceiverId} contactDetails={eventContactsData} contactDetailsList={eventContactsDataList} closeContactDialog={closeContactDialog} Open={OpenContactDialog} eventRequestId={eventRequestId} getParticipants={props.getParticipants} closeDrawer={props.closeDrawer} participantData={participantData}/>

      <Row>
      {show && <div style={{width:"600px"}}>
          <Card>
            {loading ? <Spinner /> : <ToolkitProvider
              ClassName="align-items-center table-flush" responsive
              data={recieverState.dataTable}

              keyField="contacts[0].email"
              columns={[

                {
                  dataField: "Action",
                  text: "Action",
                  formatter: (rowContent, row, index) => {
                    let confirmCount;
                    let sentCount;
                    let recvCount;
                    let incomingCount;
                    confirmCount = row.confirmedCount?row.confirmedCount:0//state.request?.filter(req => (req.senderChannelId == row.channelId || req.receiverChannelId == row.channelId) && req.status == 4).length;
                    sentCount = row.pendingCount?row.pendingCount:0//state.request?.filter(req => req.senderChannelId == row.channelId && req.status == 3).length;
                    recvCount = row.declinedCount?row.declinedCount:0//state.request?.filter(req => req.receiverChannelId == row.channelId && req.status == 3).length;
                    incomingCount = row.incomingCount?row.incomingCount:0
                    return (
                      <>

                        <button id={'Confirmed' + index} className="btn btn-icon btn-success btn-sm" variant="contained">
                          <span className="btn-inner--icon">{confirmCount}</span>
                          <UncontrolledTooltip target={'Confirmed' + index} placement='bottom'>Confirmed Count </UncontrolledTooltip>
                        </button>
                        <button id={'Incoming' + index} className="btn btn-icon btn-info btn-sm" variant="contained">
                            <span className="btn-inner--icon">{incomingCount}</span>
                            <UncontrolledTooltip target={'Incoming' + index} placement='bottom'>Incoming Count </UncontrolledTooltip>
                          </button>
                        <button id={'Sent' + index} className="btn btn-icon btn-info btn-sm" variant="contained">
                          <span className="btn-inner--icon">{sentCount}</span>
                          <UncontrolledTooltip target={'Sent' + index} placement='bottom'>Sent Count </UncontrolledTooltip>
                        </button>
                        <button id={'Received' + index} className="btn btn-icon btn-warning btn-sm" variant="contained">
                          <span className="btn-inner--icon">{recvCount}</span>
                          <UncontrolledTooltip target={'Received' + index} placement='bottom'>Received Count </UncontrolledTooltip>
                        </button>
                      </>
                    )
                  }
                },

                {
                  dataField: "companyName",
                  text: "Company Name",
                  sort: true,
                  formatter: (rowContent, row) => {
                    return (
                      <>
                         {row.receiverChannelName}
                      </>
                    )
                  }
                },

                {
                  dataField: "channelTypeId",
                  text: "Company Type",
                  sort: true,
                  formatter: (rowContent, row) => {
                    let receiverChannelType = channelTypeMap[row.channelTypeId] || '';
                    return (
                      <>
                           {receiverChannelType}
                      </>
                    )
                  },
                },
                {
                  dataField: "contact details",
                  text: "Contact",
                  sort: true,
                  formatter: (rowContent, row) => {
                    return (
                      <>
                        {row.receiverContactName}
                      </>
                    )
                  },
                },
                    {
                  dataField: "sender participants",
                  text: "Sender Participants",
                  sort: true,
                  formatter: (rowContent, row) => {
                    return (
                      <>
                        {row.senderParticipants.map(contact => contact.contactName).join(', ')}
                      </>
                    )
                  },
                },
                    {
                  dataField: "receiver participants",
                  text: "Receiver Participants",
                  sort: true,
                  formatter: (rowContent, row) => {
                    return (
                      <>
                        {row.receiverParticipants.map(contact => contact.contactName).join(', ')}
                      </>
                    )
                  },
                },
              ]}
            >
              {props => (
                <div className="py-2 table-responsive eventslider-table">
                  <Row>
                    <div>
                      <h3>Receivers Request</h3>
                      <Stack direction="row" spacing={1}>
                        <Button className={`${activeReceiverButton == 'pending' ? 'btn-primary' : 'btn-secondary'}`} variant="contained" onClick={() => receiverFilterList('3','1','pending')} >Pending Request</Button>

                        <Button className={`${activeReceiverButton == 'incoming' ? 'btn-primary' : 'btn-secondary'}`} variant="contained" onClick={() => receiverFilterList('3','2','incoming')} >Incoming Request</Button>

                        <Button className={`${activeReceiverButton == 'confirmed' ? 'btn-primary' : 'btn-secondary'}`} variant="contained" onClick={() => receiverFilterList('4','1','confirmed')} >Confirmed</Button>

                        <Button className={`${activeReceiverButton == 'declined' ? 'btn-primary' : 'btn-secondary'}`} variant="contained" onClick={() => receiverFilterList('5','1','declined')} >Declined/Cancel</Button>


                      </Stack>
                      <BootstrapTable
                        {...props.baseProps}
                        //   expandRow={User}
                        bootstrap4={true}
                        pagination={paginationFactory()}   
                        bordered={false}
                        hover={true}
                        rowClasses='pointer'
                        onPageChange={(page, sizePerPage) => {
                        }}
                      />
                      {/* <TablePagination
                        component="div"
                        count={state.total}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      /> */}
                    </div>
                  </Row>
                </div>
              )}
            </ToolkitProvider>}
          </Card>
          <div>
                        <button className="btn btn-icon btn-primary btn-sm" variant="contained" onClick={() => setShow(false)}>Close</button> 
                                               </div>
        </div>
        }
        <div style={{width:"600px"}}>
          <Card>
            {loading ? <Spinner /> : <ToolkitProvider
              ClassName="align-items-center table-flush" responsive
              data={state.dataTable}

              keyField="contacts[0].email"
              columns={[

                {
                  dataField: "Action",
                  text: "Action",
                  formatter: (rowContent, row, index) => {
                    let confirmCount;
                    let sentCount;
                    let recvCount;
                    let incomingCount;
                    confirmCount = row.confirmedCount?row.confirmedCount:0//state.request?.filter(req => (req.senderChannelId == row.channelId || req.receiverChannelId == row.channelId) && req.status == 4).length;
                    sentCount = row.pendingCount?row.pendingCount:0//state.request?.filter(req => req.senderChannelId == row.channelId && req.status == 3).length;
                    recvCount = row.declinedCount?row.declinedCount:0
                    incomingCount = row.incomingCount?row.incomingCount:0
                    return (
                      <>
<div>
                        <button id={'Confirmed' + index} className="btn btn-icon btn-success btn-sm" variant="contained">
                          <span className="btn-inner--icon">{confirmCount}</span>
                          <UncontrolledTooltip target={'Confirmed' + index} placement='bottom'>Confirmed Count </UncontrolledTooltip>
                        </button>
                        <button id={'Sent' + index} className="btn btn-icon btn-info btn-sm" variant="contained">
                          <span className="btn-inner--icon">{sentCount}</span>
                          <UncontrolledTooltip target={'Sent' + index} placement='bottom'>Sent Count </UncontrolledTooltip>
                        </button>
                        <button id={'Incoming' + index} className="btn btn-icon btn-info btn-sm" variant="contained">
                            <span className="btn-inner--icon">{incomingCount}</span>
                            <UncontrolledTooltip target={'Incoming' + index} placement='bottom'>Incoming Count </UncontrolledTooltip>
                          </button>
                        <button id={'Declined' + index} className="btn btn-icon btn-warning btn-sm" variant="contained">
                          <span className="btn-inner--icon">{recvCount}</span>
                          <UncontrolledTooltip target={'Declined' + index} placement='bottom'>Declined Count </UncontrolledTooltip>
                        </button>
                        </div>
                        <div>
                        <button className="btn btn-icon btn-primary btn-sm" variant="contained" onClick={() => openReceiverDetails(row)}>Match</button> 
                                               </div>
                      </>
                    )
                  }
                },

                {
                  dataField: "companyName",
                  text: "Company Name",
                  sort: true,
                  formatter: (rowContent, row) => {
                    return (
                      <>
                        {row.receiverChannelName}
                      </>
                    )
                  }
                },

                {
                  dataField: "channelTypeId",
                  text: "Company Type",
                  sort: true,
                  formatter: (rowContent, row) => {
                    let channelType = channelTypeMap[row.channelTypeId] || '';
                    return (
                      <>
                        {channelType}
                      </>
                    )
                  },
                },
                {
                  dataField: "contact details",
                  text: "Contact",
                  sort: true,
                  formatter: (rowContent, row) => {
                    return (
                      <>
                       <span onClick={e => openContactDialog(row)}>
                       {row.receiverContactName}
                      </span>
                      </>
                    )
                  },
                },
                {
                  dataField: "sender participants",
                  text: "Sender Participants",
                  sort: true,
                  formatter: (rowContent, row) => {
                    return (
                      <>
                        {row.senderParticipants?.map(contact => contact.contactName).join(', ')}
                      </>
                    )
                  },
                },
                    {
                  dataField: "receiver participants",
                  text: "Receiver Participants",
                  sort: true,
                  formatter: (rowContent, row) => {
                    return (
                      <>
                        {
                        row.receiverParticipants?.map(contact => contact.contactName).join(', ')}
                      </>
                    )
                  },
                },
              ]}
            >
              {props => (
                <div className="py-2 table-responsive eventslider-table">
                  <Row>
                    <div>
                      <h3>Own Request</h3>
                      <Stack direction="row" spacing={1}>
                        <Button className={`${activeButton == 'pending' ? 'btn-primary' : 'btn-secondary'}`}  variant="contained" onClick={() => filterList('3','1','pending')} >Pending Request</Button>

                        <Button className={`${activeButton == 'incoming' ? 'btn-primary' : 'btn-secondary'}`}  variant="contained" onClick={() => filterList('3','2','incoming')} >Incoming Request</Button>

                        <Button className={`${activeButton == 'confirmed' ? 'btn-primary' : 'btn-secondary'}`}  variant="contained" onClick={() => filterList('4','1','confirmed')}>Confirmed</Button>

                        <Button className={`${activeButton == 'declined' ? 'btn-primary' : 'btn-secondary'}`}  variant="contained" onClick={() => filterList('5','1','declined')}>Declined/Cancel</Button>


                      </Stack>
                      <BootstrapTable
                        {...props.baseProps}
                        //   expandRow={User}
                        bootstrap4={true}
                        // pagination={paginationFactory()}          
                        bordered={false}
                        hover={true}
                        onPageChange={(page, sizePerPage) => {
                        }}
                        rowClasses='pointer'
                      />
                      <TablePagination
                        component="div"
                        count={state.total}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </div>
                  </Row>
                </div>
              )}
            </ToolkitProvider>}
          </Card>
        </div>
      </Row>
    </Container>

  )

}

export default EventSlider;