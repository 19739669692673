import * as yup from 'yup';

const EventSchema = yup.object().shape({
  eventName: yup.object().shape({
    name: yup.string().required().min(3, 'Event name must be minimum 3 characters').max(255, 'Event Name must have Max 255 Characters'),
    id: yup.string()
  }).required('Please Enter Event Name'),
    eventDescription: yup.string().required("Event Description can't be empty!").min(5, 'Event Description must have min 5 Characters').max(5000, 'Event Description must have max 5000 Characters'),
    address: yup.string().required('Please Enter Address').max(255, 'Address Must Have Max 255 Characters'),
    country: yup.string().required('Please Select Your country'),
    city: yup.string().required('Please Enter City'),
    allowedUserType1: yup.string().required('Please Select Seller'),
    allowedUserType2: yup.string().required('Please Select Buyer'),
    zipCode: yup.string().required('Please Enter Zip/Postal Code').min(3, 'Zip/Postal Code Must Have Min 3 Characters').max(12, 'Zip/Postal Code Must Have Max 12 Characters'),
    fromDate: yup.date().required('Please Enter Event Starting Date.').typeError('Please Enter Valid Date.'),
    toDate: yup.date().required('Please Enter Event Ending Date.').typeError('Please Enter Valid Date.'),
    fromTime: yup.date().required('Please Enter Event Starting Time.').typeError('Please Enter Valid Time.'),
    toTime: yup.date().required('Please Enter Event Ending Time.').typeError('Please Enter Valid Time.'),
    timeZone: yup.string().required('Please Select TimeZone.').typeError('Please Select TimeZone'),

    sellerPackages: yup.array().of(yup.object({
      value : yup.string().required('Please Create a Package.')
    })),
    buyerPackages: yup.array().of(yup.object({
      value : yup.string().required('Please Create a Package.')
    })),

    eventBanner: yup.string().required('Please Select Event Banner'),
    eventLogo: yup.string().required('Please Select Event Logo')
  });


  const PackageSchema = yup.object().shape({
    packageName : yup.string().required('Enter Package Name').min(3, 'Package Name must have Min 3 Characters').max(255, 'Package Name must have Max 255 Characters'),
    // packageDescription: yup.string().required("Package Description can't be empty!").min(5, 'Package Description must have min 5 Characters').max(180, 'Package Description must have max 255 Characters'),
    // boothSize: yup.number().required('Please Enter Booth Size').typeError('Please Enter Booth Size'),
    additionalCosting: yup.number().required('Please Enter Additional Members Costing').typeError('Please Enter Additional Members Costing'),
    price: yup.number().required('Please Enter Price').typeError('Please Enter Price'),
    meetingsAllowed: yup.number().required('Please Enter No.of Meetings Allowed').typeError('Please Enter No.of Meetings Allowed'),
    accessBadge: yup.number().required('Please Enter No.of Access Badges').typeError('Please Enter No.of Access Badges'),
    
    privateWebinar: yup.number().when("type", {is: '1', then: yup.number().required('Please Enter No.of Private Webinar').typeError('Please Enter No.of Private Webinar')}),
    firesideChat: yup.number().when("type", {is: '1', then: yup.number().required('Please Enter No.of Fireside Chat').typeError('Please Enter No.of Fireside Chat')}),
    // membersAllowed: yup.number().required('Please Enter No.of Allowed Members').typeError('Please Enter No.of Allowed Members'),
    
    emailMarketing: yup.number().when("type", {is: '1', then: yup.number().required('Please Enter No.of.Email Marketing').typeError('Please Enter No.of.Email Marketing')}),
    subscriptionToCH: yup.number().when("type", {is: '1', then: yup.number().required('Please Enter No.of year CH Subscription').typeError('Please Enter No.of year CH Subscription')}),

    Options_for_buyers:yup.array().of(
      yup.object().shape({
        optname: yup.lazy(value => !value ? yup.string() : yup.string().required("Text can't be empty")),
        optvalue: yup.lazy(value => !value ? yup.string() : yup.number().required("Value can't be empty"))
      }).nullable()
    ),
    marketing_options:yup.array().of(
      yup.object().shape({
        optname: yup.lazy(value => !value ? yup.string() : yup.string().required("Text can't be empty")),
        optvalue: yup.lazy(value => !value ? yup.string() : yup.number().required("Value can't be empty")),
      }).nullable()
    ),

  });

  const PackageSchema1 = yup.object().shape({
    packageName : yup.string().required('Enter Package Name').min(3, 'Package Name must have Min 3 Characters').max(255, 'Package Name must have Max 255 Characters'),
    
    price: yup.number().required('Please Enter Price').typeError('Please Enter Price'),
    

    // packageDescription : yup.string().required('Enter Package Name').min(3, 'Package Name must have Min 3 Characters').max(255, 'Package Name must have Max 255 Characters'),
    packageType : yup.string().required('Enter Package Name'),
    // packageUser : yup.string().required('Enter Package Name'),
    
   
    
    
    
    
   

  });

  const PackageSchema2 = yup.object().shape({
    packageName : yup.string().required('Enter Package Name').min(3, 'Package Name must have Min 3 Characters').max(255, 'Package Name must have Max 255 Characters'),
    
    price: yup.number().required('Please Enter Price').typeError('Please Enter Price'),
  
  });

  const AddonPackageSchema1 = yup.object().shape({
    packageName : yup.string().required('Enter Package Name').min(3, 'Package Name must have Min 3 Characters').max(255, 'Package Name must have Max 255 Characters'),
    
    price: yup.number().required('Please Enter Price').typeError('Please Enter Price'),
    packageUser : yup.string().required('Enter Package Name'),

    // packageDescription : yup.string().required('Enter Package Name').min(3, 'Package Name must have Min 3 Characters').max(255, 'Package Name must have Max 255 Characters'),
   
    
   
    
    
    
    
   

  });

  export { EventSchema, PackageSchema,PackageSchema1,AddonPackageSchema1,PackageSchema2 }
