import React from "react";
import { Card,CardText,CardTitle,Button, CardBody,CardHeader, Container, Row, Col,} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useForm} from "react-hook-form";
import {GoogleAddress} from '../SignupPage/googleAddress'
import PhoneInput from 'react-phone-input-2';
import Avatar  from 'react-avatar-edit';
import {QuillEditor,FormInput,FormFileInput, FormCheckBox, FormAutocomplete, FormRadio,} from "_components/FormElements/FormInput";
import MultipleImageUploadComponent from "./MultipleImageUploadComponent";
import ImageUploaderComponent from "./ImageUploader";
import Autocomplete from '@material-ui/lab/Autocomplete'; 
import TextField from '@material-ui/core/TextField';
const Profile = (props) => {
  
  const [value, setValue] = React.useState(0);
  const { register, handleSubmit, control, errors } = useForm({      
    mode:'onBlur',  
  
});

const onSubmit = data => {    
if (data) {        
    console.log("data:",data);
}    
}
return (
   
     <div className="main-content">
        <Container className="mt-4 col-lg-8 col-md-8 col-sm-12" fluid>
 
              <Card>
            
           
       
                <CardBody className="Profad">
                 <h3 style={{textAlign:'center'}}>Registration Info</h3>
                  <p style={{textAlign:'center',fontSize:'.8rem'}}>Enter Company legal details.</p>
             
                
     
                <div className="row"> 
                <div className="col-sm-12">
                <div className="row">
                <div className="col-sm-6">
               <h4>Company Logo</h4>
                <div>
                <Avatar/>
               </div>
              </div>
    <div className="col-sm-6 mt-4">
        <span>Accepts Jpeg / Jpg / Png Only</span>
        <span>(Max size allowed 200 Kb)</span>
        </div> 
         </div>
                  
    </div>
     
</div>

        <h3 style={{textAlign:'center'}}>Trade Locations</h3>
                  <p className="text-center">Update the list of countries you are in charge of. We have pre-loaded the list of countries selected in your company profile.</p>
       
                
                  <div className="col-lg-12">
                  <div className="row">
                  <div className="col-lg-3">
                  <h5 className="text-center">Choose Selling Countries</h5></div>
                  <div className="col-lg-6"></div>
                  <div className="col-sm-3"> 
                  <div className="form-group">        
            <FormInput 
                        id="country"
                        type="text"
                        name="country"
                        label="Country"
                        //placeholder="Password"
                        // register={register}
                        // error={errors.country}
                        // value={country}
                        // onChange={handleChange('country')}
                    /> 
                 </div>      
                  </div>
                  </div>
                  </div>

                  <div className="col-lg-12 ">
            <div className="form-group">               
                    <Autocomplete
  multiple
  limitTags={2}
  id="SellingCountries"
  disableCloseOnSelect
  options={top100Films}
  getOptionLabel={(option) => option.name}
  renderInput={(params) => (
    <TextField {...params} variant="outlined" label="Selling Countries
    " placeholder="Search" />
  )}
/>     
        </div>
        </div>
            
       
                
                  <div className="col-lg-12">
                  <div className="row">
                  <div className="col-lg-3">
                  <h5 className="text-center">Choose Target Countries</h5></div>
                  <div className="col-lg-6"></div>
                  <div className="col-sm-3"> 
                  <div className="form-group">        
            <FormInput 
                        id="country"
                        type="text"
                        name="country"
                        label="Country"
                        //placeholder="Password"
                        // register={register}
                        // error={errors.country}
                        // value={country}
                        // onChange={handleChange('country')}
                    /> 
                 </div>      
                  </div>
                  </div>
                  </div>

                  <div className="col-lg-12 ">
            <div className="form-group">               
                    <Autocomplete
  multiple
  limitTags={2}
  id="ProductFamily"
  disableCloseOnSelect
  options={top100Films}
  getOptionLabel={(option) => option.name}
  renderInput={(params) => (
    <TextField {...params} variant="outlined" label="Target Countries
    " placeholder="Search" />
  )}
/>     
        </div>
        </div>
        <hr className="my-4"></hr>    
        <hr className="my-4"></hr>
            <h3 style={{textAlign:'center'}}>Product Responsibilities</h3>
                  <p style={{textAlign:'center',fontSize:'0.8rem'}}>Select the products families that you offer</p>
                  {/* <h5 style={{color:'green'}}>Download to view List of Product Families and relevant Categories<h5/> */}

            <div className="col-lg-12 ">
            <div className="form-group">               
                    <Autocomplete
  // multiple
  limitTags={2}
  id="Product Responsibilities"
  disableCloseOnSelect
  options={top100Films}
  getOptionLabel={(option) => option.name}
  renderInput={(params) => (
    <TextField {...params} variant="outlined" label="Product Responsibilities" placeholder="Product Responsibilities" />
  )}
/>     
        </div>
        </div>

        <h3 style={{textAlign:'center'}}>Communication preference</h3>
        {/* <h5>	How frequently do you wish to receive suggestions? As they come!</h5> */}
        <div className="row">
        <div className="col-sm-12">
            <div className="form-group text-center text-sm mb-0">        
            <FormRadio
                    name="Communication Preference1"
                    className="text-sm mb-0"
                    label="1.How frequently do you wish to receive suggestions? As they come!"
                    options={radioGroupOptions}
                    control={control}
                    row
                    // onChange={handleChange('isOEM')}
                    // value={isOEM}
                     />
                 </div>           
            </div>
            </div>


            <div className="row mt-2">
        <div className="col-sm-12">
            <div className="form-group text-center text-sm mb-0">        
            <FormRadio
                    name="Communication Preference2"
                    className="text-sm ml-4"
                    label="2.	How do you want to manage your network invitation coming from vendors and distributors? "
                    options={radioGroupOptions}
                    control={control}
                    row
                    // onChange={handleChange('isOEM')}
                    // value={isOEM}
                     />
                 </div>           
            </div>
            </div>
            {/* <div className="row">
            <div className="col-sm-12">
            <div className="form-group text-center text-sm mt-2">        
            <FormRadio
                    name="Communication Preference"
                    className="text-sm mb-0"
                    label="2.	How do you want to manage your network invitation coming from vendors and distributors?"
                    options={radioGroupOptions}
                    control={control}
                    row
                    // onChange={handleChange('isOEM')}
                    // value={isOEM}
                     />
                 </div>           
            </div>
            </div> */}
         
           </CardBody>
          
          </Card>
          </Container>
      </div>
    
 
  );
};

const top100Films = [
  { name: 'The Shawshank Redemption', id: 1994 },
  { name: 'The Godfather', id: 1972 },
  { name: 'The Godfather: Part II', id: 1974 },
  { name: 'The Dark Knight', id: 2008 },
  { name: '12 Angry Men', id: 1957 },
  { name: "Schindler's List", id: 1993 },
  { name: 'Pulp Fiction', id: 1994 },
  { name: 'The Lord of the Rings: The Return of the King', id: 2003 },
  { name: 'The Good, the Bad and the Ugly', id: 1966 },
  { name: 'Fight Club', id: 1999 },
  { name: 'The Lord of the Rings: The Fellowship of the Ring', id: 2001 },
  { name: 'Star Wars: Episode V - The Empire Strikes Back', id: 1980 },
  { name: 'Forrest Gump', id: 1994 },
  { name: 'Inception', id: 2010 },
  { name: 'The Lord of the Rings: The Two Towers', id: 2002 },
  { name: "One Flew Over the Cuckoo's Nest", id: 1975 },
  { name: 'Goodfellas', id: 1990 },
  { name: 'The Matrix', id: 1999 },
  { name: 'Seven Samurai', id: 1954 },
  { name: 'Star Wars: Episode IV - A New Hope', id: 1977 },
  { name: 'City of God', id: 2002 },
  { name: 'Se7en', id: 1995 },
  { name: 'The Silence of the Lambs', id: 1991 },
  { name: "It's a Wonderful Life", id: 1946 },
  { name: 'Life Is Beautiful', id: 1997 },
  { name: 'The Usual Suspects', id: 1995 },
  { name: 'Léon: The Professional', id: 1994 },
  { name: 'Spirited Away', id: 2001 },
  { name: 'Saving Private Ryan', id: 1998 },
  { name: 'Once Upon a Time in the West', id: 1968 },
  { name: 'American History X', id: 1998 },
  { name: 'Interstellar', id: 2014 },
];

const radioGroupOptions = [
  {
    value: "One a  week",
    label: "One a  week",
  },
  {
    value: "Once per 2 weeks ",
    label: "Once per 2 weeks ",
  },
  {
    value: "Once per month",
    label: "Once per month",
  },
  {
    value: "Never",
    label: "Never",
  },
 
];
 
export default Profile;
