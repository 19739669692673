import React, {useState,useEffect} from 'react';
import {Card,CardBody,Row,Col} from "reactstrap";
import {InputLabel,Select,TextField,FormControl} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { useForm} from "react-hook-form";
import {FormAutocomplete} from "_components/FormElements/FormInput";
import { commonService } from '_services/common.service';
import { Spinner} from 'views/Hoc/Spinner';
import { history } from '_helpers/history';
import axios from 'axios';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const CreateSubscription = (props) => {
  const [showDrawer,setshowDrawer]=useState(false);
  // const { register, handleSubmit, control, errors } = useForm({      
  //   mode:'onBlur',  
  //   //resolver: yupResolver(TradeInformationSchema),
  // });

  const { handleSubmit } = useForm({      
    mode:'onBlur',  
    //resolver: yupResolver(TradeInformationSchema),
  });

  
  // const {subscriptionId} = props.subscriptionId;
  // console.log(subscriptionId, 'subscriptionId', props)  
  const [loading,setLoading] = useState(false);
  
    const [state, setState] = useState({
        status:'1',
        amount:'0',     
        featureValue:[],
        feature1:null,
        feature2:null,
        feature3:null,
        feature4:null,
        feature5:null,
        feature6:null,
        feature7:null,
        feature8:null,
        feature9:null,
        feature10:null,
        feature11:null,
        feature12:null,
        limitField: []
      });

     
  
      useEffect(()=>{
        getPlanDetails(props.subscriptionId);
      }, [props.subscriptionId]);
  
      const getPlanDetails = async (subscriptionId)=>{
        setLoading(true);  
          let subsctionPlanDetails =  await axios.post('/channel/subscription/getPlansDetailsById', {subscriptionId: subscriptionId})
          .then(res =>{
              return res.data.result;
            
          }).catch(err => setLoading(false));
         
          closeDrawer();  
          let IsLimit = {};
        let limitField = await subsctionPlanDetails.subscriptionExtraFeature.map((data, index) => {
          IsLimit[index] = false;
          if(+data.feature_value > 0){
            IsLimit[index] = true;
          }
        });  
        setState({...state,
          status:subsctionPlanDetails.isActive,
          amount:subsctionPlanDetails.amount,
          featureValue:subsctionPlanDetails.subscriptionExtraFeature,
          limitField: IsLimit,
          //categories:subsctionPlanDetails.subscriptionExtraFeature[0].subscriptionExtraFeatureCategory
        });    
        setLoading(false);      
      }
     
      const handleSelect  = input => e => {
  
        if((document.getElementById('numeric'+e.target.id)) && e.target.value != 'limit' && document.getElementById('numeric'+e.target.id).style.display != 'none'){
          document.getElementById('numeric'+e.target.id).style.display = 'none';
          state.featureValue[input]['feature_value'] = e.target.value;
        } else if((document.getElementById('numeric'+e.target.id)) && e.target.value == 'limit' && document.getElementById('numeric'+e.target.id).style.display == 'none'){
          document.getElementById('numeric'+e.target.id).style.display = 'inline-block';
          state.limitField[e.target.id] = true;
              setState({...state,
                limitField: state.limitField
                });
                state.featureValue[input]['feature_value'] = 1;
        } else if(e.target.value == 'limit'){
      
          state.limitField[e.target.id] = true;
              setState({...state,
                limitField: state.limitField
                });
          state.featureValue[input]['feature_value'] = 1;
          //document.getElementById('numeric'+e.target.id).value;
          // document.getElementById('numeric'+e.target.id).style.display = 'block';
        } else {
          state.featureValue[input]['feature_value'] = e.target.value;
        }
          
          setState({...state,
            featureValue: state.featureValue
          });
      }
      
      const handleChange = (input , index, type)=> e => {   
   
        if(type == 'feature'){
          state.featureValue[index][input] = e.target.value;
          setState({...state, featureValue : state.featureValue});  
        } else {
          setState({...state, [input] : e.target.value});      
        }   
      };
      
      const handleToDate = (date) => {        
        state.endDate=date;
        setState({...state,endDate:state.endDate})
        //setFromDate(date);
      };


      
      console.log("text",showDrawer);
      const closeDrawer =()=>{
       // props.onClose();
        setshowDrawer(false);
        
      }

      

      const save = async() => { 
        console.log('state.featureValue');
          console.log(state.featureValue);
        let data={
          isActive:(state.status =='on')?'1':'1',
          amount:+state.amount,
          feature:state.featureValue
        }
        setLoading(true);   
        // if(couponId==0)
        //   await commonService.postService(data,'channelAdmin/addCoupon');  
        // else
        //await commonService.postService('/channel/subscription/updateSubscription/'+props.subscriptionId,data);  

        let result =  await axios.post('/channel/subscription/updateSubscription/'+props.subscriptionId, data)
        .then(res =>{ console.log(res);
            return res;
          
        }).catch(err => setLoading(false));


        setLoading(false);   
        closeDrawer();
      } 

      
const handleValues = input => e => {
  // state.featureValue[input].value = e.target.value;
  // console.log(e.target.value);
  state.featureValue[input]['feature_value'] = e.target.value;
  setState({...state,
      featureValue: state.featureValue
    });
    // console.log('xxxxxx');
    // console.log(state.featureValue[input].feature_value);
  }

const saveAndUpdate = async() => { 

}

    return (
      
        <div>     
        {loading? <Spinner />: (          
            <Card>
                <CardBody>
                <form className="coupon-form" noValidate autoComplete="off" onSubmit={handleSubmit(save)}>
                    <Row>
                        <Col lg="6" className="mt-4">
                        <TextField id="outlined-basic" value={state.amount} label="Amount" variant="outlined" onChange={handleChange('amount', null, null)} />
                        </Col>
                        <Col lg="6" className="mt-4">
                             <label className="custom-toggle custom-toggle-success">
                                    <input defaultChecked={state.status} type="checkbox" name="status" onChange={handleChange('status', null, null)} />
                                <span className="custom-toggle-slider rounded-circle" data-label-off="Inactive" data-label-on="Active"></span>
                            </label>
                        </Col>
                        </Row>
                        <h2>Feature Values</h2>                     
                        <Row>
                    
                      {state.featureValue.map((data, index) => (
                      <div>
                      <Row style={{padding: '10px' }}>
                        <Col lg="4">
                          <h4>{data.subscriptionExtraFeatureCategory.subsc}</h4>
                        </Col>
                        
                          <Col lg="4">
                              <TextField id="outlined-basic" value={data.feature_label}  variant="outlined" 
                              onChange={handleChange('feature_label', index, 'feature')} />
                          </Col>
                          <Col lg="4">
                              {/* <TextField id="outlined-basic" value={data.feature_value}  variant="outlined" 
                              onChange={handleChange('feature_value', index, 'feature')} /> */}
                          

                  <select id={index} style={{ padding: '18.5px 14px', color:'currentColor', borderRadius:'4px'}} onChange ={handleSelect( index)}>
                  <option value="yes" selected={'yes'  == data.feature_value}>Yes</option>
                  <option value="no" selected={'no'  == data.feature_value}>No</option>
                  <option value="random" selected={'random'  == data.feature_value}>Random</option>
                  <option value="limit" selected={'limit'  == data.feature_value || +data.feature_value > 0}>Limit</option>
                  <option value="unlimited" selected={'unlimited'  == data.feature_value}>Unlimited</option>
                </select>
                { (state.limitField[index]) === true?
                <input type="text" id={'numeric'+index} style={{display: 'block'}}  value={state.featureValue[index].feature_value}  onChange ={handleValues(index)} variant="outlined"  />
                :''}


                          </Col>
                      </Row>
                      
                      </div>
                      ))}

                    </Row>

                    

                    {/* <Row>
                        
                        <Col lg="6" className="mt-4">
                        
                        </Col>
                    </Row> */}

                    
                    <div className="text-center mt-4">
                      <button type="submit"  className="btn btn-primary" >Save</button>

                      {/* <button type="button" onClick={saveAndUpdate()}  className="btn btn-primary" >Save & update channel</button> */}

                      <button onClick={e => {e.preventDefault(); closeDrawer()}}  className="btn btn-warning" >Cancel</button>
                    </div> 
                </form>
                </CardBody>
            </Card>
            )};
        </div>
                
    );
};

export default CreateSubscription;