
import axios from 'axios';

function productGroup() {
  return axios.get('/common/getProductGroup').then(res => {
    //console.log("ProductGroup:",res.data);
    return res.data;
  });

}

const getServiceWithParams = (data, url) => {
  return new Promise(function (resolve, reject) {
    axios.get(url, {
      params: data
    })
      .then(data => { console.log(data); resolve(data) })
      .catch(err => { console.error(err); reject(err) })
  });
}


const getServices = (url) => {
  return new Promise(function (resolve, reject) {
    axios.get(url)
      .then(data => { console.log(data); resolve(data) })
      .catch(err => { console.error(err); reject(err) })
  });
}

const deleteService = (url) => {
  return new Promise(function (resolve, reject) {
    axios.delete(url)
      .then(data => { console.log(data); resolve(data) })
      .catch(err => { console.error(err); reject(err) })
  });
}


const postService = (data, url) => {
  return new Promise(function (resolve, reject) {
    axios.post(url, data)
      .then(data => { console.log(data); resolve(data) })
      .catch(err => { console.error(err); reject(err) })
  });
}

const putService = (url, data) => {
  return new Promise(function (resolve, reject) {
    axios.put(url, data)
      .then(data => { console.log(data); resolve(data) })
      .catch(err => { console.error(err); reject(err) })
  });
}


const fileUploadService = (data, url) => {
  return new Promise(function (resolve, reject) {
    axios.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(data => { console.log(data); resolve(data) })
      .catch(err => { console.error(err); reject(err) })
  });
}

const updateTradeLocation = (data, url) => {
  return new Promise(function (resolve, reject) {
    axios.put(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(data => { console.log(data); resolve(data) })
      .catch(err => { console.error(err); reject(err) })
  });
}

export const commonService = {
  productGroup,
  updateTradeLocation,
  postService,
  putService,
  getServices,
  deleteService,
  fileUploadService,
  getServiceWithParams
};



